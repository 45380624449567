import { loadCompaniesAndUpdateColumns } from 'actions/company_extra_data/update-extra-data-for-companies-in-list-action';
import { handleCustomAnalyticsDiscoverySearchProgress } from 'actions/custom-analytics/create-new-discovery-in-custom-analytics-thread-action';
import { addSubdomainsAction } from 'actions/domain-research/add-subdomains-action';
import { addNewCompanyMetasToList } from 'actions/explore/add-new-company-metas-action';
import { fetchExploreRunsSummaryAction } from 'actions/explore/fetch-explore-runs-summary-action';
import { loadExploreSupportingResource } from 'actions/explore/fetch-explore-supporting-resource-action';
import { loadListSubdomainsAction } from 'actions/explore/load-list-subdomains-action';
import { updateRecentlyFinishedRun } from 'actions/update-recently-finished-run';
import { EXPLORE_PREFIX } from 'constants/app-routes';
import { COMPANY_EXTRA_DATA_TYPES } from 'constants/company-extra-data';
import { dispatch } from 'hooks/AppStateProvider';
import { usePusher } from 'hooks/PusherProvider';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { removeExploreSubdomainsLoading } from 'reducer/domain-research-slice';
import {
  deleteCompaniesFromListByIds,
  updateDiscoveryProgress,
  updateTriggredCustomAnalytics
} from 'reducer/explore-slice';
import { addSubscriberToChannel, removeSubscriberFromChannel } from 'reducer/pusher-slice';
import { getDiscoveriesIdsByListId } from 'selectors/explore';
import { getClientId } from 'selectors/general';
import { getActiveSubscriptionsByChannelName } from 'selectors/pusher';
import { getListChannelName } from 'utils/pusher-utils';

const processProgressDiscovery = (
  searchId,
  progress,
  message,
  reduxDisaptch,
  listId,
  knownIds,
  setKnownIds,
  companyMetaIds
) => {
  if (!knownIds.includes(`${searchId}`)) {
    setKnownIds((prev) => [...prev, `${searchId}`]);
    reduxDisaptch(fetchExploreRunsSummaryAction(listId));
  }
  if (progress >= 100) {
    setTimeout(() => {
      reduxDisaptch(updateDiscoveryProgress({ searchId, progress }));
      reduxDisaptch(addNewCompanyMetasToList(listId, null, companyMetaIds));
      dispatch(updateRecentlyFinishedRun(searchId, 'discoveries'));
    }, 1000);
  } else {
    reduxDisaptch(updateDiscoveryProgress({ searchId, progress, message }));
  }
  reduxDisaptch(handleCustomAnalyticsDiscoverySearchProgress(listId, searchId, progress, message));
};

function PusherListListener({ listId }) {
  const pusher = usePusher();
  const reduxDisaptch = useDispatch();
  const [knownIds, setKnownIds] = useState([]);
  const [listChannel, setChannel] = useState(null);
  const discoveryIds = useSelector((state) => getDiscoveriesIdsByListId(state, listId));
  const currectClientId = useSelector(getClientId);
  const channelName = getListChannelName(listId);
  const navigate = useNavigate();
  const activeSubscriptions = useSelector((state) =>
    getActiveSubscriptionsByChannelName(state, channelName)
  );

  useEffect(() => {
    if (discoveryIds && discoveryIds.length > 0) {
      setKnownIds(discoveryIds);
    }
  }, [discoveryIds]);

  useEffect(() => {
    if (!pusher) {
      return;
    }

    if (activeSubscriptions) {
      setChannel(pusher?.channels?.channels?.[channelName]);
    } else {
      setChannel(pusher.subscribe(channelName));
    }
    reduxDisaptch(addSubscriberToChannel({ channelName }));

    return () => {
      reduxDisaptch(removeSubscriberFromChannel({ channelName }));
    };
    // activeSubscriptions is not included in the dependencies array because only the first render is relevant
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listId, pusher, channelName, reduxDisaptch]);

  useEffect(() => {
    if (listChannel && listChannel.bind) {
      listChannel.bind('progress', (data) => {
        const {
          record_id,
          progress: floatProgress,
          message,
          new_company_meta_ids: companyMetaIds
        } = data;
        if (record_id) {
          const progress = 100 * floatProgress;
          processProgressDiscovery(
            record_id,
            progress,
            message,
            reduxDisaptch,
            listId,
            knownIds,
            setKnownIds,
            companyMetaIds
          );
        }
      });
    }
    return () => {
      listChannel?.unbind('progress');
    };
  }, [listChannel, knownIds, listId, setKnownIds, reduxDisaptch]);

  useEffect(() => {
    if (!listChannel || !currectClientId) {
      return;
    }
    listChannel.bind('company_extra_data', (data) => {
      const { data_type, updated_company_meta_ids, client_id } = data;
      if (
        updated_company_meta_ids?.length &&
        (client_id !== currectClientId || data_type === COMPANY_EXTRA_DATA_TYPES.FINANCE)
      ) {
        reduxDisaptch(loadCompaniesAndUpdateColumns(listId, data_type, updated_company_meta_ids));
      }
    });
  }, [listChannel, currectClientId, reduxDisaptch, listId]);

  useEffect(() => {
    if (!listChannel || !currectClientId) {
      return;
    }
    listChannel.bind('subdomains', (data) => {
      const { name, description, parent_id, level, client_id, parameters } = data;
      if (name && description && client_id === currectClientId) {
        reduxDisaptch(
          addSubdomainsAction(
            listId,
            parent_id,
            [{ name, description }],
            level,
            null,
            true,
            parameters
          )
        );
      }
    });
    return () => {
      listChannel?.unbind('subdomains');
    };
  }, [listChannel, currectClientId, reduxDisaptch, listId]);

  useEffect(() => {
    if (!listChannel) {
      return;
    }
    listChannel.bind('new_subdomain_ids', (data) => {
      const { ids, parent_id, parameters } = data;
      const onFinishLoading = () => {
        reduxDisaptch(
          removeExploreSubdomainsLoading({
            listId,
            parent_subdomain_id: parent_id,
            promptPrefix: parameters?.prompt_prefix
          })
        );
        reduxDisaptch(
          updateTriggredCustomAnalytics({
            listId,
            triggredCustomAnalytics: false,
            parentId: parent_id,
            promptPrefix: parameters?.prompt_prefix
          })
        );
      };
      if (ids && ids.length) {
        reduxDisaptch(loadListSubdomainsAction(listId, ids, true, onFinishLoading));
      } else {
        onFinishLoading();
      }
    });
    return () => {
      listChannel?.unbind('new_subdomain_ids');
    };
  }, [listChannel, currectClientId, reduxDisaptch, listId]);

  useEffect(() => {
    if (!listChannel) {
      return;
    }
    listChannel.bind('deleted_companies', (data) => {
      const { company_list_meta_ids_to_delete } = data;
      if (company_list_meta_ids_to_delete?.length) {
        reduxDisaptch(
          deleteCompaniesFromListByIds({ listId, ids: company_list_meta_ids_to_delete })
        );
      }
    });
    return () => {
      listChannel?.unbind('deleted_companies');
    };
  }, [listChannel, currectClientId, reduxDisaptch, listId]);

  useEffect(() => {
    if (!listChannel) {
      return;
    }
    listChannel.bind('new_view', (data) => {
      const { view_id } = data;
      if (view_id) {
        navigate(`${EXPLORE_PREFIX}/${listId}/views/${view_id}`);
      }
    });
    return () => {
      listChannel?.unbind('new_view');
    };
  }, [listChannel, currectClientId, reduxDisaptch, listId, navigate]);

  useEffect(() => {
    if (!listChannel) {
      return;
    }
    listChannel.bind('supporting_resource_created', (data) => {
      const { supporting_resource_id } = data;
      if (supporting_resource_id) {
        reduxDisaptch(loadExploreSupportingResource(supporting_resource_id, listId));
      }
    });
    return () => {
      listChannel?.unbind('supporting_resource_created');
    };
  }, [listChannel, reduxDisaptch, listId]);

  return null;
}

PusherListListener.propTypes = {
  listId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default PusherListListener;
