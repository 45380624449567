import React from 'react';

export default function QueryIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.09999 5C5.82004 5 5.55155 5.11121 5.3536 5.30917C5.15564 5.50712 5.04443 5.7756 5.04443 6.05556V18.2929L7.30199 16.0353C7.39576 15.9416 7.52294 15.8889 7.65554 15.8889H17.9444C18.2244 15.8889 18.4929 15.7777 18.6908 15.5797C18.8888 15.3818 19 15.1133 19 14.8333V6.05556C19 5.77561 18.8888 5.50712 18.6908 5.30917C18.4929 5.11121 18.2244 5 17.9444 5H6.09999ZM4.64649 4.60206C5.03198 4.21657 5.55482 4 6.09999 4H17.9444C18.4896 4 19.0125 4.21657 19.3979 4.60206C19.7834 4.98755 20 5.51039 20 6.05556V14.8333C20 15.3785 19.7834 15.9013 19.3979 16.2868C19.0125 16.6723 18.4896 16.8889 17.9444 16.8889H7.86265L4.89799 19.8536C4.75499 19.9966 4.53993 20.0393 4.35309 19.9619C4.16625 19.8845 4.04443 19.7022 4.04443 19.5V6.05556C4.04443 5.51039 4.261 4.98755 4.64649 4.60206Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99983 9C7.99983 8.72386 8.22369 8.5 8.49983 8.5H15.9998C16.276 8.5 16.4998 8.72386 16.4998 9C16.4998 9.27614 16.276 9.5 15.9998 9.5H8.49983C8.22369 9.5 7.99983 9.27614 7.99983 9Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99983 12C7.99983 11.7239 8.22369 11.5 8.49983 11.5H13.4998C13.776 11.5 13.9998 11.7239 13.9998 12C13.9998 12.2761 13.776 12.5 13.4998 12.5H8.49983C8.22369 12.5 7.99983 12.2761 7.99983 12Z"
        fill="currentColor"
      />
    </svg>
  );
}
