import { DatadogRumAction } from 'constants/datadog-rum';
import MLIconButton from 'design-system/MLIconButton';
import useMenuManagement from 'design-system/hooks/useMenuManagement';
import ThunderIcon from 'design-system/icons/basic/ThunderIcon';
import React from 'react';

import AssistantActionMenu from '../menus/AssistantActionMenu';

interface AssistantActionButtonProps {
  promptRef: React.RefObject<HTMLDivElement>;
}

export default function AssistantActionButton({ promptRef }: AssistantActionButtonProps) {
  const { onOpen, onClose, open, anchorEl } = useMenuManagement<HTMLDivElement>(promptRef);
  return (
    <React.Fragment>
      <MLIconButton
        tooltipText="Action"
        onClick={onOpen}
        data-dd-action-name={DatadogRumAction.UNIFY_ACTION_MENU}>
        <ThunderIcon />
      </MLIconButton>
      <AssistantActionMenu anchorEl={anchorEl} open={open} handleMenuClose={onClose} />
    </React.Fragment>
  );
}
