import { Stack, Typography } from '@mui/material';
import WorkflowsCenterDialog from 'components/work-flow/WorkflowsCenterDialog';
import { WORKFLOW_SELECTED_TYPE } from 'constants/workflows';
import MLIconButton from 'design-system/MLIconButton';
import { WorkflowIcon } from 'icons/WorkflowIcon';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateSelectedWorkflowIdByListId } from 'reducer/workflows-slice';

function CustomAnalyticsWorkflowMessageComponent({ listId, postId, isWorflowRun, statusMessage }) {
  const [openWorkflow, setOpenWorkflow] = useState(false);
  const dispatch = useDispatch();

  const closeWorkflowDialog = () => {
    dispatch(
      updateSelectedWorkflowIdByListId({
        listId,
        selectedWorkflowId: null,
        selectedType: null
      })
    );
    setOpenWorkflow(false);
  };

  return (
    <React.Fragment>
      <Stack direction="row" alignItems="center" justifyContent="space-between" gap="16px">
        {statusMessage && (
          <Typography variant="paragraphMedium" textAlign="start">
            {statusMessage}
          </Typography>
        )}
        <MLIconButton
          tooltipText="Show Workflow"
          onClick={() => {
            setOpenWorkflow(true);
            dispatch(
              updateSelectedWorkflowIdByListId({
                listId,
                selectedWorkflowId: postId,
                selectedType: WORKFLOW_SELECTED_TYPE.DRAFT
              })
            );
          }}>
          <WorkflowIcon />
        </MLIconButton>
      </Stack>
      <WorkflowsCenterDialog
        listId={listId}
        open={openWorkflow}
        onClose={closeWorkflowDialog}
        isWorflowRun={isWorflowRun}
      />
    </React.Fragment>
  );
}

CustomAnalyticsWorkflowMessageComponent.propTypes = {
  listId: PropTypes.number,
  // can be string or number
  postId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isWorflowRun: PropTypes.bool,
  statusMessage: PropTypes.string
};

export default CustomAnalyticsWorkflowMessageComponent;
