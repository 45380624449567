import { Stack } from '@mui/material';
import AssistantThread from 'models/assistant/AssistantThread';
import React from 'react';
import { getAssistantThreadById, getAssistantUtilitShowLoading } from 'selectors/assistant';
import { useAppSelector } from 'types/store';

import BasicMessageComponent, { AssistantLoadingMessageComponent } from './MessageComponent';

interface AssistantConversationThreadProps {
  threadId: string;
  divider?: boolean;
}

function AssistantConversationThread({ threadId, divider }: AssistantConversationThreadProps) {
  const thread: AssistantThread = useAppSelector((state) =>
    getAssistantThreadById(state, threadId)
  );
  const { messages } = thread;
  const showLoading = useAppSelector(getAssistantUtilitShowLoading);
  return (
    <Stack
      gap="24px"
      textAlign="start"
      sx={{
        borderTop: divider ? '1px solid' : null,
        padding: '16px',
        borderColor: 'colors.ui_border'
      }}>
      {messages.map((message, index) => (
        <BasicMessageComponent key={index} threadId={threadId} message={message} />
      ))}
      {showLoading && <AssistantLoadingMessageComponent />}
    </Stack>
  );
}

export default AssistantConversationThread;
