import { WORKFLOW_STATUSES } from 'constants/workflows';
import { addWorkflowsHistory, updateWorkflowStatusByIdAndListId } from 'reducer/workflows-slice';

export function loadDiscoveryWorkflowsHistoryAction(listId, onLoaded) {
  return (dispatch, _getState, apiClient) => {
    apiClient.companiesLists
      .fetchListRecordsHistory(listId, 11, 'workflow')
      .then((res) => {
        if (res.ok) {
          const { records } = res.data;
          if (records?.length > 0) {
            const history = {};
            records.forEach((workflowRecord) => {
              const workflowId = workflowRecord.parameters.post_id;
              history[workflowId] = workflowRecord;
              let status = WORKFLOW_STATUSES.LAUNCHING;
              if (workflowRecord.status === 'SUCCEEDED') {
                status = WORKFLOW_STATUSES.HISTORY_SUCCEED;
              } else if (workflowRecord.status === 'FAILED') {
                status = WORKFLOW_STATUSES.HISTORY_FAILED;
              }
              dispatch(updateWorkflowStatusByIdAndListId({ listId, workflowId, status }));
            });
            dispatch(addWorkflowsHistory({ listId, history }));
          }
        }
        onLoaded?.();
      })
      .catch(() => onLoaded?.());
  };
}
