/* eslint-disable max-lines-per-function */
import { Stack, Tooltip, Typography } from '@mui/material';
import { useMLTooltip } from 'hooks/useMLTooltip';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import { ToastIcons } from 'icons/ToastIcons';
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import {
  getIsSavedQueryPredefined,
  getSavedQueryIsMine,
  getSavedQueryOwner
} from 'selectors/saved-queries';
import { getUserId } from 'selectors/user';

import CustomAnalyticsPlatterItemTooltip from './CustomAnalyticsPlatterItemTooltip';

interface platterItemTooltipProps {
  isColumnInList: boolean;
  title: string;
  parameters: any;
  id: number;
  children: ReactElement;
}

/* eslint-disable max-lines */
export const PlatterItemTooltip = ({
  isColumnInList,
  title,
  parameters,
  id,
  children
}: platterItemTooltipProps) => {
  const userId = useOldStateSelector(getUserId);
  const resourceOwner = useSelector((state) => getSavedQueryOwner(state, id));
  const isMine = useSelector((state) => getSavedQueryIsMine(state, id, userId));
  const isQueryPredefined = useSelector((state) => getIsSavedQueryPredefined(state, id));
  const { isTooltipVisible } = useMLTooltip();
  if (!isTooltipVisible) return children;

  return (
    <Tooltip
      disableInteractive
      arrow
      slotProps={{
        tooltip: {
          sx: {
            maxWidth: 350
          }
        }
      }}
      title={
        (isColumnInList || parameters?.question) && (
          <Stack padding="4px">
            {isColumnInList && (
              <Stack direction="row" alignItems="center">
                <ToastIcons type="success" contained={false} mainColor="white" />
                <Typography paddingRight="8px" variant="text1_Medium">
                  Already added to the list
                </Typography>
              </Stack>
            )}
            {parameters?.question && (
              <CustomAnalyticsPlatterItemTooltip
                isInList={isColumnInList}
                title={title}
                question={parameters.question}
                isMine={isMine}
                resourceOwner={resourceOwner}
                isPredefined={isQueryPredefined}
              />
            )}
          </Stack>
        )
      }>
      {children}
    </Tooltip>
  );
};
