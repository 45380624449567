import PropTypes from 'prop-types';
import { appTheme } from 'theme';

function WarningIcon({ size, color }) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path d="M12 10V13.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M11.9976 16C12 16 12.0038 16 12.0038 16"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7662 5.18305L4.69338 17.3715C4.56738 17.5861 4.50071 17.8294 4.50001 18.0771C4.4993 18.3249 4.56458 18.5685 4.68936 18.7838C4.81414 18.999 4.99406 19.1784 5.21123 19.3041C5.42839 19.4297 5.67523 19.4973 5.92719 19.5L18.0728 19.5C18.3248 19.4973 18.5716 19.4297 18.7888 19.3041C19.0059 19.1784 19.1859 18.999 19.3106 18.7838C19.4354 18.5685 19.5007 18.3249 19.5 18.0771C19.4993 17.8294 19.4326 17.5861 19.3066 17.3715L13.2338 5.18305C13.1052 4.97454 12.9241 4.80214 12.708 4.6825C12.4918 4.56285 12.248 4.5 12 4.5C11.752 4.5 11.5082 4.56285 11.292 4.6825C11.0759 4.80214 10.8948 4.97454 10.7662 5.18305Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

WarningIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string
};

WarningIcon.defaultProps = {
  size: 24,
  color: appTheme.palette.colors.icon
};

export default WarningIcon;
