import { getDiscoverySuggestionsAction } from 'actions/explore/get-discovery-suggestions-action';
import { PROMPT_PREFIX } from 'constants/custom-analytics';
import {
  DISCOVERY_TYPES_TO_SCHEDULE_DISOVERY_TYPES,
  getDiscoveryTypeTitle
} from 'constants/discovery';
import {
  addPostQueryToThread,
  addPostRecordIdToThread,
  getThreadKey,
  updatePostIdOfDataType
} from 'reducer/custom-analytics';
import { updateWorkflowSubmittedRecordsByIdAndListId } from 'reducer/workflows-slice';
import { getCompanyMetasByListId } from 'selectors/explore';
import { getWorkflowIdByRecordId } from 'selectors/workflows';
import { isEmpty } from 'utils/objects-utils';

export function updateWorkflowSubmittedRecordsAction({
  listId,
  customAnalyticsRecordId,
  recordIds,
  postIds,
  stepId
}) {
  return (dispatch, getState, apiClient) => {
    const state = getState();

    const workflowId =
      customAnalyticsRecordId && getWorkflowIdByRecordId(state, listId, customAnalyticsRecordId);

    const threadKey = getThreadKey(listId);
    if (!isEmpty(postIds)) {
      const companyMetas = getCompanyMetasByListId(state, listId);
      const total_num_companies = companyMetas?.length;
      const addColumnMessages = companyMetas?.map((company) => ({
        company_meta_id: company.company_meta_id,
        loading: true
      }));

      postIds.forEach(async (postId) => {
        try {
          const res = await apiClient.runs.getCustomAnalyticsRecordByPostId(postId);
          if (!res?.data) {
            return;
          }
          const postRecord = res.data;
          const post = postRecord.parameters;

          const isForEach =
            post?.question?.includes(`{{${PROMPT_PREFIX.FOR_EACH_COMPANY.prefix}}}`) ||
            post.for_each_company;

          let messages = null;
          const body = {
            ...post,
            hidePost: Boolean(workflowId),
            timeSubmitted: postRecord.time_submitted
          };
          if (isForEach) {
            messages = addColumnMessages;
            body.total_num_companies = total_num_companies;
          }
          if (post.company_extra_data_type) {
            dispatch(updatePostIdOfDataType({ postId, dataType: post.company_extra_data_type }));
          }

          dispatch(
            addPostQueryToThread({
              threadKey,
              postId,
              query: post.question,
              messages,
              body
            })
          );
          dispatch(
            addPostRecordIdToThread({ threadKey, postId, customAnalyticsRecordId: postRecord.id })
          );
        } catch {
          dispatch(
            addPostQueryToThread({
              threadKey,
              postId,
              query: null
            })
          );
        }
      });
    }

    if (!isEmpty(recordIds) && !workflowId) {
      recordIds.forEach((recordId) => {
        const postId = new Date().getTime();
        apiClient.runs.fetchRun(recordId).then((res) => {
          if (res?.data) {
            const record = res.data;
            const discoverMethod = record.parameters.discovery_type;
            const discoveryTypeTitle = getDiscoveryTypeTitle(discoverMethod);
            if (discoveryTypeTitle) {
              const query = `${PROMPT_PREFIX.DISCOVER.title} by ${discoveryTypeTitle}`;
              const body = {
                question: query,
                discoverMethod
              };
              dispatch(
                addPostQueryToThread({ threadKey, postId, query, body, discoveryId: recordId })
              );
              if (discoverMethod === DISCOVERY_TYPES_TO_SCHEDULE_DISOVERY_TYPES.TEXT) {
                dispatch(
                  getDiscoverySuggestionsAction(
                    threadKey,
                    postId,
                    record?.parameters?.user_search_term
                  )
                );
              }
            }
          }
        });
      });
    }

    if (workflowId) {
      dispatch(
        updateWorkflowSubmittedRecordsByIdAndListId({
          listId,
          workflowId,
          postIds,
          recordIds,
          stepId
        })
      );
    }
  };
}
