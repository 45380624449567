import { Stack, Typography } from '@mui/material';
import { ExtendedEditor } from 'models/assistant/tiptap';
import { MentionItem } from 'models/assistant/types';
import React, { forwardRef } from 'react';

import MentionListAutocomplete from './MentionListAutocomplete';
import MentionListDefault from './MentionListDefault';
import MentionListNoResults from './MentionListNoResults';

export interface MentionListBaseProps {
  items: MentionItem[];
  command: (item: { id: string; label: string }) => void;
}
interface MentionListProps extends MentionListBaseProps {
  editor: ExtendedEditor;
}

export interface MentionListHandle {
  onKeyDown: ({ event }: { event: KeyboardEvent }) => boolean;
}

const MentionList = forwardRef<MentionListHandle, MentionListProps>(
  ({ items, command, editor }, ref) => {
    const showDefault =
      items.length && items.length === editor.storage.MentionStorage.suggestions.length;
    let Component = MentionListNoResults;
    if (showDefault) {
      Component = MentionListDefault;
    } else if (items.length) {
      Component = MentionListAutocomplete;
    }
    return (
      <Stack
        sx={{
          width: showDefault ? '218px' : '318px',
          maxHeight: '256px',
          borderRadius: '8px',
          backgroundColor: 'colors.primary_bg',
          padding: '8px',
          boxShadow: '0px 2px 7px 0px rgba(66, 82, 110, 0.41)'
        }}>
        <Typography color="colors.secondary_text" sx={{ padding: '8px 12px' }}>
          Use context
        </Typography>
        <Component items={items} command={command} ref={ref} />
      </Stack>
    );
  }
);

MentionList.displayName = 'MentionList';

export default MentionList;
