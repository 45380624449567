import React from 'react';

export default function ArrowLeftIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.14645 17.3536C9.34171 17.5488 9.65829 17.5488 9.85355 17.3536C10.0488 17.1583 10.0488 16.8417 9.85355 16.6464L5.70711 12.5L19.5 12.5C19.7761 12.5 20 12.2761 20 12C20 11.7239 19.7761 11.5 19.5 11.5L5.70711 11.5L9.85355 7.35355C10.0488 7.15829 10.0488 6.84171 9.85355 6.64645C9.65829 6.45118 9.34171 6.45118 9.14645 6.64645L4.14645 11.6464C4.05268 11.7402 4 11.8674 4 12C4 12.1326 4.05268 12.2598 4.14645 12.3536L9.14645 17.3536Z"
        fill="currentColor"
      />
    </svg>
  );
}
