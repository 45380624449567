import { Button, CircularProgress } from '@mui/material';
import MLInlineStack from 'design-system/MLInlineStack';
import PropTypes from 'prop-types';
import React from 'react';

function LaunchWorkflowButton({ isRunning, handleSubmitPlan }) {
  const notRunningTitle = 'Launch workflow';
  return (
    <Button
      disabled={isRunning}
      sx={{
        pointerEvents: isRunning ? 'none' : 'auto',
        whiteSpace: 'nowrap',
        cursor: isRunning ? 'not-allowed' : 'pointer',
        '&.Mui-disabled': {
          backgroundColor: 'colors.primary',
          color: 'white',
          opacity: 1
        }
      }}
      variant="contained"
      onClick={handleSubmitPlan}>
      <MLInlineStack>
        {isRunning && (
          <CircularProgress
            sx={{
              '& .MuiCircularProgress-circle': {
                animationDuration: '5s'
              }
            }}
            color="white"
            size={16}
          />
        )}
        {isRunning ? 'Running workflow...' : notRunningTitle}
      </MLInlineStack>
    </Button>
  );
}

LaunchWorkflowButton.propTypes = {
  isRunning: PropTypes.bool.isRequired,
  handleSubmitPlan: PropTypes.func.isRequired
};

export default LaunchWorkflowButton;
