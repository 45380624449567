import { TOAST_TYPES } from 'constants/toasts';
import { updateSavedQuery } from 'reducer/saved-queries-slice';

import { loadSavedQueriesAction } from './load-saved-queries-action';

export function updateSavedQueryAction(savedQueryId, data, addToast, onUpdateQuery) {
  return (dispatch, _getState, apiClient) => {
    dispatch(updateSavedQuery({ savedQueryId, data }));
    apiClient.saved_queries
      .updateSavedQuery(savedQueryId, data)
      .then((res) => {
        if (res.ok) {
          onUpdateQuery?.();
        }
        addToast(
          res.ok ? 'Updated query' : 'Failed to update query',
          res.ok ? TOAST_TYPES.SUCCESS : TOAST_TYPES.ERROR
        );
      })
      .catch(() => {
        dispatch(loadSavedQueriesAction());
        addToast('Failed to update query', TOAST_TYPES.ERROR);
      });
  };
}
