import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Checkbox, Stack, Typography } from '@mui/material';
// import DragIcon from 'design-system/icons/basic/DragIcon';
import PropTypes from 'prop-types';
import React from 'react';

function ManageColumnsItem({ column, isChecked, updateColumnVisibilty }) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    // change id_tmp to id inorder to allow drag&drop
    id_tmp: column.id
  });

  return (
    <Stack
      direction="row"
      alignItems="center"
      ref={setNodeRef}
      sx={{
        transform: CSS.Transform.toString(transform),
        transition,
        color: 'colors.primary_text'
      }}
      {...listeners}
      {...attributes}>
      {/* <DragIcon /> */}
      <Checkbox
        size="small"
        checked={isChecked}
        onChange={(event) => updateColumnVisibilty(column, event.target.checked)}
      />
      <Typography variant="text1_Normal" color="colors.primary_text">
        {column.title}
      </Typography>
    </Stack>
  );
}

ManageColumnsItem.propTypes = {
  column: PropTypes.string,
  isChecked: PropTypes.bool,
  updateColumnVisibilty: PropTypes.func
};

export default ManageColumnsItem;
