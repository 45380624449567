import { datadogRum } from '@datadog/browser-rum';
import { DatadogRumAction } from 'constants/datadog-rum';
import { getAssistantThreadById } from 'selectors/assistant';
import { AppDispatch, getAppState } from 'types/store';

import { submitAssistantHelperAction } from './submit-assistant-helper-action';

export function submitAssistantActionAction(threadId: string, actionId: string) {
  return (dispatch: AppDispatch, getState: getAppState) => {
    const state = getState();
    const thread = getAssistantThreadById(state, threadId);
    const payload = {
      ...thread,
      action: thread.actions[actionId]
    };
    datadogRum.addAction(DatadogRumAction.UNIFY_CONFIRM_ACTION, {
      listId: thread.company_list_id,
      action: thread.actions[actionId]
    });
    dispatch(submitAssistantHelperAction(thread.id, payload));
  };
}
