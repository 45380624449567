import React from 'react';

import './MLLoadingDotsAnimation.css';

const MLLoadingDotsAnimation = () => {
  return (
    // <div className="chat-bubble">
    <div className="typing">
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
    </div>
    // </div>
  );
};

export default MLLoadingDotsAnimation;
