import React from 'react';

function DependenciesIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M4.5 5H9.2C9.94261 5 10.6548 5.32778 11.1799 5.91122C11.705 6.49467 12 7.28599 12 8.11111V19.5C12 18.8812 11.7788 18.7877 11.3849 18.3501C10.9911 17.9125 10.457 17.6667 9.9 17.6667H4.5L4.5 5Z"
        stroke="#5C5E76"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 5H14.8C14.0574 5 13.3452 5.32778 12.8201 5.91122C12.295 6.49467 12 7.28599 12 8.11111V19.5C12 18.8812 12.2212 18.621 12.6151 18.1834C13.0089 17.7458 13.543 17.5 14.1 17.5H19.5V5Z"
        stroke="#5C5E76"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M14.5 9.5H17" stroke="#5C5E76" strokeLinecap="round" />
      <path d="M7 9.5H9.5" stroke="#5C5E76" strokeLinecap="round" />
      <path d="M14.5 12.5H17" stroke="#5C5E76" strokeLinecap="round" />
      <path d="M7 12.5H9.5" stroke="#5C5E76" strokeLinecap="round" />
    </svg>
  );
}

export default DependenciesIcon;
