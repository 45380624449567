import {
  ACTION_TYPES_WITH_ANSWER_TYPE,
  ACTION_TYPES_WITH_SCOPING,
  ACTION_TYPES_WITH_SEARCH_SETTINGS
} from 'constants/assistant';
import { AnswerType } from 'utils/custom-analytics-utils';
import { v4 as uuid4 } from 'uuid';

import { ActionType } from './assistant-actions';
import { Message, MessageRole, Scoping, SearchSettings, UserMessageContent } from './types';

type PositiveValidity = {
  isValid: true;
};

type NegativeValidity = {
  isValid: false;
  message: string;
};

type Validity = PositiveValidity | NegativeValidity;

export interface AssistantPromptConfigJSON {
  action_type: ActionType | null;
  saved_query_id: number | null;
  text: string;
  answer_type: AnswerType | null;
  scoping: Scoping | null;
  search_settings: SearchSettings | null;
  overwrite: boolean;
}

class AssistantPromptConfig {
  action_type: ActionType | null;
  saved_query_id: number | null;
  text: string;
  search_settings: SearchSettings | null;
  answer_type: AnswerType | null;
  scoping: Scoping | null;
  overwrite: boolean;

  constructor(params: Partial<AssistantPromptConfigJSON> = {}) {
    this.action_type = null;
    this.saved_query_id = null;
    this.text = '';
    this.search_settings = null;
    this.answer_type = null;
    this.overwrite = false;
    this.scoping = null;
    Object.assign(this, params);
    this.ensureValidity();
  }

  private ensureValidity() {
    if (ACTION_TYPES_WITH_SCOPING.includes(this.action_type as ActionType)) {
      this.scoping = this.scoping || {};
    } else {
      this.scoping = null;
    }
    if (ACTION_TYPES_WITH_SEARCH_SETTINGS.includes(this.action_type as ActionType)) {
      this.search_settings = this.search_settings || { use_web: true, use_table: true };
    } else {
      this.search_settings = null;
    }
    if (ACTION_TYPES_WITH_ANSWER_TYPE.includes(this.action_type as ActionType)) {
      this.answer_type = this.answer_type || AnswerType.TEXT;
    } else {
      this.answer_type = null;
    }
  }

  // Method to update the text
  updateQuestion(newQuestion: string) {
    this.text = newQuestion;
  }

  // Method to disable overwriting
  disableOverwrite() {
    this.overwrite = false;
  }

  private toUserMessageContent(): UserMessageContent {
    return {
      action_type: this.action_type,
      saved_query_id: this.saved_query_id,
      text: this.text,
      search_settings: this.search_settings,
      answer_type: this.answer_type,
      scoping: this.scoping
    };
  }

  toggleUseWeb() {
    if (this.search_settings && this.search_settings.use_table) {
      this.search_settings.use_web = !this.search_settings.use_web;
    }
  }

  toggleUseTable() {
    if (this.search_settings && this.search_settings.use_web) {
      this.search_settings.use_table = !this.search_settings.use_table;
    }
  }

  updateSearchSettings(search_settings: SearchSettings) {
    if (
      this.action_type &&
      ACTION_TYPES_WITH_SEARCH_SETTINGS.includes(this.action_type) &&
      (search_settings.use_web || search_settings.use_table)
    ) {
      this.search_settings = search_settings;
    }
  }

  isValid(): this is AssistantPromptConfigJSON {
    if (this.search_settings && !this.search_settings.use_web && !this.search_settings.use_table) {
      return false;
    }
    if (ACTION_TYPES_WITH_SCOPING.includes(this.action_type as ActionType) && !this.scoping) {
      return false;
    }
    if (
      ACTION_TYPES_WITH_SEARCH_SETTINGS.includes(this.action_type as ActionType) &&
      !this.search_settings
    ) {
      return false;
    }
    return true;
  }

  checkSubmissionValidity(): Validity {
    if (!this.text || this.text.trim() === '') {
      return { isValid: false, message: 'Please select at least one search setting' };
    }
    if (this.isValid()) {
      return { isValid: true };
    }
    return { isValid: false, message: 'System error' };
  }

  // Method to convert the AssistantPromptConfig object to a Message object
  // ready for backend
  toMessage(): Message {
    return {
      id: uuid4(),
      role: MessageRole.USER,
      content: this.toUserMessageContent()
    };
  }

  // Method to convert the AssistantPromptConfig object to JSON for Redux
  toJSON(): AssistantPromptConfigJSON {
    return { ...this };
  }
}

export default AssistantPromptConfig;
