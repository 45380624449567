import { pusherBindToCARecordId } from 'actions/custom-analytics/create-new-post-in-custom-analytics-thread-async-action';
import { CHAT_GPT_MODEL_VERSION } from 'constants/custom-analytics';
import { WORKFLOW_SELECTED_TYPE, WORKFLOW_STATUSES } from 'constants/workflows';
import {
  addPostAnswerToThread,
  addPostQueryToThread,
  addPostRecordIdToThread,
  deletePost,
  getThreadKey,
  updateThreadProccessingMode
} from 'reducer/custom-analytics';
import {
  updateSelectedWorkflowIdByListId,
  updateWorkflowFormModeByIdAndListId,
  updateWorkflowStatusByIdAndListId
} from 'reducer/workflows-slice';
import { getPostNameByThreadIdAndPostId } from 'selectors/custom-analytics';
import { getWorkflowSelectedPostIdByListId } from 'selectors/workflows';

export function generateWorkflowAction(listId, mainPrompt, isRegenerate) {
  return (dispatch, getState, apiClient) => {
    const state = getState();
    const selectedWorkflowId = getWorkflowSelectedPostIdByListId(state, listId);
    const postId = isRegenerate ? new Date().getTime() : selectedWorkflowId;
    const selectedWorkflowName = getPostNameByThreadIdAndPostId(
      state,
      getThreadKey(listId),
      selectedWorkflowId
    );

    const threadKey = getThreadKey(listId);
    const body = {
      question: mainPrompt,
      company_list_id: listId,
      post_id: postId,
      model: CHAT_GPT_MODEL_VERSION,
      action: 'prepare_workflow',
      hidePost: true
    };
    dispatch(updateWorkflowFormModeByIdAndListId({ listId, workflowId: postId, isFormMode: true }));
    dispatch(
      addPostQueryToThread({
        threadKey,
        postId,
        query: mainPrompt,
        body,
        name: selectedWorkflowName
      })
    );
    dispatch(
      updateWorkflowStatusByIdAndListId({
        listId,
        workflowId: postId,
        status: WORKFLOW_STATUSES.GENERATING
      })
    );
    if (isRegenerate) {
      dispatch(deletePost({ threadKey, postId: selectedWorkflowId }));
    }

    apiClient.customAnalytics
      .queryCustomAnalyticsAssistantAsync(body)
      .then((res) => {
        if (res.ok) {
          const customAnalyticsRecordId = res.data.record_id;
          dispatch(
            addPostRecordIdToThread({
              threadKey,
              postId,
              customAnalyticsRecordId
            })
          );
          dispatch(
            pusherBindToCARecordId({
              listId,
              recordId: customAnalyticsRecordId,
              threadKey,
              postId,
              isWorkFlowPlan: true
            })
          );
        } else {
          dispatch(
            addPostAnswerToThread({
              threadKey,
              postId,
              message: { answer: 'Something went wrong, please try again' },
              currentStepId: 'end'
            })
          );
        }
        dispatch(
          updateWorkflowStatusByIdAndListId({
            listId,
            workflowId: postId,
            status: res.ok ? WORKFLOW_STATUSES.GENERATED : WORKFLOW_STATUSES.GENERATE_FAILED
          })
        );
        dispatch(updateThreadProccessingMode({ threadKey, isProccessing: false }));
      })
      .catch(() => {
        dispatch(
          addPostAnswerToThread({
            threadKey,
            postId,
            message: { answer: 'Something went wrong, please try again' },
            currentStepId: 'end'
          })
        );
        dispatch(
          updateWorkflowStatusByIdAndListId({
            listId,
            workflowId: postId,
            status: WORKFLOW_STATUSES.GENERATE_FAILED
          })
        );
        dispatch(updateThreadProccessingMode({ threadKey, isProccessing: false }));
      });

    if (isRegenerate) {
      dispatch(
        updateSelectedWorkflowIdByListId({
          listId,
          selectedWorkflowId: postId,
          selectedType: WORKFLOW_SELECTED_TYPE.DRAFT
        })
      );
    }
  };
}
