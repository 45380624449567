import { Box, Paper, Popper, Stack, Typography } from '@mui/material';
import {
  SUGGESTION_ID_TYPE_TO_HOVER_BG_COLOR,
  SUGGESTION_ID_TYPE_TO_ICON,
  suggestionIdTypeToSx
} from 'constants/assistant';
import MLIcon from 'design-system/MLIcon';
import ArrowIcon from 'icons/ArrowIcon';
import { MentionId, MentionItem, SuggestionIdType } from 'models/assistant/types';
import React, { useEffect, useRef, useState } from 'react';

import MentionListItem from './MentionListItem';

interface MentionListCategoryItemProps {
  id: MentionId;
  label: string;
  focused: boolean;
  focusedColor?: string;
  selectedItemIndex: number | null;
  filteredItems: MentionItem[];
  selectItem: (index: number) => void;
}

function MentionListCategoryItem({
  id,
  label,
  focused,
  focusedColor,
  filteredItems,
  selectedItemIndex,
  selectItem
}: MentionListCategoryItemProps) {
  // Choose the icon based on the suggestion's category.
  const [hover, setHover] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [suggestionIdType] = id.split(':') as [SuggestionIdType];
  const sx = suggestionIdTypeToSx(suggestionIdType);
  const bgColor =
    focused || hover
      ? focusedColor || SUGGESTION_ID_TYPE_TO_HOVER_BG_COLOR[suggestionIdType]
      : null;
  const selectedBoxRef = useRef<HTMLDivElement>(null);

  // Keep active item in view
  useEffect(() => {
    if (selectedBoxRef.current) {
      selectedBoxRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest'
      });
    }
  }, [selectedItemIndex]);

  return (
    <Stack
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      gap="8px"
      direction="row"
      alignItems="center"
      sx={{
        padding: '8px 12px',
        borderRadius: '4px',
        backgroundColor: bgColor
      }}>
      <MLIcon
        icon={React.createElement(SUGGESTION_ID_TYPE_TO_ICON[suggestionIdType])}
        size="small"
        sx={sx}
      />
      <Typography variant="text1_Normal">{label}</Typography>
      <Box ref={anchorRef} sx={{ marginLeft: 'auto', width: '24px', height: '24px' }}>
        <ArrowIcon rotation={90} />
      </Box>
      <Popper
        open={focused}
        anchorEl={anchorRef.current}
        placement="right-start"
        sx={{ zIndex: 10000 }}>
        <Paper
          sx={{
            padding: '8px',
            maxWidth: '318px',
            maxHeight: '256px',
            overflowY: 'auto',
            boxShadow: 'box-shadow: 0px 2px 7px 0px rgba(66, 82, 110, 0.41)'
          }}>
          <Stack>
            {filteredItems.map(({ id: id2, label: label2 }, index) => (
              <Box
                ref={selectedItemIndex === index ? selectedBoxRef : null}
                key={index}
                sx={{ cursor: 'pointer' }}
                onClick={() => selectItem(index)}>
                <MentionListItem id={id2} focused={index === selectedItemIndex} label={label2} />
              </Box>
            ))}
          </Stack>
        </Paper>
      </Popper>
    </Stack>
  );
}

export default MentionListCategoryItem;
