import { getExploreTableSelectedCompanies } from 'selectors/explore-table-ui';

export function fillCellsForSelectedCompaniesAction(listId, recompute) {
  return (_dispatch, getState, apiClient) => {
    const selectedCompnaies = getExploreTableSelectedCompanies(getState(), listId);
    const companiesMetaIds = selectedCompnaies.map((companyMeta) => companyMeta.companyMetaId);
    const postId = new Date().getTime();
    apiClient.companyExtraData.fillCompaniesRows(listId, companiesMetaIds, recompute, postId);
  };
}
