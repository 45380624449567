import { createSelector } from 'reselect';

export const getSavedQueriesSlice = (state) => state.savedQueries;
export const getAllSavedQueries = (state) => getSavedQueriesSlice(state)?.savedQueries;
export const getSavedQueryById = (state, id) => getAllSavedQueries(state)?.[id];

export const getSavedQueryIsShared = (state, savedQueryId) =>
  getSavedQueryById(state, savedQueryId)?.isShared;
export const getSavedQueryOwner = (state, savedQueryId) =>
  getSavedQueryById(state, savedQueryId)?.owner;
export const getSavedQueryCollaboratorsUsers = (state, savedQueryId) =>
  getSavedQueryById(state, savedQueryId)?.collaboratorsUsers;
export const getSavedQueryCollaboratorsOrgs = (state, savedQueryId) =>
  getSavedQueryById(state, savedQueryId)?.collaboratorsOrgs;
export const getSavedQueryCollaboratorsTeams = (state, savedQueryId) =>
  getSavedQueryById(state, savedQueryId)?.collaboratorsTeams;
export const getSavedQueryIsMine = (state, savedQueryId, userId) => {
  if (!userId) {
    return false;
  }
  return getSavedQueryOwner(state, savedQueryId)?.id === userId;
};

export const getIsSavedQueryPredefined = (state, savedQueryId) => {
  return getSavedQueryById(state, savedQueryId)?.isPredefined;
};

// recieve state, id
export const getSavedQueryCollaborateInfo = createSelector(
  getSavedQueryCollaboratorsUsers,
  getSavedQueryCollaboratorsOrgs,
  getSavedQueryCollaboratorsTeams,
  getSavedQueryOwner,
  (resourceUsers, resourceOrgs, resourceTeams, resourceOwner) => ({
    resourceUsers,
    resourceOrgs,
    resourceTeams,
    resourceOwner
  })
);
