import { Avatar, Chip, Divider, Stack, Tooltip, Typography } from '@mui/material';
import BarChartComponent from 'components/elements/bar/BarChartComponent';
import GoToLinkIcon from 'icons/GoToLinkIcon';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { appTheme } from 'theme';
import {
  CONSENSUS_KEY,
  formatStrRevenueFromData,
  getConsensusFromData,
  getIsEstimatedFromData,
  revenueCategoryFromData
} from 'utils/finance-utils';
import { barDataFromRevenuesData } from 'utils/graph-utils';
import { underscoreToTitle } from 'utils/string-utils';

import QuickViewBox from './QuickViewBox';
import QuickViewFieldValue from './QuickViewFieldValue';

const barColor = appTheme.vars.palette.graphColors.graph10;
const transparentBarColor = '#E0CEE4';

const renderInvestors = (investors) => {
  if (!investors) return null;
  return (
    <Stack direction="row" gap="8px" sx={{ flexWrap: 'wrap' }}>
      {investors.map((investor, index) => (
        <Chip
          key={index}
          size="small"
          label={investor.name}
          sx={{ height: '24px' }}
          avatar={<Avatar src={investor.logo_url} variant="rounded" />}
        />
      ))}
    </Stack>
  );
};

function QuickViewFinancials({ row, revenuesData }) {
  const { revenues: revenuesOuter, lead_investors, last_equity_round_message, total_funding } = row;
  const { data: revenues } = revenuesOuter || {};
  const isEstimated = getIsEstimatedFromData(revenues);
  const consensus = getConsensusFromData(revenues);

  const finalValue = useMemo(() => {
    return formatStrRevenueFromData(consensus);
  }, [consensus]);

  const targetCategory = revenueCategoryFromData(revenues);
  const barData = barDataFromRevenuesData(revenuesData);
  const barColors =
    barData &&
    Object.keys(barData).reduce((acc, label) => {
      const color =
        targetCategory && barData[label].index === targetCategory.index
          ? barColor
          : transparentBarColor;
      acc[label] = color;
      return acc;
    }, {});

  const revenuesToDisplay =
    revenues &&
    revenues.length > 0 &&
    revenues.filter(
      (source) =>
        source && source.source_name !== CONSENSUS_KEY && source.is_estimated === isEstimated
    );
  return (
    <QuickViewBox title="Financials">
      <Stack direction="row" width="100%" minHeight="330px" gap="24px">
        <Stack minWidth="256px" maxWidth="256px" gap="24px">
          <Stack gap="12px">
            <Typography variant="text1_Semibold">Revenue by sources</Typography>
            {revenuesToDisplay ? (
              revenuesToDisplay.map((source, index) => (
                <QuickViewFieldValue
                  key={index}
                  direction="row"
                  endAdornment={<GoToLinkIcon />}
                  field={underscoreToTitle(source.source_name)}
                  value={formatStrRevenueFromData(source)}
                  href={source.link_to_source}
                />
              ))
            ) : (
              <Typography variant="text1_Normal">Unkown</Typography>
            )}
          </Stack>
          <QuickViewFieldValue
            gap="4px"
            direction="column"
            field="Total funding"
            value={total_funding}
          />
          {lead_investors && (
            <QuickViewFieldValue
              gap="8px"
              direction="column"
              field="Lead investors"
              value={lead_investors}
              renderVal={renderInvestors}
            />
          )}
          <QuickViewFieldValue
            direction="column"
            gap="4px"
            field="Funding details"
            value={last_equity_round_message}
          />
        </Stack>
        <Divider orientation="vertical" flexItem />
        <Stack width="100%" gap="0px">
          <Stack direction="row" justifyContent="space-between" width="570px" paddingRight="24px">
            <Stack>
              <Typography variant="text1_Bold">Revenue relative to list</Typography>
              {targetCategory ? (
                <Typography variant="text1_Normal">
                  {row.company_name.name} is part of the <strong>{targetCategory.label}</strong>{' '}
                  revenue group
                </Typography>
              ) : (
                <Typography variant="text1_Normal">
                  {row.company_name.name} has no revenue information
                </Typography>
              )}
            </Stack>
            <Stack direction="row" alignItems="center" gap="4px">
              <Typography variant="h2_Bold" noWrap display="block">
                {finalValue}
              </Typography>
              {consensus && (
                <Tooltip title={isEstimated ? 'Estimated' : 'Actual'} arrow>
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      width: '23px',
                      height: '27px',
                      backgroundColor: 'colors.hover_on_gray_bg',
                      borderRadius: '4px',
                      padding: '6px'
                    }}>
                    <Typography color="colors.primary_text" variant="h3_Bold">
                      {isEstimated ? 'E' : 'A'}
                    </Typography>
                  </Stack>
                </Tooltip>
              )}
            </Stack>
          </Stack>
          <Stack width="645px" marginLeft="-40px">
            <BarChartComponent
              labels={Object.keys(barData)}
              values={Object.values(barData).map((item) => item.value)}
              colors={barColors}
              noXLabel
              yAxisName="Company count"
            />
          </Stack>
        </Stack>
      </Stack>
    </QuickViewBox>
  );
}

QuickViewFinancials.propTypes = {
  row: PropTypes.object,
  revenuesData: PropTypes.array
};

export default QuickViewFinancials;
