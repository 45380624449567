export function getFileSizeString(fileSizeInBytes) {
  let index = -1;
  let size = fileSizeInBytes;
  const byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
  do {
    size /= 1024;
    index++;
  } while (size > 1024);

  return Math.max(size, 0.1).toFixed(1) + byteUnits[index];
}

export function getDocumentFileName(value, postfix = '') {
  return `${value?.replace(/ /gu, '_') || 'untitled'}_${postfix}`;
}
