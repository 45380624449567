import { FINANCE_COLUMN_TYPES, FINANCE_TYPES_TO_TITLE } from 'constants/finance';
import { isEmpty } from 'lodash';

export function formatNumberToMil(number) {
  if (!number) return null;
  const numInMil = Math.round(number / 1e6);
  return numInMil.toLocaleString();
}

export function formatNumberToBil(number) {
  if (!number) return null;
  const numInMil = Math.round(number / 1e9);
  return numInMil.toLocaleString();
}

export function formatNumberToK(number) {
  if (!number) return null;
  const numInMil = Math.round(number / 1e3);
  return numInMil.toLocaleString();
}

export function formatNumberTo10k(number) {
  if (!number) return null;
  const numIn10k = Math.round(number / 1e4) * 10;
  return numIn10k.toLocaleString();
}

export const CONSENSUS_KEY = 'consensus';

export const getConsensusFromData = (data) => {
  if (!data?.length) return null;
  return data?.find((revenues) => revenues.source_name === CONSENSUS_KEY);
};

export function getIsEstimatedFromData(data) {
  return getConsensusFromData(data)?.is_estimated;
}

export function formatFinanceStrFromData(consensus) {
  if (consensus?.clean_value_amount) {
    const numInMil = formatNumberToMil(consensus?.clean_value_amount);
    if (numInMil === '0') {
      return '<1';
    }
    return numInMil;
  }
  if (consensus?.value_range_lower) {
    return `>${formatNumberToMil(consensus?.value_range_lower)}`;
  }
  if (consensus?.value_range_upper) {
    return `<${formatNumberToMil(consensus?.value_range_upper)}`;
  }
  return null;
}

function formatStrRevenueFromNumber(revenue) {
  if (revenue) {
    const numInBil = formatNumberToBil(revenue);
    if (numInBil === '0') {
      const numInMil = formatNumberToMil(revenue);
      if (numInMil === '0') {
        const numInK = formatNumberToK(revenue);
        if (numInK === '0') {
          return '<$1K';
        }
        return `$${numInK}K`;
      }
      return `$${numInMil}M`;
    }
    return `$${numInBil}B`;
  }
  return null;
}

export function formatStrRevenueFromData(data) {
  if (!data) return null;
  const { clean_value_amount, value_range_lower, value_range_upper } = data;
  if (!clean_value_amount && !value_range_lower && !value_range_upper) return null;
  const formattedRevenue = formatStrRevenueFromNumber(
    clean_value_amount || value_range_lower || value_range_upper
  );
  if (clean_value_amount) {
    return formattedRevenue;
  }
  if (value_range_lower) {
    return `> ${formattedRevenue}`;
  }
  return `< ${formattedRevenue}`;
}

export function toFixedIfNeeded(num) {
  const zerosPattern = /[.,]00$/u;
  return num.toFixed(2).replace(zerosPattern, '');
}

export function formatNumber(number) {
  if (!number) return null;

  if (Math.abs(number) >= 1e9) {
    return `${toFixedIfNeeded(number / 1e9)}B`;
  } else if (Math.abs(number) >= 1e6) {
    return `${toFixedIfNeeded(number / 1e6)}M`;
  } else if (Math.abs(number) >= 1e3) {
    return `${toFixedIfNeeded(number / 1e3)}K`;
  }

  return toFixedIfNeeded(number);
}

export function formatRevenueForPeers(revenue) {
  if (revenue) {
    const numInMil = formatNumberToMil(revenue);
    if (numInMil === '0') {
      return '<1';
    }
    return numInMil;
  }
  return null;
}

export function formatRevenueForPeersPerPerson(revenue) {
  if (revenue) {
    const numIn10k = formatNumberTo10k(revenue);
    if (numIn10k === '0') {
      return '<1';
    }
    return numIn10k;
  }
  return null;
}

export function revenueFromData(data) {
  if (!data) return null;
  const consensus = getConsensusFromData(data);
  if (!consensus) return null;
  const { clean_value_amount, value_range_lower, value_range_upper } = consensus;
  const revenue = clean_value_amount || value_range_lower || value_range_upper;
  if (!revenue) return null;
  return revenue;
}

export function revenueCategoryFromData(data) {
  const revenue = revenueFromData(data);
  if (!revenue) return null;
  const power = Math.floor(Math.log10(revenue));
  let lower = 10 ** power;
  let upper = 10 ** (power + 1);
  let label = `${formatStrRevenueFromNumber(lower)}-${formatStrRevenueFromNumber(upper)}`;
  if (power < 6) {
    lower = 0;
    upper = 1e6;
    label = '<$1M';
  } else if (power > 8) {
    lower = 1e9;
    upper = 1e15;
    label = '>$1B';
  }
  return {
    index: power,
    range: [lower, upper],
    label
  };
}

export function revenueCategoryStrFromData(data) {
  const category = revenueCategoryFromData(data);
  if (!category) return null;
  const [lower, upper] = category;
  return `${formatStrRevenueFromNumber(lower)}-${formatStrRevenueFromNumber(upper)}`;
}

export function parseFinanceSourceValue(sourceData) {
  let sourceStr = null;
  if (sourceData) {
    const range_lower = sourceData.value_range_lower;
    const range_upper = sourceData.value_range_upper;
    if (range_lower && range_upper) {
      sourceStr = `$${formatNumber(range_lower)}-$${formatNumber(range_upper)}`;
    } else if (range_lower) {
      sourceStr = `$>${formatNumber(range_lower)}`;
    } else if (range_upper) {
      sourceStr = `$<${formatNumber(range_upper)}`;
    } else if (sourceData.clean_value_amount) {
      sourceStr = `$${formatNumber(sourceData.clean_value_amount)}`;
    }
  }
  return sourceStr;
}

export const getTitleFromFinanceType = (financeType) =>
  financeType &&
  (FINANCE_TYPES_TO_TITLE?.[financeType] ||
    financeType
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      ?.join(' '));

export const getOrderedFinanceDataTypes = (items) => {
  return Object.keys(items)
    .filter((source) => source !== FINANCE_COLUMN_TYPES.REVENUES && !isEmpty(items[source]))
    .sort(
      (f1, f2) =>
        Object.values(FINANCE_COLUMN_TYPES).indexOf(f1) -
        Object.values(FINANCE_COLUMN_TYPES).indexOf(f2)
    );
};
