import { Checkbox, Divider, Stack, TextField, Typography, useTheme } from '@mui/material';
import { useAppUtils } from 'AppUtilsProvider';
import { submitDomainReportSection } from 'actions/explore/submit-domain-report-section-action';
import { useDomainReportContext } from 'components/domain-report/DomainReportContext';
import MLButton from 'design-system/MLButton';
import MLInlineStack from 'design-system/MLInlineStack';
import MLMultiselect from 'design-system/MLMultiselect';
import { DomainReportSectionType } from 'models/domain-report/types';
import React, { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { getListDomainKnowledgeByListIdAndType } from 'selectors/explore';
import { useAppDispatch } from 'types/store';

import DomainReportEditorExpandableText from '../edit/DomainReportEditorExpandableText';
import DomainReportEditorKeyValue from '../edit/DomainReportEditorKeyValue';

interface DomainReportCreateSectionViewProps {
  type: DomainReportSectionType;
  resetView: () => void;
}

export default function DomainReportCreateSectionView({
  type,
  resetView
}: DomainReportCreateSectionViewProps) {
  const dispatch = useAppDispatch();
  const { addToast } = useAppUtils();

  const theme = useTheme();
  const { listId, domainReport } = useDomainReportContext();
  const [title, setTitle] = useState<string>('');
  const [displayPrompt, setDisplayPrompt] = useState<boolean>(false);
  const [displaySources, setDisplaySources] = useState<boolean>(false);
  const [displayReasoning, setDisplayReasoning] = useState<boolean>(false);
  const [body, setBody] = useState<string>('');
  const [allowTitle, setAllowTitle] = useState(true);
  const [allowSubmission, setAllowSubmission] = useState(false);
  const [selectedDomainKnowledge, setSelectedDomainKnowledge] = useState<any>([]);

  const listDomainKnowledge = useSelector(
    (state) => getListDomainKnowledgeByListIdAndType(state, listId, 'answers'),
    shallowEqual
  );

  const singleSelectedDomainKnowledge = useMemo(() => {
    return selectedDomainKnowledge.length === 1
      ? listDomainKnowledge.find(
          (item: { id: number }) => item.id === selectedDomainKnowledge[0].id
        )
      : null;
  }, [selectedDomainKnowledge, listDomainKnowledge]);

  const selectedDomainKnowledgeNum = useMemo(() => {
    return selectedDomainKnowledge.length;
  }, [selectedDomainKnowledge]);

  useEffect(() => {
    setAllowSubmission(
      (selectedDomainKnowledgeNum === 1 && title.length > 0) || selectedDomainKnowledgeNum > 1
    );
  }, [title, selectedDomainKnowledgeNum]);

  useEffect(() => {
    setAllowTitle(selectedDomainKnowledgeNum <= 1);
  }, [selectedDomainKnowledgeNum]);

  useEffect(() => {
    if (selectedDomainKnowledgeNum === 0) {
      setTitle('');
      setBody('');
    }
    if (selectedDomainKnowledgeNum === 1) {
      setTitle(singleSelectedDomainKnowledge?.name || '');
      setBody(singleSelectedDomainKnowledge?.data?.message?.answer || '');
    }
  }, [singleSelectedDomainKnowledge, selectedDomainKnowledgeNum]);
  const getTitlePlaceholder = () => {
    if (selectedDomainKnowledge.length === 0) {
      return 'No answer has been selected yet';
    }
    if (selectedDomainKnowledge.length === 1) {
      return 'Choose Section Name';
    }
    return 'Choose Saved Answers to Show';
  };

  const updateTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const removeItem = (selection: any) => {
    setSelectedDomainKnowledge(
      selectedDomainKnowledge.filter((item: { id: number }) => item.id !== selection.id)
    );
  };

  const handleSelect = (event: React.SyntheticEvent, value: { name: string }[]) => {
    setSelectedDomainKnowledge(value);
  };

  const postNewSections = () => {
    let selectedDomainKnowledgeItems = [];
    if (selectedDomainKnowledgeNum === 1) {
      selectedDomainKnowledgeItems.push({
        title: title,
        data: {
          displayPrompt,
          displaySources,
          displayReasoning,
          ...singleSelectedDomainKnowledge,
          data: {
            ...singleSelectedDomainKnowledge?.data,
            message: {
              ...singleSelectedDomainKnowledge?.data?.message,
              answer: body
            }
          }
        }
      });
    } else {
      selectedDomainKnowledgeItems = selectedDomainKnowledge.map((selection: { id: number }) => {
        const item =
          listDomainKnowledge.find((item: { id: number }) => item.id === selection.id) || {};
        return {
          title: item.name,
          data: { ...item, displayPrompt, displaySources, displayReasoning }
        };
      });
    }

    dispatch(
      submitDomainReportSection(
        listId,
        domainReport.domain_report_id,
        type,
        selectedDomainKnowledgeItems,
        addToast
      )
    );
    resetView();
  };

  const checkboxes = [
    { text: 'Prompt', value: displayPrompt, setter: setDisplayPrompt },
    { text: 'Sources', value: displaySources, setter: setDisplaySources },
    { text: 'Reasoning', value: displayReasoning, setter: setDisplayReasoning }
  ];

  return (
    <Stack textAlign="left" sx={{ height: '100%' }}>
      <Stack
        gap="32px"
        padding="24px"
        sx={{ flexGrow: 1, overflowY: 'auto', scrollbarGutter: 'stable' }}>
        <DomainReportEditorKeyValue title="Saved Answers">
          <MLMultiselect
            options={listDomainKnowledge}
            placeHolder="Choose one or more saved answers"
            selectedOptions={selectedDomainKnowledge}
            onChange={handleSelect}
            onRemove={removeItem}
            noOptionsText="No answers have been saved to Domain Knowledge yet"
            notFoundText="No saved answers found"
          />
        </DomainReportEditorKeyValue>
        <DomainReportEditorKeyValue title="Section Name">
          {allowTitle ? (
            <TextField
              variant="outlined"
              value={title}
              size="small"
              disabled={!allowTitle}
              sx={{
                borderColor: 'colors.ui_border',
                backgroundColor: allowTitle ? 'colors.white' : 'colors.hover_on_gray_bg'
              }}
              placeholder={getTitlePlaceholder()}
              slotProps={{
                input: {
                  sx: {
                    ...theme.typography.text1_Normal,
                    color: allowTitle ? 'colors.text' : 'colors.disabled_text'
                  }
                }
              }}
              onChange={updateTitle}
            />
          ) : (
            <Typography variant="text1_Normal">
              Each section will be named after its answer’s title. You can rename them individually
              after creation.
            </Typography>
          )}
        </DomainReportEditorKeyValue>
        <DomainReportEditorKeyValue title="Display in section">
          {checkboxes.map((checkbox) => (
            <MLInlineStack gap="8px" key={checkbox.text}>
              <Checkbox
                value={checkbox.value}
                onChange={() => checkbox.setter(!checkbox.value)}
                size="small"
                sx={{ color: 'colors.ui_border', width: '16px', height: '16px', padding: '0px' }}
              />
              <Typography variant="text1_Normal">{checkbox.text}</Typography>
            </MLInlineStack>
          ))}
        </DomainReportEditorKeyValue>
        <Stack gap="24px">
          <Divider />
          <DomainReportEditorExpandableText
            disabled={!singleSelectedDomainKnowledge}
            disabledText={
              selectedDomainKnowledgeNum > 1
                ? 'You’ll be able to edit each answer’s content individually after the sections are created.'
                : 'No answer has been selected yet'
            }
            title="Answer Content"
            text={body}
            setText={setBody}
          />
        </Stack>
      </Stack>
      <MLInlineStack
        padding="24px"
        gap="8px"
        justifyContent="flex-end"
        sx={{ position: 'sticky', bottom: 0, backgroundColor: 'white' }}>
        <MLButton
          variant="outlined"
          size="large"
          onClick={resetView}
          sx={{ backgroundColor: 'white' }}>
          Cancel
        </MLButton>
        <MLButton
          variant="contained"
          size="large"
          onClick={postNewSections}
          disabled={!allowSubmission}>
          Create Section
        </MLButton>
      </MLInlineStack>
    </Stack>
  );
}
