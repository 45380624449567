import { Menu, Typography } from '@mui/material';
import { UseMenuManagementReturn } from 'design-system/hooks/useMenuManagement';
import React from 'react';

import MLButton from './MLButton';
import MLIconButton, { MLIconButtonProps } from './MLIconButton';

export interface MLIconMenuButtonProps extends MLIconButtonProps {
  menuContent?: React.ReactNode;
  label?: string;
  topToBottom?: boolean;
  menuManagement: UseMenuManagementReturn<HTMLButtonElement>;
}

export default function MLIconMenuButton({
  menuContent,
  menuManagement,
  label,
  topToBottom,
  ...buttonArgs
}: MLIconMenuButtonProps) {
  const { onOpen, onClose, open, anchorEl, ref } = menuManagement;
  return (
    <React.Fragment>
      {label ? (
        <MLButton
          ref={ref}
          onClick={onOpen}
          startIcon={buttonArgs.children}
          {...buttonArgs}
          sx={{ backgroundColor: open ? 'colors.selected' : null }}>
          <Typography>{label}</Typography>
        </MLButton>
      ) : (
        <MLIconButton
          ref={ref}
          onClick={onOpen}
          {...buttonArgs}
          sx={{ backgroundColor: open ? 'colors.selected' : null }}>
          {buttonArgs.children}
        </MLIconButton>
      )}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        anchorOrigin={{ vertical: topToBottom ? 'bottom' : 'top', horizontal: 'left' }}
        transformOrigin={{
          vertical: topToBottom ? 'top' : 'bottom',
          horizontal: 'left'
        }}
        slotProps={{
          paper: {
            sx: {
              border: '1px solid',
              borderColor: 'colors.ui_border',
              borderRadius: '8px',
              boxShadow: 'box-shadow: 0px 2px 7px 0px rgba(66, 82, 110, 0.41)'
            }
          }
        }}>
        {menuContent}
      </Menu>
    </React.Fragment>
  );
}
