import { sendUserEvent } from 'actions/users/send-user-event-action';
import { DOMAIN_REPORT_EVENTS } from 'constants/userEvents';
import { DomainReportSection } from 'models/domain-report/types';
import { updateDomainReport } from 'reducer/domain-reports-slice';
import { AppDispatch, getAppState } from 'types/store';

export function updateDomainReportSections(
  listId: number,
  domainReportId: number,
  sections: DomainReportSection[]
) {
  return (dispatch: AppDispatch, getState: getAppState, apiClient: any) => {
    dispatch(
      sendUserEvent(DOMAIN_REPORT_EVENTS.DOMAIN_REPORT_SECTIONS_UPDATE, {
        listId,
        domainReportId,
        sections
      })
    );
    const payload = sections.map((section) => section.props);
    apiClient.explore
      .submitExploreListUpdateDomainReportSections(listId, domainReportId, payload)
      .then((res: any) => {
        if (res.ok && res.data) {
          const data = { ...res.data };
          dispatch(updateDomainReport({ listId, lastDomainReportId: domainReportId, data }));
        }
      });
  };
}
