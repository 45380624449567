import { Avatar, Button, ButtonGroup, Stack, Typography } from '@mui/material';
import MLIconButton from 'design-system/MLIconButton';
import MLInlineStack from 'design-system/MLInlineStack';
import CloseIcon from 'design-system/icons/platform/CloseIcon';
import DownIcon from 'icons/DownIcon';
import UpIcon from 'icons/UpIcon';
import React from 'react';

type Props = {
  logoUrl: string;
  name: string;
  onUp: () => void;
  onDown: () => void;
  onClose: () => void;
};

const iconButtonStyle = {
  height: '32px',
  width: '32px',
  color: 'colors.primary_text',
  padding: 0
};
const upDownButtonStyle = {
  ...iconButtonStyle,
  width: '28px'
};

function SingleCompanyAnswerTitle({ logoUrl, name, onUp, onDown, onClose }: Props) {
  return (
    <MLInlineStack
      width="100%"
      sx={{
        flexDirection: 'row',
        padding: '8px',
        justifyContent: 'space-between'
      }}>
      <Stack gap="16px" direction="row">
        {logoUrl && <Avatar src={logoUrl} sx={{ width: 24, height: 24 }} variant="rounded" />}
        <Stack gap="4px">
          <Typography variant="h2_Semibold" color="colors.primary_text">
            {name}
          </Typography>
        </Stack>
      </Stack>
      <MLInlineStack justifySelf="flex-end" sx={{ height: '32px' }}>
        <ButtonGroup>
          <Button sx={upDownButtonStyle} disabled={!onDown} onClick={onDown}>
            <DownIcon />
          </Button>
          <Button sx={upDownButtonStyle} disabled={!onUp} onClick={onUp}>
            <UpIcon />
          </Button>
        </ButtonGroup>
        <MLIconButton sx={iconButtonStyle} tooltipText="Close" onClick={onClose}>
          <CloseIcon />
        </MLIconButton>
      </MLInlineStack>
    </MLInlineStack>
  );
}

export default SingleCompanyAnswerTitle;
