import { Menu, Typography } from '@mui/material';
import { ACTION_MENU_ITEMS } from 'constants/assistant';
import MLInlineStack from 'design-system/MLInlineStack';
import { ActionType } from 'models/assistant/assistant-actions';
import React from 'react';
import { updatePromptConfig } from 'reducer/assistant-slice';
import { useAppDispatch } from 'types/store';

import AssistantActionMenuItem from '../AssistantActionMenuItem';

interface AssistantActionMenuProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  handleMenuClose: () => void;
}

export default function AssistantActionMenu({
  anchorEl,
  open,
  handleMenuClose
}: AssistantActionMenuProps) {
  const dispatch = useAppDispatch();
  const handleMenuItemClick = (action_type: ActionType) => {
    dispatch(updatePromptConfig({ action_type }));
    handleMenuClose();
  };
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleMenuClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      sx={{
        transform: 'translateY(-12px)'
      }}
      slotProps={{
        paper: {
          sx: {
            width: anchorEl ? anchorEl.clientWidth : null,
            border: '1px solid',
            borderColor: 'colors.ui_border',
            paddingX: '0px',
            paddingY: '2px',
            borderRadius: '8px',
            boxShadow: 'box-shadow: 0px 2px 7px 0px rgba(66, 82, 110, 0.41)'
          }
        }
      }}
      MenuListProps={{
        'aria-labelledby': 'thunder-button'
      }}>
      <MLInlineStack sx={{ paddingX: '20px', paddingY: '8px' }}>
        <Typography color="colors.secondary_text">Actions</Typography>
      </MLInlineStack>
      {ACTION_MENU_ITEMS.map((actionTypeDisplay) => (
        <AssistantActionMenuItem
          key={actionTypeDisplay.actionType}
          handleMenuItemClick={handleMenuItemClick}
          {...actionTypeDisplay}
        />
      ))}
    </Menu>
  );
}
