import { Menu, Stack } from '@mui/material';
import useMenuManagement from 'design-system/hooks/useMenuManagement';
import { isEqual } from 'lodash';
import React, { useMemo } from 'react';
import { appTheme } from 'theme';
import {
  formatFinanceStrFromData,
  getConsensusFromData,
  getIsEstimatedFromData
} from 'utils/finance-utils';

import TableRowLoading from './TableRowLoading';
import FinanceDataMenuItem from './finance/FinanceDataMenuItem';
import RenderFinanceTableInlineCell from './finance/RenderFinanceTableInlineCell';
import { FinanceData } from './finance/types';

interface FinanceValue {
  data: FinanceData[] | null;
  loading?: boolean;
  financeType?: string;
}

interface RenderFinanceTableCellProps {
  value: FinanceValue | null;
  color: string;
}

function RenderFinanceTableCell({
  value,
  color = 'colors.primary_text'
}: RenderFinanceTableCellProps) {
  const { onOpen, onClose, open, anchorEl, ref } = useMenuManagement();
  const menuBackgroundColor = appTheme.palette.colors.dark_bg;

  const data = value?.data;
  const consensus = getConsensusFromData(data);
  const isEstimated = getIsEstimatedFromData(data);
  const finalValue = useMemo(() => {
    return formatFinanceStrFromData(consensus);
  }, [consensus]);

  const loading = value?.loading;
  if (loading) {
    return <TableRowLoading></TableRowLoading>;
  }

  if (!value || !data || !finalValue) return null;

  return (
    <Stack width="100%" padding={0}>
      <RenderFinanceTableInlineCell
        onOpen={onOpen}
        color={color}
        finalValue={finalValue}
        isEstimated={isEstimated}
        ref={ref}
      />

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        disableScrollLock
        slotProps={{ paper: { style: { backgroundColor: menuBackgroundColor } } }}>
        <FinanceDataMenuItem
          menuBackgroundColor={menuBackgroundColor}
          data={data}
          isEstimated={isEstimated}
          financeType={value?.financeType || ''}
        />
      </Menu>
    </Stack>
  );
}

export default React.memo(RenderFinanceTableCell, isEqual);
