import { useAuth0 } from '@auth0/auth0-react';
import { datadogRum } from '@datadog/browser-rum';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import GroupIcon from '@mui/icons-material/Group';
import LogoutIcon from '@mui/icons-material/Logout';
import {
  Avatar,
  Button,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import Box from '@mui/material/Box';
import { wrapWithError } from 'components/ErrorBoundaryComponent';
import { FOLDERS_PREFIX, ROUTES_PATHS } from 'constants/app-routes';
import { SHARED_WITH_ME_FOLDER_ID } from 'constants/folders';
import { ICON_VARIANTS } from 'constants/icons';
import { RESEARCH_TYPES, researchIdToTypeMap } from 'constants/researches';
import { useAppState } from 'hooks/state-context';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import ArrowIcon from 'icons/ArrowIcon';
import HomePageIcon from 'icons/HomePageIcon';
import Logo from 'icons/Logo';
import OpenFolderIcon from 'icons/OpenFolderIcon';
import { WorkflowIcon } from 'icons/WorkflowIcon';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { getDeepDiveIsPublicMode } from 'selectors/deep-dive';
import { getCurrentFolderId, getCurrentFolderName, getFolderBreadcrumbs } from 'selectors/folders';
import {
  getCurrentOpenResourceId,
  getCurrentOpenResourceName,
  getCurrentOpenResourceType
} from 'selectors/resources';
import {
  getUserEmail,
  getUserFeatureFlags,
  getUserIsActive,
  getUserIsAdmin,
  getUserIsOrgAdmin,
  getUserName,
  getUserOrganizationId,
  getUserPictureUrl
} from 'selectors/user';
import { stringToCodeInt, toTitleCase } from 'utils/string-utils';

import MLIconButton from '../design-system/MLIconButton';
import NotificationsComponent from './NotificationsComponent';
import TopbarResourceActionsMenu from './TopbarResourceActionsMenu';
import RenderStr from './elements/table/RenderStr';
import WorkflowsCenterDialog from './work-flow/WorkflowsCenterDialog';

/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
function TopbarComponent() {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [resourceMenuAnchorEl, setResourceMenuAnchorEl] = useState(null);
  const [organizationHover, setOrganizationHover] = useState(false);
  const [openWorkflowCenter, setOpenWorkflowCenter] = useState(false);
  const open = Boolean(anchorEl);
  const { logout } = useAuth0();
  const { state: oldState } = useAppState();
  const canTeamManagement = Boolean(useOldStateSelector(getUserOrganizationId));
  const isPublicMode = useSelector(getDeepDiveIsPublicMode);
  const folderId = useSelector(getCurrentFolderId);
  const folderName = useSelector(getCurrentFolderName);
  const folderBreadcrumbs = useSelector((state) => getFolderBreadcrumbs(state, folderId));
  const currentOpenResourceName = useSelector(getCurrentOpenResourceName);
  const currentOpenResourceType = useSelector(getCurrentOpenResourceType);
  const currentOpenResourceId = useSelector(getCurrentOpenResourceId);
  const userName = getUserName(oldState);
  const userEmail = getUserEmail(oldState);
  const userNameToDisplay = userName || userEmail || '';
  const userPictureUrl = getUserPictureUrl(oldState);
  const isActive = getUserIsActive(oldState);
  const isAdmin = getUserIsAdmin(oldState);
  const isOrgAdmin = getUserIsOrgAdmin(oldState);
  const featureFlags = useOldStateSelector(getUserFeatureFlags);
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const haveFolder = folderId && (folderName || folderId === SHARED_WITH_ME_FOLDER_ID);
  const ResearchTypeIcon = currentOpenResourceType
    ? researchIdToTypeMap[currentOpenResourceType]?.Icon
    : null;
  const canOrganizationManagement = isAdmin || isOrgAdmin;

  return (
    <Box
      sx={{
        display: 'flex',
        padding: { xs: '0 8px', md: '0 24px' },
        gap: '24px',
        height: '50px',
        alignItems: 'center',
        bgcolor: 'colors.dark_bg',
        position: 'sticky',
        top: 0,
        zIndex: 'topBar',
        justifyContent: { xs: 'space-between', md: 'initial' }
      }}>
      <Stack direction="row" alignItems="center" gap="16px">
        {isPublicMode ? (
          <Box>
            <Logo style={{ color: 'white' }} />
          </Box>
        ) : (
          <Link component={RouterLink} to={ROUTES_PATHS.MAIN_PATH}>
            <Logo style={{ color: 'white' }} />
          </Link>
        )}
      </Stack>
      {isActive && !isPublicMode && (
        <Stack direction="row" alignItems="center" gap="8px">
          <Link
            id="to-home-page"
            component={RouterLink}
            to={ROUTES_PATHS.MAIN_PATH}
            color="colors.dark_bg"
            sx={{ textDecoration: 'none' }}>
            <Stack direction="row" alignItems="center" gap="4px">
              <HomePageIcon color={theme.vars.palette.colors.text_on_primary} />
              {!haveFolder && !currentOpenResourceName && (
                <Typography variant="text1_Medium" color="colors.text_on_primary">
                  Home Page
                </Typography>
              )}
            </Stack>
          </Link>
          {folderId === SHARED_WITH_ME_FOLDER_ID && (
            <Link
              component={RouterLink}
              to={`${FOLDERS_PREFIX}/${SHARED_WITH_ME_FOLDER_ID}`}
              color="colors.dark_bg"
              sx={{ textDecoration: 'none' }}>
              <Stack direction="row" alignItems="center" gap="8px">
                <ArrowIcon rotation={90} color={theme.vars.palette.colors.text_on_primary} />
                <OpenFolderIcon />
                <Typography variant="text1_Medium" color="colors.text_on_primary">
                  Shared With Me
                </Typography>
              </Stack>
            </Link>
          )}
          {folderBreadcrumbs &&
            folderBreadcrumbs.length > 0 &&
            folderBreadcrumbs.map((folder, index) => (
              <Link
                component={RouterLink}
                key={index}
                to={`${FOLDERS_PREFIX}/${folderBreadcrumbs
                  .slice(0, index + 1)
                  .map((folder2) => folder2.id)
                  .join('/')}`}
                color="colors.dark_bg"
                sx={{ textDecoration: 'none' }}>
                <Stack direction="row" alignItems="center" gap="8px">
                  <ArrowIcon rotation={90} color={theme.vars.palette.colors.text_on_primary} />
                  <OpenFolderIcon />
                  <Typography variant="text1_Medium" color="colors.text_on_primary">
                    {folderId === SHARED_WITH_ME_FOLDER_ID ? 'Shared With Me' : folderName}
                  </Typography>
                </Stack>
              </Link>
            ))}
          {currentOpenResourceName && (
            <Stack direction="row" alignItems="center" gap="8px">
              <ArrowIcon rotation={90} color={theme.vars.palette.colors.text_on_primary} />
              {ResearchTypeIcon && <ResearchTypeIcon variant={ICON_VARIANTS.INVERT} />}
              <Box maxWidth="600px">
                <RenderStr
                  variant="text1_Medium"
                  color="colors.text_on_primary"
                  value={currentOpenResourceName}
                />
              </Box>
              {currentOpenResourceType === RESEARCH_TYPES.EXPLORE.id && (
                <React.Fragment>
                  <MLIconButton
                    color="inverted"
                    onClick={(event) => setResourceMenuAnchorEl(event.target)}>
                    <ArrowIcon rotation={180} color={theme.vars.palette.colors.text_on_primary} />
                  </MLIconButton>
                  <TopbarResourceActionsMenu
                    resourceId={currentOpenResourceId}
                    resourceType={currentOpenResourceType}
                    menuAnchorEl={resourceMenuAnchorEl}
                    onClose={() => setResourceMenuAnchorEl(null)}
                  />
                </React.Fragment>
              )}
            </Stack>
          )}
        </Stack>
      )}

      <Typography sx={{ flex: 1 }}></Typography>
      {featureFlags.includes('work_flow') && currentOpenResourceType === RESEARCH_TYPES.EXPLORE.id && (
        <Button
          color="inverted"
          startIcon={<WorkflowIcon />}
          onClick={() => setOpenWorkflowCenter(true)}>
          Workflows
        </Button>
      )}
      {isPublicMode ? (
        <Typography variant="h2_Bold" color="colors.text_on_primary" sx={{ flex: 100 }}>
          PUBLIC LINK
        </Typography>
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '16px',
            color: 'colors.text_on_primary',
            overflow: 'hidden'
          }}>
          {userName && <NotificationsComponent />}
          <Stack
            id="user-menu-button"
            direction="row"
            gap="16px"
            alignItems="center"
            onClick={handleClick}
            sx={{
              cursor: 'pointer',
              paddingY: '2px',
              paddingX: '8px',
              border: '2px solid',
              borderColor: 'rgb(255,255,255,0)',
              borderRadius: '4px',
              '&:hover': {
                opacity: '0.8',
                color: 'colors.secondary_text',
                borderColor: 'colors.secondary_text'
              }
            }}>
            <Avatar
              alt={toTitleCase(userNameToDisplay)}
              src={userPictureUrl}
              sx={{
                width: 28,
                height: 28,
                border: '2px solid currentcolor',
                backgroundColor: `graphColors.graph${
                  ((stringToCodeInt(userNameToDisplay) + 3) % 12) + 1
                }`
              }}>
              {userPictureUrl ? null : toTitleCase(userNameToDisplay).charAt(0)}
            </Avatar>
            <Typography variant="text1_Semibold">{userNameToDisplay}</Typography>
          </Stack>

          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            {canTeamManagement && (
              <MenuItem
                onClick={() => {
                  handleClose();
                  navigate(ROUTES_PATHS.PROFILE_PATH);
                }}>
                <ListItemIcon>
                  <AccountCircleIcon />
                </ListItemIcon>
                <ListItemText>Profile</ListItemText>
              </MenuItem>
            )}
            {canTeamManagement && (
              <MenuItem
                onClick={() => {
                  handleClose();
                  navigate(ROUTES_PATHS.TEAM_PATH);
                }}>
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                <ListItemText>Teams</ListItemText>
              </MenuItem>
            )}
            <Tooltip
              title="Only organization admin users can use this setting."
              open={organizationHover && !canOrganizationManagement}>
              <MenuItem
                disabled={!canOrganizationManagement}
                sx={{
                  '&.Mui-disabled': {
                    pointerEvents: 'all',
                    '&:hover': { backgroundColor: 'transparent' }
                  }
                }}
                onMouseEnter={() => setOrganizationHover(true)}
                onMouseLeave={() => setOrganizationHover(false)}
                onClick={() => {
                  if (canOrganizationManagement) {
                    handleClose();
                    navigate(ROUTES_PATHS.ORGANIZATION_PATH);
                  }
                }}>
                <ListItemIcon>
                  <CorporateFareIcon />
                </ListItemIcon>
                <ListItemText>Organization</ListItemText>
              </MenuItem>
            </Tooltip>
            <MenuItem
              id="logout-button"
              onClick={() => {
                handleClose();
                datadogRum.clearUser();
                logout({ logoutParams: { returnTo: window.location.origin } });
              }}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText>Logout</ListItemText>
            </MenuItem>
          </Menu>
        </Box>
      )}
      <WorkflowsCenterDialog
        listId={currentOpenResourceId}
        open={openWorkflowCenter}
        onClose={() => setOpenWorkflowCenter(false)}
      />
    </Box>
  );
}

export default wrapWithError(TopbarComponent);
