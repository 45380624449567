import { Stack, Typography } from '@mui/material';
import React from 'react';

export default function FilterAnswer() {
  return (
    <Stack gap="16px" sx={{ padding: '8px' }}>
      <Typography>Filter applied.</Typography>
    </Stack>
  );
}
