import { Avatar, Stack } from '@mui/material';
import DotsAnimation from 'components/DotsAnimation';
import RenderStr from 'components/elements/table/RenderStr';
import MLIconButton from 'design-system/MLIconButton';
import MLInlineStack from 'design-system/MLInlineStack';
import InfoIcon from 'design-system/icons/basic/InfoIcon';
import { useMLTooltip } from 'hooks/useMLTooltip';
import React from 'react';

import AnswerTextComponent from './AnswerTextComponent';

/* eslint-disable id-length */

type Props = {
  companyName: string;
  companyLogo: string;
  isLoading: boolean;
  message: any;
  onClickRow: () => void;
};

export default function CompanyAnswerRow({
  companyName,
  companyLogo,
  isLoading,
  message,
  onClickRow
}: Props) {
  const { hideTooltip } = useMLTooltip();
  const [showInfoIcon, setShowInfoIcon] = React.useState(false);

  const onClick = () => {
    hideTooltip();
    onClickRow();
  };
  return (
    <MLInlineStack
      width="100%"
      height="50px"
      sx={{ cursor: isLoading ? 'auto' : 'pointer' }}
      onMouseEnter={() => setShowInfoIcon(true)}
      onMouseLeave={() => setShowInfoIcon(false)}
      onClick={onClick}>
      <Avatar src={companyLogo} sx={{ width: 24, height: 24 }} variant="rounded" />
      <Stack minWidth={100} maxWidth={100}>
        <RenderStr value={companyName} />
      </Stack>
      {isLoading && (
        <Stack width="100%" alignItems="center">
          <DotsAnimation size="6px" gap="2.5px" />
        </Stack>
      )}
      {message?.answer && (
        <AnswerTextComponent
          answerText={message.answer}
          answerType={message.answer_type}
          listId={message.list_id}
        />
      )}
      {!isLoading && showInfoIcon && (
        <MLIconButton tooltipText="View result details">
          <InfoIcon />
        </MLIconButton>
      )}
    </MLInlineStack>
  );
}
