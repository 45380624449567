import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getCompanyMetasByListId } from 'selectors/explore';
import { getExploreTableUICheckedCompaniesByListId } from 'selectors/explore-table-ui';

import ClustersCompaniesSelectionsList from './ClustersCompaniesSelectionsList';

function ClustersCompaniesSelectionsListConnector({
  listId,
  onExtend,
  onNewList,
  headerHeight,
  initialLeft
}) {
  const companiesIsChecked = useSelector((state) =>
    getExploreTableUICheckedCompaniesByListId(state, listId)
  );
  const companies = useSelector((state) => getCompanyMetasByListId(state, listId));
  const selectedCompanies = useMemo(() => {
    if (!companiesIsChecked || !companies) {
      return null;
    }
    return companies
      .filter((company) => companiesIsChecked[company.company_list_meta_id])
      .map((company) => {
        const {
          logo_url,
          display_name,
          cb_name,
          name,
          description,
          company_website,
          linkedin_url
        } = company.parameters;
        return {
          logoUrl: logo_url,
          name: display_name || cb_name || name,
          description: description,
          companyListMetaId: company.company_list_meta_id,
          companyListId: listId,
          recordId: company.record_id,
          recordReady: company.record_ready,
          recordTimeSubmitted: company.record_time_submitted,
          website: company_website,
          linkedinUrl: linkedin_url,
          hideRecordReady: true,
          suggestion: { ...company.parameters }
        };
      });
  }, [companiesIsChecked, companies, listId]);

  return (
    <ClustersCompaniesSelectionsList
      selectedCompanies={selectedCompanies}
      onExtend={onExtend}
      onNewList={onNewList}
      listId={listId}
      headerHeight={headerHeight}
      initialLeft={initialLeft}
    />
  );
}

ClustersCompaniesSelectionsListConnector.propTypes = {
  listId: PropTypes.number,
  onExtend: PropTypes.func,
  onNewList: PropTypes.func,
  headerHeight: PropTypes.number,
  initialLeft: PropTypes.number
};

export default ClustersCompaniesSelectionsListConnector;
