import cloneDeep from 'lodash/cloneDeep';
import { getThreadKey, updatePlan } from 'reducer/custom-analytics';
import { getCustomAnalyticsPlanByThreadAndPostId } from 'selectors/custom-analytics';
import { getWorkflowStepAndQueryIndexesByStepId } from 'utils/workflows-utils';

export function updateAttributeTitleInWorkflowAction(
  listId,
  workflowId,
  stepId,
  newAttributeTitle
) {
  return (dispatch, getState) => {
    const threadKey = getThreadKey(listId);
    const plan = getCustomAnalyticsPlanByThreadAndPostId(getState(), threadKey, workflowId);

    if (plan?.steps) {
      const newPlan = cloneDeep(plan);
      const { stepIndex, queryIndex } = getWorkflowStepAndQueryIndexesByStepId(
        stepId,
        newPlan.steps
      );
      if (stepIndex >= 0) {
        const step = newPlan.steps[stepIndex];
        const { function_use: stepInfo } = step;
        const { action_variables: variables } = stepInfo;
        const { queries } = variables;
        if (queries?.length >= queryIndex) {
          if (typeof queries[queryIndex].attribute_title === 'string') {
            queries[queryIndex].attribute_title = newAttributeTitle;
          }
          if (typeof queries[queryIndex].analysis_title === 'string') {
            queries[queryIndex].analysis_title = newAttributeTitle;
          }
          dispatch(updatePlan({ threadKey, postId: workflowId, newPlan }));
        }
      }
    }
  };
}
