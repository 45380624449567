import { Box, Grow, Input, Menu, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import RenderDiscoveryRunFeedMessage from 'components/discovery/RenderDiscoveryRunFeedMessage';
import RenderStr from 'components/elements/table/RenderStr';
import MLButton from 'design-system/MLButton';
import MLIconButton from 'design-system/MLIconButton';
import { ArrowRightIcon } from 'icons/ArrowRightIcon';
import CheckIcon2 from 'icons/CheckIcon2';
import ViewFollowupIcon from 'icons/ViewFollowupIcon';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getDomainResearchIsDescriptionHidden,
  getExploredDomains,
  subdomainIdToChildPrefixes
} from 'selectors/domain-research';
import { appTheme } from 'theme';
import { EXTEND_DOMAIN_TYPES } from 'utils/subdomains';

import { useDomainResearchContext } from './DomainResearchContextProvider';

function SubdomainItem({
  subdomain,
  inputName,
  setInputName,
  onPressEnter,
  placeholder,
  listId,
  backgroundStyle,
  isMainDomainLevel
}) {
  const name = subdomain ? subdomain.name : inputName;
  const id = subdomain ? subdomain.id : null;
  const description = subdomain ? subdomain.description : null;
  const childPrefixes = useSelector((state) => subdomainIdToChildPrefixes(state, listId, id));
  const [isHovered, setIsHovered] = useState(false);
  const hideSubdomainsDescription = useSelector((state) =>
    getDomainResearchIsDescriptionHidden(state, listId)
  );
  const exploredDomains = useSelector((state) => getExploredDomains(state, listId));
  const [discoveryMenuAnchorEl, setDiscoveryMenuAnchorEl] = useState(false);

  const {
    onClickSubdomain,
    selectedDomains,
    ranSubdomains,
    submitSubdomain,
    scrollToCategory: doScroll
  } = useDomainResearchContext(listId);
  const width = isMainDomainLevel ? 'fit-content' : '350px';

  const isSelected = useMemo(
    () => subdomain?.id && selectedDomains.has(subdomain.id),
    [selectedDomains, subdomain?.id]
  );

  const discoveryRecordId = useMemo(
    () => subdomain?.id && ranSubdomains?.[subdomain.id],
    [ranSubdomains, subdomain?.id]
  );

  const isInListButtonDisabled = discoveryRecordId < 0;
  const hasSubdomains = useMemo(
    () => !isMainDomainLevel && subdomain?.id && exploredDomains.has(subdomain.id),
    [isMainDomainLevel, exploredDomains, subdomain?.id]
  );

  const componentStyle = {
    backgroundColor: 'colors.primary_bg',
    maxWidth: '100%',
    borderRadius: '8px',
    padding: '16px 24px',
    paddingRight: '16px',
    justifyContent: 'center',
    border: isSelected ? '2px solid #2C96FF' : '2px solid white',
    cursor: id ? 'pointer' : 'auto'
  };

  if (subdomain && subdomain.loading) {
    return (
      <Box sx={{ paddingRight: '12px', maxWidth: '100%' }}>
        <Skeleton variant="rounded" sx={{ minWidth: width }} height={130} />
      </Box>
    );
  }

  const scrollToCategory = (event) => {
    event?.stopPropagation();
    const elementId = `subdomain-category-${id}-${childPrefixes[0]}`;
    doScroll(elementId, id);
  };

  const submitItem = () => {
    submitSubdomain(subdomain, EXTEND_DOMAIN_TYPES.SUBDOMAINS, null, true);
  };

  const submitSubdomainButtonStyle = {
    position: 'absolute',
    left: '378px',
    top: '50%',
    transform: 'translateY(-50%)',
    backgroundColor: 'colors.primary_bg',
    borderRadius: '4px',
    boxShadow: `0px 2px 4px 0px #00000026`,
    height: '24px',
    padding: 0,
    ':hover': {
      backgroundColor: 'colors.hover_on_gray_bg'
    }
  };
  const component = (
    <Stack
      id="subdomain-item"
      width="100%"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      boxSizing="border-box">
      <Stack
        direction="row"
        boxSizing="border-box"
        sx={{
          ...backgroundStyle,
          position: 'relative',
          overflow: 'hidden',
          padding: 0,
          paddingRight: '12px'
        }}>
        {isHovered && !hasSubdomains && subdomain && (
          <Tooltip arrow title="Create a follow-up">
            <Box onClick={submitItem} sx={{ ...submitSubdomainButtonStyle, cursor: 'pointer' }}>
              <ArrowRightIcon color={appTheme.vars.palette.colors.icon} />
            </Box>
          </Tooltip>
        )}
        <Stack
          sx={{ ...componentStyle }}
          onClick={id ? () => onClickSubdomain(id) : null}
          width="100%">
          {subdomain ? (
            <Stack gap="8px" width={width}>
              <Stack direction="row" alignItems="center">
                <RenderStr color="colors.primary_text" variant="h4" value={name} />
                <Stack direction="row" gap="4px" marginLeft="auto">
                  {discoveryRecordId && (
                    <MLButton
                      tooltipText={isInListButtonDisabled ? '' : 'View discovery details'}
                      color="positive"
                      startIcon={<CheckIcon2 />}
                      sx={{ cursor: isInListButtonDisabled ? 'default' : 'pointer' }}
                      onClick={(event) => {
                        event.stopPropagation();
                        if (isInListButtonDisabled) {
                          return;
                        }
                        setDiscoveryMenuAnchorEl(event.currentTarget);
                      }}>
                      in list
                    </MLButton>
                  )}
                  {hasSubdomains && (
                    <MLIconButton
                      tooltipText="View follow-up"
                      onClick={scrollToCategory}
                      sx={{ cursor: 'pointer', padding: 0 }}>
                      <ViewFollowupIcon />
                    </MLIconButton>
                  )}
                </Stack>
              </Stack>
              {!hideSubdomainsDescription && description && (
                <Typography
                  display="block"
                  variant="text1_Normal"
                  color="colors.secondary_text"
                  paddingRight="16px">
                  {description}
                </Typography>
              )}
            </Stack>
          ) : (
            <Input
              multiline
              sx={{ width }}
              value={inputName}
              disableUnderline
              onChange={(event) => setInputName(event.target.value)}
              placeholder={placeholder}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  onPressEnter();
                }
              }}
            />
          )}
        </Stack>
      </Stack>
      {discoveryRecordId && (
        <Menu
          anchorEl={discoveryMenuAnchorEl}
          open={Boolean(discoveryMenuAnchorEl)}
          onClose={() => setDiscoveryMenuAnchorEl(null)}>
          <Stack padding="8px">
            <RenderDiscoveryRunFeedMessage
              searchId={discoveryRecordId}
              listId={listId}
              showOnlyDiscoveredCompanies={true}
            />
          </Stack>
        </Menu>
      )}
    </Stack>
  );

  if (subdomain?.streamed) {
    return <Grow in={true}>{component}</Grow>;
  }
  return component;
}

SubdomainItem.propTypes = {
  listId: PropTypes.number,
  subdomain: PropTypes.object,
  onClickSubmit: PropTypes.func,
  level: PropTypes.number,
  onAdd: PropTypes.func,
  parentId: PropTypes.number,
  isSelected: PropTypes.bool,
  inputName: PropTypes.string,
  setInputName: PropTypes.func,
  onPressEnter: PropTypes.func,
  discoveryRecordId: PropTypes.bool,
  placeholder: PropTypes.string,
  hasSubdomains: PropTypes.bool,
  backgroundStyle: PropTypes.object,
  width: PropTypes.string,
  isMainDomainLevel: PropTypes.bool
};

export default SubdomainItem;
