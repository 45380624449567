import { Stack, TableCell, TableRow, Typography } from '@mui/material';
import { copyLibraryWorkflowByQueryAction } from 'actions/workflows/copy-library-workflow-by-query-action';
import CustomAvatar from 'components/tile/CustomAvatar';
import WorkflowsLibraryRowMenu from 'components/work-flow/WorkflowsLibraryRowMenu';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { formatTimestamp } from 'utils/time-utils';

function WorkflowsLibraryDialogRow({ query, listId }) {
  const dispatch = useDispatch();
  const onOpenWorkflow = () => dispatch(copyLibraryWorkflowByQueryAction(listId, query));

  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell
        component="th"
        scope="row"
        onClick={() => onOpenWorkflow(query)}
        sx={{ cursor: 'pointer' }}>
        <Stack direction="row" alignItems="center" gap="8px">
          {query.Icon && <query.Icon />}
          <Typography variant="text1_Medium" sx={{ flex: 1 }}>
            {query.name}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell align="right">
        {query.time_created && formatTimestamp(query.time_created)}
      </TableCell>
      <TableCell align="right">
        <Stack direction="row" sx={{ width: '100%', justifyContent: 'flex-end' }}>
          <CustomAvatar name={query.owner.username} pictureUrl={query.owner.picture} />
        </Stack>
      </TableCell>
      <TableCell align="right">
        <WorkflowsLibraryRowMenu query={query} listId={listId} />
      </TableCell>
    </TableRow>
  );
}

WorkflowsLibraryDialogRow.propTypes = {
  listId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  query: PropTypes.object
};

export default WorkflowsLibraryDialogRow;
