import { addNewCompanyMetasToList } from 'actions/explore/add-new-company-metas-action';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  startNewConversation,
  updateAssistantUtility,
  updateThreadCompanyListId
} from 'reducer/assistant-slice';
import {
  getAssistantUtilityCompanyMetaIdsToFetch,
  getAssistantUtilityNavigateToViewId,
  getLatestListThread
} from 'selectors/assistant';
import { useAppDispatch, useAppSelector, useAppSelectorWithArgs } from 'types/store';
import { generateNavigateToView } from 'utils/assistant/navigation';

export default function AssistantUtility() {
  const { listId: listIdStr, viewId: viewIdStr } = useParams();
  const listId = listIdStr ? parseInt(listIdStr, 10) : null;
  const navigateToViewId = useAppSelector(getAssistantUtilityNavigateToViewId);
  const companyMetaIdsToFetch = useAppSelector(getAssistantUtilityCompanyMetaIdsToFetch);
  const navigate = useNavigate();
  const navigateToView = generateNavigateToView(navigate);
  const dispatch = useAppDispatch();
  const latestThread = useAppSelectorWithArgs(getLatestListThread, listId);

  // Fetch new company_metas if necessary
  useEffect(() => {
    if (listIdStr && companyMetaIdsToFetch && companyMetaIdsToFetch.length > 0) {
      dispatch(addNewCompanyMetasToList(listIdStr, null, companyMetaIdsToFetch, true, true));
      dispatch(updateAssistantUtility({ companyMetaIdsToFetch: [] }));
    }
  }, [listIdStr, companyMetaIdsToFetch, dispatch]);

  // Change view if necessary
  useEffect(() => {
    if (
      listIdStr &&
      navigateToViewId &&
      (!viewIdStr || navigateToViewId !== parseInt(viewIdStr, 10))
    ) {
      navigateToView(parseInt(listIdStr, 10), navigateToViewId);
      dispatch(updateAssistantUtility({ navigateToViewId: null }));
    }
  }, [listIdStr, navigate, navigateToView, navigateToViewId, viewIdStr, dispatch]);

  // Manage thread context
  useEffect(() => {
    if (listId && !latestThread) {
      dispatch(startNewConversation({ company_list_id: listId }));
    } else if (listId && latestThread.company_list_id !== listId) {
      if (latestThread.messages.length > 0) {
        dispatch(startNewConversation({ company_list_id: listId }));
      } else {
        dispatch(updateThreadCompanyListId({ threadId: latestThread.id, company_list_id: listId }));
      }
    }
  }, [dispatch, listId, latestThread]);
  return null;
}
