import { Extension } from '@tiptap/core';
import { MentionItem } from 'models/assistant/types';

export const MentionStorage = Extension.create({
  name: 'MentionStorage',
  addStorage(): { suggestions: MentionItem[] } {
    return {
      suggestions: []
    };
  }
});
