import { getRoundedFinanceColumnValue } from 'utils/companyList-utils';
import { stringFilterOperatorsByObjectKey } from 'utils/table-utils';

import { localeStringToNumber } from './string-utils';

export const COLUMN_TYPES = Object.freeze({
  COMPANY: 'company_name',
  SUMMARY: 'summary',
  DESCRIPTION: 'description',
  INDUSTRIES: 'industries',
  REVENUES: 'revenues',
  PROFIT: 'profit',
  FINANCE_PEER_COLUMN: 'finance_peer_column',
  LAST_EQUITY_ROUND_TYPE: 'last_equity_round_type',
  LAST_EQUITY_DETAILES: 'last_equity_round_message',
  TOTAL_FUNDING: 'total_funding',
  LEAD_INVESTORS: 'lead_investors',
  YEAR_FOUNDED: 'year_founded',
  EMPLOYEES: 'employees',
  NUM_EMPLOYEES: 'num_employees',
  REGION: 'region',
  COUNTRY: 'country',
  HQ: 'hq',
  LOCATIONS: 'locations',
  LEADERS: 'leaders',
  SOURCE: 'company_source',
  CREATED_AT: 'created_at',
  FILES: 'files',
  HIDDEN_GEMS: 'hidden_gems'
});

export const COLUMN_TYPE_TO_FEATURE_FLAG_NAME = Object.freeze({
  LOCATIONS: 'locations_column',
  FINANCE_PEER_COLUMN: 'peer_finance',
  HIDDEN_GEMS: 'hidden_gems',
  PROFIT: 'profit_column'
});

export const CLUSTERS_COLUMN_TYPES = Object.freeze({
  COMPANY: 'company_name',
  DESCRIPTION: 'description'
});

export const PEER_COLUMN_TYPES = Object.freeze({
  COMPANY: 'company_name',
  DESCRIPTION: 'description',
  REV_PER_HEAD: 'rev_per_head',
  NUM_EMPLOYEES: 'num_employees'
});

export const DEV_COLUMNS = {};

export const COLUMN_TYPE_TO_TITLE = Object.freeze({
  LAST_EQUITY_ROUND_TYPE: 'Last Equity Round',
  TOTAL_FUNDING: 'Total Funding',
  HQ: 'HQ',
  REVENUES: 'Revenue ($M)',
  FINANCE_PEER_COLUMN: 'Finance Peers ($M)',
  NUM_EMPLOYEES: '# Employees',
  SOURCE: 'Source',
  REV_PER_HEAD: 'Revenue Per Head',
  HIDDEN_GEMS: 'Hidden Gems',
  LAST_EQUITY_DETAILES: 'Last Equity Round Details',
  PROFIT: 'Profit ($M)'
});

export const COLUMN_TYPE_TO_RENDER_DATA_TYPE = Object.freeze({
  COMPANY: 'bulk_company_name',
  DESCRIPTION: 'description',
  EMPLOYEES: 'employees',
  SUMMARY: 'summary',
  SIMILARITY: 'similarity_ranking',
  REVENUES: 'revenues',
  FINANCE_PEER_COLUMN: 'peer_revenues',
  LEAD_INVESTORS: 'lead_investors',
  SOURCE: 'company_source',
  FILES: 'files',
  LEADERS: 'leaders',
  LAST_EQUITY_ROUND_TYPE: 'text_with_tooltip',
  LOCATIONS: 'locations',
  PROFIT: 'profit'
});

const defaultConfig = {
  icon: null,
  display: true
};

export const COLUMN_TYPE_TO_EXTRA_PARAMS = Object.freeze({
  COMPANY: (listId) => ({
    valueFormatter: (value) => value.name,
    filterOperators: stringFilterOperatorsByObjectKey('name'),
    ...defaultConfig,
    extraParams: {
      allowCheckbox: true,
      listId
    }
  }),
  DESCRIPTION: {
    valueFormatter: (value) => {
      return typeof value === 'string' || value instanceof String ? value : null;
    }
  },
  YEAR_FOUNDED: { type: 'number' },
  EMPLOYEES: { type: 'singleSelect', valueOptions: ['1 - 50', '51 - 500', '501 - 5000', '5001+'] },
  REVENUES: (values) => ({
    extraParams: {
      values,
      companyInfoFormatter: (value) => {
        return value && getRoundedFinanceColumnValue(value)
          ? `$${getRoundedFinanceColumnValue(value)}M`
          : null;
      }
    },
    valueFormatter: (value) => getRoundedFinanceColumnValue(value)
  }),
  PROFIT: {
    valueFormatter: (value) => {
      if (!value?.data) return null;
      return getRoundedFinanceColumnValue({ data: value.data?.profit });
    }
  },
  FINANCE_PEER_COLUMN: {
    valueFormatter: (value) => {
      if (value?.loading) {
        return value;
      }
      return Math.round((value?.revenue || 0) / 1e6) || null;
    }
  },

  NUM_EMPLOYEES: {
    valueFormatter: (value) => value && Math.round(localeStringToNumber(value)),
    valueGetter: (value) => value && Math.round(localeStringToNumber(value))
  },
  LEAD_INVESTORS: {
    valueFormatter: (value) => value && value.map((investor) => investor.name).join(', '),
    disableSort: true
  },
  SOURCE: (values, history) => ({
    valueFormatter: (value) => Math.round(value?.iteration || 0),
    filterOperators: stringFilterOperatorsByObjectKey('iteration'),
    sortComparator: (item1, item2) => item1?.iteration - item2?.iteration,
    extraParams: { values, history },
    type: 'number'
  }),
  COUNTRY: (values) => ({
    extraParams: { values }
  }),
  FILES: { disableExport: true, disableFilter: true, disableSort: true },
  LEADERS: { disableExport: true, disableFilter: true, disableSort: true },
  TOTAL_FUNDING: {
    extraParams: { companyInfoFormatter: (value) => value },
    valueFormatter: (value) =>
      value && localeStringToNumber(value.replace('$', '').replace('<', '')) * 1e6
  },
  LAST_EQUITY_ROUND_TYPE: {
    valueFormatter: (value) => value?.data,
    valueGetter: (value) => value?.data
  },
  GROWTH_SIGNALS: {
    valueFormatter: (value) => value?.value?.percentage
  },
  SAP_PARTNERSHIP_LEVEL: {
    valueFormatter: (value) => value?.value?.partnership_level
  },
  AWS_PARTNERSHIP_LEVEL: {
    valueFormatter: (value) => value?.value?.partnership_level
  },
  LOCATIONS: {
    valueFormatter: (value) => {
      if (!value) return null;
      return Object.keys(value).join(', ');
    }
  }
});

export const getColumnRenderType = (column, mapToTypes) => {
  const renderType = mapToTypes?.[column];
  return renderType || 'str';
};

export const getCustomColumnRenderType = (customColumn, mapToTypes) => {
  const renderType = mapToTypes?.[customColumn?.id] || mapToTypes?.[customColumn?.type];
  return renderType || customColumn.type;
};

export const getColumnTitle = (column) => {
  const title = COLUMN_TYPE_TO_TITLE[column];
  if (title) {
    return title;
  }
  return (
    COLUMN_TYPES[column] &&
    COLUMN_TYPES[column]
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      ?.join(' ')
  );
};

const PEER_COLUMN_TO_DATA_TYPE = Object.freeze({
  DESCRIPTION: 'description_wrap',
  COMPANY: 'peer_company_name',
  REV_PER_HEAD: 'rev_per_head'
});

export const PEER_COLUMN_TYPE_TO_EXTRA_PARAMS = Object.freeze({
  DESCRIPTION: {
    valueFormatter: (value) => {
      return typeof value?.text === 'string' || value?.text instanceof String ? value?.text : null;
    }
  },
  COMPANY: {
    valueFormatter: (value) => value.name,
    filterOperators: stringFilterOperatorsByObjectKey('name'),
    ...defaultConfig,
    extraParams: {
      allowCheckbox: true
    }
  }
});

export const getColumnExtraParams = ({ columnId, mapToParams, listId, values, history }) => {
  const extractor = mapToParams[columnId];
  const args = [];
  switch (COLUMN_TYPES[columnId]) {
    case COLUMN_TYPES.COMPANY:
      args.push(listId);
      break;
    case COLUMN_TYPES.REVENUES:
      args.push(values[COLUMN_TYPES.REVENUES]);
      break;
    case COLUMN_TYPES.SOURCE:
      args.push(values[COLUMN_TYPES.SOURCE], history);
      break;
    case COLUMN_TYPES.COUNTRY:
      args.push(values[COLUMN_TYPES.COUNTRY]);
      break;
    default:
      break;
  }
  const extraParams = args.length ? extractor(...args) : extractor;
  return extraParams || {};
};

export const getColumnsByKeys = (keys, values, columnsSettings, listId, history) =>
  keys.map((columnKey) => {
    const extraParams = getColumnExtraParams({
      columnId: columnKey,
      mapToParams: COLUMN_TYPE_TO_EXTRA_PARAMS,
      listId,
      values,
      history
    });
    return {
      id: COLUMN_TYPES[columnKey],
      name: getColumnTitle(columnKey),
      data_type: getColumnRenderType(columnKey, COLUMN_TYPE_TO_RENDER_DATA_TYPE),
      values: values[COLUMN_TYPES[columnKey]],
      display: 'flex',
      width: columnsSettings?.[COLUMN_TYPES[columnKey]]?.width,
      ...extraParams,
      extraParams: extraParams.extraParams ? { ...extraParams.extraParams, listId } : { listId }
    };
  });

export const getPeersColumns = (values, columnsSettings) =>
  Object.keys(PEER_COLUMN_TYPES).map((columnKey) => {
    const extraParams = getColumnExtraParams({
      columnId: columnKey,
      mapToParams: PEER_COLUMN_TYPE_TO_EXTRA_PARAMS
    });
    return {
      id: PEER_COLUMN_TYPES[columnKey],
      name: getColumnTitle(columnKey),
      display: 'flex',
      data_type: getColumnRenderType(columnKey, PEER_COLUMN_TO_DATA_TYPE),
      values: values[PEER_COLUMN_TYPES[columnKey]],
      width: columnsSettings?.[PEER_COLUMN_TYPES[columnKey]]?.width,
      ...extraParams
    };
  });
