import { useListContext } from 'hooks/ListContextProvider';
import React from 'react';
import { convertV1CaParametersToV2 } from 'utils/assistant/backwards-compatibility';

import ActionDisplayComponent, {
  ActionDisplayComponentProps
} from '../conversation/ActionDisplayComponent';

interface ActionDisplayComponentWrapperProps extends Omit<ActionDisplayComponentProps, 'action'> {
  post: {
    version?: number;
    action: any;
    body: any;
    query: any;
  };
}

export default function ActionDisplayComponentWrapper({
  post,
  listId
}: ActionDisplayComponentWrapperProps) {
  const listContext = useListContext();
  return (
    <ActionDisplayComponent
      action={
        post?.version === 2
          ? post.action
          : convertV1CaParametersToV2({ ...post.body, question: post.query }, listContext)
      }
      listId={listId}
      actionTaken
    />
  );
}
