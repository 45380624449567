import { Divider, Stack, Typography } from '@mui/material';
import CustomMarkdown from 'components/CustomMarkdown';
import AssistantRenderText from 'components/assistant/conversation/AssistantRenderText';
import CustomAnalyticsSourceItemComponent from 'components/elements/table/CustomAnalyticsSourceItemComponent';
import QuickViewExpandable from 'components/elements/table/quick-view/QuickViewExpandable';
import MLIcon from 'design-system/MLIcon';
import QueryIcon from 'design-system/icons/platform/QueryIcon';
import { useListContext } from 'hooks/ListContextProvider';
import { DomainReportSection } from 'models/domain-report/types';
import React from 'react';
import { convertV1CaParametersToV2 } from 'utils/assistant/backwards-compatibility';
import { AnswerType, formatAnswerBody } from 'utils/custom-analytics-utils';
import { PPTX_CLASS_NAMES } from 'utils/react-to-powerpoint-utils';
import { capitalizeFirstLetter } from 'utils/string-utils';

import DomainReportBox from '../DomainReportBox';
import { useDomainReportContext } from '../DomainReportContext';

interface DomainReportDomainKnowledgeProps {
  section: DomainReportSection;
}
export default function DomainReportDomainKnowledge({ section }: DomainReportDomainKnowledgeProps) {
  const listContext = useListContext();
  const { listId } = useDomainReportContext();
  const { title } = section.props;
  const body = section.props.data?.data?.message?.answer;
  const sourcesList = Object.values(section.props.data?.data?.message?.sources || []);
  const sourcesExist = sourcesList && sourcesList.length > 0;
  const reasoning = section.props.data?.data?.message?.reasoning;
  const displayPrompt = section.props.data?.displayPrompt;
  const displaySources = section.props.data?.displaySources;
  const displayReasoning = section.props.data?.displayReasoning;
  const query = section.props.data?.data?.query;
  const action = section.props.data?.data?.action;
  const version = section.props.data?.data?.version || 1;
  const convertedAction =
    version === 2
      ? action
      : convertV1CaParametersToV2({ ...section.props.data?.data, question: query }, listContext);

  return (
    <DomainReportBox title={title} className={PPTX_CLASS_NAMES.slideQuery}>
      <Stack gap="16px">
        {displayPrompt && (
          <Stack
            direction="row"
            gap="8px"
            sx={{
              backgroundColor: 'colors.gray_bg',
              padding: '16px',
              borderRadius: '8px'
            }}>
            <MLIcon size="small" icon={<QueryIcon />} color="colors.icon" />
            <Typography alignSelf="center">
              <AssistantRenderText text={capitalizeFirstLetter(convertedAction.text)} />
            </Typography>
          </Stack>
        )}
        <CustomMarkdown
          body={formatAnswerBody(body, AnswerType.TEXT)}
          listId={listId}
          context={title}
        />
        {displaySources && (
          <React.Fragment>
            <Divider />
            <QuickViewExpandable
              title={sourcesExist ? 'Sources' : 'No sources found'}
              disabled={!sourcesExist}
              pptxAlways>
              <Stack gap="8px">
                {sourcesList.map((source: any) => (
                  <CustomAnalyticsSourceItemComponent
                    key={source}
                    listId={listId}
                    source={source}
                  />
                ))}
              </Stack>
            </QuickViewExpandable>
          </React.Fragment>
        )}
        {displayReasoning && reasoning && (
          <React.Fragment>
            <Divider className={`${PPTX_CLASS_NAMES.ignore}`} />
            <QuickViewExpandable
              title={reasoning ? 'Reasoning' : 'No reasoning found'}
              disabled={!reasoning}
              pptxIgnore={true}>
              <Typography variant="text1_Normal">{reasoning}</Typography>
            </QuickViewExpandable>
          </React.Fragment>
        )}
      </Stack>
    </DomainReportBox>
  );
}
