import { Box, Button, Typography } from '@mui/material';
import MLInlineStack from 'design-system/MLInlineStack';
import { useMLTooltip } from 'hooks/useMLTooltip';
import AnswerIcon from 'icons/AnswerIcon';
import ColumnsIcon from 'icons/ColumnsIcon';
import PrivateIcon from 'icons/PrivateIcon';
import ShareIcon from 'icons/ShareIcon';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getSavedQueryIsShared } from 'selectors/saved-queries';
import { CATEGORY_TO_ICON, DATA_NAME_TO_ICON, SAVED_QUERY_ACTION_TYPES } from 'utils/platter-utils';

import RenderStr from '../table/RenderStr';
import { PlatterItemMenu } from './PlatterItemMenu';
import { PlatterItemTooltip } from './PlatterItemTooltip';
import { SavedQueryType } from './usePlatterItems';

interface PlatterItemComponentProps {
  onCloseDialog: () => void;
  onClick: () => void;
  isColumnInList: boolean;
  title: string;
  type: string;
  parameters: any;
  id: number;
  actionType: SavedQueryType;
  color: string;
  backgroundColor: string;
  data_name: string | null;
  category: string;
}
export const PlatterItemComponent = ({
  onCloseDialog,
  onClick,
  isColumnInList,
  title,
  type,
  parameters,
  id,
  actionType,
  color,
  backgroundColor,
  data_name,
  category
}: PlatterItemComponentProps) => {
  const showMenu = type?.includes('custom_analytics');

  const [sharedWithOrg, setSharedWithOrg] = useState(false);

  const isSharedFromDb = useSelector((state) => getSavedQueryIsShared(state, id));
  const { showTooltip, isTooltipVisible, hideTooltip } = useMLTooltip();

  useEffect(() => {
    setSharedWithOrg(isSharedFromDb);
  }, [isSharedFromDb]);

  const onItemClick = () => {
    if (isColumnInList) return;
    onClick();
  };

  const itemStyle = {
    padding: '8px',
    width: actionType === SAVED_QUERY_ACTION_TYPES.WORKFLOW ? '350px' : '230px',
    height: '40px',
    backgroundColor: isColumnInList ? 'colors.hover_on_gray_bg' : 'white',
    borderRadius: '8px',
    border: '1px solid',
    borderColor: 'colors.ui_border',
    ':hover': {
      backgroundColor: isColumnInList ? 'colors.hover_on_gray_bg' : 'colors.gray_bg'
    }
  };

  const getItemIcon = () => {
    if (type === 'custom_analytics') {
      return sharedWithOrg ? ShareIcon : PrivateIcon;
    }
    if (actionType === SAVED_QUERY_ACTION_TYPES.ASK) {
      return AnswerIcon;
    }
    return (
      DATA_NAME_TO_ICON?.[data_name as keyof typeof DATA_NAME_TO_ICON] ||
      CATEGORY_TO_ICON?.[category as keyof typeof CATEGORY_TO_ICON] ||
      ColumnsIcon
    );
  };

  return (
    <div>
      <PlatterItemTooltip
        isColumnInList={isColumnInList}
        title={title}
        parameters={parameters}
        id={id}>
        <Box sx={{ padding: 0 }} onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
          <Button
            onClick={onItemClick}
            sx={{
              padding: 0,
              cursor: isColumnInList ? 'auto' : 'pointer',
              width: '100%',
              height: '100%'
            }}>
            <Box display="flex" alignItems="center" gap="8px" sx={itemStyle}>
              <Box
                sx={{
                  backgroundColor: backgroundColor,
                  color: color,
                  borderRadius: '4px',
                  padding: '4px',
                  display: 'flex',
                  justifyContent: 'center'
                }}>
                {React.createElement(getItemIcon())}
              </Box>
              <RenderStr
                value={title}
                color={isColumnInList ? 'colors.disabled_text' : 'colors.primary_text'}
                variant="text1_Medium"
                disableTooltip={true}
              />
              <MLInlineStack marginLeft="auto">
                {actionType === SAVED_QUERY_ACTION_TYPES.WORKFLOW && isTooltipVisible && (
                  <Typography
                    noWrap
                    border="1px solid"
                    borderColor="colors.ui_border"
                    borderRadius="4px"
                    padding="4px 8px">
                    Use workflow
                  </Typography>
                )}
                {showMenu && (
                  <PlatterItemMenu
                    type={type}
                    onCloseDialog={onCloseDialog}
                    title={title}
                    parameters={parameters}
                    id={id}
                    actionType={actionType}
                  />
                )}
              </MLInlineStack>
            </Box>
          </Button>
        </Box>
      </PlatterItemTooltip>
    </div>
  );
};
