import React from 'react';

export default function CrunchbaseIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 15.4444V4.55556C3 4.143 3.16389 3.74733 3.45561 3.45561C3.74733 3.16389 4.143 3 4.55556 3H15.4444C15.857 3 16.2527 3.16389 16.5444 3.45561C16.8361 3.74733 17 4.143 17 4.55556V15.4444C17 15.857 16.8361 16.2527 16.5444 16.5444C16.2527 16.8361 15.857 17 15.4444 17H4.55556C4.143 17 3.74733 16.8361 3.45561 16.5444C3.16389 16.2527 3 15.857 3 15.4444Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.4402 9.94905C8.19936 9.70815 7.8925 9.54407 7.5584 9.47757C7.22431 9.41108 6.878 9.44515 6.56328 9.57549C6.24855 9.70583 5.97955 9.92657 5.79028 10.2098C5.60102 10.493 5.5 10.826 5.5 11.1667C5.5 11.5073 5.60102 11.8403 5.79028 12.1235C5.97955 12.4068 6.24855 12.6275 6.56328 12.7578C6.878 12.8882 7.22431 12.9223 7.5584 12.8558C7.8925 12.7893 8.19936 12.6252 8.4402 12.3843"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 11.1667C11 11.6234 11.1814 12.0615 11.5044 12.3845C11.8274 12.7074 12.2655 12.8889 12.7222 12.8889C13.179 12.8889 13.617 12.7074 13.94 12.3845C14.263 12.0615 14.4444 11.6234 14.4444 11.1667C14.4444 10.7099 14.263 10.2718 13.94 9.94887C13.617 9.62589 13.179 9.44444 12.7222 9.44444C12.2655 9.44444 11.8274 9.62589 11.5044 9.94887C11.1814 10.2718 11 10.7099 11 11.1667Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 6.5V11"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
