import { DEFAULT_NEW_CONVERSATION_MESSAGE_TEXT } from 'constants/assistant';
import { Message, MessageRole } from 'models/assistant/types';
import { v4 as uuidv4 } from 'uuid';

export function generateDefaultNewConversationMessage(): Message {
  return {
    id: uuidv4(),
    role: MessageRole.ASSISTANT,
    is_dry: true,
    content: {
      text: DEFAULT_NEW_CONVERSATION_MESSAGE_TEXT,
      raw_content: null
    }
  };
}
