import { Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { CONSENSUS_KEY, getTitleFromFinanceType } from 'utils/finance-utils';

import ActualDataIndicationComponent from './ActualDataIndicationComponent';
import FinanceSourceDataComponent from './FinanceSourceDataComponent';
import { FinanceData } from './types';

type Props = {
  menuBackgroundColor: string;
  data: FinanceData[] | null;
  isEstimated: boolean;
  financeType?: string;
};
export default function FinanceDataMenuItem({
  menuBackgroundColor,
  data,
  isEstimated,
  financeType
}: Props) {
  const financeTypeTitle = useMemo(() => {
    return getTitleFromFinanceType(financeType);
  }, [financeType]);

  const filteredData = useMemo(() => {
    return data?.filter(
      (source) =>
        source && source.source_name !== CONSENSUS_KEY && source.is_estimated === isEstimated
    );
  }, [data, isEstimated]);

  return (
    <Stack
      sx={{
        backgroundColor: menuBackgroundColor,
        color: 'white',
        padding: '4px',
        borderRadius: '4px'
      }}>
      <ActualDataIndicationComponent
        isEstimated={isEstimated}
        isOnDark={true}
        extraSX={{ padding: '4px', width: 'fit-content', marginBottom: '8px' }}
      />
      <Typography
        color="colors.text_on_primary"
        variant="text1_Semibold"
        paddingBottom="8px"
        paddingTop="4px">
        {isEstimated ? 'Estimated' : 'Actual'} {financeTypeTitle}
      </Typography>
      {filteredData?.map((source, idx) => (
        <FinanceSourceDataComponent
          key={source.source_name}
          sourceData={source}
          hasBorderBottom={idx < filteredData.length - 1}
        />
      ))}
    </Stack>
  );
}
