import { IconButton, IconButtonProps } from '@mui/material';
import React, { forwardRef } from 'react';

export interface MLBasicIconButtonProps extends IconButtonProps {
  variant?: 'contained' | 'outlined' | 'text';
  color?: 'primary' | 'inverted';
}

const MLBasicIconButton = forwardRef<HTMLButtonElement, MLBasicIconButtonProps>(
  ({ variant = 'contained', color = 'primary', size = 'medium', children, ...extraProps }, ref) => {
    const muiIconButtonColor = `${color}_${variant.charAt(0).toUpperCase()}${variant.slice(1)}` as
      | 'primary_Contained'
      | 'primary_Outlined'
      | 'primary_Text'
      | 'inverted_Contained'
      | 'inverted_Outlined'
      | 'inverted_Text';
    return (
      <IconButton ref={ref} color={muiIconButtonColor} size={size} {...extraProps}>
        {children}
      </IconButton>
    );
  }
);

MLBasicIconButton.displayName = 'MLBasicIconButton';

export default MLBasicIconButton;
