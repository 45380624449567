import { isEmpty } from 'lodash';
import { updateSubdomainsDiscoveryRecords } from 'reducer/domain-research-slice';

export function updateSubdomainDiscoveriesFromHistoryAction(listId, history) {
  return (dispatch) => {
    if (!history) {
      return;
    }
    const recordsWithSubdomains =
      Object.values(history).filter((record) => record.subdomain_id) || [];
    const subdomainIdToRecordId = recordsWithSubdomains?.reduce(
      (acc, { record_id, subdomain_id }) => {
        acc[subdomain_id] = record_id;
        return acc;
      },
      {}
    );
    if (isEmpty(subdomainIdToRecordId)) {
      return;
    }
    dispatch(updateSubdomainsDiscoveryRecords({ listId, subdomainIdToRecordId }));
  };
}
