import { Box, Button, Checkbox, Stack, TextField, Typography } from '@mui/material';
import ModalComponent from 'components/modal/ModalComponent';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { appTheme } from 'theme';

import { useDomainResearchContext } from './DomainResearchContextProvider';

function DiscoveryFromSubdomainsDialog({ open, onClose, listId }) {
  const [customLine, setCustomLine] = useState('');
  const {
    onSubmitDiscovery: onSubmit,
    selectedDomains,
    subdomains: allSubdomains
  } = useDomainResearchContext(listId);
  const [localSelectedDomains, setLocalSelectedDomains] = useState(new Set());
  const subdomainsToSubmit = allSubdomains?.filter((subdomain) =>
    selectedDomains.has(subdomain.id)
  );

  useEffect(() => {
    setLocalSelectedDomains(new Set(selectedDomains));
  }, [selectedDomains]);

  const onClickSubdomain = (subdomainId) => {
    const newSelectedDomains = new Set(localSelectedDomains);
    if (newSelectedDomains.has(subdomainId)) {
      newSelectedDomains.delete(subdomainId);
    } else {
      newSelectedDomains.add(subdomainId);
    }
    setLocalSelectedDomains(newSelectedDomains);
  };
  const handleInputChange = (event) => {
    setCustomLine(event.target.value);
  };

  const handleClose = () => {
    onClose();
    setLocalSelectedDomains(new Set(selectedDomains));
    setCustomLine('');
  };

  const handleSubmit = () => {
    onSubmit(localSelectedDomains, customLine);
    handleClose();
  };

  return (
    <ModalComponent
      title="Add companies to the list"
      open={open}
      onClose={handleClose}
      maxWidth={526}
      bottomBar={
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: '100%', padding: '8px' }}>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" disabled={!localSelectedDomains.size} onClick={handleSubmit}>
            Add companies
          </Button>
        </Stack>
      }>
      <Stack direction="column" gap="16px" sx={{ padding: '24px' }}>
        <Typography variant="text1_Medium" color="primary.primary150">
          Companies will be added based on {localSelectedDomains?.size} selected items:
        </Typography>
        <Stack direction="column">
          {subdomainsToSubmit?.map((subdomain) => (
            <Stack key={subdomain.id} direction="row" alignItems="center">
              <Checkbox
                onClick={() => onClickSubdomain(subdomain.id)}
                checked={localSelectedDomains.has(subdomain.id)}
              />
              <Typography variant="text1_Normal" color="colors.primary_text">
                {subdomain.name}
              </Typography>
            </Stack>
          ))}
        </Stack>

        <Typography variant="text1_Medium" color="primary.primary150">
          Optional context
        </Typography>

        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
          <TextField
            multiline
            value={customLine}
            maxRows={6}
            minRows={1}
            onChange={handleInputChange}
            placeholder="E.g. Headquartered in the UK"
            fullWidth
            slotProps={{
              input: { sx: { color: 'colors.secondary_text', ...appTheme.typography.text1_Normal } }
            }}
          />
        </Box>
      </Stack>
    </ModalComponent>
  );
}

DiscoveryFromSubdomainsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  listId: PropTypes.number
};

export default DiscoveryFromSubdomainsDialog;
