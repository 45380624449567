import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import ActionDisplayComponent from 'components/assistant/conversation/ActionDisplayComponent';
import PromptDisplayComponent from 'components/discovery/PromptDisplayComponent';
import { useListContext } from 'hooks/ListContextProvider';
import CopyIcon from 'icons/CopyIcon';
import React from 'react';
import { convertV1CaParametersToV2 } from 'utils/assistant/backwards-compatibility';

interface QuerySettingsDialogProps {
  open: boolean;
  onClose: () => void;
  info: any;
  listId: number;
  columnName: string;
  onSaveQuery: (id: string) => void;
  onCopyContent: () => void;
  canUnify: boolean;
}

export function QuerySettingsDialog({
  open,
  onClose,
  info,
  listId,
  columnName,
  onSaveQuery,
  onCopyContent,
  canUnify
}: QuerySettingsDialogProps) {
  const listContext = useListContext();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">Column Query</DialogTitle>
      <DialogContent>
        {canUnify && info?.question ? (
          <ActionDisplayComponent
            action={convertV1CaParametersToV2(info, listContext) as any}
            listId={listId as number}
            actionTaken
          />
        ) : (
          <PromptDisplayComponent
            listId={listId}
            prompt={info}
            columnName={columnName}
            onSaveQuery={onSaveQuery}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onCopyContent} startIcon={<CopyIcon color="white" />}>
          Copy Content
        </Button>
      </DialogActions>
    </Dialog>
  );
}
