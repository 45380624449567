import { CATEGORIES } from 'utils/platter-utils';

export interface Item {
  id: string;
  name: string;
  type: string;
}

// Transform the data
export const transformedDataTobeIdAndName = (data: Item[]) =>
  data.reduce((acc: Record<string, string>, item) => {
    acc[item.id as string] = item.name;
    return acc;
  }, {} as Record<string, string>);

export const getTypeName = (type: string) => {
  switch (type) {
    case 'custom_boolean':
      return CATEGORIES.MANUAL;
    case 'custom_str':
      return CATEGORIES.MANUAL;
    case 'custom_number':
      return CATEGORIES.MANUAL;
    case 'custom_analytics':
      return 'Custom Analytics';
    case 'basic_data_column':
      return CATEGORIES.BASIC;
    default:
      return 'Extra column';
  }
};

const sortGroupedByData = (data: Record<string, Item[]>) => {
  const entries = Object.entries(data);

  const sorted = entries.sort(([keyA], [keyB]) => {
    if (keyA === 'Custom Analytics') return -1;
    if (keyB === 'Custom Analytics') return 1;
    return keyA.localeCompare(keyB);
  });

  return Object.fromEntries(sorted);
};

// Transform the data into the desired format
export const transformData = (data: Record<string, any>): Record<string, Item[]> => {
  const groupedData: Record<string, Item[]> = {};

  Object.values(data).forEach((item) => {
    const type = getTypeName(item.type);
    if (!groupedData[type]) {
      groupedData[type] = [];
    }
    groupedData[type].push({
      id: item.id,
      type,
      name: item.name
    });
  });

  return sortGroupedByData(groupedData);
};
