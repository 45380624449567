import { sendUserEvent } from 'actions/users/send-user-event-action';
import { DOMAIN_REPORT_EVENTS } from 'constants/userEvents';
import { addDomainReport } from 'reducer/domain-reports-slice';

import { bindDomainReportPusherAction } from './bind-domain-report-pusher-action';

export function submitDomainReportTaskAction({ listId, domainReportId, section, parameters }) {
  return (dispatch, _getState, apiClient) => {
    dispatch(
      sendUserEvent(DOMAIN_REPORT_EVENTS.DOMAIN_REPORT_CA_TASK_SUBMISSION, {
        listId,
        domainReportId,
        section,
        parameters
      })
    );
    apiClient.explore
      .submitExploreListDomainReportTask(listId, domainReportId, section, parameters)
      .then((res) => {
        if (res.ok && res.data.exists) {
          dispatch(addDomainReport({ listId, data: res.data }));
          if (res.data?.domain_report?.parameters?.sections) {
            res.data.domain_report.parameters.sections
              .filter((new_section) => new_section.id === section.props.id)
              .forEach((new_section) => {
                if (new_section?.data?.post_id) {
                  dispatch(
                    bindDomainReportPusherAction({
                      listId,
                      domainReportId: res.data.domain_report_id,
                      postId: new_section.data.post_id
                    })
                  );
                }
              });
          }
        }
      });
  };
}
