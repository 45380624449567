import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { useAppUtils } from 'AppUtilsProvider';
import { updateResourcePermissionsAction } from 'actions/permissions/update-resource-permissions-action';
import { deleteSavedQueryAction } from 'actions/saved_queries/delete-saved-query-action';
import { renameSavedQueryAction } from 'actions/saved_queries/rename-saved-query-action';
import SetQueryNameDialog from 'components/discovery/SetQueryNameDialog';
import { RESOURCE_TYPES } from 'constants/resources';
import MLIconButton from 'design-system/MLIconButton';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import CopyIcon from 'icons/CopyIcon';
import DeleteIcon from 'icons/DeleteIcon';
import EditIcon from 'icons/EditIcon';
import MenuIcon from 'icons/MenuIcon';
import ShareIcon from 'icons/ShareIcon';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSavedQueryIsShared } from 'selectors/saved-queries';
import { getUserId, getUserOrganizationId } from 'selectors/user';

import DuplicateLibraryWorkflowComponent from './DuplicateLibraryWorkflowComponent';

function WorkflowsLibraryRowMenu({ listId, query }) {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(false);
  const [workflowName, setWorkflowName] = useState('');
  const [isRenameDialogOpen, setIsRenameDialogOpen] = useState(false);
  const [isDuplicateDialogOpen, setIsDuplicateDialogOpen] = useState(false);
  const { addToast } = useAppUtils();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const organizationId = useOldStateSelector(getUserOrganizationId);
  const userId = useOldStateSelector(getUserId);

  const [sharedWithOrg, setSharedWithOrg] = useState(false);
  const isSharedFromDb = useSelector((state) => getSavedQueryIsShared(state, query?.savedQueryId));

  useEffect(() => {
    setSharedWithOrg(isSharedFromDb);
  }, [isSharedFromDb]);

  const handleShareToOrg = () => {
    dispatch(
      updateResourcePermissionsAction(
        query.savedQueryId,
        RESOURCE_TYPES.SAVED_QUERY,
        [],
        sharedWithOrg ? [] : [organizationId],
        [],
        addToast
      )
    );
    setSharedWithOrg(!sharedWithOrg);
  };

  const handleRenameWorkflow = (event) => {
    event.stopPropagation();
    setWorkflowName(query?.name);
    setIsRenameDialogOpen(true);
    handleClose();
  };

  const handleDuplicateWorkflow = (event) => {
    event.stopPropagation();
    setIsDuplicateDialogOpen(true);
    handleClose();
  };

  const handleDeleteWorkflow = (event) => {
    event.stopPropagation();
    dispatch(deleteSavedQueryAction(query.id, addToast));
    handleClose();
  };

  const closeRenameDialog = () => {
    setIsRenameDialogOpen(false);
    setWorkflowName(query?.name);
  };

  const handleRenameSubmit = () => {
    dispatch(renameSavedQueryAction(query?.id, workflowName));
    closeRenameDialog();
  };

  const menuItems = [
    {
      id: 'rename',
      text: 'Rename',
      startIcon: <EditIcon />,
      onClick: handleRenameWorkflow,
      hidden: userId !== query?.owner?.id
    },
    {
      id: 'share',
      text: sharedWithOrg ? 'Change to private' : 'Share with account',
      startIcon: <ShareIcon />,
      onClick: handleShareToOrg,
      hidden: userId !== query?.owner?.id
    },
    {
      id: 'duplicate',
      startIcon: <CopyIcon color="currentColor" />,
      onClick: handleDuplicateWorkflow,
      text: 'Duplicate'
    },
    {
      id: 'delete',
      text: 'Delete',
      startIcon: <DeleteIcon />,
      onClick: handleDeleteWorkflow,
      hidden: userId !== query?.owner?.id
    }
  ];

  return (
    <React.Fragment>
      <MLIconButton sx={{ padding: 0 }} onClick={handleOpen}>
        <MenuIcon color="currentColor" />
      </MLIconButton>

      <Menu
        MenuListProps={{ sx: { py: 0 } }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {menuItems
          .filter(({ hidden }) => !hidden)
          .map(({ id, startIcon, onClick, text, getText }) => (
            <MenuItem key={id} onClick={onClick}>
              <ListItemIcon>{startIcon}</ListItemIcon>
              <ListItemText>{text || getText()}</ListItemText>
            </MenuItem>
          ))}
      </Menu>
      <SetQueryNameDialog
        title="Rename Workflow"
        open={isRenameDialogOpen}
        onClose={closeRenameDialog}
        columnName={workflowName}
        setColumnName={setWorkflowName}
        onSaveQuery={handleRenameSubmit}
      />
      {isDuplicateDialogOpen && (
        <DuplicateLibraryWorkflowComponent
          listId={listId}
          workflow={query?.parameters}
          workflowName={query?.name}
          dialogOnly={true}
          open={isDuplicateDialogOpen}
          onClose={() => setIsDuplicateDialogOpen(false)}
        />
      )}
    </React.Fragment>
  );
}

WorkflowsLibraryRowMenu.propTypes = {
  listId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  query: PropTypes.object,
  planIdsSet: PropTypes.object
};

export default WorkflowsLibraryRowMenu;
