import { isDomainReportCASectionType } from 'utils/domain-report/domain-report-ts-utils';

export const getDomainReports = (state) => state.domainReports;
export const getDomainReportsByListId = (state, listId) =>
  getDomainReports(state).domainReports?.[listId];
export const getLatestDomainReportByListId = (state, listId) => {
  const domainReports = getDomainReportsByListId(state, listId);
  if (!domainReports) return null;
  if (Object.keys(domainReports).length === 0) return null;
  return Object.values(domainReports || []).sort(
    (dr1, dr2) => dr2.domain_report_id - dr1.domain_report_id
  )[0];
};
export const getLatestDomainReportIdByListId = (state, listId) => {
  const domainReport = getLatestDomainReportByListId(state, listId);
  if (!domainReport) return null;
  return domainReport.domain_report_id;
};
export const getLatestDRCompanyMetaIdsByListId = (state, listId) => {
  const domainReport = getLatestDomainReportByListId(state, listId) || {};
  return domainReport?.parameters?.company_meta_ids;
};
export const getLatestDRCompanyMetasByListId = (state, listId) => {
  const domainReport = getLatestDomainReportByListId(state, listId) || {};
  return domainReport?.company_metas || [];
};

export const getLatestDRFilteredOutCompanyMetaIdsByListId = (state, listId) => {
  const domainReport = getLatestDomainReportByListId(state, listId) || {};
  return domainReport?.parameters?.filtered_out_company_meta_ids;
};
export const getLatestDRFiltersByListId = (state, listId) => {
  const domainReport = getLatestDomainReportByListId(state, listId) || {};
  return domainReport?.parameters?.filters;
};
export const getLatestDRTaskByListId = (state, listId, taskType) => {
  const domainReport = getLatestDomainReportByListId(state, listId) || {};
  const result = domainReport.parameters.sections?.find((section) => section.type === taskType);
  return result?.data;
};
export const getLatestDRTaskStatusByListId = (state, listId, taskType) => {
  if (!listId || !taskType) return null;
  const task = getLatestDRTaskByListId(state, listId, taskType) || {};
  if (!task.status) return 'RUNNING';
  return task.status;
};

export const getLatestDRStatusByListId = (state, listId) => {
  const domainReport = getLatestDomainReportByListId(state, listId);
  if (!domainReport) return null;
  const sectionTypes = domainReport.parameters.sections?.map((section) => section.type);
  const caSections = sectionTypes.filter((sectionType) => isDomainReportCASectionType(sectionType));
  const taskStatuses = caSections.map((sectionType) =>
    getLatestDRTaskStatusByListId(state, listId, sectionType)
  );
  if (taskStatuses.length === 0) return 'RUNNING';
  if (taskStatuses.includes('FAILED')) return 'FAILED';
  if (taskStatuses.includes('RUNNING')) return 'RUNNING';
  if (taskStatuses.includes('SUCCEEDED')) return 'SUCCEEDED';
  return domainReport.status || 'RUNNING';
};
export const getLatestDRTaskProgressByListId = (state, listId, taskType) => {
  if (!listId || !taskType) return null;
  const task = getLatestDRTaskByListId(state, listId, taskType) || {};
  if (task.status === 'SUCCEEDED') {
    return 1;
  }
  if (!task.progress) return 0.05;
  return task.progress.progress;
};
export const getLatestDRProgressByListId = (state, listId) => {
  const domainReport = getLatestDomainReportByListId(state, listId);
  if (!domainReport) return null;
  const taskTypes = domainReport.parameters.sections?.map((value) => value.type);
  const taskProgresses = taskTypes.map((taskType) =>
    getLatestDRTaskProgressByListId(state, listId, taskType)
  );
  if (taskProgresses.length === 0) return 0;
  return (
    0.2 +
    0.8 * (taskProgresses.reduce((acc, progress) => acc + progress, 0) / taskProgresses.length)
  );
};
export const getDomainReportDefaultConfig = (state) => state.domainReports.defaultConfig;
export const getDomainReportTaskDefaultConfig = (state, taskType) =>
  state.domainReports.defaultConfig?.[taskType];
