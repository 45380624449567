import { Box, Button, Input, Stack } from '@mui/material';
import MLIconButton from 'design-system/MLIconButton';
import CloseIcon from 'design-system/icons/platform/CloseIcon';
import AddBulkIllustration from 'icons/AddBulkIllustration';
import PropTypes from 'prop-types';

export default function EnterNameStepComponent({
  name,
  setName,
  onCancel,
  onCreateReport,
  buttonText,
  placeholderText
}) {
  const onKeyDown = (event) => {
    if (event?.key === 'Enter') {
      onCreateReport();
      return;
    }
    event.stopPropagation();
  };

  return (
    <Stack
      padding="32px"
      direction="column"
      gap="24px"
      alignItems="center"
      sx={{ width: 'fit-content' }}>
      <MLIconButton onClick={onCancel} sx={{ alignSelf: 'flex-end' }}>
        <CloseIcon />
      </MLIconButton>
      <Box sx={{ padding: '0 140px' }}>
        <AddBulkIllustration />
      </Box>
      <Input
        placeholder={placeholderText ? placeholderText : 'Company List Name'}
        value={name}
        size="small"
        autoFocus
        disableUnderline={true}
        sx={{
          flex: 1
        }}
        onChange={(event) => setName(event.target.value)}
        onKeyDown={onKeyDown}
      />
      <Button variant="contained" disabled={!name} onClick={onCreateReport}>
        {buttonText ? buttonText : 'Create Company List'}
      </Button>
    </Stack>
  );
}

EnterNameStepComponent.propTypes = {
  name: PropTypes.string,
  setName: PropTypes.func,
  onCancel: PropTypes.func,
  onCreateReport: PropTypes.func,
  buttonText: PropTypes.string,
  placeholderText: PropTypes.string
};
