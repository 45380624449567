import { WORKFLOW_SELECTED_TYPE } from 'constants/workflows';
import { addPostQueryToThread, getThreadKey } from 'reducer/custom-analytics';
import {
  updateSelectedWorkflowIdByListId,
  updateWorkflowFormModeByIdAndListId
} from 'reducer/workflows-slice';

export function addEmptyWorkflowAction(listId, workflowName, startFromScratch) {
  return (dispatch) => {
    const threadKey = getThreadKey(listId);
    const newPostId = new Date().getTime();
    const plan = { question: startFromScratch ? null : '', isWorkflow: true };
    dispatch(
      addPostQueryToThread({
        threadKey,
        postId: newPostId,
        plan,
        query: '',
        body: { hidePost: true },
        discoveryId: 1,
        messages: [],
        isWorkflow: true,
        name: workflowName
      })
    );
    dispatch(
      updateSelectedWorkflowIdByListId({
        listId,
        selectedWorkflowId: newPostId,
        selectedType: WORKFLOW_SELECTED_TYPE.DRAFT
      })
    );
    dispatch(
      updateWorkflowFormModeByIdAndListId({ listId, workflowId: newPostId, isFormMode: true })
    );
  };
}
