import React from 'react';

export enum DomainReportSectionType {
  EXPLORE_PROCESS = 'EXPLORE_PROCESS',
  MARKET_LANDSCAPE = 'MARKET_OVERVIEW',
  TOP_PROBLEMS_BEING_SOLVED = 'TOP_PROBLEMS',
  CLUSTER_ANALYSIS = 'TAGGING',
  DOMAIN_KNOWLEDGE = 'DOMAIN_KNOWLEDGE'
}

export const domainReportCASectionsValues = [
  DomainReportSectionType.MARKET_LANDSCAPE,
  DomainReportSectionType.TOP_PROBLEMS_BEING_SOLVED,
  DomainReportSectionType.CLUSTER_ANALYSIS
] as const;

export type DomainReportCASections = typeof domainReportCASectionsValues[number];

export enum DomainReportSidebarView {
  MAIN = 'MAIN',
  EDIT = 'EDIT',
  CREATE = 'CREATE'
}

export enum DomainReportEditQueryType {
  DEFAULT = 'DEFAULT',
  CUSTOM = 'CUSTOM'
}

export enum DomainReportCaTaskType {
  TAGGING = 'TAGGING',
  TOP_PROBLEMS = 'TOP_PROBLEMS',
  MARKET_OVERVIEW = 'MARKET_OVERVIEW'
}

export enum DomainReportStatus {
  RUNNING = 'RUNNING',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED'
}

export interface DomainReport {
  domain_report_id: number;
  company_list_id: number;
  time_submitted: string;
  parameters: any;
  custom_analytics: any;
  status: DomainReportStatus;
}

export interface DomainReportSectionProps {
  id: string;
  type: DomainReportSectionType;
  isHidden: boolean;
  title: string;
  data?: any;
}
export interface DomainReportSectionPresentationProps {
  defaultTitle: string;
  iconComponent: React.ReactNode;
  isEditable: boolean;
  isAddable: boolean;
  isDeletable: boolean;
  customAnalyticsTaskType?: DomainReportCaTaskType;
}
export interface DomainReportSection {
  props: DomainReportSectionProps;
  presentationProps: DomainReportSectionPresentationProps;
}
