import { Button, Stack, TextField, Typography } from '@mui/material';
import { generateWorkflowAction } from 'actions/workflows/generate-workflow-action';
import { updateMainPromptInWorkflowAction } from 'actions/workflows/update-main-prompt-in-workflow-action';
import EditableMultilineTextarea from 'components/EditableMultilineTextarea';
import MLIconButton from 'design-system/MLIconButton';
import EditIcon from 'icons/EditIcon';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Handle, Position } from 'reactflow';
import { getThreadKey } from 'reducer/custom-analytics';
import { getCustomAnalyticsPlanMainPromptByThreadAndPostId } from 'selectors/custom-analytics';
import { getIsWorkflowReadOnlyModeByListId } from 'selectors/workflows';
import { applyThemeColorOpacity } from 'utils/theme-utils';

function WorkFlowPlanMainPromptNode({ data }) {
  const dispatch = useDispatch();
  const {
    listId,
    postId,
    label,
    color,
    Icon,
    width,
    height,
    isEmptyPlan,
    showTopHandle = true,
    showBottomHandle = true
  } = data;

  const threadKey = getThreadKey(listId);
  const mainPrompt = useSelector((state) =>
    getCustomAnalyticsPlanMainPromptByThreadAndPostId(state, threadKey, postId)
  );
  const isReadOnly = useSelector((state) => getIsWorkflowReadOnlyModeByListId(state, listId));

  const [isHover, setIsHover] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [originalMainPrompt] = useState(mainPrompt);

  const onValueChange = (event) => {
    dispatch(updateMainPromptInWorkflowAction(listId, postId, event.target.value));
  };

  const onGenerateButtonClick = () => {
    dispatch(generateWorkflowAction(listId, mainPrompt, true));
    dispatch(updateMainPromptInWorkflowAction(listId, postId, originalMainPrompt));
  };

  const toggleEditMode = () => setIsEditMode(!isEditMode);

  const cancelRegenerate = () => {
    dispatch(updateMainPromptInWorkflowAction(listId, postId, originalMainPrompt));
    setIsEditMode(false);
  };

  return (
    <Stack
      // disable node dragging
      className="nodrag"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      sx={{
        width: width,
        height: height,
        padding: '16px',
        boxSizing: 'border-box',
        border: '1.5px solid',
        borderColor: applyThemeColorOpacity(color, 50),
        color: 'colors.primary_text',
        backgroundColor: applyThemeColorOpacity(color, 7),
        borderRadius: '16px',
        textAlign: 'left',
        margin: 0,
        minHeight: 0
      }}>
      <Stack direction="column" gap="8px">
        <Stack direction="row" alignItems="center" justifyContent="space-between" gap="8px">
          <Stack direction="row" alignItems="center" gap="8px">
            {Icon && (
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: 32,
                  height: 32,
                  backgroundColor: applyThemeColorOpacity(color, 12),
                  borderRadius: '6px'
                }}
                color={color}>
                <Icon />
              </Stack>
            )}
            {label && (
              <Typography variant="text1_Bold" color={color}>
                {label}
              </Typography>
            )}
          </Stack>
          <Stack direction="row" alignItems="center" gap="0px">
            {!isEmptyPlan && !isReadOnly && (
              <MLIconButton
                tooltipText="Regenerate Workflow"
                onClick={toggleEditMode}
                sx={{ visibility: isHover ? 'visible' : 'hidden' }}>
                <EditIcon />
              </MLIconButton>
            )}
          </Stack>
        </Stack>
        {(isEmptyPlan || isEditMode) && !isReadOnly ? (
          <TextField
            multiline
            minRows={isEditMode ? 4 : 5}
            maxRows={isEditMode ? 4 : 5}
            variant="outlined"
            placeholder="Describe the type of companies that interest you and what you would like to learn about them...."
            value={mainPrompt}
            sx={{ backgroundColor: 'primary.white' }}
            onChange={onValueChange}
          />
        ) : (
          <EditableMultilineTextarea
            text={mainPrompt}
            onTextChange={onValueChange}
            maxLines={5}
            maxWidth={360}
            allowEdit={isEmptyPlan}
            placeholder="Describe the type of companies that interest you and what you would like to learn about them...."
          />
        )}
        {isEditMode && (
          <Stack direction="row" alignItems="center" justifyContent="flex-end" gap="8px">
            <Button onClick={cancelRegenerate}>Cancel</Button>
            <Button
              variant="contained"
              onClick={onGenerateButtonClick}
              disabled={!mainPrompt || mainPrompt.length <= 0}>
              Regenerate
            </Button>
          </Stack>
        )}
      </Stack>
      {showTopHandle && (
        <Handle type="target" position={Position.Top} style={{ visibility: 'hidden', top: 0 }} />
      )}
      {showBottomHandle && (
        <Handle
          type="source"
          position={Position.Bottom}
          style={{ visibility: 'hidden', bottom: 0 }}
        />
      )}
    </Stack>
  );
}

WorkFlowPlanMainPromptNode.propTypes = {
  data: PropTypes.object.isRequired
};

export default WorkFlowPlanMainPromptNode;
