import React from 'react';

export default function SlashIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8 5H7.2C5.98497 5 5 5.98497 5 7.2V16.5C5 17.715 5.98497 19 7.2 19H16.8C18.015 19 19 17.715 19 16.5V7.2C19 5.98497 18.015 5 16.8 5ZM7.2 4C5.43269 4 4 5.43269 4 7.2V16.5C4 18.2673 5.43269 20 7.2 20H16.8C18.5673 20 20 18.2673 20 16.5V7.2C20 5.43269 18.5673 4 16.8 4H7.2Z"
        fill="currentColor"
      />
      <path d="M10.0372 15.9267L13.9637 8" stroke="currentColor" strokeLinecap="round" />
    </svg>
  );
}
