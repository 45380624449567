// import { updateTeams } from 'reducer/teams-slice';
import { addAllSavedQueries } from 'reducer/saved-queries-slice';

export const translateSavedQuery = (savedQuery, isPredefined) => {
  return {
    id: savedQuery.id,
    name: savedQuery.name,
    type: savedQuery.query_type,
    parameters: savedQuery.parameters,
    isShared: savedQuery.is_shared,
    description: savedQuery.description,
    time_created: savedQuery.time_created,
    owner: savedQuery.user,
    collaboratorsUsers: savedQuery.collaborators_users,
    collaboratorsOrgs: savedQuery.collaborators_orgs,
    collaboratorsTeams: savedQuery.collaborators_teams,
    isPredefined
  };
};
export function loadSavedQueriesAction() {
  return (dispatch, _getState, apiClient) => {
    apiClient.saved_queries.fetchUserSavedQueries().then((res) => {
      if (res.ok && res.data) {
        const savedQueries =
          res.data.saved_queries?.reduce((prev, query) => {
            prev[query.id] = translateSavedQuery(query, false);
            return prev;
          }, {}) || {};
        const globalSavedQueries =
          res.data.global_saved_queries?.reduce((prev, query) => {
            prev[query.id] = translateSavedQuery(query, true);
            return prev;
          }, {}) || {};

        dispatch(addAllSavedQueries({ savedQueries: { ...savedQueries, ...globalSavedQueries } }));
      }
    });
  };
}
