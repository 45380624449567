import NumberRangeFilterComponent from 'components/elements/table/filters/columns/components/NumberRangeFilterComponent';
import PropTypes from 'prop-types';
import React from 'react';

import TextColumnFilter from './TextColumnFilter';

function CustomAnalyticsColumnFilter({ rows, columnId, ...rest }) {
  if (rows?.[0]?.[columnId]?.value?.answer_type === 'number') {
    const rowsWithNumberAnswers = rows.map((row) => ({
      ...row,
      [columnId]: {
        ...row[columnId],
        value: { ...row[columnId].value, answer: parseFloat(row[columnId].value.answer) }
      }
    }));
    return (
      <NumberRangeFilterComponent
        columnId={columnId}
        rows={rowsWithNumberAnswers}
        {...rest}
        rowMap={(value) => value?.value?.answer}
      />
    );
  }
  return <TextColumnFilter {...rest} />;
}

CustomAnalyticsColumnFilter.propTypes = {
  rows: PropTypes.array,
  columnId: PropTypes.string
};

export default CustomAnalyticsColumnFilter;
