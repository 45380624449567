import { Box, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

function DomainReportBreakdownItem({ leftText, rightText, percent, component }) {
  return (
    <Stack
      justifyContent="space-between"
      sx={{
        color: 'colors.primary_text',
        minHeight: '35px',
        maxHeight: '35px',
        borderRadius: '4px',
        backgroundColor: 'colors.gray_bg'
      }}>
      {component ? (
        component
      ) : (
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography
            sx={{
              marginTop: '8px',
              marginX: '8px',
              textOverflow: 'ellipsis',
              textWrap: 'nowrap',
              overflowX: 'hidden'
            }}
            variant="text1_Normal">
            {leftText}
          </Typography>
          <Typography sx={{ marginTop: '8px', marginX: '8px' }} variant="text1_Bold">
            {rightText}
          </Typography>
        </Stack>
      )}
      {percent ? (
        <Box
          sx={{
            backgroundColor: 'graphColors.graph13',
            height: '3px',
            borderRadius: `0px ${percent === 100 ? 0 : 4}px 4px 4px`,
            width: `${percent}%`
          }}
        />
      ) : null}
    </Stack>
  );
}

DomainReportBreakdownItem.propTypes = {
  leftText: PropTypes.string,
  rightText: PropTypes.string,
  percent: PropTypes.number,
  component: PropTypes.node
};

export default DomainReportBreakdownItem;
