import { Stack, Typography } from '@mui/material';
import { WORK_FLOW_STEP_TYPES } from 'constants/workflows';
import MLInlineStack from 'design-system/MLInlineStack';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { applyThemeColorOpacity } from 'utils/theme-utils';

import WorkflowAttributeTitleComponent from './WorkflowAttributeTitleComponent';

function WorkflowFromStepMetaComponent({ step, isReadOnly, maxWidth }) {
  const [isHover, setIsHover] = useState();
  const { data, parentId } = step;
  const {
    Icon: StepIcon,
    color,
    stepIndex,
    action,
    queryIndex,
    attributeName,
    attributeTitle,
    attributeDefaultTitle,
    listId,
    postId,
    stepId
  } = data;
  const { label } = WORK_FLOW_STEP_TYPES[action];
  return (
    <MLInlineStack
      key={step.step_id}
      maxWidth={maxWidth}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          width: '24px',
          height: '24px',
          borderRadius: '4px',
          color,
          backgroundColor: applyThemeColorOpacity(color, 12)
        }}>
        <StepIcon />
      </Stack>
      <Stack width="100%">
        <Typography variant="text1_Bold" color={color}>
          {`${stepIndex + 1}${parentId ? '.' : ''}${parentId ? queryIndex + 1 : ''} ${label}`}
        </Typography>
        <WorkflowAttributeTitleComponent
          listId={listId}
          workflowId={postId}
          stepId={stepId}
          attributeName={attributeName}
          attributeTitle={attributeTitle}
          attributeDefaultTitle={attributeDefaultTitle}
          color={color}
          isEditMode={isHover && !isReadOnly}
        />
      </Stack>
    </MLInlineStack>
  );
}

WorkflowFromStepMetaComponent.propTypes = {
  step: PropTypes.object,
  isReadOnly: PropTypes.object,
  maxWidth: PropTypes.string
};

export default WorkflowFromStepMetaComponent;
