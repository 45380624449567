import React from 'react';

export default function DragIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.84964 6.43265C9.17733 6.43265 8.63232 5.88808 8.63232 5.21632C8.63232 4.54457 9.17733 4 9.84964 4C10.5219 4 11.067 4.54457 11.067 5.21632C11.067 5.88808 10.5219 6.43265 9.84964 6.43265Z"
        fill="currentColor"
      />
      <path
        d="M8.63184 9.73878C8.63184 10.4105 9.17685 10.9551 9.84915 10.9551C10.5215 10.9551 11.0665 10.4105 11.0665 9.73878C11.0665 9.06703 10.5215 8.52246 9.84915 8.52246C9.17685 8.52246 8.63184 9.06703 8.63184 9.73878Z"
        fill="currentColor"
      />
      <path
        d="M14.2173 10.9551C13.545 10.9551 13 10.4105 13 9.73878C13 9.06703 13.545 8.52246 14.2173 8.52246C14.8896 8.52246 15.4346 9.06703 15.4346 9.73878C15.4346 10.4105 14.8896 10.9551 14.2173 10.9551Z"
        fill="currentColor"
      />
      <path
        d="M9.84915 15.4775C9.17685 15.4775 8.63184 14.933 8.63184 14.2612C8.63184 13.5895 9.17685 13.0449 9.84915 13.0449C10.5215 13.0449 11.0665 13.5895 11.0665 14.2612C11.0665 14.933 10.5215 15.4775 9.84915 15.4775Z"
        fill="currentColor"
      />
      <path
        d="M14.2173 15.4775C13.545 15.4775 13 14.933 13 14.2612C13 13.5895 13.545 13.0449 14.2173 13.0449C14.8896 13.0449 15.4346 13.5895 15.4346 14.2612C15.4346 14.933 14.8896 15.4775 14.2173 15.4775Z"
        fill="currentColor"
      />
      <path
        d="M9.84915 20C9.17685 20 8.63184 19.4554 8.63184 18.7837C8.63184 18.1119 9.17685 17.5674 9.84915 17.5674C10.5215 17.5674 11.0665 18.1119 11.0665 18.7837C11.0665 19.4554 10.5215 20 9.84915 20Z"
        fill="currentColor"
      />
      <path
        d="M14.2173 20C13.545 20 13 19.4554 13 18.7837C13 18.1119 13.545 17.5674 14.2173 17.5674C14.8896 17.5674 15.4346 18.1119 15.4346 18.7837C15.4346 19.4554 14.8896 20 14.2173 20Z"
        fill="currentColor"
      />
      <path
        d="M14.2178 6.43265C13.5455 6.43265 13.0005 5.88808 13.0005 5.21632C13.0005 4.54457 13.5455 4 14.2178 4C14.8901 4 15.4351 4.54457 15.4351 5.21632C15.4351 5.88808 14.8901 6.43265 14.2178 6.43265Z"
        fill="currentColor"
      />
    </svg>
  );
}
