import { OutlinedInput, Stack, Typography } from '@mui/material';
import { useAppUtils } from 'AppUtilsProvider';
import { duplicateHistoryWorkflowAction } from 'actions/workflows/duplicate-history-workflow-action';
import MLButton from 'design-system/MLButton';
import MLModal from 'design-system/MLModal';
import CopyIcon from 'icons/CopyIcon';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

function DuplicateLibraryWorkflowComponent({
  listId,
  workflow,
  workflowName,
  dialogOnly,
  open,
  onClose
}) {
  const dispatch = useDispatch();
  const { addToast } = useAppUtils();

  const [duplicatedWorkflowName, setDuplicatedWorkflowName] = useState('');
  const [isEditLibraryWorkflowOpen, setIsEditLibraryWorkflowOpen] = useState(false);

  useEffect(() => {
    if (workflowName) {
      setDuplicatedWorkflowName(`Copy ${workflowName}`);
    }
  }, [workflowName]);

  const onCloseDialog = () => {
    setIsEditLibraryWorkflowOpen(false);
    onClose?.();
  };

  const onDuplicateWorkflow = () => {
    dispatch(duplicateHistoryWorkflowAction(listId, workflow, duplicatedWorkflowName, addToast));
    onCloseDialog();
  };

  return (
    <React.Fragment>
      {!dialogOnly && (
        <MLButton
          variant="outlined"
          startIcon={<CopyIcon color={'currentColor'} />}
          onClick={() => setIsEditLibraryWorkflowOpen(true)}
          sx={{ whiteSpace: 'nowrap' }}>
          Duplicate
        </MLButton>
      )}
      <MLModal
        title="Save a copy of this workflow"
        open={isEditLibraryWorkflowOpen || open}
        primaryButtonProps={{ label: 'save', onClick: onDuplicateWorkflow }}
        secondaryButtonProps={{
          label: 'Cancel',
          onClick: onCloseDialog
        }}
        onClose={onCloseDialog}>
        <Stack gap="8px">
          <Typography variant="text1_Medium">Workflow name</Typography>
          <OutlinedInput
            size="small"
            value={duplicatedWorkflowName}
            onChange={(event) => setDuplicatedWorkflowName(event.target.value)}
            placeholder="Write the workflow name here..."
            autoFocus
            sx={{
              width: '100%',
              height: '32px'
            }}
          />
        </Stack>
      </MLModal>
    </React.Fragment>
  );
}

DuplicateLibraryWorkflowComponent.propTypes = {
  listId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  workflow: PropTypes.object,
  workflowName: PropTypes.string,
  dialogOnly: PropTypes.bool,
  open: PropTypes.bool,
  onClose: PropTypes.func
};

DuplicateLibraryWorkflowComponent.defaultProps = {
  dialogOnly: false
};

export default DuplicateLibraryWorkflowComponent;
