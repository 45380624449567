import { createTheme } from '@mui/material';

const appFontFamily = ['"Inter"', 'sans-serif'].join(',');

/* eslint-disable max-lines */
export const appTheme = createTheme({
  cssVariables: true,
  status: {
    danger: '#e53e3e'
  },
  palette: {
    colors: {
      main: '#7B5BF3',
      primary: '#7B5BF3',
      hover_on_primary: '#6244DC',
      selected: '#E9E6FF',
      hover_on_selected: '#B8C0D9',
      hover_on_primary_bg: '#F4F5FD',
      hover_on_gray_bg: '#E7EBF6',
      primary_text: '#0B1C38',
      secondary_text: '#5C5E76',
      placeholder_text: '#5C5E76',
      text_on_primary: '#FFFFFF',
      secondary_text_on_primary: '#A8B2C2',
      disabled_text: '#0B1C3861',
      icon: '#5C5E76',
      ui_border: '#D7DCE4',
      disabled_bg: '#D7DCE4',
      link: '#579BFC',
      positive_green: '#04BD00',
      hover_on_positive_green: '#00A45A',
      selected_positive_green: '#DDF5EA',
      disabled_hover_on_positive_green: '#00A45A80',
      disabled_positive_green: '#04BD0080',
      negative_red: '#F20530',
      hover_on_negative_red: '#DB0028',
      selected_negative_red: '#FCE6EA',
      warning_yellow: '#F0CF50',
      informative_blue: '#374EF4',
      poor_orange: '#FF8811',
      primary_bg: '#FFFFFF',
      gray_bg: '#F8F9FF',
      dark_bg: '#0B1C38'
    },
    accent_colors: {
      main: '#8795F8',
      bright_blue: '#579BFC',
      royal_blue: '#374EF4',
      lavender: '#8795F8',
      dark_lavender: '#807EF1',
      teal: '#87CAB9',
      dark_teal: '#24A3A0',
      sun: '#FFD549',
      red: '#F20530',
      orange: '#FF8811',
      yellow: '#F3BA2F',
      green: '#9CD326',
      dark_green: '#536E15',
      lilac: '#C8A9CD',
      dusty_lilac: '#AF85B7',
      berry: '#7E3B8A',
      bubble: '#DC9DE6',
      orchid: '#C45CD6',
      peach: '#EF8B86',
      tulip: '#E2445C',
      positive_green_bg1: '#B7ECDD',
      positive_green_bg2: '#EBFAF5',
      bright_blue_bg1: '#DBE9FF',
      bright_blue_bg2: '#F2F7FF',
      dark_lavender_bg1: '#E6E6FC',
      dark_lavender_bg2: '#F5F5FE',
      orchid_bg1: '#F4E1F8',
      orchid_bg2: '#FCF6FD',
      poor_orange_bg1: '#FFE3C7',
      poor_orange_bg2: '#FFF8F1'
    },
    graphColors: {
      graph1: '#579BFC',
      graph2: '#374EF4',
      graph3: '#807EF1',
      graph4: '#7E3B8A',
      graph5: '#E2445C',
      graph6: '#9CD326',
      graph7: '#24A3A0',
      graph8: '#87CAB9',
      graph9: '#FFD549',
      graph10: '#C45CD6',
      graph11: '#EF8B86',
      graph12: '#536E15',
      graph13: '#8FBCFC'
    },
    coding: {
      coding1: '#B5186D',
      coding2: '#9A2FC5',
      coding3: '#0072C0',
      coding4: '#0593A2',
      coding5: '#FF8811',
      coding6: '#00BD84'
    }
  },
  typography: {
    fontFamily: appFontFamily,
    h1_Bold: {
      fontSize: 24,
      lineHeight: '29px',
      fontStyle: 'normal',
      fontWeight: 600,
      fontFamily: appFontFamily
    },
    h1_Semibold: {
      fontSize: 24,
      lineHeight: '29px',
      fontStyle: 'normal',
      fontWeight: 500,
      fontFamily: appFontFamily
    },
    h1_Normal: {
      fontSize: 24,
      lineHeight: '29px',
      fontStyle: 'normal',
      fontWeight: 400,
      fontFamily: appFontFamily
    },
    h2_Bold: {
      fontSize: 20,
      lineHeight: '24px',
      fontStyle: 'normal',
      fontWeight: 700,
      fontFamily: appFontFamily
    },
    h2_Semibold: {
      fontSize: 20,
      lineHeight: '24px',
      fontStyle: 'normal',
      fontWeight: 600,
      fontFamily: appFontFamily
    },
    h2_Normal: {
      fontSize: 20,
      lineHeight: '24px',
      fontStyle: 'normal',
      fontWeight: 400,
      fontFamily: appFontFamily
    },
    h3_Bold: {
      fontSize: 16,
      lineHeight: '19.4px',
      fontStyle: 'normal',
      fontWeight: 700,
      fontFamily: appFontFamily
    },
    h3_Semibold: {
      fontSize: 16,
      lineHeight: '19.4px',
      fontStyle: 'normal',
      fontWeight: 500,
      fontFamily: appFontFamily
    },
    h3_Normal: {
      fontSize: 16,
      lineHeight: '19.4px',
      fontStyle: 'normal',
      fontWeight: 400,
      fontFamily: appFontFamily
    },
    h4: {
      fontSize: 16,
      lineHeight: '19.36px',
      fontStyle: 'normal',
      fontWeight: 700,
      fontFamily: appFontFamily
    },
    text1_Bold: {
      fontSize: 14,
      lineHeight: '20px',
      fontStyle: 'normal',
      fontWeight: 700,
      fontFamily: appFontFamily
    },
    text1_Semibold: {
      fontSize: 14,
      lineHeight: '20px',
      fontStyle: 'normal',
      fontWeight: 600,
      fontFamily: appFontFamily
    },
    text1_Medium: {
      fontSize: 14,
      lineHeight: '20px',
      fontStyle: 'normal',
      fontWeight: 500,
      fontFamily: appFontFamily
    },
    text1_Normal: {
      fontSize: 14,
      lineHeight: '20px',
      fontStyle: 'normal',
      fontWeight: 400,
      fontFamily: appFontFamily
    },
    text2_Bold: {
      fontSize: 12,
      lineHeight: '20px',
      fontStyle: 'normal',
      fontWeight: 700,
      fontFamily: appFontFamily
    },
    text2_Medium: {
      fontSize: 12,
      lineHeight: '20px',
      fontStyle: 'normal',
      fontWeight: 500,
      fontFamily: appFontFamily
    },
    text2_Normal: {
      fontSize: 12,
      lineHeight: '20px',
      fontStyle: 'normal',
      fontWeight: 400,
      fontFamily: appFontFamily
    },
    mediumNumber: {
      fontSize: 32,
      lineHeight: '38px',
      fontStyle: 'normal',
      fontWeight: 700,
      fontFamily: appFontFamily
    },
    largeNumber: {
      fontSize: 48,
      lineHeight: '58px',
      fontStyle: 'normal',
      fontWeight: 600,
      fontFamily: appFontFamily
    }
  },
  zIndex: {
    topDialog: 1301,
    statusMonitor: 101,
    topBar: 100,
    mlSideBar: 99,
    searchBar: 99,
    overviewTabs: 99,
    overviewSummary: 98,
    domainReportHeader: 97,
    screeningPanel: 100
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        indicator: ({ theme }) => ({
          backgroundColor: theme.vars.palette.colors.primary,
          height: '4px'
        })
      }
    },
    MuiTab: {
      styleOverrides: {
        textColorPrimary: ({ theme }) => ({
          color: theme.vars.palette.colors.text_on_primary,
          marginLeft: '8px',
          marginRight: '8px',
          paddingLeft: '8px',
          paddingRight: '8px',
          textTransform: 'none',
          ...theme.typography.text1_Medium,
          '&:hover': {
            color: theme.vars.palette.colors.primary
          },
          '&.Mui-selected': {
            color: theme.vars.palette.colors.text_on_primary
          }
        }),
        textColorSecondary: ({ theme }) => ({
          color: theme.vars.palette.colors.primary_text,
          textTransform: 'none',
          ...theme.typography.text1_Normal,
          '&:hover': {
            color: theme.vars.palette.colors.primary
          },
          '&.Mui-selected': {
            color: theme.vars.palette.colors.primary
          }
        })
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          whiteSpace: 'nowrap',
          textTransform: 'none',
          backgroundColor: theme.vars.palette.colors.primary_bg,
          color: theme.vars.palette.colors.secondary_text,
          width: '100px',
          padding: '4px',
          '&.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
            borderLeft: '1px solid',
            borderColor: theme.vars.palette.colors.ui_border
          },
          '&.MuiToggleButton-root.Mui-selected': {
            backgroundColor: theme.vars.palette.colors.primary,
            color: theme.vars.palette.colors.text_on_primary,
            borderColor: theme.vars.palette.colors.primary
          }
        })
      }
    },
    MuiTypography: {
      defaultProps: {
        variant: 'text1_Normal'
      }
    },
    MuiButton: {
      defaultProps: {
        variant: 'text',
        color: 'primary',
        size: 'medium',
        disableElevation: true
      },
      styleOverrides: {
        root: ({ theme }) => ({
          textTransform: 'capitalize',
          ...theme.typography.text1_Normal,
          borderRadius: '4px'
        }),
        sizeLarge: {
          padding: '10px 16px',
          height: '40px'
        },
        sizeMedium: {
          padding: '6px 8px',
          height: '32px'
        },
        sizeSmall: {
          padding: '2px 8px',
          height: '24px'
        },
        icon: {
          color: 'inherit'
        },
        startIcon: {
          marginRight: '4px'
        },
        endIcon: {
          marginLeft: '4px'
        },
        containedPrimary: ({ theme }) => ({
          color: theme.vars.palette.colors.text_on_primary,
          backgroundColor: theme.vars.palette.colors.primary,
          '&:hover': {
            backgroundColor: theme.vars.palette.colors.hover_on_primary
          },
          '&.Mui-pressed': {
            backgroundColor: theme.vars.palette.colors.hover_on_primary
          },
          '&.Mui-selected': {
            backgroundColor: theme.vars.palette.colors.selected
          },
          '&.Mui-disabled': {
            backgroundColor: theme.vars.palette.colors.disabled_bg
          }
        }),
        outlinedPrimary: ({ theme }) => ({
          color: theme.vars.palette.colors.primary_text,
          borderColor: theme.vars.palette.colors.ui_border,
          '&:hover': {
            backgroundColor: theme.vars.palette.colors.hover_on_primary_bg
          },
          '&.Mui-pressed': {
            backgroundColor: theme.vars.palette.colors.selected
          },
          '&.Mui-selected': {
            backgroundColor: theme.vars.palette.colors.selected
          },
          '&.Mui-disabled': {
            color: theme.vars.palette.colors.disabled_text
          }
        }),
        textPrimary: ({ theme }) => ({
          color: theme.vars.palette.colors.primary_text,
          '&:hover': {
            backgroundColor: theme.vars.palette.colors.hover_on_primary_bg
          },
          '&.Mui-pressed': {
            backgroundColor: theme.vars.palette.colors.selected
          },
          '&.Mui-selected': {
            backgroundColor: theme.vars.palette.colors.selected
          },
          '&.Mui-disabled': {
            color: theme.vars.palette.colors.disabled_text
          }
        }),
        containedInverted: ({ theme }) => ({
          color: theme.vars.palette.colors.primary_text,
          backgroundColor: theme.vars.palette.colors.primary_bg,
          '&:hover': {
            backgroundColor: theme.vars.palette.colors.hover_on_gray_bg
          },
          '&.Mui-pressed': {
            backgroundColor: theme.vars.palette.colors.hover_on_gray_bg
          },
          '&.Mui-selected': {
            backgroundColor: theme.vars.palette.colors.hover_on_gray_bg
          },
          '&.Mui-disabled': {
            backgroundColor: theme.vars.palette.colors.disabled_bg
          }
        }),
        outlinedInverted: ({ theme }) => ({
          color: theme.vars.palette.colors.text_on_primary,
          borderColor: theme.vars.palette.colors.text_on_primary,
          '&:hover': {
            backgroundColor: theme.vars.palette.colors.placeholder_text
          },
          '&.Mui-pressed': {
            backgroundColor: theme.vars.palette.colors.placeholder_text
          },
          '&.Mui-selected': {
            backgroundColor: theme.vars.palette.colors.placeholder_text
          },
          '&.Mui-disabled': {
            color: theme.vars.palette.colors.disabled_text,
            borderColor: theme.vars.palette.colors.disabled_text
          }
        }),
        textInverted: ({ theme }) => ({
          color: theme.vars.palette.colors.text_on_primary,
          '&:hover': {
            backgroundColor: theme.vars.palette.colors.placeholder_text
          },
          '&.Mui-pressed': {
            backgroundColor: theme.vars.palette.colors.placeholder_text
          },
          '&.Mui-selected': {
            backgroundColor: theme.vars.palette.colors.placeholder_text
          },
          '&.Mui-disabled': {
            color: theme.vars.palette.colors.disabled_text
          }
        }),
        containedPositive: ({ theme }) => ({
          color: theme.vars.palette.colors.text_on_primary,
          backgroundColor: theme.vars.palette.colors.positive_green,
          '&:hover': {
            backgroundColor: theme.vars.palette.colors.hover_on_positive_green
          },
          '&.Mui-pressed': {
            backgroundColor: theme.vars.palette.colors.hover_on_positive_green
          },
          '&.Mui-selected': {
            backgroundColor: theme.vars.palette.colors.hover_on_positive_green
          },
          '&.Mui-disabled': {
            backgroundColor: theme.vars.palette.colors.disabled_positive_green
          }
        }),
        outlinedPositive: ({ theme }) => ({
          color: theme.vars.palette.colors.hover_on_positive_green,
          borderColor: theme.vars.palette.colors.hover_on_positive_green,
          '&:hover': {
            backgroundColor: theme.vars.palette.colors.selected_positive_green
          },
          '&.Mui-pressed': {
            backgroundColor: theme.vars.palette.colors.selected_positive_green
          },
          '&.Mui-selected': {
            backgroundColor: theme.vars.palette.colors.selected_positive_green
          },
          '&.Mui-disabled': {
            color: theme.vars.palette.colors.disabled_hover_on_positive_green
          }
        }),
        textPositive: ({ theme }) => ({
          color: theme.vars.palette.colors.hover_on_positive_green,
          '&:hover': {
            backgroundColor: theme.vars.palette.colors.selected_positive_green
          },
          '&.Mui-pressed': {
            backgroundColor: theme.vars.palette.colors.selected_positive_green
          },
          '&.Mui-selected': {
            backgroundColor: theme.vars.palette.colors.selected_positive_green
          },
          '&.Mui-disabled': {
            color: theme.vars.palette.colors.disabled_hover_on_positive_green
          }
        })
      }
    },
    MuiIconButton: {
      defaultProps: {
        color: 'secondary',
        size: 'large'
      },
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: '4px',
          '&:hover': {
            backgroundColor: theme.vars.palette.colors.hover_on_primary_bg
          },
          '&.Mui-disabled': {
            color: theme.vars.palette.colors.disabled_text
          }
        }),
        sizeLarge: {
          padding: '8px',
          width: '40px',
          height: '40px'
        },
        sizeMedium: {
          padding: '4px',
          width: '32px',
          height: '32px'
        },
        sizeSmall: {
          padding: '0px',
          width: '24px',
          height: '24px'
        },
        sizeTiny: {
          padding: '0px',
          width: '16px',
          height: '16px'
        },
        colorPrimary: ({ theme }) => ({
          color: theme.vars.palette.colors.text_on_primary,
          '&:hover': {
            backgroundColor: theme.vars.palette.colors.hover_on_primary_bg
          },
          '&.Mui-disabled': {
            color: theme.vars.palette.colors.disabled_text
          }
        }),
        colorSecondary: ({ theme }) => ({
          color: theme.vars.palette.colors.secondary_text,
          '&:hover': {
            backgroundColor: theme.vars.palette.colors.hover_on_primary_bg
          },
          '&.Mui-disabled': {
            color: theme.vars.palette.colors.selected
          }
        }),
        colorPrimary_Contained: ({ theme }) => ({
          color: theme.vars.palette.colors.text_on_primary,
          backgroundColor: theme.vars.palette.colors.primary,
          '&:hover': {
            backgroundColor: theme.vars.palette.colors.hover_on_primary
          },
          '&:Mui-selected': {
            backgroundColor: theme.vars.palette.colors.hover_on_primary
          },
          '&:Mui-pressed': {
            backgroundColor: theme.vars.palette.colors.hover_on_primary
          },
          '&.Mui-disabled': {
            backgroundColor: theme.vars.palette.colors.disabled_bg
          }
        }),
        colorPrimary_Outlined: ({ theme }) => ({
          color: theme.vars.palette.colors.icon,
          border: '1px solid',
          borderColor: theme.vars.palette.colors.ui_border,
          '&:hover': {
            backgroundColor: theme.vars.palette.colors.hover_on_primary_bg
          },
          '&:Mui-selected': {
            backgroundColor: theme.vars.palette.colors.selected
          },
          '&:Mui-pressed': {
            backgroundColor: theme.vars.palette.colors.selected
          },
          '&.Mui-disabled': {
            color: theme.vars.palette.colors.disabled_text
          }
        }),
        colorPrimary_Text: ({ theme }) => ({
          color: theme.vars.palette.colors.icon,
          '&:hover': {
            backgroundColor: theme.vars.palette.colors.hover_on_primary_bg
          },
          '&:Mui-selected': {
            backgroundColor: theme.vars.palette.colors.selected
          },
          '&:Mui-pressed': {
            backgroundColor: theme.vars.palette.colors.selected
          },
          '&.Mui-disabled': {
            color: theme.vars.palette.colors.disabled_text
          }
        }),
        colorInverted_Contained: ({ theme }) => ({
          color: theme.vars.palette.colors.primary_text,
          backgroundColor: theme.vars.palette.colors.primary_bg,
          '&:hover': {
            backgroundColor: theme.vars.palette.colors.hover_on_gray_bg
          },
          '&:Mui-selected': {
            backgroundColor: theme.vars.palette.colors.hover_on_gray_bg
          },
          '&:Mui-pressed': {
            backgroundColor: theme.vars.palette.colors.hover_on_gray_bg
          },
          '&.Mui-disabled': {
            backgroundColor: theme.vars.palette.colors.disabled_text
          }
        }),
        colorInverted_Outlined: ({ theme }) => ({
          color: theme.vars.palette.colors.text_on_primary,
          border: '1px solid',
          borderColor: theme.vars.palette.colors.text_on_primary,
          '&:hover': {
            backgroundColor: theme.vars.palette.colors.placeholder_text
          },
          '&:Mui-selected': {
            backgroundColor: theme.vars.palette.colors.placeholder_text
          },
          '&:Mui-pressed': {
            backgroundColor: theme.vars.palette.colors.placeholder_text
          },
          '&.Mui-disabled': {
            color: theme.vars.palette.colors.disabled_text,
            borderColor: theme.vars.palette.colors.disabled_text
          }
        }),
        colorInverted_Text: ({ theme }) => ({
          color: theme.vars.palette.colors.text_on_primary,
          '&:hover': {
            backgroundColor: theme.vars.palette.colors.placeholder_text
          },
          '&:Mui-selected': {
            backgroundColor: theme.vars.palette.colors.placeholder_text
          },
          '&:Mui-pressed': {
            backgroundColor: theme.vars.palette.colors.placeholder_text
          },
          '&.Mui-disabled': {
            color: theme.vars.palette.colors.disabled_text
          }
        })
      }
    },
    MuiPopover: {
      styleOverrides: {
        paper: ({ theme }) => ({
          color: theme.vars.palette.colors.text_on_primary,
          backgroundColor: theme.vars.palette.colors.dark_bg,
          padding: '8px',
          marginTop: '4px'
        })
      }
    },
    MuiMenu: {
      styleOverrides: {
        paper: ({ theme }) => ({
          '&.MuiMenu-paper': {
            ...theme.typography.text1_Normal,
            backgroundColor: theme.vars.palette.colors.primary_bg,
            color: theme.vars.palette.colors.primary_text
          }
        })
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ theme }) => ({
          ...theme.typography.text1_Medium,
          backgroundColor: theme.vars.palette.colors.dark_bg,
          color: theme.vars.palette.colors.text_on_primary,
          padding: '8px',
          marginTop: '4px'
        }),
        arrow: ({ theme }) => ({
          color: theme.vars.palette.colors.dark_bg
        })
      }
    },
    MuiChip: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.vars.palette.colors.hover_on_gray_bg,
          borderRadius: '4px',
          color: theme.vars.palette.colors.primary_text
        }),
        outlinedPrimary: ({ theme }) => ({
          backgroundColor: 'rgba(0,0,0,0)',
          borderColor: theme.vars.palette.colors.link,
          borderRadius: '4px',
          border: '2px solid',
          fontWeight: 'bold',
          color: theme.vars.palette.colors.link
        })
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.MuiAutocomplete-clearIndicator': {
            visibility: 'visible'
          }
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.vars.palette.colors.icon,
          '&.Mui-checked': {
            color: theme.vars.palette.colors.link
          }
        })
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        root: ({ theme }) => ({
          '.MuiDataGrid-columnSeparator': {
            display: 'none'
          },
          '.MuiDataGrid-main': {
            border: `1px solid ${theme.vars.palette.colors.ui_border}`,
            borderRadius: '4px 4px 4px 4px',
            color: theme.vars.palette.colors.primary_text,
            backgroundColor: 'white'
          },
          '&.MuiDataGrid-root': {
            border: 'none'
          },
          '.MuiDataGrid-footerContainer': {
            border: 'none',
            justifyContent: 'center'
          },
          '.MuiDataGrid-columnHeaders': {
            backgroundColor: theme.vars.palette.colors.hover_on_gray_bg,
            font: {
              family: theme.typography.fontFamily,
              color: theme.vars.palette.colors.ui_border,
              size: theme.typography.text1_Semibold.fontSize,
              weight: theme.typography.text1_Semibold.fontWeight
            }
          }
        })
      }
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.vars.palette.colors.secondary_text,
          ...theme.typography.text1_Normal,
          '&.Mui-selected': {
            ...theme.typography.text1_Medium,
            color: theme.vars.palette.colors.primary_text,
            backgroundColor: 'rgba(0,0,0,0)'
          }
        })
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: ({ theme }) => ({
          backgroundColor: theme.vars.palette.colors.primary_bg,
          color: theme.vars.palette.colors.primary_text,
          borderRadius: '8px'
        })
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: () => ({
          paddingX: '24px',
          paddingBottom: '32px'
        })
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.vars.palette.colors.gray_bg,
          padding: '16px',
          borderTop: '1px solid',
          borderColor: theme.vars.palette.colors.gray_bg
        })
      }
    },
    MuiSkeleton: {
      defaultProps: {
        animation: 'wave'
      },
      styleOverrides: {
        root: {
          borderRadius: '4px'
        }
      }
    }
  }
});
