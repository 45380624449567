import { Box } from '@mui/material';
import MLChip from 'design-system/MLChip';
import React from 'react';

type Props = {
  isOnDark: boolean;
  isEstimated: boolean;
  extraSX?: React.CSSProperties;
};

function ActualDataIndicationComponent({ isOnDark, isEstimated, extraSX = {} }: Props) {
  const backgroundColor = isOnDark ? 'colors.secondary_text' : '#D6DCED';

  return (
    <Box sx={{ ...extraSX }}>
      <MLChip
        label={isEstimated ? 'E' : 'A'}
        sx={{ backgroundColor }}
        labelVariant="text1_Medium"
      />
    </Box>
  );
}

export default ActualDataIndicationComponent;
