import React from 'react';

const AddSmallIcon = () => {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 1C5.5 0.723858 5.27614 0.5 5 0.5C4.72386 0.5 4.5 0.723858 4.5 1V4.5L1 4.5C0.723858 4.5 0.5 4.72386 0.5 5C0.5 5.27614 0.723858 5.5 1 5.5H4.5V9C4.5 9.27614 4.72386 9.5 5 9.5C5.27614 9.5 5.5 9.27614 5.5 9V5.5H9C9.27614 5.5 9.5 5.27614 9.5 5C9.5 4.72386 9.27614 4.5 9 4.5L5.5 4.5V1Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default AddSmallIcon;
