/* eslint-disable max-lines */

/* eslint-disable max-lines-per-function */
import { Box, Stack } from '@mui/material';
import { deleteSubdomainAction } from 'actions/domain-research/delete-subdomain-action';
import CloseIcon from 'design-system/icons/platform/CloseIcon';
import ArrowIcon from 'icons/ArrowIcon';
import { ArrowRightIcon } from 'icons/ArrowRightIcon';
import DeleteIcon from 'icons/DeleteIcon';
import { NewListIcon } from 'icons/NewListIcon';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'utils/objects-utils';
import { DOMAIN_RESEARCH_COMPONENT_TYPES, MAX_DISCOVERIES_TO_SUBMIT } from 'utils/subdomains';

import DeleteSubdomainsDialog from './DeleteSubdomainsDialog';
import DiscoveryFromSubdomainsDialog from './DiscoveryFromSubdomainsDialog';
import DomainResearchBottomBar from './DomainResearchBottomBar';
import { useDomainResearchContext } from './DomainResearchContextProvider';
import DomainResearchEmptyStateMenu from './DomainResearchEmptyStateMenu';
import GenerateFollowup from './GenerateFollowup';
import SelectFollowupMenu from './SelectFollowupMenu';
import SubdomainsLevel from './SubdomainsLevel';

function DomainResearchTab({ listId, headerHeight }) {
  const dispatch = useDispatch();
  const [openDeleteDialig, setOpenDeleteDialog] = useState(false);
  const [openGenerateDialog, setOpenGenerateDialog] = useState(false);
  const [followupDialogAnchorEl, setFollowupDialogAnchorEl] = useState(false);
  const [openDiscoveryDialog, setOpenDiscoveryDialog] = useState(false);

  const {
    selectedDomains,
    setSelectedDomains,
    subdomains,
    submitSubdomain: submitSingleSubdomain
  } = useDomainResearchContext(listId);

  const groupByLevel = (allSubdomains) => {
    return allSubdomains.reduce((acc, subdomain) => {
      if (acc[subdomain.level]) {
        acc[subdomain.level].push(subdomain);
      } else {
        acc[subdomain.level] = [subdomain];
      }
      return acc;
    }, {});
  };

  const groupByPrefix = (parentDomains) => {
    return parentDomains.reduce((acc, subdomain) => {
      const prefix = subdomain.parameters?.prompt_prefix || '';
      if (acc[prefix]) {
        acc[prefix].push(subdomain);
      } else {
        acc[prefix] = [subdomain];
      }
      return acc;
    }, {});
  };

  const groupByParentId = (levelDomains) => {
    return levelDomains.reduce((acc, subdomain) => {
      const parentId = subdomain.parent_subdomain_id || -1;
      if (acc[parentId]) {
        acc[parentId].push(subdomain);
      } else {
        acc[parentId] = [subdomain];
      }
      return acc;
    }, {});
  };

  const formatLevels = useCallback((domainsByLevels) => {
    return Object.keys(domainsByLevels).map((level) => {
      const parentDomainsDict = groupByParentId(domainsByLevels[level]);
      return {
        level,
        levelDomains: Object.keys(parentDomainsDict).map((parentId) => {
          const domainsDict = groupByPrefix(parentDomainsDict[parentId]);
          return {
            parentId,
            parentDomains: Object.keys(domainsDict).map((prefix) => {
              return { prefix, prefixDomains: domainsDict[prefix] };
            })
          };
        })
      };
    });
  }, []);

  const levels = useMemo(() => {
    if (isEmpty(subdomains)) {
      return [{ level: 0, levelDomains: [{ parentId: null, parentDomains: [] }] }];
    }
    const domainsByLevels = groupByLevel(subdomains);
    return formatLevels(domainsByLevels);
  }, [subdomains, formatLevels]);

  const submitSubdomains = async (extendType, userPrefix) => {
    const submit = (subdomain, shouldScrollToCategory) => {
      submitSingleSubdomain(subdomain, extendType, userPrefix, shouldScrollToCategory);
    };
    if (subdomains.length === 1) {
      submit(subdomains[0]);
      return;
    }

    const submitItemsSync = async (items) => {
      let isFirst = true;

      for (const item of items) {
        // eslint-disable-next-line no-await-in-loop
        await submit(item, isFirst);
        if (isFirst) {
          isFirst = false;
        }
      }
    };

    const subdomainsToSubmit = subdomains.filter((subdomain) => selectedDomains.has(subdomain.id));
    await submitItemsSync(subdomainsToSubmit);
    setSelectedDomains(new Set());
  };

  const onDelete = () => {
    setOpenDeleteDialog(false);
    const subdomainsToDelete = subdomains.filter((subdomain) => selectedDomains.has(subdomain.id));
    setSelectedDomains(new Set());
    subdomainsToDelete.forEach((subdomain) => {
      dispatch(deleteSubdomainAction(listId, subdomain.id));
    });
  };

  const bottomBarActions = [
    {
      id: 'generate-followups',
      title: 'Add follow-up',
      onClick: (event) => setFollowupDialogAnchorEl(event.currentTarget),
      icon: <ArrowRightIcon color="black" />,
      endIcon: <ArrowIcon rotation={followupDialogAnchorEl ? '180' : '0'} />
    },
    {
      id: 'trigger-discovery',
      title: 'Find Companies',
      onClick: () => setOpenDiscoveryDialog(true),
      icon: <NewListIcon />,
      disabled: selectedDomains.size > MAX_DISCOVERIES_TO_SUBMIT,
      disabledMessage: `Select up to ${MAX_DISCOVERIES_TO_SUBMIT} items to find companies.`
    },
    {
      id: 'remove',
      title: 'Delete',
      onClick: () => setOpenDeleteDialog(true),
      icon: <DeleteIcon />
    },
    {
      id: 'close',
      onClick: () => setSelectedDomains(new Set()),
      icon: <CloseIcon />
    }
  ];

  const isEmptyState = subdomains.length === 1 && subdomains[0]?.id;
  const marginTop = 12;
  return (
    <Stack
      width="100%"
      marginTop={`${marginTop}px`}
      textAlign="left"
      height={`calc(100vh - ${headerHeight + marginTop}px)`}>
      <Stack direction="row" gap="8px" sx={{ overflowX: 'auto' }}>
        {levels.map(({ level, levelDomains }, index) => (
          <Stack direction="row" gap="8px" key={level}>
            <SubdomainsLevel
              key={level}
              levelDomains={levelDomains}
              listId={listId}
              idx={Number(level)}
            />
            <Stack direction="row" gap="8px" alignItems="flex-start">
              {(index === 0 || index !== levels.length - 1) && !isEmpty(subdomains) && (
                <Box paddingTop="8px">
                  <ArrowRightIcon />
                </Box>
              )}
              {isEmptyState && (
                <DomainResearchEmptyStateMenu
                  onFollowUp={() => setOpenGenerateDialog(true)}
                  listId={listId}
                  mainDomainId={subdomains[0]?.id}
                  name={subdomains[0]?.name}
                />
              )}
            </Stack>
          </Stack>
        ))}
      </Stack>
      <Box width="100%" display="flex" justifyContent="center">
        <DomainResearchBottomBar
          selectedDomains={selectedDomains}
          bottomBarActions={bottomBarActions}
        />
      </Box>
      <DeleteSubdomainsDialog
        open={openDeleteDialig}
        onClose={() => setOpenDeleteDialog(false)}
        onDelete={onDelete}
        componentType={
          isEmptyState || selectedDomains.has(subdomains?.[0]?.id)
            ? DOMAIN_RESEARCH_COMPONENT_TYPES.MAIN_DOMAIN
            : DOMAIN_RESEARCH_COMPONENT_TYPES.ITEM
        }
        numSelectedSubdomains={selectedDomains.size}
      />

      <SelectFollowupMenu
        anchorEl={followupDialogAnchorEl}
        setAnchorEl={setFollowupDialogAnchorEl}
        onCustomQuery={() => setOpenGenerateDialog(true)}
        onSubmitSubdomains={submitSubdomains}
      />
      <GenerateFollowup
        open={openGenerateDialog}
        onClose={() => setOpenGenerateDialog(false)}
        onSubmit={submitSubdomains}
      />
      {selectedDomains.size > 0 && (
        <DiscoveryFromSubdomainsDialog
          open={openDiscoveryDialog}
          onClose={() => setOpenDiscoveryDialog(false)}
          listId={listId}
        />
      )}
    </Stack>
  );
}

DomainResearchTab.propTypes = {
  listId: PropTypes.number,
  headerHeight: PropTypes.number
};

export default DomainResearchTab;
