import { Stack, Typography } from '@mui/material';
import MLIconButton from 'design-system/MLIconButton';
import CloseIcon from 'design-system/icons/platform/CloseIcon';
import React from 'react';

import MLInlineStack from './MLInlineStack';
import ArrowLeftIcon from './icons/basic/ArrowLeftIcon';

interface MLSidebarHeaderProps {
  title: string;
  isBackButton?: boolean;
  onBack?: () => void;
  onClose: () => void;
}

export default function MLSidebarHeader({
  title,
  isBackButton,
  onBack,
  onClose
}: MLSidebarHeaderProps) {
  return (
    <Stack
      sx={{ padding: '16px', paddingBottom: '12px', position: 'sticky', top: 0 }}
      direction="row"
      justifyContent="space-between"
      alignItems="center">
      <MLInlineStack>
        {isBackButton && (
          <MLIconButton onClick={onBack}>
            <ArrowLeftIcon />
          </MLIconButton>
        )}
        <Typography variant="h3_Bold">{title}</Typography>
      </MLInlineStack>
      <MLIconButton onClick={onClose}>
        <CloseIcon />
      </MLIconButton>
    </Stack>
  );
}
