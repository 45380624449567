import { MenuItem, Stack, Typography } from '@mui/material';
import MLInlineStack from 'design-system/MLInlineStack';
import useMenuManagement from 'design-system/hooks/useMenuManagement';
import React from 'react';

import MLIconMenuButton from './MLIconMenuButton';

const itemSx = {
  minHeight: '32px',
  paddingRight: '12px',
  borderRadius: '4px',
  color: 'colors.secondary_text'
};

interface MLIconMenuEnumButtonOptionsValue {
  icon: React.ElementType;
  label: string;
}
export type MLIconMenuEnumButtonOptions<T extends string> = {
  [K in T]: MLIconMenuEnumButtonOptionsValue;
};

interface MLIconMenuEnumButtonProps<T extends string> {
  options: MLIconMenuEnumButtonOptions<T>;
  updateValue: (value: T) => void;
  value: T | null;
  title?: string;
}

export default function MLIconMenuEnumButton<T extends string>({
  title,
  value,
  updateValue,
  options
}: MLIconMenuEnumButtonProps<T>) {
  const menuManagement = useMenuManagement<HTMLButtonElement>();
  const handleMenuItemClick = (newValue: T) => {
    updateValue(newValue);
    menuManagement.onClose();
  };
  if (!value) return null;
  return (
    <MLIconMenuButton
      tooltipText={options[value].label}
      tooltipPlacement="top"
      menuManagement={menuManagement}
      menuContent={
        <Stack>
          {title && (
            <MLInlineStack sx={itemSx}>
              <Typography sx={{ paddingLeft: '12px' }}>{title}</Typography>
            </MLInlineStack>
          )}
          {Object.entries(options).map(([key, option]) => {
            const typedOption = option as MLIconMenuEnumButtonOptions<T>[T];
            return (
              <MenuItem
                key={key}
                sx={{ ...itemSx, backgroundColor: key === value ? 'colors.selected' : null }}
                onClick={() => handleMenuItemClick(key as T)}>
                <MLInlineStack>
                  {React.createElement(typedOption.icon)}
                  <Typography color="colors.primary_text">{typedOption.label}</Typography>
                </MLInlineStack>
              </MenuItem>
            );
          })}
        </Stack>
      }>
      {React.createElement(options[value].icon)}
    </MLIconMenuButton>
  );
}
