import Document from '@tiptap/extension-document';
import HardBreak from '@tiptap/extension-hard-break';
import Mention from '@tiptap/extension-mention';
import Paragraph from '@tiptap/extension-paragraph';
import Placeholder from '@tiptap/extension-placeholder';
import Text from '@tiptap/extension-text';
import { EditorState, Transaction } from '@tiptap/pm/state';
import { EditorProvider, ReactNodeViewRenderer as reactNodeViewRenderer } from '@tiptap/react';
import { submitAssistantMessageAction } from 'actions/assistant/submit-assistant-message-action';
import { TT_PLACEHOLDER } from 'constants/assistant';
import { useListContext } from 'hooks/ListContextProvider';
import { matchSorter } from 'match-sorter';
import { MentionItem } from 'models/assistant/types';
import React from 'react';
import { useAppDispatch } from 'types/store';

import '../../tiptap-styles.scss';
import MentionChip from './MentionChip';
import { MentionStorage } from './MentionStorage.extension';
import { PlaceholderStorage } from './PlaceholderStorage.extension';
import generateSuggestion from './generateSuggestion';

interface TTProviderProps {
  slotBefore?: React.ReactNode;
  slotAfter?: React.ReactNode;
}

function TTProvider({ slotAfter, slotBefore }: TTProviderProps) {
  const dispatch = useAppDispatch();
  const { listId } = useListContext();

  const extentions = [
    Document,
    Paragraph,
    Text,
    PlaceholderStorage,
    Placeholder.configure({
      placeholder: ({ editor }) => editor.storage.PlaceholderStorage.placeholder || TT_PLACEHOLDER
    }),
    MentionStorage,
    Mention.configure({
      renderText: ({ node }) => `@[${node.attrs.label}](${node.attrs.id})`,
      suggestion: {
        ...generateSuggestion(),
        items: ({ query, editor }) => {
          const { suggestions } = editor.storage.MentionStorage;
          return matchSorter(suggestions as MentionItem[], query, {
            keys: ['label']
          });
        }
      }
    }).extend({
      addNodeView() {
        return reactNodeViewRenderer(MentionChip);
      }
    }),
    HardBreak
  ];

  const editorProps = {
    handleKeyDown: (
      view: { state: EditorState; dispatch: (tr: Transaction) => void },
      event: KeyboardEvent
    ) => {
      // Access the ProseMirror view state to check if a mention is active.
      const isUsingMention = (view.state as any).mention$?.active;
      if (event.key === 'Enter') {
        const { state, dispatch: tTdispatch } = view;
        if (!isUsingMention && !event.shiftKey) {
          event.preventDefault();
          dispatch(submitAssistantMessageAction({ listId }));
          tTdispatch(state.tr.delete(0, state.doc.content.size));
          return true;
        }
      }
      return false;
    }
  };

  return (
    <EditorProvider
      extensions={extentions}
      editorProps={editorProps}
      slotAfter={slotAfter}
      slotBefore={slotBefore}
    />
  );
}

export default TTProvider;
