import { Stack } from '@mui/material';
import { ACTION_TYPE_TO_BG_COLOR } from 'constants/assistant';
import React, { useEffect, useRef } from 'react';
import { resetPromptConfig } from 'reducer/assistant-slice';
import { getLatestListThreadId, getPromptConfigActionType } from 'selectors/assistant';
import { useAppDispatch, useAppSelector, useAppSelectorWithArgs } from 'types/store';

import AssistantActionBanner from './AssistantActionBanner';
import TTTextBox from './tiptap/TTTextBox';

interface AssistantPromptProps {
  listId: number | null;
}

function AssistantPrompt({ listId }: AssistantPromptProps) {
  const dispatch = useAppDispatch();
  const promptRef = useRef<HTMLDivElement>(null);
  const action_type = useAppSelector(getPromptConfigActionType);
  const latestThreadId = useAppSelectorWithArgs(getLatestListThreadId, listId);

  useEffect(() => {
    // Reset prompt config if thread changes
    dispatch(resetPromptConfig());
  }, [dispatch, latestThreadId]);
  return (
    <Stack
      sx={{
        backgroundColor: action_type ? ACTION_TYPE_TO_BG_COLOR[action_type] : null,
        borderRadius: '16px',
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px'
      }}>
      {action_type && <AssistantActionBanner />}
      <Stack
        justifyContent="space-between"
        gap="12px"
        ref={promptRef}
        sx={{
          minHeight: '100px',
          borderRadius: '16px',
          border: '1px solid',
          backgroundColor: 'colors.primary_bg',
          padding: '16px',
          borderColor: 'colors.ui_border'
        }}>
        <TTTextBox promptRef={promptRef} listId={listId} />
      </Stack>
    </Stack>
  );
}

export default AssistantPrompt;
