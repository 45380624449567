import React from 'react';

export default function PPTXIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.97949 15.5V10C6.97949 9.44772 7.42721 9 7.97949 9H8.83333C10.03 9 11 9.97005 11 11.1667C11 12.3633 10.03 13.3333 8.83333 13.3333H8.02033V15.5C8.02033 15.7874 7.78733 16.0204 7.49991 16.0204C7.21249 16.0204 6.97949 15.7874 6.97949 15.5ZM8.02033 12.25V10.0833H8.83333C9.43164 10.0833 9.91667 10.5684 9.91667 11.1667C9.91667 11.765 9.43164 12.25 8.83333 12.25H8.02033Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 5.76239C13.5 4.79511 12.598 4.08072 11.6564 4.30226L5.15644 5.83167C4.47887 5.9911 4 6.59572 4 7.2918V17.6105C4 18.3712 4.56932 19.0114 5.32474 19.1003L11.8247 19.865C12.6747 19.965 13.4264 19.3366 13.4949 18.5H18.5C19.3284 18.5 20 17.8284 20 17V7.5C20 6.67157 19.3284 6 18.5 6H13.5V5.76239ZM12.5 18.3752C12.5 18.6745 12.2388 18.9068 11.9416 18.8718L5.44158 18.1071C5.18977 18.0775 5 17.8641 5 17.6105V7.2918C5 7.05977 5.15962 6.85823 5.38548 6.80509L11.8855 5.27568C12.1993 5.20183 12.5 5.43996 12.5 5.76239V18.3752ZM13.5 17.5V16.5H17.5C17.7761 16.5 18 16.2761 18 16C18 15.7239 17.7761 15.5 17.5 15.5H13.5V15H17.5C17.7761 15 18 14.7761 18 14.5C18 14.2239 17.7761 14 17.5 14H13.5V12.0002C13.9561 12.6073 14.6822 13 15.5 13C16.8807 13 18 11.8807 18 10.5C18 9.11929 16.8807 8 15.5 8C14.6822 8 13.9561 8.39267 13.5 8.99976V7H18.5C18.7761 7 19 7.22386 19 7.5V17C19 17.2761 18.7761 17.5 18.5 17.5H13.5ZM15.5 12C16.1531 12 16.7087 11.5826 16.9146 11H15.7C15.3134 11 15 10.6866 15 10.3V9.08535C14.4174 9.29127 14 9.84689 14 10.5C14 11.3284 14.6716 12 15.5 12ZM16 10V9.08535C16.4262 9.236 16.764 9.57379 16.9146 10H16Z"
        fill="currentColor"
      />
    </svg>
  );
}
