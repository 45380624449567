import { Stack, Typography } from '@mui/material';
import MLButton from 'design-system/MLButton';
import MLInlineStack from 'design-system/MLInlineStack';
import MLMarkdownEditor from 'design-system/MLMarkdownEditor';
import MLModal from 'design-system/MLModal';
import ExpandIcon from 'design-system/icons/platform/ExpandIcon';
import React, { useEffect, useState } from 'react';

interface DomainReportEditorExpandableTextProps {
  title: string;
  text: string;
  setText: (text: string) => void;
  disabled?: boolean;
  disabledText?: string;
}

export default function DomainReportEditorExpandableText({
  title,
  disabled,
  disabledText,
  text,
  setText
}: DomainReportEditorExpandableTextProps) {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [allowSave, setAllowSave] = useState<boolean>(true);
  const [internalText, setInternalText] = useState<string>(text);

  useEffect(() => {
    setInternalText(text);
  }, [text]);

  useEffect(() => {
    setAllowSave(internalText.length > 0);
  }, [internalText]);

  const updateText = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInternalText(event.target.value);
  };

  const setExternalText = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setText(event.target.value);
  };

  const saveAndClose = () => {
    setText(internalText);
    setOpenModal(false);
  };

  const resetAndClose = () => {
    setText(text);
    setInternalText(text);
    setOpenModal(false);
  };

  const primaryButtonProps = {
    label: 'Save Changes',
    onClick: () => saveAndClose(),
    disabled: !allowSave
  };
  const secondaryButtonProps = {
    label: 'Cancel',
    onClick: () => resetAndClose()
  };

  return (
    <React.Fragment>
      {openModal && (
        <MLModal
          maxWidth={'lg'}
          open={openModal}
          onClose={resetAndClose}
          title={`Edit ${title}`}
          primaryButtonProps={primaryButtonProps}
          secondaryButtonProps={secondaryButtonProps}>
          <MLMarkdownEditor
            value={internalText}
            onChange={updateText}
            autoFocus
            fullWidth
            rows={23}
          />
        </MLModal>
      )}
      <Stack gap="16px">
        <MLInlineStack sx={{ justifyContent: 'space-between' }}>
          <Typography variant="text1_Semibold">{title}</Typography>
          <MLButton
            disabled={disabled}
            startIcon={<ExpandIcon />}
            onClick={() => setOpenModal(!openModal)}>
            <Typography variant="text1_Normal">Expand</Typography>
          </MLButton>
        </MLInlineStack>
        {disabled && disabledText ? (
          <Typography variant="text1_Normal">{disabledText}</Typography>
        ) : (
          <MLMarkdownEditor value={text} onChange={setExternalText} minRows={3} maxRows={15} />
        )}
      </Stack>
    </React.Fragment>
  );
}
