import { Box } from '@mui/material';
import React from 'react';

interface DraggableItemPlaceholderProps {
  sx: object;
  setNodeRef: React.Ref<any>;
}
export default function DraggableItemPlaceholder({
  sx,
  setNodeRef
}: DraggableItemPlaceholderProps) {
  return <Box ref={setNodeRef} sx={{ border: '1px dashed', ...sx }} />;
}
