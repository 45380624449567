import { TTMentionOrText } from 'models/assistant/tiptap';

import { stringIsMentionId } from './text';

export function parseMentions(input: string): TTMentionOrText[] {
  const result: TTMentionOrText[] = [];
  // eslint-disable-next-line require-unicode-regexp, prefer-named-capture-group
  const regex = /@\[([^\]]+)\]\(([^)]+)\)/g;
  let lastIndex = 0;
  let match: RegExpExecArray | null = null;

  while ((match = regex.exec(input)) !== null) {
    // Add text before the mention
    if (match.index > lastIndex) {
      result.push(input.substring(lastIndex, match.index));
    }
    // Create the mention object, converting id to number
    if (stringIsMentionId(match[2])) {
      result.push({ type: 'mention', attrs: { label: match[1], id: match[2] } });
    }
    ({ lastIndex } = regex);
  }

  // Add any remaining text after the last mention
  if (lastIndex < input.length) {
    result.push(input.substring(lastIndex));
  }

  return result;
}
