import { WORKFLOW_SELECTED_TYPE, WORKFLOW_STATUSES } from 'constants/workflows';
import { addPostQueryToThread, getThreadKey, updatePlan } from 'reducer/custom-analytics';
import {
  updateSelectedWorkflowIdByListId,
  updateWorkflowStatusByIdAndListId
} from 'reducer/workflows-slice';

export function createWorkflowFromListAction(listId, workflowName) {
  return (dispatch, _getState, apiClient) => {
    const threadKey = getThreadKey(listId);
    const newPostId = new Date().getTime();
    const plan = { question: 'Turn list columns to a workflow', is_workflow: true };
    dispatch(
      addPostQueryToThread({
        threadKey,
        postId: newPostId,
        plan,
        query: '',
        body: { hidePost: true },
        discoveryId: 1,
        messages: [],
        isWorkflow: true,
        name: workflowName
      })
    );
    dispatch(
      updateWorkflowStatusByIdAndListId({
        listId,
        workflowId: newPostId,
        status: WORKFLOW_STATUSES.CREATING_FROM_LIST
      })
    );
    dispatch(
      updateSelectedWorkflowIdByListId({
        listId,
        selectedWorkflowId: newPostId,
        selectedType: WORKFLOW_SELECTED_TYPE.DRAFT
      })
    );
    apiClient.companiesLists.getListWorkflow(listId).then((res) => {
      if (res.ok) {
        const { workflow } = res.data;
        workflow.question = plan.question;
        workflow.is_workflow = true;
        workflow.name = workflowName;
        dispatch(updatePlan({ threadKey, postId: newPostId, newPlan: workflow }));
        dispatch(
          updateWorkflowStatusByIdAndListId({
            listId,
            workflowId: newPostId,
            status: WORKFLOW_STATUSES.GENERATED
          })
        );
      }
    });
  };
}
