import PropTypes from 'prop-types';
import React from 'react';

export const ListIcon = ({ size, color }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.09999 4.04445L17.9001 4.04443C18.4452 4.04443 18.9681 4.261 19.3536 4.64649C19.739 5.03198 19.9556 5.55482 19.9556 6.09999V17.9001C19.9556 18.4453 19.739 18.9681 19.3536 19.3536C18.9681 19.7391 18.4452 19.9557 17.9001 19.9557L6.09999 19.9557C5.55482 19.9557 5.03198 19.7391 4.64649 19.3536C4.261 18.9681 4.04443 18.4453 4.04443 17.9001V6.10001C4.04443 5.55484 4.261 5.032 4.64649 4.64651C5.03198 4.26102 5.55482 4.04445 6.09999 4.04445V4.54445V4.04445ZM9.16665 5.04444L6.09999 5.04445C5.82004 5.04445 5.55155 5.15566 5.3536 5.35361C5.15564 5.55157 5.04443 5.82005 5.04443 6.10001V17.9001C5.04443 18.1801 5.15564 18.4486 5.3536 18.6465C5.55155 18.8445 5.82004 18.9557 6.09999 18.9557L9.16665 18.9557V5.04444ZM10.1666 5.04443V18.9557H17.9001C18.18 18.9557 18.4485 18.8444 18.6464 18.6465C18.8444 18.4485 18.9556 18.1801 18.9556 17.9001V6.09999C18.9556 5.82004 18.8444 5.55155 18.6464 5.3536C18.4485 5.15564 18.18 5.04443 17.9001 5.04443H10.1666ZM11.5 9.00006C11.5 8.72392 11.7239 8.50006 12 8.50006H17C17.2761 8.50006 17.5 8.72392 17.5 9.00006C17.5 9.2762 17.2761 9.50006 17 9.50006H12C11.7239 9.50006 11.5 9.2762 11.5 9.00006ZM11.5 12.0001C11.5 11.7239 11.7239 11.5001 12 11.5001H17C17.2761 11.5001 17.5 11.7239 17.5 12.0001C17.5 12.2762 17.2761 12.5001 17 12.5001H12C11.7239 12.5001 11.5 12.2762 11.5 12.0001ZM11.5 15.0001C11.5 14.7239 11.7239 14.5001 12 14.5001H17C17.2761 14.5001 17.5 14.7239 17.5 15.0001C17.5 15.2762 17.2761 15.5001 17 15.5001H12C11.7239 15.5001 11.5 15.2762 11.5 15.0001Z"
        fill={color}
      />
    </svg>
  );
};

ListIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
};

ListIcon.defaultProps = {
  size: 24,
  color: '#5C5E76'
};
