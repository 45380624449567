import {
  Active,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  Over,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Stack } from '@mui/material';
import { DomainReportSection } from 'models/domain-report/types';
import React, { useState } from 'react';

import DomainReportDraggableSection from './DomainReportDraggableSection';
import DraggableItemOverlay from './DraggableItemOverlay';

interface DomainReportDraggableListProps {
  setEditableSection: (section: DomainReportSection) => void;
  items: DomainReportSection[];
  setItems: (items: DomainReportSection[]) => void;
  disabled?: boolean;
  onDragEndCallback?: (items: DomainReportSection[]) => void;
}

export default function DomainReportDraggableList({
  setEditableSection,
  items,
  setItems,
  disabled,
  onDragEndCallback
}: DomainReportDraggableListProps) {
  const [activeItemId, setActiveItemId] = useState<string | number | null>(null);
  const sensors = useSensors(useSensor(PointerSensor));

  const getItemData = (id: string | number | null): DomainReportSection | null => {
    return id ? items.find((item) => item.props.id === id) || null : null;
  };

  const getNewItemsOrder = (items: DomainReportSection[], active: Active, over: Over | null) => {
    const oldIndex = items.findIndex((item) => item.props.id === active.id);
    const newIndex = items.findIndex((item) => item.props.id === over?.id);

    return arrayMove(items, oldIndex, newIndex);
  };

  const updateOrder = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      const newItems = getNewItemsOrder(items, active, over);
      setItems(newItems);
      if (onDragEndCallback) {
        onDragEndCallback(newItems);
      }
    }
    setActiveItemId(null);
  };

  const updateActiveItem = (event: DragStartEvent) => {
    setActiveItemId(event.active.id);
  };

  const activeItem = getItemData(activeItemId);

  return (
    <DndContext
      sensors={sensors}
      onDragEnd={updateOrder}
      onDragStart={updateActiveItem}
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis, restrictToParentElement]}>
      <SortableContext
        items={items.map((item) => item.props.id)}
        strategy={verticalListSortingStrategy}>
        <Stack>
          {items.map((item) => {
            return (
              <DomainReportDraggableSection
                key={item.props.id}
                section={item}
                displayPlaceholder={activeItemId === item.props.id}
                setEditableSection={setEditableSection}
                disabled={disabled}
              />
            );
          })}
        </Stack>
      </SortableContext>
      {activeItem && activeItemId && (
        <DragOverlay>
          <DraggableItemOverlay id={activeItemId}>
            <DomainReportDraggableSection
              section={activeItem}
              key={activeItemId}
              sx={{
                backgroundColor: 'colors.hover_on_primary_bg',
                '&:hover': {
                  cursor: 'grabbing'
                }
              }}
              isDragged={true}
            />
          </DraggableItemOverlay>
        </DragOverlay>
      )}
    </DndContext>
  );
}
