/* eslint-disable max-statements */
import { COMPANY_EXTRA_DATA_TYPES } from 'constants/company-extra-data';
import { updateFiltersBypassWarnings } from 'reducer/explore-slice';
import {
  CLUSTERS_COLUMN_TYPES,
  COLUMN_TYPES,
  COLUMN_TYPE_TO_EXTRA_PARAMS,
  COLUMN_TYPE_TO_FEATURE_FLAG_NAME,
  COLUMN_TYPE_TO_RENDER_DATA_TYPE,
  DEV_COLUMNS,
  PEER_COLUMN_TYPES,
  getColumnExtraParams,
  getColumnsByKeys,
  getCustomColumnRenderType,
  getPeersColumns
} from 'utils/explore-table-columns';

import { getCustomAnalyticsMetaColumnId } from './custom-analytics-utils';
import {
  CLUSTETRS_COLUMN_TYPES_TO_EXTRACTORS,
  COLUMN_TYPES_TO_EXTRACTORS,
  PEER_COLUMN_TYPES_TO_EXTRACTORS,
  getValueByName,
  getValueDefault,
  getValueFromExtraData
} from './explore-columns-data-extractors';
import { EXTRA_DATA_COLUMN_TYPE } from './extra-data-utils';
import { getConsensusFromData } from './finance-utils';
import { localeStringToNumber, removeMarkdown } from './string-utils';
import { stringFilterOperatorsByObjectKey } from './table-utils';

export const COLUMN_TYPES_TO_SERVER_TYPE = Object.freeze({
  [COLUMN_TYPES.INDUSTRIES]: 'industries',
  [COLUMN_TYPES.DESCRIPTION]: 'description',
  [COLUMN_TYPES.HQ]: 'hq_location',
  [COLUMN_TYPES.YEAR_FOUNDED]: 'year_founded',
  [COLUMN_TYPES.INDUSTRIES]: 'industries',
  [COLUMN_TYPES.EMPLOYEES]: 'size',
  [COLUMN_TYPES.TOTAL_FUNDING]: 'total_funding',
  [COLUMN_TYPES.LAST_EQUITY_ROUND_TYPE]: 'last_equity_round_type',
  [COLUMN_TYPES.NUM_EMPLOYEES]: 'num_employees',
  [COLUMN_TYPES.SUMMARY]: 'summary',
  [COLUMN_TYPES.REVENUES]: 'revenues',
  [COLUMN_TYPES.LAST_EQUITY_DETAILES]: 'last_equity_round_message'
});

export function getFinanceColumnValue(value) {
  let amount = null;
  let addition = 0;
  const consensus = getConsensusFromData(value?.data);
  if (!consensus) return null;
  if (consensus?.clean_value_amount) {
    amount = consensus.clean_value_amount;
  } else if (consensus?.value_range_lower) {
    amount = consensus.value_range_lower;
    addition = 0.001;
  } else {
    amount = consensus.value_range_upper;
    addition = -0.0001;
  }

  return Math.round(amount / 1e6) + addition || null;
}

export function getRoundedFinanceColumnValue(value) {
  const amount = getFinanceColumnValue(value);
  return amount ? Math.round(amount) : null;
}

export const createOneRow = (id, loadingName, handleFiles, onEnterNewCompany, columnId) => {
  const loading = { loading: true };
  const uploadFiles = { uploadFiles: true, handleFiles };
  const row = {
    id,
    company_name: { enterNewCompany: true, onEnterNewCompany },
    [columnId]: uploadFiles,
    fullRow: true
  };
  if (loadingName) {
    row.company_name = { loadingName: loadingName };
    row.description = loading;
    row.year_founded = loading;
    row.employees = loading;
    row.last_equity_round_type = loading;
    row.last_equity_details = loading;
    row.industries = loading;
    row.hq = loading;
    row.region = loading;
  }

  return row;
};

export const createRunningRow = (id, data) => {
  const runningData = { running: true, ...data };
  const row = {
    id,
    company_name: runningData,
    runningData,
    fullRow: true
  };

  return row;
};

/* eslint-disable max-lines */
export function columnsFromMergedRows(
  companyMetas,
  customColumns,
  listId,
  columnsSettings,
  isDev,
  featureFlags,
  history
) {
  const numRows = companyMetas?.length;
  if (!companyMetas) {
    return [];
  }
  const keepMd = featureFlags?.includes('keep_markdown');

  const rows = [...companyMetas];

  rows.sort((row1, row2) => {
    return (
      row2.highlighted - row1.highlighted ||
      row1.name.localeCompare(row2.name, { sensitivity: 'base' })
    );
  });
  rows.reverse();
  const values = {};
  Object.values(COLUMN_TYPES).forEach((col) => {
    values[col] = [];
  });
  if (customColumns) {
    Object.values(customColumns).forEach((customColumn) => {
      values[customColumn.id] = [];
      if (customColumn.type?.includes('custom_analytics')) {
        values[getCustomAnalyticsMetaColumnId(customColumn.id)] = [];
      }
    });
  }

  const columns = [];
  for (let index = 0; index < numRows; index++) {
    const row = rows[index];

    Object.keys(COLUMN_TYPES).forEach((key) => {
      const extractor = COLUMN_TYPES_TO_EXTRACTORS?.[key] || getValueDefault;
      const value = extractor(row, key);
      values[COLUMN_TYPES[key]].unshift(value);
    });

    if (customColumns) {
      Object.values(customColumns).forEach((customColumn) => {
        let value = null;
        const extraParams = {};
        if (customColumn.type === 'basic_data_column') {
          value = getValueByName(row, customColumn.id);
          extraParams.disableEdit = true;
        } else if (customColumn.type === 'finance_column') {
          const financeExtraData = getValueFromExtraData(row, COMPANY_EXTRA_DATA_TYPES.FINANCE);
          const columnData = financeExtraData?.[customColumn.id];
          values[customColumn.id].unshift({
            data: columnData,
            financeType: customColumn.id,
            loading: financeExtraData?.loading
          });
          return;
        } else {
          value = getValueFromExtraData(row, customColumn.id);
          if (!value) {
            value = row.customColumns?.[customColumn.id];
            if (!value && value !== false) {
              value = '';
            }
          }
        }

        values[customColumn.id].unshift({
          value,
          listId,
          companyListMetaId: row?.companyListMetaId,
          companyMetaId: row?.companyMetaId,
          columnId: customColumn.id,
          columnName: customColumn.name,
          columnInfo: customColumn.info,
          companyName: row.name,
          columnType: customColumn.type,
          companyLogo: row.logo_url,
          extraParams
        });
        if (customColumn.type?.includes('custom_analytics')) {
          values[getCustomAnalyticsMetaColumnId(customColumn.id)].unshift({
            value
          });
        }
      });
    }
  }
  const defaultColumnsKeys = Object.keys(COLUMN_TYPES).filter(
    (columnKey) =>
      !DEV_COLUMNS[columnKey] &&
      (!COLUMN_TYPE_TO_FEATURE_FLAG_NAME?.[columnKey] ||
        (featureFlags?.includes(COLUMN_TYPE_TO_FEATURE_FLAG_NAME[columnKey]) &&
          values[COLUMN_TYPES[columnKey]].some((item) => item)))
  );
  const columnsFromTypes = getColumnsByKeys(
    defaultColumnsKeys,
    values,
    columnsSettings,
    listId,
    history
  );
  columns.push(...columnsFromTypes);
  if (customColumns) {
    Object.values(customColumns).forEach((customColumn) => {
      const financeExtraParams = COLUMN_TYPE_TO_EXTRA_PARAMS.REVENUES;
      columns.push({
        id: customColumn.id,
        name: customColumn.name,
        color: customColumn.color,
        display: 'flex',
        data_type: getCustomColumnRenderType(customColumn, COLUMN_TYPE_TO_RENDER_DATA_TYPE),
        width: columnsSettings?.[customColumn.id]?.width,
        values: values[customColumn.id],
        valueFormatter: (value) => {
          if (customColumn.type === 'finance_column') {
            return financeExtraParams?.([])?.valueFormatter?.(value);
          }
          return customColumn.type.includes('custom_analytics')
            ? removeMarkdown(value?.value?.answer, keepMd)
            : removeMarkdown(value?.value, keepMd);
        },
        filterOperators: stringFilterOperatorsByObjectKey('value'),
        extraParams: {
          allowEditHeader: true,
          listId,
          info: customColumn.info,
          ...getColumnExtraParams({
            columnId: customColumn?.id,
            mapToParams: COLUMN_TYPE_TO_EXTRA_PARAMS
          })
        }
      });
      if (customColumn.type?.includes('custom_analytics')) {
        columns.push({
          id: getCustomAnalyticsMetaColumnId(customColumn.id),
          name: `${customColumn.name} reasoning and sources`,
          // display: 'flex',
          hide: true,
          data_type: 'custom_analytics_meta',
          values: values[`${customColumn.id}-meta`],
          valueFormatter: (value) =>
            `reasoning: ${value?.value?.reasoning}\n\nsources: ${
              value?.value?.sources ? Object.values(value.value.sources).join(', ') : ''
            }`,
          filterOperators: stringFilterOperatorsByObjectKey('value')
        });
      }
    });
  }

  if (isDev) {
    const devColumns = getColumnsByKeys(Object.keys(DEV_COLUMNS), values, columnsSettings, listId);
    columns.push(...devColumns);
  }
  return columns;
}

const platterColumnTypes = [
  'predefined_custom_analytics',
  EXTRA_DATA_COLUMN_TYPE,
  'basic_data_column',
  'finance_column'
];
export function getColumnGrouping(customColumns) {
  const model = [
    {
      groupId: 'custom_analytics',
      headerName: 'Custom Analytics',
      headerClassName: 'table-section',
      description: '',
      defaultExpand: false,
      children: []
    },
    {
      groupId: 'extra_columns',
      headerName: 'Extra Columns',
      headerClassName: 'table-section',
      description: '',
      defaultExpand: false,
      children: []
    },
    {
      groupId: 'offering',
      headerName: 'Offering',
      headerClassName: 'table-section',
      description: '',
      defaultExpand: true,
      children: [{ field: 'description' }, { field: 'summary' }, { field: 'industries' }]
    },
    {
      groupId: 'financials',
      headerName: 'Financials',
      headerClassName: 'table-section',
      description: '',
      defaultExpand: false,
      children: [
        { field: 'revenues' },
        { field: 'profit' },
        { field: 'finance_peer_column' },
        { field: 'last_equity_round_type' },
        { field: 'last_equity_round_message' },
        { field: 'total_funding' },
        { field: 'lead_investors' }
      ]
    },
    {
      groupId: 'basics',
      headerName: 'Basics',
      headerClassName: 'table-section',
      description: '',
      defaultExpand: false,
      children: [
        { field: 'year_founded' },
        { field: 'employees' },
        { field: 'num_employees' },
        { field: 'region' },
        { field: 'country' },
        { field: 'hq' },
        { field: 'locations' },
        { field: 'leaders' }
      ]
    },
    {
      groupId: 'other',
      headerName: 'Other',
      headerClassName: 'table-section',
      description: '',
      defaultExpand: false,
      children: [
        { field: 'company_source' },
        { field: 'created_at' },
        { field: 'files' },
        { field: 'hidden_gems' }
      ]
    },
    {
      groupId: 'no-title',
      headerName: '',
      headerClassName: 'table-section',
      description: '',
      defaultExpand: false,
      children: [{ field: 'company_name' }]
    }
  ];
  const customColumnsIds = (customColumns && Object.keys(customColumns)) || [];
  customColumnsIds.forEach((columnId) => {
    if (customColumns[columnId].type?.includes('custom_analytics')) {
      const modelIdx = customColumns[columnId].type === 'predefined_custom_analytics' ? 1 : 0;

      model[modelIdx].children.push({ field: columnId });
      model[modelIdx].children.push({
        field: getCustomAnalyticsMetaColumnId(columnId)
      });
    } else if (platterColumnTypes.includes(customColumns[columnId].type)) {
      model[1].children.push({ field: columnId });
    } else {
      model[4].children.push({ field: columnId });
    }
  });

  return model;
}

export function clustersColumnsFromMergedRows(companyMetas) {
  const numRows = companyMetas?.length;
  if (!companyMetas) {
    return [];
  }
  const defaultConfig = {
    icon: null,
    display: true
  };
  const rows = [...companyMetas];
  rows.reverse();
  const values = {};
  Object.values(CLUSTERS_COLUMN_TYPES).forEach((col) => {
    values[col] = [];
  });
  const columns = [];
  for (let index = 0; index < numRows; index++) {
    const row = rows[index];

    Object.keys(CLUSTERS_COLUMN_TYPES).forEach((key) => {
      const extractor = CLUSTETRS_COLUMN_TYPES_TO_EXTRACTORS?.[key] || getValueDefault;
      const value = extractor(row, key);
      values[CLUSTERS_COLUMN_TYPES[key]].unshift(value);
    });
  }
  const companyCol = {
    id: 'company_name',
    name: 'Company',
    display: 'flex',
    data_type: 'bulk_company_name',
    values: values[COLUMN_TYPES.COMPANY],
    valueFormatter: (value) => value.name,
    filterOperators: stringFilterOperatorsByObjectKey('name'),
    ...defaultConfig
  };
  columns.push(companyCol);
  columns.push({
    id: 'description',
    name: 'Description',
    display: 'flex',
    valueFormatter: (value) => {
      return typeof value === 'string' || value instanceof String ? value : null;
    },
    data_type: 'description',
    values: values[COLUMN_TYPES.DESCRIPTION]
  });

  return columns;
}

export function formatFilters(selectedFilters, allFiltersWithoutIndustries) {
  const allFilters = { ...allFiltersWithoutIndustries, industry: { labels: [] } };
  const newSelected = Object.keys(allFilters).reduce(
    (acc, key) => {
      const labels =
        !selectedFilters[key] || selectedFilters[key].length === allFilters[key].labels.length
          ? []
          : selectedFilters[key];
      return { ...acc, [key]: { ...allFilters[key], labels: labels } };
    },
    { global_table_poc: selectedFilters.global_table_poc }
  );
  return newSelected;
}

/**
 * csvToArray parses any String of Data including '\r' '\n' characters,
 * and returns an array with the rows of data.
 * @param {String} CSV_string - the CSV string you need to parse
 * @returns {Array} rows - rows of CSV where first row are column headers
 */
export function csvToArray(CSV_string) {
  const delimiter = ',';

  // RegExp groups: #1: Delimiters, #2: Quoted fields, #3: Standard fields
  // eslint-disable-next-line prefer-named-capture-group, prefer-regex-literals
  const pattern = new RegExp('(,|\\r?\\n|\\r|^)(?:"([^"]*(?:""[^"]*)*)"|([^",\\r\\n]*))', 'giu');
  const rows = [[]];
  let matches = false;
  // Loop until we no longer find a regular expression match
  while ((matches = pattern.exec(CSV_string))) {
    const [, matched_delimiter] = matches;
    // Check if the delimiter has a length (and is not the start of string)
    // and if it matches field delimiter. If not, it is a row delimiter.
    if (matched_delimiter.length && matched_delimiter !== delimiter) {
      // Since this is a new row of data, add an empty row to the array.
      rows.push([]);
    }
    let matched_value = null;
    // Once we have eliminated the delimiter, check to see
    // what kind of value was captured (quoted or unquoted):
    if (matches[2]) {
      // found quoted value. unescape any double quotes.
      matched_value = matches[2].replace(/""/u, '"');
    } else {
      // found a non-quoted value
      [, , , matched_value] = matches;
    }
    rows[rows.length - 1].push(matched_value);
  }

  // remove empty columns
  if (Array.isArray(rows) && rows.length > 0 && Array.isArray(rows[0])) {
    const emptyRows = new Array(rows[0].length).fill(true);

    rows.forEach((row) =>
      row.forEach((col, colIndex) => {
        emptyRows[colIndex] = emptyRows[colIndex] && typeof col === 'string' && col === '';
      })
    );

    const emptyIndexes = emptyRows.reduce((indexes, isEmptyRow, index) => {
      if (isEmptyRow) indexes.add(index);
      return indexes;
    }, new Set());

    if (emptyIndexes.size > 0) {
      rows.forEach((row, index) => {
        rows[index] = row.filter((_col, colIndex) => !emptyIndexes.has(colIndex));
      });
    }
  }
  return rows;
}

const shouldKeepCompanyByListTypeFilters = (exploreFilters, row, dataName, valueGetter) => {
  const rowData = valueGetter ? valueGetter(row?.[dataName]) : row?.[dataName];

  if (!rowData) {
    return (
      !exploreFilters?.[dataName]?.data ||
      exploreFilters?.[dataName]?.data?.includes('No Information')
    );
  }
  if (exploreFilters?.[dataName]?.data && !exploreFilters?.[dataName].data.includes(rowData)) {
    return false;
  }
  return true;
};
const shouldKeepCompanyByPartnershipLevel = (exploreFilters, row, dataName) => {
  if (exploreFilters?.[dataName]?.data?.length > 0) {
    if (
      row[dataName]?.value?.partnership_level?.length > 0 &&
      !exploreFilters[dataName].data.includes(row[dataName]?.value?.partnership_level)
    ) {
      return false;
    }

    if (
      !row[dataName]?.value?.partnership_level &&
      !exploreFilters[dataName].data.includes('No Information')
    ) {
      return false;
    }
  }
  return true;
};
const shouldKeepCompanyByRangeTypeFilters = (exploreFilters, value, dataName) => {
  if (!value) return true;
  const number = typeof value === 'string' ? localeStringToNumber(value) : value;

  if (
    exploreFilters?.[dataName]?.data &&
    (number > exploreFilters[dataName].data[1] || number < exploreFilters[dataName].data[0])
  ) {
    return false;
  }
  return true;
};

const shouldKeepCompanyByStrTypeFilters = (operator, filterValue, value) => {
  if (operator && filterValue) {
    if (!value) {
      if (operator === 'not_contains') return true;
      if (operator === 'contains') return false;
    }
    const isValueIncludesText = value.toLowerCase().includes(filterValue.toLowerCase());
    if (operator === 'contains' && !isValueIncludesText) {
      return false;
    }
    if (operator === 'not_contains' && isValueIncludesText) {
      return false;
    }
  }
  return true;
};

const rowToCompanyMetaId = (row) => row?.companyMetaId || row?.company_name?.companyMetaId;

const shouldKeepCompanyByDescription = (exploreFilters, row, isDev) => {
  if (exploreFilters?.description?.data) {
    if (!row.description) return false;
    const descriptionData = exploreFilters.description.data;
    const companiesIds = descriptionData?.companiesIds;
    const score = descriptionData.scores?.[rowToCompanyMetaId(row)]?.top_scoring_sentence_score;
    const threshold = descriptionData.semanticThreshold;
    if (!score) {
      return false;
    }
    if (!descriptionData.searchTerm) return true;
    if (score && threshold && score < threshold) {
      return false;
    }
    if (isDev && descriptionData?.scores) {
      row.top_sentence = descriptionData.scores[rowToCompanyMetaId(row)].top_scoring_sentence_text;
      row.top_sentence_score =
        descriptionData.scores[rowToCompanyMetaId(row)].top_scoring_sentence_score;
    }
    if (
      !companiesIds &&
      !shouldKeepCompanyByStrTypeFilters(
        descriptionData.type,
        descriptionData.searchTerm,
        row.description
      )
      // !row.description.toLowerCase().includes(descriptionData.searchTerm.toLowerCase())
    ) {
      return false;
    }
  }
  return true;
};
const LIST_TYPE_FILTER_COLUMNS = [
  COLUMN_TYPES.LAST_EQUITY_ROUND_TYPE,
  COLUMN_TYPES.HQ,
  COLUMN_TYPES.REGION,
  COLUMN_TYPES.COUNTRY
];
export function innerFilterExploreRow(row, exploreFilters, isDev) {
  if (!shouldKeepCompanyByDescription(exploreFilters, row, isDev)) {
    return false;
  }
  let shouldReturnFalse = false;
  LIST_TYPE_FILTER_COLUMNS.forEach((dataName) => {
    const columnType = Object.entries(COLUMN_TYPES).find(([, value]) => value === dataName)?.[0];
    const valueGetter = COLUMN_TYPE_TO_EXTRA_PARAMS?.[columnType]?.valueGetter || null;

    if (!shouldKeepCompanyByListTypeFilters(exploreFilters, row, dataName, valueGetter)) {
      shouldReturnFalse = true;
    }
  });
  if (shouldReturnFalse) {
    return false;
  }

  // filter custom columns
  Object.values(row).forEach((col) => {
    const operator = exploreFilters?.[col?.columnId]?.data?.[1];
    const filterValue = exploreFilters?.[col?.columnId]?.data?.[0];

    if (col?.columnType === 'custom_str') {
      if (!shouldKeepCompanyByStrTypeFilters(operator, filterValue, col?.value)) {
        shouldReturnFalse = true;
      }
    } else if (col?.columnType?.includes('custom_analytics')) {
      if (col?.columnInfo?.answer_type === 'number') {
        if (
          !shouldKeepCompanyByRangeTypeFilters(exploreFilters, col?.value?.answer, col.columnId)
        ) {
          shouldReturnFalse = true;
        }
      } else if (
        !shouldKeepCompanyByStrTypeFilters(operator, filterValue, col?.value?.answer, col.columnId)
      ) {
        shouldReturnFalse = true;
      }
    } else if (col?.columnType === 'custom_number') {
      if (!shouldKeepCompanyByRangeTypeFilters(exploreFilters, col?.value, col.columnId)) {
        shouldReturnFalse = true;
      }
    } else if (col?.columnType === 'custom_boolean') {
      if (
        !shouldKeepCompanyByListTypeFilters(exploreFilters, row, col.columnId, (data) => {
          if (data?.value) return 'Yes';
          if (data?.value === false) return 'No';
          return 'Not set';
        })
      ) {
        shouldReturnFalse = true;
      }

      if (!shouldKeepCompanyByRangeTypeFilters(exploreFilters, col?.value, col.columnId)) {
        shouldReturnFalse = true;
      }
    }
  });
  if (shouldReturnFalse) {
    return false;
  }

  if (
    exploreFilters?.[COLUMN_TYPES.CREATED_AT]?.data?.length > 0 &&
    !exploreFilters[COLUMN_TYPES.CREATED_AT].data.includes(row[COLUMN_TYPES.CREATED_AT])
  ) {
    return false;
  }

  if (
    !shouldKeepCompanyByPartnershipLevel(exploreFilters, row, 'SAP_PARTNERSHIP_LEVEL') ||
    !shouldKeepCompanyByPartnershipLevel(exploreFilters, row, 'AWS_PARTNERSHIP_LEVEL')
  ) {
    return false;
  }

  if (
    !shouldKeepCompanyByListTypeFilters(
      exploreFilters,
      row,
      'company_source',
      (value) =>
        `${value?.title}${value?.iteration > 0 ? ' #' : ''}${
          value?.iteration > 0 ? value?.iteration : ''
        }`
    )
  ) {
    return false;
  }

  if (
    !shouldKeepCompanyByRangeTypeFilters(
      exploreFilters,
      row[COLUMN_TYPES.YEAR_FOUNDED],
      COLUMN_TYPES.YEAR_FOUNDED
    )
  ) {
    return false;
  }
  if (
    !shouldKeepCompanyByRangeTypeFilters(
      exploreFilters,
      row[COLUMN_TYPES.NUM_EMPLOYEES],
      COLUMN_TYPES.NUM_EMPLOYEES
    )
  ) {
    return false;
  }

  if (
    !shouldKeepCompanyByRangeTypeFilters(
      exploreFilters,
      getFinanceColumnValue(row[COLUMN_TYPES.REVENUES]),
      COLUMN_TYPES.REVENUES
    )
  ) {
    return false;
  }

  if (
    !shouldKeepCompanyByStrTypeFilters(
      exploreFilters?.[COLUMN_TYPES.LAST_EQUITY_DETAILES]?.data?.[1],
      exploreFilters?.[COLUMN_TYPES.LAST_EQUITY_DETAILES]?.data?.[0],
      row[COLUMN_TYPES.LAST_EQUITY_DETAILES]
    )
  ) {
    return false;
  }

  return true;
}

export function filterExploreRow(
  row,
  exploreFilters,
  isDev,
  filtersBypass,
  dispatch,
  keyFuncToCompanyNameField = (row2) => row2.company_name
) {
  const filterResult = innerFilterExploreRow(row, exploreFilters, isDev);
  if (filterResult) {
    return true;
  }
  if (
    filtersBypass &&
    Object.keys(filtersBypass).includes(keyFuncToCompanyNameField(row).companyMetaId.toString())
  ) {
    if (
      dispatch &&
      !Object.keys(filtersBypass)
        .filter((id) => !filtersBypass[id].warn)
        .includes(keyFuncToCompanyNameField(row).companyMetaId)
    ) {
      dispatch(
        updateFiltersBypassWarnings({
          listId: keyFuncToCompanyNameField(row).companyListId,
          companyMetaId: keyFuncToCompanyNameField(row).companyMetaId,
          name: keyFuncToCompanyNameField(row).name
        })
      );
    }
    return true;
  }
  return filterResult;
}

export function peerColumnsFromMergedRows(refereneCompanies, columnsSettings, checked, onCheck) {
  const numRows = refereneCompanies?.length;
  if (!refereneCompanies) {
    return [];
  }

  const rows = [...refereneCompanies];
  rows.reverse();
  const values = {};
  Object.values(PEER_COLUMN_TYPES).forEach((col) => {
    values[col] = [];
  });
  const columns = [];
  for (let index = 0; index < numRows; index++) {
    const row = rows[index];
    const isChecked = Boolean(checked?.some((item) => item === row.id));

    Object.keys(PEER_COLUMN_TYPES).forEach((key) => {
      const extractor = PEER_COLUMN_TYPES_TO_EXTRACTORS?.[key] || getValueDefault;
      const value = extractor(row, key, isChecked, onCheck);
      values[PEER_COLUMN_TYPES[key]].unshift(value);
    });
  }

  const columnsFromTypes = getPeersColumns(values, columnsSettings);
  columns.push(...columnsFromTypes);

  return columns;
}
