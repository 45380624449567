import React from 'react';

export default function PDFIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 5H18C18.5523 5 19 5.44772 19 6V18C19 18.5523 18.5523 19 18 19H6C5.44772 19 5 18.5523 5 18V6C5 5.44772 5.44772 5 6 5ZM4 6C4 4.89543 4.89543 4 6 4H18C19.1046 4 20 4.89543 20 6V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V6ZM6.07422 14.4147V9.7998H7.98058C8.3261 9.7998 8.6243 9.86741 8.87517 10.0026C9.12755 10.1363 9.32209 10.3233 9.4588 10.5637C9.5955 10.8026 9.66386 11.0805 9.66386 11.3975C9.66386 11.7159 9.594 11.9946 9.45429 12.2335C9.31608 12.4708 9.11854 12.6548 8.86165 12.7855C8.60477 12.9162 8.29981 12.9816 7.94678 12.9816H7.18964V14.4147H6.07422ZM7.73947 12.1028H7.18964V10.6989H7.73947C7.90622 10.6989 8.04668 10.7274 8.16085 10.7845C8.27502 10.8416 8.3614 10.9227 8.41999 11.0279C8.47858 11.1316 8.50787 11.2547 8.50787 11.3975C8.50787 11.5387 8.47858 11.6626 8.41999 11.7693C8.3614 11.8744 8.27502 11.9563 8.16085 12.0149C8.04818 12.0735 7.90772 12.1028 7.73947 12.1028ZM10.2144 14.4147H11.9202C12.3889 14.4147 12.7937 14.3231 13.1347 14.1398C13.4757 13.955 13.7379 13.6899 13.9212 13.3444C14.1059 12.9989 14.1983 12.5857 14.1983 12.105C14.1983 11.6258 14.1059 11.2142 13.9212 10.8702C13.7364 10.5246 13.4735 10.2602 13.1325 10.077C12.793 9.89219 12.3881 9.7998 11.9179 9.7998H10.2144V14.4147ZM11.8774 13.4638H11.3298V10.7507H11.8616C12.126 10.7507 12.3483 10.795 12.5286 10.8837C12.7104 10.9708 12.8478 11.1135 12.9409 11.3118C13.0341 11.5086 13.0807 11.773 13.0807 12.105C13.0807 12.437 13.0341 12.7029 12.9409 12.9027C12.8493 13.101 12.7134 13.2445 12.5331 13.3331C12.3543 13.4202 12.1357 13.4638 11.8774 13.4638ZM14.8496 9.7998V14.4147H15.965V12.5602H17.7993V11.6521H15.965V10.7057H17.9998V9.7998H14.8496Z"
        fill="currentColor"
      />
    </svg>
  );
}
