import { Button, Divider, Stack, Typography } from '@mui/material';
import { stopWorkflowAction } from 'actions/workflows/stop-workflow-action';
import RunningSearchActionCA from 'components/custom-analytics/RunningSearchAction';
import CustomAnalyticsWorkflowMessageComponent from 'components/discovery/CustomAnalyticsWorkflowMessageComponent';
import RenderStr from 'components/elements/table/RenderStr';
import FinishedRunningSearch from 'components/tile/FinishedRunningSearch';
import RunningSearchActionBase from 'components/tile/RunningSearchAction';
import RunningSearchActionDiscovery from 'components/tile/discovery/RunningSearchAction';
import { WORKFLOW_STATUSES } from 'constants/workflows';
import MLInlineStack from 'design-system/MLInlineStack';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getThreadKey } from 'reducer/custom-analytics';
import {
  getCustomAnalyticsPlanByThreadAndPostId,
  getPostNameByThreadIdAndPostId
} from 'selectors/custom-analytics';
import { getWorkflowByListIdAndWorkflowId } from 'selectors/workflows';
import { applyThemeColorOpacity } from 'utils/theme-utils';
import { extractNodesAndEdges } from 'utils/work-flow-planner-utils';

export default function RunningSearchAction({ id, listId, recentlyFinished }) {
  const dispatch = useDispatch();
  const plan = useSelector((state) =>
    getCustomAnalyticsPlanByThreadAndPostId(state, getThreadKey(listId), id)
  );
  const workflow = useSelector((state) => getWorkflowByListIdAndWorkflowId(state, listId, id));
  const name =
    useSelector((state) => getPostNameByThreadIdAndPostId(state, getThreadKey(listId), id)) ||
    plan?.question;
  const stepsData = useMemo(() => {
    if (!plan?.steps) {
      return [];
    }
    const allSteps = extractNodesAndEdges(plan.steps, listId)?.nodes?.slice(1);
    const stepIds = allSteps.map(({ data: { stepId } }) => stepId);
    const groupStepIds = stepIds.filter((stepId) =>
      stepIds.find((step) => step.includes(`${stepId}-`))
    );
    const flatSteps = allSteps
      .filter(({ data: { stepId } }) => !groupStepIds.includes(stepId))
      .map(({ data }) => {
        let postId = null;
        let recordId = null;
        const { stepId } = data;
        const [groupStepId, nestedStepIndex] = stepId.includes('-')
          ? stepId.split('-')
          : [stepId, 0];

        const statusesIdsData = workflow.stepsStatuses?.[groupStepId];
        const attributeName = data.attributeName
          ? ` - ${data.attributeTitle || data.attributeDefaultTitle || data.attributeName}`
          : '';

        let title = '';
        if (groupStepId === stepId) {
          title = `${data.label}${attributeName}`;
        } else {
          const mainStepIndex = allSteps.findIndex(
            ({ data: { stepId: mainStepId } }) => mainStepId === groupStepId
          );
          const mainStepLabel = allSteps[mainStepIndex]?.data?.label;
          title = `${mainStepLabel.split('. ')[0]}.${Number(nestedStepIndex) + 1} ${
            mainStepLabel.split('. ')[1]
          } - ${attributeName}`;
        }

        if (statusesIdsData) {
          postId = statusesIdsData?.postIds?.[nestedStepIndex];
          recordId = statusesIdsData?.recordIds?.[nestedStepIndex];
        }
        return { data, postId, recordId, title };
      });

    return flatSteps;
  }, [plan, listId, workflow]);

  const onStopWorkflow = () => {
    const runId = workflow?.customAnalyticsRecordId;
    if (!runId) {
      return;
    }
    dispatch(stopWorkflowAction(runId, listId, id));
  };

  if (recentlyFinished) {
    return (
      <FinishedRunningSearch
        runNameRender={
          <MLInlineStack maxWidth="200px">
            <RenderStr variant={'text1_Medium'} value={name} />
            <CustomAnalyticsWorkflowMessageComponent
              listId={listId}
              postId={id}
              isWorflowRun={true}
            />
          </MLInlineStack>
        }
        runId={id}
        isFailed={workflow?.status === WORKFLOW_STATUSES.RUN_FAILED}
      />
    );
  }

  const renderIcon = (Icon, color) =>
    Icon && (
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          width: 22,
          height: 22,
          backgroundColor: applyThemeColorOpacity(color, 12),
          borderRadius: '6px'
        }}
        color={color}>
        <Icon />
      </Stack>
    );

  return (
    <Stack gap="16px" width={300}>
      <MLInlineStack sx={{ width: '100%', pb: '6px' }}>
        <RenderStr variant={'text1_Medium'} value={`Running ${name}`} />
        <CustomAnalyticsWorkflowMessageComponent listId={listId} postId={id} isWorflowRun={true} />
        <Button
          size="small"
          onClick={onStopWorkflow}
          sx={{
            marginLeft: 'auto',
            color: 'colors.hover_on_negative_red',
            borderColor: 'colors.hover_on_negative_red',
            ':hover': { color: 'colors.hover_on_negative_red' }
          }}
          variant="outlined">
          Stop
        </Button>
      </MLInlineStack>
      {stepsData.map(({ data: { stepId, Icon, color }, postId, recordId, title }, index) => (
        <Stack key={stepId} direction="column" alignItems="center" sx={{ width: '100%' }}>
          <Stack direction="row" alignItems="center" width="100%" gap="8px" pb="8px">
            {renderIcon(Icon, color)}{' '}
            {title && (
              <Typography variant="text1_Medium" textAlign="left" color={'colors.primary_text'}>
                {title}
              </Typography>
            )}
          </Stack>

          {recordId || postId ? (
            <Stack alignItems="center" width="100%" pl="4px" pt="4px">
              {recordId && (
                <RunningSearchActionDiscovery key={recordId} id={recordId} mode="workflow" />
              )}

              {postId && <RunningSearchActionCA key={postId} listId={listId} id={postId} />}
            </Stack>
          ) : (
            <RunningSearchActionBase
              {...{ progress: 0, progressExistInState: false, searchTimestamp: null }}
            />
          )}
          {index !== stepsData.length - 1 && <Divider sx={{ py: '8px' }} flexItem />}
        </Stack>
      ))}
    </Stack>
  );
}

RunningSearchAction.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  listId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mode: PropTypes.oneOf(['tile', 'monitor']),
  recentlyFinished: PropTypes.bool
};

RunningSearchAction.defaultProps = {
  mode: 'tile'
};
