import { Stack, Typography } from '@mui/material';
import React, { ReactNode } from 'react';

interface DomainReportEditorKeyValueProps {
  title: string;
  children: string | ReactNode;
}

export default function DomainReportEditorKeyValue({
  title,
  children
}: DomainReportEditorKeyValueProps) {
  return (
    <Stack gap="16px">
      <Typography variant="text1_Semibold">{title}</Typography>
      {typeof children === 'string' ? (
        <Typography variant="text1_Normal">{children}</Typography>
      ) : (
        children
      )}
    </Stack>
  );
}
