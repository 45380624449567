import { Stack, Typography } from '@mui/material';
import { useListContext } from 'hooks/ListContextProvider';
import { AssistantAction } from 'models/assistant/assistant-actions';
import React from 'react';
import { getCompanyMetasByListId, getExploreFiltersBypassById } from 'selectors/explore';
import { useAppSelectorWithArgs } from 'types/store';

interface ActionAddCompaniesByNameDisplayProps {
  action: AssistantAction;
}

interface CompanyMeta {
  company_meta_id: number;
  name: string;
}

interface AddedCompanyCategory {
  text: (plural: boolean) => string;
  companyMetaIds: number[];
}

function isCompanyMetaNotNull(companyMeta: CompanyMeta | null): companyMeta is CompanyMeta {
  return companyMeta !== null;
}

function addedCompanyCategoryToMessageBlock(
  addedCompanyCategory: AddedCompanyCategory,
  idToCompany: (id: number) => CompanyMeta | null
) {
  if (addedCompanyCategory.companyMetaIds.length === 0) {
    return null;
  }
  const companyMetas: CompanyMeta[] = addedCompanyCategory.companyMetaIds
    .map(idToCompany)
    .filter(isCompanyMetaNotNull);
  const plural = companyMetas.length > 1;
  const message = `${companyMetas.length} ${addedCompanyCategory.text(plural)}`;
  return (
    <Typography>
      {message}: {companyMetas.map((comp: CompanyMeta) => comp.name).join(', ')}.
    </Typography>
  );
}

export default function ActionAddCompaniesByNameDisplay({
  action
}: ActionAddCompaniesByNameDisplayProps) {
  // Exctract companies after filters
  const { companies, listId } = useListContext();
  // Collect ids
  // All ids involved in action
  const companyMetaIds = action.submission_info?.company_meta_ids || [];
  // All company metas in list before filters
  const allCompanyMetas: CompanyMeta[] = useAppSelectorWithArgs(getCompanyMetasByListId, listId);
  // Ids already in list before filters
  const alreadyCompanyMetaIds = action.submission_info?.already_company_meta_ids || [];
  // Ids not already in list before filters
  const newCompanyMetaIds = companyMetaIds.filter((id) => !alreadyCompanyMetaIds.includes(id));
  // Get companies that should be blocked by filters but are shown anyway
  const filtersBypass = useAppSelectorWithArgs(getExploreFiltersBypassById, listId);
  const filtersBypassMetaIdsWithWarning: number[] = Object.keys(filtersBypass)
    .filter((idStr) => filtersBypass[idStr].warn)
    .map((idStr) => parseInt(idStr, 10));
  const filtersBypassMetaIdsWithoutWaring: number[] = Object.keys(filtersBypass)
    .filter((idStr) => !filtersBypass[idStr].warn)
    .map((idStr) => parseInt(idStr, 10));
  // Ids not already in list and passing filters
  const newCompanyMetaIdsPassingFilters = newCompanyMetaIds.filter(
    (id) =>
      (!filtersBypassMetaIdsWithWarning.includes(id) &&
        companies.find((comp) => comp.companyMetaId === id)) ||
      filtersBypassMetaIdsWithoutWaring.includes(id)
  );
  // Ids not already in list, blocked by filters but shown anyway
  const newCompanyMetaIdsBypassingFilters = newCompanyMetaIds.filter((id) =>
    filtersBypassMetaIdsWithWarning.includes(id)
  );
  // Ids already in list before filters and passing filters
  const alreadyCompanyMetaIdsPassingFilters = alreadyCompanyMetaIds.filter((id) =>
    companies.find((company) => company.companyMetaId === id)
  );
  // Ids already in list before filters and blocked by filters
  const alreadyCompanyMetaIdsBlockedByFilters = alreadyCompanyMetaIds.filter(
    (id) => !companies.find((company) => company.companyMetaId === id)
  );
  const idToCompany = (id: number) =>
    allCompanyMetas.find((company: CompanyMeta) => company.company_meta_id === id) || null;

  const addedCompanyCategories: AddedCompanyCategory[] = [
    {
      text: (plural) => `${plural ? 'companies' : 'company'} added to list`,
      companyMetaIds: newCompanyMetaIdsPassingFilters
    },
    {
      text: (plural) =>
        `added ${
          plural ? 'companies do' : 'company does'
        } not pass filters but will be shown anyway until next refresh`,
      companyMetaIds: newCompanyMetaIdsBypassingFilters
    },
    {
      text: (plural) => `${plural ? 'companies' : 'company'} already in list`,
      companyMetaIds: alreadyCompanyMetaIdsPassingFilters
    },
    {
      text: (plural) =>
        `${
          plural ? 'companies' : 'company'
        } already in list but blocked by filters and so not shown`,
      companyMetaIds: alreadyCompanyMetaIdsBlockedByFilters
    }
  ];

  return (
    <Stack gap="16px" sx={{ padding: '8px' }}>
      {action.submission_info?.company_meta_ids ? (
        addedCompanyCategories.map((addedCompanyCategory) =>
          addedCompanyCategoryToMessageBlock(addedCompanyCategory, idToCompany)
        )
      ) : (
        <Typography>No companies added to the list.</Typography>
      )}
    </Stack>
  );
}
