import { Extension } from '@tiptap/core';
import { TT_PLACEHOLDER } from 'constants/assistant';

export const PlaceholderStorage = Extension.create({
  name: 'PlaceholderStorage',
  addStorage(): { placeholder: string } {
    return {
      placeholder: TT_PLACEHOLDER
    };
  }
});
