import { Stack, Typography } from '@mui/material';
import {
  ACTION_TYPE_TO_COLOR,
  ACTION_TYPE_TO_ICON,
  ACTION_TYPE_TO_LABEL
} from 'constants/assistant';
import MLIcon from 'design-system/MLIcon';
import MLInlineStack from 'design-system/MLInlineStack';
import { AssistantAction, isCustomAnalyticsAction } from 'models/assistant/assistant-actions';
import React from 'react';
import { updateActionParams } from 'reducer/assistant-slice';
import { useAppDispatch } from 'types/store';
import { capitalizeFirstLetter } from 'utils/string-utils';

import ActionDisplayActionButtons from './ActionDisplayActionButtons';
import ActionDisplayParametersComponent from './ActionDisplayParametersComponent';
import AssistantRenderText from './AssistantRenderText';

export interface ActionDisplayComponentProps {
  action: AssistantAction;
  actionTaken: boolean;
  listId: number;
  threadId?: string;
}

function ActionDisplayComponent({
  action,
  actionTaken,
  listId,
  threadId
}: ActionDisplayComponentProps) {
  const dispatch = useAppDispatch();

  const onActionParamsChange = (newAction: AssistantAction) => {
    if (!threadId) {
      return;
    }
    dispatch(
      updateActionParams({
        threadId,
        actionId: action.id,
        newAction
      })
    );
  };
  return (
    <Stack
      gap="8px"
      sx={{
        padding: '16px',
        paddingTop: '8px',
        borderRadius: '8px',
        backgroundColor: 'colors.gray_bg'
      }}>
      <MLInlineStack>
        <MLInlineStack borderRadius="8px" width="fit-content">
          <MLIcon
            color={ACTION_TYPE_TO_COLOR[action.action_type]}
            size="small"
            icon={React.createElement(ACTION_TYPE_TO_ICON[action.action_type])}
          />
          <Typography variant="text1_Medium" color={ACTION_TYPE_TO_COLOR[action.action_type]}>
            {ACTION_TYPE_TO_LABEL[action.action_type]}
          </Typography>
        </MLInlineStack>
        <MLInlineStack marginLeft="auto" alignItems="flex-start" height="100%" pt="8px">
          {actionTaken && (
            <ActionDisplayActionButtons listId={listId} action={action} threadId={threadId} />
          )}
          {!actionTaken && isCustomAnalyticsAction(action) && (
            <ActionDisplayParametersComponent
              action={action}
              onActionParamsChange={onActionParamsChange}
            />
          )}
        </MLInlineStack>
      </MLInlineStack>
      <Typography>
        {
          <AssistantRenderText
            text={action.text.startsWith('http') ? action.text : capitalizeFirstLetter(action.text)}
          />
        }
      </Typography>
    </Stack>
  );
}

export default ActionDisplayComponent;
