import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import AddNewWorkflowComponent from 'components/work-flow/AddNewWorkflowComponent';
import WorkflowsLibraryDialogRow from 'components/work-flow/WorkflowsLibraryDialogRow';
import { ListIcon } from 'icons/ListIcon';
import ShareIcon from 'icons/ShareIcon';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getAllSavedQueries } from 'selectors/saved-queries';
import { SAVED_QUERY_ACTION_TYPES } from 'utils/platter-utils';

function WorkflowsTemplateDialogStep({ listId }) {
  const allSavedQueries = useSelector(getAllSavedQueries);

  const workflowSaveQueries = useMemo(() => {
    const queries = [];
    if (allSavedQueries && Object.keys(allSavedQueries).length > 0) {
      Object.values(allSavedQueries)
        .filter((query) => query.type === SAVED_QUERY_ACTION_TYPES.WORKFLOW)
        .sort((q1, q2) => new Date(q2.time_created) - new Date(q1.time_created))
        .forEach((query) => {
          queries.push({
            ...query,
            Icon: query.isShared ? ShareIcon : ListIcon,
            shouldAddToState: true,
            savedQueryId: query.id
          });
        });
    }
    return queries;
  }, [allSavedQueries]);

  return (
    <Stack direction="column" gap="16px" sx={{ padding: '16px' }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" gap="8px">
        <Typography variant="h3_Semibold" color="colors.primary_text">
          {`${workflowSaveQueries.length} saved workflow${
            workflowSaveQueries.length === 1 ? '' : 's'
          }`}
        </Typography>
        <AddNewWorkflowComponent listId={listId} />
      </Stack>
      <TableContainer
        component={Box}
        sx={{
          flex: 1,
          border: '1px solid',
          borderColor: 'colors.ui_border',
          borderRadius: '8px',
          backgroundColor: 'colors.primary_bg'
        }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Created on </TableCell>
              <TableCell align="right">Created by</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {workflowSaveQueries.map((query) => (
              <WorkflowsLibraryDialogRow key={query.id} query={query} listId={listId} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
}

WorkflowsTemplateDialogStep.propTypes = {
  listId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default WorkflowsTemplateDialogStep;
