import { Box, Stack } from '@mui/material';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { MentionListBaseProps } from './MentionList';
import MentionListItem from './MentionListItem';

export interface MentionListAutocompleteHandle {
  onKeyDown: ({ event }: { event: KeyboardEvent }) => boolean;
}

const MentionListAutocomplete = forwardRef<MentionListAutocompleteHandle, MentionListBaseProps>(
  ({ items, command }, ref) => {
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const selectedBoxRef = useRef<HTMLDivElement>(null);
    const selectItem = (index: number) => {
      const item = items[index];

      if (item) {
        command(item);
      }
    };

    const upHandler = () => {
      setSelectedIndex((selectedIndex + items.length - 1) % items.length);
    };

    const downHandler = () => {
      setSelectedIndex((selectedIndex + 1) % items.length);
    };

    const enterHandler = () => {
      selectItem(selectedIndex);
    };

    useEffect(() => setSelectedIndex(0), [items]);

    useImperativeHandle(ref, () => ({
      onKeyDown: ({ event }) => {
        if (event.key === 'ArrowUp') {
          upHandler();
          return true;
        }

        if (event.key === 'ArrowDown') {
          downHandler();
          return true;
        }

        if (event.key === 'Enter') {
          enterHandler();
          return true;
        }

        return false;
      }
    }));

    // Keep active item in view
    useEffect(() => {
      if (selectedBoxRef.current) {
        selectedBoxRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'nearest'
        });
      }
    }, [selectedIndex]);
    return (
      <Stack
        sx={{
          overflowY: 'auto',
          maxWidth: '318px',
          maxHeight: '256px'
        }}>
        {items.map(({ id, label }, index) => (
          <Box
            ref={selectedIndex === index ? selectedBoxRef : null}
            key={index}
            sx={{ cursor: 'pointer' }}
            onClick={() => selectItem(index)}>
            <MentionListItem id={id} focused={index === selectedIndex} label={label} />
          </Box>
        ))}
      </Stack>
    );
  }
);

MentionListAutocomplete.displayName = 'MentionListAutocomplete';

export default MentionListAutocomplete;
