import React from 'react';

export default function CollaborateIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.95557 14.2545C5.53958 13.6705 6.33167 13.3424 7.15759 13.3424H12.3859C13.2118 13.3424 14.0039 13.6705 14.5879 14.2545C15.1719 14.8385 15.5 15.6306 15.5 16.4565V17.7636C15.5 18.0397 15.2761 18.2636 15 18.2636C14.7238 18.2636 14.5 18.0397 14.5 17.7636V16.4565C14.5 15.8958 14.2772 15.3581 13.8808 14.9616C13.4843 14.5651 12.9466 14.3424 12.3859 14.3424H7.15759C6.59689 14.3424 6.05915 14.5651 5.66267 14.9616C5.2662 15.3581 5.04346 15.8958 5.04346 16.4565V17.7636C5.04346 18.0397 4.8196 18.2636 4.54346 18.2636C4.26731 18.2636 4.04346 18.0397 4.04346 17.7636V16.4565C4.04346 15.6306 4.37155 14.8385 4.95557 14.2545Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.88574 6.5C8.71814 6.5 7.77161 7.44653 7.77161 8.61413C7.77161 9.78173 8.71814 10.7283 9.88574 10.7283C11.0533 10.7283 11.9999 9.78173 11.9999 8.61413C11.9999 7.44653 11.0533 6.5 9.88574 6.5ZM6.77161 8.61413C6.77161 6.89424 8.16585 5.5 9.88574 5.5C11.6056 5.5 12.9999 6.89424 12.9999 8.61413C12.9999 10.334 11.6056 11.7283 9.88574 11.7283C8.16585 11.7283 6.77161 10.334 6.77161 8.61413Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0158 13.8021C17.0849 13.5347 17.3576 13.374 17.625 13.443C18.2931 13.6155 18.8849 14.005 19.3077 14.5503C19.7304 15.0956 19.9601 15.7659 19.9606 16.4559V17.7634C19.9606 18.0395 19.7367 18.2634 19.4606 18.2634C19.1844 18.2634 18.9606 18.0395 18.9606 17.7634V16.4567C18.9606 16.4566 18.9606 16.4567 18.9606 16.4567C18.9602 15.9883 18.8043 15.5332 18.5173 15.163C18.2303 14.7928 17.8285 14.5284 17.375 14.4112C17.1076 14.3422 16.9468 14.0695 17.0158 13.8021Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0988 5.96067C14.1673 5.69315 14.4397 5.53182 14.7072 5.60031C15.3771 5.77182 15.9708 6.1614 16.3948 6.70763C16.8188 7.25385 17.0489 7.92566 17.0489 8.61713C17.0489 9.3086 16.8188 9.9804 16.3948 10.5266C15.9708 11.0729 15.3771 11.4624 14.7072 11.6339C14.4397 11.7024 14.1673 11.5411 14.0988 11.2736C14.0303 11.0061 14.1917 10.7337 14.4592 10.6652C14.914 10.5488 15.317 10.2843 15.6049 9.91345C15.8927 9.54263 16.0489 9.08655 16.0489 8.61713C16.0489 8.1477 15.8927 7.69162 15.6049 7.3208C15.317 6.94998 14.914 6.6855 14.4592 6.56906C14.1917 6.50057 14.0303 6.22818 14.0988 5.96067Z"
        fill="currentColor"
      />
    </svg>
  );
}
