export function isEmpty<T>(value: T | null | undefined): value is null | undefined {
  if (!value) return true;
  if (
    typeof value === 'object' &&
    // Check for array-like objects
    !('length' in value && value.length) &&
    // Check for objects
    !Object.keys(value).length &&
    !(value instanceof Map) &&
    !(value instanceof Set)
  ) {
    return true;
  }
  return false;
}

export function getObjectLength(object: unknown): number {
  if (isEmpty(object)) return 0;

  if (Array.isArray(object) || typeof object === 'string') {
    return object.length;
  }

  if (object instanceof Map || object instanceof Set) {
    return object.size;
  }

  if (typeof object === 'object' && object !== null) {
    return Object.keys(object).length;
  }

  return 0;
}
