import { addSavedQueryAction } from 'actions/saved_queries/add-saved-query-action';
import { updateSavedQueryAction } from 'actions/saved_queries/update-saved-query-action';
import { cloneDeep } from 'lodash';
import { SAVED_QUERY_ACTION_TYPES } from 'utils/platter-utils';

import { copyLibraryWorkflowByQueryAction } from './copy-library-workflow-by-query-action';

export function duplicateHistoryWorkflowAction(listId, workflow, duplicateWorkflowName, addToast) {
  return (dispatch) => {
    dispatch(
      addSavedQueryAction({
        name: duplicateWorkflowName,
        parameters: { ...workflow, name: duplicateWorkflowName },
        description: null,
        query_type: SAVED_QUERY_ACTION_TYPES.WORKFLOW,
        addToast,
        onSaveQuery: (saveQueryId, duplicateSavedQuery) => {
          const duplicateSavedQueryClone = cloneDeep(duplicateSavedQuery);
          duplicateSavedQueryClone.parameters.id = saveQueryId.toString();
          dispatch(
            updateSavedQueryAction(saveQueryId, duplicateSavedQueryClone.parameters, addToast)
          );
          dispatch(
            copyLibraryWorkflowByQueryAction(listId, {
              ...duplicateSavedQueryClone,
              shouldAddToState: true,
              owner: duplicateSavedQuery.user
            })
          );
        }
      })
    );
  };
}
