import { Button, Divider, Stack } from '@mui/material';
import BottomBarComponent from 'components/BottomBarComponent';
import ListRunActionsMenu from 'components/tile/discovery/ListRunActionsMenu';
import MLIconButton from 'design-system/MLIconButton';
import CloseIcon from 'design-system/icons/platform/CloseIcon';
import DeleteIcon from 'icons/DeleteIcon';
import { NewListIcon } from 'icons/NewListIcon';
import PropTypes from 'prop-types';
import React from 'react';
import { appTheme } from 'theme';

import { NewListActionsMenu } from './NewListActionsMenu';
import { SelectedCompaniesComponent } from './SelectedCompaniesComponent';

export const BottomBarDiscovery = ({
  listId,
  selectedCompanies,
  onClose,
  onRowsDeleted,
  onNewList,
  onDeepDive,
  onExtend,
  noRunsLeft
}) => {
  const numCompaniesSelected = selectedCompanies?.length;

  const maxLogosNum = 4;
  if (numCompaniesSelected <= 0) {
    return null;
  }

  return (
    <BottomBarComponent>
      <Stack flexDirection="row" gap="16px">
        <SelectedCompaniesComponent
          selectedCompanies={selectedCompanies}
          maxLogosNum={maxLogosNum}
        />
      </Stack>
      <Stack flexDirection="row" gap="16px">
        <NewListActionsMenu
          onDeepDive={onDeepDive}
          numSelected={numCompaniesSelected}
          noRunsLeft={noRunsLeft}
          onExtend={onExtend}></NewListActionsMenu>
        <ListRunActionsMenu listId={listId} />
        <Button onClick={onNewList} startIcon={<NewListIcon />}>
          Add To List
        </Button>
        <Button
          onClick={onRowsDeleted}
          startIcon={<DeleteIcon useDefaultColor={false} />}
          sx={{ color: appTheme.vars.palette.colors.negative_red }}>
          Remove
        </Button>
        <Divider
          orientation="vertical"
          sx={{ borderColor: 'colors.ui_border', height: '20px', alignSelf: 'center' }}
        />
        <MLIconButton onClick={onClose}>
          <CloseIcon />
        </MLIconButton>
      </Stack>
    </BottomBarComponent>
  );
};

BottomBarDiscovery.propTypes = {
  listId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  selectedCompanies: PropTypes.array,
  onClose: PropTypes.func,
  onRowsDeleted: PropTypes.func,
  onNewList: PropTypes.func,
  onDeepDive: PropTypes.func,
  noRunsLeft: PropTypes.bool,
  onExtend: PropTypes.func
};

export default React.memo(BottomBarDiscovery);
