import React from 'react';

export default function ExpandIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 4.5C13.5 4.22386 13.7239 4 14 4H19.5C19.7761 4 20 4.22386 20 4.5V10C20 10.2761 19.7761 10.5 19.5 10.5C19.2239 10.5 19 10.2761 19 10V5H14C13.7239 5 13.5 4.77614 13.5 4.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 13.5C4.77614 13.5 5 13.7239 5 14V19H10C10.2761 19 10.5 19.2239 10.5 19.5C10.5 19.7761 10.2761 20 10 20H4.5C4.22386 20 4 19.7761 4 19.5V14C4 13.7239 4.22386 13.5 4.5 13.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.3536 4.64645C19.5488 4.84171 19.5488 5.15829 19.3536 5.35355L14.3536 10.3536C14.1583 10.5488 13.8417 10.5488 13.6464 10.3536C13.4512 10.1583 13.4512 9.84171 13.6464 9.64645L18.6464 4.64645C18.8417 4.45118 19.1583 4.45118 19.3536 4.64645Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3536 13.6464C10.5488 13.8417 10.5488 14.1583 10.3536 14.3536L5.35355 19.3536C5.15829 19.5488 4.84171 19.5488 4.64645 19.3536C4.45118 19.1583 4.45118 18.8417 4.64645 18.6464L9.64645 13.6464C9.84171 13.4512 10.1583 13.4512 10.3536 13.6464Z"
        fill="currentColor"
      />
    </svg>
  );
}
