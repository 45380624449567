import { FINANCE_COLUMN_TYPES, FINANCE_TYPES_TO_TITLE } from 'constants/finance';
import WebsiteIcon from 'design-system/icons/basic/WebsiteIcon';
import BusinessIcon from 'design-system/icons/platform/BusinessIcon';
import GrowthIcon from 'design-system/icons/platform/GrowthIcon';
import HiddenGemsIcon from 'design-system/icons/platform/HiddenGemsIcon';
import LeftLeadershipIcon from 'design-system/icons/platform/LeftLeadershipIcon';
import PartnersIcon from 'design-system/icons/platform/PartnersIcon';
import CrunchbaseIcon from 'design-system/icons/sources/CrunchbaseIcon';
import LinkedinIcon from 'design-system/icons/sources/LinkedinIcon';
import PitchbookIcon from 'design-system/icons/sources/PitchbookIcon';
import DoneTypeIcon from 'icons/DoneTypeIcon';
import NumberTypeIcon from 'icons/NumberTypeIcon';
import TextTypeIcon from 'icons/TextTypeIcon';

export const CATEGORIES = Object.freeze({
  MY_SAVED_COLUMNS: 'Saved by me',
  SHARED_WITH_ME: 'Shared with me',
  MANUAL: 'Manual',
  BASIC: 'Basic',
  FINANCE: 'Finance',
  CLIENTS_AND_PARTNERS: 'Clients and Partners',
  BUSINESS: 'Business Model',
  GROWTH: 'Growth'
});

const CATEGORY_TO_INDEX = Object.values(CATEGORIES).reduce((acc, category, index) => {
  acc[category] = index + 1;
  return acc;
}, {});

export const categoriesSorter = (cat1, cat2) =>
  CATEGORY_TO_INDEX?.[cat1] - CATEGORY_TO_INDEX?.[cat2];

export const SAVED_QUERY_ACTION_TYPES = Object.freeze({
  COLUMN: 'COLUMN',
  ASK: 'ASK',
  WORKFLOW: 'WORKFLOW'
});

export const getPlatterEmptyColumns = (actionType) => {
  if (actionType !== SAVED_QUERY_ACTION_TYPES.COLUMN) {
    return [];
  }
  return [
    {
      data_name: 'custom_str',
      title: 'Text',
      type: 'custom_column',
      category: CATEGORIES.MANUAL
    },
    {
      data_name: 'custom_number',
      title: 'Number',
      type: 'custom_column',
      category: CATEGORIES.MANUAL
    },
    {
      data_name: 'custom_boolean',
      title: 'Yes/No',
      type: 'custom_column',
      category: CATEGORIES.MANUAL
    }
  ];
};

export const getPlatterBasicColumns = (actionType) => {
  if (actionType !== SAVED_QUERY_ACTION_TYPES.COLUMN) {
    return [];
  }

  return [
    {
      data_name: 'company_website',
      title: 'Website',
      type: 'basic_data_column',
      category: CATEGORIES.BASIC
    },
    {
      data_name: 'linkedin_url',
      title: 'Linkedin',
      type: 'basic_data_column',
      category: CATEGORIES.BASIC
    },
    {
      data_name: 'cb_url',
      title: 'Crunchbase Link',
      type: 'basic_data_column',
      category: CATEGORIES.BASIC
    },
    {
      data_name: 'hidden_gems',
      title: 'Hidden Gems',
      type: 'basic_data_column',
      category: CATEGORIES.BASIC
    }
  ];
};

export const getPlatterFinanceColumns = (actionType, canFinanceData) => {
  if (!canFinanceData || actionType !== SAVED_QUERY_ACTION_TYPES.COLUMN) {
    return [];
  }
  const columnType = 'finance_column';
  const columnInfo = { type: columnType, category: CATEGORIES.FINANCE };
  const typesToIgnore = [FINANCE_COLUMN_TYPES.REVENUES, FINANCE_COLUMN_TYPES.PROFIT];
  return Object.values(FINANCE_COLUMN_TYPES)
    .filter((column) => !typesToIgnore.includes(column))
    .map((column) => ({
      data_name: column,
      title: `${FINANCE_TYPES_TO_TITLE[column]} ($M)`,
      ...columnInfo
    }));
};

export const DATA_NAME_TO_ICON = {
  company_website: WebsiteIcon,
  linkedin_url: LinkedinIcon,
  cb_url: CrunchbaseIcon,
  custom_str: TextTypeIcon,
  custom_number: NumberTypeIcon,
  custom_boolean: DoneTypeIcon,
  PITCHBOOK_SCRAPINGS: PitchbookIcon,
  left_leadership_ca: LeftLeadershipIcon,
  LEFT_LEADERSHIP: LeftLeadershipIcon,
  hidden_gems: HiddenGemsIcon
};

export const CATEGORY_TO_ICON = {
  [CATEGORIES.GROWTH]: GrowthIcon,
  [CATEGORIES.BUSINESS]: BusinessIcon,
  [CATEGORIES.CLIENTS_AND_PARTNERS]: PartnersIcon
};
