import React from 'react';

function DoneTypeIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M18 8.00001L10.3484 16.608C10.1587 16.8214 9.82915 16.8329 9.62505 16.6333L6 13.087"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default DoneTypeIcon;
