import AssistantThread from 'models/assistant/AssistantThread';
import { Message } from 'models/assistant/types';
import { createSelector } from 'reselect';
import { RootState } from 'types/store';

const EMPTY_OBJECT = {};
const EMPTY_MESSAGE_ARRAY: Message[] = [];

export const getAssistantSlice = (state: RootState) => state.assistant;

// THREADS
export const getAssistantThreads = (state: RootState) =>
  getAssistantSlice(state)?.threads || EMPTY_OBJECT;
export const getAssistantThreadsByListId = (state: RootState, listId: number | null) =>
  Object.entries(getAssistantThreads(state)).reduce(
    (acc: Record<string, AssistantThread>, [threadId, thread]) => {
      if (thread.company_list_id === listId && !thread.is_hidden) {
        acc[threadId] = thread;
      }
      return acc;
    },
    {}
  );
export const getOrderedAssistantListThreadIds = createSelector(
  // Input selector: returns threads for the given listId
  (state: RootState, listId: number | null) => getAssistantThreadsByListId(state, listId),
  // Output selector: sorts the thread ids based on the timestamp
  (threads) => {
    return Object.keys(threads).sort((id1, id2) => threads[id1].timestamp - threads[id2].timestamp);
  }
);
export const getIsInitialState = (state: RootState, listId: number | null) => {
  const threads = getAssistantThreadsByListId(state, listId);
  return (
    Object.keys(threads).length === 1 && threads[Object.keys(threads)[0]].messages.length === 0
  );
};
export const getAssistantThreadById = (state: RootState, threadId: string) =>
  getAssistantThreads(state)?.[threadId];
export const getAssistantThreadMessagesById = (state: RootState, threadId: string) =>
  getAssistantThreadById(state, threadId)?.messages || EMPTY_MESSAGE_ARRAY;
export const getLatestListThread = (state: RootState, listId: number | null) => {
  const threads = getAssistantThreads(state);
  return Object.values(threads)
    .sort((thread1, thread2) => thread2.timestamp - thread1.timestamp)
    .filter((thread) => thread.company_list_id === listId)[0];
};
export const getLatestListThreadId = (state: RootState, listId: number | null) =>
  getLatestListThread(state, listId)?.id;
export const getThreadActionById = (
  state: RootState,
  threadId: string,
  actionId?: string | null
) => {
  if (!actionId) {
    return null;
  }
  return getAssistantThreadById(state, threadId).actions[actionId];
};

// PROMPT CONFIG
export const getPromptConfig = (state: RootState) => getAssistantSlice(state).promptConfig;
export const getPromptConfigActionType = (state: RootState) => getPromptConfig(state)?.action_type;
export const getPromptConfigAnswerType = (state: RootState) => getPromptConfig(state).answer_type;
export const getPromptConfigScoping = (state: RootState) => getPromptConfig(state).scoping;
export const getPromptConfigSearchSettings = (state: RootState) =>
  getPromptConfig(state).search_settings;
export const getPromptConfigOverwrite = (state: RootState) => getPromptConfig(state).overwrite;

// UTILITY
export const getAssistantUtility = (state: RootState) => getAssistantSlice(state).utility;
export const getAssistantUtilitScrollToEnd = (state: RootState) =>
  getAssistantUtility(state).scrollToEnd;
export const getAssistantUtilityNavigateToViewId = (state: RootState) =>
  getAssistantUtility(state).navigateToViewId;
export const getAssistantUtilityCompanyMetaIdsToFetch = (state: RootState) =>
  getAssistantUtility(state).companyMetaIdsToFetch;
export const getAssistantUtilitShowLoading = (state: RootState) =>
  getAssistantUtility(state).showLoading;
