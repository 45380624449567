import { Box, Divider, Skeleton, Stack, Typography } from '@mui/material';
import { DOMAIN_REPORT_TASK_TYPES } from 'constants/domain-reports';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { getLatestDRCompanyMetasByListId } from 'selectors/domain-reports';
import { clusterBreakdownFromTaggingData } from 'utils/domain-report-utils';
import { PPTX_CLASS_NAMES } from 'utils/react-to-powerpoint-utils';

import DomainReportBox from '../DomainReportBox';
import { useDomainReportContext } from '../DomainReportContext';
import ClusterTreeMap from './ClusterTreeMap';
import DomainReportClusterItem from './DomainReportClusterItem';

const determineSize = (companies, numTotalCompanies) => {
  const size = Math.ceil((companies.length / numTotalCompanies) * 10);
  return size > 5 ? 5 : size;
};

function DomainReportCluster({ section }) {
  const { title } = section.props;
  const { listId } = useDomainReportContext();
  const companyMetas = useSelector((state) => getLatestDRCompanyMetasByListId(state, listId));
  const { data } = section.props.data;
  const { clusters } = clusterBreakdownFromTaggingData(data, companyMetas) || {};
  return (
    <DomainReportBox title={title} taskType={DOMAIN_REPORT_TASK_TYPES.TAGGING}>
      <Stack gap="6px">
        <div className={PPTX_CLASS_NAMES.slideQuery}>
          <Typography variant="text1_Normal">
            Breakdown of the domain&apos;s distinct segments
          </Typography>
          <Box sx={{ marginBottom: '10px' }}>
            {clusters ? (
              <ClusterTreeMap
                height={478}
                data={Object.keys(clusters).map((cluster) => ({
                  name: cluster,
                  size: clusters[cluster].companies.length
                }))}
              />
            ) : (
              <Skeleton variant="rounded" width="100%" height={478} />
            )}
          </Box>
        </div>
        <Stack>
          {clusters ? (
            Object.values(clusters)
              .sort((cl1, cl2) => cl2.companies.length - cl1.companies.length)
              .map((cluster, index) => (
                <React.Fragment key={index}>
                  <DomainReportClusterItem
                    id={`domain-report-cluster-${index}`}
                    key={index}
                    title={cluster.name}
                    companies={cluster.companies}
                    description={cluster.description}
                    size={determineSize(cluster.companies, companyMetas.length)}
                  />
                  {index < Object.keys(clusters).length - 1 && <Divider flexItem />}
                </React.Fragment>
              ))
          ) : (
            <React.Fragment>
              <DomainReportClusterItem loading />
              <DomainReportClusterItem loading />
              <DomainReportClusterItem loading />
              <DomainReportClusterItem loading />
              <DomainReportClusterItem loading />
            </React.Fragment>
          )}
        </Stack>
      </Stack>
    </DomainReportBox>
  );
}

DomainReportCluster.propTypes = {
  section: PropTypes.object
};

export default DomainReportCluster;
