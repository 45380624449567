import { Suggestion } from 'components/custom-analytics/types/CustomAnalyticsPost';
import DiscoverySuggestionsContentComponent from 'components/discovery/DiscoverySuggestionsContentComponent';
import { ActionType } from 'models/assistant/assistant-actions';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { newPromptConfig } from 'reducer/assistant-slice';

interface DiscoverySuggestionsComponentProps {
  suggestions: Suggestion[];
}

function DiscoverySuggestionsComponent({ suggestions }: DiscoverySuggestionsComponentProps) {
  const dispatch = useDispatch();

  const onClickSuggestion = (suggestion: Suggestion) => {
    const params = {
      action_type: ActionType.DISCOVERY_BY_QUERY,
      text: suggestion.prompt
    };
    dispatch(newPromptConfig(params));
  };

  return (
    <DiscoverySuggestionsContentComponent
      suggestions={suggestions}
      onClickSuggestion={onClickSuggestion}
    />
  );
}

export default DiscoverySuggestionsComponent;

DiscoverySuggestionsComponent.propTypes = {
  suggestions: PropTypes.array,
  setPromptConfig: PropTypes.func
};
