import { sendUserEvent } from 'actions/users/send-user-event-action';
import { USER_EVENTS } from 'constants/userEvents';
import { dispatch } from 'hooks/AppStateProvider';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import './LoaderComponent.scss';

export default function LoaderComponent({ monitorTimeOpen }) {
  useEffect(() => {
    if (monitorTimeOpen) {
      const timer = setTimeout(() => {
        dispatch(sendUserEvent(USER_EVENTS.LONG_LOADER, {}));
      }, 10000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [monitorTimeOpen]);
  return (
    <div className="loader-component">
      <svg viewBox="0 0 50 50" className="ml-spinner">
        <circle className="ring" cx="25" cy="25" r="22.5" />
        <circle className="line" cx="25" cy="25" r="22.5" />
      </svg>
    </div>
  );
}

LoaderComponent.propTypes = {
  monitorTimeOpen: PropTypes.bool
};
