export const FINANCE_COLUMN_TYPES = Object.freeze({
  REVENUES: 'revenue',
  EBITDA: 'ebitda',
  OP_INCOME: 'operating_income',
  NET_INCOME: 'net_income',
  EARNING: 'earning',
  PROFIT: 'profit'
});

export const FINANCE_TYPES_TO_TITLE = {
  [FINANCE_COLUMN_TYPES.REVENUES]: 'Revenue',
  [FINANCE_COLUMN_TYPES.EBITDA]: 'EBITDA',
  [FINANCE_COLUMN_TYPES.EARNING]: 'Earning',
  [FINANCE_COLUMN_TYPES.NET_INCOME]: 'Net Income',
  [FINANCE_COLUMN_TYPES.OP_INCOME]: 'Operating Income'
};
