import { Typography } from '@mui/material';
import { MuiMarkdown } from 'mui-markdown';
import PropTypes from 'prop-types';
import React from 'react';
import { PPTX_CLASS_NAMES } from 'utils/react-to-powerpoint-utils';

import LinkWithAddToDomainKnowledge from './domain-knowledge/LinkWithAddToDomainKnowledge';

/* eslint-disable id-length */
function CustomMarkdown({ custom, body, listId, context, formatLinks }) {
  const formattedBody = body.replace(/\n\n/gu, '<br/><br/>\n\n');
  if (custom) {
    return (
      <Typography variant="text1_Normal" className={PPTX_CLASS_NAMES.markdown}>
        <MuiMarkdown
          overrides={{
            h1: {
              component: Typography,
              props: {
                variant: 'h1_Normal'
              }
            },
            h2: {
              component: Typography,
              props: {
                variant: 'h2_Normal'
              }
            },
            h3: {
              component: Typography,
              props: {
                variant: 'h3_Normal'
              }
            },
            h4: {
              component: Typography,
              props: {
                variant: 'h3_Normal'
              }
            },
            h5: {
              component: Typography,
              props: {
                variant: 'text1_Bold'
              }
            },
            h6: {
              component: Typography,
              props: {
                variant: 'text1_Bold'
              }
            },
            p: {
              component: Typography,
              props: {
                variant: 'text1_Normal'
              }
            },
            a: {
              component: formatLinks ? LinkWithAddToDomainKnowledge : Typography,
              props: formatLinks
                ? { listId, context }
                : { variant: 'text1_Normal', color: 'colors.link' }
            }
          }}>
          {formattedBody}
        </MuiMarkdown>
      </Typography>
    );
  }
  return (
    <Typography variant="text1_Normal" className={PPTX_CLASS_NAMES.markdown}>
      <MuiMarkdown
        overrides={{
          p: {
            component: Typography,
            props: {
              variant: 'text1_Normal'
            }
          },
          a: {
            component: formatLinks ? LinkWithAddToDomainKnowledge : Typography,
            props: formatLinks
              ? { listId, context }
              : { variant: 'text1_Normal', color: 'colors.link' }
          }
        }}>
        {formattedBody}
      </MuiMarkdown>
    </Typography>
  );
}

CustomMarkdown.propTypes = {
  body: PropTypes.string,
  listId: PropTypes.number,
  context: PropTypes.string,
  custom: PropTypes.bool,
  formatLinks: PropTypes.bool
};

CustomMarkdown.defaultProps = {
  custom: true,
  formatLinks: true
};

export default CustomMarkdown;
