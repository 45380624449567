import { Stack, Typography } from '@mui/material';
import { DOMAIN_REPORT_TASK_TYPES } from 'constants/domain-reports';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { getLatestDRCompanyMetasByListId } from 'selectors/domain-reports';
import { formatTopProblemsData } from 'utils/domain-report-utils';

import DomainReportBox from '../DomainReportBox';
import { useDomainReportContext } from '../DomainReportContext';
import DomainReportTopProblemExample from './DomainReportTopProblemExample';

const innerComponent = ({ topProblems }) => {
  return (
    <Stack gap="16px" marginTop="16px">
      {topProblems &&
        topProblems.map((problem, index) => (
          <DomainReportTopProblemExample
            id={`domain-report-top-problems-${index}`}
            key={index}
            {...problem}
            companies={problem.examples}
          />
        ))}
    </Stack>
  );
};

function DomainReportTopProblems({ section }) {
  const { listId } = useDomainReportContext();
  const companyMetas = useSelector((state) => getLatestDRCompanyMetasByListId(state, listId));
  const { data } = section.props.data;
  const { title } = section.props;
  const { topProblems } = formatTopProblemsData(data, companyMetas) || {};
  return (
    <DomainReportBox title={title} taskType={DOMAIN_REPORT_TASK_TYPES.TOP_PROBLEMS}>
      <Typography variant="text1_Normal">
        Key challenges addressed by companies in this domain
      </Typography>
      {topProblems ? (
        innerComponent({ topProblems })
      ) : (
        <Stack>
          <DomainReportTopProblemExample loading />
          <DomainReportTopProblemExample loading />
        </Stack>
      )}
    </DomainReportBox>
  );
}

DomainReportTopProblems.propTypes = {
  section: PropTypes.object
};

export default DomainReportTopProblems;
