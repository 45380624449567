import { v4 as uuidv4 } from 'uuid';

import { AssistantAction } from './assistant-actions';
import { Message } from './types';

class AssistantThread {
  id: string;
  timestamp: number;
  is_hidden: boolean;
  company_list_id: number | null;
  folder_id: number | null;
  messages: Message[];
  actions: Record<string, AssistantAction>;

  constructor(params: Partial<AssistantThread> = {}) {
    this.id = uuidv4();
    this.timestamp = Date.now();
    this.messages = [];
    this.is_hidden = false;
    this.actions = {};
    this.company_list_id = null;
    this.folder_id = null;
    Object.assign(this, params);
  }
}

export const createNewThreadForState = (payload: Partial<AssistantThread> = {}) => {
  return { ...new AssistantThread(payload) };
};

export default AssistantThread;
