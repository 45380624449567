import { Box, Collapse, Stack, Typography } from '@mui/material';
import MLInlineStack from 'design-system/MLInlineStack';
import ArrowIcon from 'icons/ArrowIcon';
import PropTypes from 'prop-types';
import React from 'react';
import { PPTX_CLASS_NAMES } from 'utils/react-to-powerpoint-utils';

function QuickViewExpandable({
  title,
  disabled,
  children,
  pptxIgnore,
  pptxAlways,
  endButton,
  startButton,
  defaultExpanded = false,
  marginTop = '12px',
  titleVariant = 'text1_Bold'
}) {
  const [expanded, setExpanded] = React.useState(defaultExpanded);
  const handleExpandedChange = () => setExpanded(!expanded);
  return (
    <Stack className={`${PPTX_CLASS_NAMES.footer} ${pptxIgnore ? PPTX_CLASS_NAMES.ignore : ''}`}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap="4px"
        onClick={disabled ? null : handleExpandedChange}
        sx={{
          color: disabled ? 'colors.primary_text' : null,
          opacity: disabled ? 0.38 : 1,
          cursor: disabled ? null : 'pointer'
        }}>
        <Typography variant={titleVariant} textTransform="capitalize">
          {startButton} {title}
        </Typography>
        <MLInlineStack gap="4px" marginLeft="auto">
          {endButton}
          <Box
            sx={{
              transform: expanded ? 'rotate(0deg)' : 'rotate(180deg)'
            }}>
            <ArrowIcon />
          </Box>
        </MLInlineStack>
      </Stack>
      <Collapse in={expanded} className={`${pptxAlways ? PPTX_CLASS_NAMES.ignoreHidden : ''}`}>
        {children && <Stack sx={{ marginTop }}>{children}</Stack>}
      </Collapse>
    </Stack>
  );
}

QuickViewExpandable.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  pptxIgnore: PropTypes.bool,
  pptxAlways: PropTypes.bool,
  defaultExpanded: PropTypes.bool,
  endButton: PropTypes.node,
  startButton: PropTypes.node,
  titleVariant: PropTypes.string,
  marginTop: PropTypes.string
};

export default QuickViewExpandable;
