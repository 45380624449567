import { Stack, useTheme } from '@mui/material';
import { DatadogRumAction } from 'constants/datadog-rum';
import React from 'react';

import AssistantActionsBar from '../AssistantActionsBar';
import TTProvider from './TTProvider';
import TTUtility from './TTUtility';

interface TTTextBoxProps {
  promptRef: React.RefObject<HTMLDivElement>;
  listId: number | null;
}

function TTTextBox({ promptRef, listId }: TTTextBoxProps) {
  const theme = useTheme();
  return (
    <Stack
      id="assistant-prompt-text-field"
      data-dd-action-name={DatadogRumAction.ENTER_UNIFY_CHATBOX}
      sx={{
        width: '100%',
        height: '100%',
        textAlign: 'left',
        ...theme.typography.text1_Normal,
        color: 'colors.primary_text'
      }}>
      <TTProvider
        slotAfter={<AssistantActionsBar promptRef={promptRef} listId={listId} />}
        slotBefore={<TTUtility />}
      />
    </Stack>
  );
}

export default React.memo(TTTextBox);
