import { Button, Stack, Typography } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid-premium';
import NonScrollBlockingDialog from 'components/NonScrollBlockingDialog';
import ViewButton from 'components/ViewButton';
import TableFiltersSectionComponent from 'components/elements/table/filters/TableFiltersSectionComponent';
import MLIconButton from 'design-system/MLIconButton';
import CloseIcon from 'design-system/icons/platform/CloseIcon';
import { useWindowSize } from 'hooks/useWindowSize';
import FilterIcon from 'icons/FilterIcon';
import { NewListIcon } from 'icons/NewListIcon';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { clearAllFilters, updateIsScreeningOpen } from 'reducer/explore-slice';
import { getExploreFiltersById, getIsScreeningOpen } from 'selectors/explore';

function TableScreeningFiltersComponent({ listId, viewId, allRows, filteredRowsNum, onNewList }) {
  const apiRef = useGridApiContext();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const openScreening = useSelector((state) => getIsScreeningOpen(state));
  const filters = useSelector((state) => getExploreFiltersById(state, listId));
  const windowSize = useWindowSize();

  const rawColumns = apiRef.current.getAllColumns();
  const allGroupsInfo = apiRef.current.getAllGroupDetails();
  const columnsAndGroups = useMemo(() => {
    const sections = {};
    rawColumns.forEach((column) => {
      const [sectionId] = apiRef.current.getColumnGroupPath(column.field);
      if (!sections[sectionId]) {
        sections[sectionId] = [];
      }
      sections[sectionId].push({
        id: column.field,
        title: column.headerName,
        data_type: column.type
      });
    });
    return Object.keys(sections)
      .filter((sectionId) => sectionId !== 'no-title')
      .map((sectionId) => {
        return {
          id: sectionId,
          sectionColumns: sections[sectionId],
          defaultExpand: allGroupsInfo[sectionId].defaultExpand,
          title: allGroupsInfo[sectionId].headerName
        };
      });
  }, [rawColumns, allGroupsInfo, apiRef]);

  const closeDialog = () => dispatch(updateIsScreeningOpen({ isOpen: false }));

  const numberOfFilters = filters ? Object.keys(filters).length : 0;

  const handleClearAll = () => {
    dispatch(clearAllFilters({ listId }));

    Array.from(searchParams.keys()).forEach((filter) => searchParams.delete(filter));
    setSearchParams(searchParams);
  };
  return (
    <React.Fragment>
      <Button
        variant="outlined"
        sx={{ backgroundColor: numberOfFilters > 0 ? 'colors.selected' : null }}
        startIcon={<FilterIcon />}
        onClick={() => dispatch(updateIsScreeningOpen({ isOpen: true }))}>
        Filter {numberOfFilters ? `(${numberOfFilters})` : ''}
      </Button>
      <NonScrollBlockingDialog open={openScreening} onClose={closeDialog}>
        <Stack
          direction="column"
          gap="8px"
          sx={{
            boxShadow: '0px 0px 10px 1px rgba(0, 19, 195, 0.15)',
            position: 'absolute',
            top: 50,
            right: 0,
            width: '400px',
            height: `${windowSize[1] - 50}px`,
            backgroundColor: 'colors.primary_bg',
            overflowY: 'auto',
            outline: 'none',
            zIndex: 'screeningPanel'
          }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            padding="12px 16px"
            sx={{ backgroundColor: 'colors.gray_bg' }}>
            <Stack direction="row" alignItems="center" gap="8px">
              <FilterIcon />
              <Typography variant="h4">{`Filter ${
                numberOfFilters ? `(${numberOfFilters})` : ''
              }`}</Typography>
            </Stack>
            <Stack direction="row" gap="8px">
              {numberOfFilters > 0 && <Button onClick={handleClearAll}>Clear All</Button>}
              <MLIconButton onClick={closeDialog}>
                <CloseIcon />
              </MLIconButton>
            </Stack>
          </Stack>
          <ViewButton
            listId={listId}
            viewId={viewId}
            sx={{ marginX: '8px', borderRadius: '4px' }}
            variant="outlined"
            toolTipPlacement="left"
          />
          <Stack direction="row" gap="16px" alignItems="center">
            <Typography variant="text1_Normal" padding="16px">
              Showing {filteredRowsNum === allRows.length ? 'all' : `${filteredRowsNum} out`} of{' '}
              {allRows.length} companies
            </Typography>
            <Button startIcon={<NewListIcon />} onClick={onNewList}>
              Add To List
            </Button>
          </Stack>
          <Stack overflow="scroll">
            {columnsAndGroups.map((section, index) => (
              <TableFiltersSectionComponent
                key={section.id}
                listId={listId}
                viewId={viewId}
                sectionTitle={section.title}
                sectionColumns={section.sectionColumns}
                defaultExpand={section.defaultExpand}
                rows={allRows}
                filters={filters}
                isLast={index === columnsAndGroups.length - 1}
              />
            ))}
          </Stack>
        </Stack>
      </NonScrollBlockingDialog>
    </React.Fragment>
  );
}

TableScreeningFiltersComponent.propTypes = {
  listId: PropTypes.number,
  allRows: PropTypes.array,
  filteredRowsNum: PropTypes.number,
  onNewList: PropTypes.func,
  viewId: PropTypes.string
};

export default TableScreeningFiltersComponent;
