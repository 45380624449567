import { Column, Company, SupportingResource } from 'models/assistant/types';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getCompanyMetasMergedRowsByCompanyListId } from 'selectors/companyMetas';
import {
  getExploreFiltersById,
  getExploreFiltersBypassById,
  getListCustomColumnsByListId,
  getListDomainKnowledgeByListId
} from 'selectors/explore';
import { getExploreUiColumnsByListId } from 'selectors/explore-table-ui';
import { getCurrentFolderSupportingResources } from 'selectors/folders';
import { getUserFeatureFlags } from 'selectors/user';
import { useAppDispatch, useAppSelectorWithArgs } from 'types/store';
import { filterExploreRow } from 'utils/companyList-utils';
import { combineDomainKnowledge } from 'utils/domain-knowledge-utils';
import { getCompaniesAndColumns } from 'utils/prompts-utils';

import { useOldStateSelector } from './useOldStateSelector';

function useDeriveListContext({ listId = null }: { listId: number | null }) {
  const canUnifyVal = (useOldStateSelector(getUserFeatureFlags) || []).includes('unify');
  const canUnify = useMemo(() => Boolean(canUnifyVal), [canUnifyVal]);
  const exploreFilters = useAppSelectorWithArgs(getExploreFiltersById, listId);
  const filtersBypass = useAppSelectorWithArgs(getExploreFiltersBypassById, listId);
  const dispatch = useAppDispatch();
  const filterRow = useCallback(
    (row: any[]) =>
      filterExploreRow(row, exploreFilters, false, filtersBypass, dispatch, (row2) => row2),
    [exploreFilters, filtersBypass, dispatch]
  );
  // companies
  const rows = useSelector((state) => getCompanyMetasMergedRowsByCompanyListId(state, listId));
  const filteredRows = useMemo(() => (rows || []).filter(filterRow), [rows, filterRow]);

  // columns
  const customColumns = useSelector((state) => getListCustomColumnsByListId(state, listId));
  const columnsSettings = useSelector((state) => getExploreUiColumnsByListId(state, listId));

  // supportingResources
  const listDomainKnowledge = useSelector((state) => getListDomainKnowledgeByListId(state, listId));
  const folderDomainKnowledge = useSelector((state) => getCurrentFolderSupportingResources(state));
  const domainKnowledge = useMemo(
    () =>
      combineDomainKnowledge(listDomainKnowledge, folderDomainKnowledge) as SupportingResource[],
    [listDomainKnowledge, folderDomainKnowledge]
  );

  // Infer columns and companies from the rows and settings.
  const { columns: columnsObj, companies: companiesMap } = useMemo(() => {
    return getCompaniesAndColumns(listId, filteredRows, customColumns, columnsSettings);
  }, [filteredRows, customColumns, listId, columnsSettings]);

  // Convert the objs/maps to arrays.
  const companies = useMemo(() => {
    return Array.from(companiesMap.values()) as Company[];
  }, [companiesMap]);
  const columns = useMemo(() => {
    return Object.values(columnsObj) as Column[];
  }, [columnsObj]);

  return {
    companies,
    columns,
    supportingResources: domainKnowledge,
    canUnify,
    customColumns
  };
}

export default useDeriveListContext;
