import { useCallback, useMemo, useRef, useState } from 'react';

export type UseMenuManagementReturn<T extends HTMLElement = HTMLButtonElement> = {
  onOpen: () => void;
  onClose: () => void;
  open: boolean;
  anchorEl: T | null;
  ref: React.RefObject<T>;
};

export default function useMenuManagement<T extends HTMLElement = HTMLButtonElement>(
  providedRef: React.RefObject<T> | null = null
): UseMenuManagementReturn<T> {
  const [anchorEl, setAnchorEl] = useState<null | T>(null);
  const defaultRef = useRef<T>(null);
  const ref = useMemo(() => providedRef || defaultRef, [providedRef]);
  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const onOpen = useCallback(() => {
    setAnchorEl(ref.current);
  }, [ref]);
  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, []);
  return { ref, anchorEl, open, onOpen, onClose };
}
