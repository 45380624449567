import { Typography } from '@mui/material';
import { ACTION_TYPE_TO_LABEL } from 'constants/assistant';
import MLIconButton from 'design-system/MLIconButton';
import MLInlineStack from 'design-system/MLInlineStack';
import CloseIcon from 'design-system/icons/platform/CloseIcon';
import React from 'react';
import { updatePromptConfig } from 'reducer/assistant-slice';
import { getPromptConfigActionType } from 'selectors/assistant';
import { useAppDispatch, useAppSelector } from 'types/store';

export default function AssistantActionBanner() {
  const actionType = useAppSelector(getPromptConfigActionType);
  const dispatch = useAppDispatch();
  if (!actionType) {
    return null;
  }
  return (
    <MLInlineStack
      sx={{
        padding: '8px 12px',
        borderRadius: '4px',
        boxSizing: 'border-box'
      }}
      justifyContent="space-between">
      <Typography>{ACTION_TYPE_TO_LABEL[actionType]}</Typography>
      <MLIconButton
        size="small"
        tooltipText="Cancel"
        tooltipPlacement="top"
        onClick={() => dispatch(updatePromptConfig({ action_type: null }))}>
        <CloseIcon color="currentColor" />
      </MLIconButton>
    </MLInlineStack>
  );
}
