import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  getLatestDRTaskProgressByListId,
  getLatestDRTaskStatusByListId
} from 'selectors/domain-reports';

import { useDomainReportContext } from './DomainReportContext';

const RADIUS = 16;
const PROGRESS_HEIGHT = 6;

interface DomainReportBoxProps {
  title: string;
  taskType?: string;
  children: React.ReactNode;
  className?: string;
}

function DomainReportBox({ title, taskType, children, className }: DomainReportBoxProps) {
  const { listId } = useDomainReportContext();
  const progress = useSelector((state) => getLatestDRTaskProgressByListId(state, listId, taskType));
  const status = useSelector((state) => getLatestDRTaskStatusByListId(state, listId, taskType));
  const showProgress = status !== 'FAILED' && progress && progress < 1 && progress > 0;
  const borderRadius = `${RADIUS}px`;
  const height = `${PROGRESS_HEIGHT}px`;
  return (
    <Stack
      className={className}
      sx={{
        overflowX: 'hidden',
        backgroundColor: 'colors.primary_bg',
        borderRadius,
        color: 'colors.primary_text',
        pageBreakInside: 'avoid',
        maxWidth: '802px'
      }}>
      {status === 'FAILED' && (
        <Box
          sx={{
            borderTopLeftRadius: '16px',
            borderTopRightRadius: '16px',
            width: '100%',
            height,
            backgroundColor: 'colors.negative_red'
          }}
        />
      )}
      {showProgress ? (
        <Box
          sx={{
            width: '100%',
            height,
            backgroundColor: 'colors.selected'
          }}>
          <Box
            sx={{
              borderBottomRightRadius: progress < 1 ? '9px' : '0px',
              borderBottomLeftRadius: '0px',
              borderTopLeftRadius: '16px',
              borderTopRightRadius: progress < 1 ? '9px' : '16px',
              width: `${progress * 100}%`,
              height,
              backgroundColor: 'graphColors.graph13'
            }}
          />
        </Box>
      ) : null}
      <Stack
        gap="12px"
        sx={{
          paddingY: '24px',
          paddingX: '32px',
          maxWidth: '802px'
        }}>
        <Stack direction="row" gap="8px" alignItems="center" height="fit-content" minHeight="20">
          <Typography variant="h4" color="colors.primary_text">
            {title}
          </Typography>
        </Stack>
        {status === 'FAILED' ? (
          <Typography variant="text1_Normal">Job failed.</Typography>
        ) : (
          children
        )}
      </Stack>
    </Stack>
  );
}
export default DomainReportBox;
