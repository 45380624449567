import { TextField, Typography } from '@mui/material';
import { updateAttributeTitleInWorkflowAction } from 'actions/workflows/update-attribute-title-in-workflow-action';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { appTheme } from 'theme';

function WorkflowAttributeTitleComponent({
  listId,
  workflowId,
  stepId,
  attributeName,
  attributeTitle,
  attributeDefaultTitle,
  color,
  isEditMode
}) {
  const dispatch = useDispatch();

  const onValueChange = (event) => {
    dispatch(updateAttributeTitleInWorkflowAction(listId, workflowId, stepId, event.target.value));
  };

  return (
    <React.Fragment>
      {isEditMode ? (
        <TextField
          value={attributeTitle}
          onChange={onValueChange}
          slotProps={{ input: { sx: { padding: '4px' } } }}
          sx={{
            '.MuiInputBase-input': {
              ...appTheme.typography.text1_Medium,
              color,
              padding: 0
            }
          }}
        />
      ) : (
        <Typography
          variant="text1_Medium"
          color={color}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          sx={{ width: 'calc(100% - 32px)', padding: '4px' }}>
          {attributeTitle || attributeDefaultTitle || attributeName}
        </Typography>
      )}
    </React.Fragment>
  );
}

WorkflowAttributeTitleComponent.propTypes = {
  listId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  workflowId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  stepId: PropTypes.string,
  attributeName: PropTypes.string,
  attributeTitle: PropTypes.string,
  attributeDefaultTitle: PropTypes.string,
  color: PropTypes.string,
  isEditMode: PropTypes.bool,
  onValueChange: PropTypes.func
};

export default WorkflowAttributeTitleComponent;
