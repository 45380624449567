import React from 'react';

function ColumnsIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1667 19V5H9.83333V19H14.1667ZM15.1667 20H14.6667H14.1667H9.83333H9.33333H8.83333H6C4.89543 20 4 19.1046 4 18V6C4 4.89543 4.89543 4 6 4H8.83333H9.33333H9.83333H14.1667H14.6667H15.1667H18C19.1046 4 20 4.89543 20 6V18C20 19.1046 19.1046 20 18 20H15.1667ZM15.1667 5H18C18.5523 5 19 5.44772 19 6V18C19 18.5523 18.5523 19 18 19H15.1667V5ZM8.83333 19H6C5.44772 19 5 18.5523 5 18V6C5 5.44772 5.44772 5 6 5H8.83333V19Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default ColumnsIcon;
