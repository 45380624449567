import { sendUserEvent } from 'actions/users/send-user-event-action';
import { addDomainReport } from 'reducer/domain-reports-slice';
import { isDomainReportCASectionType } from 'utils/domain-report/domain-report-ts-utils';

import { bindDomainReportPusherAction } from './bind-domain-report-pusher-action';
import { loadDomainReportAction } from './load-domain-report-action';

export function submitDomainReportAction({
  listId,
  companyMetaIds,
  filters,
  filtered_out_company_meta_ids,
  eventType
}) {
  return (dispatch, _getState, apiClient) => {
    dispatch(
      sendUserEvent(eventType, {
        listId,
        included_companies: companyMetaIds.length,
        filters: filters,
        filtered_out_companies: filtered_out_company_meta_ids.length
      })
    );
    apiClient.explore
      .submitExploreListDomainReport(listId, companyMetaIds, filters, filtered_out_company_meta_ids)
      .then((res) => {
        if (res.ok) {
          dispatch(
            addDomainReport({
              listId,
              data: {
                ...res.data,
                exists: true,
                domain_report: {
                  parameters: {
                    company_meta_ids: companyMetaIds,
                    filters,
                    filtered_out_company_meta_ids: filtered_out_company_meta_ids,
                    sections: res.data.sections
                  }
                }
              }
            })
          );
          res.data.sections
            .filter((section) => {
              return isDomainReportCASectionType(section.type);
            })
            .forEach((section) => {
              dispatch(
                bindDomainReportPusherAction({
                  listId,
                  domainReportId: res.data.domain_report_id,
                  postId: section.data?.post_id
                })
              );
            });
        }
        dispatch(loadDomainReportAction(listId));
      });
  };
}
