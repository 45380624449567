import { Stack, TextField, Typography, useTheme } from '@mui/material';
import { useAppUtils } from 'AppUtilsProvider';
import { updateSupportingResourceData } from 'actions/supporting-resources/update-supporting-resources-data';
import { sendUserEvent } from 'actions/users/send-user-event-action';
import { DomainKnowledgeType } from 'constants/domain-knowledge';
import { TOAST_TYPES } from 'constants/toasts';
import { DOMAIN_KNOWLEDGE_EVENTS } from 'constants/userEvents';
import MLMarkdownEditor from 'design-system/MLMarkdownEditor';
import MLModal from 'design-system/MLModal';
import React, { useState } from 'react';
import { updateExploreDomainKnowledgeResource } from 'reducer/explore-slice';
import { useAppDispatch } from 'types/store';

interface EditDomainKnowledgeAnswerDialogProps {
  open: boolean;
  listId: number;
  folderId: number;
  onClose: () => void;
  answer: { id: number; name: string; data: any };
}

export default function EditDomainKnowledgeAnswerDialog({
  open,
  onClose,
  answer,
  listId,
  folderId
}: EditDomainKnowledgeAnswerDialogProps) {
  const name = answer?.name;
  const body = answer?.data?.message?.answer;
  const dispatch = useAppDispatch();
  const { addToast } = useAppUtils();
  const [internalTitle, setInternalTitle] = useState(name);
  const [internalBody, setInternalBody] = useState(body);
  const updateTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInternalTitle(event.target.value);
  };
  const updateBody = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInternalBody(event.target.value);
  };
  const theme = useTheme();
  const resetAndClose = () => {
    onClose();
    setInternalTitle(name);
    setInternalBody(body);
  };

  const errorHandler = (error: any) => {
    addToast('Failed to update resource', TOAST_TYPES.ERROR);
    dispatch(
      sendUserEvent(DOMAIN_KNOWLEDGE_EVENTS.DOMAIN_KNOWLEDGE_RESOURCE_UPDATE_FAILURE, {
        listId,
        folderId,
        type: DomainKnowledgeType.ANSWERS,
        error,
        answer
      })
    );
    dispatch(updateExploreDomainKnowledgeResource({ listId, resource: answer }));
  };

  const saveAndClose = () => {
    dispatch(
      updateSupportingResourceData(
        listId,
        folderId,
        DomainKnowledgeType.ANSWERS,
        {
          ...answer,
          name: internalTitle,
          data: {
            ...answer.data,
            title: internalTitle,
            message: {
              ...answer.data.message,
              answer: internalBody
            }
          }
        },
        addToast,
        errorHandler
      )
    );
    onClose();
  };

  const primaryButtonProps = {
    label: 'Save Changes',
    onClick: () => saveAndClose()
  };
  const secondaryButtonProps = {
    label: 'Cancel',
    onClick: () => resetAndClose()
  };
  return (
    <MLModal
      maxWidth="md"
      title={`Edit Saved Answer - ${name}`}
      open={open}
      onClose={resetAndClose}
      primaryButtonProps={primaryButtonProps}
      secondaryButtonProps={secondaryButtonProps}>
      <Stack gap="24px">
        <Stack gap="12px">
          <Typography variant="text1_Semibold">Answer Name</Typography>
          <TextField
            variant="outlined"
            value={internalTitle}
            size="small"
            placeholder="Choose a title for this saved answer"
            slotProps={{
              input: {
                sx: {
                  ...theme.typography.text1_Normal
                }
              }
            }}
            onChange={updateTitle}
          />
        </Stack>
        <Stack gap="12px">
          <Typography variant="text1_Semibold">Answer Content</Typography>
          <MLMarkdownEditor
            value={internalBody}
            onChange={updateBody}
            placeholder="Choose body for this saved answer"
          />
        </Stack>
      </Stack>
    </MLModal>
  );
}
