import { Box, Divider, Stack, Typography } from '@mui/material';
import CustomMarkdown from 'components/CustomMarkdown';
import { AssistantOutput } from 'components/custom-analytics/types/CustomAnalyticsResponse';
import CustomAnalyticsSourceItemComponent from 'components/elements/table/CustomAnalyticsSourceItemComponent';
import QuickViewExpandable from 'components/elements/table/quick-view/QuickViewExpandable';
import ModalComponent from 'components/modal/ModalComponent';
import { CUSTOM_ANALYTICS_PLACEMENT } from 'constants/custom-analytics';
import MLIconButton from 'design-system/MLIconButton';
import JsonIcon from 'design-system/icons/JsonIcon';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import React, { useState } from 'react';
import { getUserFeatureFlags } from 'selectors/user';
import { formatAnswerBody } from 'utils/custom-analytics-utils';
import { isEmpty } from 'utils/objects-utils';

interface CustomAnalyticsAnswerComponentProps {
  message: AssistantOutput;
  listId: number;
  defaultOpenAll?: boolean;
  titleVariant?: string;
  context?: any;
  answerMaxHeight?: number;
}

export default function CustomAnalyticsAnswerComponent({
  message,
  listId,
  context,
  defaultOpenAll = false,
  answerMaxHeight = 400,
  titleVariant = 'text1_Bold'
}: CustomAnalyticsAnswerComponentProps) {
  const { sources, reasoning, tool_call_history, answer, answer_type } = message;

  const [toolCallHistoryOpen, setToolCallHistoryOpen] = useState(false);
  const featureFlags = useOldStateSelector(getUserFeatureFlags);

  const sourcesList = sources && Object.values(sources);

  const handleToolCallHistoryClose = () => setToolCallHistoryOpen(false);
  const handleToolHistory = (event: any) => {
    setToolCallHistoryOpen(true);
    event.stopPropagation();
    event.preventDefault();
  };

  const toolCallHistoryButton = tool_call_history && featureFlags.includes('ca_planner') && (
    <MLIconButton
      sx={{ marginLeft: 'auto', height: '24px' }}
      tooltipText="Show tool tree"
      onClick={handleToolHistory}>
      <JsonIcon />
    </MLIconButton>
  );

  const renderSources = !isEmpty(sourcesList) && (
    <Stack gap="8px">
      {sourcesList?.map((source) => (
        <CustomAnalyticsSourceItemComponent
          key={source}
          listId={listId}
          source={source}
          placement={CUSTOM_ANALYTICS_PLACEMENT.EXPLORE}
        />
      ))}
    </Stack>
  );

  const renderAnswer = (
    <Box maxHeight={answerMaxHeight} width="100%" sx={{ overflowY: 'auto' }}>
      <Typography
        variant="text1_Normal"
        color="colors.primary_text"
        textAlign="left"
        style={{ whiteSpace: 'pre-line' }}>
        <CustomMarkdown
          body={formatAnswerBody(answer, answer_type)}
          listId={listId}
          custom
          context={context}
        />
      </Typography>
    </Box>
  );

  const items = [
    {
      title: 'Answer',
      value: renderAnswer,
      defaultOpen: defaultOpenAll || true
    },
    {
      title: isEmpty(sourcesList) ? 'No sources found' : 'Sources',
      value: renderSources,
      defaultOpen: defaultOpenAll
    },
    {
      title: 'Reasoning',
      value: reasoning,
      endButton: toolCallHistoryButton,
      defaultOpen: defaultOpenAll
    }
  ].map((item) => ({
    ...item,
    hasValue: !isEmpty(item.value)
  }));
  return (
    <Stack direction="column" gap="8px" sx={{ marginTop: '-4px' }}>
      {items.map((item) => (
        <Stack key={item.title}>
          <QuickViewExpandable
            title={item.title}
            disabled={!item.hasValue}
            pptxAlways={true}
            defaultExpanded={item.defaultOpen}
            endButton={item.endButton}
            titleVariant={titleVariant}>
            {item.hasValue && (
              <Typography variant="text1_Normal" color="colors.primary_text" textAlign="left">
                {item.value}
              </Typography>
            )}
          </QuickViewExpandable>
          <Divider sx={{ paddingTop: '8px' }} />
        </Stack>
      ))}

      <ModalComponent open={toolCallHistoryOpen} onClose={handleToolCallHistoryClose}>
        <Stack padding="16px">
          <pre>{JSON.stringify(tool_call_history, null, 2)}</pre>
        </Stack>
      </ModalComponent>
    </Stack>
  );
}
