import { GridApiPro } from '@mui/x-data-grid-pro';
import { useEffect, useState } from 'react';

export default function useScrollToEndDetector(
  apiRef: React.MutableRefObject<GridApiPro>,
  isTableScrollable: boolean
) {
  const [isScrolledToEndRight, setIsScrolledToEndRight] = useState(false);
  const [isScrolledToEndBottom, setIsScrolledToEndBottom] = useState(false);

  useEffect(() => {
    if (!apiRef.current) return;

    const onScroll = (args: { top: number; left: number }) => {
      if (!apiRef.current) return;

      const container = apiRef.current.rootElementRef?.current?.querySelector(
        '.MuiDataGrid-virtualScroller'
      );
      if (!container) return;
      const { top, left } = args;

      const atBottom = top + container.clientHeight >= container.scrollHeight - 1;
      const atRightEnd = left + container.clientWidth >= container.scrollWidth - 1;

      // I would rather to change the border radius of the table corners directly from apiRef but i dont have time.
      // currently when one of the values changes, the whole table is re-rendered
      setIsScrolledToEndBottom(!isTableScrollable || atBottom);
      setIsScrolledToEndRight(atRightEnd);
    };

    requestAnimationFrame(() =>
      onScroll({
        top: 0,
        left: 0
      })
    );
    const unsubscribeToScrollEvent = apiRef.current.subscribeEvent(
      'scrollPositionChange',
      onScroll
    );

    return () => {
      unsubscribeToScrollEvent();
    };
  }, [apiRef, isTableScrollable]);
  return { isScrolledToEndRight, isScrolledToEndBottom };
}
