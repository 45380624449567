import { sendUserEvent } from 'actions/users/send-user-event-action';
import { DomainKnowledgeType } from 'constants/domain-knowledge';
import { TOAST_TYPES } from 'constants/toasts';
import { DOMAIN_KNOWLEDGE_EVENTS } from 'constants/userEvents';
import { updateExploreDomainKnowledgeResource } from 'reducer/explore-slice';
import { AppDispatch, getAppState } from 'types/store';

export function updateSupportingResourceData(
  listId: number,
  folderId: number,
  type: DomainKnowledgeType,
  resource: any,
  addToast: (message: string, type: string) => void,
  errorHandler?: (error: any) => void
) {
  return (dispatch: AppDispatch, getState: getAppState, apiClient: any) => {
    dispatch(
      sendUserEvent(DOMAIN_KNOWLEDGE_EVENTS.DOMAIN_KNOWLEDGE_RESOURCE_UPDATE, {
        listId,
        folderId,
        type,
        resource
      })
    );
    const payload = {
      company_list_id: listId,
      folder_id: folderId,
      name: resource.name,
      supporting_resource_type: type,
      data: resource.data
    };
    dispatch(updateExploreDomainKnowledgeResource({ listId, resource }));
    apiClient.supportingResources
      .updateSupportingResourceData(resource.id, payload)
      .then((res: any) => {
        if (res.ok && res.data) {
          addToast('Resource Updated Successfully', TOAST_TYPES.SUCCESS);
        } else {
          errorHandler?.(res);
        }
      })
      .catch((error: any) => {
        errorHandler?.(error);
      });
  };
}
