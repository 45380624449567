import { Avatar, Stack, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { getLatestDRCompanyMetasByListId } from 'selectors/domain-reports';
import { PPTX_CLASS_NAMES } from 'utils/react-to-powerpoint-utils';

import DomainReportBreakdownItem from './DomainReportBreakdownItem';

const LOGO_SIZE = '24px';
const CompanyComponent = ({ company }) => {
  return (
    <Stack direction="row" gap="8px" alignItems="center" sx={{ padding: '8px' }}>
      <Stack
        direction="row"
        gap="8px"
        alignItems="center"
        sx={{
          width: '200px',
          paddingRight: '8px',
          textOverflow: 'ellipsis',
          textWrap: 'nowrap'
        }}>
        <Avatar
          src={company?.parameters?.logo_url}
          sx={{ width: LOGO_SIZE, height: LOGO_SIZE }}
          variant="rounded"
        />
        <Tooltip title={company.name} arrow>
          <Typography
            sx={{ marginX: '8px', overflowX: 'hidden', textOverflow: 'ellipsis' }}
            variant="text1_Medium">
            {company.name}
          </Typography>
        </Tooltip>
      </Stack>
      <Typography
        sx={{ marginX: '8px', overflowX: 'hidden', textOverflow: 'ellipsis', textWrap: 'nowrap' }}
        variant="text1_Normal">
        {company?.company_extra_data?.SUMMARY?.data?.summary}
      </Typography>
    </Stack>
  );
};

function DomainReportCompaniesBreakdown({ listId }) {
  const companyMetas = useSelector((state) => getLatestDRCompanyMetasByListId(state, listId));
  return (
    <Stack
      gap="8px"
      sx={{ maxHeight: '400px', overflowY: 'scroll' }}
      className={PPTX_CLASS_NAMES.ignore}>
      {companyMetas &&
        companyMetas.map((company, index) => (
          <DomainReportBreakdownItem
            key={index}
            component={<CompanyComponent company={company} />}
          />
        ))}
    </Stack>
  );
}

CompanyComponent.propTypes = {
  company: PropTypes.object
};

DomainReportCompaniesBreakdown.propTypes = {
  listId: PropTypes.number
};

export default DomainReportCompaniesBreakdown;
