import { Popover, Stack, Typography } from '@mui/material';
import MLIconButton from 'design-system/MLIconButton';
import MLInlineStack from 'design-system/MLInlineStack';
import InfoIcon from 'design-system/icons/basic/InfoIcon';
import {
  ActionType,
  AssistantAction,
  isCustomAnalyticsAction,
  isDiscoveryAction
} from 'models/assistant/assistant-actions';
import React, { useMemo } from 'react';
import { parseMentions } from 'utils/assistant/tiptap';

interface ActionPromptDetailsComponentProps {
  action: AssistantAction;
}

function ActionPromptDetailsComponent({ action }: ActionPromptDetailsComponentProps) {
  const [infoMenuAnchorEl, setInfoMenuAnchorEl] = React.useState<null | HTMLElement>(null);

  const promptDetails: Record<string, string> = useMemo(() => {
    if (isDiscoveryAction(action)) {
      switch (action.action_type) {
        case ActionType.DISCOVERY_BY_QUERY:
          return { 'Search by': 'Query' };
        case ActionType.DISCOVERY_BY_PEERS:
          return { 'Search by': 'Peers' };
        case ActionType.DISCOVERY_BY_URL:
          return { 'Search by': 'URL' };
        default:
          return {};
      }
    }
    if (isCustomAnalyticsAction(action)) {
      const mentions = parseMentions(action.text).filter((node) => typeof node !== 'string');

      const sources = [];
      if (action.search_settings.use_table) {
        sources.push('Table');
      }
      if (action.search_settings.use_web) {
        sources.push('Web');
      }
      const details: Record<string, string> = {
        'Output type': action.answer_type || 'Text'
      };
      if (sources.length) {
        details.Search = sources.join(', ');
      }
      if (mentions.length) {
        details['Use context'] = `${mentions.length}`;
      }
      return details;
    }
    return {};
  }, [action]);

  return (
    <Stack>
      <MLIconButton
        onClick={(event) => setInfoMenuAnchorEl(event.currentTarget)}
        tooltipText="View prompt details">
        <InfoIcon />
      </MLIconButton>

      <Popover
        anchorEl={infoMenuAnchorEl}
        open={Boolean(infoMenuAnchorEl)}
        onClose={() => setInfoMenuAnchorEl(null)}
        slotProps={{ paper: { sx: { borderRadius: '12px' } } }}>
        <Stack sx={{ backgroundColor: 'colors.dark_bg' }} color="white" padding="16px" gap="16px">
          <Typography variant="text1_Medium">Prompt Details</Typography>
          <MLInlineStack gap="32px">
            <Stack gap="8px">
              {Object.keys(promptDetails).map((key) => (
                <Typography key={key}>{key}</Typography>
              ))}
            </Stack>
            <Stack gap="8px">
              {Object.keys(promptDetails).map((key) => (
                <Typography key={key}>{promptDetails[key]}</Typography>
              ))}
            </Stack>
          </MLInlineStack>
        </Stack>
      </Popover>
    </Stack>
  );
}

export default ActionPromptDetailsComponent;
