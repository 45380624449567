import { sendUserEvent } from 'actions/users/send-user-event-action';
import { TOAST_TYPES } from 'constants/toasts';
import { DOMAIN_REPORT_EVENTS } from 'constants/userEvents';
import { DomainReportSectionType } from 'models/domain-report/types';
import { updateDomainReport } from 'reducer/domain-reports-slice';
import { AppDispatch, getAppState } from 'types/store';

export function submitDomainReportSection(
  listId: number,
  domainReportId: number,
  sectionType: DomainReportSectionType,
  sectionsData: { title: string; data: any }[],
  addToast: (message: string, type: string) => void
) {
  return (dispatch: AppDispatch, getState: getAppState, apiClient: any) => {
    dispatch(
      sendUserEvent(DOMAIN_REPORT_EVENTS.DOMAIN_REPORT_SECTION_SUBMISSIONM, {
        listId,
        domainReportId,
        sectionType,
        sectionsData
      })
    );
    const payload = sectionsData.map((sectionData) => {
      return {
        type: sectionType,
        title: sectionData.title,
        data: sectionData.data
      };
    });
    apiClient.explore
      .submitExploreListDomainReportSection(listId, domainReportId, payload)
      .then((res: any) => {
        if (res.ok && res.data) {
          const data = { ...res.data };
          dispatch(updateDomainReport({ listId, lastDomainReportId: domainReportId, data }));
          if (data.sections.length > 1) {
            addToast('Sections Created Successfully', TOAST_TYPES.SUCCESS);
          } else {
            addToast('Section Created Successfully', TOAST_TYPES.SUCCESS);
          }
        }
      });
  };
}
