import { OutlinedInput, Stack, Typography } from '@mui/material';
import { useAppUtils } from 'AppUtilsProvider';
import { addSavedQueryAction } from 'actions/saved_queries/add-saved-query-action';
import { updateSavedQueryAction } from 'actions/saved_queries/update-saved-query-action';
import MLModal from 'design-system/MLModal';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SAVED_QUERY_ACTION_TYPES } from 'utils/platter-utils';

function ForgotSaveWorkflowDialog({ workflowId, plan, open, createNew, planName, onClose }) {
  const dispatch = useDispatch();
  const { addToast } = useAppUtils();

  const [workflowName, setWorkflowName] = useState('');

  useEffect(() => {
    if (planName) {
      setWorkflowName(planName);
    }
  }, [planName]);

  const handleSaveChanges = () => {
    if (createNew) {
      dispatch(
        addSavedQueryAction({
          name: workflowName,
          parameters: plan,
          query_type: SAVED_QUERY_ACTION_TYPES.WORKFLOW,
          description: null,
          addToast
        })
      );
    } else {
      dispatch(updateSavedQueryAction(workflowId, plan, addToast));
    }
  };

  const closeDialog = (_event, reason) => {
    if (reason !== 'backdropClick') {
      onClose(true);
    }
  };

  const onGoBack = () => {
    onClose(false);
  };

  const onSaveAndExit = () => {
    handleSaveChanges();
    onClose(false);
  };
  return (
    <MLModal
      open={open}
      onClose={closeDialog}
      title="There are unsaved changes"
      primaryButtonProps={{
        label: 'Save Changes',
        onClick: onSaveAndExit
      }}
      secondaryButtonProps={{
        label: 'Exit without saving',
        onClick: onGoBack
      }}>
      <Stack py="24px" gap="24px">
        <Typography variant="paragraph">
          Save your changes before exiting the workflow to avoid losing them.
        </Typography>
        <OutlinedInput
          size="small"
          value={workflowName}
          onChange={(event) => setWorkflowName(event.target.value)}
          placeholder="Write the workflow name here..."
          autoFocus
          sx={{
            width: '100%',
            height: '32px'
          }}
        />
      </Stack>
    </MLModal>
  );
}

ForgotSaveWorkflowDialog.propTypes = {
  workflowId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  plan: PropTypes.object,
  open: PropTypes.bool,
  createNew: PropTypes.bool,
  planName: PropTypes.string,
  onClose: PropTypes.func
};

export default ForgotSaveWorkflowDialog;
