import { Stack, StackProps, SxProps, Theme } from '@mui/material';
import React from 'react';

interface MLShimmeringStackProps extends Omit<StackProps, 'sx'> {
  sx?: SxProps<Theme>;
  children: React.ReactNode;
}

export default function MLShimmeringStack({ children, sx, ...extraProps }: MLShimmeringStackProps) {
  return (
    <Stack
      spacing={2}
      sx={{
        position: 'relative',
        overflow: 'hidden',
        '::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: '-100%',
          width: '200%',
          height: '100%',
          background:
            'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.7) 50%, rgba(255,255,255,0) 100%)',
          animation: 'shimmer 2s infinite ease-in-out',
          zIndex: 1,
          pointerEvents: 'none'
        },
        opacity: 0.8,
        ...sx
      }}
      {...extraProps}>
      {children}
    </Stack>
  );
}
