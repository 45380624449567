import isEqual from 'lodash/isEqual';
import React from 'react';

import RenderCustomAnalyticsOutput from './RenderCustomAnalyticsOutput';

type Props = {
  value: any;
  listId: number;
  companyListMetaId: number;
  companyMetaId: number;
  columnId: string;
  columnName: string;
  columnInfo: any;
  companyName: string;
  columnType: string;
  companyLogo: string;
  extraParams?: any;
};

const MemoizedRenderCustomAnalyticsOutput = React.memo(RenderCustomAnalyticsOutput, isEqual);

// const NULL_VALUE = null;
export default function RenderCustomAnalyticsCell({ value }: { value: Props }) {
  // the code in comments is currently overkill
  // if we remove company_extra_data from company meta merged rows, it will be relevant

  // const data = useSelector((state) =>
  //   getCompanyMetasExtraDataByListIdAndMetaId(state, value?.listId, value?.companyMetaId)
  // );
  // const customColumnData = useSelector((state) =>
  //   getCompanyMetaCustomColumnValueByListIdAndMetaIdAndColumnId(
  //     state,
  //     value?.listId,
  //     value?.companyMetaId,
  //     value?.columnId
  //   )
  // );

  // const finalValue = useMemo(() => {
  //   if (!value || !data) {
  //     return NULL_VALUE;
  //   }
  //   let caValue = getValueFromExtraData({ company_extra_data: data }, value.columnId);
  //   if (!caValue && caValue !== false) {
  //     caValue = customColumnData;
  //     if (!caValue && caValue !== false) {
  //       caValue = '';
  //     }
  //   }
  //   return { ...value, value: caValue };
  // }, [data, value, customColumnData]);

  //
  return <MemoizedRenderCustomAnalyticsOutput value={value} />;
}
