import { datadogRum } from '@datadog/browser-rum';
import { DatadogRumAction } from 'constants/datadog-rum';
import AssistantPromptConfig, {
  AssistantPromptConfigJSON
} from 'models/assistant/AssistantPromptConfig';
import { MessageRole } from 'models/assistant/types';
import { resetPromptConfig, updateAssistantUtility, updateThread } from 'reducer/assistant-slice';
import { getLatestListThread, getPromptConfig } from 'selectors/assistant';
import { AppDispatch } from 'types/store';

import { ExtraParams, submitAssistantHelperAction } from './submit-assistant-helper-action';

interface SubmitAssistantMessageActionParams {
  listId: number | null;
  newPromptConfig?: AssistantPromptConfigJSON | null;
  extraParams?: ExtraParams;
}

export function submitAssistantMessageAction({
  newPromptConfig,
  extraParams,
  listId
}: SubmitAssistantMessageActionParams) {
  return (dispatch: AppDispatch, getState: any) => {
    // We must either be submitting
    const state = getState();
    const promptConfig = new AssistantPromptConfig(newPromptConfig || getPromptConfig(state));
    const promptConfigValidity = promptConfig.checkSubmissionValidity();
    if (!promptConfigValidity.isValid) {
      return;
    }
    const thread = getLatestListThread(state, listId);
    const { messages } = thread;
    // Update the thread with the new message for state purposes
    const newMessage = promptConfig.toMessage();
    const updatedThread = { ...thread, messages: [...messages, newMessage] };
    // Send DD RUM action
    if (newMessage.role === MessageRole.USER && newMessage.content.action_type) {
      datadogRum.addAction(DatadogRumAction.UNIFY_DIRECT_ACTION, {
        listId,
        action: newMessage.content
      });
    } else {
      datadogRum.addAction(DatadogRumAction.UNIFY_SEND_MESSAGE, {
        listId,
        messageContent: newMessage.content
      });
    }
    dispatch(updateThread({ thread: updatedThread }));
    // Remove dry messages before submitting (like the "All set!" message)
    const threadWithoutDry = {
      ...updatedThread,
      messages: updatedThread.messages.filter((msg) => !msg.is_dry)
    };
    dispatch(submitAssistantHelperAction(thread.id, threadWithoutDry, extraParams));
    dispatch(resetPromptConfig());
    dispatch(updateAssistantUtility({ scrollToEnd: true }));
  };
}
