import { Stack } from '@mui/material';
import React from 'react';

function MathlabsLogoSmall() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_3462_56075"
        // style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="-1"
        width="16"
        height="17">
        <path d="M16.0001 -0.0078125H0V15.8459H16.0001V-0.0078125Z" fill="white" />
      </mask>
      <g mask="url(#mask0_3462_56075)">
        <path
          d="M12.4352 -0.00732422C12.1577 -0.00732422 11.8926 0.108045 11.7034 0.311177L8 4.28804L16 12.8738V0.492676C16 0.216533 15.7762 -0.00732422 15.5 -0.00732422H12.4352Z"
          fill="#C5CDFF"
        />
        <path
          d="M8.00004 4.28804L4.29662 0.311177C4.10745 0.108045 3.84237 -0.00732422 3.5648 -0.00732422H0.5C0.223858 -0.00732422 0 0.216533 0 0.492676V15.8464V12.8738L8.00004 4.28804Z"
          fill="url(#paint0_linear_3462_56075)"
        />
        <path
          d="M0 12.8744V14.8469C0 15.3992 0.447715 15.8469 1 15.8469H15.0001C15.5524 15.8469 16.0001 15.3992 16.0001 14.8469V12.8744L8.00004 4.28857L0 12.8744Z"
          fill="url(#paint1_linear_3462_56075)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_3462_56075"
          x1="-2.04001"
          y1="2.37569"
          x2="4.60325"
          y2="10.9959"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#584CA7" />
          <stop offset="1" stopColor="#584CA7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3462_56075"
          x1="-0.730003"
          y1="13.5382"
          x2="24.8368"
          y2="6.44247"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#584CA7" />
          <stop offset="1" stopColor="#D6DCFF" />
        </linearGradient>
      </defs>
    </svg>
  );
}

function MathlabsAvatar() {
  return (
    <Stack
      sx={{
        minWidth: 32,
        height: 32,
        color: 'white',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 16,
        background: 'linear-gradient(64.36deg, #1B0F49 16.85%, #6752BC 81.8%)'
      }}>
      <MathlabsLogoSmall />
    </Stack>
  );
}

export default MathlabsAvatar;
