import React from 'react';

export default function EraseIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.5618 4.97595C13.3573 4.21496 14.6183 4.24003 15.383 5.03204L18.942 8.71811C19.697 9.50015 19.6892 10.7421 18.9243 11.5146L11.5853 18.9259C10.7879 19.7311 9.48135 19.7136 8.70581 18.8873L5.00581 14.9449C4.25498 14.1449 4.28884 12.8893 5.08169 12.1309L12.5618 4.97595Z"
        stroke="currentColor"
      />
      <path d="M6.80859 10.9619L13.0392 17.1925" stroke="currentColor" strokeLinecap="round" />
      <path d="M10.5 19.5H17" stroke="currentColor" strokeLinecap="round" />
    </svg>
  );
}
