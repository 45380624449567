import { BaseTextFieldProps, TextField, useTheme } from '@mui/material';
import React, { ChangeEvent } from 'react';
import { parseNewLines } from 'utils/string-utils';

interface MLMarkdownEditorProps extends BaseTextFieldProps {
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export default function MLMarkdownEditor({ value, onChange, ...props }: MLMarkdownEditorProps) {
  const formatValue = (value: string | undefined): string => {
    return value ? parseNewLines(value) : '';
  };

  const internalOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const newValue = value.replace(/\n/gu, '\\n');
    onChange({ ...event, target: { ...event.target, value: newValue } });
  };
  const theme = useTheme();

  return (
    <TextField
      multiline
      variant="outlined"
      value={formatValue(value)}
      size="small"
      slotProps={{
        input: {
          sx: {
            ...theme.typography.text1_Normal
          }
        }
      }}
      onChange={internalOnChange}
      {...props}
    />
  );
}
