import { Stack, Typography } from '@mui/material';
import { MessageRole } from 'models/assistant/types';
import React from 'react';

import AssistantRenderText from './AssistantRenderText';

interface AssistantRenderMessageProps {
  role: MessageRole;
  text: string;
  hasMinWidth: boolean;
}

const messageRoleToSx = (role: MessageRole) => {
  switch (role) {
    case MessageRole.USER:
      return {
        paddingX: '16px',
        paddingY: '12px',
        borderRadius: '8px',
        backgroundColor: 'colors.selected'
      };
    case MessageRole.ASSISTANT:
      return {};
    default:
      return {
        border: '1px solid',
        borderColor: 'colors.ui_border',
        borderRadius: '8px'
      };
  }
};

export default function AssistantRenderMessage({
  role,
  text,
  hasMinWidth
}: AssistantRenderMessageProps) {
  return (
    <Stack
      sx={{
        gap: '8px',
        width: 'fit-content',
        padding: '12px',
        minWidth: hasMinWidth ? '300px' : null,
        ...messageRoleToSx(role)
      }}>
      <Typography>
        <AssistantRenderText text={text} />
      </Typography>
    </Stack>
  );
}
