import { updateCustomAnalyticsExtraDataForCompaniesInListAction } from 'actions/company_extra_data/update-extra-data-for-companies-in-list-action';
import { highlightColumnAction } from 'actions/explore-table-ui/highlight-column-action';
import { addExploreCustomColumnAction } from 'actions/explore/add-discovery-custom-column-action';
import { generateColumnNameAction } from 'actions/llm_queries/generate-name-for-column-action';
import {
  CHAT_GPT_MODEL_VERSION,
  CUSTOM_ANALYTICS_ACTIONS,
  REASONING_MODEL_VERSION
} from 'constants/custom-analytics';
import {
  addPostQueryToThread,
  getThreadKey,
  updatePostIdOfDataType
} from 'reducer/custom-analytics';
import { getCompanyMetasByListId } from 'selectors/explore';

export function addEmptyCustomAnalyticsColumnAction({
  listId,
  columnName,
  question,
  companyMetaIds,
  onCreate,
  answerType,
  columns,
  folderId,
  usePlanner,
  temperature,
  doTagging,
  supportingResourceId,
  useTable,
  useReasoningModel
}) {
  return (dispatch, getState) => {
    const threadKey = getThreadKey(listId);
    const state = getState();
    const companyMetas = getCompanyMetasByListId(state, listId);
    const info = {
      question,
      company_list_id: listId,
      folder_id: folderId,
      model: useReasoningModel ? REASONING_MODEL_VERSION : CHAT_GPT_MODEL_VERSION,
      answer_type: answerType || 'text',
      total_num_companies: companyMetaIds?.length || companyMetas?.length,
      company_meta_ids: companyMetaIds,
      is_table_question: useTable,
      for_each_company: true
    };
    if (usePlanner) {
      info.action = CUSTOM_ANALYTICS_ACTIONS.GENERATE_PLAN_AND_EXECUTE;
    }
    if (temperature) {
      info.temperature = temperature / 10;
    }
    if (answerType) {
      info.answer_type = answerType;
    }
    if (supportingResourceId) {
      info.supporting_resource_id = supportingResourceId;
    }
    if (columns) {
      info.columns = columns;
    }
    if (doTagging) {
      info.action = CUSTOM_ANALYTICS_ACTIONS.CREATE_CLUSTERS_AND_TAG;
    }
    const postId = new Date().getTime();
    let messages = [];
    if (companyMetaIds) {
      messages = companyMetaIds.map((company_meta_id) => ({
        company_meta_id,
        loading: true
      }));
    } else {
      messages = companyMetas?.map((company) => ({
        company_meta_id: company.company_meta_id,
        loading: true
      }));
    }
    dispatch(addPostQueryToThread({ threadKey, postId, query: question, body: info, messages }));
    dispatch(
      addExploreCustomColumnAction(
        listId,
        columnName,
        info,
        'custom_analytics',
        answerType,
        ({ id: newColumnId }) => {
          dispatch(updatePostIdOfDataType({ postId, dataType: newColumnId }));
          dispatch(
            updateCustomAnalyticsExtraDataForCompaniesInListAction(
              listId,
              newColumnId,
              false,
              companyMetaIds,
              postId,
              doTagging
            )
          );
          dispatch(highlightColumnAction(listId, newColumnId, true));
          const cleanQuestion = question.split('}}').pop().trim();
          dispatch(generateColumnNameAction(listId, newColumnId, cleanQuestion));
          onCreate?.(newColumnId);
        }
      )
    );
  };
}
