import { updateRecentlyFinishedRun } from 'actions/update-recently-finished-run';
import { EXPLORE_PREFIX } from 'constants/app-routes';
import { CHAT_GPT_MODEL_VERSION } from 'constants/custom-analytics';
import {
  WORKFLOW_SELECTED_TYPE,
  WORKFLOW_STATUSES,
  WORK_FLOW_STEP_TYPES
} from 'constants/workflows';
import { dispatch as oldDispatch } from 'hooks/AppStateProvider';
import { getPusherClient } from 'hooks/PusherProvider';
import cloneDeep from 'lodash/cloneDeep';
import {
  addPostAnswerToThread,
  addPostQueryToThread,
  addPostRecordIdToThread,
  addPostStatusToThread,
  getThreadKey,
  updatePlan,
  updatePostIsHidden,
  updateThreadProccessingMode
} from 'reducer/custom-analytics';
import {
  updateSelectedWorkflowIdByListId,
  updateWorkflowInHistory,
  updateWorkflowStatusByIdAndListId
} from 'reducer/workflows-slice';
import { getPostSavedQueryIdByThreadIdAndPostId } from 'selectors/custom-analytics';
import { getExploreSelectedCompanyMetaIds } from 'selectors/explore-table-ui';
import { getRunProgressChannelName } from 'utils/pusher-utils';
import { isStringIsNumber } from 'utils/string-utils';
import { sanitizeWorkflowPlan } from 'utils/workflows-utils';

export function pusherBindToCARecordId({ listId, recordId, threadKey, postId, navigate }) {
  return (dispatch, _getState, apiClient) => {
    const pusher = getPusherClient();
    const channelName = getRunProgressChannelName(recordId);
    const channel = pusher.subscribe(channelName);
    channel.bind('progress', (data) => {
      if (data.progress === 1) {
        pusher.unsubscribe(channelName);
        apiClient.runs.getRunStatuses(recordId).then((res) => {
          if (res.ok) {
            const { statuses } = res.data;
            const isFailed = statuses?.workflow === 'FAILED';
            dispatch(
              updateWorkflowStatusByIdAndListId({
                listId,
                workflowId: postId,
                status: isFailed ? WORKFLOW_STATUSES.RUN_FAILED : WORKFLOW_STATUSES.RUN_FINISHED
              })
            );
            oldDispatch(updateRecentlyFinishedRun(postId, 'workflows'));

            dispatch(
              addPostStatusToThread({
                threadKey,
                postId,
                statusMessage: isFailed ? 'Workflow failed' : 'Workflow completed',
                currentStepId: 'end'
              })
            );
          }
        });
        apiClient.runs.fetchRun(recordId).then((res) => {
          if (res.ok && res.data) {
            dispatch(updateWorkflowInHistory({ record: res.data }));
            if (
              window.location.pathname.startsWith(`${EXPLORE_PREFIX}/${listId}`) &&
              res.data?.parameters?.view_id &&
              navigate
            ) {
              navigate(`${EXPLORE_PREFIX}/${listId}/views/${res.data.parameters.view_id}`);
            }
          }
        });
        dispatch(
          addPostStatusToThread({
            threadKey,
            postId,
            status: `${Math.round(data.progress * 100)}%`,
            statusMessage: data.message,
            currentStepId: 'end'
          })
        );
      }
    });
  };
}

export function launchWorkflowAction(
  listId,
  plan,
  name,
  selectedWorkflowId,
  isRelaunch = false,
  fromStepId = null,
  navigate = null
) {
  return (dispatch, getState, apiClient) => {
    const state = getState();
    const query = plan.question;
    const startStepId = plan.steps[0].step_id;
    const threadKey = getThreadKey(listId);

    const workflowSavedQueryId = getPostSavedQueryIdByThreadIdAndPostId(
      state,
      threadKey,
      selectedWorkflowId
    );

    const postId = new Date().getTime();
    const selectedCompanyMetaIds = getExploreSelectedCompanyMetaIds(state, listId);
    const clonePlan = sanitizeWorkflowPlan(plan, name);
    if (!isRelaunch) {
      clonePlan.id = workflowSavedQueryId?.toString() || postId.toString();
    }
    const body = {
      question: query,
      company_list_id: listId,
      post_id: postId,
      plan: clonePlan,
      company_meta_ids: selectedCompanyMetaIds,
      model: CHAT_GPT_MODEL_VERSION,
      saved_query_id: workflowSavedQueryId,
      originalWorkflowId: selectedWorkflowId
    };
    if (isRelaunch) {
      body.start_workflow_at_step_id = fromStepId || clonePlan.steps?.[0]?.step_id;
    }

    dispatch(
      addPostQueryToThread({
        threadKey,
        postId,
        query,
        body,
        clonePlan,
        currentStepId: startStepId,
        name
      })
    );
    dispatch(updatePostIsHidden({ threadKey, postId: selectedWorkflowId, hidePost: true }));

    dispatch(
      updateSelectedWorkflowIdByListId({
        listId,
        selectedWorkflowId: postId,
        selectedType: WORKFLOW_SELECTED_TYPE.DRAFT
      })
    );
    dispatch(
      updateWorkflowStatusByIdAndListId({
        listId,
        workflowId: postId,
        status: WORKFLOW_STATUSES.LAUNCHING
      })
    );

    apiClient.customAnalytics
      .queryCustomAnalyticsAssistantAsync(body)
      .then((res) => {
        if (res.ok) {
          const customAnalyticsRecordId = res.data.record_id;
          dispatch(addPostRecordIdToThread({ threadKey, postId, customAnalyticsRecordId }));
          dispatch(
            pusherBindToCARecordId({
              listId,
              recordId: customAnalyticsRecordId,
              threadKey,
              postId,
              navigate
            })
          );
          dispatch(
            updateWorkflowStatusByIdAndListId({
              listId,
              workflowId: postId,
              status: WORKFLOW_STATUSES.LAUNCHED,
              customAnalyticsRecordId
            })
          );
        } else {
          dispatch(
            addPostAnswerToThread({
              threadKey,
              postId,
              message: { answer: 'Something went wrong, please try again' },
              currentStepId: 'end'
            })
          );
          let info = null;
          if (res.status === 422) {
            const newPlan = cloneDeep(clonePlan);
            const invalidResourcesNames = new Set();
            res?.data?.invalid_resources?.forEach((invalidResource) => {
              const stepIndex = newPlan.steps?.findIndex(
                (planStep) => planStep.step_id === invalidResource.step_id
              );
              const step = stepIndex >= 0 ? newPlan.steps?.[stepIndex] : null;
              const action = step?.function_use?.action;
              if (action) {
                const stepLabel = WORK_FLOW_STEP_TYPES[action].label;
                let partObj = step.function_use?.action_variables;
                let queryIndex = null;
                const resourcePath = invalidResource.resource_location?.split('.');
                resourcePath?.forEach((part, index) => {
                  const prevPartObj = partObj;
                  partObj = partObj[part];
                  if (isStringIsNumber(part)) {
                    queryIndex = part;
                  }
                  if (partObj.attribute_name || partObj.condition || partObj.analysis_name) {
                    const showQueryIndex =
                      Array.isArray(prevPartObj) &&
                      prevPartObj.length > 1 &&
                      queryIndex?.length > 0;
                    invalidResourcesNames.add(
                      `${stepIndex + 1}${showQueryIndex ? '.' : ''}${
                        showQueryIndex ? parseInt(queryIndex, 10) + 1 : ''
                      } ${stepLabel}${partObj.attribute_name || partObj.analysis_name ? ': ' : ''}${
                        partObj.attribute_name || partObj.analysis_name || ''
                      }`
                    );
                  }
                  if (index >= resourcePath.length - 1 && Array.isArray(partObj)) {
                    prevPartObj[part] = partObj.filter(
                      (resourceId) => resourceId.toString() !== invalidResource.resource_id
                    );
                  }
                });
              }
            });
            if (invalidResourcesNames.size > 0) {
              const invalidSteps = [...invalidResourcesNames].join(',');
              info = `The workflow had invalid resources in the following steps[${invalidSteps}], we removed those resources`;
              dispatch(updatePlan({ threadKey, postId, newPlan }));
            }
          }
          dispatch(
            updateWorkflowStatusByIdAndListId({
              listId,
              workflowId: postId,
              status: WORKFLOW_STATUSES.LAUNCH_FAILED,
              info
            })
          );
        }
        dispatch(updateThreadProccessingMode({ threadKey, isProccessing: false }));
      })
      .catch(() => {
        dispatch(
          addPostAnswerToThread({
            threadKey,
            postId,
            message: { answer: 'Something went wrong, please try again' },
            currentStepId: 'end'
          })
        );
        dispatch(
          updateWorkflowStatusByIdAndListId({
            listId,
            workflowId: postId,
            status: WORKFLOW_STATUSES.LAUNCH_FAILED
          })
        );
        dispatch(updateThreadProccessingMode({ threadKey, isProccessing: false }));
      });
  };
}
