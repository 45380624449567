import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  getLatestDRCompanyMetaIdsByListId,
  getLatestDRFilteredOutCompanyMetaIdsByListId,
  getLatestDRFiltersByListId
} from 'selectors/domain-reports';
import { PPTX_CLASS_NAMES } from 'utils/react-to-powerpoint-utils';

import DomainReportBox from '../DomainReportBox';
import { useDomainReportContext } from '../DomainReportContext';
import DomainReportCompaniesBreakdown from './DomainReportCompaniesBreakdown';
import DomainReportDiscoveryBreakdown from './DomainReportDiscoveryBreakdown';
import DomainReportFiltersBreakdown from './DomainReportFiltersBreakdown';
import DomainReportProcessBar from './DomainReportProcessBar';

function DomainReportProcess({ section }) {
  const { title } = section.props;
  const { listId } = useDomainReportContext();
  const filteredOutCompenyMetaIds = useSelector((state) =>
    getLatestDRFilteredOutCompanyMetaIdsByListId(state, listId)
  );
  // from the domain report - not updated
  const companyMetaIds = useSelector((state) => getLatestDRCompanyMetaIdsByListId(state, listId));
  const filtered = filteredOutCompenyMetaIds?.length || 0;
  const final = companyMetaIds?.length;
  const total = final + filtered;
  const filters = useSelector((state) => getLatestDRFiltersByListId(state, listId));
  const numFilters = filters ? Object.keys(filters).length : 0;
  const pptxPaddingY = numFilters * 75;
  return (
    <DomainReportBox title={title}>
      <Stack gap="4px">
        <DomainReportProcessBar
          title="Total discovered"
          number={total}
          total={total}
          className={PPTX_CLASS_NAMES.slideQuery}>
          <DomainReportDiscoveryBreakdown listId={listId} />
        </DomainReportProcessBar>
        <Stack gap="4px" className={PPTX_CLASS_NAMES.slideQuery}>
          <DomainReportProcessBar title="Removed by filtering" number={total - final} total={total}>
            {numFilters > 0 && <DomainReportFiltersBreakdown listId={listId} />}
          </DomainReportProcessBar>
          <DomainReportProcessBar
            title="Final list"
            number={final}
            total={total}
            className={`${PPTX_CLASS_NAMES.paddingY}-${pptxPaddingY}`}>
            <DomainReportCompaniesBreakdown listId={listId} />
          </DomainReportProcessBar>
        </Stack>
      </Stack>
    </DomainReportBox>
  );
}

DomainReportProcess.propTypes = {
  section: PropTypes.object
};

export default DomainReportProcess;
