import React from 'react';

export default function InfoIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12ZM12 11.5C12.2761 11.5 12.5 11.7239 12.5 12V15.5C12.5 15.7761 12.2761 16 12 16C11.7239 16 11.5 15.7761 11.5 15.5V12C11.5 11.7239 11.7239 11.5 12 11.5ZM11.9999 8.5C11.4477 8.5 10.9999 8.94772 10.9999 9.5C10.9999 10.0523 11.4477 10.5 11.9999 10.5H12.0076C12.5599 10.5 13.0076 10.0523 13.0076 9.5C13.0076 8.94772 12.5599 8.5 12.0076 8.5H11.9999Z"
        fill="currentColor"
      />
    </svg>
  );
}
