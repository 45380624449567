import { Stack, Typography } from '@mui/material';
import React from 'react';
import { getTitleFromFinanceType } from 'utils/finance-utils';

import { FinanceDataItems } from '../RenderProfitData';

type Props = {
  financeDataItems: Record<string, FinanceDataItems>;
};

export default function ProfitCellTooltip({ financeDataItems }: Props) {
  return (
    <Stack gap="4px" padding="4px">
      {Object.keys(financeDataItems).map((financeType) => (
        <Typography key={financeType}>
          {getTitleFromFinanceType(financeType)} - ${financeDataItems[financeType].finalValue}M
        </Typography>
      ))}{' '}
    </Stack>
  );
}
