import { renameSavedQueryAction } from 'actions/saved_queries/rename-saved-query-action';
import { getThreadKey, renamePost } from 'reducer/custom-analytics';

export function renameWorkflowAction(listId, workflowId, newName, isLibraryRename) {
  return (dispatch) => {
    if (isLibraryRename) {
      dispatch(renameSavedQueryAction(workflowId, newName));
    }
    const threadKey = getThreadKey(listId);
    dispatch(renamePost({ threadKey, postId: workflowId, newName }));
  };
}
