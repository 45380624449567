import React from 'react';

export default function ClustersIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5 15C17.433 15 19 13.433 19 11.5C19 9.567 17.433 8 15.5 8C13.567 8 12 9.567 12 11.5C12 13.433 13.567 15 15.5 15ZM8.5 19C9.32843 19 10 18.3284 10 17.5C10 16.6716 9.32843 16 8.5 16C7.67157 16 7 16.6716 7 17.5C7 18.3284 7.67157 19 8.5 19ZM7 9C8.10457 9 9 8.10457 9 7C9 5.89543 8.10457 5 7 5C5.89543 5 5 5.89543 5 7C5 8.10457 5.89543 9 7 9ZM7 10C8.65685 10 10 8.65685 10 7C10 5.34315 8.65685 4 7 4C5.34315 4 4 5.34315 4 7C4 8.65685 5.34315 10 7 10ZM20 11.5C20 13.9853 17.9853 16 15.5 16C13.0147 16 11 13.9853 11 11.5C11 9.01472 13.0147 7 15.5 7C17.9853 7 20 9.01472 20 11.5ZM11 17.5C11 18.8807 9.88071 20 8.5 20C7.11929 20 6 18.8807 6 17.5C6 16.1193 7.11929 15 8.5 15C9.88071 15 11 16.1193 11 17.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
