import { ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import ExploreQueriesHistoryDialog from 'components/discovery/ExploreQueriesHistory';
import WorkflowsCenterDialog from 'components/work-flow/WorkflowsCenterDialog';
import { RESEARCH_TYPES } from 'constants/researches';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import DependsOnIcon from 'icons/DependsOnIcon';
import HistoryIcon from 'icons/HistoryIcon';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { getUserFeatureFlags } from 'selectors/user';

function TopbarResourceActionsMenu({ resourceId, resourceType, menuAnchorEl, onClose }) {
  const [openWorkflowCenter, setOpenWorkflowCenter] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);

  const featureFlags = useOldStateSelector(getUserFeatureFlags);

  if (resourceType !== RESEARCH_TYPES.EXPLORE.id) return null;

  const handleOpenWorkflowCenter = () => {
    setOpenWorkflowCenter(true);
    onClose();
  };

  const handleOpenHistory = () => {
    setOpenHistory(true);
    onClose();
  };

  return (
    <React.Fragment>
      <Menu
        id="resource-actions-menu"
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={onClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}>
        {featureFlags.includes('work_flow') && (
          <MenuItem
            onClick={handleOpenWorkflowCenter}
            sx={{ '&:hover': { backgroundColor: 'background.bg75' } }}>
            <ListItemIcon sx={{ color: 'secondary.secondary5_100' }}>
              <DependsOnIcon useDefaultColor={false} />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="text1_Normal" color="primary.primary150">
                Workflow
              </Typography>
            </ListItemText>
          </MenuItem>
        )}
        <MenuItem
          onClick={handleOpenHistory}
          sx={{ '&:hover': { backgroundColor: 'background.bg75' } }}>
          <ListItemIcon sx={{ color: 'secondary.secondary5_100' }}>
            <HistoryIcon useDefaultColor={false} />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="text1_Normal" color="primary.primary150">
              Query history
            </Typography>
          </ListItemText>
        </MenuItem>
      </Menu>
      <WorkflowsCenterDialog
        listId={resourceId}
        open={openWorkflowCenter}
        onClose={() => setOpenWorkflowCenter(false)}
      />
      {openHistory && (
        <ExploreQueriesHistoryDialog
          listId={resourceId}
          open={openHistory}
          onClose={() => setOpenHistory(false)}
        />
      )}
    </React.Fragment>
  );
}

TopbarResourceActionsMenu.propTypes = {
  resourceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  resourceType: PropTypes.string,
  menuAnchorEl: PropTypes.object,
  onClose: PropTypes.func
};

export default TopbarResourceActionsMenu;
