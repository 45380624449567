import { SEARCH_SETTING_OPTIONS, SearchSettingsOptions } from 'constants/assistant';
import MLIconMenuEnumButton from 'design-system/MLIconMenuEnumButton';
import { SearchSettings } from 'models/assistant/types';
import React from 'react';
import { updatePromptConfigSearchSettings } from 'reducer/assistant-slice';
import { getPromptConfigSearchSettings } from 'selectors/assistant';
import { useAppDispatch, useAppSelector } from 'types/store';

export function searchSettingsToSearchSettingsOption(
  searchSettings: SearchSettings
): SearchSettingsOptions {
  if (searchSettings.use_table && searchSettings.use_web) {
    return SearchSettingsOptions.WEB_AND_TABLE;
  }
  if (searchSettings.use_table) {
    return SearchSettingsOptions.TABLE_ONLY;
  }
  return SearchSettingsOptions.WEB_ONLY;
}

export const searchSettingsOptionToSearchSettings = Object.entries(SEARCH_SETTING_OPTIONS).reduce(
  (acc, [key, value]) => ({
    ...acc,
    [key]: value.search_settings
  }),
  {} as Record<SearchSettingsOptions, SearchSettings>
);

export default function AssistantWebTableButton() {
  const dispatch = useAppDispatch();
  const searchSettings = useAppSelector(getPromptConfigSearchSettings);
  const value = searchSettings ? searchSettingsToSearchSettingsOption(searchSettings) : null;
  const updateValue = (searchSettingsOption: SearchSettingsOptions) => {
    dispatch(
      updatePromptConfigSearchSettings({
        search_settings: searchSettingsOptionToSearchSettings[searchSettingsOption]
      })
    );
  };

  return (
    <MLIconMenuEnumButton
      title="Search settings"
      value={value}
      updateValue={updateValue}
      options={SEARCH_SETTING_OPTIONS}
    />
  );
}
