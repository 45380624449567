/* eslint-disable id-length*/

/* eslint-disable prefer-const*/
import { handleErrorAction } from 'actions/users/handle-error-action';
import { dispatch as oldDispatch } from 'hooks/AppStateProvider';
import { toPng } from 'html-to-image';
import PptxGenJS from 'pptxgenjs';

export const POWER_POINT_SLIDE_WIDTH = 10;
export const POWER_POINT_SLIDE_HEIGHT = 5.625;
const PIXELS_PER_INCH = 96;
const TEXT_ELEMENT_TAGS = ['H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'P', 'A', 'LI', 'SPAN', 'STRONG'];
const DIV_ELEMENT_TAG = 'DIV';
const LINK_ELEMENT_TAG = 'A';
const LIST_ITEM_ELEMENT_TAG = 'LI';
const IMAGE_ELEMENT_TAGS = ['IMG', 'svg'];
const ELEMENT_TAGS_TO_IGNORE = ['BUTTON'];
const DEFAULT_BORDER_COLOR = '0b1c38';
export const PPTX_CLASS_NAMES = {
  slideQuery: 'exportable-to-pptx',
  ignore: 'pptx-ignore',
  ignoreHidden: 'pptx-ignore-hidden',
  footer: 'pptx-footer',
  paddingY: 'pptx-padding-y',
  markdown: 'pptx-markdown'
};

const rgbToHex = (color) => {
  return color
    .replace('rgb(', '')
    .replace(')', '')
    .split(',')
    .map((x) => parseInt(x, 10).toString(16).padStart(2, '0'))
    .join('');
};

function getElementPowerPointPositions(rect, parentRect) {
  const x = (rect.left - parentRect.left) / PIXELS_PER_INCH;
  const y = (rect.top - parentRect.top) / PIXELS_PER_INCH;
  const w = rect.width / PIXELS_PER_INCH;
  const h = rect.height / PIXELS_PER_INCH;
  return { x, y, w, h };
}

function getTextAdditionalOptions(element) {
  if (element.tagName === LINK_ELEMENT_TAG) {
    return {
      hyperlink: {
        url: element.href
      }
    };
  } else if (element.tagName === LIST_ITEM_ELEMENT_TAG) {
    return {
      bullet: true,
      indentLevel: 0
    };
  }
}

function getTextStyling(element) {
  const computedStyle = window.getComputedStyle(element);
  let scale = 0.75;
  if (element.closest(`.${PPTX_CLASS_NAMES.footer}`)) {
    scale = 0.5;
  }
  const fontSize = parseFloat(computedStyle.fontSize) * scale;
  const bold = computedStyle.fontWeight >= 500;
  const color = rgbToHex(computedStyle.color);

  return { fontSize, bold, color, align: 'left', autoFit: true };
}

function extractTextData(element, defaultTextStyling = {}) {
  const textStyling =
    element.nodeType === Node.ELEMENT_NODE ? getTextStyling(element) : defaultTextStyling;
  const currentText = element.textContent;
  const additionalOptions = getTextAdditionalOptions(element);
  return { currentText, textStyling, additionalOptions };
}

function isTextElement(element) {
  return element.nodeType === Node.TEXT_NODE || TEXT_ELEMENT_TAGS.includes(element.tagName);
}

function addTextData(child, defaultTextStyling, textArray) {
  const { currentText, textStyling, additionalOptions } = extractTextData(
    child,
    defaultTextStyling
  );

  if (currentText) {
    textArray.push({
      text: currentText,
      options: { ...textStyling, ...additionalOptions }
    });
  }
}

const transformTextElementToPptx = (element, elementPosition, slide) => {
  const text = element.textContent;
  if (!text) return;
  const textArray = [];
  const defaultTextStyling = getTextStyling(element);
  const childNodesToTransform = Array.of(...element.childNodes);
  let index = 1;
  for (const child of childNodesToTransform) {
    if (isTextElement(child)) {
      addTextData(child, defaultTextStyling, textArray);
    } else {
      // if it's not a text element, try to parse the children right after this element
      childNodesToTransform.splice(index, 0, ...child.childNodes);
    }
    index++;
  }

  slide.addText(textArray, {
    x: elementPosition.x,
    y: elementPosition.y,
    // increase the width a bit to prevent narrow text boxes
    w: elementPosition.w + 0.2,
    h: elementPosition.h
  });
};

async function transformImageElementToPptx(element, elementPosition, slide) {
  try {
    if (element.tagName === 'IMG' && element.src) {
      slide.addImage({
        path: element.src,
        ...elementPosition
      });
    } else {
      const dataUrl = await toPng(element, {
        cacheBust: true,
        skipFonts: true
      });
      slide.addImage({
        data: dataUrl,
        ...elementPosition
      });
    }
  } catch (error) {
    oldDispatch(handleErrorAction(error, 'Failed to export image to PowerPoint'));
  }
}

const transformDividerElementToPptx = (element, elementPosition, slide, pptx) => {
  const vertical = element.classList?.contains('MuiDivider-vertical');

  // Get bounding box relative to parent
  const computedStyle = window.getComputedStyle(element);
  const color = rgbToHex(computedStyle.color);
  const length = Math.max(elementPosition.w, elementPosition.h);

  // Adjust width and height based on orientation
  const adjustedWidth = vertical ? 0 : length;
  const adjustedHeight = vertical ? length : 0;

  slide.addShape(pptx.shapes.LINE, {
    x: elementPosition.x,
    y: elementPosition.y,
    w: adjustedWidth,
    h: adjustedHeight,
    line: { color: color, width: 0.5 }
  });
};
const transformDivElementToPptx = (element, elementPosition, slide, pptx) => {
  const computedStyle = window.getComputedStyle(element);
  const computedBackgroundColor = rgbToHex(computedStyle.backgroundColor);
  const computedBorderColor = rgbToHex(computedStyle.borderColor);

  if (!computedBackgroundColor.includes('NaN')) {
    const borderColor =
      DEFAULT_BORDER_COLOR === computedBorderColor ? computedBackgroundColor : computedBorderColor;
    slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
      ...elementPosition,
      fill: { color: computedBackgroundColor },
      line: { width: computedStyle.borderWidth, color: borderColor }
    });
  }
};

const isDividerElement = (element) => {
  return element.classList && element.classList.contains('MuiDivider-root');
};

const isDivElement = (element) => {
  return element.tagName === DIV_ELEMENT_TAG;
};

function shouldIgnoreCollapse(element) {
  return (
    element.classList?.contains('MuiCollapse-hidden') &&
    !element.classList?.contains(PPTX_CLASS_NAMES.ignoreHidden)
  );
}

function shouldIgnoreElement(element) {
  return (
    ELEMENT_TAGS_TO_IGNORE.includes(element.tagName) ||
    element.classList?.contains(PPTX_CLASS_NAMES.ignore) ||
    shouldIgnoreCollapse(element)
  );
}
/*eslint-disable*/
function addPaddingToElementPosition(element, parentRect) {
  try {
    const regex = /pptx-padding-y-(?<paddingValue>\d+)/u;
    if (typeof element.className !== 'string') return;
    const match = element.className?.match(regex);

    if (match) {
      const paddingValue = parseInt(match[1], 10);
      parentRect.y -= paddingValue;
    }
  } catch (error) {
    console.error('Error extracting padding value:', error);
  }
}

export const transformElementToPptx = async (element, slide, parentRect, pptx) => {
  if (shouldIgnoreElement(element)) {
    return;
  }
  const elementPosition = getElementPowerPointPositions(
    element.getBoundingClientRect(),
    parentRect
  );
  addPaddingToElementPosition(element, parentRect);
  if (element.classList?.contains(PPTX_CLASS_NAMES.markdown)) {
    transformElementToPptx(element.childNodes[0], slide, parentRect, pptx);
  } else if (isTextElement(element)) {
    transformTextElementToPptx(element, elementPosition, slide);
  } else if (IMAGE_ELEMENT_TAGS.includes(element.tagName)) {
    await transformImageElementToPptx(element, elementPosition, slide);
  } else if (isDividerElement(element)) {
    transformDividerElementToPptx(element, elementPosition, slide, pptx);
  } else {
    if (isDivElement(element)) {
      transformDivElementToPptx(element, elementPosition, slide, pptx);
    }
    for (const child of element.childNodes) {
      /* eslint-disable no-await-in-loop */
      await transformElementToPptx(child, slide, parentRect, pptx);
    }
  }
};

export async function generateQuickViewDialogPowerPoint(
  dialogElementRef,
  slideQuerySelector = PPTX_CLASS_NAMES.slideQuery
) {
  if (!dialogElementRef) return;
  const pptx = new PptxGenJS();

  const childNodes = dialogElementRef.querySelectorAll(`.${slideQuerySelector}`);

  let parentRect = dialogElementRef.getBoundingClientRect();

  // Merge first two elements into one slide
  const firstSlide = pptx.addSlide();
  // Temporary fix for scrolling issue and element alignment
  const newParentRect = new DOMRect(
    parentRect.left,
    parentRect.top - dialogElementRef.childNodes[1].scrollTop,
    parentRect.width,
    parentRect.height
  );
  await transformElementToPptx(childNodes[0], firstSlide, parentRect, pptx);
  await transformElementToPptx(childNodes[1], firstSlide, newParentRect, pptx);

  for (let index = 2; index < childNodes.length; index++) {
    const child = childNodes[index];
    parentRect = child.getBoundingClientRect();
    /* eslint-disable no-await-in-loop */
    await transformElementToPptx(child, pptx.addSlide(), parentRect, pptx);
  }
  return pptx;
}

export async function generateDomainReportPowerpoint(
  reportElementRef,
  slideQuerySelector = PPTX_CLASS_NAMES.slideQuery
) {
  if (!reportElementRef) return;
  const pptx = new PptxGenJS();

  const childNodes = reportElementRef.querySelectorAll(`.${slideQuerySelector}`);

  let parentRect = reportElementRef.getBoundingClientRect();

  for (let index = 0; index < childNodes.length; index++) {
    const child = childNodes[index];
    parentRect = child.getBoundingClientRect();
    /* eslint-disable no-await-in-loop */
    await transformElementToPptx(child, pptx.addSlide(), parentRect, pptx);
  }
  return pptx;
}
