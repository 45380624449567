import { Stack, Typography } from '@mui/material';
import { addParallelStepToPlanAction } from 'actions/workflows/add-parallel-step-to-plan-action';
import MLButton from 'design-system/MLButton';
import MLInlineStack from 'design-system/MLInlineStack';
import AddIcon from 'icons/AddIcon';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Handle, Position } from 'reactflow';
import { getIsWorkflowReadOnlyModeByListId } from 'selectors/workflows';
import { applyThemeColorOpacity } from 'utils/theme-utils';

function WorkFlowPlanGroupNode({ data }) {
  const dispatch = useDispatch();
  const {
    listId,
    postId,
    stepId,
    label,
    color,
    Icon,
    width,
    height,
    currentStepIndex,
    isStepDone,
    isStepRunning,
    showTopHandle = true,
    showBottomHandle = true
  } = data;

  const isReadOnly = useSelector((state) => getIsWorkflowReadOnlyModeByListId(state, listId));

  const isFaded = currentStepIndex !== null && !isStepDone && !isStepRunning;
  const handleAddParallelStep = () => {
    dispatch(addParallelStepToPlanAction(listId, postId, stepId, true));
  };

  return (
    <Stack
      // disable node dragging
      className="nodrag"
      sx={{
        width: width,
        height: height,
        padding: '16px',
        boxSizing: 'border-box',
        color: 'colors.primary_text',
        backgroundColor: applyThemeColorOpacity(color, 7),
        borderRadius: '16px',
        opacity: isFaded ? 0.5 : 1,
        textAlign: 'left',
        margin: 0,
        minHeight: 0
      }}>
      <Stack direction="column" gap="8px">
        <MLInlineStack justifyContent="space-between">
          <MLInlineStack direction="row" alignItems="center" gap="8px">
            {Icon && (
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: 32,
                  height: 32,
                  backgroundColor: applyThemeColorOpacity(color, 12),
                  borderRadius: '6px'
                }}>
                <Icon />
              </Stack>
            )}
            <Typography variant="text1_Bold" color={color}>
              {label}
            </Typography>
          </MLInlineStack>
          {!isReadOnly && (
            <MLButton variant="outlined" startIcon={<AddIcon />} onClick={handleAddParallelStep}>
              Add
            </MLButton>
          )}
        </MLInlineStack>
      </Stack>
      {showTopHandle && (
        <Handle type="target" position={Position.Top} style={{ visibility: 'hidden', top: 0 }} />
      )}
      {showBottomHandle && (
        <Handle
          type="source"
          position={Position.Bottom}
          style={{ visibility: 'hidden', bottom: 0 }}
        />
      )}
    </Stack>
  );
}

WorkFlowPlanGroupNode.propTypes = {
  data: PropTypes.object.isRequired
};

export default WorkFlowPlanGroupNode;
