import cloneDeep from 'lodash/cloneDeep';
import { getThreadKey, updatePlan } from 'reducer/custom-analytics';
import { getCustomAnalyticsPlanByThreadAndPostId } from 'selectors/custom-analytics';

export function updateFinalStepInPlanAction(listId, workflowId, columnsVisibility) {
  return (dispatch, getState) => {
    const threadKey = getThreadKey(listId);
    const state = getState();
    const plan = getCustomAnalyticsPlanByThreadAndPostId(state, threadKey, workflowId);

    if (plan.steps) {
      const newPlan = cloneDeep(plan);
      const haveHiddenColumns =
        Object.values(columnsVisibility).findIndex((columnVisibility) => !columnVisibility) >= 0;
      const newColumns = Object.keys(columnsVisibility).filter(
        (column) => columnsVisibility[column]
      );
      if (haveHiddenColumns) {
        const lastStep = newPlan.steps[newPlan.steps.length - 1];
        if (lastStep.step_id === 'end') {
          lastStep.function_use.action_variables.queries[0].columns = newColumns;
        } else {
          newPlan.steps.push({
            step_id: 'end',
            function_use: {
              action: 'view',
              action_variables: {
                queries: [
                  {
                    columns: newColumns
                  }
                ]
              }
            },
            objective: 'view'
          });
        }
      } else if (newPlan.steps[newPlan.steps.length - 1].step_id === 'end') {
        newPlan.steps.splice(newPlan.steps.length - 1, 1);
      }
      dispatch(updatePlan({ threadKey, postId: workflowId, newPlan }));
    }
  };
}
