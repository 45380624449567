import { Button, Dialog, DialogActions, DialogContent, Divider, Stack } from '@mui/material';
import { updateCustomAnalyticsExtraDataForCompaniesInListAction } from 'actions/company_extra_data/update-extra-data-for-companies-in-list-action';
import { updateDiscoveryCustomColumnValueAction } from 'actions/explore/update-discovery-custom-column-value-action';
import CustomMarkdown from 'components/CustomMarkdown';
import ActionDisplayComponent from 'components/assistant/conversation/ActionDisplayComponent';
import CustomAnalyticsAnswerComponent from 'components/assistant/conversation/answer-posts-display/CustomAnalyticsAnswerComponent';
import CustomAnalyticsAnswerEdit from 'components/assistant/conversation/answer-posts-display/CustomAnalyticsAnswerEdit';
import PromptDisplayComponent from 'components/discovery/PromptDisplayComponent';
import { useListContext } from 'hooks/ListContextProvider';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { convertV1CaParametersToV2 } from 'utils/assistant/backwards-compatibility';
import { formatAnswerBody } from 'utils/custom-analytics-utils';

import RenderCustomCellDialogTitle from './RenderCustomCellDialogTitle';
import RenderCustomTextCell from './RenderCustomTextCell';
import TableRowLoading from './TableRowLoading';

const payloadTextFromPayload = (payload) => {
  if (payload?.columnType?.includes('custom_analytics')) {
    return payload?.value?.answer || '';
  }
  if (payload?.value && typeof payload?.value === 'string') {
    return payload?.value;
  }
  return '';
};

export default function RenderCustomAnalyticsOutput({ value: payload }) {
  const { listId, companyName, columnName, extraParams } = payload || {};
  const dispatch = useDispatch();
  const listContext = useListContext();
  const [cellValue, setCellValue] = useState(payloadTextFromPayload(payload));
  const [openDialog, setOpenDialog] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [payloadText, setPayloadText] = useState('');
  const [isHoverCell, setIsHoverCell] = useState(false);
  const isCustomAnalytics = payload?.columnType?.includes('custom_analytics');
  const allowRerun = isCustomAnalytics;

  useEffect(() => {
    const payloadTextTmp = payloadTextFromPayload(payload);
    setIsEdit(payloadTextTmp === '');
    setCellValue(payloadTextTmp);
    setPayloadText(payloadTextTmp);
  }, [payload]);

  if (payload?.value?.loading) {
    return <TableRowLoading />;
  }
  if (!payload) return null;

  if (!payloadText && payload?.columnType === 'basic_data_column') {
    return null;
  }

  const handleClose = () => {
    setOpenDialog(false);
    setIsEdit(false);
  };

  const handleCancel = () => {
    setCellValue(payloadText);
    if (payloadText) {
      setIsEdit(false);
    } else {
      setOpenDialog(false);
    }
  };

  const onUpdateContent = () => {
    const newValue = payload?.columnType?.includes('custom_analytics')
      ? {
          ...(payload.value || {}),
          company_meta_id: payload?.companyMetaId,
          answer: cellValue
        }
      : cellValue;
    dispatch(
      updateDiscoveryCustomColumnValueAction(
        payload?.listId,
        payload?.companyListMetaId,
        payload?.columnId,
        newValue
      )
    );
    if (cellValue) {
      setIsEdit(false);
    }
  };

  const onRerun = (event) => {
    requestAnimationFrame(() => setIsHoverCell(false));
    const metaIds = [payload.companyMetaId];
    dispatch(
      updateCustomAnalyticsExtraDataForCompaniesInListAction(
        listId,
        payload.columnId,
        true,
        metaIds
      )
    );
    event.stopPropagation();
    handleClose();
  };
  const renderFullText = (
    <CustomMarkdown
      body={formatAnswerBody(cellValue, payload.value?.answer_type)}
      listId={listId}
      custom={false}
      context={`${companyName} - ${columnName}`}
    />
  );
  const onCellClick = (event) => {
    event?.stopPropagation();
    event?.preventDefault();
    requestAnimationFrame(() => setIsHoverCell(false));
    setOpenDialog(true);
    if (!cellValue) {
      setIsEdit(true);
    }
  };

  return (
    <Stack
      justifyContent="center"
      width="100%"
      height="100%"
      onMouseEnter={() => setIsHoverCell(true)}
      onMouseLeave={() => setIsHoverCell(false)}>
      <RenderCustomTextCell
        cellValue={cellValue}
        onCellClick={onCellClick}
        onRerun={allowRerun && onRerun}
        isHoverCell={isHoverCell}
        renderFullText={renderFullText}
      />
      <Dialog
        open={openDialog}
        onClose={handleClose}
        disableEnforceFocus
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ sx: { minWidth: '500px' } }}>
        <RenderCustomCellDialogTitle
          companyLogo={payload.companyLogo}
          onRerun={allowRerun && onRerun}
          companyName={companyName}
          columnName={columnName}
          timestamp={payload.value.timestamp}
          isCellEmpty={!cellValue}
        />

        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            height: isCustomAnalytics ? '450px' : '200px'
          }}>
          {!listContext?.canUnify && isCustomAnalytics && (
            <Stack height="fit-content" paddingTop="8px">
              <PromptDisplayComponent
                listId={payload?.listId}
                prompt={payload?.columnInfo}
                mode="plain"
              />
            </Stack>
          )}
          {listContext?.canUnify && isCustomAnalytics && (
            <ActionDisplayComponent
              action={convertV1CaParametersToV2(payload?.columnInfo, listContext)}
              listId={listId}
              actionTaken
            />
          )}
          <Divider />
          <Stack gap="16px" marginTop={isEdit ? '16px' : 'default'}>
            {isEdit && (
              <CustomAnalyticsAnswerEdit
                isEdit={isEdit}
                answer={cellValue}
                setAnswer={setCellValue}
              />
            )}
            {!isEdit && isCustomAnalytics && (
              <CustomAnalyticsAnswerComponent
                message={{ ...payload.value, answer: cellValue }}
                listId={listId}
                context={`${companyName} - ${columnName}`}
                answerMaxHeight={'100%'}
              />
            )}
            {!isEdit && !isCustomAnalytics && renderFullText}
          </Stack>
        </DialogContent>
        <DialogActions>
          {!isEdit && <Button onClick={handleClose}>Close</Button>}
          {!isEdit && !extraParams?.disableEdit && (
            <Button onClick={() => setIsEdit(true)}>Edit</Button>
          )}
          {isEdit && <Button onClick={handleCancel}>Cancel</Button>}
          {isEdit && (
            <Button onClick={onUpdateContent} autoFocus>
              Update
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Stack>
  );
}

RenderCustomAnalyticsOutput.propTypes = {
  value: PropTypes.object
};
