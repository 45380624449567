interface CustomScrollBarProps {
  size: 'small' | 'medium' | 'large';
}
export const getCustomScrollbarStyles = ({ size }: CustomScrollBarProps) => {
  let width = '10px';
  switch (size) {
    case 'small':
      width = '10px';
      break;
    case 'medium':
      width = '12px';
      break;
    case 'large':
      width = '16px';
      break;
    default:
  }
  return {
    '&::-webkit-scrollbar': {
      // Scrollbar width
      width
    },
    '&::-webkit-scrollbar-thumb': {
      border: '2px solid transparent',
      backgroundClip: 'content-box',
      borderRadius: '16px',
      // Scrollbar color
      backgroundColor: 'colors.secondary_text_on_primary'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      // Color on hover
      backgroundColor: 'colors.secondary_text'
    },
    '&::-webkit-scrollbar-track': {
      // Track color
      backgroundColor: 'transparent'
    }
  };
};
