import { Column, Company, SupportingResource } from 'models/assistant/types';
import { CustomColumn } from 'models/data-grid/types';
import React, { ReactNode, createContext, useContext } from 'react';

import useDeriveListContext from './useDeriveListContext';

// Define the shape of your context
export type ListContextType = {
  columns: Column[];
  companies: Company[];
  supportingResources: SupportingResource[];
  listId: number;
  canUnify: boolean;
  customColumns: CustomColumn[];
};

// Create the context with an undefined default value
/* eslint-disable no-undefined */
const ListContext = createContext<ListContextType | undefined>(undefined);

interface ListContextProviderProps {
  listId: number;
  children: ReactNode;
}

// Provider component that holds the state and provides it to its children
export const ListContextProvider: React.FC<ListContextProviderProps> = ({ listId, children }) => {
  const listContext = useDeriveListContext({ listId });

  return <ListContext.Provider value={{ listId, ...listContext }}>{children}</ListContext.Provider>;
};

// Custom hook for consuming the context
export const useListContext = (): ListContextType => {
  const context = useContext(ListContext);
  if (!context) {
    throw new Error('useListContext must be used within a ListContextProvider');
  }
  return context;
};
