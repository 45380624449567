import { Checkbox, FormControlLabel, MenuItem } from '@mui/material';
import React from 'react';

interface ColumnSelectionItemProps {
  id: string;
  name: string;
  isChecked?: boolean;
  onToggle: (id: string) => void;
}

const ColumnSelectionItem: React.FC<ColumnSelectionItemProps> = ({
  name,
  id,
  isChecked = false,
  onToggle
}) => {
  const handleChange = () => {
    onToggle(id);
  };

  return (
    <MenuItem disableRipple>
      <FormControlLabel
        sx={{
          width: '100%',
          margin: 0,
          padding: 0,
          '&.MuiFormControlLabel-root': {
            padding: 0,
            transition: 'none !important'
          }
        }}
        label={name}
        control={
          <Checkbox
            size="small"
            checked={isChecked}
            onChange={handleChange}
            sx={{
              '&.Mui-checked': {
                '&:hover': {
                  backgroundColor: 'transparent !important'
                }
              },
              '&.MuiCheckbox-root': {
                paddingLeft: 'none'
              }
            }}
          />
        }
      />
    </MenuItem>
  );
};

export default React.memo(ColumnSelectionItem);
