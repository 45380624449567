import { Typography } from '@mui/material';
import {
  ACTION_TYPE_TO_BG_COLOR_LIGHT,
  ACTION_TYPE_TO_COLOR,
  ACTION_TYPE_TO_ICON
} from 'constants/assistant';
import MLIcon from 'design-system/MLIcon';
import MLInlineStack from 'design-system/MLInlineStack';
import { AssistantPromptConfigJSON } from 'models/assistant/AssistantPromptConfig';
import React from 'react';
import { newPromptConfig } from 'reducer/assistant-slice';
import { useAppDispatch } from 'types/store';

export interface AssistantSuggestedQueryProps {
  promptConfigParams: Partial<AssistantPromptConfigJSON>;
  label: string;
}

export default function AssistantSuggestedQuery({
  label,
  promptConfigParams
}: AssistantSuggestedQueryProps) {
  const { action_type } = promptConfigParams;
  const sx = action_type
    ? {
        color: ACTION_TYPE_TO_COLOR[action_type],
        backgroundColor: ACTION_TYPE_TO_BG_COLOR_LIGHT[action_type]
      }
    : {};
  const dispatch = useAppDispatch();
  const handleClick = () => {
    dispatch(newPromptConfig(promptConfigParams));
  };
  return (
    <MLInlineStack
      onClick={handleClick}
      sx={{
        border: '1px solid',
        borderColor: 'colors.ui_border',
        borderRadius: '8px',
        maxHeight: '36px',
        paddingX: '8px',
        paddingY: '6px',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'colors.hover_on_primary_bg'
        }
      }}>
      {action_type && (
        <MLIcon icon={React.createElement(ACTION_TYPE_TO_ICON[action_type])} size="small" sx={sx} />
      )}
      <Typography color="colors.primary_text">{label}</Typography>
    </MLInlineStack>
  );
}
