import { Divider, Grid2, Skeleton, Stack, Typography } from '@mui/material';
import CustomMarkdown from 'components/CustomMarkdown';
import BarChartComponent from 'components/elements/bar/BarChartComponent';
import PieChartComponent from 'components/elements/pie/PieChartComponent';
import CountryMap from 'components/elements/table/quick-view/CountryMap';
import { DOMAIN_REPORT_TASK_TYPES } from 'constants/domain-reports';
import MLResizableCustomTextComponent from 'design-system/MLResizableCustomTextComponent';
import { MLTooltipProvider } from 'hooks/useMLTooltip';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { getLatestDRCompanyMetasByListId } from 'selectors/domain-reports';
import { appTheme } from 'theme';
import { formatAnswerBody } from 'utils/custom-analytics-utils';
import { formatNumber, revenueFromData } from 'utils/finance-utils';
import {
  barDataFromHeadcountData,
  barDataFromRevenuesData,
  barDataFromYearsFoundedData,
  pieDataFromFundingStageData
} from 'utils/graph-utils';
import { PPTX_CLASS_NAMES } from 'utils/react-to-powerpoint-utils';

import DomainReportBox from '../DomainReportBox';
import { useDomainReportContext } from '../DomainReportContext';

const gridItemStyle = {
  borderRadius: '8px',
  border: '1px solid',
  borderColor: 'colors.hover_on_gray_bg',
  padding: '16px',
  color: 'colors.primary_text'
};

const GridItem = ({ title, percent, size, sx, children, className }) => {
  return (
    <Grid2 size={size} sx={{ ...gridItemStyle, ...sx }} className={className}>
      <Stack gap="8px" height="100%" sx={{ pageBreakInside: 'avoid' }}>
        <Stack direction="row" gap="12px" alignItems="center" height="20px">
          <Typography variant="text1_Semibold">
            {title}
            {percent ? ` (${percent}%)` : ''}
          </Typography>
        </Stack>
        {children}
      </Stack>
    </Grid2>
  );
};

const getAllValues = (companies, keyFunc) => {
  return companies.map(keyFunc).filter(Boolean);
};

function DomainReportMarket({ section }) {
  const { listId } = useDomainReportContext();

  const companyMetas = useSelector((state) => getLatestDRCompanyMetasByListId(state, listId));
  const { data } = section.props.data;
  const countryData = getAllValues(
    companyMetas,
    (company) => company.enrichment_parameters.hq_location_country
  );
  const revenuesData = getAllValues(
    companyMetas,
    (company) => company.company_extra_data?.FINANCE?.data?.revenue
  );
  const yearData = getAllValues(
    companyMetas,
    (company) => company.enrichment_parameters.year_founded
  );
  const headcountData = getAllValues(companyMetas, (company) => company.enrichment_parameters.size);
  const fundingStageData = getAllValues(
    companyMetas,
    (company) => company.enrichment_parameters.last_equity_round_type
  );
  const revenuesBarData = barDataFromRevenuesData(revenuesData);
  const yearsBarData = barDataFromYearsFoundedData(yearData);
  const headcountBarData = barDataFromHeadcountData(headcountData);
  const fundingStagePieData = pieDataFromFundingStageData(fundingStageData);
  const fundingTotalData = getAllValues(
    companyMetas,
    (company) => company.enrichment_parameters.total_funding
  );
  const revenueDataNoNull = revenuesData.map(revenueFromData).filter(Boolean);
  const calculateTotalValue = (arr) =>
    formatNumber(
      (arr || [])
        .filter((item) => !isNaN(parseInt(item, 10)))
        .reduce((acc, item) => acc + parseInt(item, 10), 0)
    );

  const totalFunding = calculateTotalValue(fundingTotalData);
  const totalRevenue = calculateTotalValue(revenueDataNoNull);
  return (
    <DomainReportBox title="Market Landscape" taskType={DOMAIN_REPORT_TASK_TYPES.MARKET_OVERVIEW}>
      <Grid2 container spacing={2} sx={{ marginTop: '8px' }}>
        <Grid2 container className={PPTX_CLASS_NAMES.slideQuery}>
          <GridItem
            title="Market Overview"
            size={12}
            listId={listId}
            taskType={DOMAIN_REPORT_TASK_TYPES.MARKET_OVERVIEW}>
            {data?.answer ? (
              <div id="domain-report-market-overview">
                <CustomMarkdown
                  body={formatAnswerBody(data.answer)}
                  color="colors.primary_text"
                  listId={listId}
                />
              </div>
            ) : (
              <Skeleton variant="rounded" width="100%" height="150px" />
            )}
          </GridItem>
          <Grid2 size={12} sx={{ visibility: 'hidden' }} className={PPTX_CLASS_NAMES.ignoreHidden}>
            <Divider flexItem />
          </Grid2>
          <GridItem title="Headcount" size={6}>
            <BarChartComponent
              labels={Object.keys(headcountBarData)}
              values={Object.values(headcountBarData)}
              color={appTheme.vars.palette.graphColors.graph10}
              xAxisName="Company count"
              barWidth={12}
              vertical
            />
          </GridItem>
          <GridItem title="Annual revenue $M" size={6}>
            <BarChartComponent
              labels={Object.keys(revenuesBarData)}
              values={Object.values(revenuesBarData).map((item) => item.value)}
              color={appTheme.vars.palette.graphColors.graph2}
              xAxisName="Company count"
              barWidth={12}
              vertical
            />
          </GridItem>
        </Grid2>
        <Grid2 container className={PPTX_CLASS_NAMES.slideQuery}>
          <GridItem title="Foundation Year" size={8}>
            <BarChartComponent
              labels={Object.keys(yearsBarData)}
              values={Object.values(yearsBarData)}
              color={appTheme.vars.palette.graphColors.graph11}
              yAxisName="Company count"
              noXLabel
              height={200}
            />
          </GridItem>
          <Grid2 size={4}>
            <Grid2 container spacing={2} sx={{ height: '100%' }}>
              <GridItem title="Total revenues" size={12}>
                <MLTooltipProvider>
                  <MLResizableCustomTextComponent
                    text={totalRevenue ? `$${totalRevenue}` : ''}
                    variant="mediumNumber"
                  />
                </MLTooltipProvider>
              </GridItem>
              <GridItem title="Total funding" size={12}>
                <MLTooltipProvider>
                  <MLResizableCustomTextComponent
                    text={totalFunding ? `$${totalFunding}` : ''}
                    variant="mediumNumber"
                  />
                </MLTooltipProvider>
              </GridItem>
            </Grid2>
          </Grid2>
          <Grid2 size={12} sx={{ visibility: 'hidden' }} className={PPTX_CLASS_NAMES.ignoreHidden}>
            <Divider flexItem />
          </Grid2>
          <GridItem title="Funding Stage" size={12}>
            <PieChartComponent
              shouldCollapseLegend
              orderByCount={false}
              labels={Object.keys(fundingStagePieData)}
              values={Object.values(fundingStagePieData)}
              showAsDonut={true}
              showLegend={true}
            />
          </GridItem>
        </Grid2>
        <GridItem title="Company locations" size={12} className={PPTX_CLASS_NAMES.slideQuery}>
          <CountryMap countryData={countryData} />
        </GridItem>
      </Grid2>
    </DomainReportBox>
  );
}
GridItem.propTypes = {
  title: PropTypes.string,
  size: PropTypes.number,
  sx: PropTypes.object,
  percent: PropTypes.number,
  taskType: PropTypes.string,
  listId: PropTypes.number,
  children: PropTypes.node,
  className: PropTypes.string
};

DomainReportMarket.propTypes = {
  section: PropTypes.object
};

export default DomainReportMarket;
