import React from 'react';

export default function RerunIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.19091 5.51739C9.63451 4.68341 11.3123 4.3472 12.9657 4.56055C14.6192 4.7739 16.1567 5.52498 17.3413 6.69809C18.5259 7.8712 19.292 9.40125 19.5215 11.0526C19.5595 11.3261 19.3686 11.5786 19.095 11.6166C18.8215 11.6546 18.569 11.4637 18.531 11.1902C18.3317 9.75616 17.6664 8.42741 16.6377 7.40864C15.6089 6.38987 14.2737 5.73761 12.8378 5.55233C11.4018 5.36705 9.94482 5.65902 8.69115 6.38328C7.66079 6.97853 6.81478 7.83863 6.23697 8.86812H8.5066C8.78274 8.86812 9.0066 9.09198 9.0066 9.36812C9.0066 9.64426 8.78274 9.86812 8.5066 9.86812H5.44957C5.44199 9.8683 5.43439 9.8683 5.42678 9.86812H5.00006C4.72392 9.86812 4.50006 9.64426 4.50006 9.36812V5.86158C4.50006 5.58544 4.72392 5.36158 5.00006 5.36158C5.2762 5.36158 5.50006 5.58544 5.50006 5.86158V8.14756C6.15922 7.06439 7.08242 6.15778 8.19091 5.51739ZM4.93124 12.3795C5.20475 12.3415 5.45729 12.5324 5.4953 12.8059C5.69459 14.2399 6.35986 15.5687 7.38862 16.5875C8.41739 17.6062 9.75257 18.2585 11.1885 18.4438C12.6244 18.629 14.0815 18.3371 15.3351 17.6128C16.3655 17.0176 17.2115 16.1575 17.7893 15.128H15.5197C15.2435 15.128 15.0197 14.9041 15.0197 14.628C15.0197 14.3518 15.2435 14.128 15.5197 14.128H18.5767C18.5843 14.1278 18.5919 14.1278 18.5995 14.128H19.0262C19.3024 14.128 19.5262 14.3518 19.5262 14.628V18.1345C19.5262 18.4107 19.3024 18.6345 19.0262 18.6345C18.7501 18.6345 18.5262 18.4107 18.5262 18.1345V15.8485C17.8671 16.9317 16.9439 17.8383 15.8354 18.4787C14.3918 19.3127 12.714 19.6489 11.0605 19.4355C9.40706 19.2222 7.8696 18.4711 6.68498 17.298C5.50036 16.1249 4.73431 14.5948 4.50482 12.9435C4.46681 12.67 4.65772 12.4175 4.93124 12.3795Z"
        fill="#5C5E76"
      />
    </svg>
  );
}
