import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Box, Typography } from '@mui/material';
import { useAppUtils } from 'AppUtilsProvider';
import { deleteDomainReportSection } from 'actions/explore/delete-domain-report-section-action';
import { useDomainReportContext } from 'components/domain-report/DomainReportContext';
import MLIconButton from 'design-system/MLIconButton';
import MLInlineStack from 'design-system/MLInlineStack';
import DragIcon from 'design-system/icons/basic/DragIcon';
import { DomainReportSection } from 'models/domain-report/types';
import React from 'react';
import { useAppDispatch } from 'types/store';

import DomainReportDraggableSectionActions from './DomainReportDraggableSectionActions';
import DraggableItemPlaceholder from './DraggableItemPlaceholder';

interface DomainReportDraggableSectionProps {
  section: DomainReportSection;
  disabled?: boolean;
  displayPlaceholder?: boolean;
  isDragged?: boolean;
  setEditableSection?: (section: DomainReportSection) => void;
  sx?: any;
}

export default function DomainReportDraggableSection({
  section,
  displayPlaceholder,
  setEditableSection,
  sx,
  isDragged,
  disabled
}: DomainReportDraggableSectionProps) {
  const dispatch = useAppDispatch();
  const { listId, domainReport } = useDomainReportContext();
  const { addToast } = useAppUtils();
  const { title } = section.props;
  const { iconComponent } = section.presentationProps;

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: section.props.id
  });

  const deleteSection = () => {
    dispatch(
      deleteDomainReportSection(listId, domainReport.domain_report_id, section.props.id, addToast)
    );
  };

  const boxSx = {
    minHeight: '62px',
    margin: '4px 16px',
    borderRadius: '8px',
    alignContent: 'center',
    transform: CSS.Transform.toString(transform),
    transition: transition,
    borderColor: 'colors.ui_border',
    backgroundColor: 'white'
  };

  return displayPlaceholder ? (
    <DraggableItemPlaceholder sx={boxSx} setNodeRef={setNodeRef} />
  ) : (
    <Box
      ref={setNodeRef}
      sx={{
        border: '1px solid',
        '&:hover': {
          backgroundColor: 'colors.hover_on_primary_bg'
        },
        ...boxSx,
        ...sx
      }}>
      <MLInlineStack sx={{ padding: '0 8px' }}>
        <MLIconButton
          size="small"
          disabled={disabled}
          tooltipText={disabled ? 'Please wait for report update to finish' : ''}
          {...listeners}
          {...attributes}
          sx={isDragged ? { cursor: 'grabbing' } : { cursor: 'grab' }}>
          <DragIcon />
        </MLIconButton>
        {iconComponent}
        <Typography variant="text1_Normal">{title}</Typography>
        <Box sx={{ flexGrow: 1 }} />
        <DomainReportDraggableSectionActions
          section={section}
          deleteAction={deleteSection}
          editAction={() => setEditableSection?.(section)}
        />
      </MLInlineStack>
    </Box>
  );
}
