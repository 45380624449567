import { Stack } from '@mui/material';
import RenderDiscoveryRunFeedMessage from 'components/discovery/RenderDiscoveryRunFeedMessage';
import MLLoadingDotsAnimation from 'design-system/MLLoadingDotsAnimation';
import {
  ActionType,
  AssistantAction,
  isDiscoveryAction,
  isForEachQuestion
} from 'models/assistant/assistant-actions';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getThreadKey } from 'reducer/custom-analytics';
import { getPostFromAssistantAction } from 'selectors/custom-analytics';
import { getObjectLength, isEmpty } from 'utils/objects-utils';

import AddToDomainKnowledgeButton from './AddToDomainKnowledgeButton';
import ColumnCreatedMessageComponent, {
  ColumnCreatedComponent
} from './ColumnCreatedMessageComponent';
import CustomAnalyticsAnswerComponent from './CustomAnalyticsAnswerComponent';
import DiscoverySuggestionsComponent from './DiscoverySuggestionsComponent';
import PostStatusComponent from './PostStatusComponent';
import CompaniesAnswerComponent from './for-each-question-answers-diaplay/CompaniesAnswerComponent';

interface CustomAnalyticsPostDisplayProps {
  listId: number;
  action: AssistantAction;
}

function CustomAnalyticsPostDisplay({ listId, action }: CustomAnalyticsPostDisplayProps) {
  const [showAnswers, setShowAnswers] = useState(true);
  const post = useSelector((state) =>
    getPostFromAssistantAction(state, getThreadKey(listId), action)
  );

  if (!post) {
    return (
      <Stack height="100%" justifyContent="center" pl="16px">
        <MLLoadingDotsAnimation />
      </Stack>
    );
  }

  if (post?.query === null) {
    // Handle the case where all companies already have answers so no record was submitted (can happen in add column from library)
    if (action.action_type === ActionType.ADD_COLUMN && action.submission_info?.column_id) {
      return <ColumnCreatedComponent listId={listId} dataType={action.submission_info.column_id} />;
    }
    return null;
  }
  const isForEach = isForEachQuestion(action);
  const isDone = post.status === '100%';
  const isPreview = action.action_type === ActionType.PREVIEW_COLUMN;

  return (
    <Stack
      direction="column"
      sx={{
        flex: 1,
        border: 'none',
        borderColor: 'colors.hover_on_gray_bg',
        borderRadius: '4px',
        padding: '8px',
        gap: '16px',
        maxWidth: '100%',
        overflowX: 'hidden'
      }}>
      {post.postId && !isDone && (
        <PostStatusComponent post={post} listId={listId} isForEach={isForEach} />
      )}
      {isDone && isDiscoveryAction(action) && (
        <RenderDiscoveryRunFeedMessage
          searchId={post.discoveryId}
          listId={listId}
          showOnlyDiscoveredCompanies={true}
        />
      )}
      {!isEmpty(post?.suggestions) && (
        <DiscoverySuggestionsComponent suggestions={post?.suggestions} />
      )}
      {!isPreview && post.messages && isDone && (
        <ColumnCreatedMessageComponent
          listId={listId}
          postId={post.postId}
          numSelectedCompanies={getObjectLength(post.body.company_meta_ids)}
          showAnswers={showAnswers}
          setShowAnswers={setShowAnswers}
        />
      )}
      {showAnswers && post.messages && (
        <CompaniesAnswerComponent
          listId={listId}
          messages={post.messages}
          selectedCompaniesIds={post.body.company_meta_ids}
        />
      )}

      {action.action_type === ActionType.ASK_QUESTION && post.message && (
        <Stack gap="8px">
          <CustomAnalyticsAnswerComponent
            message={post.message}
            listId={listId}
            titleVariant="text1_Normal"
          />
          <AddToDomainKnowledgeButton listId={listId} postId={post.postId} action={action} />
        </Stack>
      )}

      {post.image && (
        <img style={{ maxWidth: '100%' }} src={`data:image/png;base64,${post.image}`} />
      )}
    </Stack>
  );
}

export default React.memo(CustomAnalyticsPostDisplay);
