import React from 'react';

function TextTypeIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M12 5.5L12 18"
        stroke="currentColor"
        strokeWidth="1.13333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.25 9.33333V5.5H19V7.91667V9.33333"
        stroke="currentColor"
        strokeWidth="1.13333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 18.5L12 18.5L14.5 18.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default TextTypeIcon;
