import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { loadAllOrganizations } from 'actions/organizations/load-all-organizations-action';
import {
  ORGANIZATION_SELECTOR_ALL_VALUE,
  ORGANIZATION_SELECTOR_NONE_VALUE,
  ORGANIZATION_SELECTOR_NON_MATHLABS_VALUE
} from 'constants/organizations';
import { dispatch } from 'hooks/AppStateProvider';
import { useAppState } from 'hooks/state-context';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { getOrganizations } from 'selectors/organizations';

function OrgnizationSelector({
  setOrganizationId,
  userDialog,
  sx,
  error,
  variant,
  organizationId
}) {
  const { state } = useAppState();
  const organizations = getOrganizations(state);

  const initialValue = userDialog
    ? ORGANIZATION_SELECTOR_NONE_VALUE
    : ORGANIZATION_SELECTOR_ALL_VALUE;

  useEffect(() => {
    dispatch(loadAllOrganizations());
  }, []);

  const handleChange = (event) => {
    setOrganizationId(event.target.value);
  };

  return (
    <FormControl sx={sx ? sx : { width: '200px' }} variant={variant} error={error}>
      <InputLabel id="organization-select-label">Organization</InputLabel>
      <Select
        labelId="organization-select-label"
        id="organization-select"
        value={organizationId || initialValue}
        label="Organization"
        onChange={handleChange}>
        {!userDialog && <MenuItem value={ORGANIZATION_SELECTOR_ALL_VALUE}>All</MenuItem>}
        <MenuItem value={ORGANIZATION_SELECTOR_NONE_VALUE}>No Organization</MenuItem>
        {!userDialog && (
          <MenuItem value={ORGANIZATION_SELECTOR_NON_MATHLABS_VALUE}>Non Mathlabs</MenuItem>
        )}
        {organizations &&
          Object.keys(organizations)
            .sort((key1, key2) => organizations[key1].name.localeCompare(organizations[key2].name))
            .map((key) => (
              <MenuItem key={organizations[key].id} value={organizations[key].id}>
                {organizations[key].name}
              </MenuItem>
            ))}
      </Select>
    </FormControl>
  );
}

OrgnizationSelector.propTypes = {
  setOrganizationId: PropTypes.func,
  userDialog: PropTypes.bool,
  variant: PropTypes.string,
  error: PropTypes.bool,
  sx: PropTypes.object,
  organizationId: PropTypes.number
};
export default OrgnizationSelector;
