import { Avatar } from '@mui/material';
import React from 'react';
import { stringToCodeInt, toTitleCase } from 'utils/string-utils';

interface MLAvatarProps {
  name: string;
  pictureUrl: string;
  size?: 'small' | 'medium' | 'large';
  variant?: 'circular' | 'rounded';
  disabled?: boolean;
}

export default function MLAvatar({
  name,
  pictureUrl,
  disabled,
  size = 'small',
  variant = 'circular'
}: MLAvatarProps) {
  const displayName = name ? toTitleCase(name) : '?';
  const getNameColor = (name: string) =>
    `graphColors.graph${((stringToCodeInt(name) + 3) % 12) + 1}`;
  const sizeMap = {
    small: 20,
    medium: 24,
    large: 40
  };
  const sizeNumber = sizeMap[size];
  const avatarBackground = 'solid 2px white';
  return (
    <Avatar
      alt={displayName}
      src={pictureUrl}
      variant={variant}
      sx={{
        backgroundColor: pictureUrl ? 'null' : getNameColor(displayName),
        border: pictureUrl ? avatarBackground : null,
        width: sizeNumber,
        height: sizeNumber,
        fontSize: sizeNumber ? sizeNumber / 2 + 2 : null,
        opacity: disabled ? 0.5 : 1
      }}>
      {pictureUrl ? null : displayName.charAt(0)}
    </Avatar>
  );
}
