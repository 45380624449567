export const getCustomAnalytics = (state) => state.customAnalytics;
export const getCustomAnalyticsThreads = (state) => getCustomAnalytics(state).threads;
export const getCustomAnalyticsThreadById = (state, threadKey) =>
  getCustomAnalyticsThreads(state)[threadKey];
export const getIsCustomAnalyticsThreadIsReadyById = (state, threadKey) =>
  getCustomAnalyticsThreadById(state, threadKey)?.isReady;
export const getCustomAnalyticsThreadIdById = (state, threadKey) =>
  getCustomAnalyticsThreadById(state, threadKey)?.threadId;
export const getCustomAnalyticsThreadPostsById = (state, threadKey) =>
  getCustomAnalyticsThreadById(state, threadKey)?.posts;
export const getCustomAnalyticsThreadSpecificPostById = (state, threadKey, postId) =>
  getCustomAnalyticsThreadPostsById(state, threadKey)?.[postId];
export const getCustomAnalyticsPlanByThreadAndPostId = (state, threadKey, postId) => {
  const post = getCustomAnalyticsThreadSpecificPostById(state, threadKey, postId);
  return post?.plan || post?.body?.plan;
};
export const getCustomAnalyticsPlanStepsByThreadAndPostId = (state, threadKey, postId) =>
  getCustomAnalyticsPlanByThreadAndPostId(state, threadKey, postId)?.steps;
export const getCustomAnalyticsPlanMainPromptByThreadAndPostId = (state, threadKey, postId) =>
  getCustomAnalyticsPlanByThreadAndPostId(state, threadKey, postId)?.question;
export const getCustomAnalyticsPlanStepByThreadAndPostIdAndStepIndex = (
  state,
  threadKey,
  postId,
  stepIndex
) => getCustomAnalyticsPlanStepsByThreadAndPostId(state, threadKey, postId)?.[stepIndex];
export const getCustomAnalyticsPlanStepActionByThreadAndPostIdAndStepIndex = (
  state,
  threadKey,
  postId,
  stepIndex
) =>
  getCustomAnalyticsPlanStepByThreadAndPostIdAndStepIndex(state, threadKey, postId, stepIndex)
    ?.objective;
export const getCustomAnalyticsPlanStepQueriesByThreadAndPostIdAndStepIndex = (
  state,
  threadKey,
  postId,
  stepIndex
) =>
  getCustomAnalyticsPlanStepByThreadAndPostIdAndStepIndex(state, threadKey, postId, stepIndex)
    ?.function_use?.action_variables?.queries;
export const getCustomAnalyticsPlanStepQueryByThreadAndPostIdAndStepIndexAndQueryIndex = (
  state,
  threadKey,
  postId,
  stepIndex,
  queryIndex
) =>
  getCustomAnalyticsPlanStepQueriesByThreadAndPostIdAndStepIndex(
    state,
    threadKey,
    postId,
    stepIndex,
    queryIndex
  )?.[queryIndex];
export const getIsCustomAnalyticsThreadProccessingById = (state, threadKey) =>
  getCustomAnalyticsThreadById(state, threadKey)?.isProccessing;
export const getCustomAnalyticClipboard = (state) => getCustomAnalytics(state).clipboard;
export const getCustomAnalyticsOpen = (state) => getCustomAnalytics(state).customAnalyticsOpen;
export const getCustomAnalyticClipboardById = (state, threadKey) =>
  getCustomAnalyticClipboard(state)?.[threadKey];
export const getCustomAnalyticDataTypeToPostIdMap = (state) =>
  getCustomAnalytics(state).dataTypeToPostIdMap;
export const getCustomAnalyticPostIdByDataType = (state, dataType) =>
  getCustomAnalyticDataTypeToPostIdMap(state)[dataType];
export const getCustomAnalyticDataTypeByPostIdMap = (state, postId) => {
  const dataTypeToPostIdMap = getCustomAnalyticDataTypeToPostIdMap(state);
  return Object.entries(dataTypeToPostIdMap).find(([, id]) => id === postId)?.[0];
};
export const getCustomAnalyticsDiscoveryPostByThreadIdAndDiscoveryId = (
  state,
  threadKey,
  discoveryId
) => {
  const posts = getCustomAnalyticsThreadPostsById(state, threadKey);
  if (!posts) {
    return null;
  }
  return Object.values(posts).find((post) => post?.discoveryId === discoveryId);
};

export const getCustomAnalyticsDiscoveryPostIdByThreadIdAndDiscoveryId = (
  state,
  threadKey,
  discoveryId
) => {
  const post = getCustomAnalyticsDiscoveryPostByThreadIdAndDiscoveryId(
    state,
    threadKey,
    discoveryId
  );
  return post?.postId;
};

export const getCustomAnalyticsDiscoverySuggestionsByThreadIdAndDiscoveryId = (
  state,
  threadKey,
  discoveryId
) => {
  const post = getCustomAnalyticsDiscoveryPostByThreadIdAndDiscoveryId(
    state,
    threadKey,
    discoveryId
  );
  return post?.suggestions;
};

const getIsPostWorkflow = (post) => {
  return post?.body?.plan?.is_workflow || post?.plan?.is_workflow;
};

export const getIsWorkflowPlanning = (post) => {
  return getIsPostWorkflow(post) && post?.responseType === 'PREPARATION';
};

const getIsWorkflowRun = (post) => {
  const isWorflow = getIsPostWorkflow(post);
  const isWorkflowPlanning = getIsWorkflowPlanning(post);
  const isWorflowRun = isWorflow && !isWorkflowPlanning;
  return isWorflowRun;
};

export const getIsPostWorkflowByThreadIdAndPostId = (state, threadKey, postId) => {
  const post = getCustomAnalyticsThreadSpecificPostById(state, threadKey, postId);
  return getIsPostWorkflow(post);
};

export const getIsPostWorkflowRunByThreadIdAndPostId = (state, threadKey, postId) => {
  const post = getCustomAnalyticsThreadSpecificPostById(state, threadKey, postId);
  return getIsWorkflowRun(post);
};

export const getCurrentlyRunningPostForWorkflowInList = (state, threadKey, plan) => {
  if (!plan) {
    return false;
  }
  const posts = getCustomAnalyticsThreadPostsById(state, threadKey);
  return Object.values(posts)
    .filter(
      (post) =>
        getIsWorkflowRun(post) &&
        post?.body?.plan &&
        JSON.stringify(post.body.plan) === JSON.stringify(plan)
    )
    .sort((post1, post2) => post2.recordId - post1.recordId)?.[0];
};

export const getPostNameByThreadIdAndPostId = (state, threadKey, postId) => {
  const post = getCustomAnalyticsThreadSpecificPostById(state, threadKey, postId);
  return post?.name;
};

export const getPostSavedQueryIdByThreadIdAndPostId = (state, threadKey, postId) => {
  const post = getCustomAnalyticsThreadSpecificPostById(state, threadKey, postId);
  return post?.body?.saved_query_id;
};

export const getPostFromAssistantAction = (state, threadKey, action) => {
  const { post_id: postId, record_id: recordId } = action.submission_info || {};

  const postByPostId = getCustomAnalyticsThreadSpecificPostById(state, threadKey, postId);
  if (postByPostId) {
    return postByPostId;
  }
  const postIdByRecord = getCustomAnalyticsDiscoveryPostIdByThreadIdAndDiscoveryId(
    state,
    threadKey,
    recordId
  );

  const postByRecordId = getCustomAnalyticsThreadSpecificPostById(state, threadKey, postIdByRecord);
  return postByRecordId;
};

export const getCurrentlyRunningPostForWorkflowFromOriginalWorkflowIdInList = (
  state,
  threadKey,
  workflowId
) => {
  if (!workflowId) {
    return false;
  }
  const posts = getCustomAnalyticsThreadPostsById(state, threadKey);
  if (!posts) return false;
  return Object.values(posts)
    .filter(
      (post) =>
        post?.body?.originalWorkflowId &&
        post?.body?.originalWorkflowId === workflowId &&
        getIsWorkflowRun(post)
    )
    .sort((post1, post2) => post2.recordId - post1.recordId)?.[0];
};
