import { ANSWER_TYPES } from 'constants/assistant';
import MLIconMenuEnumButton from 'design-system/MLIconMenuEnumButton';
import React from 'react';
import { updatePromptConfig } from 'reducer/assistant-slice';
import { getPromptConfigAnswerType } from 'selectors/assistant';
import { useAppDispatch, useAppSelector } from 'types/store';
import { AnswerType } from 'utils/custom-analytics-utils';

export default function AssistantWebTableButton() {
  const dispatch = useAppDispatch();
  const value = useAppSelector(getPromptConfigAnswerType);
  const updateValue = (answer_type: AnswerType) => {
    dispatch(updatePromptConfig({ answer_type }));
  };
  return (
    <MLIconMenuEnumButton
      title="Answer type"
      value={value}
      updateValue={updateValue}
      options={ANSWER_TYPES}
    />
  );
}
