import { Button, Collapse, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { appTheme } from 'theme';
import { PPTX_CLASS_NAMES } from 'utils/react-to-powerpoint-utils';

const minOpacity = 0.2;

function DomainReportProcessBar({ title, number, total, children, className }) {
  const [seeAll, setSeeAll] = React.useState(false);
  const percentage = Math.round((number / total) * 100);
  const bgColor = appTheme.vars.palette.colors.hover_on_primary_bg;
  const opacity = minOpacity + (percentage / 100) * (1 - minOpacity);
  const bgGradient = `linear-gradient(to right, rgba(143,188,252,${opacity}) ${percentage}%, ${bgColor} ${percentage}%)`;

  return (
    <div className={className}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          maxHeight: '91px',
          paddingY: '16px',
          paddingX: '24px',
          borderRadius: '8px',
          background: bgGradient,
          cursor: children ? 'pointer' : 'default'
        }}
        onClick={children ? () => setSeeAll((prev) => !prev) : null}>
        <Stack>
          <Typography variant="text1_Semibold" color="colors.primary_text">
            {title}
          </Typography>
          <Typography variant="mediumNumber" color="colors.primary_text">
            {number}
          </Typography>
        </Stack>
        <Button disabled={!children}>{seeAll ? 'Hide' : 'See all'}</Button>
      </Stack>
      <Collapse in={seeAll} className={PPTX_CLASS_NAMES.ignoreHidden}>
        <Stack gap="16px" sx={{ padding: '24px', paddingTop: '8px' }}>
          {children}
        </Stack>
      </Collapse>
    </div>
  );
}

DomainReportProcessBar.propTypes = {
  title: PropTypes.string,
  number: PropTypes.number,
  total: PropTypes.number,
  children: PropTypes.node,
  className: PropTypes.string
};

DomainReportProcessBar.defaultProps = {
  hideButtonText: 'Hide'
};

export default DomainReportProcessBar;
