import { ANSWER_TYPES, SEARCH_SETTING_OPTIONS, SearchSettingsOptions } from 'constants/assistant';
import MLIconMenuEnumButton from 'design-system/MLIconMenuEnumButton';
import MLInlineStack from 'design-system/MLInlineStack';
import {
  ActionType,
  AssistantAction,
  AssistantActionCustomAnalytics,
  isCustomAnalyticsAction
} from 'models/assistant/assistant-actions';
import React, { useEffect } from 'react';
import { AnswerType } from 'utils/custom-analytics-utils';

import {
  searchSettingsOptionToSearchSettings,
  searchSettingsToSearchSettingsOption
} from '../prompt/buttons/AssistantWebTableButton';

interface ActionDisplayParametersComponentProps {
  action: AssistantActionCustomAnalytics;
  onActionParamsChange: (newAction: AssistantAction) => void;
}

function ActionDisplayParametersComponent({
  action,
  onActionParamsChange
}: ActionDisplayParametersComponentProps) {
  const [localAction, setLocalAction] = React.useState<AssistantActionCustomAnalytics>(action);

  const shouldShowAnswerType = [
    ActionType.ADD_COLUMN,
    ActionType.ASK_QUESTION,
    ActionType.PREVIEW_COLUMN
  ].includes(action.action_type);

  useEffect(() => {
    if (JSON.stringify(action) !== JSON.stringify(localAction)) {
      onActionParamsChange(localAction);
    }
  }, [action, localAction, onActionParamsChange]);

  const handleAnswerTypeChange = (newAnswerType: AnswerType) => {
    if (!isCustomAnalyticsAction(localAction)) return;
    setLocalAction({ ...localAction, answer_type: newAnswerType });
  };

  const updateSearchSettings = (searchSettingsOption: SearchSettingsOptions) => {
    setLocalAction({
      ...localAction,
      search_settings: searchSettingsOptionToSearchSettings[searchSettingsOption]
    });
  };

  return (
    <MLInlineStack gap={0}>
      <MLIconMenuEnumButton
        title="Search settings"
        value={searchSettingsToSearchSettingsOption(localAction.search_settings)}
        updateValue={updateSearchSettings}
        options={SEARCH_SETTING_OPTIONS}
      />
      {shouldShowAnswerType && (
        <MLIconMenuEnumButton
          title="Answer type"
          value={action.answer_type || 'text'}
          updateValue={handleAnswerTypeChange}
          options={ANSWER_TYPES}
        />
      )}
    </MLInlineStack>
  );
}

export default ActionDisplayParametersComponent;
