import PropTypes from 'prop-types';
import React from 'react';

function PromptTextIcon({ size }) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.10011 5C5.82016 5 5.55168 5.11121 5.35372 5.30917C5.15577 5.50712 5.04456 5.7756 5.04456 6.05556V18.2929L7.30211 16.0353C7.39588 15.9416 7.52306 15.8889 7.65567 15.8889H17.9446C18.2245 15.8889 18.493 15.7777 18.691 15.5797C18.8889 15.3818 19.0001 15.1133 19.0001 14.8333V6.05556C19.0001 5.77561 18.8889 5.50712 18.691 5.30917C18.493 5.11121 18.2245 5 17.9446 5H6.10011ZM4.64661 4.60206C5.03211 4.21657 5.55494 4 6.10011 4H17.9446C18.4897 4 19.0126 4.21657 19.3981 4.60206C19.7836 4.98755 20.0001 5.51039 20.0001 6.05556V14.8333C20.0001 15.3785 19.7836 15.9013 19.3981 16.2868C19.0126 16.6723 18.4897 16.8889 17.9446 16.8889H7.86277L4.89811 19.8536C4.75511 19.9966 4.54005 20.0393 4.35321 19.9619C4.16638 19.8845 4.04456 19.7022 4.04456 19.5V6.05556C4.04456 5.51039 4.26112 4.98755 4.64661 4.60206Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99995 9C7.99995 8.72386 8.22381 8.5 8.49995 8.5H16C16.2761 8.5 16.5 8.72386 16.5 9C16.5 9.27614 16.2761 9.5 16 9.5H8.49995C8.22381 9.5 7.99995 9.27614 7.99995 9Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99995 12C7.99995 11.7239 8.22381 11.5 8.49995 11.5H13.5C13.7761 11.5 14 11.7239 14 12C14 12.2761 13.7761 12.5 13.5 12.5H8.49995C8.22381 12.5 7.99995 12.2761 7.99995 12Z"
        fill="currentColor"
      />
    </svg>
  );
}

PromptTextIcon.propTypes = {
  size: PropTypes.number
};

PromptTextIcon.defaultProps = {
  size: 24
};

export default PromptTextIcon;
