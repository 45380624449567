import MLMarkdownEditor from 'design-system/MLMarkdownEditor';
import React, { ChangeEvent, useEffect, useRef } from 'react';

type Props = {
  isEdit: boolean;
  answer: string;
  setAnswer: (answer: string) => void;
};

function CustomAnalyticsAnswerEdit({ isEdit, answer, setAnswer }: Props) {
  const editBoxRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (isEdit && editBoxRef.current) {
      requestAnimationFrame(() => editBoxRef.current?.focus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, editBoxRef?.current]);
  const onCellValueChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setAnswer(event.target.value);
  };
  return (
    <MLMarkdownEditor
      value={answer}
      onChange={onCellValueChange}
      minRows={5}
      maxRows={11}
      sx={{ width: '550px' }}
      autoFocus
      inputRef={editBoxRef}
      placeholder="Update the content here"
    />
  );
}

export default CustomAnalyticsAnswerEdit;
