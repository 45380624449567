import { Button, ButtonProps } from '@mui/material';
import React from 'react';

import MLButtonWrapper, { MLButtonWrapperProps } from './base/MLButtonWrapper';

interface MLButtonProps extends ButtonProps, MLButtonWrapperProps {
  children: React.ReactNode;
}

const MLButton = React.forwardRef<HTMLButtonElement, MLButtonProps>(
  (
    {
      id,
      tooltipText,
      tooltipPlacement = 'bottom',
      tooltipArrow = true,
      alertMessage,
      alertSeverity,
      children,
      ...extraProps
    },
    ref
  ) => {
    const inner = (
      <Button id={id} {...extraProps} ref={ref}>
        {children}
      </Button>
    );
    return (
      <MLButtonWrapper
        tooltipText={tooltipText}
        tooltipPlacement={tooltipPlacement}
        tooltipArrow={tooltipArrow}
        alertMessage={alertMessage}
        alertSeverity={alertSeverity}>
        {inner}
      </MLButtonWrapper>
    );
  }
);

MLButton.displayName = 'MLButton';

export default MLButton;
