import { Button, Stack, Typography } from '@mui/material';
import { launchWorkflowAction } from 'actions/workflows/launch-workflow-action';
import WorkflowsCenterDialog from 'components/work-flow/WorkflowsCenterDialog';
import { WORKFLOW_SELECTED_TYPE } from 'constants/workflows';
import MLButton from 'design-system/MLButton';
import MLInlineStack from 'design-system/MLInlineStack';
import CheckIcon2 from 'icons/CheckIcon2';
import { AssistantAction } from 'models/assistant/assistant-actions';
import React, { ElementType, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getThreadKey } from 'reducer/custom-analytics';
import { updateSelectedWorkflowIdByListId } from 'reducer/workflows-slice';
import {
  getCurrentlyRunningPostForWorkflowFromOriginalWorkflowIdInList,
  getPostFromAssistantAction,
  getPostNameByThreadIdAndPostId
} from 'selectors/custom-analytics';
import { useAppDispatch } from 'types/store';
import { isEmpty } from 'utils/objects-utils';
import { applyThemeColorOpacity } from 'utils/theme-utils';
import { extractNodesAndEdges } from 'utils/work-flow-planner-utils';

type WorkflowActionDisplayComponentProps = {
  listId: number;
  action: AssistantAction;
};

function WorkflowPostDisplayComponent({ listId, action }: WorkflowActionDisplayComponentProps) {
  const threadKey = getThreadKey(listId);
  const post = useSelector((state) => getPostFromAssistantAction(state, threadKey, action));
  const plan = post?.plan;

  const currentlyRunningPost = useSelector((state) =>
    getCurrentlyRunningPostForWorkflowFromOriginalWorkflowIdInList(state, threadKey, post?.postId)
  );

  const [openWorkflow, setOpenWorkflow] = useState(false);
  const dispatch = useAppDispatch();

  const closeWorkflowDialog = () => {
    dispatch(
      updateSelectedWorkflowIdByListId({
        listId,
        selectedWorkflowId: null,
        selectedType: null
      })
    );
    setOpenWorkflow(false);
  };

  const stepsData = useMemo(() => {
    if (!plan?.steps) {
      return [];
    }
    const allSteps: Record<string, any>[] = extractNodesAndEdges(plan.steps, listId)?.nodes?.slice(
      1
    );
    const stepIds = allSteps.map(({ data: { stepId } }) => stepId);
    const groupStepIds = stepIds.filter((stepId) =>
      stepIds.find((step) => step.includes(`${stepId}-`))
    );
    const flatSteps = allSteps
      .filter(({ data: { stepId } }) => !groupStepIds.includes(stepId))
      .map(({ data }) => {
        const { stepId } = data;
        const [groupStepId, nestedStepIndex] = stepId.includes('-')
          ? stepId.split('-')
          : [stepId, 0];

        let title = '';
        if (groupStepId === stepId) {
          title = `${data.label}${data.attributeName ? ` - ${data.attributeName}` : ''}`;
        } else {
          const mainStepIndex = allSteps.findIndex(
            ({ data: { stepId: mainStepId } }) => mainStepId === groupStepId
          );
          const mainStepLabel = allSteps[mainStepIndex]?.data?.label;
          title = `${mainStepLabel.split('. ')[0]}.${Number(nestedStepIndex) + 1} ${
            mainStepLabel.split('. ')[1]
          } - ${data.attributeName}`;
        }

        return { data, title };
      });

    return flatSteps;
  }, [plan, listId]);

  const renderIcon = (Icon: ElementType, color: string) =>
    Icon && (
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          width: 22,
          height: 22,
          backgroundColor: applyThemeColorOpacity(color, 12),
          borderRadius: '6px'
        }}
        color={color}>
        <Icon />
      </Stack>
    );
  const workflowName = useSelector((state) =>
    getPostNameByThreadIdAndPostId(state, getThreadKey(listId), post?.postId)
  );

  const handleSubmitPlan = () => {
    dispatch(launchWorkflowAction(listId, plan, workflowName, post?.postId));
  };

  const onClickViewWorkflow = () => {
    setOpenWorkflow(true);
    dispatch(
      updateSelectedWorkflowIdByListId({
        listId,
        selectedWorkflowId: post?.postId,
        selectedType: WORKFLOW_SELECTED_TYPE.DRAFT
      })
    );
  };

  const creatingWorkflowMessageCompoment = () => (
    <Stack
      sx={{
        width: 'fit-content',
        padding: '8px',
        borderRadius: '8px',
        minWidth: '300px',
        gap: '8px'
      }}>
      <Typography>We are passing your request onto a more advanced agent...</Typography>
    </Stack>
  );

  if (isEmpty(stepsData)) {
    return creatingWorkflowMessageCompoment();
  }
  return (
    <Stack gap="16px">
      {creatingWorkflowMessageCompoment()}
      <Typography paddingX="8px" variant="text1_Normal">
        {currentlyRunningPost
          ? 'These are the details of your request:'
          : `Looks like your request needs ${stepsData.length} actions to complete:`}
      </Typography>
      {stepsData.map(({ data: { stepId, Icon, color, query }, title }) => (
        <Stack
          bgcolor="colors.gray_bg"
          borderRadius={'8px'}
          key={stepId}
          direction="column"
          alignItems="flex-start"
          padding="16px">
          <Stack direction="row" alignItems="center" width="100%" gap="8px" pb="8px">
            {renderIcon(Icon, color)}{' '}
            {title && (
              <Typography variant="text1_Semibold" textAlign="left" color={color}>
                {title}
              </Typography>
            )}
          </Stack>
          <Typography variant="text1_Normal" textAlign="left" color={'colors.primary_text'}>
            {typeof query === 'string' ? query : query?.user_question}
          </Typography>
        </Stack>
      ))}
      <MLInlineStack>
        <Button variant="outlined" onClick={onClickViewWorkflow}>
          {currentlyRunningPost ? 'View workflow' : 'View and edit the workflow'}
        </Button>
        <MLButton
          disabled={currentlyRunningPost}
          startIcon={currentlyRunningPost ? <CheckIcon2 /> : null}
          variant="contained"
          onClick={handleSubmitPlan}>
          {currentlyRunningPost ? 'Launched' : 'Launch workflow'}
        </MLButton>
      </MLInlineStack>

      {currentlyRunningPost && currentlyRunningPost.status === '100%' && (
        <Typography color="colors.primary_text">Workflow ran successfully</Typography>
      )}
      {currentlyRunningPost && currentlyRunningPost.status !== '100%' && (
        <Typography color="colors.primary">Running workflow...</Typography>
      )}

      <WorkflowsCenterDialog
        listId={listId}
        open={openWorkflow}
        onClose={closeWorkflowDialog}
        isWorflowRun={false}
      />
    </Stack>
  );
}

export default WorkflowPostDisplayComponent;
