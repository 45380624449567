import { Popover, Stack } from '@mui/material';
import { editDiscoveryCustomColumnColorAction } from 'actions/explore/edit-discovery-custom-column-color-action';
import MLInlineStack from 'design-system/MLInlineStack';
import YesNoIcon from 'icons/YesNoIcon';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getListCustomColumnColorByListIdAndColumnId } from 'selectors/explore';
import { appTheme } from 'theme';

function ColorPickerMenu({ listId, columnId, anchorEl, onClose }) {
  const dispatch = useDispatch();

  const selectedColor = useSelector((state) =>
    getListCustomColumnColorByListIdAndColumnId(state, listId, columnId)
  );

  const onColorChange = (color) => {
    dispatch(editDiscoveryCustomColumnColorAction(listId, columnId, color));
  };

  const avialableColora = [
    'colors.selected_negative_red',
    'accent_colors.positive_green_bg2',
    'accent_colors.poor_orange_bg2',
    'accent_colors.dark_lavender_bg2',
    'accent_colors.bright_blue_bg2',
    'accent_colors.bright_blue_bg1',
    'accent_colors.poor_orange_bg1'
  ];

  return (
    <Popover
      id="color-picker"
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      slotProps={{
        paper: {
          sx: { backgroundColor: 'colors.primary_bg' }
        }
      }}>
      <MLInlineStack sx={{ width: '152px', flexWrap: 'wrap', padding: '8px' }}>
        <Stack
          alignItems="center"
          justifyContent="center"
          onClick={() => onColorChange('')}
          sx={{
            boxSizing: 'border-box',
            width: '32px',
            height: '32px',
            borderRadius: '50%',
            border: '1px solid',
            borderColor: 'colors.ui_border',
            cursor: 'pointer'
          }}>
          <svg width="16" height="20" viewBox="0 0 16 20" fill="none">
            <path d="M15 1L1 19" stroke="#D7DCE4" strokeLinecap="round" />
          </svg>
        </Stack>
        {avialableColora.map((color) => (
          <Stack
            key={color}
            alignItems="center"
            justifyContent="center"
            onClick={() => onColorChange(color)}
            sx={{
              boxSizing: 'border-box',
              width: '32px',
              height: '32px',
              backgroundColor: color,
              borderRadius: '50%',
              border: `${selectedColor === color ? 2 : 1}px solid`,
              borderColor: selectedColor === color ? 'colors.primary' : 'colors.ui_border',
              cursor: 'pointer'
            }}>
            {selectedColor === color && <YesNoIcon color={appTheme.palette.colors.primary} />}
          </Stack>
        ))}
      </MLInlineStack>
    </Popover>
  );
}

ColorPickerMenu.propTypes = {
  listId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columnId: PropTypes.string,
  anchorEl: PropTypes.object,
  onClose: PropTypes.func
};

export default ColorPickerMenu;
