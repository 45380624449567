import { Typography, TypographyProps } from '@mui/material';
import React from 'react';

import MLAvatar from './MLAvatar';
import MLIcon from './MLIcon';
import MLIconButton from './MLIconButton';
import MLInlineStack from './MLInlineStack';
import MLButtonWrapper, { MLButtonWrapperProps } from './base/MLButtonWrapper';
import CloseIcon from './icons/platform/CloseIcon';

interface avatar {
  name: string;
  picture: string;
}

interface MLChipProps extends Omit<MLButtonWrapperProps, 'children'> {
  label: string;
  size?: 'small' | 'medium';
  variant?: 'regular' | 'negative' | 'disabled';
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  rightAvatar?: avatar;
  leftAvatar?: avatar;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  sx?: object;
  labelVariant?: TypographyProps['variant'];
}

export default function MLChip({
  label,
  leftIcon,
  rightIcon,
  leftAvatar,
  rightAvatar,
  sx,
  size = 'small',
  variant = 'regular',
  onClose,
  tooltipText,
  tooltipPlacement,
  tooltipArrow,
  labelVariant = 'text1_Normal'
}: MLChipProps) {
  const isDisabled = variant === 'disabled';
  const iconSize = 'small';
  const iconColor = isDisabled ? 'colors.disabled_text' : 'colors.icon';
  const avatarSize = 'small';
  const colorMap = {
    regular: 'colors.selected',
    negative: 'colors.selected_negative_red',
    disabled: 'accent_colors.dark_lavender_bg2'
  };

  const sizeMap = {
    small: {
      px: '8px',
      py: '2px'
    },
    medium: {
      px: '8px',
      py: '6px'
    }
  };
  return (
    <MLButtonWrapper
      tooltipText={tooltipText}
      tooltipPlacement={tooltipPlacement}
      tooltipArrow={tooltipArrow}>
      <MLInlineStack
        gap="4px"
        sx={{
          width: 'fit-content',
          borderRadius: '4px',
          backgroundColor: colorMap[variant],
          ...sizeMap[size],
          ...sx
        }}>
        {leftIcon && <MLIcon color={iconColor} icon={leftIcon} size={iconSize} />}
        {leftAvatar && (
          <MLAvatar
            disabled={isDisabled}
            size={avatarSize}
            name={leftAvatar.name}
            pictureUrl={leftAvatar.picture}
          />
        )}
        <Typography
          variant={labelVariant}
          color={isDisabled ? 'colors.disabled_text' : 'colors.text'}>
          {label}
        </Typography>
        {rightAvatar && (
          <MLAvatar
            disabled={isDisabled}
            size={avatarSize}
            name={rightAvatar.name}
            pictureUrl={rightAvatar.picture}
          />
        )}
        {rightIcon && <MLIcon color={iconColor} icon={rightIcon} size={iconSize} />}
        {onClose && (
          <MLIconButton
            sx={{ width: '16px', height: '16px' }}
            disabled={isDisabled}
            size={iconSize}
            onClick={onClose}>
            <CloseIcon />
          </MLIconButton>
        )}
      </MLInlineStack>
    </MLButtonWrapper>
  );
}
