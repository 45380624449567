import { Box, Stack, TextField, Typography } from '@mui/material';
import { updateQueryInPlanAction } from 'actions/custom-analytics/update-query-in-plan-action';
import WorkflowFormAddStepMenu from 'components/work-flow/WorkflowFormAddStepMenu';
import MLInlineStack from 'design-system/MLInlineStack';
import PropTypes from 'prop-types';
import React, { useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { appTheme } from 'theme';
import { getMeasuredTextHeight } from 'utils/string-utils';

import WorkflowStepDependencies from './WorkflowStepDependencies';

function WorkflowFromStepInfoComponent({ listId, workflowId, step, isReadOnly }) {
  const dispatch = useDispatch();
  const ref = useRef();

  const [addNewStepAnchorEl, setAddNewStepAnchorEl] = useState(null);

  const { data } = step;
  const { query, stepId } = data;

  const [isHover, setIshover] = useState(false);
  const stepQuery = query && typeof query === 'object' ? query.user_question : query;

  const container = ref.current;
  const inputMaxRows = useMemo(() => {
    if (!container) return;

    const { numberOfLines } = getMeasuredTextHeight(
      stepQuery,
      container.clientWidth,
      'text1_Normal'
    );
    return Math.min(4, numberOfLines);
  }, [stepQuery, container]);

  const onValueChange = (event) => {
    dispatch(
      updateQueryInPlanAction(listId, workflowId, stepId, { query: event.target.value || '' })
    );
  };

  const closeNewStepMenu = () => {
    setAddNewStepAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Box ref={ref}>
        <MLInlineStack
          onMouseEnter={() => setIshover(true)}
          onMouseLeave={() => setIshover(false)}
          justifyContent="space-between"
          sx={{ minHeight: '30px' }}>
          <Stack gap="8px" flex={1}>
            {(isHover || stepQuery?.length <= 0) && !isReadOnly ? (
              <TextField
                multiline
                value={stepQuery}
                onChange={onValueChange}
                placeholder="What this step should do?"
                maxRows={inputMaxRows}
                slotProps={{ input: { sx: { padding: '4px' } } }}
                sx={{
                  '.MuiInputBase-input': {
                    ...appTheme.typography.text1_Normal
                  }
                }}
              />
            ) : (
              <Typography
                variant="text1_Normal"
                color="colors.primary_text"
                sx={{
                  padding: '4px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: inputMaxRows,
                  WebkitBoxOrient: 'vertical'
                }}>
                {stepQuery}
              </Typography>
            )}
          </Stack>
          {!isReadOnly && (
            <WorkflowStepDependencies
              listId={listId}
              workflowId={workflowId}
              step={step}
              isVisible={true}
            />
          )}
        </MLInlineStack>
      </Box>
      <WorkflowFormAddStepMenu
        listId={listId}
        anchorEl={addNewStepAnchorEl}
        onClose={closeNewStepMenu}
        stepId={stepId}
      />
    </React.Fragment>
  );
}

WorkflowFromStepInfoComponent.propTypes = {
  listId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  workflowId: PropTypes.number,
  step: PropTypes.object,
  isReadOnly: PropTypes.object
};

export default WorkflowFromStepInfoComponent;
