import { Box, Button, Stack, Typography } from '@mui/material';
import { removeRecentlyFinishedRun } from 'actions/update-recently-finished-run';
import { TOAST_TYPES } from 'constants/toasts';
import MLIconButton from 'design-system/MLIconButton';
import CloseIcon from 'design-system/icons/platform/CloseIcon';
import { dispatch } from 'hooks/AppStateProvider';
import CheckIcon from 'icons/CheckIcon';
import { ToastIcons } from 'icons/ToastIcons';
import PropTypes from 'prop-types';

export default function FinishedRunningSearch({
  runNameRender,
  name,
  onSeeResults,
  runId,
  isFailed
}) {
  const removeRunFromState = () => dispatch(removeRecentlyFinishedRun(runId));

  const onClick = () => {
    onSeeResults();
    removeRunFromState();
  };

  return (
    <Stack color="colors.primary" direction="row" alignItems="center" gap="24px">
      {runNameRender || (
        <Typography variant="text1_Bold" color="colors.primary" textAlign="left" flex={1}>
          {name}
        </Typography>
      )}
      <Stack direction="row" alignItems="center">
        <Box width="24px" height="24px" display="flex" paddingRight="4px" alignItems="center">
          {isFailed ? <ToastIcons type={TOAST_TYPES.ERROR} size={20} /> : <CheckIcon />}
        </Box>
        <Box>
          <Typography noWrap variant="text1_Normal" color="colors.primary_text">
            Research {isFailed ? 'Failed' : 'Completed'}
          </Typography>
        </Box>

        {onSeeResults && (
          <Button sx={{ width: '100%' }} onClick={onClick}>
            <Typography noWrap variant="text1_Bold" color="colors.primary">
              See Results
            </Typography>
          </Button>
        )}
        <MLIconButton tooltipText="Close" onClick={removeRunFromState}>
          <CloseIcon />
        </MLIconButton>
      </Stack>
    </Stack>
  );
}

FinishedRunningSearch.propTypes = {
  runNameRender: PropTypes.element,
  runId: PropTypes.string,
  onSeeResults: PropTypes.func,
  name: PropTypes.string,
  isFailed: PropTypes.bool
};
