import { ListItemIcon, ListItemText, MenuItem, Stack, Typography } from '@mui/material';
import ExploreQueriesHistoryDialog from 'components/discovery/ExploreQueriesHistory';
import MLIconMenuButton from 'design-system/MLIconMenuButton';
import useMenuManagement from 'design-system/hooks/useMenuManagement';
import MenuIcon from 'design-system/icons/basic/MenuIcon';
import EraseIcon from 'design-system/icons/platform/EraseIcon';
import { useListContext } from 'hooks/ListContextProvider';
import HistoryIcon from 'icons/HistoryIcon';
import React from 'react';
import { startNewConversation } from 'reducer/assistant-slice';
import { getLatestListThread } from 'selectors/assistant';
import { useAppDispatch, useAppSelectorWithArgs } from 'types/store';

const itemSx = {
  minHeight: '32px',
  paddingRight: '12px',
  borderRadius: '4px',
  color: 'colors.primary_text'
};

export default function AssistantChatMenuButton() {
  const { listId } = useListContext();
  const [openHistory, setOpenHistory] = React.useState(false);
  const menuManagement = useMenuManagement<HTMLButtonElement>();
  const dispatch = useAppDispatch();
  const latestThread = useAppSelectorWithArgs(getLatestListThread, listId);
  const handleNewConversation = () => {
    if (listId && latestThread.messages.length > 0) {
      dispatch(startNewConversation({ company_list_id: listId, defaultMessage: true }));
    }
    menuManagement.onClose();
  };
  const handleOpenHistory = () => {
    setOpenHistory(true);
    menuManagement.onClose();
  };
  return (
    <MLIconMenuButton
      menuManagement={menuManagement}
      menuContent={
        <Stack>
          <MenuItem sx={itemSx} onClick={handleNewConversation}>
            <ListItemIcon>
              <EraseIcon />
            </ListItemIcon>
            <ListItemText>
              <Typography>Clear chat</Typography>
            </ListItemText>
          </MenuItem>
          <MenuItem onClick={handleOpenHistory} sx={itemSx}>
            <ListItemIcon>
              <HistoryIcon />
            </ListItemIcon>
            <ListItemText>
              <Typography>Query history</Typography>
            </ListItemText>
          </MenuItem>
        </Stack>
      }>
      <MenuIcon />
      {openHistory && (
        <ExploreQueriesHistoryDialog
          listId={listId}
          open={openHistory}
          onClose={() => setOpenHistory(false)}
        />
      )}
    </MLIconMenuButton>
  );
}
