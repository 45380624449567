import ColumnsIcon from 'icons/ColumnsIcon';
import FilterIcon from 'icons/FilterIcon';
import FlagIcon from 'icons/FlagIcon';
import PromptTextIcon from 'icons/PromptTextIcon';
import WorkflowDiscoveryIcon from 'icons/WorkflowDiscoveryIcon';

export const WORK_FLOW_STEP_TYPES = Object.freeze({
  main_prompt: {
    id: 'main_prompt',
    label: 'Main Prompt',
    Icon: FlagIcon,
    color: 'coding.coding6'
  },
  information: {
    id: 'information',
    label: 'Add Columns',
    Icon: ColumnsIcon,
    color: 'accent_colors.dark_lavender'
  },
  filter: {
    id: 'filter',
    label: 'Filter companies',
    Icon: FilterIcon,
    color: 'colors.poor_orange'
  },
  analysis: {
    id: 'analysis',
    label: 'Ask',
    Icon: PromptTextIcon,
    color: 'accent_colors.orchid'
  },
  discovery: {
    id: 'discovery',
    Icon: WorkflowDiscoveryIcon,
    label: 'Find companies',
    color: 'accent_colors.bright_blue'
  },
  add_column_from_library: {
    id: 'add_column_from_library',
    label: 'Add Column From Library',
    Icon: ColumnsIcon,
    color: 'accent_colors.dark_lavender'
  }
});

export const WORKFLOW_STATUSES = Object.freeze({
  CREATING_FROM_LIST: 'creating_from_list',
  GENERATING: 'generating',
  GENERATE_FAILED: 'generate_failed',
  GENERATED: 'generated',
  LAUNCHING: 'launching',
  LAUNCH_FAILED: 'launch_failed',
  LAUNCHED: 'launched',
  STOPING: 'stoping',
  STOP_FAILED: 'stop_failed',
  STOPED: 'stoped',
  RUN_FAILED: 'run_failed',
  RUN_FINISHED: 'run_finished',
  HISTORY_SUCCEED: 'history_succeed',
  HISTORY_FAILED: 'history_failed'
});

export const WORKFLOW_SELECTED_TYPE = {
  DRAFT: 'draft',
  HISTORY: 'history',
  LIBRARY_READONLY: 'library_readonly',
  LIBRARY_EDIT: 'library_edit'
};
