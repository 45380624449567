import React from 'react';

export default function PitchbookIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3491 3.11613C9.53562 4.01873 4.44733 6.83727 4.02366 13.2733C5.10538 12.2415 6.92138 11.001 9.01506 11.0252C9.13929 8.10943 10.3434 5.66177 13.3491 3.11613ZM14.7617 2.48663C14.8955 2.79743 14.826 3.18845 14.5087 3.44143C11.2556 6.03549 10.1092 8.37633 10.018 11.173C10.0019 11.6657 9.56341 12.0162 9.09149 12.0006C7.26034 11.9404 5.56917 13.1158 4.56494 14.1297C4.28317 14.4142 3.88462 14.4446 3.58845 14.3425C3.28747 14.2386 2.98227 13.9541 3.00076 13.5253C3.33297 5.81998 9.72703 2.77963 13.842 2.01574C14.2695 1.9364 14.6239 2.16663 14.7617 2.48663ZM16.8902 4.98114C13.7276 7.97214 13.3474 11.9266 13.8206 14.4487C10.0983 14.7999 8.11428 15.9436 7.12485 16.9896C7.11254 17.0026 7.09745 17.0126 7.08147 17.019C7.07583 17.0212 7.07059 17.0229 7.06588 17.024C6.87401 16.6112 6.66147 16.0566 6.50495 15.4077C8.166 14.0532 9.45695 13.8109 10.208 13.8203C10.5782 13.825 10.9518 13.6991 11.2447 13.4934C11.5372 13.288 11.7984 12.9658 11.8458 12.5506C12.1565 9.83072 12.8985 8.06666 13.6979 6.89631C14.3797 5.89796 15.5646 5.3298 16.8902 4.98114ZM17.8931 4.48166C18.0314 4.75751 18.0888 5.22459 17.7281 5.55349C14.6942 8.31973 14.3612 12.07 14.8363 14.3977C14.9354 14.8832 14.5814 15.3591 14.0585 15.4049C10.4136 15.7244 8.6552 16.815 7.86887 17.6463C7.64137 17.8868 7.31715 18.0131 6.99926 17.9989C6.66914 17.9843 6.32971 17.8125 6.16301 17.4582C5.93344 16.9702 5.67843 16.3063 5.49923 15.526C5.43078 15.228 5.54272 14.9222 5.77546 14.7291C7.63575 13.1856 9.18609 12.8339 10.2211 12.8469C10.356 12.8485 10.5199 12.7988 10.6521 12.706C10.7846 12.6129 10.8361 12.5138 10.8441 12.4439C11.169 9.59939 11.9541 7.68154 12.8566 6.36017C13.7897 4.99406 15.3829 4.33916 16.8789 3.97789C17.3946 3.85338 17.7555 4.20709 17.8931 4.48166Z"
        fill="currentColor"
      />
      <path
        d="M4.56494 14.1297C5.56917 13.1158 7.26034 11.9404 9.09149 12.0006C9.56341 12.0162 10.0019 11.6657 10.018 11.173C10.1092 8.37633 11.2556 6.03549 14.5087 3.44143C14.826 3.18845 14.8955 2.79743 14.7617 2.48663C14.6239 2.16663 14.2695 1.9364 13.842 2.01574C9.72703 2.77963 3.33297 5.81998 3.00076 13.5253C2.98227 13.9541 3.28747 14.2386 3.58845 14.3425C3.88462 14.4446 4.28317 14.4142 4.56494 14.1297ZM4.56494 14.1297L4.20082 13.7932M4.02366 13.2733C4.44733 6.83727 9.53562 4.01873 13.3491 3.11613C10.3434 5.66177 9.13929 8.10943 9.01506 11.0252C6.92138 11.001 5.10538 12.2415 4.02366 13.2733ZM16.8902 4.98114C13.7276 7.97214 13.3474 11.9266 13.8206 14.4487C10.0983 14.7999 8.11428 15.9436 7.12485 16.9896C7.11254 17.0026 7.09745 17.0126 7.08147 17.019C7.07583 17.0212 7.07059 17.0229 7.06588 17.024C6.87401 16.6112 6.66147 16.0566 6.50495 15.4077C8.166 14.0532 9.45695 13.8109 10.208 13.8203C10.5782 13.825 10.9518 13.6991 11.2447 13.4934C11.5372 13.288 11.7984 12.9658 11.8458 12.5506C12.1565 9.83072 12.8985 8.06666 13.6979 6.89631C14.3797 5.89796 15.5646 5.3298 16.8902 4.98114ZM17.7281 5.55349C18.0888 5.22459 18.0314 4.75751 17.8931 4.48166C17.7555 4.20709 17.3945 3.85338 16.8789 3.97789C15.3829 4.33916 13.7897 4.99406 12.8566 6.36017C11.9541 7.68154 11.169 9.59939 10.8441 12.4439C10.8361 12.5138 10.7846 12.6129 10.6521 12.706C10.5199 12.7988 10.356 12.8485 10.2211 12.8469C9.18609 12.8339 7.63575 13.1856 5.77546 14.7291C5.54272 14.9222 5.43078 15.228 5.49923 15.526C5.67843 16.3063 5.93344 16.9702 6.16301 17.4582C6.32971 17.8125 6.66914 17.9843 6.99926 17.9989C7.31715 18.0131 7.64137 17.8868 7.86887 17.6463C8.6552 16.815 10.4136 15.7244 14.0585 15.4049C14.5814 15.3591 14.9354 14.8832 14.8363 14.3977C14.3612 12.07 14.6942 8.31973 17.7281 5.55349Z"
        stroke="currentColor"
        strokeWidth="0.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
