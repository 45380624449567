import { Link } from '@mui/material';
import GoToIcon from 'icons/GoToIcon';
import React from 'react';

type Props = {
  link: string;
};

export default function LinkToFinanceSourceButton({ link }: Props) {
  return (
    <Link
      minHeight={0}
      href={link}
      underline="none"
      target="_blank"
      rel="noopener"
      color="colors.text_on_primary"
      padding="2px">
      <GoToIcon />
    </Link>
  );
}
