import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import React from 'react';

interface DeleteColumnDialogProps {
  open: boolean;
  onClose: () => void;
  columnName: string;
  onDelete: () => void;
  deleteColumnData?: Record<string, string>;
}

export function DeleteColumnDialog({
  open,
  onClose,
  columnName,
  onDelete
}: DeleteColumnDialogProps) {
  const handleDelete = () => {
    onDelete();
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">Delete column &quot;{columnName}&quot;</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete column &quot;{columnName}&quot;?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleDelete} autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
