import React from 'react';

export default function TableIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.61833 4.61833C5.01424 4.22242 5.55121 4 6.11111 4H17.8608C18.4207 4 18.9576 4.22242 19.3536 4.61833C19.7495 5.01424 19.9719 5.55121 19.9719 6.11111V17.8608C19.9719 18.4207 19.7495 18.9576 19.3536 19.3536C18.9576 19.7495 18.4207 19.9719 17.8608 19.9719H6.11111C5.55121 19.9719 5.01424 19.7495 4.61833 19.3536C4.22242 18.9576 4 18.4207 4 17.8608V6.11111C4 5.55121 4.22242 5.01424 4.61833 4.61833ZM6.11111 5C5.81643 5 5.53381 5.11706 5.32544 5.32544C5.11706 5.53381 5 5.81643 5 6.11111V8H8.5V5H6.11111ZM9.5 5V8H18.9719V6.11111C18.9719 5.81643 18.8548 5.53381 18.6464 5.32544C18.4381 5.11706 18.1555 5 17.8608 5H9.5ZM18.9719 9H9.5V18.9719H17.8608C18.1555 18.9719 18.4381 18.8548 18.6464 18.6464C18.8548 18.4381 18.9719 18.1555 18.9719 17.8608V9ZM8.5 18.9719V9H5V17.8608C5 18.1555 5.11706 18.4381 5.32544 18.6464C5.53381 18.8548 5.81643 18.9719 6.11111 18.9719H8.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
