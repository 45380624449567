import { Avatar, DialogTitle, Stack, Typography } from '@mui/material';
import MLIconButton from 'design-system/MLIconButton';
import PlayIcon from 'icons/PlayIcon';
import React from 'react';
import { formatTimestamp } from 'utils/time-utils';

type Props = {
  companyLogo?: string;
  companyName: string;
  columnName: string;
  onRerun?: () => void;
  isCellEmpty: boolean;
  timestamp?: string;
};

export default function RenderCustomCellDialogTitle({
  companyLogo,
  companyName,
  columnName,
  onRerun,
  isCellEmpty,
  timestamp
}: Props) {
  return (
    <DialogTitle id="alert-dialog-title" sx={{ display: 'inline-flex', flexDirection: 'column' }}>
      <Stack direction="row" alignItems="center" gap="8px">
        {companyLogo && (
          <Avatar src={companyLogo} sx={{ width: 24, height: 24 }} variant="rounded" />
        )}
        <Typography variant="h4" color="colors.primary_text">
          {`${companyName} / ${columnName}`}
        </Typography>
        {onRerun && (
          <MLIconButton tooltipText={isCellEmpty ? 'Run Query' : 'Rerun Query'} onClick={onRerun}>
            <PlayIcon />
          </MLIconButton>
        )}
        {timestamp && (
          <Typography variant="text1_Normal" color="colors.primary_text">
            {formatTimestamp(timestamp)}
          </Typography>
        )}
      </Stack>
    </DialogTitle>
  );
}
