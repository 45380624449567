import { Stack } from '@mui/material';
import MLIconButton from 'design-system/MLIconButton';
import MLInlineStack from 'design-system/MLInlineStack';
import MLResizableCustomTextComponent from 'design-system/MLResizableCustomTextComponent';
import AddSmallIcon from 'design-system/icons/basic/AddSmallIcon';
import ExpandIcon from 'design-system/icons/platform/ExpandIcon';
import { MLTooltipProvider } from 'hooks/useMLTooltip';
import PlayIcon from 'icons/PlayIcon';
import React from 'react';

interface RenderCustomTextCellProps {
  isHoverCell: boolean;
  cellValue: string;
  onRerun?: () => void;
  onCellClick: () => void;
  renderFullText?: string;
}

export default function RenderCustomTextCell({
  isHoverCell,
  cellValue,
  onRerun,
  onCellClick,
  renderFullText
}: RenderCustomTextCellProps) {
  return (
    <MLInlineStack
      width="100%"
      gap="0px"
      onClick={onCellClick}
      sx={{ cursor: 'pointer', minHeight: '20px' }}>
      {!cellValue && onRerun && isHoverCell && (
        <MLIconButton tooltipText={cellValue ? 'Rerun Query' : 'Run Query'} onClick={onRerun}>
          <PlayIcon />
        </MLIconButton>
      )}
      {cellValue && (
        <MLTooltipProvider>
          <MLResizableCustomTextComponent text={cellValue} tooltip={renderFullText} />
        </MLTooltipProvider>
      )}
      {cellValue && isHoverCell && (
        <MLIconButton sx={{ marginLeft: 'auto' }} onClick={onCellClick} tooltipText={'Show more'}>
          <ExpandIcon />
        </MLIconButton>
      )}
      {!cellValue && isHoverCell && (
        <Stack
          border="1px solid"
          borderColor={'colors.ui_border'}
          borderRadius="4px"
          width="100%"
          height={'24px'}
          alignItems={'center'}
          justifyContent={'center'}
          color={'colors.placeholder_text'}
          sx={{ cursor: 'pointer' }}>
          <AddSmallIcon />
        </Stack>
      )}
    </MLInlineStack>
  );
}
