import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { AssistantOutput } from 'components/custom-analytics/types/CustomAnalyticsResponse';
import React, { useMemo } from 'react';

import CustomAnalyticsAnswerComponent from '../CustomAnalyticsAnswerComponent';
import SingleCompanyAnswerTitle from './SingleCompanyAnswerTitle';

type Company = {
  companyName: string;
  companyLogo: string;
  answer?: React.ReactElement | null;
  isLoading: boolean;
  id: number;
  message?: AssistantOutput;
};

type Props = {
  possibleCompanies: Company[];
  setSelectedCompanyId: (id: number | null) => void;
  selectedCompanyId: number | null;
  listId: number;
};

export default function SingleCompanyAnswerDialog({
  possibleCompanies,
  setSelectedCompanyId,
  selectedCompanyId,
  listId
}: Props) {
  const minRowId = possibleCompanies?.[0]?.id;
  const maxRowId = possibleCompanies?.[possibleCompanies?.length - 1]?.id;

  const onUp = () => {
    if (selectedCompanyId === minRowId || !selectedCompanyId) return;
    const indexOfCurrentId = possibleCompanies.findIndex((row) => row.id === selectedCompanyId);
    const newId = possibleCompanies[indexOfCurrentId - 1].id;
    setSelectedCompanyId(newId);
  };

  const onDown = () => {
    if (selectedCompanyId === maxRowId) return;
    const indexOfCurrentId = possibleCompanies.findIndex((row) => row.id === selectedCompanyId);
    const newId = possibleCompanies[indexOfCurrentId + 1].id;
    setSelectedCompanyId(newId);
  };

  const selectedCompany = useMemo(() => {
    return possibleCompanies?.find((row) => row.id === selectedCompanyId);
  }, [selectedCompanyId, possibleCompanies]);

  const onClose = () => setSelectedCompanyId(null);
  return (
    <Dialog
      open={selectedCompanyId !== null}
      onClose={onClose}
      PaperProps={{
        sx: { minWidth: '700px', maxWidth: '700px', borderRadius: '8px' }
      }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: 'colors.primary_text',
            opacity: '0.5 !important'
          }
        }
      }}>
      <DialogTitle>
        {selectedCompany && (
          <SingleCompanyAnswerTitle
            onClose={onClose}
            logoUrl={selectedCompany.companyLogo}
            name={selectedCompany.companyName}
            onUp={onUp}
            onDown={onDown}
          />
        )}
      </DialogTitle>
      <DialogContent sx={{ height: '450px' }}>
        {selectedCompany && selectedCompany.message && (
          <CustomAnalyticsAnswerComponent
            message={selectedCompany.message}
            listId={listId}
            defaultOpenAll={true}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}
