import { Stack, Typography } from '@mui/material';
import { loadDomainReportDefaultConfigAction } from 'actions/domain_reports/load-domain-report-default-config';
import { DomainReportSection, DomainReportSectionType } from 'models/domain-report/types';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { getDomainReportDefaultConfig } from 'selectors/domain-reports';
import { getCompanyListNameByListId } from 'selectors/explore';
import NewDomainReportGraphic from 'svg/NewDomainReportGraphic';
import { useAppDispatch } from 'types/store';
import { getDocumentFileName } from 'utils/files';
import { generateDomainReportPowerpoint } from 'utils/react-to-powerpoint-utils';

import DomainReportButton from './DomainReportButton';
import { useDomainReportContext } from './DomainReportContext';
import DomainReportHeader from './DomainReportHeader';
import DomainReportCluster from './cluster/DomainReportCluster';
import DomainReportDomainKnowledge from './domain-knowledge/DomainReportDomainKnowledge';
import DomainReportEditorSidebar from './editor/DomainReportEditorSidebar';
import DomainReportMarket from './market/DomainReportMarket';
import DomainReportProcess from './process/DomainReportProcess';
import DomainReportTopProblems from './top-problems/DomainReportTopProblems';

export const DOMAIN_REPORT_PANEL_ID = 'domain-report-panel';

interface DomainReportTabProps {
  listId: number;
}

const domainReportCopmonentMap: Record<DomainReportSectionType, React.ComponentType<any>> = {
  [DomainReportSectionType.EXPLORE_PROCESS]: DomainReportProcess,
  [DomainReportSectionType.MARKET_LANDSCAPE]: DomainReportMarket,
  [DomainReportSectionType.TOP_PROBLEMS_BEING_SOLVED]: DomainReportTopProblems,
  [DomainReportSectionType.CLUSTER_ANALYSIS]: DomainReportCluster,
  [DomainReportSectionType.DOMAIN_KNOWLEDGE]: DomainReportDomainKnowledge
};

function DomainReportTab({ listId }: DomainReportTabProps) {
  const [exporting, setExporting] = useState(false);
  const { domainReport, sections, status } = useDomainReportContext();
  const name = useSelector((state) => getCompanyListNameByListId(state, listId));
  const [editOpen, setEditOpen] = useState(false);

  const defaultConfig = useSelector(getDomainReportDefaultConfig);
  const dispatch = useAppDispatch();
  const contentRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({
    contentRef,
    documentTitle: getDocumentFileName(name, 'report')
  });
  const toPDF = () => {
    setExporting(true);
    setTimeout(() => {
      reactToPrintFn();
      setExporting(false);
    }, 2000);
  };

  const generatePowerPoint = async () => {
    const pptx = await generateDomainReportPowerpoint(contentRef.current);
    pptx
      ?.writeFile({ fileName: getDocumentFileName(name, 'domanin_report_presentation') })
      .then(() => {
        setExporting(false);
      });
  };

  const getDomainReportSectionComponent = (section: DomainReportSection) => {
    const Component = domainReportCopmonentMap[section.props.type];
    return <Component key={section.props.id} section={section} />;
  };
  useEffect(() => {
    if (!defaultConfig) {
      dispatch(loadDomainReportDefaultConfigAction());
    }
  }, [dispatch, defaultConfig]);

  if (!domainReport) {
    return (
      <Stack gap="12px" sx={{ marginTop: '100px', paddingBottom: '32px', marginX: 'auto' }}>
        <Stack gap="16px" justifyContent="center" alignItems="center">
          <NewDomainReportGraphic />
          <Typography variant="h3_Bold" color="colors.primary_text">
            Discover domain insights
          </Typography>
          <Typography
            variant="text1_Normal"
            color="colors.primary_text"
            width="415px"
            sx={{ marginBottom: '16px' }}>
            Start by creating your first report, and update it anytime based on the latest data in
            the list.
          </Typography>
          <DomainReportButton listId={listId} status={status} />
        </Stack>
      </Stack>
    );
  }
  return (
    <React.Fragment>
      <Stack
        gap="12px"
        sx={{
          marginTop: '16px',
          paddingBottom: '32px',
          maxWidth: '802px',
          marginX: 'auto'
        }}
        textAlign="left">
        <DomainReportHeader
          listId={listId}
          toPDF={toPDF}
          toPowerPoint={generatePowerPoint}
          exporting={exporting}
          openEdit={() => setEditOpen(true)}
        />
        <Stack gap="12px" ref={contentRef}>
          {sections.map((section) => getDomainReportSectionComponent(section))}
        </Stack>
      </Stack>
      <DomainReportEditorSidebar open={editOpen} setOpen={setEditOpen} />
    </React.Fragment>
  );
}

export default DomainReportTab;
