import { editDiscoveryCustomColumnInfoAction } from 'actions/explore/edit-discovery-custom-column-info-action';
import SaveQueryDialog from 'components/discovery/SaveQueryDialog';
import MLIconButton from 'design-system/MLIconButton';
import MLInlineStack from 'design-system/MLInlineStack';
import CopyToChatIcon from 'design-system/icons/platform/CopyToChatIcon';
import { ActionType, AssistantAction } from 'models/assistant/assistant-actions';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { updateActionParams } from 'reducer/assistant-slice';
import { getThreadKey } from 'reducer/custom-analytics';
import {
  getCustomAnalyticDataTypeByPostIdMap,
  getCustomAnalyticsThreadSpecificPostById
} from 'selectors/custom-analytics';
import { getListCustomColumnNameByListIdAndColumnId } from 'selectors/explore';
import { getIsSavedQueryPredefined } from 'selectors/saved-queries';
import { useAppDispatch } from 'types/store';

import ActionPromptDetailsComponent from './ActionPromptDetailsComponent';
import usePromptFunctions from './useActionFunctions';

interface ActionDisplayActionButtonsProps {
  listId: number;
  action: AssistantAction;
  threadId?: string;
}

function ActionDisplayActionButtons({ listId, action, threadId }: ActionDisplayActionButtonsProps) {
  const dispatch = useAppDispatch();
  const [isSaved, setIsSaved] = useState(false);

  const canSaveQuery = [
    ActionType.ADD_COLUMN,
    ActionType.WORKFLOW,
    ActionType.ASK_QUESTION
  ].includes(action.action_type);
  const postId = action.submission_info?.post_id;

  const { handleCopyClick } = usePromptFunctions(action);

  const post = useSelector((state) =>
    getCustomAnalyticsThreadSpecificPostById(state, getThreadKey(listId), postId)
  );
  const dataType = useSelector((state) => getCustomAnalyticDataTypeByPostIdMap(state, postId));
  const columnName = useSelector((state) =>
    getListCustomColumnNameByListIdAndColumnId(state, listId, dataType)
  );

  const isSavedQueryPredefined = useSelector((state) =>
    getIsSavedQueryPredefined(state, action.saved_query_id)
  );
  const updateActionSavedQueryId = (queryIdToSave: number | null) => {
    if (!threadId) {
      return;
    }
    const newAction = { ...action, saved_query_id: queryIdToSave };
    dispatch(updateActionParams({ threadId, actionId: action.id, newAction }));
  };

  const onSaveQuery = (queryIdToSave: number) => {
    updateActionSavedQueryId(queryIdToSave);
    if (dataType) {
      dispatch(
        editDiscoveryCustomColumnInfoAction(
          listId,
          dataType,
          { saved_query_id: queryIdToSave },
          true
        )
      );
    }
  };

  return (
    <MLInlineStack gap={0}>
      <ActionPromptDetailsComponent action={action} />
      {canSaveQuery && !isSavedQueryPredefined && post?.body && (
        <SaveQueryDialog
          prompt={{ ...post.body, saved_query_id: action.saved_query_id }}
          isSaved={isSaved}
          setIsSaved={setIsSaved}
          existColumnName={columnName}
          onSaveQuery={onSaveQuery}
          listId={listId}
          isWorkflow={false}
          showIsBookmarked
        />
      )}
      <MLIconButton
        color="primary"
        onClick={handleCopyClick}
        sx={{ padding: 0 }}
        tooltipText="Use as starting point for new question">
        <CopyToChatIcon />
      </MLIconButton>
    </MLInlineStack>
  );
}

export default ActionDisplayActionButtons;
