import { Button, Divider, Tab, Tabs } from '@mui/material';
import { useAppUtils } from 'AppUtilsProvider';
import { loadDiscoveryWorkflowsHistoryAction } from 'actions/explore/load-discovery-workflows-history-action';
import { updateSavedQueryAction } from 'actions/saved_queries/update-saved-query-action';
import { launchWorkflowAction } from 'actions/workflows/launch-workflow-action';
import { renameWorkflowAction } from 'actions/workflows/rename-workflow-action';
import { saveNewWorkflowAction } from 'actions/workflows/save-new-workflow-action';
import { stopWorkflowAction } from 'actions/workflows/stop-workflow-action';
import ModalComponent from 'components/modal/ModalComponent';
import DuplicateLibraryWorkflowComponent from 'components/work-flow/DuplicateLibraryWorkflowComponent';
import { WORKFLOW_SELECTED_TYPE } from 'constants/workflows';
import MLButton from 'design-system/MLButton';
import MLIconButton from 'design-system/MLIconButton';
import MLInlineStack from 'design-system/MLInlineStack';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import BookmarkIcon from 'icons/BookmarkIcon';
import CodeIcon from 'icons/CodeIcon';
import { WorkflowIcon } from 'icons/WorkflowIcon';
import { omit } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getThreadKey } from 'reducer/custom-analytics';
import {
  updateSelectedWorkflowIdByListId,
  updateWorkflowFormModeByIdAndListId,
  updateWorkflowHistoryModeByIdAndListId
} from 'reducer/workflows-slice';
import {
  getCurrentlyRunningPostForWorkflowFromOriginalWorkflowIdInList,
  getCustomAnalyticsPlanByThreadAndPostId,
  getCustomAnalyticsThreadSpecificPostById,
  getIsPostWorkflowRunByThreadIdAndPostId,
  getPostNameByThreadIdAndPostId
} from 'selectors/custom-analytics';
import { getUserIsDev } from 'selectors/user';
import {
  getIsWorkflowReadOnlyModeByListId,
  getWorkflowHistoryNameByListIdAndWorkflowId,
  getWorkflowHistoryPlanByListIdAndWorkflowId,
  getWorkflowIsHistoryModeByListId,
  getWorkflowModeByListIdAndWorkflowId,
  getWorkflowSelectedPostIdByListId,
  getWorkflowSelectedTypeByListId
} from 'selectors/workflows';

import AllWorkflowsDialogStep from './AllWorkflowsDialogStep';
import ForgotSaveWorkflowDialog from './ForgotSaveWorkflowDialog';
import LaunchWorkflowButton from './LaunchWorkflowButton';
import WorkflowPlannerDialogStep from './WorkflowPlannerDialogStep';
import WorkflowsTemplateDialogStep from './WorkflowsTemplateDialogStep';

/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
function WorkflowsCenterDialog({ open, listId, onClose, isWorflowRun }) {
  const dispatch = useDispatch();
  const { addToast } = useAppUtils();
  const navigate = useNavigate();

  const [isJsonViewOpen, setIsJsonViewOpen] = useState(false);
  const [isSaveChangesModalOpen, setIsSaveChangesModalOpen] = useState(false);
  const [shouldExit, setShouldExit] = useState(false);
  const [originalPlan, setOriginalPlan] = useState(null);

  const threadKey = getThreadKey(listId);
  const isDev = useOldStateSelector(getUserIsDev);
  const selectedWorkflowId = useSelector((state) =>
    getWorkflowSelectedPostIdByListId(state, listId)
  );
  const selectedWorkflowType = useSelector((state) =>
    getWorkflowSelectedTypeByListId(state, listId)
  );
  const isReadOnly = useSelector((state) => getIsWorkflowReadOnlyModeByListId(state, listId));
  const plan = useSelector((state) =>
    getCustomAnalyticsPlanByThreadAndPostId(state, threadKey, selectedWorkflowId)
  );
  const historyPlan = useSelector((state) =>
    getWorkflowHistoryPlanByListIdAndWorkflowId(state, listId, selectedWorkflowId)
  );
  const isFormMode = useSelector((state) =>
    getWorkflowModeByListIdAndWorkflowId(state, listId, selectedWorkflowId)
  );
  const isWorkflowRun = useSelector((state) =>
    getIsPostWorkflowRunByThreadIdAndPostId(state, threadKey, selectedWorkflowId)
  );
  const post = useSelector((state) => {
    return isWorkflowRun
      ? getCustomAnalyticsThreadSpecificPostById(state, threadKey, selectedWorkflowId)
      : getCurrentlyRunningPostForWorkflowFromOriginalWorkflowIdInList(
          state,
          getThreadKey(listId),
          selectedWorkflowId
        );
  });
  const isHistoryStep = useSelector((state) =>
    getWorkflowIsHistoryModeByListId(state, listId, selectedWorkflowId)
  );
  const actualPostId = post?.postId;
  const currentStepId = post?.currentStepId;
  const isRunning = currentStepId && currentStepId !== 'end';

  useEffect(() => {
    if (listId && open) {
      dispatch(loadDiscoveryWorkflowsHistoryAction(listId));
    }
  }, [open, listId, dispatch]);

  useEffect(() => {
    setOriginalPlan((prevOriginalPlan) => {
      if (!selectedWorkflowId && prevOriginalPlan) {
        return null;
      }
      if (selectedWorkflowId && !prevOriginalPlan?.steps) {
        return cloneDeep(plan);
      }
      return prevOriginalPlan;
    });
  }, [selectedWorkflowId, plan]);

  const hasChanges = useMemo(() => {
    if (!plan || !originalPlan) return false;
    return !isEqual(omit(plan, ['id']), omit(originalPlan, ['id']));
  }, [plan, originalPlan]);

  const workflowName = useSelector((state) =>
    getPostNameByThreadIdAndPostId(state, getThreadKey(listId), selectedWorkflowId)
  );
  const workflowHistoryName = useSelector((state) =>
    getWorkflowHistoryNameByListIdAndWorkflowId(state, listId, selectedWorkflowId)
  );

  const goToAllWorkflowsStep = useCallback(() => {
    dispatch(updateWorkflowHistoryModeByIdAndListId({ listId, isHistoryMode: false }));
    dispatch(
      updateSelectedWorkflowIdByListId({ listId, selectedWorkflowId: null, selectedType: null })
    );
    setIsJsonViewOpen(false);
  }, [listId, dispatch]);

  const handleSubmitPlan = () => {
    if (plan) {
      setOriginalPlan(cloneDeep(plan));
    }
    dispatch(
      launchWorkflowAction(
        listId,
        plan || historyPlan,
        workflowName,
        selectedWorkflowId,
        false,
        null,
        navigate
      )
    );
  };

  const onStopWorkflow = () => {
    const runId = post?.recordId;
    if (!runId) {
      return;
    }
    dispatch(stopWorkflowAction(runId, listId, actualPostId));
  };

  const handleSaveWorkflow = () => {
    dispatch(
      saveNewWorkflowAction(listId, plan, workflowName, addToast, (savedQuery) => {
        setOriginalPlan(cloneDeep(savedQuery.parameters));
      })
    );
  };

  const handleSaveChanges = () => {
    dispatch(
      updateSavedQueryAction(selectedWorkflowId, plan, addToast, () =>
        setOriginalPlan(cloneDeep(plan))
      )
    );
  };

  const onOpenJsonView = () => {
    setIsJsonViewOpen(true);
  };

  const closeDialog = useCallback(() => {
    if (hasChanges) {
      setIsSaveChangesModalOpen(true);
      setShouldExit(true);
    } else {
      goToAllWorkflowsStep();
      onClose();
    }
  }, [hasChanges, goToAllWorkflowsStep, onClose]);

  const onBackClick = () => {
    if (hasChanges) {
      setIsSaveChangesModalOpen(true);
      setShouldExit(false);
    } else {
      goToAllWorkflowsStep();
    }
  };

  const closeForgotToSave = (avoidGoToAllWorkflowsStep) => {
    setIsSaveChangesModalOpen(false);
    if (!avoidGoToAllWorkflowsStep) {
      goToAllWorkflowsStep();
    }
    if (shouldExit && !avoidGoToAllWorkflowsStep) {
      onClose();
    }
  };

  const toggleEditMode = () => {
    dispatch(
      updateWorkflowFormModeByIdAndListId({
        listId,
        workflowId: selectedWorkflowId,
        isFormMode: !isFormMode
      })
    );
  };

  const isPlannerStep = Boolean(selectedWorkflowId);

  const onRenamePlanName = (newPlanName) => {
    dispatch(
      renameWorkflowAction(
        listId,
        selectedWorkflowId,
        newPlanName,
        selectedWorkflowType === WORKFLOW_SELECTED_TYPE.LIBRARY_EDIT
      )
    );
  };

  const onLibraryModeChange = (_event, newValue) => {
    dispatch(
      updateWorkflowHistoryModeByIdAndListId({
        listId,
        isHistoryMode: newValue === 1
      })
    );
  };

  let title = 'Workflows hub';
  if (isPlannerStep) {
    title = workflowName || workflowHistoryName || 'Workflow';
  }

  return (
    <React.Fragment>
      <ModalComponent
        title={title}
        titleIcon={isPlannerStep ? null : <WorkflowIcon />}
        titleTag={
          isReadOnly
            ? {
                tag: 'View only',
                tooltip: `${
                  historyPlan ? 'Launched' : 'Shared'
                } workflows are view-only. To edit this workflow, duplicate it first.`
              }
            : null
        }
        open={open}
        maxWidth={1600}
        height="90%"
        containerStyle={{ width: '80%', display: 'flex' }}
        contentStyle={{ width: '100%', height: '90%' }}
        onClose={closeDialog}
        onBack={isPlannerStep ? onBackClick : null}
        allowEditTitle={isPlannerStep && !isReadOnly}
        onTitleChange={onRenamePlanName}
        topBarMiddleActions={
          !isPlannerStep && (
            <Tabs
              value={isHistoryStep ? 1 : 0}
              onChange={onLibraryModeChange}
              textColor="secondary">
              <Tab label="Workflows" color="primary" />
              <Tab label="Launch history" color="primary" />
            </Tabs>
          )
        }
        topBarActions={
          (plan?.steps || historyPlan?.steps) && (
            <MLInlineStack>
              <MLButton variant="outlined" onClick={toggleEditMode} sx={{ whiteSpace: 'nowrap' }}>
                {isFormMode ? 'Show Graph' : 'Show Form'}
              </MLButton>
              <Divider orientation="vertical" flexItem />
              {isDev && (
                <MLIconButton
                  size="small"
                  sx={{ backgroundColor: 'colors.selected', borderRadius: '4px', padding: '4px' }}
                  onClick={onOpenJsonView}>
                  <CodeIcon />
                </MLIconButton>
              )}
              {originalPlan && selectedWorkflowType === WORKFLOW_SELECTED_TYPE.LIBRARY_EDIT && (
                <Button
                  variant="outlined"
                  onClick={handleSaveChanges}
                  disabled={!hasChanges}
                  sx={{ whiteSpace: 'nowrap' }}>
                  Save Changes
                </Button>
              )}
              {selectedWorkflowType === WORKFLOW_SELECTED_TYPE.DRAFT && (
                <Button
                  variant="outlined"
                  onClick={handleSaveWorkflow}
                  disabled={!hasChanges}
                  startIcon={<BookmarkIcon />}
                  sx={{ whiteSpace: 'nowrap' }}>
                  Save Workflow
                </Button>
              )}
              {(selectedWorkflowType === WORKFLOW_SELECTED_TYPE.HISTORY ||
                selectedWorkflowType === WORKFLOW_SELECTED_TYPE.LIBRARY_READONLY) && (
                <DuplicateLibraryWorkflowComponent
                  listId={listId}
                  workflow={plan || historyPlan}
                  workflowName={workflowName || workflowHistoryName}
                />
              )}
              {!isWorflowRun && (
                <LaunchWorkflowButton isRunning={isRunning} handleSubmitPlan={handleSubmitPlan} />
              )}
              {isRunning && (
                <Button
                  onClick={onStopWorkflow}
                  sx={{
                    whiteSpace: 'nowrap',
                    color: 'colors.hover_on_negative_red',
                    borderColor: 'colors.hover_on_negative_red',
                    ':hover': { color: 'colors.hover_on_negative_red' }
                  }}
                  variant="outlined">
                  Stop
                </Button>
              )}
            </MLInlineStack>
          )
        }>
        {isPlannerStep && (
          <WorkflowPlannerDialogStep
            listId={listId}
            isJsonViewOpen={isJsonViewOpen}
            setIsJsonViewOpen={setIsJsonViewOpen}
          />
        )}
        {!isHistoryStep && !isPlannerStep && <WorkflowsTemplateDialogStep listId={listId} />}
        {!isPlannerStep && isHistoryStep && (
          <AllWorkflowsDialogStep listId={listId} closeWorkflowCenter={closeDialog} />
        )}
      </ModalComponent>
      <ForgotSaveWorkflowDialog
        workflowId={selectedWorkflowId}
        plan={plan}
        open={isSaveChangesModalOpen}
        onClose={closeForgotToSave}
        createNew={selectedWorkflowType === WORKFLOW_SELECTED_TYPE.DRAFT}
        planName={title}
      />
    </React.Fragment>
  );
}

WorkflowsCenterDialog.propTypes = {
  listId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  isWorflowRun: PropTypes.bool
};

export default WorkflowsCenterDialog;
