import { Stack, Typography } from '@mui/material';
import { ActionType } from 'models/assistant/assistant-actions';
import { MessageRole } from 'models/assistant/types';
import React from 'react';
import { getAssistantThreadById, getThreadActionById } from 'selectors/assistant';
import { useAppSelectorWithArgs } from 'types/store';

import ActionDisplayComponent from './ActionDisplayComponent';
import { messageRoleToAvatar } from './MessageComponent';
import MessageConfirmationComponent from './MessageConfirmationComponent';

interface ActionMessageComponentProps {
  threadId: string;
  actionId: string;
  role: MessageRole;
  listId: number;
}

export default function ActionMessageComponent({
  threadId,
  actionId,
  role,
  listId
}: ActionMessageComponentProps) {
  const action = useAppSelectorWithArgs(getThreadActionById, threadId, actionId);
  const thread = useAppSelectorWithArgs(getAssistantThreadById, threadId);
  const isActionSubmitted = Boolean(action && action.submission_info?.submitted);

  if (!action || !thread.company_list_id) return null;
  if (action.action_type === ActionType.WORKFLOW) {
    return;
  }
  return (
    <Stack direction="row" gap="8px" sx={{ marginBottom: '4px' }}>
      {messageRoleToAvatar(role)}
      <Stack width="100%">
        <Stack
          sx={{
            padding: '8px',
            minWidth: action ? '300px' : null,
            gap: '8px'
          }}>
          <Typography>
            {isActionSubmitted
              ? 'These are the details of your request:'
              : 'Here is the action based on your request:'}
          </Typography>
          <ActionDisplayComponent
            action={action}
            actionTaken={isActionSubmitted}
            listId={thread.company_list_id}
            threadId={threadId}
          />
          {!isActionSubmitted && (
            <MessageConfirmationComponent threadId={threadId} action={action} listId={listId} />
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}
