import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { store } from 'reducer/store';

// Infer the type of Dispatch from the store
export type AppDispatch = typeof store.dispatch;
export type getAppState = typeof store.getState;

// Use it in your component
export const useAppDispatch: () => AppDispatch = useDispatch;

export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// Custom useAppSelector that accepts a selector function and additional arguments
export function useAppSelectorWithArgs<T, A extends any[]>(
  selector: (state: RootState, ...args: A) => T,
  ...args: A
): T {
  return useSelector((state: RootState) => selector(state, ...args));
}
