import React from 'react';

export default function PartnersIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.0262 4.90508C15.676 4.55473 15.2602 4.27681 14.8026 4.0872C14.345 3.89758 13.8545 3.79999 13.3592 3.79999C12.8638 3.79999 12.3733 3.89758 11.9157 4.0872C11.4581 4.27681 11.0423 4.55473 10.6921 4.90508L9.96536 5.63183L9.23861 4.90508C8.53127 4.19774 7.5719 3.80035 6.57157 3.80035C5.57123 3.80035 4.61187 4.19774 3.90453 4.90508C3.19719 5.61242 2.7998 6.57178 2.7998 7.57212C2.7998 8.57245 3.19719 9.53181 3.90453 10.2392L4.63128 10.9659L9.96536 16.3L15.2994 10.9659L16.0262 10.2392C16.3765 9.88898 16.6545 9.4732 16.8441 9.01558C17.0337 8.55796 17.1313 8.06747 17.1313 7.57212C17.1313 7.07677 17.0337 6.58628 16.8441 6.12866C16.6545 5.67104 16.3765 5.25526 16.0262 4.90508Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
