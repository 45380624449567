import React from 'react';

export default function MenuIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.2863 7.28571C13.2863 7.99579 12.7106 8.57143 12.0006 8.57143C11.2905 8.57143 10.7148 7.99579 10.7148 7.28571C10.7148 6.57563 11.2905 6 12.0006 6C12.7106 6 13.2863 6.57563 13.2863 7.28571Z"
        fill="currentColor"
      />
      <path
        d="M13.2863 12C13.2863 12.7101 12.7106 13.2857 12.0006 13.2857C11.2905 13.2857 10.7148 12.7101 10.7148 12C10.7148 11.2899 11.2905 10.7143 12.0006 10.7143C12.7106 10.7143 13.2863 11.2899 13.2863 12Z"
        fill="currentColor"
      />
      <path
        d="M12.0006 18C12.7106 18 13.2863 17.4244 13.2863 16.7143C13.2863 16.0042 12.7106 15.4286 12.0006 15.4286C11.2905 15.4286 10.7148 16.0042 10.7148 16.7143C10.7148 17.4244 11.2905 18 12.0006 18Z"
        fill="currentColor"
      />
    </svg>
  );
}
