import React from 'react';

const DeleteMultipleIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 4C8.67157 4 8 4.67157 8 5.5V6.5H4.5C4.22386 6.5 4 6.72386 4 7C4 7.27614 4.22386 7.5 4.5 7.5H6V15C6 16.3807 7.11929 17.5 8.5 17.5H14.5C15.8807 17.5 17 16.3807 17 15V7.5H18.5C18.7761 7.5 19 7.27614 19 7C19 6.72386 18.7761 6.5 18.5 6.5H15V5.5C15 4.67157 14.3284 4 13.5 4H9.5ZM14 6.5V5.5C14 5.22386 13.7761 5 13.5 5H9.5C9.22386 5 9 5.22386 9 5.5V6.5H14ZM7 7.5V15C7 15.8284 7.67157 16.5 8.5 16.5H14.5C15.3284 16.5 16 15.8284 16 15V7.5H7Z"
        fill="currentColor"
      />
      <path
        d="M20 10C20 9.72386 19.7761 9.5 19.5 9.5C19.2239 9.5 19 9.72386 19 10V17.5C19 18.3284 18.3284 19 17.5 19H13.5C13.2239 19 13 19.2239 13 19.5C13 19.7761 13.2239 20 13.5 20H17.5C18.8807 20 20 18.8807 20 17.5V10Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default DeleteMultipleIcon;
