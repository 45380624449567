import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Divider,
  Link,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import { createNewDiscoveryInCustomAnalyticsThreadAction } from 'actions/custom-analytics/create-new-discovery-in-custom-analytics-thread-action';
import { makeNewSearchRunAction } from 'actions/runs/make-new-search-run-action';
import ModalComponent from 'components/modal/ModalComponent';
import { COMPANY_INSIGHTS_PREFIX } from 'constants/app-routes';
import { OVERVIEW_TAB } from 'constants/company-insights';
import { DISCOVERY_TYPES } from 'constants/discovery';
import MLIconButton from 'design-system/MLIconButton';
import MLShimmeringStack from 'design-system/MLShimmeringStack';
import CloseIcon from 'design-system/icons/platform/CloseIcon';
import { dispatch } from 'hooks/AppStateProvider';
import CrunchbaseIcon from 'icons/CrunchbaseIcon';
import DeepDiveIcon from 'icons/DeepDiveIcon';
import DeleteIcon from 'icons/DeleteIcon';
import DownIcon from 'icons/DownIcon';
import ExportIcon from 'icons/ExportIcon';
import LinkedinIcon from 'icons/LinkedinIcon';
import MenuIcon from 'icons/MenuIcon';
import SearchForPeersIcon from 'icons/SearchForPeersIcon';
import SourceIcon from 'icons/SourceIcon';
import UpIcon from 'icons/UpIcon';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { COLUMN_TYPES } from 'utils/explore-table-columns';

const iconButtonStyle = {
  height: '32px',
  width: '32px',
  color: 'colors.primary_text',
  padding: 0
};
const upDownButtonStyle = {
  ...iconButtonStyle,
  width: '28px'
};

const LOADING_VALUE = '{"loading":true}';
/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines*/
function QuickViewTitle({
  listId,
  value,
  onClose,
  getFieldValue,
  onUp,
  onDown,
  toPdf,
  exportingPdf,
  setExportingPdf,
  toPowerPoint,
  exportingPowerPoint,
  setExportingPowerPoint
}) {
  const { name, logoUrl, recordId, recordReady } = value;
  const reduxDispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [submittedPeers, setSubmittedPeers] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const open = Boolean(anchorEl);
  const appTheme = useTheme();
  const determineDeepDiveText = () => {
    if (recordId) {
      if (recordReady) {
        return 'See Deep Dive';
      }
      return 'Running Deep Dive';
    }
    return 'Run Deep Dive';
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const onDeepDive = () => {
    if (recordId && !recordReady) return;
    if (recordId) {
      if (recordReady) {
        window.open(`${COMPANY_INSIGHTS_PREFIX}/${recordId}/${OVERVIEW_TAB.path}`);
        return;
      }
      return;
    }
    dispatch(makeNewSearchRunAction(value.suggestion, null, reduxDispatch, listId));
  };
  const onDelete = () => {
    value.onDelete?.(value.suggestion.companyListMetaId);
    setDeleteOpen(false);
  };
  const exportPdf = toPdf
    ? () => {
        setExportingPdf(true);
        toPdf();
      }
    : null;
  const exportPowerPoint = () => {
    setExportingPowerPoint(true);
    toPowerPoint();
  };
  const onSubmitPeers = () => {
    setSubmittedPeers(true);
    reduxDispatch(
      createNewDiscoveryInCustomAnalyticsThreadAction(
        listId,
        DISCOVERY_TYPES.PEERS,
        null,
        null,
        null,
        value.companyListMetaId
      )
    );
  };

  const summary = getFieldValue(COLUMN_TYPES.SUMMARY);
  return (
    <Stack
      id="alert-dialog-title"
      className="exportable-to-pptx"
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        borderBottom: '1px solid',
        padding: '24px',
        justifyContent: 'space-between',
        borderColor: 'colors.ui_border'
      }}>
      <Stack gap="16px" direction="row">
        {logoUrl && <Avatar src={logoUrl} sx={{ width: 50, height: 50 }} variant="rounded" />}
        <Stack gap="4px">
          <Typography variant="h3_Bold" color="colors.primary_text">
            {name}
          </Typography>

          {summary === LOADING_VALUE ? (
            <MLShimmeringStack>
              <Typography variant="text1_Normal" color="colors.secondary_text">
                Creating summary...
              </Typography>
            </MLShimmeringStack>
          ) : (
            <Typography variant="text1_Normal" color="colors.secondary_text">
              {summary}
            </Typography>
          )}
        </Stack>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        gap="8px"
        sx={{ height: '32px', minWidth: '360px' }}>
        <ButtonGroup sx={{ marginLeft: 'auto' }}>
          <Button sx={upDownButtonStyle} disabled={!onDown} onClick={onDown}>
            <DownIcon />
          </Button>
          <Button sx={upDownButtonStyle} disable={!onUp} onClick={onUp}>
            <UpIcon />
          </Button>
        </ButtonGroup>
        <Button
          variant="outlined"
          sx={{ minWidth: 'fit-content' }}
          startIcon={
            recordId && !recordReady ? (
              <CircularProgress color="primary" size="20px" />
            ) : (
              <DeepDiveIcon variant="secondary" color={appTheme.vars.palette.colors.primary} />
            )
          }
          onClick={onDeepDive}>
          {determineDeepDiveText()}
        </Button>
        <MLIconButton sx={iconButtonStyle} tooltipText="Delete" onClick={() => setDeleteOpen(true)}>
          <DeleteIcon />
        </MLIconButton>
        <MLIconButton sx={iconButtonStyle} tooltipText="More" onClick={handleMenu}>
          <MenuIcon />
        </MLIconButton>
        <Box sx={{ height: '32px', width: '1px', backgroundColor: 'colors.ui_border' }} />
        <MLIconButton sx={iconButtonStyle} tooltipText="Close" onClick={onClose}>
          <CloseIcon />
        </MLIconButton>
      </Stack>
      <Menu
        id="bulk-record-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        textAlign="left"
        sx={{
          '&.MuiPaper-root': {
            backgroundColor: 'colors.primary_bg'
          }
        }}>
        {exportPdf && (
          <MenuItem textAlign="left" color="colors.dark_bg">
            <Stack
              direction="row"
              alignItems="center"
              gap="4px"
              onClick={exportPdf}
              sx={{ marginLeft: '-2px' }}>
              <ExportIcon />
              {exportingPdf ? 'Exporting...' : 'Export To PDF'}
            </Stack>
          </MenuItem>
        )}
        {exportPowerPoint && (
          <MenuItem textAlign="left" color="colors.dark_bg">
            <Stack
              direction="row"
              alignItems="center"
              gap="4px"
              onClick={exportPowerPoint}
              sx={{ marginLeft: '-2px' }}>
              <ExportIcon />
              {exportingPowerPoint ? 'Exporting...' : 'Export To PowerPoint'}
            </Stack>
          </MenuItem>
        )}
        {(exportPdf || exportPowerPoint) && <Divider component="menu" />}

        <MenuItem textAlign="left" color="colors.dark_bg">
          <Stack
            direction="row"
            alignItems="center"
            gap="4px"
            onClick={onSubmitPeers}
            sx={{ marginLeft: '-2px' }}>
            <SearchForPeersIcon />
            {submittedPeers ? 'Searching for peers...' : 'Search for peers'}
          </Stack>
        </MenuItem>
        <Divider component="menu" />
        {value.website && (
          <MenuItem textAlign="left" color="colors.dark_bg">
            <Link href={value.website} underline="none" target="_blank" rel="noopener">
              <Stack direction="row" alignItems="center" gap="8px">
                <SourceIcon />
                Go to website
              </Stack>
            </Link>
          </MenuItem>
        )}
        {value.linkedinUrl && (
          <MenuItem textAlign="left" color="colors.dark_bg">
            <Link href={value.linkedinUrl} underline="none" target="_blank" rel="noopener">
              <Stack direction="row" alignItems="center" gap="8px">
                <LinkedinIcon />
                Go to Linkedin
              </Stack>
            </Link>
          </MenuItem>
        )}
        {value.cbPermalink && (
          <MenuItem textAlign="left" color="colors.dark_bg">
            <Link
              href={`https://www.crunchbase.com/organization/${value.cbPermalink}`}
              underline="none"
              target="_blank"
              rel="noopener">
              <Stack direction="row" alignItems="center" gap="8px">
                <CrunchbaseIcon />
                Go to Crunchbase
              </Stack>
            </Link>
          </MenuItem>
        )}
      </Menu>
      <ModalComponent
        title="Delete company from list"
        open={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        maxWidth={500}
        bottomBar={
          <Stack
            direction="row"
            gap="8px"
            alignItems="center"
            justifyContent="space-between"
            padding="0 24px"
            sx={{
              width: '100%'
            }}>
            <Button onClick={() => setDeleteOpen(false)}>Cancel</Button>
            <Button variant="contained" onClick={onDelete}>
              Delete
            </Button>
          </Stack>
        }>
        <Stack padding="24px">
          <Typography variant="text1_Normal">
            Are you sure you want to delete <strong>{value.name}</strong> from the list?
          </Typography>
        </Stack>
      </ModalComponent>
    </Stack>
  );
}

QuickViewTitle.propTypes = {
  value: PropTypes.object,
  onClose: PropTypes.func,
  getFieldValue: PropTypes.func,
  onUp: PropTypes.func,
  onDown: PropTypes.func,
  listId: PropTypes.string,
  toPdf: PropTypes.func,
  toPowerPoint: PropTypes.func,
  exportingPdf: PropTypes.bool,
  exportingPowerPoint: PropTypes.bool,
  setExportingPdf: PropTypes.func,
  setExportingPowerPoint: PropTypes.func
};

export default QuickViewTitle;
