import { Stack, Tooltip, Typography } from '@mui/material';
import MLInlineStack from 'design-system/MLInlineStack';
import PropTypes from 'prop-types';
import React from 'react';

import RenderMultipleLogos from './RenderMultipleLogos';

export default function DiscoveredCompaniesSummary({ alwaysOpen, discoveredCompanies, color }) {
  const colors = {
    titleColor: color,
    urlColor: alwaysOpen ? null : 'colors.secondary_text',
    text: alwaysOpen ? 'colors.primary_text' : 'white'
  };

  return (
    <Stack gap="16px" width={alwaysOpen ? '100%' : '200px'} alignItems="flex-start">
      {discoveredCompanies && Boolean(discoveredCompanies.length) && (
        <MLInlineStack gap={'4px'}>
          <Typography variant="text1_Semibold" color={colors.text}>
            {discoveredCompanies.length} compan
            {discoveredCompanies.length === 1 ? 'y' : 'ies'}
          </Typography>
          <Typography variant="text1_Normal" color={colors.text}>
            {discoveredCompanies.length === 1 ? ' was ' : ' were '}
            added to the list
            {discoveredCompanies.length ? ':' : ''}
          </Typography>
        </MLInlineStack>
      )}
      {discoveredCompanies && (
        <Stack direction="row" gap="8px" alignItems="center" justifyContent="flex-start">
          <RenderMultipleLogos value={discoveredCompanies?.slice(0, 8)} />
          {discoveredCompanies.length > 8 && (
            <Tooltip
              title={
                <Stack gap="4px">
                  {discoveredCompanies
                    .slice(8)
                    .reduce((resultArray, item, index) => {
                      const chunkIndex = Math.floor(index / 8);
                      if (!resultArray[chunkIndex]) {
                        resultArray[chunkIndex] = [];
                      }
                      resultArray[chunkIndex].push(item);
                      return resultArray;
                    }, [])
                    .map((chunk, indedx) => (
                      <RenderMultipleLogos key={indedx} value={chunk} />
                    ))}
                </Stack>
              }>
              <Typography
                variant="text1_Bold"
                color="colors.primary_text"
                sx={{
                  border: '1px solid',
                  borderColor: 'colors.hover_on_selected',
                  borderRadius: '4px',
                  padding: '3px'
                }}>
                {`+${discoveredCompanies.length - 8}`}
              </Typography>
            </Tooltip>
          )}
        </Stack>
      )}
    </Stack>
  );
}

DiscoveredCompaniesSummary.propTypes = {
  color: PropTypes.string,
  discoveredCompanies: PropTypes.array,
  alwaysOpen: PropTypes.bool
};

DiscoveredCompaniesSummary.defaultProps = {
  color: 'colors.primary_text'
};
