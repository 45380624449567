import { createSelector } from '@reduxjs/toolkit';
import { getObjectLength, isEmpty } from 'utils/objects-utils';

import { getCompanyMetasMergedRowsMapByListId, mapListMetaIdToMetaId } from './companyMetas';

export const getAllExploresTableUI = (state) => state.exploreTableUI;
export const getAllExploresCheckedCompanies = (state) =>
  getAllExploresTableUI(state).companiesIsChecked;
export const getExploreCsvColumnsToUpload = (state) =>
  getAllExploresTableUI(state).csvColumnsToUpload;
export const getExploreUiColumns = (state) => getAllExploresTableUI(state).columns;
export const getExploresClosedCsvAddColumnsDialog = (state) =>
  getAllExploresTableUI(state).closedCsvAddColumnsDialog;
export const getExploresTableUiHighlightColumns = (state) =>
  getAllExploresTableUI(state).highlightedColumns;
export const getExploreTableUICheckedCompaniesByListId = (state, listId) =>
  getAllExploresCheckedCompanies(state)[listId];

export const getExploreUiColumnsByListId = (state, listId) => getExploreUiColumns(state)?.[listId];
export const getExploreUiColumnByListId = (state, listId, columnId) =>
  getExploreUiColumnsByListId(state, listId)?.[columnId];
export const getExploreUiColumnWidthByListId = (state, listId, columnId) =>
  getExploreUiColumnByListId(state, listId, columnId)?.width;
export const getExploreUiCsv = (state) => getAllExploresTableUI(state).csv;
export const getExploreUiCsvByListId = (state, listId) => getExploreUiCsv(state)?.[listId];
export const getIsExploreAddColumnsCsvClosed = (state, listId) =>
  getExploresClosedCsvAddColumnsDialog(state)?.[listId];

export const getExploreTableSelectedCompanies = createSelector(
  getCompanyMetasMergedRowsMapByListId,
  getExploreTableUICheckedCompaniesByListId,
  (rowsMapPerCompanyListMetaId, companiesCheckedForList) => {
    if (!rowsMapPerCompanyListMetaId) return null;
    return Object.keys(companiesCheckedForList || {}).reduce((acc, companyListMetaId) => {
      if (companiesCheckedForList[companyListMetaId]) {
        acc.push(rowsMapPerCompanyListMetaId[companyListMetaId]);
      }
      return acc;
    }, []);
  }
);

export const getExploreCompaniesLoadingByListId = (state, listId) =>
  getAllExploresTableUI(state).exploreCompaniesLoading?.[listId];

export const getExploreFilteredCompanies = createSelector(
  getAllExploresTableUI,
  (_state, id) => id,
  (exploreUI, id) => exploreUI.filteredCompanies[id]
);

export const getAllPredefinedColumns = (state) => getAllExploresTableUI(state).predefinedColumns;
export const getAllPredefinedTableQuestions = (state) =>
  getAllExploresTableUI(state).predefinedTableQuestions;

export const getExploresTableUiListHighlightColumns = (state, listId) =>
  getExploresTableUiHighlightColumns(state)[listId];
export const getExploreCsvColumnsToUploadByListId = (state, listId) =>
  getExploreCsvColumnsToUpload(state)[listId];

export const getExploreFilteredMetaIdsIfListFiltered = createSelector(
  getExploreFilteredCompanies,
  mapListMetaIdToMetaId,
  (filteredCompanyListMetaIds, allListMetaIdToMetaIdMap) => {
    const isListFiltered =
      getObjectLength(filteredCompanyListMetaIds) !== getObjectLength(allListMetaIdToMetaIdMap);
    if (!isListFiltered || !allListMetaIdToMetaIdMap) return null;

    return filteredCompanyListMetaIds?.map((listMetaId) => allListMetaIdToMetaIdMap[listMetaId]);
  }
);

export const getExploreSelectedCompanyMetaIds = createSelector(
  getExploreTableSelectedCompanies,
  getExploreFilteredMetaIdsIfListFiltered,
  (selectedCompanies, filteredMetaIdsIfFiltered) => {
    let selectedCompanyMetaIds = null;
    if (!isEmpty(selectedCompanies)) {
      selectedCompanyMetaIds = selectedCompanies.map((company) => company.companyMetaId);
    } else if (filteredMetaIdsIfFiltered) {
      selectedCompanyMetaIds = filteredMetaIdsIfFiltered;
    }

    return selectedCompanyMetaIds;
  }
);

const getAllExploreSelectedView = (state) => getAllExploresTableUI(state).selectedView;

export const getExploreListSelectedView = createSelector(
  getAllExploreSelectedView,
  (_state, listId) => listId,
  (selectedView, listId) => selectedView?.[listId]
);

export const getListOrderedRenderdMetaIdsByListId = createSelector(
  getAllExploresTableUI,
  (_state, id) => id,
  (exploreUI, id) => exploreUI.orderedRenderedMetaIds[id]
);
