import { submitDomainReportAction } from 'actions/explore/submit-domain-report-action';
import { DOMAIN_REPORT_EVENTS } from 'constants/userEvents';
import MLButton from 'design-system/MLButton';
import RefreshIcon from 'icons/RefreshIcon';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getLatestDomainReportByListId } from 'selectors/domain-reports';
import { getCompanyMetasByListId, getExploreFiltersById } from 'selectors/explore';
import { getExploreFilteredCompanies } from 'selectors/explore-table-ui';
import { useAppDispatch } from 'types/store';

interface DomainReportButtonProps {
  listId: number;
  status: string;
}

export default function DomainReportButton({ listId, status }: DomainReportButtonProps) {
  const dispatch = useAppDispatch();
  const [submitted, setSubmitted] = useState(false);
  const companyMetas = useSelector((state) => getCompanyMetasByListId(state, listId));
  const isEmptyList = !companyMetas || companyMetas.length === 0;
  const filteredListMetaIds = useSelector((state) => getExploreFilteredCompanies(state, listId));
  const currentFilteredCompanies = companyMetas.filter((company: { company_list_meta_id: any }) =>
    filteredListMetaIds.includes(company.company_list_meta_id)
  );
  const currentFilteredOutCompanies = companyMetas.filter(
    (company: { company_list_meta_id: any }) =>
      !filteredListMetaIds.includes(company.company_list_meta_id)
  );
  const currentFilters = useSelector((state) => getExploreFiltersById(state, listId));
  const currentFilteredCompanyMetaIds = currentFilteredCompanies.map(
    (company: { company_meta_id: any }) => company.company_meta_id
  );
  const currentFilteredOutCompanyMetaIds = currentFilteredOutCompanies.map(
    (company: { company_meta_id: any }) => company.company_meta_id
  );
  useEffect(() => {
    if (status !== 'RUNNING') {
      setSubmitted(false);
    }
  }, [status]);
  const domainReport = useSelector((state) => getLatestDomainReportByListId(state, listId));
  const getTooltipText = () => {
    if (isEmptyList) {
      if (submitted) {
        return 'Generating report...';
      }
      return 'To generate a report, first add companies to the list';
    } else if (submitted) {
      return 'Please wait while report update starts...';
    }
    return '';
  };
  const submitDomainReport = () => {
    if (submitted) return;
    setSubmitted(true);
    const event = domainReport
      ? DOMAIN_REPORT_EVENTS.DOMAIN_REPORT_UPDATE
      : DOMAIN_REPORT_EVENTS.DOMAIN_REPORT_CREATION;

    dispatch(
      submitDomainReportAction({
        listId,
        companyMetaIds: currentFilteredCompanyMetaIds,
        filters: currentFilters,
        filtered_out_company_meta_ids: currentFilteredOutCompanyMetaIds,
        eventType: event
      })
    );
  };
  if (!domainReport) {
    return (
      <MLButton
        id="domain-report-button"
        variant="contained"
        disabled={isEmptyList || submitted}
        tooltipText={getTooltipText()}
        onClick={submitDomainReport}>
        Generate a report
      </MLButton>
    );
  }
  return (
    <MLButton
      id="domain-report-button"
      size="small"
      onClick={submitDomainReport}
      tooltipText={getTooltipText()}
      startIcon={<RefreshIcon />}
      disabled={status === 'RUNNING' || submitted}>
      {status === 'FAILED' ? 'Retry' : 'Update'}
    </MLButton>
  );
}
