import { ListItemIcon, ListItemText, MenuItem, Stack, Typography } from '@mui/material';
import { useDomainReportContext } from 'components/domain-report/DomainReportContext';
import MLIcon from 'design-system/MLIcon';
import MLIconButton from 'design-system/MLIconButton';
import MLIconMenuButton from 'design-system/MLIconMenuButton';
import MLInlineStack from 'design-system/MLInlineStack';
import useMenuManagement from 'design-system/hooks/useMenuManagement';
import MenuIcon from 'design-system/icons/basic/MenuIcon';
import DeleteIcon from 'icons/DeleteIcon';
import EditIcon from 'icons/EditIcon';
import { DomainReportSection } from 'models/domain-report/types';
import React from 'react';

const itemSx = {
  minHeight: '32px',
  borderRadius: '4px',
  padding: '8px',
  color: 'colors.primary_text'
};

interface DomainReportDraggableSectionActionsProps {
  section: DomainReportSection;
  deleteAction?: () => void;
  editAction?: () => void;
}
export default function DomainReportDraggableSectionActions({
  section,
  editAction,
  deleteAction
}: DomainReportDraggableSectionActionsProps) {
  const { outdated } = useDomainReportContext();
  const menuManagement = useMenuManagement<HTMLButtonElement>();
  const { isEditable, isDeletable } = section.presentationProps;
  const isMultipleOptions = isDeletable && isEditable;

  if (isMultipleOptions) {
    return (
      <MLIconMenuButton
        menuManagement={menuManagement}
        menuContent={
          <Stack>
            <MenuItem sx={itemSx} onClick={editAction}>
              <ListItemIcon sx={{ gap: '8px' }}>
                <MLIcon icon={<EditIcon />} size={'small'} />
              </ListItemIcon>
              <ListItemText>
                <Typography>Edit</Typography>
              </ListItemText>
            </MenuItem>
            <MenuItem sx={itemSx} onClick={deleteAction}>
              <ListItemIcon sx={{ gap: '8px' }}>
                <MLIcon icon={<DeleteIcon />} size={'small'} />
              </ListItemIcon>
              <ListItemText>
                <Typography>Delete</Typography>
              </ListItemText>
            </MenuItem>
          </Stack>
        }>
        <MenuIcon />
      </MLIconMenuButton>
    );
  }
  return (
    <MLInlineStack>
      {isEditable && (
        <MLIconButton
          disabled={outdated}
          tooltipText={
            outdated
              ? 'This report is outdated because the company list has changed. Update the report to show the latest data.'
              : 'Edit'
          }
          size="medium"
          onClick={editAction}>
          <EditIcon />
        </MLIconButton>
      )}
      {isDeletable && (
        <MLIconButton tooltipText="Delete" size="medium" onClick={deleteAction}>
          <DeleteIcon />
        </MLIconButton>
      )}
    </MLInlineStack>
  );
}
