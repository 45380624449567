import { Stack } from '@mui/material';
import React from 'react';
import { PromptConfig } from 'utils/custom-analytics-utils';
import { promptConfigToSearchPlaces } from 'utils/prompt-dropdown-utils';

import DomainReportEditorKeyValue from './DomainReportEditorKeyValue';

const columnsToColumnText = (columns?: string[] | null): string => {
  if (!columns) {
    return 'No columns';
  }
  if (columns.length === 1) {
    return `Using column ${columns[0]}`;
  }
  return `Using columns ${columns.join(', ')}`;
};

const searchPlacesToSearchText = (searchPlaces?: string[]): string => {
  if (!searchPlaces) {
    return 'No search places';
  }
  if (searchPlaces.length === 2) {
    return 'Search everywhere';
  }
  return `Search in ${searchPlaces.join(', ')}`;
};

interface DomainReportEditorDefaultConfigProps {
  promptConfig: PromptConfig;
}

export default function DomainReportEditorDefaultConfig({
  promptConfig
}: DomainReportEditorDefaultConfigProps) {
  const searchPlaces = promptConfigToSearchPlaces(promptConfig);
  const searchText = searchPlacesToSearchText(searchPlaces);
  const columnText = columnsToColumnText(promptConfig?.columns);
  return (
    <Stack gap="32px">
      <DomainReportEditorKeyValue title="Query">
        {promptConfig?.question}
      </DomainReportEditorKeyValue>
      <DomainReportEditorKeyValue title="Search">{searchText}</DomainReportEditorKeyValue>
      <DomainReportEditorKeyValue title="Context">{columnText}</DomainReportEditorKeyValue>
    </Stack>
  );
}
