import React, { forwardRef } from 'react';

import MLBasicIconButton, { MLBasicIconButtonProps } from './base/MLBasicIconButton';
import MLButtonWrapper, { MLButtonWrapperProps } from './base/MLButtonWrapper';

export interface MLIconButtonProps extends MLBasicIconButtonProps, MLButtonWrapperProps {
  children: React.ReactNode;
}

const MLIconButton = forwardRef<HTMLButtonElement, MLIconButtonProps>(
  (
    {
      id,
      variant = 'text',
      color = 'primary',
      size = 'medium',
      tooltipText,
      tooltipPlacement = 'bottom',
      tooltipArrow = true,
      alertMessage,
      alertSeverity,
      disabled,
      onClick,
      sx,
      children,
      ...extraProps
    },
    ref
  ) => {
    const inner = (
      <MLBasicIconButton
        ref={ref}
        id={id}
        disabled={disabled}
        onClick={onClick}
        variant={variant}
        color={color}
        size={size}
        sx={sx}
        {...extraProps}>
        {children}
      </MLBasicIconButton>
    );
    return (
      <MLButtonWrapper
        tooltipText={tooltipText}
        tooltipPlacement={tooltipPlacement}
        tooltipArrow={tooltipArrow}
        alertMessage={alertMessage}
        alertSeverity={alertSeverity}>
        {inner}
      </MLButtonWrapper>
    );
  }
);

MLIconButton.displayName = 'MentionList';

export default MLIconButton;
