import { AssistantAction } from 'models/assistant/assistant-actions';
import { newPromptConfig } from 'reducer/assistant-slice';
import { useAppDispatch } from 'types/store';

const usePromptFunctions = (action: AssistantAction) => {
  const dispatch = useAppDispatch();

  const handleCopyClick = () => {
    const params = { ...action, question: action.text };
    dispatch(newPromptConfig(params));
  };

  return { handleCopyClick };
};

export default usePromptFunctions;
