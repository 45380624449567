import { Box, Stack, Typography } from '@mui/material';
import CloseIcon from 'design-system/icons/platform/CloseIcon';
import ArrowIcon from 'icons/ArrowIcon';
import CheckIcon from 'icons/CheckIcon';
import UploadIcon from 'icons/UploadIcon';
import WarningIcon from 'icons/WarningIcon';
import React, { useCallback, useMemo, useState } from 'react';
import Draggable from 'react-draggable';
import { useSelector } from 'react-redux';
import { getFilesUpload } from 'selectors/files';
import { getFileSizeString } from 'utils/files';

import MLIconButton from '../design-system/MLIconButton';

export default function FileUploadProgressComponent() {
  const [openProgressMonitor, setOpenProgressMonitor] = useState(true);
  const filesUpload = useSelector(getFilesUpload);
  const [dismissed, setDismissed] = useState([]);

  const uploads = useMemo(
    () =>
      Object.keys(filesUpload)
        .filter((id) => !dismissed.includes(id))
        .map((id) => ({ id, ...filesUpload[id] })),
    [filesUpload, dismissed]
  );

  const getFileStatusColor = useCallback((file) => {
    if (!file.finished) return 'colors.informative_blue';
    return file.isSucceed ? 'colors.positive_green' : 'colors.negative_red';
  }, []);

  const handleDismiss = (id) => {
    if (dismissed.includes(id)) return;
    setDismissed([...dismissed, id]);
  };

  const handleClose = () => {
    setDismissed(Object.keys(filesUpload));
  };

  if (!uploads.length > 0) {
    return null;
  }

  const numUploading = uploads.filter((file) => !file.finished).length;

  return (
    <Draggable>
      <Stack
        sx={{
          minWidth: '25%',
          width: 'fit-content',
          position: 'fixed',
          bottom: '5%',
          right: 24,
          zIndex: 'statusMonitor',
          boxShadow: '0px 4px 8px 0px rgba(11, 28, 56, 0.3)'
        }}>
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            color: 'white',
            padding: '4px 16px',
            gap: '8px',
            backgroundColor: 'colors.dark_bg'
          }}>
          <UploadIcon />
          <Typography variant="text1_Medium">
            {numUploading > 0 ? `Uploading ${numUploading} files` : 'All uploads finished'}
          </Typography>
          <MLIconButton
            color="inverted"
            tooltipText={openProgressMonitor ? 'Hide' : 'Show'}
            onClick={() => setOpenProgressMonitor(!openProgressMonitor)}
            sx={{
              transform: openProgressMonitor ? 'rotate(180deg)' : 'rotate(0)',
              marginLeft: 'auto'
            }}>
            <ArrowIcon color="white" />
          </MLIconButton>
          <MLIconButton
            tooltipText="Close"
            onClick={handleClose}
            sx={{
              color: 'white',
              marginRight: '6px'
            }}>
            <CloseIcon color="white" />
          </MLIconButton>
        </Stack>
        {openProgressMonitor && (
          <Stack
            direction="column"
            padding="8px"
            sx={{
              maxHeight: '300px',
              overflowY: 'scroll',
              backgroundColor: 'colors.primary_bg'
            }}>
            {uploads.map((file, index) => (
              <React.Fragment key={file.id}>
                {file.finished ? (
                  <Stack
                    direction="row"
                    alignItems="center"
                    gap="16px"
                    width="100%"
                    sx={{
                      borderBottom: index < uploads.length - 1 ? '1px solid' : null,
                      borderColor: 'colors.selected'
                    }}>
                    {file.isSucceed ? <CheckIcon /> : <WarningIcon />}
                    <Typography variant="text1_Normal">{file.name}</Typography>
                    {!file.isSucceed && (
                      <Typography variant="text1_Normal">{file.error}</Typography>
                    )}
                    <MLIconButton
                      tooltipText="Close"
                      onClick={() => handleDismiss(file.id)}
                      sx={{ marginLeft: 'auto' }}>
                      <CloseIcon />
                    </MLIconButton>
                  </Stack>
                ) : (
                  <Stack
                    direction="column"
                    alignItems="flex-start"
                    gap="16px"
                    width="100%"
                    sx={{
                      borderBottom: index < uploads.length - 1 ? '1px solid' : null,
                      borderColor: 'colors.selected'
                    }}>
                    <Stack direction="column" alignItems="flex-start" gap="4px">
                      <Typography variant="text1_Normal">{file.name}</Typography>
                      <Typography variant="text1_Normal" color="colors.disabled_text">
                        {file.target}
                      </Typography>
                      <Typography variant="text1_Normal" color="colors.disabled_text">
                        {getFileSizeString(file.size)}
                      </Typography>
                    </Stack>

                    <Box
                      sx={{
                        transition: 'width 0.5s linear',
                        width: `${file.progress}%`,
                        height: '4px',
                        backgroundColor: getFileStatusColor(file),
                        borderRadius: '2px'
                      }}
                    />
                  </Stack>
                )}
              </React.Fragment>
            ))}
          </Stack>
        )}
      </Stack>
    </Draggable>
  );
}
