export const DOMAIN_KNOWLEDGE_RESOURCE_TYPE = Object.freeze({
  ANSWERS: 'answers',
  FILES: 'files',
  LINKS: 'links'
});

export const DOMAIN_KNOWLEDGE_RESOURCE_OPTIONS = Object.values(DOMAIN_KNOWLEDGE_RESOURCE_TYPE).map(
  (type) => ({ id: type, name: `All ${type}` })
);

export enum DomainKnowledgeType {
  LINKS = 'links',
  ANSWERS = 'answers',
  FILES = 'files'
}

export type DomainKnowledgeResource = {
  id: string;
  name?: string;
  supporting_resource_type: DomainKnowledgeType;
  data: {
    link?: string;
    title?: string;
    description?: string;
    file_name?: string;
  };
};

export type DomainKnowledge = {
  [key: string]: DomainKnowledgeResource;
};
