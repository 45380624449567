import { FetchThreadsForAdminParams, PostAssistantPayload } from 'models/assistant/api';

import BaseClient from './BaseClient';
import { getQueryParamsString } from './Utils';

const URL_PREFIX = 'assistant';

export default class AssistantClient extends BaseClient {
  submit(payload: PostAssistantPayload) {
    // Hacky way to remove unwanted fields - must be a better way?
    const sanitisedPayload: PostAssistantPayload = {
      id: payload.id,
      messages: payload.messages,
      action: payload.action,
      folder_id: payload.folder_id,
      company_list_id: payload.company_list_id,
      selected_company_meta_ids: payload.selected_company_meta_ids
    };
    return this.post(`${URL_PREFIX}`, sanitisedPayload);
  }

  fetchThreadsForAdmin(params: FetchThreadsForAdminParams) {
    return this.get(`${URL_PREFIX}/admin?${getQueryParamsString(params)}`);
  }
}
