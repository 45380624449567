import * as Sentry from '@sentry/react';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from 'reducer/store';
import 'themeOverrides';

import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

if (process.env.REACT_APP_ENV !== 'local') {
  Sentry.init({
    dsn: 'https://951a634931df96f256294279440c3552@o4507928853086208.ingest.de.sentry.io/4507928860360784',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', 'https://api.mathlabs.io/api/'],
    replaysSessionSampleRate: process.env.REACT_APP_ENV === 'demo' ? 1 : 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: process.env.REACT_APP_ENV
  });
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = ReactDOM.createRoot(document.getElementById('root')!);

root.render(
  <StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
