import { Box } from '@mui/material';
import { TTMentionOrText } from 'models/assistant/tiptap';
import React, { ReactNode, useRef } from 'react';
import { parseMentions } from 'utils/assistant/tiptap';

import MentionChip from '../prompt/tiptap/MentionChip';

interface AssistantRenderTextProps {
  text: string;
}

function nodeToElement(node: TTMentionOrText): ReactNode {
  if (typeof node === 'string') {
    return <span key={node}>{node}</span>;
  }
  return <MentionChip key={node.attrs.id} node={node} isStatic />;
}

function determineMarginTop(hasChips: boolean, firstLineHasChips: boolean, multiline: boolean) {
  if (multiline) {
    if (hasChips) {
      return firstLineHasChips ? '-6px' : '0px';
    }
    return '0px';
  }
  return firstLineHasChips ? '-10px' : '0px';
}

export default function AssistantRenderText({ text }: AssistantRenderTextProps) {
  const ref = useRef<HTMLDivElement>(null);
  const nodes = parseMentions(text);
  const elements = nodes
    .map((node, index) => {
      return typeof node === 'string'
        ? node.split('\n').reduce((acc, item, index2) => {
            acc.push(<span key={`span-${index}-${index2}`}>{item}</span>);
            if (index2 < node.split('\n').length - 1) {
              acc.push(<br key={`br-${index}-${index2}`} />);
            }
            return acc;
          }, [] as React.JSX.Element[])
        : nodeToElement(node);
    })
    .flat();

  // Determine presence of chips and multiline, and which lines chips appear on
  // in order to determine margin top
  const allStrings = nodes.every((node) => typeof node === 'string');
  const boxChildren = ref.current ? Array.from(ref.current.children) : [];
  const firstLine = [];
  let multiline = ref.current ? ref.current.getBoundingClientRect().height > 50 : false;
  for (let index = 0; index < boxChildren.length; index++) {
    if (index === 0) {
      firstLine.push(boxChildren[index]);
    } else if (
      boxChildren[index].getBoundingClientRect().left <
      boxChildren[index - 1].getBoundingClientRect().right
    ) {
      multiline = true;
      break;
    } else {
      firstLine.push(boxChildren[index]);
    }
  }
  const firstLineHasChips = firstLine.length > 1 || (boxChildren.length === 1 && !allStrings);

  return (
    <Box
      ref={ref}
      sx={{
        marginTop: determineMarginTop(!allStrings, firstLineHasChips, multiline)
      }}>
      {elements}
    </Box>
  );
}
