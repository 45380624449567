import { AnswerType } from 'utils/custom-analytics-utils';

import { Scoping, SearchSettings } from './types';

export enum ActionType {
  DISCOVERY_BY_QUERY = 'discovery_by_query',
  DISCOVERY_BY_PEERS = 'discovery_by_peers',
  DISCOVERY_BY_URL = 'discovery_by_url',
  ADD_COMPANIES_BY_NAME = 'add_companies_by_name',
  FILTER = 'filter',
  ADD_COLUMN = 'add_column',
  ASK_QUESTION = 'ask_question',
  WORKFLOW = 'workflow',
  PREVIEW_COLUMN = 'preview_column'
}

export interface AssistantActionSubmissionInfo {
  submitted: boolean;
  record_id?: number;
  post_id?: string;
  confirmed_by_user: boolean;
  view_id?: number | null;
  company_meta_ids?: number[] | null;
  column_id?: string | null;
  already_company_meta_ids?: number[] | null;
}

type AssistantActionBase = {
  id: string;
  action: ActionType;
  saved_query_id?: number | null;
  text: string;
  submission_info?: AssistantActionSubmissionInfo;
};

const DISCOVERY_ACTIONS = [
  ActionType.DISCOVERY_BY_PEERS,
  ActionType.DISCOVERY_BY_QUERY,
  ActionType.DISCOVERY_BY_URL,
  ActionType.ADD_COMPANIES_BY_NAME
] as const;

const CUSTOM_ANALYTICS_ACTIONS = [
  ActionType.ADD_COLUMN,
  ActionType.ASK_QUESTION,
  ActionType.FILTER,
  ActionType.WORKFLOW,
  ActionType.PREVIEW_COLUMN
] as const;

type CustomAnalyticsActionType = typeof CUSTOM_ANALYTICS_ACTIONS[number];

type DiscoveryActionType = typeof DISCOVERY_ACTIONS[number];

export type AssistantActionCustomAnalytics = AssistantActionBase & {
  action_type: CustomAnalyticsActionType;
  search_settings: SearchSettings;
  answer_type: AnswerType;
};

export type AssistantActionDiscovery = AssistantActionBase & {
  action_type: DiscoveryActionType;
  scoping: Scoping;
};

export type AssistantAction = AssistantActionCustomAnalytics | AssistantActionDiscovery;

const CUSTOM_ANALYTICS_ACTIONS_SET = new Set<ActionType>(CUSTOM_ANALYTICS_ACTIONS);
const DISCOVERY_ACTIONS_SET = new Set<ActionType>(DISCOVERY_ACTIONS);

export function isCustomAnalyticsAction(
  action: AssistantAction
): action is AssistantActionCustomAnalytics {
  return CUSTOM_ANALYTICS_ACTIONS_SET.has(action.action_type);
}

export function isDiscoveryAction(action: AssistantAction): action is AssistantActionDiscovery {
  return DISCOVERY_ACTIONS_SET.has(action.action_type);
}

export function isForEachQuestion(action: AssistantAction): boolean {
  return (
    action.action_type === ActionType.ADD_COLUMN || action.action_type === ActionType.PREVIEW_COLUMN
  );
}

export function isAddCompaniesByNameAction(action: AssistantAction): boolean {
  return action.action === ActionType.ADD_COMPANIES_BY_NAME;
}
