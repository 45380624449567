import { Divider, ListItemIcon, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { addParallelStepToPlanAction } from 'actions/workflows/add-parallel-step-to-plan-action';
import { removeStepFromPlanAction } from 'actions/workflows/remove-step-from-plan-action';
import WorkflowFormAddStepMenu from 'components/work-flow/WorkflowFormAddStepMenu';
import MLIconButton from 'design-system/MLIconButton';
import AddIcon from 'icons/AddIcon';
import ArrowIcon from 'icons/ArrowIcon';
import DeleteIcon from 'icons/DeleteIcon';
import MenuIcon from 'icons/MenuIcon';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

function WorkflowStepMenu({ listId, workflowId, stepId }) {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [addNewStepAnchorEl, setAddNewStepAnchorEl] = useState(null);

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const closeNewStepMenu = () => {
    setAddNewStepAnchorEl(null);
    closeMenu();
  };

  const handleAddParallelStep = () => {
    dispatch(addParallelStepToPlanAction(listId, workflowId, stepId));
    closeMenu();
  };

  const handleDeleteStep = () => {
    dispatch(removeStepFromPlanAction(listId, workflowId, stepId));
    closeMenu();
  };

  return (
    <Stack>
      <MLIconButton onClick={(event) => setAnchorEl(event.target)}>
        <MenuIcon />
      </MLIconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMenu}>
        <MenuItem onClick={(event) => setAddNewStepAnchorEl(event.target)}>
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <Typography variant="text1_Normal" color="colors.primary_text">
            Add new step below
          </Typography>
          <ListItemIcon sx={{ transform: 'rotate(90deg)', justifyContent: 'center' }}>
            <ArrowIcon />
          </ListItemIcon>
        </MenuItem>
        <MenuItem onClick={handleAddParallelStep}>
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <Typography variant="text1_Normal" color="colors.primary_text">
            Add parallel step below
          </Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleDeleteStep}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <Typography variant="text1_Normal" color="colors.primary_text">
            Delete
          </Typography>
        </MenuItem>
      </Menu>
      <WorkflowFormAddStepMenu
        listId={listId}
        anchorEl={addNewStepAnchorEl}
        onClose={closeNewStepMenu}
        stepId={stepId}
      />
    </Stack>
  );
}

WorkflowStepMenu.propTypes = {
  listId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  workflowId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  stepId: PropTypes.string
};

export default WorkflowStepMenu;
