/* eslint-disable array-bracket-newline */
import { CHAT_GPT_MODEL_VERSION } from 'constants/custom-analytics';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import {
  getAllPredefinedColumns,
  getAllPredefinedTableQuestions
} from 'selectors/explore-table-ui';
import { getAllSavedQueries } from 'selectors/saved-queries';
import { getUserFeatureFlags, getUserId } from 'selectors/user';
import { useAppSelector } from 'types/store';
import { COLUMN_TYPE_TO_FEATURE_FLAG_NAME } from 'utils/explore-table-columns';
import { isEmpty } from 'utils/objects-utils';
import {
  CATEGORIES,
  SAVED_QUERY_ACTION_TYPES,
  categoriesSorter,
  getPlatterBasicColumns,
  getPlatterEmptyColumns,
  getPlatterFinanceColumns
} from 'utils/platter-utils';

const LEFT_LEADERSHIP_DATA_NAME = 'LEFT_LEADERSHIP';
const PITCHBOOK_SCRAPINGS_DATA_NAME = 'PITCHBOOK_SCRAPINGS';
const SAP_PARTNERSHIP_DATA_NAME = 'SAP_PARTNERSHIP_DATA';
const AWS_PARTNERSHIP_DATA_NAME = 'AWS_PARTNERSHIP_DATA';
const MSFT_PARTNERSHIP_DATA_NAME = 'MSFT_PARTNERSHIP_DATA';

const COLUMN_TYPE_TO_FEATURE_FLAG_NAME_TYPED = COLUMN_TYPE_TO_FEATURE_FLAG_NAME as Record<
  string,
  string
>;

export type LibraryCategoryType = typeof CATEGORIES[keyof typeof CATEGORIES];
export type SavedQueryType = typeof SAVED_QUERY_ACTION_TYPES[keyof typeof SAVED_QUERY_ACTION_TYPES];

interface SavedQuery {
  id: number;
  name: string;
  type: SavedQueryType;
  parameters: any;
  isShared: boolean;
  description: string;
  time_created: string;
  owner: any;
  collaborators_users: any;
  collaborators_orgs: any;
  collaborators_teams: any;
  isPredefined: boolean;
}

export interface PlatterItem extends Record<string, any> {
  data_name: string | null;
  title: string;
  type: string;
  parameters: any;
  category: LibraryCategoryType;
  id: number;
}

export function usePlatterItems(actionType: SavedQueryType) {
  const predefinedColumns = useAppSelector(getAllPredefinedColumns);
  const predefinedTableQuestions = useAppSelector(getAllPredefinedTableQuestions);
  const allSavedQueries: SavedQuery[] = useAppSelector(getAllSavedQueries);

  const featureFlags = useOldStateSelector(getUserFeatureFlags);
  const canLeftLeadership = featureFlags?.includes('left_leadership');
  const canPitchbook = featureFlags?.includes('pitchbook_scrapings');
  const canPartnershipData = featureFlags?.includes('partnership_data');
  const canFinanceData = featureFlags?.includes('finance_column');
  const userId = useOldStateSelector(getUserId);

  let predefinedItems = [];
  if (actionType === SAVED_QUERY_ACTION_TYPES.COLUMN && predefinedColumns) {
    predefinedItems = predefinedColumns;
  }
  if (actionType === SAVED_QUERY_ACTION_TYPES.ASK && predefinedTableQuestions) {
    predefinedItems = predefinedTableQuestions;
  }

  predefinedItems = predefinedItems
    .filter((item: any) => {
      if (item?.type === 'predefined_custom_analytics') {
        return false;
      }
      switch (item.data_name) {
        case LEFT_LEADERSHIP_DATA_NAME:
          return canLeftLeadership;
        case PITCHBOOK_SCRAPINGS_DATA_NAME:
          return canPitchbook;
        case SAP_PARTNERSHIP_DATA_NAME:
          return canPartnershipData;
        case AWS_PARTNERSHIP_DATA_NAME:
          return canPartnershipData;
        case MSFT_PARTNERSHIP_DATA_NAME:
          return canPartnershipData;
        default:
          return true;
      }
    })
    .map((column: any) => {
      return {
        ...column,
        parameters: {
          question: column.question,
          answer_type: column?.answer_type || 'text',
          model: CHAT_GPT_MODEL_VERSION,
          fields: column?.fields
        }
      };
    });

  let savedQueries: PlatterItem[] = [];
  if (!isEmpty(allSavedQueries)) {
    const getIsQueryMine = (query: SavedQuery) => query.owner.id === userId;
    const getSavedQueryCategory = (query: SavedQuery) => {
      if (query.parameters?.category) {
        return query.parameters.category;
      }
      if (query.isShared && !getIsQueryMine(query)) {
        return CATEGORIES.SHARED_WITH_ME;
      }
      return CATEGORIES.MY_SAVED_COLUMNS;
    };
    const transleteSavedQueryColumns = (columns: SavedQuery[]) => {
      return columns.map((query) => {
        return {
          data_name: null,
          title: query.name,
          type: query.parameters?.type || 'custom_analytics',
          parameters: { ...query.parameters, saved_query_id: query.id },
          category: getSavedQueryCategory(query),
          id: query.id
        };
      });
    };
    savedQueries = transleteSavedQueryColumns(
      Object.values(allSavedQueries).filter((savedQuery) => savedQuery.type === actionType)
    ).sort((sq1, sq2) => {
      if (sq1.category !== sq2.category) {
        return sq1.category === CATEGORIES.MY_SAVED_COLUMNS ? -1 : 1;
      }
      return 0;
    });
  }

  const allItems: PlatterItem[] = [
    ...savedQueries,
    ...getPlatterEmptyColumns(actionType),
    ...getPlatterBasicColumns(actionType),
    ...getPlatterFinanceColumns(actionType, canFinanceData),
    ...predefinedItems
  ];

  const categorizedItems = allItems?.reduce((categories, column: PlatterItem) => {
    const featureFlagName =
      column.data_name && COLUMN_TYPE_TO_FEATURE_FLAG_NAME_TYPED?.[column.data_name.toUpperCase()];
    if (featureFlagName && !featureFlags?.includes(featureFlagName)) {
      return categories;
    }
    categories[column.category] = categories[column.category] || [];
    categories[column.category].push(column);
    return categories;
  }, {} as Record<LibraryCategoryType, PlatterItem[]>);

  const sortedCategories = Object.keys(categorizedItems).sort(
    categoriesSorter
  ) as LibraryCategoryType[];

  const columnsByCategories: Record<LibraryCategoryType, PlatterItem[]> = Object.fromEntries([
    ...sortedCategories.map((key) => [key, categorizedItems[key]])
  ]);

  const CATEGORY_TO_BACKGROUND_COLOR: Record<string, string> = {
    [CATEGORIES.MY_SAVED_COLUMNS]: 'accent_colors.bright_blue_bg1',
    [CATEGORIES.SHARED_WITH_ME]: 'accent_colors.bright_blue_bg1',
    [CATEGORIES.MANUAL]: 'accent_colors.orchid_bg1',
    [CATEGORIES.BASIC]: 'accent_colors.poor_orange_bg1'
  };

  const CATEGORY_TO_COLOR: Record<string, string> = {
    [CATEGORIES.MY_SAVED_COLUMNS]: 'accent_colors.bright_blue',
    [CATEGORIES.SHARED_WITH_ME]: 'accent_colors.bright_blue',
    [CATEGORIES.MANUAL]: 'accent_colors.orchid',
    [CATEGORIES.BASIC]: 'colors.poor_orange'
  };

  const getCategoryColor = (category: LibraryCategoryType | string) => {
    if (category in CATEGORY_TO_COLOR) {
      return CATEGORY_TO_COLOR[category as LibraryCategoryType];
    }

    return 'accent_colors.dark_lavender';
  };

  const getCategoryBackgroundColor = (category: LibraryCategoryType | string) => {
    if (category in CATEGORY_TO_BACKGROUND_COLOR) {
      return CATEGORY_TO_BACKGROUND_COLOR[category as LibraryCategoryType];
    }
    return 'accent_colors.dark_lavender_bg1';
  };
  return { columnsByCategories, getCategoryColor, getCategoryBackgroundColor };
}
