import { fetchCustomColumnOfListAction } from 'actions/company_lists/fetch-custom-column-of-list-action';
import { pusherBindToCARecordId } from 'actions/custom-analytics/create-new-post-in-custom-analytics-thread-async-action';
import { loadNotificationByIdAction } from 'actions/notifications/load-user-notification-by-id-action';
import { updateWorkflowSubmittedRecordsAction } from 'actions/workflows/update-workflow-submitted-records-action';
import { usePusher } from 'hooks/PusherProvider';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addPostStatusToThread, getThreadKey } from 'reducer/custom-analytics';
import { getUserId } from 'selectors/user';
import { getHomePageChannelName } from 'utils/pusher-utils';

export default function PusherHomePageListener() {
  const pusher = usePusher();
  const userId = useOldStateSelector(getUserId);
  const [channel, setChannel] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!pusher || !userId) {
      return;
    }
    const channelName = getHomePageChannelName(userId);
    const homepageChannel = pusher.subscribe(channelName);
    setChannel(homepageChannel);

    return () => {
      pusher.unsubscribe(channelName);
    };
  }, [userId, pusher]);

  useEffect(() => {
    if (!channel) {
      return;
    }
    channel.bind('notifications', (data) => {
      const { notification_id } = data;
      dispatch(loadNotificationByIdAction(notification_id));
    });
  }, [channel, dispatch]);

  useEffect(() => {
    if (!channel) {
      return;
    }

    channel.bind('custom_analytics_record', (data) => {
      const { post_id, record_id, company_list_id } = data;

      if (record_id && company_list_id && post_id) {
        const threadKey = getThreadKey(company_list_id);

        dispatch(
          pusherBindToCARecordId({
            listId: company_list_id,
            recordId: record_id,
            threadKey,
            postId: post_id
          })
        );
      }
    });
    return () => {
      channel?.unbind('custom_analytics_record');
    };
  }, [channel, dispatch]);

  useEffect(() => {
    if (!channel) {
      return;
    }
    channel.bind('custom_column_added', (data) => {
      const { company_list_id, id: custom_column_id } = data;
      if (custom_column_id && company_list_id) {
        dispatch(fetchCustomColumnOfListAction(company_list_id, custom_column_id));
      }
    });
    return () => {
      channel?.unbind('custom_column_added');
    };
  }, [channel, dispatch]);

  useEffect(() => {
    if (!channel) {
      return;
    }
    channel.bind('workflow_running_stage', (data) => {
      const { company_list_id, starting_step, post_id } = data;
      const threadKey = getThreadKey(company_list_id);
      if (starting_step === 'end') {
        dispatch(
          addPostStatusToThread({
            threadKey,
            postId: post_id,
            status: `100%`,
            statusMessage: 'Workflow completed',
            currentStepId: starting_step
          })
        );
      } else {
        dispatch(
          addPostStatusToThread({
            threadKey,
            postId: post_id,
            currentStepId: starting_step
          })
        );
      }
    });
    return () => {
      channel?.unbind('workflow_running_stage');
    };
  }, [channel, dispatch]);

  useEffect(() => {
    if (!channel) {
      return;
    }
    channel.bind('start_bind', (data) => {
      const { company_list_id, workflow_id, record_ids, post_ids, step_id } = data;

      dispatch(
        updateWorkflowSubmittedRecordsAction({
          listId: company_list_id,
          customAnalyticsRecordId: workflow_id,
          recordIds: record_ids,
          postIds: post_ids,
          stepId: step_id
        })
      );

      return () => {
        channel?.unbind('start_bind');
      };
    });
  }, [channel, dispatch]);

  return null;
}
