import {
  Autocomplete,
  AutocompleteRenderInputParams,
  ListItem,
  Stack,
  TextField,
  Theme,
  Typography,
  useTheme
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { MLTooltipProvider } from 'hooks/useMLTooltip';
import ArrowIcon from 'icons/ArrowIcon';
import React from 'react';

import MLChip from './MLChip';
import MLInlineStack from './MLInlineStack';
import MLResizableCustomTextComponent from './MLResizableCustomTextComponent';

const autocompleteSx = {
  '& .MuiAutocomplete-popupIndicator': {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
};

const checkboxSx = {
  color: 'colors.ui_border',
  width: '16px',
  height: '16px',
  margin: '8px',
  marginLeft: '0'
};

const getTextboxSx = (theme: Theme, params: AutocompleteRenderInputParams) => {
  return {
    input: {
      ...params.InputProps,
      sx: {
        ...theme.typography.text1_Normal
      }
    }
  };
};

const getListItemSx = (theme: Theme) => {
  return {
    ...theme.typography.text1_Normal,
    '&[aria-selected="true"]': {
      backgroundColor: 'transparent !important'
    },
    '&[aria-selected="true"]:hover': {
      backgroundColor: `${theme.palette.action.hover} !important`
    }
  };
};

const SELECT_ALL = 'Select All';

interface selectableOption {
  name: string;
}

interface MLMultiselectProps {
  options: selectableOption[];
  selectedOptions: selectableOption[];
  onChange: (event: React.SyntheticEvent, value: selectableOption[]) => void;
  noOptionsText?: string;
  notFoundText?: string;
  placeHolder?: string;
  onRemove?: (item: any) => void;
}

export default function MLMultiselect({
  options,
  placeHolder,
  selectedOptions,
  noOptionsText,
  notFoundText,
  onChange,
  onRemove
}: MLMultiselectProps) {
  const theme = useTheme();
  const allOptionsSelected = options.length === selectedOptions.length;

  const handleChange = (event: React.SyntheticEvent, value: selectableOption[]) => {
    if (value.some((option) => option.name === SELECT_ALL)) {
      if (allOptionsSelected) {
        onChange(event, []);
      } else {
        onChange(event, options);
      }
    } else {
      onChange(event, value);
    }
  };

  return (
    <Stack gap="8px">
      <Autocomplete
        multiple
        options={options.length > 0 ? [{ name: SELECT_ALL }, ...options] : []}
        value={selectedOptions}
        onChange={handleChange}
        noOptionsText={
          <Typography variant="text1_Normal">
            {options.length === 0 ? noOptionsText : notFoundText}
          </Typography>
        }
        disableCloseOnSelect
        disableClearable
        popupIcon={<ArrowIcon rotation={180} />}
        getOptionLabel={(option) => option.name}
        renderOption={(props, option, { selected }) => {
          const { ...optionProps } = props;
          const isSelectAll = option.name === SELECT_ALL;

          return (
            <ListItem
              {...optionProps}
              sx={{
                ...getListItemSx(theme),
                ...(isSelectAll && { ...theme.typography.text1_Semibold })
              }}>
              <Checkbox
                size="small"
                sx={checkboxSx}
                checked={selected || (option.name === SELECT_ALL && allOptionsSelected)}
              />
              <MLTooltipProvider>
                <MLResizableCustomTextComponent text={option.name} />
              </MLTooltipProvider>
            </ListItem>
          );
        }}
        renderInput={(params) => (
          <TextField
            placeholder={placeHolder}
            {...params}
            size="small"
            slotProps={getTextboxSx(theme, params)}
          />
        )}
        renderTags={() => null}
        sx={autocompleteSx}
      />
      {selectedOptions.length > 0 && (
        <MLInlineStack sx={{ flexWrap: 'wrap' }}>
          {selectedOptions.map((selection: any) => (
            <MLChip
              key={selection.name}
              onClose={() => onRemove?.(selection)}
              label={selection.name}
            />
          ))}
        </MLInlineStack>
      )}
    </Stack>
  );
}
