import BaseClient from './BaseClient';
import { getQueryParamsString, translateFilterParams } from './Utils';

const URL_PREFIX = 'explore';

export default class ExploreClient extends BaseClient {
  fetchAllExploreLists({
    num = 50,
    all = false,
    record_only = false,
    last_fetched_date = null,
    folder_id = null,
    organizationId = 0,
    userId = 0
  }) {
    const params = getQueryParamsString(
      translateFilterParams({ num, all, organizationId, userId })
    );
    return this.get(
      `${URL_PREFIX}?record_only=${record_only ? '1' : '0'}${
        last_fetched_date ? '&last_fetched_date='.concat(last_fetched_date) : ''
      }${folder_id || folder_id === 0 ? '&folder_id='.concat(folder_id) : ''}&${params}`,
      this.token
    );
  }

  fetchExploreList(listId) {
    return this.get(`${URL_PREFIX}/${listId}`);
  }

  fetchPartialExploreList(listId, companyMetaIds) {
    return this.post(`${URL_PREFIX}/${listId}`, {
      company_meta_ids: companyMetaIds,
      company_metas_only: true
    });
  }

  getSemanticSearch(listId, searchTerm) {
    return this.get(
      `${URL_PREFIX}/${listId}/semantic_search?search_query=${searchTerm}`,
      this.token
    );
  }

  fetchExploreListDomainReport(listId) {
    return this.get(`${URL_PREFIX}/${listId}/domain_report`);
  }

  submitExploreListDomainReport(listId, company_meta_ids, filters, filtered_out_company_meta_ids) {
    return this.post(`${URL_PREFIX}/${listId}/domain_report`, {
      company_meta_ids,
      filters,
      filtered_out_company_meta_ids
    });
  }

  submitExploreListDomainReportTask(listId, domainReportId, section, parameters) {
    const sectionId = section.props.id;
    return this.post(`${URL_PREFIX}/${listId}/domain_report/${domainReportId}`, {
      section_id: sectionId,
      parameters
    });
  }

  submitExploreListUpdateDomainReportSection(listId, domainReportId, sectionId, payload) {
    return this.put(
      `${URL_PREFIX}/${listId}/domain_report/${domainReportId}/sections/${sectionId}`,
      payload
    );
  }

  submitExploreListDomainReportSection(listId, domainReportId, sections) {
    return this.post(`${URL_PREFIX}/${listId}/domain_report/${domainReportId}/sections`, {
      sections
    });
  }

  submitExploreListUpdateDomainReportSections(listId, domainReportId, sections) {
    return this.put(`${URL_PREFIX}/${listId}/domain_report/${domainReportId}/sections`, {
      sections
    });
  }

  submitExploreListDomainReportSectionDelete(listId, domainReportId, sectionId) {
    return this.httpDelete(
      `${URL_PREFIX}/${listId}/domain_report/${domainReportId}/sections/${sectionId}`
    );
  }

  fetchExploreRunsSummary(listId) {
    return this.get(`${URL_PREFIX}/${listId}/runs_summary`);
  }
}
