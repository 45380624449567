import { DISCOVERY_TYPES } from 'constants/discovery';

import { parseNewLines } from './string-utils';

export enum AnswerType {
  TEXT = 'text',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  LINKS = 'links'
}

export type PromptConfig = {
  forEachCompany: boolean;
  companies: Map<any, any> | null;
  searchTheWeb: boolean;
  column: string | null;
  useColumn: boolean;
  columns: string[] | null;
  useColumns: boolean;
  resource: string | null;
  useDiscover?: boolean;
  discoverMethod?: string;
  resources: number[] | null;
  useDomainKnowledge: boolean;
  question: string;
  answerType: AnswerType;
  useReasoningModel?: boolean;
  useGlobalTablePoc?: boolean;
  usePlanner?: boolean;
  temperature: number;
  useTable: boolean;
  doTagging: boolean;
  useWorkFlow: boolean;
  simplified_question?: string;
  isSimplified?: boolean;
};

export const NULL_PROMPT_CONFIG: PromptConfig = {
  forEachCompany: false,
  companies: null,
  searchTheWeb: false,
  column: null,
  useColumn: false,
  columns: null,
  useColumns: false,
  resource: null,
  resources: null,
  useDomainKnowledge: false,
  useDiscover: false,
  discoverMethod: DISCOVERY_TYPES.TEXT,
  question: '',
  answerType: AnswerType.TEXT,
  useReasoningModel: false,
  usePlanner: false,
  temperature: 0,
  useTable: true,
  useWorkFlow: false,
  doTagging: false
};

type LinkObject = {
  title?: string;
  link?: string;
  url?: string;
  score?: number;
};

const linkObjectToMarkdown = (linkObject: LinkObject) => {
  return `[${linkObject.title || linkObject.link || linkObject.url}](${
    linkObject.link || linkObject.url
  })`;
};

const scoreSort = (item1: LinkObject, item2: LinkObject) => {
  if (item1?.score && item2?.score) {
    return item2.score - item1.score;
  }
  return 0;
};

export const formatAnswerBody = (body: string, answerType: AnswerType | undefined) => {
  switch (answerType) {
    case 'links':
      try {
        return JSON.parse(body)
          .sort(scoreSort)
          .map(
            (link: string | LinkObject, index: number) =>
              `${index + 1}. ${typeof link === 'string' ? link : linkObjectToMarkdown(link)}`
          )
          .join('\n');
      } catch {
        return parseNewLines(body);
      }
    default:
      break;
  }
  return parseNewLines(body);
};

export const getCustomAnalyticsMetaColumnId = (columnId: string) => `${columnId}-meta`;
