import { useAppUtils } from 'AppUtilsProvider';
import { submitAssistantMessageAction } from 'actions/assistant/submit-assistant-message-action';
import { updateExtraDataByTypeForCompaniesInListAction } from 'actions/company_extra_data/update-extra-data-for-companies-in-list-action';
import { submitHiddenGemsAction } from 'actions/company_lists/submit-hidden-gems-action';
import { highlightColumnAction } from 'actions/explore-table-ui/highlight-column-action';
import { addExploreCustomColumnAction } from 'actions/explore/add-discovery-custom-column-action';
import { sendUserEvent } from 'actions/users/send-user-event-action';
import { launchWorkflowAction } from 'actions/workflows/launch-workflow-action';
import { COMPANY_EXTRA_DATA_TYPES } from 'constants/company-extra-data';
import { TOAST_TYPES } from 'constants/toasts';
import { USER_EVENTS } from 'constants/userEvents';
import { useListContext } from 'hooks/ListContextProvider';
import { ActionType } from 'models/assistant/assistant-actions';
import { useRef } from 'react';
import { useAppDispatch } from 'types/store';
import { convertV1CaParametersToV2 } from 'utils/assistant/backwards-compatibility';
import { EXTRA_DATA_COLUMN_TYPE } from 'utils/extra-data-utils';
import { SAVED_QUERY_ACTION_TYPES } from 'utils/platter-utils';

import { PlatterItem } from './usePlatterItems';

interface PlatterItemActionsProps {
  handleClose: () => void;
  companyListId: number;
  actionType: string;
}

export function usePlatterItemActions({
  handleClose,
  companyListId,
  actionType
}: PlatterItemActionsProps) {
  const dispatch = useAppDispatch();
  const { addToast, cancelToast } = useAppUtils();
  const listContext = useListContext();
  const toastRef = useRef<any>(null);
  const { listId } = useListContext();

  const onColumnCreated = (columnSetting: any) => {
    cancelToast(toastRef.current);
    toastRef.current = null;
    const id = addToast('Adding new column succeed', TOAST_TYPES.SUCCESS);
    setTimeout(() => cancelToast(id), 2000);
    if (columnSetting?.type === EXTRA_DATA_COLUMN_TYPE) {
      dispatch(updateExtraDataByTypeForCompaniesInListAction(companyListId, columnSetting.id));
    }
    if (columnSetting.id) {
      dispatch(highlightColumnAction(companyListId, columnSetting.id, true));
    } else {
      dispatch(
        sendUserEvent(USER_EVENTS.FAILING_SCROLL_TO_COLUMN, {
          listId: companyListId,
          columnName: columnSetting.name,
          columnType: columnSetting.type,
          id: columnSetting.id,
          dataType: columnSetting.data_type,
          ...columnSetting
        })
      );
    }
  };

  const onCustomAnalyticsColumnClick = (column: PlatterItem) => {
    const assistantPrompt = convertV1CaParametersToV2(
      {
        ...column.parameters,
        for_each_company: actionType === SAVED_QUERY_ACTION_TYPES.COLUMN
      },
      listContext
    );

    let assistantActionType = null;
    switch (actionType) {
      case SAVED_QUERY_ACTION_TYPES.COLUMN:
        assistantActionType = ActionType.ADD_COLUMN;
        break;
      case SAVED_QUERY_ACTION_TYPES.WORKFLOW:
        assistantActionType = ActionType.WORKFLOW;
        break;
      case SAVED_QUERY_ACTION_TYPES.ASK:
        assistantActionType = ActionType.ASK_QUESTION;
        break;
      default:
        assistantActionType = ActionType.ADD_COLUMN;
        break;
    }
    assistantPrompt.action_type = assistantActionType;
    assistantPrompt.saved_query_id = column.parameters.saved_query_id;

    dispatch(submitAssistantMessageAction({ listId, newPromptConfig: assistantPrompt }));
  };

  const onColumnClick = (column: PlatterItem) => {
    if (actionType === SAVED_QUERY_ACTION_TYPES.WORKFLOW) {
      const workflowId = column.id;
      const workflowName = column.title;
      const plan = column.parameters;
      dispatch(launchWorkflowAction(companyListId, plan, workflowName, workflowId));
    }
    if (column.type?.includes('custom_analytics')) {
      onCustomAnalyticsColumnClick(column);
      handleClose();
      return;
    }
    if (column.data_name === 'hidden_gems') {
      dispatch(submitHiddenGemsAction(companyListId));
      handleClose();
      return;
    }
    const columnId = column.type === 'custom_column' ? null : column.data_name;
    const columnType = column.type === 'custom_column' ? column.data_name : column.type;

    dispatch(
      addExploreCustomColumnAction(
        companyListId,
        column.title,
        column.parameters,
        columnType,
        null,
        onColumnCreated,
        columnId
      )
    );
    if (column.type === 'finance_column') {
      dispatch(
        updateExtraDataByTypeForCompaniesInListAction(
          companyListId,
          COMPANY_EXTRA_DATA_TYPES.FINANCE
        )
      );
    }
    handleClose();
  };

  return { onColumnClick };
}
