import { Box, DialogTitle, Tab, Tabs, Typography } from '@mui/material';
import MLIconButton from 'design-system/MLIconButton';
import MLInlineStack from 'design-system/MLInlineStack';
import CloseIcon from 'design-system/icons/platform/CloseIcon';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import React from 'react';
import { getUserFeatureFlags } from 'selectors/user';
import { SAVED_QUERY_ACTION_TYPES } from 'utils/platter-utils';

const TABS: Record<string, string> = Object.freeze({
  [SAVED_QUERY_ACTION_TYPES.COLUMN]: 'Columns',
  [SAVED_QUERY_ACTION_TYPES.ASK]: 'Questions',
  [SAVED_QUERY_ACTION_TYPES.WORKFLOW]: 'Workflows'
});

const tabStyle = {
  padding: '0px',
  gap: '8px',
  margin: 0
};

const tabsStyle = {
  padding: 0,
  margin: 0,
  '& .MuiTabs-flexContainer': {
    gap: '16px'
  }
};

type QueryLibraryDialogTitleProps = {
  onClose: () => void;
  selectedTab: string;
  setSelectedTab: (newValue: string) => void;
};
function QueryLibraryDialogTitle({
  onClose,
  selectedTab,
  setSelectedTab
}: QueryLibraryDialogTitleProps) {
  const featureFlags = useOldStateSelector(getUserFeatureFlags);
  const canSeeWorkflowTab = featureFlags?.includes('workflow_library');
  const handleChangeOption = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  return (
    <DialogTitle display="flex" sx={{ padding: '0px 24px', height: '60px' }}>
      <MLInlineStack width="100%" justifyContent="space-between">
        <Typography variant="h2_Bold">Query Library</Typography>
        <Box alignSelf="flex-end">
          <Tabs
            value={selectedTab}
            onChange={handleChangeOption}
            textColor="secondary"
            sx={tabsStyle}
            centered
            TabIndicatorProps={{
              style: {
                maxHeight: '2px'
              }
            }}>
            {Object.keys(TABS)
              .filter((tab) => tab !== SAVED_QUERY_ACTION_TYPES.WORKFLOW || canSeeWorkflowTab)
              .map((actionType, index) => (
                <Tab
                  disableRipple
                  key={index}
                  value={actionType}
                  sx={tabStyle}
                  label={
                    <MLInlineStack>
                      <Typography variant={actionType === selectedTab ? 'h3_Bold' : 'h3_Normal'}>
                        {TABS[actionType]}
                      </Typography>
                    </MLInlineStack>
                  }
                />
              ))}
          </Tabs>
        </Box>
        <MLIconButton onClick={onClose}>
          <CloseIcon />
        </MLIconButton>
      </MLInlineStack>
    </DialogTitle>
  );
}

export default QueryLibraryDialogTitle;
