import { Stack, Typography } from '@mui/material';
import {
  SUGGESTION_ID_TYPE_TO_HOVER_BG_COLOR,
  SUGGESTION_ID_TYPE_TO_ICON,
  suggestionIdTypeToSx
} from 'constants/assistant';
import MLIcon from 'design-system/MLIcon';
import { MentionId, SuggestionIdType } from 'models/assistant/types';
import React from 'react';
import { limitLength } from 'utils/string-utils';

interface MentionListItemnProps {
  id: MentionId;
  label: string;
  focused: boolean;
  focusedColor?: string;
}

const LABEL_CUTOFF = 30;

function MentionListItem({ id, label, focused, focusedColor }: MentionListItemnProps) {
  // Choose the icon based on the suggestion's category.
  const [hover, setHover] = React.useState(false);
  const [suggestionIdType] = id.split(':') as [SuggestionIdType];
  const sx = suggestionIdTypeToSx(suggestionIdType);
  const bgColor =
    focused || hover
      ? focusedColor || SUGGESTION_ID_TYPE_TO_HOVER_BG_COLOR[suggestionIdType]
      : null;
  return (
    <Stack
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      gap="8px"
      direction="row"
      alignItems="center"
      sx={{
        padding: '8px 12px',
        borderRadius: '4px',
        backgroundColor: bgColor
      }}>
      <MLIcon
        icon={React.createElement(SUGGESTION_ID_TYPE_TO_ICON[suggestionIdType])}
        size="small"
        sx={sx}
      />
      <Typography variant="text1_Normal">{limitLength(label, LABEL_CUTOFF)}</Typography>
    </Stack>
  );
}

export default MentionListItem;
