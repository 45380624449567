import { Menu, Stack } from '@mui/material';
import { FINANCE_COLUMN_TYPES } from 'constants/finance';
import useMenuManagement from 'design-system/hooks/useMenuManagement';
import { getCustomScrollbarStyles } from 'design-system/styles/customScrollBar';
import { MLTooltipProvider } from 'hooks/useMLTooltip';
import { isEqual } from 'lodash';
import React, { useMemo } from 'react';
import { appTheme } from 'theme';
import {
  formatFinanceStrFromData,
  getConsensusFromData,
  getIsEstimatedFromData
} from 'utils/finance-utils';
import { isEmpty } from 'utils/objects-utils';

import TableRowLoading from './TableRowLoading';
import FinanceDataMenuItem from './finance/FinanceDataMenuItem';
import RenderProfitInlineCell from './finance/RenderProfitInlineCell';
import { FinanceData } from './finance/types';

interface value {
  data: Record<string, FinanceData[]> | null;
  loading?: boolean;
}
interface RenderProfitDataProps {
  value: value;
  color: string;
}

export interface FinanceDataItems {
  finalValue: string;
  isEstimated: boolean;
  financeData: FinanceData[];
}

function RenderProfitData({ value, color }: RenderProfitDataProps) {
  const menuManagment = useMenuManagement();
  const { onClose, open, anchorEl } = menuManagment;
  const menuBackgroundColor = appTheme.palette.colors.dark_bg;

  const [profitFinanceData, financeDataItems] = useMemo(() => {
    if (isEmpty(value?.data)) {
      return [null, null];
    }
    const { data } = value;
    let profit = null;
    const dataItems = Object.keys(data)
      .filter((source) => source !== FINANCE_COLUMN_TYPES.REVENUES && !isEmpty(data[source]))
      .reduce((acc, financeType) => {
        if (financeType !== FINANCE_COLUMN_TYPES.REVENUES && !isEmpty(data[financeType])) {
          const consensus = getConsensusFromData(data[financeType]);
          const strValue = formatFinanceStrFromData(consensus);
          if (strValue) {
            const val = {
              finalValue: strValue,
              isEstimated: getIsEstimatedFromData(data[financeType]),
              financeData: data[financeType]
            };
            if (financeType === FINANCE_COLUMN_TYPES.PROFIT) {
              profit = val;
            } else {
              acc[financeType] = val;
            }
          }
        }
        return acc;
      }, {} as Record<string, FinanceDataItems>);
    return [profit, dataItems];
  }, [value]);

  if (value?.loading) {
    return <TableRowLoading />;
  }

  if (isEmpty(value) || !profitFinanceData || isEmpty(financeDataItems)) {
    return null;
  }

  return (
    <Stack width="100%" padding={0}>
      <MLTooltipProvider>
        <RenderProfitInlineCell
          menuManagment={menuManagment}
          financeDataItems={financeDataItems}
          profitFinanceData={profitFinanceData}
          color={color}
        />
      </MLTooltipProvider>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        disableScrollLock
        slotProps={{
          paper: { style: { backgroundColor: menuBackgroundColor, paddingRight: '4px' } }
        }}>
        <Stack
          gap="8px"
          paddingRight={'4px'}
          maxHeight={'400px'}
          overflow="auto"
          sx={{ ...getCustomScrollbarStyles({ size: 'small' }) }}>
          {Object.keys(financeDataItems)?.map((financeType, idx) => (
            <FinanceDataMenuItem
              key={idx}
              menuBackgroundColor={'#FFFFFF1A'}
              data={financeDataItems[financeType].financeData}
              isEstimated={financeDataItems[financeType].isEstimated}
              financeType={financeType}
            />
          ))}
        </Stack>
      </Menu>
    </Stack>
  );
}

export default React.memo(RenderProfitData, isEqual);
