import { Dialog } from '@mui/material';
import MLButton from 'design-system/MLButton';
import LibraryIcon from 'design-system/icons/basic/LibraryIcon';
import React, { useState } from 'react';
import { SAVED_QUERY_ACTION_TYPES } from 'utils/platter-utils';

import QueryLibraryContent from './QueryLibraryContent';
import QueryLibraryDialogTitle from './QueryLibraryDialogTitle';

type QueryLibraryDialogProps = {
  listId: number;
};

function QueryLibraryDialog({ listId }: QueryLibraryDialogProps) {
  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
  };

  const [selectedTab, setSelectedTab] = useState(SAVED_QUERY_ACTION_TYPES.COLUMN as string);

  return (
    <React.Fragment>
      <MLButton onClick={() => setOpen(true)} startIcon={<LibraryIcon />}>
        Library
      </MLButton>
      <Dialog
        open={open}
        onClose={onClose}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              minWidth: 1100
            }
          }
        }}>
        <QueryLibraryDialogTitle
          onClose={onClose}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        <QueryLibraryContent onClose={onClose} companyListId={listId} actionType={selectedTab} />
      </Dialog>
    </React.Fragment>
  );
}

export default QueryLibraryDialog;
