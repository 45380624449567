import { ListItemIcon, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { fillCellsForSelectedCompaniesAction } from 'actions/explore/fill-cells-for-selected-companies-action';
import MLButton from 'design-system/MLButton';
import PlayIcon from 'icons/PlayIcon';
import RerunIcon from 'icons/RerunIcon';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

function ListRunActionsMenu({ listId }) {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onRunTypeSelected = (recompute) => {
    dispatch(fillCellsForSelectedCompaniesAction(listId, recompute));
    setAnchorEl(null);
  };

  return (
    <Stack alignItems="center">
      <Menu
        id="demo-customized-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}>
        <MenuItem onClick={() => onRunTypeSelected(false)}>
          <ListItemIcon>
            <PlayIcon />
          </ListItemIcon>
          <Typography>Fill empty cells</Typography>
        </MenuItem>
        <MenuItem onClick={() => onRunTypeSelected(true)}>
          <ListItemIcon>
            <RerunIcon />
          </ListItemIcon>
          <Typography>Rerun all columns</Typography>
        </MenuItem>
      </Menu>
      <MLButton onClick={handleClick} startIcon={<PlayIcon />}>
        Run
      </MLButton>
    </Stack>
  );
}

ListRunActionsMenu.propTypes = {
  listId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default ListRunActionsMenu;
