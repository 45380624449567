import { Autocomplete, Button, Stack, TextField, Typography } from '@mui/material';
import { useAppUtils } from 'AppUtilsProvider';
import { addTeamAction } from 'actions/teams/add-team-action';
import { deleteTeamAction } from 'actions/teams/delete-team-action';
import { updateTeamAction } from 'actions/teams/update-team-action';
import CustomAvatarGroup from 'components/CustomAvatarGroup';
import RenderUserOption from 'components/autocomplete/RenderUserOption';
import CustomAvatar from 'components/tile/CustomAvatar';
import MLIconButton from 'design-system/MLIconButton';
import CloseIcon from 'design-system/icons/platform/CloseIcon';
import DeleteIcon from 'icons/DeleteIcon';
import EditIcon from 'icons/EditIcon';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import DeleteTeamDialog from './DeleteTeamDialog';

const usernameSort = (user1, user2) => {
  if (!user1 || !user2) return 0;
  if (user1.username < user2.username) {
    return -1;
  }
  if (user1.username > user2.username) {
    return 1;
  }
  return 0;
};

function TeamCard({ team, orgUsers, teamNames, onCancel }) {
  const { addToast } = useAppUtils();
  const reduxDispatch = useDispatch();
  const [inEdit, setInEdit] = useState(Boolean(onCancel));
  const [inputValue, setInputValue] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(null);
  const [teamName, setTeamName] = useState('New Team');
  const [selectValue, setSelectValue] = useState(null);
  const [teamUsers, setTeamUsers] = useState([]);
  const usernames = orgUsers
    ?.map((user) => user.username)
    .filter((username) => !teamUsers.includes(username));
  const clearInput = () => {
    setSelectValue(null);
    setInputValue('');
  };
  const teamNameAlreadyExists = teamNames.filter((name) => name !== team.name).includes(teamName);
  const noChange =
    !onCancel &&
    JSON.stringify(teamUsers.sort()) ===
      JSON.stringify(team.users.map((user) => user.username).sort()) &&
    teamName === team.name;
  const handleSelect = (_event, newValue) => {
    setTeamUsers((prev) => [...prev, newValue]);
    clearInput();
  };
  const handleRemove = (username) => {
    setTeamUsers((prev) => prev.filter((item) => item !== username));
  };

  const handleClose = () => {
    setInEdit(false);
    clearInput();
  };

  const handleSaveChanges = () => {
    if (onCancel) {
      reduxDispatch(
        addTeamAction(
          teamName,
          orgUsers.filter((user) => teamUsers.includes(user.username)).map((user) => user.id),
          addToast
        )
      );
      onCancel();
    } else {
      reduxDispatch(
        updateTeamAction(
          team.id,
          teamName,
          orgUsers.filter((user) => teamUsers.includes(user.username)).map((user) => user.id),
          addToast
        )
      );
    }
    handleClose();
  };

  const handleDelete = () => {
    reduxDispatch(deleteTeamAction(team.id, addToast));
    handleClose();
  };

  const handleCancel = () => {
    handleClose();
    if (onCancel) {
      onCancel();
    }
  };

  useEffect(() => {
    if (team) {
      setTeamUsers(team.users.map((user) => user.username));
      setTeamName(team.name);
    }
  }, [team]);
  return (
    <Stack
      gap="16px"
      sx={{
        border: '1px solid',
        borderRadius: '4px',
        paddingX: '16px',
        paddingY: '8px',
        borderColor: 'colors.selected',
        backgroundColor: 'colors.gray_bg',
        boxShadow: '0px 1px 2px rgba(9, 30, 66, 0.2), 0px 0px 0px rgba(9, 30, 66, 0.31)'
      }}>
      <Stack gap="16px" direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" gap="16px" alignItems="center">
          <CustomAvatar name={teamName} pictureUrl={team?.picture} org size={32} />
          {inEdit ? (
            <TextField
              variant="standard"
              error={teamNameAlreadyExists}
              helperText={teamNameAlreadyExists ? 'Team name already exists' : ''}
              sx={{ backgroundColor: 'colors.gray_bg' }}
              value={teamName}
              onChange={(event) => setTeamName(event.target.value)}
            />
          ) : (
            <Typography variant="text1_Normal" color="colors.primary_text">
              {teamName}
            </Typography>
          )}
        </Stack>
        {inEdit && !onCancel && (
          <MLIconButton
            tooltipText="Delete Team"
            sx={{ color: 'colors.negative_red' }}
            onClick={() => setDeleteOpen(true)}>
            <DeleteIcon />
          </MLIconButton>
        )}
        <Stack direction="row" gap="16px" alignItems="center">
          <CustomAvatarGroup
            entities={
              inEdit && orgUsers
                ? orgUsers
                    .sort(usernameSort)
                    .filter((item) => teamUsers.includes(item.username))
                    .map((user) => ({ ...user, name: user.username }))
                : (team?.users || [])
                    ?.map((user) => ({ ...user, name: user.username }))
                    .sort(usernameSort)
            }
          />
          <MLIconButton tooltipText="Edit Team" onClick={() => setInEdit(!inEdit)}>
            <EditIcon />
          </MLIconButton>
        </Stack>
      </Stack>
      {inEdit && orgUsers && (
        <React.Fragment>
          <Stack gap="4px">
            {teamUsers && teamUsers.length > 0 ? (
              teamUsers.map((username) => {
                const user = orgUsers?.find((item) => item.username === username);
                return (
                  <Stack
                    key={user.id}
                    direction="row"
                    gap="8px"
                    alignItems="center"
                    sx={{
                      borderLeft: '2px solid',
                      borderColor: 'colors.selected',
                      backgroundColor: 'colors.hover_on_primary_bg',
                      paddingLeft: '12px',
                      paddingRight: '4px',
                      paddingY: '2px'
                    }}>
                    <CustomAvatar name={user.username} pictureUrl={user.picture} size={24} />
                    <Typography variant="text1_Normal" color="colors.primary_text">
                      {user.username}
                    </Typography>
                    <MLIconButton
                      tooltipText="Remove"
                      sx={{ marginLeft: 'auto' }}
                      onClick={() => handleRemove(user.username)}>
                      <CloseIcon />
                    </MLIconButton>
                  </Stack>
                );
              })
            ) : (
              <Typography variant="text1_Normal" color="colors.primary_text" textAlign="left">
                Add users to team below
              </Typography>
            )}
          </Stack>
          {usernames && (
            <Autocomplete
              options={usernames}
              value={selectValue}
              size="small"
              onChange={handleSelect}
              inputValue={inputValue}
              onInputChange={(_event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              renderOption={(props, option) => (
                <RenderUserOption {...props} option={option} users={orgUsers} />
              )}
              sx={{ width: '100%' }}
              renderInput={(params) => <TextField {...params} label="Select Users" />}
            />
          )}
          <Stack direction="row" gap="8px" alignItems="center" justifyContent="space-between">
            <Button onClick={handleCancel}>Cancel</Button>
            <Button
              disabled={noChange || teamNameAlreadyExists}
              variant="contained"
              onClick={handleSaveChanges}>
              {onCancel ? 'Create Team' : 'Save Changes'}
            </Button>
          </Stack>
        </React.Fragment>
      )}
      <DeleteTeamDialog
        open={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        onDelete={handleDelete}
        teamName={team.name}
      />
    </Stack>
  );
}

TeamCard.propTypes = {
  team: PropTypes.object,
  orgUsers: PropTypes.array,
  teamNames: PropTypes.array,
  onCancel: PropTypes.func
};

export default TeamCard;
