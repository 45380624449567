import { useOldStateSelector } from 'hooks/useOldStateSelector';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearExploreTableUIState } from 'reducer/explore-table-ui';
import { getExploreTableSelectedCompanies } from 'selectors/explore-table-ui';
import { getUserHasRunsLeft } from 'selectors/user';

import { BottomBarDiscovery } from './BottomBarDiscovery';

export default function BottomBarDiscoveryConnector({ listId, onClose, ...args }) {
  const dispatch = useDispatch();
  const selectedCompanies = useSelector((state) => getExploreTableSelectedCompanies(state, listId));

  const userHasRunsLeft = useOldStateSelector(getUserHasRunsLeft);

  const onCloseBottomBar = useCallback(() => {
    dispatch(clearExploreTableUIState({ listId }));
    onClose?.();
  }, [listId, onClose, dispatch]);
  return (
    <BottomBarDiscovery
      listId={listId}
      selectedCompanies={selectedCompanies}
      onClose={onCloseBottomBar}
      noRunsLeft={!userHasRunsLeft}
      {...args}
    />
  );
}

BottomBarDiscoveryConnector.propTypes = {
  listId: PropTypes.number,
  onClose: PropTypes.func
};
