import {
  DomainReportCASections,
  DomainReportSectionType,
  domainReportCASectionsValues
} from 'models/domain-report/types';

const DomainReportCASectionTypeSet = new Set<DomainReportSectionType>(domainReportCASectionsValues);

export function isDomainReportCASectionType(
  value: DomainReportSectionType
): value is DomainReportCASections {
  return DomainReportCASectionTypeSet.has(value);
}
