import PropTypes from 'prop-types';
import React from 'react';

export default function YesNoIcon({ color }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M18 8.00001L10.3484 16.608C10.1587 16.8214 9.82915 16.8329 9.62505 16.6333L6 13.087"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
      />
    </svg>
  );
}

YesNoIcon.propTypes = {
  color: PropTypes.string
};

YesNoIcon.defaultProps = {
  color: '#5C5E76'
};
