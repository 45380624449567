import { Button, Stack, TextField, Typography } from '@mui/material';
import { launchWorkflowAction } from 'actions/workflows/launch-workflow-action';
import { updateWorkflowPlanAction } from 'actions/workflows/update-workflow-plan-action';
import LoaderComponent from 'components/LoaderComponent';
import WorkFlowPlanChart from 'components/work-flow/WorkFlowPlanChart';
import { WORKFLOW_STATUSES } from 'constants/workflows';
import MLIconButton from 'design-system/MLIconButton';
import MLInlineStack from 'design-system/MLInlineStack';
import CloseIcon from 'design-system/icons/platform/CloseIcon';
import WarningIcon from 'icons/WarningIcon';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getThreadKey } from 'reducer/custom-analytics';
import {
  getCurrentlyRunningPostForWorkflowFromOriginalWorkflowIdInList,
  getCustomAnalyticsPlanByThreadAndPostId,
  getCustomAnalyticsThreadSpecificPostById,
  getIsPostWorkflowRunByThreadIdAndPostId,
  getPostNameByThreadIdAndPostId
} from 'selectors/custom-analytics';
import {
  getWorkflowHistoryPlanByListIdAndWorkflowId,
  getWorkflowInfoByListIdAndWorkflowId,
  getWorkflowModeByListIdAndWorkflowId,
  getWorkflowSelectedPostIdByListId,
  getWorkflowStatusByListIdAndWorkflowId
} from 'selectors/workflows';
import { appTheme } from 'theme';
import { isStringIsValidJson } from 'utils/string-utils';

import WorkflowFromComponent from './WorkflowFromComponent';

function WorkflowPlannerDialogStep({ listId, isJsonViewOpen, setIsJsonViewOpen }) {
  const dispatch = useDispatch();

  const selectedWorkflowId = useSelector((state) =>
    getWorkflowSelectedPostIdByListId(state, listId)
  );
  const plan = useSelector((state) =>
    getCustomAnalyticsPlanByThreadAndPostId(state, getThreadKey(listId), selectedWorkflowId)
  );
  const historyPlan = useSelector((state) =>
    getWorkflowHistoryPlanByListIdAndWorkflowId(state, listId, selectedWorkflowId)
  );

  const isWorkflowRun = useSelector((state) =>
    getIsPostWorkflowRunByThreadIdAndPostId(state, getThreadKey(listId), selectedWorkflowId)
  );
  const post = useSelector((state) => {
    return isWorkflowRun
      ? getCustomAnalyticsThreadSpecificPostById(state, getThreadKey(listId), selectedWorkflowId)
      : getCurrentlyRunningPostForWorkflowFromOriginalWorkflowIdInList(
          state,
          getThreadKey(listId),
          selectedWorkflowId
        );
  });

  const actualPostId = post?.postId;

  const workflowName = useSelector((state) =>
    getPostNameByThreadIdAndPostId(state, getThreadKey(listId), actualPostId || selectedWorkflowId)
  );

  const status = useSelector((state) =>
    getWorkflowStatusByListIdAndWorkflowId(state, listId, actualPostId)
  );
  const info = useSelector((state) =>
    getWorkflowInfoByListIdAndWorkflowId(state, listId, actualPostId)
  );
  const isFormMode = useSelector((state) =>
    getWorkflowModeByListIdAndWorkflowId(state, listId, selectedWorkflowId)
  );

  const currentStepId = post?.currentStepId;
  const isReadOnly = Boolean(historyPlan);

  const [editPlan, setEditPlan] = useState('{}');
  const [showError, setShowError] = useState(false);
  const [showSucceeded, setShowSucceeded] = useState(false);

  const isEditPlanValid = useMemo(() => isStringIsValidJson(editPlan), [editPlan]);
  const haveError = useMemo(
    () =>
      status === WORKFLOW_STATUSES.GENERATE_FAILED ||
      status === WORKFLOW_STATUSES.LAUNCH_FAILED ||
      status === WORKFLOW_STATUSES.STOP_FAILED ||
      status === WORKFLOW_STATUSES.RUN_FAILED,
    [status]
  );

  const workflowFinished = useMemo(() => status === WORKFLOW_STATUSES.RUN_FINISHED, [status]);

  useEffect(() => {
    setShowError(haveError);
  }, [haveError]);

  useEffect(() => {
    setShowSucceeded(workflowFinished);
  }, [workflowFinished]);

  useEffect(() => {
    if (isJsonViewOpen) {
      setEditPlan(JSON.stringify(plan ? plan : {}));
    }
  }, [isJsonViewOpen, plan]);

  const handleSubmitPlan = () => {
    dispatch(launchWorkflowAction(listId, plan, workflowName, selectedWorkflowId));
  };

  const onCloseJsonView = () => {
    setIsJsonViewOpen(false);
    setEditPlan('{}');
  };

  const onJsonChange = (event) => {
    setEditPlan(event.target.value);
  };

  const handleUpdatePlan = () => {
    dispatch(updateWorkflowPlanAction(listId, selectedWorkflowId, editPlan));
    onCloseJsonView();
  };

  const onClosePannel = () => {
    setShowError(false);
    setShowSucceeded(false);
  };

  const getStatusPannelTitle = () => {
    if (info) return info;
    return showError ? 'Something went wrong, please try again' : 'Workflow completed successfully';
  };

  const getStatusPannelBackgroundColor = () => {
    if (info) return 'colors.warning_yellow';
    return showError ? 'colors.hover_on_negative_red' : 'colors.hover_on_positive_green';
  };

  return (plan || historyPlan || haveError || isFormMode) &&
    status !== WORKFLOW_STATUSES.CREATING_FROM_LIST ? (
    <Stack sx={{ position: 'relative', height: '100%' }}>
      {(showError || showSucceeded) && (
        <MLInlineStack
          sx={{
            boxSizing: 'border-box',
            position: 'absolute',
            width: '100%',
            justifyContent: 'space-between',
            top: 0,
            left: 0,
            backgroundColor: getStatusPannelBackgroundColor(),
            boxShadow: '0px 4px 15px 0px #0000001F',
            padding: '4px 24px',
            zIndex: 100000
          }}>
          <MLInlineStack>
            <Typography
              variant="text1_Bold"
              color={info ? 'colors.primary_text' : 'colors.text_on_primary'}>
              {getStatusPannelTitle()}
            </Typography>
            {info && (
              <Button variant="outlined" onClick={handleSubmitPlan}>
                Relaunch
              </Button>
            )}
          </MLInlineStack>
          <MLIconButton onClick={onClosePannel}>
            <CloseIcon
              color={
                info
                  ? appTheme.palette.colors.primary_text
                  : appTheme.palette.colors.text_on_primary
              }
            />
          </MLIconButton>
        </MLInlineStack>
      )}
      {isFormMode ? (
        <WorkflowFromComponent listId={listId} isReadOnly={isReadOnly} />
      ) : (
        <WorkFlowPlanChart
          listId={listId}
          postId={selectedWorkflowId}
          plan={historyPlan || plan}
          currentStepId={currentStepId}
          isReadOnly={isReadOnly}
        />
      )}
      {isJsonViewOpen && (
        <Stack
          direction="column"
          gap="24px"
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            height: '100%',
            width: '450px',
            backgroundColor: 'colors.primary_bg',
            borderLeft: '1px solid',
            borderLeftColor: 'colors.ui_border',
            boxShadow: '0px 4px 15px 0px #0000001F',
            padding: '24px 16px',
            boxSizing: 'border-box'
          }}>
          <MLInlineStack sx={{ justifyContent: 'space-between' }}>
            <Typography variant="h3_Bold" color="colors.dark_bg">
              Workflow code
            </Typography>
            {!isEditPlanValid && (
              <MLInlineStack>
                <Typography variant="text2_Normal" color="accent_colors.red">
                  JSON not valid
                </Typography>
                <WarningIcon size={16} />
              </MLInlineStack>
            )}
          </MLInlineStack>
          <TextField
            multiline
            rows={1}
            value={editPlan}
            onChange={onJsonChange}
            slotProps={{
              input: {
                sx: {
                  height: '100%',
                  display: 'flex',
                  alignItems: 'flex-start',
                  '.MuiInputBase-inputMultiline': { height: '100% !important' }
                }
              }
            }}
            sx={{ height: '100%' }}
          />
          <MLInlineStack sx={{ justifyContent: 'flex-end' }}>
            <Button variant="outlined" onClick={onCloseJsonView}>
              Cancel changes
            </Button>
            <Button variant="contained" disabled={!isEditPlanValid} onClick={handleUpdatePlan}>
              Save and regenerate
            </Button>
          </MLInlineStack>
        </Stack>
      )}
    </Stack>
  ) : (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        backgroundColor: 'primary.white'
      }}>
      <LoaderComponent />
    </Stack>
  );
}

WorkflowPlannerDialogStep.propTypes = {
  listId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isJsonViewOpen: PropTypes.bool,
  setIsJsonViewOpen: PropTypes.func
};

export default WorkflowPlannerDialogStep;
