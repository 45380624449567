import CustomMarkdown from 'components/CustomMarkdown';
import MLResizableCustomTextComponent from 'design-system/MLResizableCustomTextComponent';
import React from 'react';
import { AnswerType, formatAnswerBody } from 'utils/custom-analytics-utils';

type Props = {
  answerText: string;
  answerType: AnswerType;
  listId: number;
};

export default function AnswerTextComponent({ answerText, answerType, listId }: Props) {
  const tooltip = (
    <CustomMarkdown
      body={formatAnswerBody(answerText, answerType)}
      listId={listId}
      formatLinks={false}
    />
  );

  return <MLResizableCustomTextComponent text={answerText} tooltip={tooltip} />;
}
