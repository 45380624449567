import { Stack, Typography } from '@mui/material';
import { updateQueryInPlanAction } from 'actions/custom-analytics/update-query-in-plan-action';
import WorkflowAttributeTitleComponent from 'components/work-flow/WorkflowAttributeTitleComponent';
import WorkflowStepDependencies from 'components/work-flow/WorkflowStepDependencies';
import WorkflowTextComponent from 'components/work-flow/WorkflowTextComponent';
import MLShimmeringStack from 'design-system/MLShimmeringStack';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Handle, Position } from 'reactflow';
import { getThreadKey } from 'reducer/custom-analytics';
import { getCustomAnalyticsPlanStepQueryByThreadAndPostIdAndStepIndexAndQueryIndex } from 'selectors/custom-analytics';
import { getIsWorkflowReadOnlyModeByListId } from 'selectors/workflows';
import { applyThemeColorOpacity } from 'utils/theme-utils';

function WorkFlowPlanNode({ data }) {
  const dispatch = useDispatch();
  const {
    listId,
    postId,
    stepId,
    stepIndex,
    queryIndex,
    label,
    color,
    Icon,
    width,
    height,
    query: inputQuery,
    attributeName,
    attributeTitle,
    attributeDefaultTitle,
    showTopHandle = true,
    showBottomHandle = true,
    allowEdit = false,
    currentStepIndex,
    isStepDone,
    isStepRunning
  } = data;
  const [isHover, setIsHover] = useState(false);

  const threadKey = getThreadKey(listId);

  const stepData = useSelector((state) =>
    getCustomAnalyticsPlanStepQueryByThreadAndPostIdAndStepIndexAndQueryIndex(
      state,
      threadKey,
      postId,
      stepIndex,
      queryIndex
    )
  );
  const isReadOnly = useSelector((state) => getIsWorkflowReadOnlyModeByListId(state, listId));
  const actualQuery = stepData?.query || inputQuery;
  const query =
    actualQuery && typeof actualQuery === 'object' ? actualQuery.user_question : actualQuery;

  const onValueChange = (event) => {
    dispatch(updateQueryInPlanAction(listId, postId, stepId, { query: event.target.value || '' }));
  };

  const onMouseLeave = (event) => {
    if (event.target.tagName !== 'TEXTAREA' && event.target.tagName !== 'INPUT') {
      setIsHover(false);
    }
  };

  const isFaded = currentStepIndex !== null && !isStepDone && !isStepRunning;

  const nodeComponent = (
    <Stack
      // disable node dragging
      className="nodrag"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={onMouseLeave}
      sx={{
        width: width,
        height: height,
        padding: '16px',
        boxSizing: 'border-box',
        border: isStepRunning ? '2px solid' : '1.5px solid',
        borderColor: isStepRunning ? 'colors.primary' : applyThemeColorOpacity(color, 50),
        color: 'colors.primary_text',
        backgroundColor: applyThemeColorOpacity(color, 7),
        borderRadius: '16px',
        textAlign: 'left',
        margin: 0,
        minHeight: 0,
        opacity: isFaded ? 0.3 : 1
      }}>
      <Stack direction="column" gap="8px">
        <Stack direction="row" alignItems="center" justifyContent="space-between" gap="8px">
          <Stack direction="row" alignItems="center" gap="8px">
            {Icon && (
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: 32,
                  height: 32,
                  backgroundColor: applyThemeColorOpacity(color, 12),
                  borderRadius: '6px'
                }}
                color={color}>
                <Icon />
              </Stack>
            )}
            {label && (
              <Typography variant="text1_Bold" color={color}>
                {label}
              </Typography>
            )}
            {label && attributeName && (
              <Typography variant="text1_Medium" color={color}>
                {' - '}
              </Typography>
            )}
            {attributeName && (
              <WorkflowAttributeTitleComponent
                listId={listId}
                workflowId={postId}
                stepId={stepId}
                attributeName={attributeName}
                attributeTitle={attributeTitle}
                attributeDefaultTitle={attributeDefaultTitle}
                color={color}
                isEditMode={isHover && !isReadOnly}
              />
            )}
          </Stack>
          {!isReadOnly && (
            <Stack direction="row" alignItems="center" gap="0px">
              <WorkflowStepDependencies
                listId={listId}
                workflowId={postId}
                step={{ data }}
                isVisible={isHover}
              />
            </Stack>
          )}
        </Stack>
        <WorkflowTextComponent
          text={query}
          isEditMode={isHover && allowEdit && !isReadOnly}
          onValueChange={onValueChange}
        />
      </Stack>
      {showTopHandle && (
        <Handle type="target" position={Position.Top} style={{ visibility: 'hidden', top: 0 }} />
      )}
      {showBottomHandle && (
        <Handle
          type="source"
          position={Position.Bottom}
          style={{ visibility: 'hidden', bottom: 0 }}
        />
      )}
    </Stack>
  );

  if (isStepRunning) {
    return <MLShimmeringStack>{nodeComponent}</MLShimmeringStack>;
  }
  return nodeComponent;
}

WorkFlowPlanNode.propTypes = {
  data: PropTypes.object.isRequired
};

export default WorkFlowPlanNode;
