import { Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { getLatestDRFiltersByListId } from 'selectors/domain-reports';
import { getListCustomColumnsByListId } from 'selectors/explore';
import { PPTX_CLASS_NAMES } from 'utils/react-to-powerpoint-utils';
import { columnIdToTitle } from 'utils/table-utils';

import DomainReportBreakdownItem from './DomainReportBreakdownItem';

function DomainReportFiltersBreakdown({ listId }) {
  const filters = useSelector((state) => getLatestDRFiltersByListId(state, listId));
  const customColumns = useSelector((state) => getListCustomColumnsByListId(state, listId));
  const numFilters = filters ? Object.keys(filters).length : 0;
  return (
    <Stack gap="8px" className={PPTX_CLASS_NAMES.ignoreHidden}>
      <Typography variant="text1_Bold" color="colors.primary_text" sx={{ marginBottom: '8px' }}>
        {numFilters} filter{numFilters === 1 ? ' was' : 's were'} applied to the list
        {numFilters === 0 ? '.' : ':'}
      </Typography>
      {filters &&
        Object.entries(filters).map(([field, value], index) => {
          const params = field === 'description' ? value?.data?.searchTerm : value?.data;
          const paramStr =
            typeof params === 'string'
              ? params.slice(0, 50)
              : Object.values(params).join(params.length === 2 ? ' - ' : ', ');
          return (
            <DomainReportBreakdownItem
              key={index}
              leftText={columnIdToTitle(field, customColumns)}
              rightText={paramStr}
            />
          );
        })}
    </Stack>
  );
}

DomainReportFiltersBreakdown.propTypes = {
  listId: PropTypes.number
};

export default DomainReportFiltersBreakdown;
