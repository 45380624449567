import { WORK_FLOW_STEP_TYPES } from 'constants/workflows';
import dagre from 'dagre';

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

export function nodesFromPlan(plan, listId, postId, currentStepId) {
  const nodes = [];
  nodes.push({
    id: 'main_prompt',
    type: 'workFlowMainPrompt',
    data: {
      listId,
      postId,
      label: WORK_FLOW_STEP_TYPES.main_prompt.label,
      color: WORK_FLOW_STEP_TYPES.main_prompt.color,
      Icon: WORK_FLOW_STEP_TYPES.main_prompt.Icon,
      showTopHandle: false,
      isEmptyPlan: !plan
    }
  });

  if (!plan) {
    nodes.push({
      id: 'generate_plan',
      type: 'WorkFlowGeneratePlan',
      data: { listId, postId, color: 'primary.white' }
    });
  }

  const currentStepIndex = currentStepId
    ? plan.findIndex((step) => step.step_id === currentStepId)
    : null;

  plan
    ?.filter((step) => step?.step_id !== 'end')
    .forEach((step, index) => {
      const { step_id: stepId, function_use: functionUse } = step;
      const { action_variables: actionVariables, action } = functionUse || {};
      const isStepDone = index < currentStepIndex || currentStepId === 'end';
      if (actionVariables?.queries?.length > 0) {
        nodes.push({
          id: stepId,
          type: actionVariables.queries.length > 1 ? 'workFlowGroup' : 'workFlow',
          data: {
            color: WORK_FLOW_STEP_TYPES[action].color,
            label: `${index + 1}. ${WORK_FLOW_STEP_TYPES[action]?.label || action}`,
            Icon: WORK_FLOW_STEP_TYPES[action]?.Icon,
            showBottomHandle: index < plan.length - 1,
            dependsOn: actionVariables.queries[0].depends_on || [],
            attributeTitle:
              actionVariables.queries[0].attribute_title ||
              actionVariables.queries[0].analysis_title,
            attributeName:
              actionVariables.queries[0].attribute_name || actionVariables.queries[0].analysis_name,
            attributeDefaultTitle:
              actionVariables.queries[0].attribute_default_title ||
              actionVariables.queries[0].analysis_default_title,
            numberOfChildrens:
              actionVariables.queries.length > 1 ? actionVariables.queries.length : null,
            allowEdit: actionVariables.queries.length <= 1,
            query: actionVariables.queries[0].query,
            listId,
            postId,
            stepId,
            stepIndex: index,
            queryIndex: 0,
            action,
            currentStepIndex,
            isStepDone,
            isStepRunning: index === currentStepIndex
          }
        });

        if (actionVariables.queries.length > 1) {
          actionVariables?.queries?.forEach((query, parallelStepIndex) => {
            const parallelStepId = `${stepId}-${parallelStepIndex}`;
            nodes.push({
              id: parallelStepId,
              type: 'workFlow',
              parentId: stepId,
              extent: 'parent',
              data: {
                color: WORK_FLOW_STEP_TYPES[action].color,
                Icon: WORK_FLOW_STEP_TYPES[action]?.Icon,
                dependsOn: query.depends_on || [],
                attributeTitle: query.attribute_title || query.analysis_title,
                attributeName: query.attribute_name || query.analysis_name,
                attributeDefaultTitle:
                  query.attribute_default_title || query.analysis_default_title,
                showTopHandle: false,
                showBottomHandle: false,
                allowEdit: true,
                query: query.query,
                listId,
                postId,
                stepId: parallelStepId,
                stepIndex: index,
                queryIndex: parallelStepIndex,
                action,
                currentStepIndex,
                isStepDone,
                isStepRunning: index === currentStepIndex
              }
            });
          });
        }
      }
    });

  if (plan) {
    const attributeNameToNode = nodes
      .filter((node) => node.data.attributeName)
      .reduce((map, node) => {
        map[node.data.attributeName] = node;
        return map;
      }, {});

    const usedBy = nodes.reduce((map, node) => {
      node.data.dependsOn
        ?.filter((dependency) => attributeNameToNode[dependency])
        ?.forEach((dependency) => {
          if (!map[attributeNameToNode[dependency].id]) {
            map[attributeNameToNode[dependency].id] = [];
          }
          map[attributeNameToNode[dependency].id].push(node);
        });
      return map;
    }, {});

    const dependableNodes = nodes.filter((node) => node.type === 'workFlow');
    let lastParentId = null;
    let dependencies = [];
    dependableNodes.forEach((dependableNode, index) => {
      if (!dependableNode.parentId || dependableNode.parentId !== lastParentId) {
        lastParentId = dependableNode.parentId;
        dependencies = dependableNodes.slice(0, index).map((node) => ({
          id: node.data.attributeName,
          name:
            node.data.attributeTitle || node.data.attributeDefaultTitle || node.data.attributeName,
          color: node.data.color,
          icon: <node.data.Icon />
        }));
      }
      dependableNode.data.dependencies = dependencies;
    });

    nodes.forEach((node) => {
      if (node.data.dependsOn?.length > 0) {
        node.data.dependsOn = node.data.dependsOn
          .filter((dependency) => attributeNameToNode[dependency])
          .map((dependency) => ({
            title: dependency,
            Icon: attributeNameToNode[dependency]?.data?.Icon,
            color: attributeNameToNode[dependency]?.data?.color,
            action: attributeNameToNode[dependency]?.data?.action
          }));
      }
      if (usedBy[node.id]) {
        node.data.usedBy = usedBy[node.id].map((dependencyNode) => ({
          title: dependencyNode.data.attributeName,
          Icon: dependencyNode.data.Icon,
          color: dependencyNode.data.color
        }));
      }
    });
  }
  return nodes;
}

function edgesFromPlan(plan) {
  const edges = [];
  if (!plan) {
    edges.push({
      id: `main_prompt_to_generate_plan`,
      source: 'main_prompt',
      target: 'generate_plan',
      type: 'workFlow'
    });
  }

  if (plan?.length > 0) {
    edges.push({
      id: `main_prompt_to_${plan[0].step_id}`,
      source: 'main_prompt',
      target: plan[0].step_id,
      type: 'workFlow'
    });
  }
  edges.push();
  plan?.forEach((step) => {
    const { step_id: stepId, if_successful: nextStep } = step;

    edges.push({
      id: `${stepId}_to_${nextStep}`,
      source: stepId,
      target: nextStep,
      type: 'workFlow'
    });
  });
  return edges;
}

const getLayoutedElements = (nodes, edges) => {
  const nodeWidth = 600;
  const childNodeWidth = 450;
  const nodeHeight = 180;
  const mainNodeHeight = 240;
  const maxChildsInRow = 3;
  const widthPadding = 24;
  const topPadding = 64;
  const heightPadding = 24;

  dagreGraph.setGraph({ rankdir: 'TB' });

  nodes.forEach((node) => {
    let width = nodeWidth;
    if (node.data.numberOfChildrens > 0) {
      width = maxChildsInRow * childNodeWidth + (maxChildsInRow + 1) * widthPadding;
    } else if (node.parentId) {
      width = childNodeWidth;
    }
    node.data.width = width;
    node.data.height = nodeHeight;
    if (node.data.numberOfChildrens > 0) {
      node.data.height =
        topPadding +
        Math.ceil(node.data.numberOfChildrens / maxChildsInRow) * (nodeHeight + heightPadding);
    } else if (node.id === 'main_prompt') {
      node.data.height = mainNodeHeight;
    }
    dagreGraph.setNode(node.id, {
      width: node.data.width,
      height: node.data.height
    });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = 'top';
    node.sourcePosition = 'bottom';
    if (!node.data.numberOfChildrens) {
      node.style = { backgroundColor: 'white' };
    }

    let xPosition =
      node.type === 'workFlowMainPrompt'
        ? nodeWithPosition.x - node.data.width / 2
        : nodes[0].position.x;
    let yPosition = nodeWithPosition.y - node.data.height / 2;
    if (node.parentId) {
      const childIndex = parseInt(node.id.substring(node.parentId.length + 1), 10);
      xPosition = widthPadding + (childNodeWidth + widthPadding) * (childIndex % maxChildsInRow);
      yPosition =
        topPadding + Math.floor(childIndex / maxChildsInRow) * (nodeHeight + heightPadding);
    }
    if (node.type === 'workFlowGroup') {
      xPosition -= 423;
    }
    if (node.type === 'WorkFlowGeneratePlan') {
      xPosition = nodes[0].position.x + nodeWidth / 2 - 83;
    }

    node.position = {
      // eslint-disable-next-line id-length
      x: xPosition,
      // eslint-disable-next-line id-length
      y: yPosition
    };
    return node;
  });

  return { nodes, edges };
};

export function extractNodesAndEdges(plan, listId, postId, currentStepId) {
  const nodes = nodesFromPlan(plan, listId, postId, currentStepId);
  const edges = edgesFromPlan(plan);
  return getLayoutedElements(nodes, edges);
}
