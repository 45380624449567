import { Stack, Tab, Tabs, Typography } from '@mui/material';
import React from 'react';

type Props = {
  categoryValue: string | number;
  setCategoryValue: (value: string | number) => void;
  categories: string[];
  sectionsContainerRef: React.MutableRefObject<HTMLElement | null>;
};

export default function QueryLibraryCategorySideBar({
  categoryValue,
  setCategoryValue,
  categories,
  sectionsContainerRef
}: Props) {
  const sectionTabStyle = (index: number) => {
    const selectedSx = { backgroundColor: 'colors.selected', color: 'colors.primary' };
    const sx = index === categoryValue ? selectedSx : {};

    return {
      color: 'colors.secondary_text',
      alignItems: 'start',
      borderRadius: '4px',
      '&:hover': {
        backgroundColor: 'colors.hover_on_gray_bg',
        color: 'colors.primary'
      },
      '&.Mui-selected': selectedSx,
      ...sx
    };
  };

  const handleCategoryChange = (event: any, newValue: string) => {
    setCategoryValue(newValue);
    const index = categories.indexOf(newValue);
    if (sectionsContainerRef.current) {
      sectionsContainerRef.current.children[index].scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Stack
      sx={{
        gap: '16px',
        padding: '16px',
        minWidth: '200px',
        borderRight: '1px solid',
        borderColor: 'colors.ui_border',
        backgroundColor: 'colors.gray_bg'
      }}>
      <Tabs
        value={categoryValue}
        onChange={handleCategoryChange}
        orientation="vertical"
        variant="scrollable"
        TabIndicatorProps={{
          style: {
            display: 'none'
          }
        }}>
        {categories?.map((category, index) => (
          <Tab
            disableRipple
            key={category}
            value={category}
            label={
              <Typography variant="text1_Medium" color="colors.primary_text" noWrap>
                {category}
              </Typography>
            }
            sx={sectionTabStyle(index)}></Tab>
        ))}
      </Tabs>
    </Stack>
  );
}
