import { Checkbox, Divider, Stack, TextField, Typography, useTheme } from '@mui/material';
import { useAppUtils } from 'AppUtilsProvider';
import { updateDomainReportSections } from 'actions/explore/update-domain-report-section-action';
import { useDomainReportContext } from 'components/domain-report/DomainReportContext';
import MLButton from 'design-system/MLButton';
import MLInlineStack from 'design-system/MLInlineStack';
import { DomainReportSection, DomainReportSectionProps } from 'models/domain-report/types';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from 'types/store';

import DomainReportEditorKeyValue from '../edit/DomainReportEditorKeyValue';
import DomainReportEditorExpandableText from './DomainReportEditorExpandableText';

interface DomainReportDomainKnowledgeEditorViewProps {
  section: DomainReportSection;
  resetView: () => void;
}

export default function DomainReportDomainKnowledgeEditor({
  section,
  resetView
}: DomainReportDomainKnowledgeEditorViewProps) {
  const dispatch = useAppDispatch();
  const { addToast } = useAppUtils();
  const { listId, domainReport } = useDomainReportContext();

  const theme = useTheme();
  const [title, setTitle] = useState<string>(section.props.title);
  const [displayPrompt, setDisplayPrompt] = useState<boolean>(section.props.data?.displayPrompt);
  const [displaySources, setDisplaySources] = useState<boolean>(section.props.data?.displaySources);
  const [displayReasoning, setDisplayReasoning] = useState<boolean>(
    section.props.data?.displayReasoning
  );
  const [body, setBody] = useState<string>(section.props.data?.data?.message?.answer);
  const [allowSubmission, setAllowSubmission] = useState<boolean>(false);

  useEffect(() => {
    setAllowSubmission(title.length > 0);
  }, [title]);

  const updateTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const updateSection = () => {
    const newSection: DomainReportSectionProps = {
      ...section.props,
      title,
      data: {
        ...section.props.data,
        displayPrompt,
        displaySources,
        displayReasoning,
        data: {
          ...section.props.data?.data,
          message: {
            ...section.props.data?.data?.message,
            answer: body
          }
        }
      }
    };
    dispatch(
      updateDomainReportSections(
        listId,
        domainReport.domain_report_id,
        section.props.id,
        newSection,
        addToast
      )
    );
    resetView();
  };

  const checkboxes = [
    { text: 'Prompt', value: displayPrompt, setter: setDisplayPrompt },
    { text: 'Sources', value: displaySources, setter: setDisplaySources },
    { text: 'Reasoning', value: displayReasoning, setter: setDisplayReasoning }
  ];

  return (
    <Stack textAlign="left" sx={{ height: '100%' }}>
      <Stack
        gap="32px"
        padding="24px"
        sx={{ flexGrow: 1, overflowY: 'auto', scrollbarGutter: 'stable' }}>
        <DomainReportEditorKeyValue title="Section Name">
          <TextField
            variant="outlined"
            value={title}
            size="small"
            sx={{
              borderColor: 'colors.ui_border',
              backgroundColor: 'colors.white'
            }}
            placeholder="Choose Saved Answers to Show"
            slotProps={{
              input: {
                sx: {
                  ...theme.typography.text1_Normal,
                  color: 'colors.text'
                }
              }
            }}
            onChange={updateTitle}
          />
        </DomainReportEditorKeyValue>
        <DomainReportEditorKeyValue title="Display in section">
          {checkboxes.map((checkbox) => (
            <MLInlineStack gap="8px" key={checkbox.text}>
              <Checkbox
                value={checkbox.value}
                checked={checkbox.value}
                onChange={() => checkbox.setter(!checkbox.value)}
                size="small"
                sx={{ color: 'colors.ui_border', width: '16px', height: '16px', padding: '0px' }}
              />
              <Typography variant="text1_Normal">{checkbox.text}</Typography>
            </MLInlineStack>
          ))}
        </DomainReportEditorKeyValue>
        <Divider />
        <DomainReportEditorExpandableText title="Answer Content" text={body} setText={setBody} />
      </Stack>
      <MLInlineStack
        padding="24px"
        gap="8px"
        justifyContent="flex-end"
        sx={{ position: 'sticky', bottom: 0, backgroundColor: 'white' }}>
        <MLButton variant="outlined" size="large" onClick={resetView}>
          Cancel
        </MLButton>
        <MLButton
          variant="contained"
          size="large"
          onClick={updateSection}
          disabled={!allowSubmission}>
          Save Changes
        </MLButton>
      </MLInlineStack>
    </Stack>
  );
}
