import { Box, Stack } from '@mui/material';
import { useListContext } from 'hooks/ListContextProvider';
import React, { useEffect, useMemo, useRef } from 'react';
import { updateAssistantUtility } from 'reducer/assistant-slice';
import {
  getAssistantUtilitScrollToEnd,
  getOrderedAssistantListThreadIds
} from 'selectors/assistant';
import { useAppDispatch, useAppSelector, useAppSelectorWithArgs } from 'types/store';

import AssistantConversationThread from './AssistantConversationThread';

function AssistantConversation() {
  const { listId } = useListContext();
  const memoizedLiseId = useMemo(() => listId, [listId]);
  const threadIds = useAppSelectorWithArgs(getOrderedAssistantListThreadIds, memoizedLiseId);
  const scrollToEnd = useAppSelector(getAssistantUtilitScrollToEnd);
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref.current && scrollToEnd) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
    dispatch(updateAssistantUtility({ scrollToEnd: false }));
  }, [scrollToEnd, dispatch]);
  return (
    <Stack
      ref={ref}
      gap="12px"
      sx={{
        paddingX: '16px',
        height: '100%',
        overflowY: 'auto',
        overflowX: 'none',
        boxSizing: 'border-box',
        marginTop: 'auto'
      }}>
      <Box sx={{ height: '4px' }}></Box>
      {threadIds.map((threadId, index) => (
        <AssistantConversationThread key={index} threadId={threadId} divider={index !== 0} />
      ))}
    </Stack>
  );
}

export default AssistantConversation;
