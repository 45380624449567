import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React, { useId } from 'react';

import { ALL_LISTS_VALUE } from './AssistantAdminTab';

interface AssistantAdminCompanyListSelectorProps {
  listIds: number[];
  listId: number;
  setListId: (listId: number) => void;
}

export default function AssistantAdminCompanyListSelector({
  listIds,
  listId,
  setListId
}: AssistantAdminCompanyListSelectorProps) {
  const labelId = useId();
  const handleChange = (event: SelectChangeEvent<number>) => {
    setListId(event.target.value as number);
  };
  return (
    <FormControl>
      <InputLabel id={labelId}>List ID</InputLabel>
      <Select
        labelId={labelId}
        value={listId}
        label="Organization"
        onChange={handleChange}
        size="small">
        <MenuItem value={ALL_LISTS_VALUE}>All</MenuItem>
        {listIds &&
          listIds
            .sort((id1, id2) => id1 - id2)
            .map((id) => (
              <MenuItem key={id} value={id}>
                {id}
              </MenuItem>
            ))}
      </Select>
    </FormControl>
  );
}
