import PropTypes from 'prop-types';

export default function AssistantWandIcon({ size, color }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M18.875 12V15.75"
          stroke={color}
          strokeWidth="0.9375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17 13.875H20.75"
          stroke={color}
          strokeWidth="0.9375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.25 5.125V8.875"
          stroke={color}
          strokeWidth="0.9375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.375 7H10.125"
          stroke={color}
          strokeWidth="0.9375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.125 16.375V18.875"
          stroke={color}
          strokeWidth="0.9375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.875 17.625H16.375"
          stroke={color}
          strokeWidth="0.9375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.25 8.25L15.75 10.75"
          stroke={color}
          strokeWidth="0.9375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.1921 5.30793L5.30815 16.1918C5.06407 16.4359 5.06407 16.8317 5.30815 17.0757L6.924 18.6916C7.16808 18.9357 7.56381 18.9357 7.80789 18.6916L18.6918 7.80766C18.9359 7.56358 18.9359 7.16785 18.6918 6.92378L17.076 5.30793C16.8319 5.06385 16.4361 5.06385 16.1921 5.30793Z"
          stroke={color}
          strokeWidth="0.9375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5500_106435">
          <rect width="20" height="20" fill={color} transform="translate(2 2)" />
        </clipPath>
      </defs>
    </svg>
  );
}

AssistantWandIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
};

AssistantWandIcon.defaultProps = {
  size: 24,
  color: 'black'
};
