import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import { updateQueryInPlanAction } from 'actions/custom-analytics/update-query-in-plan-action';
import SelectWithAnswerTypeComponent from 'components/SelectWithAnswerTypeComponent';
import MultiSectionsSelectComponent from 'components/discovery/MultiSectionsSelectComponent';
import SlimMultiSelectPromptInputComponent from 'components/discovery/SlimMultiSelectComponent';
import { RESOURCE_TYPE_TO_ICON } from 'components/domain-knowledge/DomainKnowledgeResourceTile';
import WorkflowStepMenu from 'components/work-flow/WorkflowStepMenu';
import { DOMAIN_KNOWLEDGE_RESOURCE_OPTIONS } from 'constants/domain-knowledge';
import { WORK_FLOW_STEP_TYPES } from 'constants/workflows';
import MLInlineStack from 'design-system/MLInlineStack';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import DependenciesIcon from 'icons/DependenciesIcon';
import DomainKnowledgeIcon from 'icons/DomainKnowledgeIcon';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanyMetasMergedRowsByCompanyListId } from 'selectors/companyMetas';
import { getListCustomColumnsByListId, getListDomainKnowledgeByListId } from 'selectors/explore';
import { getExploreUiColumnsByListId } from 'selectors/explore-table-ui';
import { getCurrentFolderId, getCurrentFolderSupportingResources } from 'selectors/folders';
import { getUserIsDev } from 'selectors/user';
import { combineDomainKnowledge } from 'utils/domain-knowledge-utils';
import { getCompaniesAndColumns } from 'utils/prompts-utils';

/* eslint-disable max-lines */
/* eslint-disable  max-lines-per-function */
function WorkflowStepDependencies({ listId, workflowId, step, isVisible }) {
  const dispatch = useDispatch();

  const { data } = step;
  const { query, stepId, action, dependsOn, dependencies } = data;

  const rows = useSelector((state) => getCompanyMetasMergedRowsByCompanyListId(state, listId));
  const customColumns = useSelector((state) => getListCustomColumnsByListId(state, listId));
  const isDev = useOldStateSelector(getUserIsDev);
  const columnsSettings = useSelector((state) => getExploreUiColumnsByListId(state, listId));
  const listDomainKnowledge = useSelector((state) => getListDomainKnowledgeByListId(state, listId));
  const folderId = useSelector(getCurrentFolderId);
  const folderDomainKnowledge = useSelector((state) =>
    getCurrentFolderSupportingResources(state, folderId)
  );
  const haveActions = query && typeof query === 'object';
  const shouldShowAnswerType =
    action === WORK_FLOW_STEP_TYPES.analysis.id || action === WORK_FLOW_STEP_TYPES.information.id;
  const shouldShowCondition = action === WORK_FLOW_STEP_TYPES.filter.id;

  const columnIds = useMemo(
    () => (query && typeof query === 'object' ? query.column_ids : []),
    [query]
  );
  const domainKnowledgeIds = useMemo(
    () => (query && typeof query === 'object' ? query.domain_knowledge_ids : []),
    [query]
  );

  const domainKnowledge = useMemo(
    () => combineDomainKnowledge(listDomainKnowledge, folderDomainKnowledge),
    [listDomainKnowledge, folderDomainKnowledge]
  );

  const allDomainKnowledges = useMemo(() => {
    const allOptions = [
      ...DOMAIN_KNOWLEDGE_RESOURCE_OPTIONS,
      ...Object.values(domainKnowledge || {})
        .sort((item1, item2) => (item1.type < item2.type ? 1 : -1))
        .map((dk) => ({
          icon: RESOURCE_TYPE_TO_ICON[dk.type],
          ...dk
        }))
    ];

    return allOptions.reduce((acc, item) => {
      acc[item.id] = item;
      return acc;
    }, {});
  }, [domainKnowledge]);

  const dkTypesSet = useMemo(
    () =>
      Object.values(allDomainKnowledges).reduce((set, item) => {
        if (item.supporting_resource_type) {
          set.add(item.supporting_resource_type);
        }
        return set;
      }, new Set()),
    [allDomainKnowledges]
  );

  const { columns } = useMemo(() => {
    return getCompaniesAndColumns(listId, rows, customColumns, columnsSettings, isDev);
  }, [listId, rows, customColumns, columnsSettings, isDev]);

  const searchTheWeb = query && typeof query === 'object' ? query.search_the_web : null;
  const searchInTable = query && typeof query === 'object' ? query.is_table_question : null;
  const condition = query && typeof query === 'object' ? query.condition : null;

  const defualtSources = [
    { id: 'web', name: 'Web' },
    { id: 'table', name: 'Table' }
  ];

  const sources = useMemo(() => {
    const newSources = [];
    if (searchTheWeb) {
      newSources.push('web');
    }
    if (searchInTable) {
      newSources.push('table');
    }
    return newSources;
  }, [searchTheWeb, searchInTable]);

  const onUpdateNodeSettings = useCallback(
    (queryDetails) => {
      dispatch(updateQueryInPlanAction(listId, workflowId, stepId, queryDetails));
    },
    [dispatch, listId, stepId, workflowId]
  );

  const contextSections = useMemo(() => {
    const sections = [];
    if (searchInTable) {
      sections.push({
        id: 'columns',
        type: 'multiple',
        title: 'Columns',
        items: Object.values(columns),
        itemsMap: columns,
        selectItemsSet: new Set(columnIds),
        onSelectionsChange: (selectedColumns) =>
          onUpdateNodeSettings({ columnIds: selectedColumns })
      });
    }

    const domainKnowledgeOptions = [
      ...Object.values(allDomainKnowledges || {})
        .sort((item1, item2) => {
          if (!item1.supporting_resource_type && !item2.supporting_resource_type) {
            // for items without type (all files/all answers..), keep the original order
            return 1;
          }
          const typeCompare = item1.supporting_resource_type?.localeCompare(
            item2.supporting_resource_type
          );
          if (typeCompare === 0) {
            return item1.name?.localeCompare(item2.name) || -1;
          }
          return typeCompare || -1;
        })
        .map((dk) => ({
          ...dk,
          disabled: !dkTypesSet.has(dk.supporting_resource_type) && !dkTypesSet.has(dk.id),
          icon: RESOURCE_TYPE_TO_ICON[dk.supporting_resource_type] || RESOURCE_TYPE_TO_ICON[dk.id]
        }))
    ];

    sections.push({
      id: 'domain',
      type: 'multiple',
      title: 'Domain Knowledge',
      items: domainKnowledgeOptions,
      itemsMap: allDomainKnowledges,
      selectItemsSet: new Set(domainKnowledgeIds),
      onSelectionsChange: (selectedResourceType, addedResource) => {
        const filterOutSpecificWhenAllExist = selectedResourceType.filter((dkId) => {
          if (
            dkTypesSet.has(addedResource) &&
            allDomainKnowledges[dkId]?.supporting_resource_type === addedResource
          ) {
            return false;
          }
          if (
            !dkTypesSet.has(addedResource) &&
            allDomainKnowledges[addedResource]?.supporting_resource_type === dkId
          ) {
            return false;
          }
          return true;
        });
        onUpdateNodeSettings({ domainKnowledgeIds: filterOutSpecificWhenAllExist });
      }
    });

    if (dependencies?.length > 0) {
      const itemsMap = dependencies.reduce((map, dependency) => {
        map[dependency.name] = dependency;
        return map;
      }, {});
      sections.push({
        id: 'dependencies',
        type: 'multiple',
        title: 'Dependencies',
        items: dependencies,
        itemsMap,
        selectItemsSet: new Set(dependsOn.map((dependency) => dependency.title)),
        onSelectionsChange: (selectedDependencies) => {
          onUpdateNodeSettings({ dependsOn: selectedDependencies });
        }
      });
    }
    return sections;
  }, [
    searchInTable,
    columns,
    columnIds,
    domainKnowledgeIds,
    allDomainKnowledges,
    dkTypesSet,
    dependsOn,
    dependencies,
    onUpdateNodeSettings
  ]);

  const contextSelectorTooltip = useMemo(() => {
    const selectedList = {};
    contextSections.forEach((section) => {
      if (section.selectItemsSet) {
        section.selectItemsSet.forEach((item) => {
          const itemName = section.items.find((elem) => elem.id === item)?.name;
          if (!selectedList[section.id]) {
            selectedList[section.id] = { title: section.title, items: [] };
          }
          selectedList[section.id].items.push(itemName);
        });
      }
    });

    return Object.values(selectedList).reduce(
      (tooltip, section) =>
        tooltip.concat(
          `Using ${section.items.length} ${section.title}\n${section.items.join('\n')}\n\n`
        ),
      ''
    );
  }, [contextSections]);

  const onSearchChange = (newSearchPlaces) => {
    const shouldSearchInWeb = newSearchPlaces.includes('web');
    const shouldSearchInTable = newSearchPlaces.includes('table');
    onUpdateNodeSettings({
      searchTheWeb: shouldSearchInWeb,
      searchInTable: shouldSearchInTable
    });
  };

  const onConditionChange = (_event, newValue) => {
    onUpdateNodeSettings({ condition: newValue ? 'yes' : 'no' });
  };

  return (
    <MLInlineStack sx={{ visibility: isVisible ? 'visible' : 'hidden' }}>
      {shouldShowCondition && (
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={condition === 'yes'} onChange={onConditionChange} />}
            label={condition === 'yes' ? 'Yes' : 'No'}
          />
        </FormGroup>
      )}
      {shouldShowAnswerType && (
        <SelectWithAnswerTypeComponent
          currentAnswerType={query.answer_type || 'text'}
          setAnswerType={(newAnswerType) => onUpdateNodeSettings({ answerType: newAnswerType })}
          showAsButton={true}
          selectStyle={{
            padding: '4px',
            '&:hover': {
              backgroundColor: 'colors.hover_on_gray_bg',
              borderRadius: '4px'
            }
          }}
          inputProps={{
            disableUnderline: true,
            sx: {
              paddingRight: '0 !important'
            }
          }}
          renderValueStyle={{
            paddingLeft: 0,
            paddingTop: 0,
            paddingBottom: 0
          }}
        />
      )}
      {haveActions && (
        <SlimMultiSelectPromptInputComponent
          selectedValues={sources}
          onValueChange={onSearchChange}
          sx={{
            width: '32px',
            '.MuiSelect-select': { paddingRight: '0 !important' }
          }}
          selectStyle={{ backgroundColor: 'transparent' }}
          allTag="Search everywhere"
          emptyTag="Select sources"
          defualtSources={defualtSources}
          ValueIcon={DomainKnowledgeIcon}
          valueIconStyle={{ '&:hover': { backgroundColor: 'colors.hover_on_gray_bg' } }}
        />
      )}
      {haveActions && (
        <MultiSectionsSelectComponent
          containerSx={{ width: '32px' }}
          sections={contextSections}
          tooltipText={contextSelectorTooltip}
          ValueIcon={DependenciesIcon}
          valueIconStyle={{ '&:hover': { backgroundColor: 'colors.hover_on_gray_bg' } }}
        />
      )}
      <WorkflowStepMenu listId={listId} workflowId={workflowId} stepId={stepId} />
    </MLInlineStack>
  );
}

WorkflowStepDependencies.propTypes = {
  listId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  workflowId: PropTypes.number,
  step: PropTypes.object,
  isVisible: PropTypes.bool
};

export default WorkflowStepDependencies;
