import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import WorkflowDialogRow from 'components/work-flow/WorkflowDialogRow';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getThreadKey } from 'reducer/custom-analytics';
import { getCustomAnalyticsThreadPostsById } from 'selectors/custom-analytics';
import { getWorkflowsHistoryByListId } from 'selectors/workflows';

function AllWorkflowsDialogStep({ listId, closeWorkflowCenter }) {
  const threadKey = getThreadKey(listId);
  const caThreadPosts = useSelector((state) => getCustomAnalyticsThreadPostsById(state, threadKey));
  const workflowsHistory = useSelector((state) => getWorkflowsHistoryByListId(state, listId));

  const workflows = useMemo(() => {
    const mergedWorkflows = [];

    if (caThreadPosts) {
      mergedWorkflows.push(
        ...Object.values(caThreadPosts).filter(
          (caPost) =>
            (caPost.isWorkflow || caPost.plan?.is_workflow) &&
            !caPost.body?.hidePost &&
            !workflowsHistory?.[caPost.postId]
        )
      );
    }

    if (workflowsHistory) {
      mergedWorkflows.push(
        ...Object.values(workflowsHistory)
          .filter((workflowRecord) => workflowRecord?.parameters?.workflow)
          .map((workflowRecord) => ({
            postId: workflowRecord?.parameters?.post_id,
            name: workflowRecord?.parameters?.workflow?.name,
            plan: workflowRecord?.parameters?.workflow,
            timeSubmitted: workflowRecord?.time_submitted,
            body: {},
            messages: []
          }))
          .sort((wf1, wf2) => new Date(wf2.timeSubmitted) - new Date(wf1.timeSubmitted))
      );
    }

    return mergedWorkflows;
  }, [caThreadPosts, workflowsHistory]);

  return (
    <Stack direction="column" gap="16px" sx={{ padding: '16px' }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" gap="8px">
        <Typography variant="h3_Semibold" color="colors.primary_text">
          {`${workflows.length} launched workflows`}
        </Typography>
      </Stack>
      <TableContainer
        component={Box}
        sx={{
          flex: 1,
          border: '1px solid',
          borderColor: 'colors.ui_border',
          borderRadius: '8px',
          backgroundColor: 'colors.primary_bg'
        }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell sx={{ width: '125px' }}>Status</TableCell>
              <TableCell sx={{ width: '100px' }}>Created At</TableCell>
              <TableCell align="right" sx={{ width: '40px' }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {workflows.map((workflow) => (
              <WorkflowDialogRow
                key={workflow.postId}
                listId={listId}
                workflow={workflow}
                closeWorkflowCenter={closeWorkflowCenter}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
}

AllWorkflowsDialogStep.propTypes = {
  listId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  closeWorkflowCenter: PropTypes.func
};

export default AllWorkflowsDialogStep;
