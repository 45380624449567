import { Button, Stack, Typography } from '@mui/material';
import { submitAssistantActionAction } from 'actions/assistant/submit-assistant-action-action';
import { submitAssistantMessageAction } from 'actions/assistant/submit-assistant-message-action';
import MLButton from 'design-system/MLButton';
import MLInlineStack from 'design-system/MLInlineStack';
import PreviewIcon from 'icons/PreviewIcon';
import AssistantPromptConfig from 'models/assistant/AssistantPromptConfig';
import { ActionType, AssistantAction } from 'models/assistant/assistant-actions';
import React, { useState } from 'react';
import { useAppDispatch } from 'types/store';

import usePromptFunctions from './useActionFunctions';

interface MessageConfirmationComponentProps {
  action: AssistantAction;
  threadId: string;
  listId: number;
}

type ActionTaken = 'confirm' | 'dismiss' | null;

export const ACTION_TYPE_TO_TRIGGER_MESSAGE = Object.freeze({
  [ActionType.DISCOVERY_BY_PEERS]: 'Adding companies',
  [ActionType.DISCOVERY_BY_QUERY]: 'Adding companies',
  [ActionType.DISCOVERY_BY_URL]: 'Adding companies',
  [ActionType.ADD_COLUMN]: 'Adding column...',
  [ActionType.ASK_QUESTION]: 'Asking question...',
  [ActionType.FILTER]: 'Adding filter...',
  [ActionType.WORKFLOW]: 'Running workflow...',
  [ActionType.PREVIEW_COLUMN]: 'Getting preview...',
  [ActionType.ADD_COMPANIES_BY_NAME]: 'Adding company...'
});

export default function MessageConfirmationComponent({
  action,
  threadId,
  listId
}: MessageConfirmationComponentProps) {
  const dispatch = useAppDispatch();
  const [actionTaken, setActionTaken] = useState<ActionTaken>(null);
  const [triggeringActionText, setTriggeringActionText] = useState<string>('');
  const { handleCopyClick } = usePromptFunctions(action);

  const getTriggeringActionText = () => {
    if (action.action_type === ActionType.PREVIEW_COLUMN) {
      return ACTION_TYPE_TO_TRIGGER_MESSAGE[ActionType.ADD_COLUMN];
    }
    return ACTION_TYPE_TO_TRIGGER_MESSAGE?.[action.action_type] || 'Triggering action...';
  };

  const onResponse = () => setTriggeringActionText('');

  const onYes = () => {
    setActionTaken('confirm');

    setTriggeringActionText(getTriggeringActionText());

    if (action.action_type === ActionType.PREVIEW_COLUMN) {
      const newAction = { ...action, action_type: ActionType.ADD_COLUMN };
      dispatch(
        submitAssistantMessageAction({
          newPromptConfig: new AssistantPromptConfig(newAction),
          extraParams: { onResponse },
          listId
        })
      );
    } else {
      dispatch(submitAssistantActionAction(threadId, action.id));
    }
  };

  const onEdit = () => {
    handleCopyClick();
  };

  const onClickPreview = () => {
    setActionTaken('confirm');
    setTriggeringActionText(ACTION_TYPE_TO_TRIGGER_MESSAGE[ActionType.PREVIEW_COLUMN]);
    const assistantConfig = new AssistantPromptConfig({
      ...action,
      action_type: ActionType.PREVIEW_COLUMN
    });
    dispatch(
      submitAssistantMessageAction({
        newPromptConfig: assistantConfig,
        extraParams: { onResponse },
        listId
      })
    );
  };

  return (
    <Stack gap="16px">
      {actionTaken ? (
        <Typography variant="text1_Normal" color="colors.primary">
          {actionTaken === 'confirm' ? triggeringActionText : ''}
        </Typography>
      ) : (
        <MLInlineStack>
          <Button variant="outlined" onClick={onEdit}>
            Edit prompt
          </Button>
          <Button variant="contained" onClick={onYes}>
            Looks good
          </Button>
          {action.action_type === ActionType.ADD_COLUMN && (
            <MLButton
              onClick={onClickPreview}
              sx={{ marginLeft: 'auto' }}
              startIcon={<PreviewIcon />}>
              <Typography variant="text1_Normal">Preview results</Typography>
            </MLButton>
          )}
        </MLInlineStack>
      )}
    </Stack>
  );
}
