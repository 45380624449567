import { AssistantSuggestedQueryProps } from 'components/assistant/initial/AssistantSuggestedQuery';
import LinkIcon from 'design-system/icons/basic/LinkIcon';
import WebsiteIcon from 'design-system/icons/basic/WebsiteIcon';
import CollaborateIcon from 'design-system/icons/platform/CollaborateIcon';
import ColumnsIcon from 'design-system/icons/platform/ColumnsIcon';
import FilterIcon from 'design-system/icons/platform/FilterIcon';
import KnowledgeIcon from 'design-system/icons/platform/KnowledgeIcon';
import OrganizationIcon from 'design-system/icons/platform/OrganizationIcon';
import QueryIcon from 'design-system/icons/platform/QueryIcon';
import SearchEverywhereIcon from 'design-system/icons/platform/SearchEverywhereIcon';
import TableIcon from 'design-system/icons/platform/TableIcon';
import DoneTypeIcon from 'icons/DoneTypeIcon';
import NumberTypeIcon from 'icons/NumberTypeIcon';
import PreviewIcon from 'icons/PreviewIcon';
import TextTypeIcon from 'icons/TextTypeIcon';
import { WorkflowIcon } from 'icons/WorkflowIcon';
import { ActionType } from 'models/assistant/assistant-actions';
import { ActionTypeDisplay, SearchSettings, SuggestionIdType } from 'models/assistant/types';
import { AnswerType } from 'utils/custom-analytics-utils';

export const ACTION_TYPE_TO_LABEL: Record<ActionType, string> = Object.freeze({
  [ActionType.DISCOVERY_BY_QUERY]: 'Add companies by description',
  [ActionType.DISCOVERY_BY_PEERS]: 'Add companies by peers',
  [ActionType.DISCOVERY_BY_URL]: 'Add companies by URL',
  [ActionType.ADD_COMPANIES_BY_NAME]: 'Add companies by name',
  [ActionType.FILTER]: 'Filter',
  [ActionType.ADD_COLUMN]: 'Add column',
  [ActionType.ASK_QUESTION]: 'Ask question',
  [ActionType.WORKFLOW]: 'Workflow',
  [ActionType.PREVIEW_COLUMN]: 'Preview column'
});

export const ACTION_TYPE_TO_ICON: Record<ActionType, React.ComponentType | any> = Object.freeze({
  [ActionType.DISCOVERY_BY_QUERY]: QueryIcon,
  [ActionType.DISCOVERY_BY_PEERS]: CollaborateIcon,
  [ActionType.DISCOVERY_BY_URL]: LinkIcon,
  [ActionType.FILTER]: FilterIcon,
  [ActionType.ADD_COLUMN]: ColumnsIcon,
  [ActionType.ASK_QUESTION]: KnowledgeIcon,
  [ActionType.WORKFLOW]: WorkflowIcon,
  [ActionType.ADD_COMPANIES_BY_NAME]: OrganizationIcon,
  [ActionType.PREVIEW_COLUMN]: PreviewIcon
});

export const ACTION_TYPE_TO_COLOR: Record<ActionType, string> = Object.freeze({
  [ActionType.ADD_COLUMN]: 'accent_colors.dark_lavender',
  [ActionType.DISCOVERY_BY_QUERY]: 'accent_colors.bright_blue',
  [ActionType.DISCOVERY_BY_PEERS]: 'accent_colors.bright_blue',
  [ActionType.DISCOVERY_BY_URL]: 'accent_colors.bright_blue',
  [ActionType.FILTER]: 'colors.poor_orange',
  [ActionType.ASK_QUESTION]: 'accent_colors.orchid',
  [ActionType.WORKFLOW]: 'colors.primary',
  [ActionType.ADD_COMPANIES_BY_NAME]: 'accent_colors.bright_blue',
  [ActionType.PREVIEW_COLUMN]: 'accent_colors.dark_lavender'
});

export const ACTION_TYPE_TO_BG_COLOR: Record<ActionType, string> = Object.freeze({
  [ActionType.ADD_COLUMN]: 'accent_colors.dark_lavender_bg1',
  [ActionType.DISCOVERY_BY_QUERY]: 'accent_colors.bright_blue_bg1',
  [ActionType.DISCOVERY_BY_PEERS]: 'accent_colors.bright_blue_bg1',
  [ActionType.DISCOVERY_BY_URL]: 'accent_colors.bright_blue_bg1',
  [ActionType.FILTER]: 'accent_colors.poor_orange_bg1',
  [ActionType.ASK_QUESTION]: 'accent_colors.orchid_bg1',
  [ActionType.WORKFLOW]: 'colors.selected',
  [ActionType.ADD_COMPANIES_BY_NAME]: 'accent_colors.bright_blue_bg1',
  [ActionType.PREVIEW_COLUMN]: 'accent_colors.dark_lavender_bg1'
});

export const ACTION_TYPE_TO_BG_COLOR_LIGHT: Record<ActionType, string> = Object.freeze({
  [ActionType.ADD_COLUMN]: 'accent_colors.dark_lavender_bg2',
  [ActionType.DISCOVERY_BY_QUERY]: 'accent_colors.bright_blue_bg2',
  [ActionType.DISCOVERY_BY_PEERS]: 'accent_colors.bright_blue_bg2',
  [ActionType.DISCOVERY_BY_URL]: 'accent_colors.bright_blue_bg2',
  [ActionType.FILTER]: 'accent_colors.poor_orange_bg2',
  [ActionType.ASK_QUESTION]: 'accent_colors.orchid_bg2',
  [ActionType.WORKFLOW]: 'colors.selected',
  [ActionType.ADD_COMPANIES_BY_NAME]: 'accent_colors.bright_blue_bg2',
  [ActionType.PREVIEW_COLUMN]: 'accent_colors.dark_lavender_bg2'
});

export const SUGGESTION_ID_TYPE_TO_ICON: Record<SuggestionIdType, React.ComponentType | any> =
  Object.freeze({
    [SuggestionIdType.COMPANY]: OrganizationIcon,
    [SuggestionIdType.COLUMN]: ColumnsIcon,
    [SuggestionIdType.SUPPORTING_RESOURCE]: KnowledgeIcon
  });
export const SUGGESTION_ID_TYPE_TO_COLOR = Object.freeze({
  [SuggestionIdType.COLUMN]: 'accent_colors.dark_lavender',
  [SuggestionIdType.COMPANY]: 'accent_colors.bright_blue',
  [SuggestionIdType.SUPPORTING_RESOURCE]: 'accent_colors.orchid'
});

export const SUGGESTION_ID_TYPE_TO_BG_COLOR = Object.freeze({
  [SuggestionIdType.COLUMN]: 'accent_colors.dark_lavender_bg1',
  [SuggestionIdType.COMPANY]: 'accent_colors.bright_blue_bg1',
  [SuggestionIdType.SUPPORTING_RESOURCE]: 'accent_colors.orchid_bg1'
});
export const SUGGESTION_ID_TYPE_TO_HOVER_BG_COLOR = Object.freeze({
  [SuggestionIdType.COLUMN]: 'accent_colors.dark_lavender_bg2',
  [SuggestionIdType.COMPANY]: 'accent_colors.bright_blue_bg2',
  [SuggestionIdType.SUPPORTING_RESOURCE]: 'accent_colors.orchid_bg2'
});

export const suggestionIdTypeToSx = (
  suggestionIdType: SuggestionIdType,
  useHoverColorForBg = false
) => {
  return {
    color: SUGGESTION_ID_TYPE_TO_COLOR[suggestionIdType],
    backgroundColor: useHoverColorForBg
      ? SUGGESTION_ID_TYPE_TO_HOVER_BG_COLOR[suggestionIdType]
      : SUGGESTION_ID_TYPE_TO_BG_COLOR[suggestionIdType]
  };
};

const generateGenericActionTypeDisplay = (actionType: ActionType): ActionTypeDisplay => ({
  actionType,
  label: ACTION_TYPE_TO_LABEL[actionType],
  icon: ACTION_TYPE_TO_ICON[actionType],
  color: ACTION_TYPE_TO_COLOR[actionType],
  bgColor: ACTION_TYPE_TO_BG_COLOR[actionType]
});

export const ACTION_MENU_ITEMS: Readonly<ActionTypeDisplay[]> = Object.freeze([
  {
    actionType: ActionType.DISCOVERY_BY_QUERY,
    label: ACTION_TYPE_TO_LABEL[ActionType.DISCOVERY_BY_QUERY],
    icon: OrganizationIcon,
    color: ACTION_TYPE_TO_COLOR[ActionType.DISCOVERY_BY_QUERY],
    bgColor: ACTION_TYPE_TO_BG_COLOR[ActionType.DISCOVERY_BY_QUERY],
    nestedActions: [
      {
        actionType: ActionType.DISCOVERY_BY_QUERY,
        label: 'Add by description',
        icon: ACTION_TYPE_TO_ICON[ActionType.DISCOVERY_BY_QUERY]
      },
      {
        actionType: ActionType.DISCOVERY_BY_PEERS,
        label: 'Add by peers',
        icon: ACTION_TYPE_TO_ICON[ActionType.DISCOVERY_BY_PEERS]
      },
      {
        actionType: ActionType.DISCOVERY_BY_URL,
        label: 'Add by URL',
        icon: ACTION_TYPE_TO_ICON[ActionType.DISCOVERY_BY_URL]
      }
    ]
  },
  ...[ActionType.ADD_COLUMN, ActionType.ASK_QUESTION, ActionType.FILTER, ActionType.WORKFLOW].map(
    generateGenericActionTypeDisplay
  )
]);

export const SUGGESTION_ID_TYPE_TO_LABEL: Readonly<Record<SuggestionIdType, string>> =
  Object.freeze({
    [SuggestionIdType.COMPANY]: 'Companies',
    [SuggestionIdType.COLUMN]: 'Columns',
    [SuggestionIdType.SUPPORTING_RESOURCE]: 'Knowledge'
  });

export const ACTION_TYPES_WITH_SCOPING = Object.freeze([
  ActionType.DISCOVERY_BY_QUERY,
  ActionType.DISCOVERY_BY_PEERS,
  ActionType.DISCOVERY_BY_URL
]);

export const ACTION_TYPES_WITH_SEARCH_SETTINGS = Object.freeze([
  ActionType.ADD_COLUMN,
  ActionType.FILTER,
  ActionType.ASK_QUESTION,
  ActionType.PREVIEW_COLUMN
]);
export const ACTION_TYPES_WITH_ANSWER_TYPE = Object.freeze([
  ActionType.ADD_COLUMN,
  ActionType.ASK_QUESTION,
  ActionType.PREVIEW_COLUMN
]);

export const ACTION_TYPES_WITH_CONFIG = Object.freeze([
  ...ACTION_TYPES_WITH_SCOPING,
  ...ACTION_TYPES_WITH_SEARCH_SETTINGS,
  ...ACTION_TYPES_WITH_ANSWER_TYPE
]);

export const SUGGESTED_QUERIES: Readonly<AssistantSuggestedQueryProps[]> = Object.freeze([
  {
    label: 'Find companies in [industry]',
    promptConfigParams: {
      text: 'Find companies in [industry]'
    }
  },
  {
    label: 'Find competitors of [company]',
    promptConfigParams: {
      text: 'Find competitors of [company]'
    }
  },
  {
    label: 'Add company [company name]',
    promptConfigParams: {
      text: 'Add company [company name]'
    }
  }
]);

export const TT_TRIGGER_CHAR = '/';
export const TT_PLACEHOLDER = `Describe your full request, type ${TT_TRIGGER_CHAR} to add context `;

export const ACTION_TYPE_TO_PLACEHOLDER: Readonly<Record<ActionType, string>> = Object.freeze({
  [ActionType.DISCOVERY_BY_QUERY]: 'Describe the companies you are looking for...',
  [ActionType.DISCOVERY_BY_PEERS]: 'Type company names to discover their peers...',
  [ActionType.DISCOVERY_BY_URL]: 'Enter a URL to find companies...',
  [ActionType.FILTER]: `Describe how you would like to filter the table, type ${TT_TRIGGER_CHAR} to add context `,
  [ActionType.ASK_QUESTION]: 'Type a question, or choose one from the library...  ',
  [ActionType.ADD_COLUMN]: 'Type a question to create a column, or choose one from the library...',
  [ActionType.WORKFLOW]: `Describe your full request, type ${TT_TRIGGER_CHAR} to add context `,
  [ActionType.ADD_COMPANIES_BY_NAME]: TT_PLACEHOLDER,
  [ActionType.PREVIEW_COLUMN]: 'Type a question to preview a column...'
});

export enum SearchSettingsOptions {
  WEB_ONLY = 'WEB_ONLY',
  TABLE_ONLY = 'TABLE_ONLY',
  WEB_AND_TABLE = 'WEB_AND_TABLE'
}

interface SearchSettingsOptionsConfig {
  search_settings: SearchSettings;
  icon: React.ComponentType;
  label: string;
}

export const SEARCH_SETTING_OPTIONS: Readonly<
  Record<SearchSettingsOptions, SearchSettingsOptionsConfig>
> = Object.freeze({
  [SearchSettingsOptions.WEB_ONLY]: {
    label: 'Search the web',
    icon: WebsiteIcon,
    search_settings: {
      use_web: true,
      use_table: false
    }
  },
  [SearchSettingsOptions.TABLE_ONLY]: {
    label: 'Search the table',
    icon: TableIcon,
    search_settings: {
      use_web: false,
      use_table: true
    }
  },
  [SearchSettingsOptions.WEB_AND_TABLE]: {
    label: 'Search everywhere',
    icon: SearchEverywhereIcon,
    search_settings: {
      use_web: true,
      use_table: true
    }
  }
});

export const ANSWER_TYPES: Readonly<
  Record<AnswerType, { label: string; icon: React.ComponentType }>
> = Object.freeze({
  [AnswerType.TEXT]: { label: 'Text', icon: TextTypeIcon },
  [AnswerType.NUMBER]: { label: 'Number', icon: NumberTypeIcon },
  [AnswerType.BOOLEAN]: { label: 'Yes/No', icon: DoneTypeIcon },
  [AnswerType.LINKS]: { label: 'Links', icon: LinkIcon }
});

export const DEFAULT_NEW_CONVERSATION_MESSAGE_TEXT =
  'All set! Your chat is now fresh, but your research is still here. What would you like to do next?';
