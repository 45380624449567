import { createSelector } from '@reduxjs/toolkit';

import { getExploreListByListId } from './explore';

const EMPTY_OBJECT = {};
const EMPTY_ARRAY = [];

export const getDomainResearch = (state) => state.domainResearch;

export const getAllExploreListsDomainResearchData = (state) =>
  getDomainResearch(state)?.exploreLists;

export const getExploreListDomainResearchData = (state, listId) =>
  getAllExploreListsDomainResearchData(state)?.[listId];

export const getExploreListSubdomains = (state, listId) =>
  getExploreListDomainResearchData(state, listId)?.subdomains || EMPTY_ARRAY;

export const getExploredDomains = createSelector(getExploreListSubdomains, (subdomains) => {
  if (!subdomains) return {};
  const returnSet = new Set();
  subdomains.forEach(({ parent_subdomain_id }) => {
    if (parent_subdomain_id) returnSet.add(parent_subdomain_id);
  });
  return returnSet;
});

export const getSubdomainsNames = createSelector(getExploreListSubdomains, (subdomains) => {
  if (!subdomains) return {};
  return subdomains.reduce((acc, { id, name }) => {
    acc[id] = name;
    return acc;
  }, {});
});

export const getRanSubdomains = (state, listId) =>
  getExploreListDomainResearchData(state, listId)?.discoveryRecords || EMPTY_OBJECT;

export const getIsSubdomainHighlighted = createSelector(
  getExploreListSubdomains,
  (state, listId, subdomainId) => subdomainId,
  (subdomains, subdomainId) => {
    if (!subdomains) return false;
    return (
      subdomains.filter(
        ({ id, shouldHightlight }) => Number(id) === Number(subdomainId) && shouldHightlight
      ).length > 0
    );
  }
);

export const subdomainIdToChildPrefixes = createSelector(
  getExploreListSubdomains,
  (state, listId, subdomainId) => subdomainId,
  (subdomains, subdomainId) => {
    if (!subdomainId) return [];
    return Array.from(
      new Set(
        subdomains
          .filter(({ parent_subdomain_id }) => parent_subdomain_id === subdomainId)
          .map((sd) => sd.parameters?.prompt_prefix)
      )
    );
  }
);

export const getDomainResearchCategoryIsCATriggered = (state, listId, parentId, promptPrefix) => {
  return Boolean(
    getExploreListByListId(state, listId)?.triggredCustomAnalytics?.[`${parentId}-${promptPrefix}`]
  );
};

export const getDomainResearchIsDescriptionHidden = (state, listId) =>
  getExploreListDomainResearchData(state, listId)?.hideSubdomainsDescription || false;

export const getMainDomainText = createSelector(getExploreListSubdomains, (subdomains) => {
  return subdomains?.find((subdomain) => subdomain.level === 0)?.name;
});
