import { TOAST_TYPES } from 'constants/toasts';
import { deleteExploreBulkCustomColumns } from 'reducer/explore-slice';

export function deleteBulkCustomColumnsAction(listId, columnIds, addToast) {
  return (dispatch, _getState, apiClient) => {
    apiClient.companiesLists
      .deleteBulkCustomColumns(listId, columnIds)
      .then((res) => {
        if (res.ok) {
          addToast('Succeeded to delete custom columns', TOAST_TYPES.SUCCESS);
          dispatch(deleteExploreBulkCustomColumns({ listId, columnIds }));
        } else {
          addToast(
            res?.message || res?.data?.message || 'Failed to delete custom columns',
            TOAST_TYPES.ERROR
          );
        }
      })
      .catch((res) => {
        addToast(
          res?.message || res?.data?.message || 'Failed to delete custom columns',
          TOAST_TYPES.ERROR
        );
      });
  };
}
