import { Button, Typography } from '@mui/material';
import MLChip from 'design-system/MLChip';
import MLInlineStack from 'design-system/MLInlineStack';
import React, { forwardRef, useMemo } from 'react';
import { getTitleFromFinanceType } from 'utils/finance-utils';

import ActualDataIndicationComponent from './ActualDataIndicationComponent';

type Props = {
  isEstimated: boolean;
  finalValue: string;
  color: string;
  onOpen: () => void;
  ref: React.RefObject<HTMLButtonElement>;
  financeType?: string;
};

const RenderFinanceTableInlineCell = forwardRef<HTMLButtonElement, Props>(
  ({ isEstimated, finalValue, color, onOpen, financeType }, ref) => {
    const financeTypeChip = useMemo(() => {
      if (!financeType) return null;
      let title = getTitleFromFinanceType(financeType);
      if (title?.split(' ').length > 1) {
        title = title
          .split(' ')
          .map((word: string) => word[0])
          .join('')
          .toUpperCase();
      }
      return title;
    }, [financeType]);
    return (
      <Button
        onClick={onOpen}
        ref={ref}
        fullWidth
        disableRipple
        sx={{
          '&:hover': { backgroundColor: 'unset' },
          padding: 0
        }}>
        <MLInlineStack width="100%">
          <Typography color={color} noWrap display="block">
            {finalValue}
          </Typography>
          <MLInlineStack marginLeft="auto">
            {financeTypeChip && (
              <MLChip
                size="small"
                label={financeTypeChip}
                sx={{
                  backgroundColor: 'colors.gray_bg',
                  color: 'colors.secondary_text'
                }}
              />
            )}
            <ActualDataIndicationComponent isEstimated={isEstimated} isOnDark={false} />
          </MLInlineStack>
        </MLInlineStack>
      </Button>
    );
  }
);

RenderFinanceTableInlineCell.displayName = 'RenderFinanceTableInlineCell';

export default RenderFinanceTableInlineCell;
