import { Menu, Stack } from '@mui/material';
import FiltersComponent from 'components/discovery/FiltersComponent';
import MLIconButton from 'design-system/MLIconButton';
import useMenuManagement from 'design-system/hooks/useMenuManagement';
import SettingsIcon from 'design-system/icons/basic/SettingsIcon';
import { useAppState } from 'hooks/state-context';
import { Scoping } from 'models/assistant/types';
import React, { useEffect } from 'react';
import { updatePromptConfig } from 'reducer/assistant-slice';
import { getPromptConfigScoping } from 'selectors/assistant';
import { getAllFilters } from 'selectors/companyLists';
import { useAppDispatch, useAppSelector } from 'types/store';

export default function AssistantScopingButton() {
  const { state } = useAppState();
  const allFilters = getAllFilters(state);
  const dispatch = useAppDispatch();
  const { onOpen, onClose, open, anchorEl, ref } = useMenuManagement<HTMLButtonElement>();
  const scoping = useAppSelector(getPromptConfigScoping);
  const determineInitialChecked = (key: string) => {
    return (
      (scoping && scoping[key]) || (allFilters[key].single && [allFilters[key].labels[0]]) || null
    );
  };
  const handleChange = (update: Scoping) => {
    dispatch(updatePromptConfig({ scoping: { ...scoping, ...update } }));
  };
  useEffect(() => {
    if (scoping && allFilters) {
      Object.keys(allFilters)
        .filter((key) => !allFilters[key]?.partnership_discovery)
        .forEach((key) => {
          const values =
            (allFilters[key].single && [allFilters[key].labels[0]]) || allFilters[key].labels;
          if (!scoping[key] && values) {
            const newScoping = { ...scoping, [key]: values };
            dispatch(updatePromptConfig({ scoping: newScoping }));
          }
        });
    }
  }, [allFilters, scoping, dispatch]);
  if (!scoping || !allFilters) return null;
  return (
    <React.Fragment>
      <MLIconButton ref={ref} tooltipText="Scoping" onClick={onOpen}>
        <SettingsIcon />
      </MLIconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        sx={{
          transform: 'translateY(-12px)'
        }}
        slotProps={{
          paper: {
            sx: {
              border: '1px solid',
              borderColor: 'colors.ui_border',
              paddingX: '0px',
              paddingY: '2px',
              borderRadius: '8px',
              boxShadow: 'box-shadow: 0px 2px 7px 0px rgba(66, 82, 110, 0.41)'
            }
          }
        }}>
        <Stack width="fit-content">
          {allFilters &&
            Object.keys(allFilters)
              ?.filter((key) => !allFilters[key]?.partnership_discovery)
              ?.map((key, index) => {
                return (
                  <FiltersComponent
                    key={index}
                    title={allFilters[key].title}
                    labels={allFilters[key].labels}
                    checked={determineInitialChecked(key)}
                    onChange={(newValue: string[]) => handleChange({ [key]: newValue })}
                  />
                );
              })}
        </Stack>
      </Menu>
    </React.Fragment>
  );
}
