import { Box, Stack, Typography } from '@mui/material';
import MLIconButton from 'design-system/MLIconButton';
import CloseIcon from 'design-system/icons/platform/CloseIcon';
import { ToastIcons } from 'icons/ToastIcons';
import PropTypes from 'prop-types';

export function ErrorDialog({ onClose, text }) {
  return (
    <Stack
      border={1}
      borderRadius={1}
      borderColor="colors.negative_red"
      padding="16px"
      alignItems="flex-start"
      direction="row"
      gap="8px"
      sx={{
        position: 'fixed',
        width: '400px',
        bottom: '5%',
        right: '24px',
        backgroundColor: 'colors.primary_bg',
        borderRadius: '4px',
        boxShadow: '0px 0px 2px rgba(15, 35, 69, 0.6), 2px 3px 3px rgba(15, 35, 69, 0.15)'
      }}>
      <Box>
        <ToastIcons type={'error'} />
      </Box>
      <Stack>
        <Typography variant="text1_Normal" textAlign="left">
          {text}
        </Typography>
      </Stack>
      <MLIconButton onClick={onClose}>
        <CloseIcon></CloseIcon>
      </MLIconButton>
    </Stack>
  );
}

ErrorDialog.propTypes = {
  onClose: PropTypes.func,
  text: PropTypes.string
};
