import { Avatar, Stack, Typography } from '@mui/material';
import MLInlineStack from 'design-system/MLInlineStack';
import { ApiThread } from 'models/assistant/api';
import React from 'react';

import AssistantAdminMessage from './AssistantAdminMessage';

interface AssistantAdminConversationProps {
  thread: ApiThread;
}

export default function AssistantAdminConversation({ thread }: AssistantAdminConversationProps) {
  const { user, company_list_id, organization, messages, thread_id } = thread;
  return (
    <Stack gap="16px">
      <Stack
        gap="4px"
        sx={{ backgroundColor: 'colors.selected', padding: '8px', borderRadius: '8px' }}>
        <MLInlineStack>
          <Typography>User: </Typography>
          {user.picture && <Avatar sx={{ width: '24px', height: '24px' }} src={user.picture} />}
          <Typography>{user.username}</Typography>
        </MLInlineStack>
        {organization && (
          <MLInlineStack>
            <Typography>Organization: </Typography>
            {organization.logo_url && (
              <Avatar sx={{ width: '24px', height: '24px' }} src={organization.logo_url} />
            )}
            <Typography>{organization.name}</Typography>
          </MLInlineStack>
        )}
        <Typography>List ID: {company_list_id}</Typography>
        <Typography>Thread ID: {thread_id}</Typography>
      </Stack>
      <Stack gap="8px">
        {messages ? (
          [...messages]
            .reverse()
            .map((message, index) => <AssistantAdminMessage key={index} message={message} />)
        ) : (
          <Typography>No messages</Typography>
        )}
      </Stack>
    </Stack>
  );
}
