import React from 'react';

export default function JsonIcon() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.2353 8C11.5947 8 10.2647 9.32998 10.2647 10.9706V14.3529C10.2647 14.9833 9.7562 15.4949 9.127 15.5L9.11765 15.4999H8.5C8.22386 15.4999 8 15.7238 8 15.9999C8 16.2761 8.22386 16.5 8.5 16.5H9.11765L9.127 16.5C9.75619 16.505 10.2647 17.0166 10.2647 17.647V21.0294C10.2647 22.67 11.5947 24 13.2353 24H13.6471C13.9232 24 14.1471 23.7761 14.1471 23.5C14.1471 23.2239 13.9232 23 13.6471 23H13.2353C12.147 23 11.2647 22.1177 11.2647 21.0294V17.647C11.2647 16.9855 10.9655 16.3938 10.4951 16C10.9655 15.6061 11.2647 15.0145 11.2647 14.3529V10.9706C11.2647 9.88226 12.147 9 13.2353 9H13.6471C13.9232 9 14.1471 8.77614 14.1471 8.5C14.1471 8.22386 13.9232 8 13.6471 8H13.2353Z"
        fill="#5C5E76"
      />
      <path
        d="M18.7647 8C20.4053 8 21.7353 9.32998 21.7353 10.9706V14.3529C21.7353 14.9833 22.2438 15.4949 22.873 15.5C22.8761 15.4999 22.8792 15.4999 22.8824 15.4999H23.5C23.7761 15.4999 24 15.7238 24 15.9999C24 16.2761 23.7761 16.5 23.5 16.5H22.8824C22.8792 16.5 22.8761 16.5 22.873 16.5C22.2438 16.505 21.7353 17.0166 21.7353 17.647V21.0294C21.7353 22.67 20.4053 24 18.7647 24H18.3529C18.0768 24 17.8529 23.7761 17.8529 23.5C17.8529 23.2239 18.0768 23 18.3529 23H18.7647C19.853 23 20.7353 22.1177 20.7353 21.0294V17.647C20.7353 16.9855 21.0345 16.3938 21.5049 16C21.0345 15.6061 20.7353 15.0145 20.7353 14.3529V10.9706C20.7353 9.88226 19.853 9 18.7647 9H18.3529C18.0768 9 17.8529 8.77614 17.8529 8.5C17.8529 8.22386 18.0768 8 18.3529 8H18.7647Z"
        fill="#5C5E76"
      />
    </svg>
  );
}
