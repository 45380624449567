import { createSelector } from '@reduxjs/toolkit';

import { getCompanyListHistoryByListId, getCompanyMetasByListId } from './explore';

export const getCompanyMetasMergedRowsByCompanyListId = createSelector(
  getCompanyMetasByListId,
  getCompanyListHistoryByListId,
  (_state, listId) => listId?.toString(),
  (companyMetas, companyListHistory, listId) => {
    if (!companyMetas) return null;
    const rows = [];
    companyMetas.forEach((companyMeta) => {
      const {
        company_meta_id,
        company_list_meta_id,
        parameters,
        enrichment_parameters,
        company_extra_data,
        hash
      } = companyMeta;
      const row = {
        ...parameters,
        ...enrichment_parameters,
        name:
          parameters?.display_name ||
          parameters?.cb_name ||
          parameters?.linkedin_name ||
          parameters?.name,
        company_extra_data,
        recordId: companyMeta.record_id,
        companyListId: listId,
        recordReady: companyMeta.record_ready,
        customColumns: companyMeta.customColumns,
        files: companyMeta.files,
        recordTimeSubmitted: companyMeta.record_time_submitted,
        isNew: companyMeta.is_discovered,
        companyMetaId: company_meta_id,
        companyListMetaId: company_list_meta_id,
        hiddenGemsData: companyMeta?.hidden_gems_data,
        id: hash,
        iteration: companyMeta?.iteration_added,
        clustersLabels: companyMeta?.clusters_labels,
        clustersTags: companyMeta?.clusters_tags,
        showAmbiguousWebsite:
          !companyMeta.is_discovered &&
          parameters?.ambiguous_website &&
          !companyMeta.ignore_ambiguous,
        financePeerData: companyMeta.finance_peer_data,
        financePeerStatus: companyMeta.finance_peer_data_status,
        country: enrichment_parameters?.hq_location_country || enrichment_parameters?.hq_location,
        hq: enrichment_parameters?.hq_location_state,
        region: enrichment_parameters?.hq_location_continent,
        employees: enrichment_parameters?.size,
        cb_url:
          parameters?.cb_permalink &&
          `https://www.crunchbase.com/organization/${parameters.cb_permalink}`
      };
      row.company_source = { ...(companyListHistory?.[row.iteration] || {}) };
      row.company_source.time_submitted =
        companyMeta.company_list_meta_time_submitted || row.company_source?.time_submitted;
      row.created_at =
        companyMeta.company_list_meta_time_submitted || row.company_source?.time_submitted;
      row.extra_info = { ...enrichment_parameters };
      rows.push(row);
    });
    return rows;
  }
);

export const getCompanyMetasExtraDataByListIdAndMetaId = createSelector(
  getCompanyMetasByListId,
  (_state, _listId, companyMetaId) => companyMetaId,
  (companyMetas, companyMetaId) =>
    companyMetas?.find((cm) => cm.company_meta_id === companyMetaId)?.company_extra_data
);

export const getCompanyMetasExtraDataByListIdAndMetaIdAndColumnId = createSelector(
  getCompanyMetasExtraDataByListIdAndMetaId,
  (_state, _listId, _companyMetaId, columnId) => columnId,
  (companyExtraData, columnId) => companyExtraData?.[columnId]
);

export const getCompanyMetasMergedRowsMapByListId = createSelector(
  getCompanyMetasMergedRowsByCompanyListId,
  (rows) => {
    return rows
      ? rows.reduce((acc, row) => {
          acc[row.companyListMetaId] = { ...row, custom_columns: row.customColumns };
          return acc;
        }, {})
      : {};
  }
);

export const getCompanyMetaCustomColumnValueByListIdAndMetaIdAndColumnId = createSelector(
  getCompanyMetasByListId,
  (_state, _listId, companyMetaId, columnId) => [companyMetaId, columnId],
  (companyMetas, [companyMetaId, columnId]) => {
    const companyMeta = companyMetas?.find((cm) => cm.company_meta_id === companyMetaId);
    return companyMeta?.customColumns?.[columnId];
  }
);

export const mapMetaIdToListMetaId = createSelector(
  getCompanyMetasByListId,
  (_state, listId) => listId,
  (companyMetas) => {
    if (!companyMetas) return null;
    return companyMetas.reduce((acc, company) => {
      acc[company.company_meta_id] = company.company_list_meta_id;
      return acc;
    }, {});
  }
);

export const mapListMetaIdToMetaId = createSelector(
  getCompanyMetasByListId,
  (_state, listId) => listId,
  (companyMetas) => {
    if (!companyMetas) return null;
    return companyMetas.reduce((acc, company) => {
      acc[company.company_list_meta_id] = company.company_meta_id;
      return acc;
    }, {});
  }
);
