import { Stack } from '@mui/material';
import { DISCOVERY_TYPES } from 'constants/discovery';
import PropTypes from 'prop-types';
import React from 'react';

import DiscoverySuggestionsContentComponent from './DiscoverySuggestionsContentComponent';

function DiscoverySuggestionsComponent({ suggestions, setPromptConfig }) {
  const onClickSuggestion = (suggestion) => {
    setPromptConfig((prev) => {
      return {
        ...prev,
        question: suggestion.prompt,
        useDiscover: true,
        discoverMethod: DISCOVERY_TYPES.TEXT,
        useColumn: false,
        useColumns: false,
        columns: [],
        forEachCompany: false,
        useDomainKnowledge: false,
        domainKnowledge: null,
        searchTheWeb: false,
        useTable: false
      };
    });
  };

  return (
    <Stack paddingLeft="50px">
      <DiscoverySuggestionsContentComponent
        suggestions={suggestions}
        onClickSuggestion={onClickSuggestion}
      />
    </Stack>
  );
}

export default DiscoverySuggestionsComponent;

DiscoverySuggestionsComponent.propTypes = {
  suggestions: PropTypes.array,
  setPromptConfig: PropTypes.func
};
