import React from 'react';

export default function ThunderIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.7145 4.5H10.1415C10.0567 4.5 9.98113 4.55347 9.9529 4.63344L7.09408 12.7334C7.04816 12.8636 7.14469 13 7.28268 13H10L9.54349 19.3911C9.52973 19.5838 9.77022 19.6821 9.89537 19.5349L17.7199 10.3295C17.8303 10.1996 17.738 10 17.5675 10H14.2855C14.1467 10 14.0501 9.86209 14.0976 9.73165L15.9024 4.76835C15.9499 4.63791 15.8533 4.5 15.7145 4.5Z"
        stroke="currentColor"
      />
    </svg>
  );
}
