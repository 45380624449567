import MLIcon from 'design-system/MLIcon';
import ClustersIcon from 'design-system/icons/platform/ClustersIcon';
import GraphWidgetIcon from 'design-system/icons/platform/GraphWidgetIcon';
import KnowledgeIcon from 'design-system/icons/platform/KnowledgeIcon';
import ListWidgetIcon from 'design-system/icons/platform/ListWidgetIcon';
import OrganizationIcon from 'design-system/icons/platform/OrganizationIcon';
import {
  DomainReportCaTaskType,
  DomainReportSectionPresentationProps,
  DomainReportSectionType
} from 'models/domain-report/types';
import React from 'react';

export const DOMAIN_REPORT_TASK_TYPES = Object.freeze({
  TAGGING: 'TAGGING',
  TOP_PROBLEMS: 'TOP_PROBLEMS',
  MARKET_OVERVIEW: 'MARKET_OVERVIEW'
});

type DomainReportTaskType = typeof DOMAIN_REPORT_TASK_TYPES[keyof typeof DOMAIN_REPORT_TASK_TYPES];

export const DOMAIN_REPORT_TASK_TYPES_TO_TITLES: Record<DomainReportTaskType, string> =
  Object.freeze({
    [DOMAIN_REPORT_TASK_TYPES.TAGGING]: 'Cluster Analysis',
    [DOMAIN_REPORT_TASK_TYPES.TOP_PROBLEMS]: 'Top Problems',
    [DOMAIN_REPORT_TASK_TYPES.MARKET_OVERVIEW]: 'Market Overview'
  });

export const SECTIONS_DEFAULT_PRESENTATION_PROPS: Record<
  DomainReportSectionType,
  DomainReportSectionPresentationProps
> = Object.freeze({
  [DomainReportSectionType.EXPLORE_PROCESS]: {
    defaultTitle: 'Explore Process',
    iconComponent: React.createElement(MLIcon, {
      icon: React.createElement(OrganizationIcon),
      backgroundColor: 'accent_colors.bright_blue_bg1',
      color: 'accent_colors.bright_blue',
      size: 'small'
    }),
    isEditable: false,
    isAddable: false,
    isDeletable: false
  },
  [DomainReportSectionType.MARKET_LANDSCAPE]: {
    defaultTitle: 'Market Landscape',
    iconComponent: React.createElement(MLIcon, {
      icon: React.createElement(GraphWidgetIcon),
      backgroundColor: 'accent_colors.poor_orange_bg1',
      color: 'accent_colors.orange',
      size: 'small'
    }),
    isEditable: true,
    isAddable: false,
    isDeletable: false,
    customAnalyticsTaskType: DomainReportCaTaskType.MARKET_OVERVIEW
  },
  [DomainReportSectionType.TOP_PROBLEMS_BEING_SOLVED]: {
    defaultTitle: 'Top Problems Being Solved',
    iconComponent: React.createElement(MLIcon, {
      icon: React.createElement(ListWidgetIcon),
      backgroundColor: 'colors.selected_negative_red',
      color: 'colors.negative_red',
      size: 'small'
    }),
    isEditable: true,
    isAddable: false,
    isDeletable: false,
    customAnalyticsTaskType: DomainReportCaTaskType.TOP_PROBLEMS
  },
  [DomainReportSectionType.CLUSTER_ANALYSIS]: {
    defaultTitle: 'Cluster Analysis',
    iconComponent: React.createElement(MLIcon, {
      icon: React.createElement(ClustersIcon),
      backgroundColor: 'accent_colors.dark_lavender_bg1',
      color: 'accent_colors.dark_lavender',
      size: 'small'
    }),
    isEditable: true,
    isAddable: false,
    isDeletable: false,
    customAnalyticsTaskType: DomainReportCaTaskType.TAGGING
  },
  [DomainReportSectionType.DOMAIN_KNOWLEDGE]: {
    defaultTitle: 'Saved Answers',
    iconComponent: React.createElement(MLIcon, {
      icon: React.createElement(KnowledgeIcon),
      backgroundColor: 'accent_colors.orchid_bg1',
      color: 'accent_colors.orchid',
      size: 'small'
    }),
    isEditable: true,
    isAddable: true,
    isDeletable: true
  }
});
