import { Box, Stack } from '@mui/material';
import { SUGGESTION_ID_TYPE_TO_LABEL } from 'constants/assistant';
import { MentionId, MentionItem, SuggestionIdType } from 'models/assistant/types';
import React, { forwardRef, useImperativeHandle, useState } from 'react';

import { MentionListBaseProps } from './MentionList';
import MentionListCategoryItem from './MentionListCategoryItem';

export interface MentionListDefaultHandle {
  onKeyDown: ({ event }: { event: KeyboardEvent }) => boolean;
}

type MentionItemCategory = MentionItem & { filteredItems: MentionItem[] };

const MentionListDefault = forwardRef<MentionListDefaultHandle, MentionListBaseProps>(
  ({ items, command }, ref) => {
    const [selectedCategoryIndex, setSelectedCategoryIndex] = useState<number | null>(null);
    const [selectedItemIndex, setSelectedItemIndex] = useState<number | null>(null);
    const categories: MentionItemCategory[] = Object.values(
      items.reduce((acc, { id, label }: MentionItem) => {
        const [suggestionIdType] = id.split(':') as [SuggestionIdType];
        const categoryId: MentionId = `${suggestionIdType}:all`;
        if (acc[suggestionIdType]) {
          acc[suggestionIdType].filteredItems.push({ id, label });
        } else {
          acc[suggestionIdType] = {
            id: categoryId,
            label: SUGGESTION_ID_TYPE_TO_LABEL[suggestionIdType],
            filteredItems: [{ id, label }]
          };
        }
        return acc;
      }, {} as Record<SuggestionIdType, MentionItemCategory>)
    );
    const filteredItems =
      selectedCategoryIndex === null ? null : categories[selectedCategoryIndex].filteredItems;

    const selectItem = (index: number) => {
      if (!filteredItems) {
        return;
      }
      const item = filteredItems[index];

      if (item) {
        command(item);
      }
    };

    const upHandler = () => {
      if (filteredItems && selectedItemIndex !== null) {
        setSelectedItemIndex((selectedItemIndex + filteredItems.length - 1) % filteredItems.length);
        return;
      }
      if (selectedCategoryIndex === null) {
        setSelectedCategoryIndex(categories.length - 1);
        return;
      }
      setSelectedCategoryIndex((selectedCategoryIndex + categories.length - 1) % categories.length);
    };

    const downHandler = () => {
      if (filteredItems && selectedItemIndex !== null) {
        setSelectedItemIndex((selectedItemIndex + 1) % filteredItems.length);
        return;
      }
      if (selectedCategoryIndex === null) {
        setSelectedCategoryIndex(0);
        return;
      }
      setSelectedCategoryIndex((selectedCategoryIndex + 1) % categories.length);
    };

    const leftHandler = () => {
      if (selectedCategoryIndex === null) {
        return;
      }
      if (selectedItemIndex !== null) {
        setSelectedItemIndex(null);
      }
    };

    const rightHandler = () => {
      if (selectedCategoryIndex !== null && selectedItemIndex === null) {
        setSelectedItemIndex(0);
      }
    };

    const enterHandler = () => {
      if (selectedCategoryIndex === null || selectedItemIndex === null) {
        return;
      }
      selectItem(selectedItemIndex);
      setSelectedCategoryIndex(null);
      setSelectedItemIndex(null);
    };

    useImperativeHandle(ref, () => ({
      onKeyDown: ({ event }) => {
        if (event.key === 'ArrowUp') {
          upHandler();
          return true;
        }

        if (event.key === 'ArrowDown') {
          downHandler();
          return true;
        }

        if (event.key === 'ArrowLeft') {
          leftHandler();
          return true;
        }

        if (event.key === 'ArrowRight') {
          rightHandler();
          return true;
        }

        if (event.key === 'Enter') {
          enterHandler();
          return true;
        }

        return false;
      }
    }));

    return (
      <Stack>
        {categories.map(({ id, label }, index) => (
          <Box
            key={index}
            sx={{ cursor: 'pointer' }}
            onMouseEnter={() => setSelectedCategoryIndex(index)}>
            <MentionListCategoryItem
              id={id}
              label={label}
              focused={index === selectedCategoryIndex}
              selectedItemIndex={selectedItemIndex}
              selectItem={selectItem}
              filteredItems={categories[index].filteredItems}
            />
          </Box>
        ))}
      </Stack>
    );
  }
);

MentionListDefault.displayName = 'MentionListDefault';

export default MentionListDefault;
