import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Input } from '@mui/material';
import { useAppUtils } from 'AppUtilsProvider';
import { addDiscoveryDomainKnowledgeResourceAction } from 'actions/explore/add-discovery-domain-knowledge-resource-action';
import { DOMAIN_KNOWLEDGE_RESOURCE_TYPE } from 'constants/domain-knowledge';
import { TOAST_TYPES } from 'constants/toasts';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getThreadKey } from 'reducer/custom-analytics';
import { getCustomAnalyticsThreadSpecificPostById } from 'selectors/custom-analytics';

function ChooseEntrichDomainKnowledgeNameDialog({
  open,
  onClose,
  listId,
  domainKnowledgePostId,
  action
}) {
  const dispatch = useDispatch();
  const toastRef = useRef();
  const { addToast, cancelToast } = useAppUtils();
  const threadKey = getThreadKey(listId);
  const post = useSelector((state) =>
    getCustomAnalyticsThreadSpecificPostById(state, threadKey, domainKnowledgePostId)
  );

  const [domainKnowledgeResourceName, setDomainKnowledgeResourceName] = useState('');

  const closeDialog = () => {
    setDomainKnowledgeResourceName('');
    onClose();
  };

  const handleDomainKnowledgeResourceNameChange = (event) => {
    setDomainKnowledgeResourceName(event.target.value);
  };

  const additionalData = action ? { action, version: 2 } : {};

  const handleAddDomainKnowledgeResource = () => {
    dispatch(
      addDiscoveryDomainKnowledgeResourceAction({
        listId,
        resourceType: DOMAIN_KNOWLEDGE_RESOURCE_TYPE.ANSWERS,
        resourceName: domainKnowledgeResourceName,
        data: {
          title: domainKnowledgeResourceName,
          query: post.query,
          message: post.message,
          ...additionalData
        },
        onFinish: () => {
          cancelToast(toastRef.current);
          toastRef.current = null;
          const id = addToast('Adding new domain knowledge resource succeed', TOAST_TYPES.SUCCESS);
          setTimeout(() => cancelToast(id), 2000);
        }
      })
    );
    toastRef.current = addToast('Adding new domain knowledge resource', TOAST_TYPES.INFO);
    closeDialog();
  };

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ sx: { width: '500px' } }}>
      <DialogTitle id="alert-dialog-title"> What is the new domain knowledge name?</DialogTitle>
      <DialogContent>
        <Input
          value={domainKnowledgeResourceName}
          onChange={handleDomainKnowledgeResourceNameChange}
          placeholder="Write the name here"
          sx={{
            width: '100%'
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Cancel</Button>
        <Button
          onClick={handleAddDomainKnowledgeResource}
          disabled={domainKnowledgeResourceName.length <= 0}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ChooseEntrichDomainKnowledgeNameDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  listId: PropTypes.number,
  domainKnowledgePostId: PropTypes.string,
  action: PropTypes.object
};

export default ChooseEntrichDomainKnowledgeNameDialog;
