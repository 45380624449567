export const COMPANY_EXTRA_DATA_TYPES = Object.freeze({
  LEADERS: 'LEADERS',
  SUMMARY: 'SUMMARY',
  VECTORISATION: 'VECTORISATION',
  ENRICHMENT_PARAMETERS: 'ENRICHMENT_PARAMETERS',
  FINANCE: 'FINANCE'
});

export const OVERRIDE_TYPE_TO_FIELD_MAP = Object.freeze({
  [COMPANY_EXTRA_DATA_TYPES.ENRICHMENT_PARAMETERS]: 'enrichment_parameters'
});

export const DATA_TYPE_TO_FEATURE_FLAG = Object.freeze({
  // for feature flag for column use the feature flag name from COLUMN_TYPE_TO_FEATURE_FLAG_NAME like this:
  // [COMPANY_EXTRA_DATA_TYPES.LEADERS]: COLUMN_TYPE_TO_FEATURE_FLAG_NAME.LEADERS
});

export const COLUMN_ID_TO_DATA_TYPE = Object.freeze({
  REVENUES: COMPANY_EXTRA_DATA_TYPES.FINANCE,
  EARNING: COMPANY_EXTRA_DATA_TYPES.FINANCE,
  EBITDA: COMPANY_EXTRA_DATA_TYPES.FINANCE
});
