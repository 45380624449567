import { ListContextType } from 'hooks/ListContextProvider';
import { AssistantPromptConfigJSON } from 'models/assistant/AssistantPromptConfig';
import { ActionType } from 'models/assistant/assistant-actions';
import { V1orV2CaParameters, V2CaParameters } from 'models/assistant/backwards-compatibility';
import { SuggestionIdType } from 'models/assistant/types';

interface ProcessedV1Question {
  text: string;
  forEachCompany: boolean;
  searchTheWeb: boolean;
  column_ids: string[];
  supporting_resource_ids: string[];
}

function extractUsingColumns(tokens: string[]): string[] {
  const columns: string[] = [];
  // Regex to match tokens of the form "using columns $col1,col2,..."
  // The regex is case-insensitive.
  // eslint-disable-next-line prefer-named-capture-group, require-unicode-regexp
  const pattern = /^using columns\s+\$(.+)$/i;

  tokens.forEach((token) => {
    const match = token.match(pattern);
    if (match && match[1]) {
      // Split the captured group by commas and trim each column name
      const cols = match[1]
        .split(',')
        .map((col) => col.trim())
        .filter((col) => col);
      columns.push(...cols);
    }
  });

  return columns;
}

function extractUsingDomainKnowledge(tokens: string[]): string[] {
  const columns: string[] = [];
  // Regex to match tokens of the form "using columns $col1,col2,..."
  // The regex is case-insensitive.
  // eslint-disable-next-line prefer-named-capture-group, require-unicode-regexp
  const pattern = /^using domain knowledge\s+\$(.+)$/i;

  tokens.forEach((token) => {
    const match = token.match(pattern);
    if (match && match[1]) {
      // Split the captured group by commas and trim each column name
      const cols = match[1]
        .split(',')
        .map((col) => col.trim())
        .filter((col) => col);
      columns.push(...cols);
    }
  });

  return columns;
}

export function processV1Question(question: string): ProcessedV1Question {
  // Regular expression to match tokens wrapped in {{ }}
  // eslint-disable-next-line prefer-named-capture-group, require-unicode-regexp
  const tokenRegex = /\{\{([^}]+)\}\}/g;
  const tokens: string[] = [];
  let match: RegExpExecArray | null = null;

  // Extract all tokens (content inside {{ }})
  while ((match = tokenRegex.exec(question)) !== null) {
    tokens.push(match[1].trim().toLowerCase());
  }

  // Remove all {{ ... }} tokens to isolate the plain text question
  const text = question?.replace(tokenRegex, '')?.trim();

  return {
    text,
    forEachCompany: tokens.includes('for each company'),
    searchTheWeb: tokens.includes('search the web'),
    column_ids: extractUsingColumns(tokens),
    supporting_resource_ids: extractUsingDomainKnowledge(tokens)
  };
}

function labelFromListContext(
  id: string,
  suggestionIdType: SuggestionIdType,
  listContext: ListContextType | null = null
): string {
  if (!listContext) {
    return id;
  }
  switch (suggestionIdType) {
    case SuggestionIdType.COMPANY:
      return (
        listContext.companies.find((company) => company.companyMetaId === parseInt(id, 10))?.name ||
        ''
      );
    case SuggestionIdType.COLUMN:
      return listContext.columns.find((column) => column.id === id)?.name || '';
    case SuggestionIdType.SUPPORTING_RESOURCE:
      return (
        listContext.supportingResources.find((resource) => resource.id === parseInt(id, 10))
          ?.name || ''
      );
    default:
      return id;
  }
}

function processV2Parameters(parameters: V2CaParameters): AssistantPromptConfigJSON {
  const { v2_request: request } = parameters;
  return {
    answer_type: parameters.answer_type,
    scoping: null,
    saved_query_id: null,
    search_settings: null,
    overwrite: false,
    ...request
  };
}

export function convertV1CaParametersToV2(
  parameters: V1orV2CaParameters,
  listContext: ListContextType | null = null
): AssistantPromptConfigJSON {
  const { version } = parameters;
  if (
    version === 2 &&
    [ActionType.ADD_COLUMN, ActionType.ASK_QUESTION].includes(parameters.v2_request.action_type)
  ) {
    return processV2Parameters(parameters);
  }
  const { text, forEachCompany, searchTheWeb, column_ids, supporting_resource_ids } =
    processV1Question(parameters.question);
  let v2QuestionPrefix = '';
  new Set([...(parameters.columns || []), ...column_ids]).forEach((column) => {
    v2QuestionPrefix += `@[${labelFromListContext(
      column,
      SuggestionIdType.COLUMN,
      listContext
    )}](column_id:${column}) `;
  });
  const resourceIdFromParams = parameters.supporting_resource_id || [];
  new Set([
    ...(typeof resourceIdFromParams === 'object'
      ? resourceIdFromParams
      : [resourceIdFromParams]
    ).map((item) => item.toString()),
    ...supporting_resource_ids
  ]).forEach((srid) => {
    v2QuestionPrefix += `@[${labelFromListContext(
      srid,
      SuggestionIdType.SUPPORTING_RESOURCE,
      listContext
    )}](supporting_resource_id:${srid}) `;
  });
  if (v2QuestionPrefix.length > 0) {
    v2QuestionPrefix = `Using ${v2QuestionPrefix}: `;
  }
  return {
    action_type:
      forEachCompany || parameters.for_each_company
        ? ActionType.ADD_COLUMN
        : ActionType.ASK_QUESTION,
    text: `${v2QuestionPrefix}${text}`,
    answer_type: parameters.answer_type,
    scoping: null,
    saved_query_id: null,
    search_settings: {
      use_web: searchTheWeb,
      use_table: parameters.is_table_question !== false
    },
    overwrite: false
  };
}
