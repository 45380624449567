import { appTheme } from 'theme';

export function applyThemeColorOpacity(color, opacity) {
  const colorParts = color.split('.');
  const hexColor = colorParts.reduce(
    (partialPathInTheme, colorPart) => partialPathInTheme[colorPart],
    appTheme.palette
  );

  let actualOpacity = opacity;
  if (opacity < 0) {
    actualOpacity = 0;
  } else if (opacity > 100) {
    actualOpacity = 100;
  }

  actualOpacity = Math.round(actualOpacity * 2.55);

  return hexColor + actualOpacity.toString(16).toUpperCase().padStart(2, '0');
}

export function sxColorToStyleColor(color, theme) {
  const [colorLevel1, colorLevel2] = color.split('.');
  return theme.vars.palette?.[colorLevel1]?.[colorLevel2] || color;
}
