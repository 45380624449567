/* eslint-disable max-lines */

/* eslint-disable max-lines-per-function */
import HistoryIcon from '@mui/icons-material/History';
import { Box, Divider, ListItemIcon, ListItemText, MenuItem, Stack, Tooltip } from '@mui/material';
import { useAppUtils } from 'AppUtilsProvider';
import { getCompanyListColumnHistoryAction } from 'actions/company_extra_data/get-company-list-column-history-action';
import { deleteBulkCustomColumnsAction } from 'actions/explore/delete-bulk-custom-columns-action';
import { editDiscoveryCustomColumnInfoAction } from 'actions/explore/edit-discovery-custom-column-info-action';
import { editDiscoveryCustomColumnNameAction } from 'actions/explore/edit-discovery-custom-column-name-action';
import { createListFromColumnAction } from 'actions/llm_queries/check-values-contains-names-action';
import { createSchedulesByCustomAnalyticsColumnsAction } from 'actions/schedules/create-column-schedule-action';
import { deleteSchedulesByColumnsAction } from 'actions/schedules/delete-schedule-action';
import SaveQueryDialog from 'components/discovery/SaveQueryDialog';
import { SCHEDULE_FREQUENCIES } from 'constants/schedules';
import { TOAST_TYPES } from 'constants/toasts';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import ArrowIcon from 'icons/ArrowIcon';
import DeleteIcon from 'icons/DeleteIcon';
import DeleteMultipleIcon from 'icons/DeleteMultipleIcon';
import EditIcon from 'icons/EditIcon';
import MonitoringIcon from 'icons/MonitoringIcon';
import { NewListIcon } from 'icons/NewListIcon';
import OutlinedInfoIcon from 'icons/OutlinedInfoIcon';
import PaintIcon from 'icons/PaintIcon';
import SearchIcon from 'icons/SearchIcon';
import VisibilityOffIcon from 'icons/VisibilityOffIcon';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { updateExploreHiddenColumns } from 'reducer/explore-slice';
import { getUserFeatureFlags } from 'selectors/user';
import { downloadDiscoveryHistoryFromSourceValues } from 'utils/export-utils';
import { isEmpty } from 'utils/objects-utils';

import ColorPickerMenu from './ColorPickerMenu';
import { DeleteColumnDialog } from './ColumnMenu/DeleteColumnDialog';
import DialogDeleteMultipleColumns from './ColumnMenu/DialogDeleteMultipleColumns';
import { QuerySettingsDialog } from './ColumnMenu/QuerySettingsDialog';
import { RenameColumnDialog } from './ColumnMenu/RenameColumnDialog';
import ExtraDataColumnMenuItem from './ExtraDataColumnMenuItem';

function CustomColumnMenu({ colDef: currentColumn, ...args }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openDeleteMultipleDialog, setOpenDeleteMultipleDialog] = useState(false);
  const [openSettingsDialog, setOpenSettingsDialog] = useState(false);
  const [newName, setNewName] = useState(currentColumn?.headerName || '');
  const dispatch = useDispatch();
  const { field: columnId, headerName: columnName, extraParams } = currentColumn;
  const { listId, info, allowEditHeader } = extraParams;
  const featureFlags = useOldStateSelector(getUserFeatureFlags);
  const canUnify = featureFlags?.includes('unify');
  const handleClose = () => setOpenDialog(false);
  const handleDeleteMultipleColumnsClose = () => {
    setOpenDeleteMultipleDialog(false);
  };
  const handleDeleteClose = () => setOpenDeleteDialog(false);
  const handleSettingsClose = () => setOpenSettingsDialog(false);
  const { addToast } = useAppUtils();
  const [isSaved, setIsSaved] = useState(false);
  const [colorPickerAnchorEl, setColorPickerAnchorEl] = useState(null);
  const nestedComponentRef = useRef();

  const handleRename = () => {
    dispatch(editDiscoveryCustomColumnNameAction(listId, columnId, newName));
    handleClose();
  };

  const handleDelete = (item = null) => {
    const dataToRemove = item ? Object.keys(item) : [columnId];
    dispatch(deleteBulkCustomColumnsAction(listId, dataToRemove, addToast));
    if (item) {
      setOpenDeleteMultipleDialog(false);
    } else {
      setOpenDeleteDialog(false);
    }
    handleClose();
  };

  const handleCopyContent = () => {
    navigator.clipboard.writeText(info?.question || info);
    handleSettingsClose();
  };

  const handleHideColumn = (event) => {
    searchParams.append('hidden_columns', columnId);
    dispatch(updateExploreHiddenColumns({ listId, columnsIds: [columnId], isHidden: true }));
    setSearchParams(searchParams);
    args.hideMenu(event);
  };

  const onError = (message) => {
    addToast(message, TOAST_TYPES.ERROR);
  };

  const handleExportHistory = () => {
    downloadDiscoveryHistoryFromSourceValues(
      listId,
      currentColumn.extraParams.values,
      currentColumn.extraParams.history
    );
  };

  const createNewListFromColumn = (event) => {
    dispatch(createListFromColumnAction(listId, currentColumn, onError));
    args.hideMenu(event);
    handleClose();
  };

  const toggleColumnMonitor = (event) => {
    const oldInfo = currentColumn?.extraParams?.info || {};
    const newMonitor = !oldInfo.monitor;
    const newInfo = { ...oldInfo, monitor: newMonitor };
    dispatch(editDiscoveryCustomColumnInfoAction(listId, currentColumn.field, newInfo));
    if (newMonitor) {
      dispatch(
        createSchedulesByCustomAnalyticsColumnsAction(
          listId,
          [currentColumn.field],
          SCHEDULE_FREQUENCIES.DAILY
        )
      );
    } else {
      dispatch(deleteSchedulesByColumnsAction(listId, [currentColumn.field]));
    }

    args.hideMenu(event);
    handleClose();
  };

  const addLastDataColumn = () => {
    dispatch(getCompanyListColumnHistoryAction(listId, currentColumn.field));
    args.hideMenu(event);
    handleClose();
  };

  const onSaveQuery = (saved_query_id) => {
    const oldInfo = currentColumn?.extraParams?.info || {};
    const newInfo = { ...oldInfo, saved_query_id };
    dispatch(editDiscoveryCustomColumnInfoAction(listId, currentColumn.field, newInfo));
  };
  const MenuItemsDivider = <Divider sx={{ width: '95%', alignSelf: 'center' }} />;

  const menuItems = {
    info: {
      isVisible: info?.question,
      onClick: () => setOpenSettingsDialog(true),
      startIcon: <OutlinedInfoIcon />,
      text: 'Query',
      menuItemId: 'query-settings'
    },
    save: {
      isVisible: currentColumn?.type === 'custom_analytics' && info?.question,
      onClick: () => {
        if (nestedComponentRef.current) {
          nestedComponentRef.current.notifyFromParent();
        }
      },
      startIcon: (
        <SaveQueryDialog
          prompt={info}
          existColumnName={columnName}
          onSaveQuery={onSaveQuery}
          isSaved={isSaved}
          setIsSaved={setIsSaved}
          listId={listId}
          ref={nestedComponentRef}
          showIsBookmarked={false}
        />
      ),
      text: null,
      // use function to avoid re-rendering when isSaved changes
      getText: () => (isSaved ? 'Remove from Column Library' : 'Save to Column Library'),
      menuItemId: 'save-query'
    },
    rename: {
      isVisible: allowEditHeader,
      onClick: () => setOpenDialog(true),
      startIcon: <EditIcon />,
      text: 'Rename',
      menuItemId: 'rename-column'
    },
    colorColumn: {
      isVisible: allowEditHeader,
      onClick: (event) => setColorPickerAnchorEl(event.target),
      startIcon: <PaintIcon />,
      endIcon: (
        <Box sx={{ transform: 'rotate(90deg)' }}>
          <ArrowIcon />
        </Box>
      ),
      text: 'Color Column',
      menuItemId: 'color-column'
    },
    deleteMultiple: {
      isVisible: allowEditHeader,
      onClick: () => setOpenDeleteMultipleDialog(true),
      startIcon: <DeleteMultipleIcon />,
      text: 'Delete Multiple Columns',
      menuItemId: 'delete-multiple-columns'
    },
    delete: {
      isVisible: allowEditHeader,
      onClick: () => setOpenDeleteDialog(true),
      startIcon: <DeleteIcon />,
      text: 'Delete',
      menuItemId: 'delete-column'
    },
    hide: {
      isVisible: true,
      onClick: handleHideColumn,
      startIcon: <VisibilityOffIcon />,
      text: 'Hide Column',
      menuItemId: 'hide-column'
    },
    newList: {
      isVisible:
        currentColumn?.type?.includes('custom_analytics') ||
        currentColumn?.type === 'custom_str' ||
        currentColumn.type === 'basic_data_column',
      onClick: createNewListFromColumn,
      startIcon: <NewListIcon />,
      text: 'Create New Company List From Column',
      tooltip: 'Will extract names and websites of companies if the column contains any.',
      menuItemId: 'create-new-list'
    },
    monitor: {
      isVisible:
        featureFlags?.includes('column_monitoring') &&
        currentColumn?.type?.includes('custom_analytics'),
      onClick: toggleColumnMonitor,
      startIcon: <MonitoringIcon color="currentColor" />,
      text: `${currentColumn?.extraParams?.info?.monitor ? 'Stop' : 'Start'} column monitoring`,
      menuItemId: 'monitor-column'
    },
    previousValues: {
      isVisible:
        featureFlags?.includes('column_monitoring') &&
        currentColumn?.type?.includes('custom_analytics'),
      onClick: addLastDataColumn,
      startIcon: <SearchIcon color="currentColor" />,
      text: 'See Previous values',
      menuItemId: 'previous-values'
    },
    exportHistory: {
      isVisible: currentColumn?.type === 'company_source',
      onClick: handleExportHistory,
      startIcon: <HistoryIcon color="currentColor" />,
      text: 'Export history',
      menuItemId: 'export-history'
    }
  };

  const menuGroups = Object.values({
    data: [menuItems.info, menuItems.save],
    actions: [menuItems.rename, menuItems.colorColumn],
    other: [
      menuItems.newList,
      menuItems.exportHistory,
      menuItems.monitor,
      menuItems.previousValues
    ],
    dev: [menuItems.hide, menuItems.deleteMultiple, menuItems.delete]
  })
    .map((groupItems) => groupItems.filter((item) => item.isVisible))
    .filter((groupItems) => !isEmpty(groupItems));

  return (
    <Stack direction="column" pagging="16px">
      <ExtraDataColumnMenuItem
        currentColumn={currentColumn}
        listId={listId}
        hideMenu={args.hideMenu}
        handleClose={handleClose}
      />
      {menuGroups.map((groupItems, index) => (
        <Stack key={index} direction="column">
          {groupItems.map(
            ({
              onMouseEnter,
              onMouseLeave,
              onClick,
              startIcon,
              endIcon,
              text,
              getText,
              tooltip,
              menuItemId
            }) => (
              <MenuItem
                id={menuItemId}
                key={text}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onClick={onClick}>
                <ListItemIcon>{startIcon}</ListItemIcon>
                {tooltip ? (
                  <Tooltip title={tooltip} placement="right">
                    <ListItemText>{text || getText()}</ListItemText>
                  </Tooltip>
                ) : (
                  <ListItemText>{text || getText()}</ListItemText>
                )}
                {endIcon && (
                  <ListItemIcon sx={{ justifyContent: 'flex-end' }}>{endIcon}</ListItemIcon>
                )}
              </MenuItem>
            )
          )}
          {index < menuGroups.length - 1 && MenuItemsDivider}
        </Stack>
      ))}
      <DialogDeleteMultipleColumns
        open={openDeleteMultipleDialog}
        handleDeleteClose={handleDeleteMultipleColumnsClose}
        handleDelete={handleDelete}
        columnName={columnName}
      />
      <RenameColumnDialog
        open={openDialog}
        onClose={handleClose}
        columnName={columnName}
        newName={newName}
        onNameChange={setNewName}
        onRename={handleRename}
      />
      <DeleteColumnDialog
        open={openDeleteDialog}
        onClose={handleDeleteClose}
        columnName={columnName}
        onDelete={handleDelete}
      />
      <QuerySettingsDialog
        open={openSettingsDialog}
        onClose={handleSettingsClose}
        info={info}
        listId={listId}
        columnName={columnName}
        onSaveQuery={onSaveQuery}
        onCopyContent={handleCopyContent}
        canUnify={canUnify}
      />
      <ColorPickerMenu
        listId={listId}
        columnId={columnId}
        anchorEl={colorPickerAnchorEl}
        onClose={() => setColorPickerAnchorEl(null)}
      />
    </Stack>
  );
}

CustomColumnMenu.propTypes = {
  colDef: PropTypes.object,
  onError: PropTypes.func
};

export default CustomColumnMenu;
