import { Box, CircularProgress, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { WORKFLOW_SELECTED_TYPE, WORKFLOW_STATUSES } from 'constants/workflows';
import MLInlineStack from 'design-system/MLInlineStack';
import EditIcon from 'icons/EditIcon';
import HistoryIcon from 'icons/HistoryIcon';
import WarningIcon from 'icons/WarningIcon';
import YesNoIcon from 'icons/YesNoIcon';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getThreadKey } from 'reducer/custom-analytics';
import { updateSelectedWorkflowIdByListId } from 'reducer/workflows-slice';
import { getIsPostWorkflowRunByThreadIdAndPostId } from 'selectors/custom-analytics';
import {
  getWorkflowHistoryByListIdAndWorkflowId,
  getWorkflowStatusByListIdAndWorkflowId
} from 'selectors/workflows';
import { appTheme } from 'theme';
import { formatTimestamp } from 'utils/time-utils';

import WorkflowsCenterDialogRowMenu from './WorkflowsCenterDialogRowMenu';

function WorkflowDialogRow({ listId, workflow, closeWorkflowCenter }) {
  const dispatch = useDispatch();

  const [isHoverStatus, setIsHoverStatus] = useState(false);

  const workflowStatus = useSelector((state) =>
    getWorkflowStatusByListIdAndWorkflowId(state, listId, workflow.postId)
  );
  const query = useSelector((state) =>
    getWorkflowHistoryByListIdAndWorkflowId(state, listId, workflow.postId)
  );
  const isWorkflowRun = useSelector((state) =>
    getIsPostWorkflowRunByThreadIdAndPostId(state, getThreadKey(listId), workflow.postId)
  );

  const onOpenWorkflow = (workflowId) => {
    dispatch(
      updateSelectedWorkflowIdByListId({
        listId,
        selectedWorkflowId: workflowId,
        selectedType: query ? WORKFLOW_SELECTED_TYPE.HISTORY : WORKFLOW_SELECTED_TYPE.DRAFT
      })
    );
  };

  const recordStatus = query?.statuses?.workflow;
  const isStopped = recordStatus === 'STOPPED' || workflowStatus === WORKFLOW_STATUSES.STOPED;

  const isDraft =
    !workflowStatus ||
    workflowStatus === WORKFLOW_STATUSES.GENERATING ||
    workflowStatus === WORKFLOW_STATUSES.GENERATED ||
    workflowStatus === WORKFLOW_STATUSES.STOPING ||
    workflowStatus === WORKFLOW_STATUSES.STOPED;

  const isFailed =
    workflowStatus === WORKFLOW_STATUSES.GENERATE_FAILED ||
    workflowStatus === WORKFLOW_STATUSES.LAUNCH_FAILED ||
    workflowStatus === WORKFLOW_STATUSES.HISTORY_FAILED ||
    workflowStatus === WORKFLOW_STATUSES.RUN_FAILED;

  const isRunning =
    (workflowStatus === WORKFLOW_STATUSES.LAUNCHING && !isStopped) ||
    (isWorkflowRun && workflow?.currentStepId && workflow?.currentStepId !== 'end');

  const isInUsed = !isDraft && !isFailed && !isRunning && !isStopped;

  return (
    <TableRow
      key={workflow.postId}
      onClick={() => onOpenWorkflow(workflow.postId)}
      sx={{ cursor: 'pointer' }}>
      <TableCell>
        <Typography variant="text1_Medium">{workflow.name || 'Workflow'}</Typography>
      </TableCell>
      <TableCell
        onMouseEnter={() => setIsHoverStatus(true)}
        onMouseLeave={() => setIsHoverStatus(false)}
        sx={{ width: '125px' }}>
        {isDraft && (
          <MLInlineStack>
            <EditIcon />
            <Typography variant="text1_Normal">Not Launched</Typography>
          </MLInlineStack>
        )}
        {isFailed && (
          <Tooltip
            open={isHoverStatus}
            placement="top"
            arrow={true}
            title="Review the workflow and try launching it again">
            <Box>
              <MLInlineStack>
                <WarningIcon color={appTheme.palette.colors.negative_red} />
                <Typography variant="text1_Normal">Failed</Typography>
              </MLInlineStack>
            </Box>
          </Tooltip>
        )}
        {isInUsed && (
          <MLInlineStack>
            <YesNoIcon color={appTheme.palette.accent_colors.green} />
            <Typography variant="text1_Normal">Launched</Typography>
          </MLInlineStack>
        )}
        {isStopped && (
          <MLInlineStack>
            <HistoryIcon />
            <Typography variant="text1_Normal">Stopped</Typography>
          </MLInlineStack>
        )}
        {isRunning && (
          <MLInlineStack>
            <CircularProgress
              sx={{
                '& .MuiCircularProgress-circle': {
                  animationDuration: '5s'
                }
              }}
              color="white"
              size={16}
            />
            <Typography variant="text1_Normal">Running</Typography>
          </MLInlineStack>
        )}
      </TableCell>
      <TableCell sx={{ width: '100px' }}>
        {workflow.timeSubmitted ? formatTimestamp(workflow.timeSubmitted) : ''}
      </TableCell>
      <TableCell align="right" sx={{ width: '40px' }}>
        {query && (
          <WorkflowsCenterDialogRowMenu
            query={query}
            listId={listId}
            workflowId={workflow.postId}
            closeWorkflowCenter={closeWorkflowCenter}
          />
        )}
      </TableCell>
    </TableRow>
  );
}

WorkflowDialogRow.propTypes = {
  listId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  workflow: PropTypes.object,
  closeWorkflowCenter: PropTypes.func
};

export default WorkflowDialogRow;
