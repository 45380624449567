import React from 'react';

export default function PaintIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 5H5V9H17V7.5V6.5V5ZM18 9V7.5H18.5C18.7761 7.5 19 7.72386 19 8V9C19 10.3807 17.8807 11.5 16.5 11.5H12C11.1716 11.5 10.5 12.1716 10.5 13V13.563C9.63739 13.785 9 14.5681 9 15.5V19C9 19.5523 9.44772 20 10 20H12C12.5523 20 13 19.5523 13 19V15.5C13 14.5681 12.3626 13.785 11.5 13.563V13C11.5 12.7239 11.7239 12.5 12 12.5H16.5C18.433 12.5 20 10.933 20 9V8C20 7.17157 19.3284 6.5 18.5 6.5H18V5C18 4.44772 17.5523 4 17 4H5C4.44772 4 4 4.44772 4 5V9C4 9.55228 4.44772 10 5 10H17C17.5523 10 18 9.55228 18 9ZM12 15.5V19H10V15.5C10 14.9477 10.4477 14.5 11 14.5C11.5523 14.5 12 14.9477 12 15.5Z"
        fill="#5C5E76"
      />
    </svg>
  );
}
