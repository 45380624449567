import { addSavedQueryAction } from 'actions/saved_queries/add-saved-query-action';
import { updateSavedQueryAction } from 'actions/saved_queries/update-saved-query-action';
import { cloneDeep } from 'lodash';
import { SAVED_QUERY_ACTION_TYPES } from 'utils/platter-utils';

import { copyLibraryWorkflowByQueryAction } from './copy-library-workflow-by-query-action';

export function saveNewWorkflowAction(listId, workflow, newWorkflowName, addToast, onSaveQuery) {
  return (dispatch) => {
    dispatch(
      addSavedQueryAction({
        name: newWorkflowName,
        parameters: { ...workflow, name: newWorkflowName },
        query_type: SAVED_QUERY_ACTION_TYPES.WORKFLOW,
        description: null,
        addToast,
        onSaveQuery: (saveQueryId, savedQuery) => {
          const savedQueryClone = cloneDeep(savedQuery);
          savedQueryClone.parameters.id = saveQueryId.toString();
          dispatch(updateSavedQueryAction(saveQueryId, savedQueryClone.parameters, addToast));
          onSaveQuery?.(savedQueryClone);
          dispatch(
            copyLibraryWorkflowByQueryAction(listId, {
              ...savedQueryClone,
              owner: savedQueryClone.user,
              shouldAddToState: true
            })
          );
        }
      })
    );
  };
}
