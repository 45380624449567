import { TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { appTheme } from 'theme';

function WorkflowTextComponent({ text, isEditMode, onValueChange }) {
  return isEditMode ? (
    <TextField
      multiline
      value={text}
      onChange={onValueChange}
      placeholder="What this step should do?"
      maxRows={5}
      slotProps={{ input: { sx: { padding: '4px' } } }}
      sx={{
        '.MuiInputBase-input': {
          ...appTheme.typography.text1_Normal
        }
      }}
    />
  ) : (
    <Typography
      variant="text1_Normal"
      color="colors.primary_text"
      whiteSpace="pre-wrap"
      sx={{
        padding: '4px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 5,
        WebkitBoxOrient: 'vertical'
      }}>
      {text}
    </Typography>
  );
}

WorkflowTextComponent.propTypes = {
  text: PropTypes.string,
  isEditMode: PropTypes.bool,
  onValueChange: PropTypes.func
};

export default WorkflowTextComponent;
