import { Box, Stack, Typography } from '@mui/material';
import CloseIcon from 'design-system/icons/platform/CloseIcon';
import PropTypes from 'prop-types';

function SelectedChip({ value, label, handleCloseClick }) {
  return (
    <Stack
      key={value}
      direction="row"
      gap="8px"
      alignItems="center"
      sx={{
        border: '1px solid',
        borderColor: 'colors.hover_on_selected',
        padding: '2px 6px 2px 6px',
        borderRadius: '4px'
      }}>
      <Typography variant="text1_Medium" color="colors.primary_text">
        {label}
      </Typography>
      {handleCloseClick && (
        <Box
          sx={{
            height: '24px',
            width: '24px',
            borderRadius: '2px',
            padding: 0,
            margin: 0,
            '&:hover': { backgroundColor: 'colors.hover_on_primary_bg' }
          }}
          onMouseDown={handleCloseClick}>
          <CloseIcon />
        </Box>
      )}
    </Stack>
  );
}

SelectedChip.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  handleCloseClick: PropTypes.func
};

export default SelectedChip;
