import React from 'react';
import { AnswerType } from 'utils/custom-analytics-utils';

import { ActionType } from './assistant-actions';

export enum SuggestionIdType {
  COMPANY = 'company_meta_id',
  COLUMN = 'column_id',
  SUPPORTING_RESOURCE = 'supporting_resource_id'
}

export enum MessageRole {
  ASSISTANT = 'assistant',
  USER = 'user'
}

export interface ActionTypeDisplay {
  actionType: ActionType;
  label: string;
  icon: React.ComponentType;
  color?: string;
  bgColor?: string;
  nestedActions?: ActionTypeDisplay[];
}

interface MessageContent {
  text: string;
}

interface AssistantMessageContent extends MessageContent {
  raw_content: string | null;
}

export type Scoping = Record<string, string[]>;

export type SearchSettings = {
  use_web: boolean;
  use_table: boolean;
};

export interface UserMessageContent extends MessageContent {
  saved_query_id: number | null;
  action_type: ActionType | null;
  search_settings: SearchSettings | null;
  answer_type: AnswerType | null;
  scoping: Scoping | null;
}

interface MessageBase {
  id: string;
  actionId?: string | null;
  is_dry?: boolean;
}

interface UserMessage extends MessageBase {
  role: MessageRole.USER;
  content: UserMessageContent;
}

interface AssistantMessage extends MessageBase {
  role: MessageRole.ASSISTANT;
  content: AssistantMessageContent;
}

export type Message = UserMessage | AssistantMessage;

export interface SuggestionWithoutName {
  suggestionIdType: SuggestionIdType;
  id: string;
}

export interface Suggestion extends SuggestionWithoutName {
  name: string;
}

export type MentionId = `${SuggestionIdType}:${string}`;

export type MentionItem = {
  id: MentionId;
  label: string;
};

interface MentionableResource {
  name: string;
}

export interface Company extends MentionableResource {
  id: number;
  companyMetaId: number;
}

export interface Column extends MentionableResource {
  id: string;
}

export interface SupportingResource extends MentionableResource {
  id: number;
  type: string;
}
