import { Avatar, Box, Stack } from '@mui/material';
import UserAvatar from 'components/UserAvatar';
import MLLoadingDotsAnimation from 'design-system/MLLoadingDotsAnimation';
import MathlabsAvatar from 'design-system/icons/platform/MathlabsAvatar';
import { ActionType } from 'models/assistant/assistant-actions';
import { Message, MessageRole } from 'models/assistant/types';
import React, { useRef } from 'react';
import { getAssistantThreadById, getThreadActionById } from 'selectors/assistant';
import { useAppSelectorWithArgs } from 'types/store';

import ActionMessageComponent from './ActionMessageComponent';
import AssistantRenderMessage from './AssistantRenderMessage';
import AssistantActionPostDisplay from './answer-posts-display/AssistantActionPostDisplay';

interface BasicMessageComponentProps {
  threadId: string;
  message: Message;
  text?: string;
  isLoading?: boolean;
}

export const messageRoleToAvatar = (role: MessageRole): React.ReactNode => {
  switch (role) {
    case MessageRole.USER:
      return <UserAvatar width={32} height={32} />;
    case MessageRole.ASSISTANT:
      return <MathlabsAvatar />;
    default:
      return <Avatar />;
  }
};

function BasicMessageComponent({ message, text }: BasicMessageComponentProps) {
  const messageText = text || message.content.text;
  const messageRef = useRef<HTMLDivElement>(null);
  if (!messageText) return null;
  const multiline = messageRef.current && messageRef.current.getBoundingClientRect().height > 50;

  return (
    <Stack direction="row" gap="8px" alignItems={multiline ? 'flex-start' : 'center'}>
      <Box sx={{ marginTop: multiline ? '5px' : 0 }}>{messageRoleToAvatar(message.role)}</Box>
      <Stack ref={messageRef} width="100%">
        <AssistantRenderMessage
          text={messageText}
          role={message.role}
          hasMinWidth={Boolean(message.actionId)}
        />
      </Stack>
    </Stack>
  );
}

export function AssistantLoadingMessageComponent() {
  return (
    <Stack direction="row" gap="8px">
      {messageRoleToAvatar(MessageRole.ASSISTANT)}
      <Stack height="100%" justifyContent="center" pl="16px">
        <MLLoadingDotsAnimation />
      </Stack>
    </Stack>
  );
}

export default function MessageComponent({ threadId, message }: BasicMessageComponentProps) {
  const action = useAppSelectorWithArgs(getThreadActionById, threadId, message.actionId);
  const thread = useAppSelectorWithArgs(getAssistantThreadById, threadId);

  const listId = thread.company_list_id;
  const isUserMessagePreview =
    message.role === MessageRole.USER && message.content.action_type === ActionType.PREVIEW_COLUMN;
  const messageText = isUserMessagePreview
    ? 'Give me a preview of the results'
    : message.content.text;
  return (
    <Stack gap="16px">
      {message.actionId && listId ? (
        <ActionMessageComponent
          threadId={threadId}
          actionId={message.actionId}
          role={message.role}
          listId={listId}
        />
      ) : (
        <BasicMessageComponent threadId={threadId} message={message} text={messageText} />
      )}

      {listId && action && (
        <AssistantActionPostDisplay
          listId={listId}
          action={action}
          role={message.role}
          threadId={threadId}
        />
      )}
    </Stack>
  );
}
