import { Divider, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { countryEmojiFromCountry } from 'utils/location-utils';

import CountryMap from './CountryMap';
import QuickViewBox from './QuickViewBox';
import QuickViewFieldValue from './QuickViewFieldValue';

function QuickViewLocation({ row, countryData }) {
  const { region, country, hq } = row;
  const countryEmoji = countryEmojiFromCountry(country);
  return (
    <QuickViewBox title="Company Location">
      <Stack direction="row" width="100%" height="330px" gap="24px">
        <Stack width="256px" gap="24px">
          <QuickViewFieldValue direction="row" field="Region" value={region} />
          <QuickViewFieldValue
            direction="row"
            field="Country"
            value={countryEmoji ? `${countryEmoji} ${country}` : country}
          />
          <QuickViewFieldValue direction="row" field="HQ" value={hq} />
        </Stack>
        <Divider orientation="vertical" flexItem />
        <Stack width="100%" gap="0px">
          <Typography variant="text1_Bold">Location on map relative to list</Typography>
          <CountryMap country={country} countryData={countryData} />
        </Stack>
      </Stack>
    </QuickViewBox>
  );
}

QuickViewLocation.propTypes = {
  row: PropTypes.object,
  countryData: PropTypes.array
};

export default QuickViewLocation;
