import { Box, FormControl, MenuItem, Select, Stack, Typography } from '@mui/material';
import ArrowIcon from 'icons/ArrowIcon';
import PropTypes from 'prop-types';
import React from 'react';

import { ANSWER_TYPES } from './discovery/PromptInputComponent';

function SelectWithAnswerTypeComponent({
  currentAnswerType,
  setAnswerType,
  selectStyle,
  showTitle,
  inputProps,
  renderValueStyle,
  showAsButton
}) {
  const handleChange = (event) => {
    setAnswerType(event.target.value);
  };

  return (
    <FormControl>
      <Select
        labelId="column-select-label"
        id="column-select"
        variant={showAsButton ? 'standard' : 'outlined'}
        disableUnderline={showAsButton}
        value={currentAnswerType}
        onChange={handleChange}
        onKeyDownCapture={(event) => event.stopPropagation()}
        IconComponent={() => {
          if (showAsButton) return null;
          return (
            <Box
              sx={{
                position: 'absolute',
                cursor: 'pointer',
                pointerEvents: 'none',
                right: 14,
                top: 5,
                height: '15px',
                width: '15px'
              }}>
              <ArrowIcon rotation="180" />
            </Box>
          );
        }}
        renderValue={(value) => (
          <Stack
            direction="row"
            alignItems="center"
            gap="8px"
            sx={{
              color: 'colors.secondary_text',
              paddingLeft: '8px',
              paddingTop: '4px',
              paddingBottom: '4px',
              ...renderValueStyle
            }}>
            {ANSWER_TYPES[value]?.icon}
            {showTitle && (
              <Typography variant="text1_Medium" color="colors.primary_text">
                {ANSWER_TYPES[value].label}
              </Typography>
            )}
          </Stack>
        )}
        sx={{ ...selectStyle, '.MuiSelect-select': { padding: 0 } }}
        inputProps={inputProps}>
        {Object.keys(ANSWER_TYPES).map((answerType) => (
          <MenuItem key={answerType} value={answerType}>
            <Stack gap="8px" direction="row" alignItems="center">
              {ANSWER_TYPES[answerType] && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '20px',
                    height: '20px'
                  }}>
                  {ANSWER_TYPES[answerType].icon}
                </Box>
              )}
              <Typography variant="text1_Normal" color="colors.primary_text">
                {ANSWER_TYPES[answerType].label}
              </Typography>
            </Stack>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

SelectWithAnswerTypeComponent.propTypes = {
  currentAnswerType: PropTypes.string,
  setAnswerType: PropTypes.func,
  selectStyle: PropTypes.object,
  inputProps: PropTypes.object,
  renderValueStyle: PropTypes.object,
  showTitle: PropTypes.bool,
  showAsButton: PropTypes.bool
};

SelectWithAnswerTypeComponent.defaultProps = {
  showTitle: false,
  inputProps: {},
  renderValueStyle: {},
  showAsButton: false
};

export default SelectWithAnswerTypeComponent;
