import { Stack, Tooltip, Typography, TypographyProps } from '@mui/material';
import CustomMarkdownCell from 'components/CustomMarkdownCell';
import MLInlineStack from 'design-system/MLInlineStack';
import { useMLTooltip } from 'hooks/useMLTooltip';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { parseNewLines } from 'utils/string-utils';

// Import TypographyVariants
import { getCustomScrollbarStyles } from './styles/customScrollBar';

type Props = {
  text: string;
  tooltip?: string | ReactElement | undefined;
  variant?: TypographyProps['variant'];
};

export default function MLResizableCustomTextComponent({
  text,
  tooltip,
  variant = 'text1_Normal'
}: Props) {
  const { isTooltipVisible: isOnHOver, showTooltip, hideTooltip } = useMLTooltip();
  const stackRef = React.useRef<HTMLDivElement>(null);
  const textRef = React.useRef<HTMLDivElement>(null);

  const [originalWidth, setOriginalWidth] = useState<number | null>(null);
  const [finalWidth, setFinalWidth] = useState<number | string>('100%');

  useEffect(() => {
    if (textRef.current) {
      setOriginalWidth(textRef.current.scrollWidth);
    }
  }, []);

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      if (!stackRef.current) return;
      setFinalWidth(stackRef.current?.clientWidth);
    });
    if (!stackRef.current) return;
    observer.observe(stackRef.current);
    return () => {
      observer.disconnect();
    };
  }, []);

  const needsTooltip = useMemo(() => {
    return originalWidth && typeof finalWidth === 'number' ? originalWidth > finalWidth : false;
  }, [originalWidth, finalWidth]);

  const textLines = useMemo(() => {
    return parseNewLines(text).split('\n');
  }, [text]);

  const markdownOverride = {
    ul: {
      component: Typography,
      props: {
        display: 'inline',
        paddingLeft: 0,
        margin: 0
      }
    },
    li: {
      component: Typography,
      props: {
        display: 'inline',
        marginRight: '8px'
      }
    }
  };

  return (
    <MLInlineStack
      height="100%"
      flexGrow={1}
      minWidth={0}
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}>
      <Tooltip
        slotProps={{ tooltip: { sx: { padding: '4px', maxWidth: '350px', maxHeight: '300px' } } }}
        title={
          <Stack
            sx={{
              padding: '4px',
              overflowY: 'auto',
              maxWidth: '350px',
              maxHeight: '300px',
              ...getCustomScrollbarStyles({ size: 'small' })
            }}>
            {tooltip || text}
          </Stack>
        }
        arrow
        open={isOnHOver && needsTooltip}>
        <Stack ref={stackRef} height="100%" direction="row" alignItems="center" width={'100%'}>
          <Typography ref={textRef} display="inline-block" noWrap width={finalWidth}>
            {textLines.map((text: any, index: number) => (
              <Typography
                key={index}
                paddingRight={index === textLines.length - 1 ? 0 : '2px'}
                variant={variant}>
                <CustomMarkdownCell key={index} body={text} overridesExtra={markdownOverride} />
              </Typography>
            ))}
          </Typography>
        </Stack>
      </Tooltip>
    </MLInlineStack>
  );
}
