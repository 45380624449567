import {
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import MLButton from 'design-system/MLButton';
import MLChip from 'design-system/MLChip';
import MLIcon from 'design-system/MLIcon';
import MLIconMenuButton from 'design-system/MLIconMenuButton';
import MLInlineStack from 'design-system/MLInlineStack';
import useMenuManagement from 'design-system/hooks/useMenuManagement';
import InfoIcon from 'design-system/icons/basic/InfoIcon';
import PDFIcon from 'design-system/icons/platform/PDFIcon';
import PPTXIcon from 'design-system/icons/platform/PPTXIcon';
import { useScrollPositionFilterByDirection } from 'hooks/useScrollPositionFilterByDirection';
import EditIcon from 'icons/EditIcon';
import ExportIcon from 'icons/ExportIcon';
import UpIcon from 'icons/UpIcon';
import React, { MouseEventHandler } from 'react';
import { useSelector } from 'react-redux';
import { DISCOVERY_SCROLL_ELEMENT_ID } from 'screens/DiscoveryRunBaseScreen';
import { getLatestDRProgressByListId } from 'selectors/domain-reports';
import { getCompanyListNameByListId } from 'selectors/explore';

import DomainReportButton from './DomainReportButton';
import { useDomainReportContext } from './DomainReportContext';

const determineSubtitle = (status: string, time_submitted: string) => {
  if (status === 'FAILED') {
    return 'Failed to create report';
  }
  if (status === 'SUCCEEDED') {
    return `Created on: ${new Date(time_submitted).toLocaleDateString()}`;
  }
  return 'Updating report...';
};

interface DomainReportHeaderProps {
  listId: number;
  toPDF: () => void;
  exporting: boolean;
  openEdit: () => void;
  toPowerPoint: () => void;
}

const itemSx = {
  minHeight: '32px',
  minWidth: '32px',
  borderRadius: '4px',
  padding: '8px',
  color: 'colors.primary_text'
};

function DomainReportHeader({
  listId,
  toPDF,
  exporting,
  openEdit,
  toPowerPoint
}: DomainReportHeaderProps) {
  const { domainReport, status, outdated } = useDomainReportContext();
  const name = useSelector((state) => getCompanyListNameByListId(state, listId));
  const progress = useSelector((state) => getLatestDRProgressByListId(state, listId));
  const menuManagement = useMenuManagement<HTMLButtonElement>();

  const scrollPosition = useScrollPositionFilterByDirection(
    'DomainReportHeader',
    DISCOVERY_SCROLL_ELEMENT_ID,
    false
  );

  const { time_submitted } = domainReport || {};
  const subtitle = determineSubtitle(status, time_submitted);

  const theme = useTheme();
  const bgColor = theme.palette.colors.hover_on_gray_bg;
  const bgColorTransparent = `${bgColor}B8`;
  const minify = scrollPosition > 24;
  const goToTop: MouseEventHandler<HTMLDivElement> = () => {
    document.getElementById(DISCOVERY_SCROLL_ELEMENT_ID)?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Stack
      gap="8px"
      {...(minify ? { onClick: goToTop } : {})}
      sx={{
        position: 'sticky',
        top: '61px',
        padding: minify ? '16px 8px' : null,
        backdropFilter: 'blur(6px)',
        cursor: minify ? 'pointer' : null,
        backgroundColor: bgColorTransparent,
        backgroundOp: 0.7,
        maxHeight: minify ? '49px' : null,
        zIndex: 'domainReportHeader'
      }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ marginTop: minify ? '0px' : '8px' }}>
        <Typography variant={minify ? 'text1_Bold' : 'h2_Bold'} color="colors.primary_text">
          Domain Report for {name}
        </Typography>
        {minify ? (
          <UpIcon />
        ) : (
          <MLInlineStack>
            <MLButton startIcon={<EditIcon />} onClick={openEdit}>
              <Typography>Edit</Typography>
            </MLButton>
            <MLIconMenuButton
              menuManagement={menuManagement}
              label="Export"
              topToBottom
              menuContent={
                <Stack gap="4px">
                  <MenuItem sx={itemSx} onClick={toPDF} disabled={exporting}>
                    <ListItemIcon>
                      <MLIcon icon={<PDFIcon />} size={'small'} />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography>PDF</Typography>
                    </ListItemText>
                  </MenuItem>
                  <MenuItem sx={itemSx} onClick={toPowerPoint} disabled={exporting}>
                    <ListItemIcon>
                      <MLIcon icon={<PPTXIcon />} size={'small'} />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography>PPTX</Typography>
                    </ListItemText>
                  </MenuItem>
                </Stack>
              }>
              <ExportIcon />
            </MLIconMenuButton>
          </MLInlineStack>
        )}
      </Stack>
      {!minify && (
        <Stack direction="row" alignItems="center" gap="8px">
          <Typography variant="text1_Normal" color="colors.secondary_text">
            {subtitle}
          </Typography>
          {status === 'RUNNING' && progress && progress < 1 ? (
            <React.Fragment>
              <Box
                sx={{
                  marginLeft: '4px',
                  borderRadius: '9px',
                  width: '280px',
                  height: '6px',
                  backgroundColor: 'colors.selected'
                }}>
                <Box
                  sx={{
                    borderRadius: '9px',
                    width: `${progress * 280}px`,
                    height: '6px',
                    backgroundColor: 'graphColors.graph13'
                  }}
                />
              </Box>
              <Typography variant="text1_Normal" color="colors.secondary_text">
                {Math.round(progress * 100)}%
              </Typography>
            </React.Fragment>
          ) : null}
          {status !== 'RUNNING' && (
            <React.Fragment>
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  backgroundColor: 'colors.icon',
                  marginRight: '8px',
                  marginLeft: '8px',
                  height: '18px',
                  alignSelf: 'center'
                }}
              />
              <MLInlineStack>
                {outdated && (
                  <MLChip
                    rightIcon={<InfoIcon />}
                    variant="negative"
                    tooltipText="This report is outdated because the company list has changed. Update the report to show the latest data."
                    size="small"
                    sx={{ border: '1px solid', borderColor: 'colors.ui_border', height: '18px' }}
                    label="Outdated"
                  />
                )}
                <DomainReportButton listId={listId} status={status} />
              </MLInlineStack>
            </React.Fragment>
          )}
        </Stack>
      )}
    </Stack>
  );
}

export default DomainReportHeader;
