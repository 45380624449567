import React from 'react';

export default function SearchEverywhereIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.48816 4.48816C4.80072 4.17559 5.22464 4 5.66667 4H13.8333C14.2754 4 14.6993 4.17559 15.0118 4.48816C15.3244 4.80072 15.5 5.22464 15.5 5.66667V8.5C15.5 8.77614 15.2761 9 15 9C14.7239 9 14.5 8.77614 14.5 8.5V8H8V14.5H8.5C8.77614 14.5 9 14.7239 9 15C9 15.2761 8.77614 15.5 8.5 15.5H5.66667C5.22464 15.5 4.80072 15.3244 4.48816 15.0118C4.17559 14.6993 4 14.2754 4 13.8333V5.66667C4 5.22464 4.17559 4.80072 4.48816 4.48816ZM7 14.5V8H5V13.8333C5 14.0101 5.07024 14.1797 5.19526 14.3047C5.32029 14.4298 5.48986 14.5 5.66667 14.5H7ZM5 7H7V5H5.66667C5.48986 5 5.32029 5.07024 5.19526 5.19526C5.07024 5.32029 5 5.48986 5 5.66667V7ZM8 5V7H14.5V5.66667C14.5 5.48986 14.4298 5.32029 14.3047 5.19526C14.1797 5.07024 14.0101 5 13.8333 5H8Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.4969 14.5032C9.49315 14.5281 9.49121 14.5536 9.49121 14.5796C9.49121 14.5931 9.49174 14.6066 9.49279 14.6199C9.49174 14.6632 9.49121 14.7065 9.49121 14.75C9.49121 17.6545 11.8457 20.009 14.7502 20.009L14.776 20.0089L14.7839 20.009L14.799 20.0088C17.6809 19.9825 20.0091 17.6382 20.0091 14.75C20.0091 14.7065 20.0086 14.6632 20.0075 14.6199C20.0086 14.6066 20.0091 14.5931 20.0091 14.5796C20.0091 14.5536 20.0072 14.5281 20.0034 14.5032C19.8753 11.7295 17.5984 9.51677 14.799 9.49131L14.7839 9.49109L14.776 9.49115L14.7502 9.49109C11.9285 9.49109 9.62581 11.7134 9.4969 14.5032ZM18.9371 14.0706C18.6761 12.4498 17.4966 11.1363 15.9496 10.681C16.6074 11.6991 17.0199 12.8598 17.15 14.0706H18.9371ZM16.1253 14.0706C15.9777 12.8788 15.5149 11.7467 14.7839 10.7923C14.053 11.7467 13.5902 12.8788 13.4426 14.0706H16.1253ZM13.4091 15.0885H16.1588C16.0632 16.4047 15.5843 17.6627 14.7839 18.7078C13.9836 17.6627 13.5047 16.4047 13.4091 15.0885ZM12.3889 15.0885C12.4736 16.4313 12.9049 17.7238 13.6335 18.8425C11.9379 18.381 10.665 16.8937 10.5224 15.0885H12.3889ZM12.4179 14.0706H10.5632C10.8289 12.421 12.0459 11.0897 13.6335 10.6575C12.9668 11.6812 12.549 12.8505 12.4179 14.0706ZM17.179 15.0885H18.9779C18.8377 16.8642 17.6037 18.3323 15.9496 18.8191C16.669 17.7058 17.0949 16.4219 17.179 15.0885Z"
        fill="currentColor"
      />
    </svg>
  );
}
