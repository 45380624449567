import { createSlice } from '@reduxjs/toolkit';

const initialSliceState = {
  threads: {},
  deepDiveThreads: {},
  clipboard: {},
  dataTypeToPostIdMap: {}
};

export const getThreadKey = (listId, companyMetaId) => {
  const id = listId || companyMetaId;
  const prefix = listId ? 'list' : 'company';
  return `${prefix}_${id}`;
};

/* eslint-disable max-lines */
export const customAnalyticsSlice = createSlice({
  name: 'custom-analytics',
  initialState: initialSliceState,
  reducers: {
    updateInitializeEnded: (sliceState, { payload }) => {
      const { listId } = payload;
      const id = getThreadKey(listId);
      if (!sliceState.threads[id]) {
        sliceState.threads[id] = {};
      }

      sliceState.threads[id].isReady = true;
    },
    addPostQueryToThread: (sliceState, { payload }) => {
      const {
        threadKey,
        postId,
        query,
        body,
        discoveryId,
        messages,
        plan,
        isWorkflow,
        currentStepId,
        name
      } = payload;
      if (!sliceState.threads[threadKey]) {
        sliceState.threads[threadKey] = {};
      }

      if (!sliceState.threads[threadKey].posts) {
        sliceState.threads[threadKey].posts = {};
      }

      sliceState.threads[threadKey].posts[postId] = {
        postId,
        query,
        body,
        discoveryId,
        messages,
        plan,
        isWorkflow,
        currentStepId,
        name
      };
    },
    addPostAnswerToThread: (sliceState, { payload }) => {
      const {
        threadKey,
        threadId,
        postId,
        message,
        responseType,
        messages,
        image,
        plan,
        isWorkflow,
        currentStepId
      } = payload;
      if (!sliceState.threads[threadKey]) {
        sliceState.threads[threadKey] = { posts: {} };
      }
      sliceState.threads[threadKey].threadId = threadId;
      const post = sliceState.threads[threadKey]?.posts?.[postId];
      if (post) {
        if (post.messages) {
          const companyListIdToIndex = post.messages.reduce((prev, oldMessage, index) => {
            prev[oldMessage.company_meta_id] = index;
            return prev;
          }, {});
          messages?.forEach((item) => {
            const index = companyListIdToIndex[item.company_meta_id];
            if ((index || index === 0) && item.message) {
              post.messages[index].message = item.message;
              post.messages[index].loading = false;
            }
          });
        } else {
          post.messages = messages;
        }
        sliceState.threads[threadKey].posts[postId] = {
          ...post,
          message,
          responseType,
          image,
          plan,
          isWorkflow,
          currentStepId: currentStepId || post.currentStepId
        };
      }
    },
    addPostStatusToThread: (sliceState, { payload }) => {
      const { threadKey, postId, status, statusMessage, currentStepId } = payload;

      if (sliceState.threads[threadKey]?.posts?.[postId]) {
        if (status) {
          const newVal = parseFloat(status.replace('%', ''));
          const oldValStr = sliceState.threads[threadKey].posts[postId].status;
          const oldVal = oldValStr ? parseFloat(oldValStr.replace('%', '')) : 0;
          if (newVal > oldVal) {
            sliceState.threads[threadKey].posts[postId].status = status;
            if (statusMessage) {
              sliceState.threads[threadKey].posts[postId].statusMessage = statusMessage;
            }
          }
        } else if (statusMessage) {
          sliceState.threads[threadKey].posts[postId].statusMessage = statusMessage;
        }
        if (currentStepId) {
          sliceState.threads[threadKey].posts[postId].currentStepId = currentStepId;
        }
      }
    },
    addPostRecordIdToThread: (sliceState, { payload }) => {
      const { threadKey, postId, customAnalyticsRecordId } = payload;

      if (sliceState.threads[threadKey]?.posts?.[postId]) {
        sliceState.threads[threadKey].posts[postId].recordId = customAnalyticsRecordId;
      }
    },

    addPostRecordIdToRecords: (sliceState, { payload }) => {
      const { threadKey, postId, customAnalyticsRecordId } = payload;

      if (sliceState.threads[threadKey]?.posts?.[postId]) {
        sliceState.threads[threadKey].posts[postId].records = {
          ...(sliceState.threads[threadKey].posts[postId].records || {}),
          [customAnalyticsRecordId]: {
            recordId: customAnalyticsRecordId,
            isRunning: true
          }
        };
      }
    },

    updatePostRecordIsRunning: (sliceState, { payload }) => {
      const { threadKey, postId, customAnalyticsRecordId, isRunning } = payload;

      if (sliceState.threads[threadKey]?.posts?.[postId]?.records?.[customAnalyticsRecordId]) {
        sliceState.threads[threadKey].posts[postId].records[customAnalyticsRecordId].isRunning =
          isRunning;
      }
    },
    addDiscoveryPostSuggestionsToThread: (sliceState, { payload }) => {
      const { threadKey, postId, suggestions } = payload;

      if (sliceState.threads[threadKey]?.posts?.[postId]) {
        if (suggestions) {
          sliceState.threads[threadKey].posts[postId].suggestions = suggestions;
        }
      }
    },
    updatePostMessagesInThread: (sliceState, { payload }) => {
      const { threadKey, postId, messages } = payload;

      if (sliceState.threads[threadKey]?.posts?.[postId]) {
        if (sliceState.threads[threadKey]?.posts?.[postId]?.messages) {
          const companyListIdToIndex = sliceState.threads[threadKey].posts[postId].messages.reduce(
            (prev, message, index) => {
              prev[message.company_meta_id] = index;
              return prev;
            },
            {}
          );
          messages.forEach((item) => {
            const index = companyListIdToIndex[item.company_meta_id];
            if (index && item.message) {
              sliceState.threads[threadKey].posts[postId].messages[index].message = item.message;
              sliceState.threads[threadKey].posts[postId].messages[index].loading = false;
            }
          });
        } else {
          sliceState.threads[threadKey].posts[postId].messages = messages;
        }
      }
    },
    updateThreadProccessingMode: (sliceState, { payload }) => {
      const { threadKey, isProccessing } = payload;

      if (sliceState.threads[threadKey]) {
        sliceState.threads[threadKey].isProccessing = isProccessing;
      }
    },
    updatePromptClipboard: (sliceState, { payload }) => {
      const { threadKey, promptConfig } = payload;
      sliceState.clipboard[threadKey] = promptConfig;
    },
    updateCustomAnalyticsOpen: (sliceState, { payload }) => {
      const { customAnalyticsOpen } = payload;
      sliceState.customAnalyticsOpen = customAnalyticsOpen;
    },
    clearPromptClipboard: (sliceState, { payload }) => {
      const { threadKey } = payload;

      delete sliceState.clipboard[threadKey];
    },
    updatePostIdOfDataType: (sliceState, { payload }) => {
      const { postId, dataType } = payload;

      sliceState.dataTypeToPostIdMap[dataType] = postId;
    },
    updatePostSavedQueryId: (sliceState, { payload }) => {
      const { threadKey, postId, savedQueryId } = payload;

      if (sliceState.threads[threadKey]?.posts?.[postId]?.body) {
        sliceState.threads[threadKey].posts[postId].body.saved_query_id = savedQueryId;
      }
    },
    updatePlan: (sliceState, { payload }) => {
      const { threadKey, postId, newPlan } = payload;
      if (sliceState.threads[threadKey]?.posts[postId]?.plan) {
        sliceState.threads[threadKey].posts[postId].plan = newPlan;
      }
      if (sliceState.threads[threadKey]?.posts[postId]?.body?.plan) {
        sliceState.threads[threadKey].posts[postId].body.plan = newPlan;
      }
    },
    updatePlanMainPrompt: (sliceState, { payload }) => {
      const { threadKey, postId, mainPrompt } = payload;
      if (sliceState.threads[threadKey]?.posts[postId]?.plan) {
        sliceState.threads[threadKey].posts[postId].plan.question = mainPrompt;
      }
      if (sliceState.threads[threadKey]?.posts[postId]?.body?.plan) {
        sliceState.threads[threadKey].posts[postId].body.plan.question = mainPrompt;
      }
    },
    updateQueryInPlan: (sliceState, { payload }) => {
      const {
        threadKey,
        postId,
        stepId,
        queryIndex,
        query,
        searchTheWeb,
        searchInTable,
        columnIds,
        domainKnowledgeIds,
        answerType,
        dependsOn,
        condition
      } = payload;
      if (sliceState.threads[threadKey]?.posts[postId]?.plan?.steps) {
        const step = sliceState.threads[threadKey]?.posts[postId]?.plan?.steps.find(
          (stepItem) => stepItem.step_id === stepId
        );
        if (step?.function_use?.action_variables?.queries?.[queryIndex]) {
          if (typeof step.function_use.action_variables.queries[queryIndex].query === 'object') {
            if (typeof query === 'string') {
              step.function_use.action_variables.queries[queryIndex].query.user_question = query;
            }
            if (typeof searchTheWeb === 'boolean') {
              step.function_use.action_variables.queries[queryIndex].query.search_the_web =
                searchTheWeb;
            }
            if (typeof searchInTable === 'boolean') {
              step.function_use.action_variables.queries[queryIndex].query.is_table_question =
                searchInTable;
            }
            if (Array.isArray(columnIds)) {
              step.function_use.action_variables.queries[queryIndex].query.column_ids = columnIds;
            }
            if (Array.isArray(domainKnowledgeIds)) {
              step.function_use.action_variables.queries[queryIndex].query.domain_knowledge_ids =
                domainKnowledgeIds;
            }
            if (typeof answerType === 'string') {
              step.function_use.action_variables.queries[queryIndex].query.answer_type = answerType;
            }
            if (Array.isArray(dependsOn)) {
              step.function_use.action_variables.queries[queryIndex].depends_on = dependsOn;
            }
            if (typeof condition === 'string') {
              step.function_use.action_variables.queries[queryIndex].query.condition = condition;
            }
          } else {
            step.function_use.action_variables.queries[queryIndex].query = query;
          }
        }
      }
    },
    renamePost: (sliceState, { payload }) => {
      const { threadKey, postId, newName } = payload;
      if (sliceState.threads[threadKey]?.posts[postId]) {
        sliceState.threads[threadKey].posts[postId].name = newName;
      }
    },
    deletePost: (sliceState, { payload }) => {
      const { threadKey, postId } = payload;
      if (sliceState.threads[threadKey]?.posts[postId]) {
        delete sliceState.threads[threadKey].posts[postId];
      }
    },
    updatePostIsHidden: (sliceState, { payload }) => {
      const { threadKey, postId, hidePost } = payload;

      if (sliceState.threads[threadKey]?.posts?.[postId]?.body) {
        sliceState.threads[threadKey].posts[postId].body.hidePost = hidePost;
      }
    }
  }
});

export const {
  addPostQueryToThread,
  addPostAnswerToThread,
  addPostStatusToThread,
  updateInitializeEnded,
  updatePostMessagesInThread,
  updateThreadProccessingMode,
  updatePromptClipboard,
  clearPromptClipboard,
  updateCustomAnalyticsOpen,
  addPostRecordIdToThread,
  addPostRecordIdToRecords,
  updatePostRecordIsRunning,
  addDiscoveryPostSuggestionsToThread,
  updatePostIdOfDataType,
  updatePostSavedQueryId,
  updatePlan,
  updatePlanMainPrompt,
  updateQueryInPlan,
  renamePost,
  deletePost,
  updatePostIsHidden
} = customAnalyticsSlice.actions;

export default customAnalyticsSlice.reducer;
