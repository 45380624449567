import { WORK_FLOW_STEP_TYPES } from 'constants/workflows';
import cloneDeep from 'lodash/cloneDeep';
import { v4 as uuidv4 } from 'uuid';

import { isStringIsNumber } from './string-utils';

function getAttributeUniqueId() {
  return new Date().getTime();
}

export function getWorkflowStepAndQueryIndexesByStepId(stepId, steps) {
  const stepParts = stepId.split('-');
  const stepIndex = steps.findIndex((step) => step.step_id === stepParts[0]);
  const queryIndex =
    stepParts.length > 1 && isStringIsNumber(stepParts[1]) ? parseInt(stepParts[1], 10) : 0;
  return { stepIndex, queryIndex };
}

export function getWorkflowStepQueryBystepType(stepType, stepNumber, stepData) {
  const customAnalyticsQuery = {
    user_question: '',
    column_ids: [],
    domain_knowledge_ids: [],
    search_the_web: true,
    is_table_question: true,
    answer_type: 'text'
  };
  const query = {};
  switch (stepType) {
    case WORK_FLOW_STEP_TYPES.information.id:
      query.attribute_name = uuidv4();
      query.attribute_title = '';
      query.attribute_default_title = `${
        WORK_FLOW_STEP_TYPES[stepType].label
      }-step-${stepNumber}-${getAttributeUniqueId()}`;
      query.query = customAnalyticsQuery;
      query.depends_on = [];
      break;
    case WORK_FLOW_STEP_TYPES.discovery.id:
      query.query = '';
      break;
    case WORK_FLOW_STEP_TYPES.analysis.id:
      query.analysis_name = uuidv4();
      query.analysis_title = '';
      query.analysis_default_title = `${
        WORK_FLOW_STEP_TYPES[stepType].label
      }-step-${stepNumber}-${getAttributeUniqueId()}`;
      query.query = customAnalyticsQuery;
      query.depends_on = [];
      break;
    case WORK_FLOW_STEP_TYPES.filter.id:
      query.analysis_name = uuidv4();
      query.analysis_title = '';
      query.analysis_default_title = `${
        WORK_FLOW_STEP_TYPES[stepType].label
      }-step-${stepNumber}-${getAttributeUniqueId()}`;
      query.condition = 'yes';
      query.query = customAnalyticsQuery;
      query.depends_on = [];
      break;
    case WORK_FLOW_STEP_TYPES.add_column_from_library.id:
      query.attribute_title = stepData.title;
      query.attribute_name = stepData.data_name;
      if (stepData.parameters && stepData.data_name !== 'GROWTH_SIGNALS') {
        query.query = { ...customAnalyticsQuery };
        // eslint-disable-next-line prefer-named-capture-group, require-unicode-regexp
        const regex = /\{\{([^}]+)\}\}/g;
        query.query.user_question = stepData.parameters.question?.replace(regex, '')?.trim();
        query.query.answer_type = stepData.parameters.answer_type;
      }
      query.depends_on = [];
      query.saved_query_id = stepData.type === 'custom_analytics' ? stepData.id : null;
      query.data_type = stepData.data_name;
      query.custom_column_type = stepData.type;
      break;
    default:
      break;
  }
  return query;
}

export function getWorkflowStepByTypeAndSteps(stepType, steps, stepData) {
  const newStepId = steps.length > 0 ? `step_${steps.length}` : 'start';
  const query = getWorkflowStepQueryBystepType(stepType, steps.length + 1, stepData);
  const newStep = {
    step_id: newStepId,
    function_use: {
      action: stepType,
      action_variables: {
        queries: [query]
      }
    },
    objective: stepType,
    if_failed: 'end',
    if_successful: 'end'
  };

  return newStep;
}

export function sanitizeWorkflowPlan(plan, name) {
  const newPlan = cloneDeep(plan);
  newPlan.steps?.forEach((step) => {
    step?.function_use?.action_variables?.queries?.forEach((queryItem) => {
      if (typeof queryItem === 'object') {
        if (queryItem.attribute_default_title) {
          delete queryItem.attribute_default_title;
        }
        if (queryItem.analysis_default_title) {
          delete queryItem.analysis_default_title;
        }
      }
    });
  });
  newPlan.name = name;
  return newPlan;
}
