import { Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  getLatestDRCompanyMetaIdsByListId,
  getLatestDRFilteredOutCompanyMetaIdsByListId
} from 'selectors/domain-reports';
import { getCompanyListHistoryByListId, getCompanyMetasByListId } from 'selectors/explore';
import { sourceValueToHistory } from 'utils/history-utils';

import DomainReportBreakdownItem from './DomainReportBreakdownItem';

function DomainReportDiscoveryBreakdown({ listId }) {
  const allCompanyMetaIds = useSelector(
    (state) =>
      getLatestDRFilteredOutCompanyMetaIdsByListId(state, listId) +
      getLatestDRCompanyMetaIdsByListId(state, listId)
  );
  const currentCompanyMetas = useSelector((state) => getCompanyMetasByListId(state, listId));
  const listHistory = useSelector((state) => getCompanyListHistoryByListId(state, listId));
  const total = allCompanyMetaIds?.length || currentCompanyMetas?.length || 0;
  const companyMetas =
    currentCompanyMetas?.filter((company) => {
      return allCompanyMetaIds ? allCompanyMetaIds.includes(company.company_meta_id) : true;
    }) || [];
  const historyData = listHistory
    ? Object.values(listHistory)
        .map(sourceValueToHistory)
        .map((history) => {
          const number = companyMetas.filter(
            (meta) => meta.iteration_added === history.index || 0
          ).length;
          return { ...history, number };
        })
    : null;
  return (
    <Stack gap="8px">
      <Typography variant="text1_Bold" color="colors.primary_text" sx={{ marginBottom: '8px' }}>
        {Object.keys(listHistory).length} actions used to create the list:
      </Typography>
      {historyData &&
        historyData.map((history) => (
          <DomainReportBreakdownItem
            key={history.index}
            leftText={`${history.type}${history?.user_input ? `: ${history.user_input}` : ''}`}
            rightText={`+${history.number}`}
            percent={Math.floor((history.number / total) * 100)}
          />
        ))}
    </Stack>
  );
}

DomainReportDiscoveryBreakdown.propTypes = {
  listId: PropTypes.number
};

export default DomainReportDiscoveryBreakdown;
