import { Box, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

function MeasurableConponent({ setChildrenWidth, hide, children }) {
  const ref = useRef();

  const { key } = children;
  useEffect(() => {
    if (!ref.current || !key || hide) return;

    setChildrenWidth((prev) => ({ ...prev, [key]: ref.current?.clientWidth }));
  }, [ref, setChildrenWidth, key, hide]);

  return (
    <Box
      ref={ref}
      sx={{ backgroundColor: 'red', visibility: 'hidden', display: hide ? 'none' : 'block' }}>
      {children}
    </Box>
  );
}

MeasurableConponent.propTypes = {
  setChildrenWidth: PropTypes.func,
  hide: PropTypes.bool,
  children: PropTypes.any
};

export function ShowMoreContainerComponent({ component, maxWidth, tooltip, children, ...rest }) {
  const [childrenWidth, setChildrenWidth] = useState({});

  let total = 0;
  let hiddenCount = 0;
  const clones = React.Children.map(children, (child, index) => {
    if (!childrenWidth[child.key]) {
      return (
        <MeasurableConponent key={index} setChildrenWidth={setChildrenWidth}>
          {child}
        </MeasurableConponent>
      );
    }

    total += childrenWidth[child.key];
    if (total < maxWidth) {
      return child;
    }

    hiddenCount++;
    return (
      <MeasurableConponent key={index} setChildrenWidth={setChildrenWidth} hide={true}>
        {child}
      </MeasurableConponent>
    );
  });

  if (hiddenCount > 0) {
    clones.push(
      <Tooltip title={tooltip || ''}>
        <Typography
          variant="text1_Bold"
          color="colors.primary_text"
          sx={{
            border: '1px solid',
            borderColor: 'colors.hover_on_selected',
            borderRadius: '4px',
            padding: '4px'
          }}>{`+${hiddenCount}`}</Typography>
      </Tooltip>
    );
  }

  const Component = component || Box;

  return <Component {...rest}>{clones}</Component>;
}

ShowMoreContainerComponent.propTypes = {
  component: PropTypes.object,
  maxWidth: PropTypes.number,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.any
};

export default ShowMoreContainerComponent;
