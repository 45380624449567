import { Box } from '@mui/material';
import { SECTIONS_DEFAULT_PRESENTATION_PROPS } from 'constants/domain-reports';
import MLSidebar from 'design-system/MLSidebar';
import {
  DomainReportSection,
  DomainReportSectionType,
  DomainReportSidebarView
} from 'models/domain-report/types';
import React, { useState } from 'react';

import DomainReportDomainKnowledgeEditor from './edit/DomainReportDomainKnowledgeEditor';
import DomainReportCreateSectionView from './views/DomainReportCreateSectionView';
import DomainReportEditSectionView from './views/DomainReportEditSectionView';
import DomainReportEditorMainView from './views/DomainReportEditorMainView';

const editorMapper: {
  [key in DomainReportSectionType]: React.FC<any> | null;
} = {
  [DomainReportSectionType.DOMAIN_KNOWLEDGE]: DomainReportDomainKnowledgeEditor,
  [DomainReportSectionType.CLUSTER_ANALYSIS]: DomainReportEditSectionView,
  [DomainReportSectionType.MARKET_LANDSCAPE]: DomainReportEditSectionView,
  [DomainReportSectionType.TOP_PROBLEMS_BEING_SOLVED]: DomainReportEditSectionView,
  [DomainReportSectionType.EXPLORE_PROCESS]: null
};

interface DomainReportEditorSidebarProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}
export default function DomainReportEditorSidebar({
  open,
  setOpen
}: DomainReportEditorSidebarProps) {
  const [view, setView] = useState<DomainReportSidebarView>(DomainReportSidebarView.MAIN);
  const [editableSection, setEditableSection] = useState<DomainReportSection | null>(null);
  const [sectionToCreate, setSectionToCreate] = useState<DomainReportSectionType | null>(null);

  const updateSectionEdit = (section: DomainReportSection) => {
    setEditableSection(section);
    setView(DomainReportSidebarView.EDIT);
  };

  const updateSectionCreation = (sectionType: DomainReportSectionType) => {
    setSectionToCreate(sectionType);
    setView(DomainReportSidebarView.CREATE);
  };

  const getViewTitle = () => {
    if (view === DomainReportSidebarView.EDIT && editableSection) {
      return editableSection.props.title;
    }
    if (view === DomainReportSidebarView.CREATE && sectionToCreate) {
      const title = SECTIONS_DEFAULT_PRESENTATION_PROPS[sectionToCreate].defaultTitle;
      return `New ${title} Section`;
    }
    return 'Domain Report';
  };
  const resetView = () => {
    setView(DomainReportSidebarView.MAIN);
    setEditableSection(null);
  };
  const title = getViewTitle();
  return (
    <MLSidebar
      id={'domain-report-editor-sidebar'}
      open={open}
      setOpen={setOpen}
      title={title}
      isBackButton={view !== DomainReportSidebarView.MAIN}
      onBack={resetView}>
      <Box sx={{ width: 400, height: '100%' }}>
        {view === DomainReportSidebarView.MAIN && (
          <DomainReportEditorMainView
            updateSectionEdit={updateSectionEdit}
            updateSectionCreation={updateSectionCreation}
          />
        )}

        {view === DomainReportSidebarView.EDIT &&
          editableSection &&
          (() => {
            const EditorComponent = editorMapper[editableSection.props.type];
            if (!EditorComponent) return null;
            return <EditorComponent section={editableSection} resetView={resetView} />;
          })()}

        {view === DomainReportSidebarView.CREATE && sectionToCreate && (
          <DomainReportCreateSectionView type={sectionToCreate} resetView={resetView} />
        )}
      </Box>
    </MLSidebar>
  );
}
