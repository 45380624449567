import { Stack, Typography } from '@mui/material';
import { SUGGESTED_QUERIES } from 'constants/assistant';
import MLInlineStack from 'design-system/MLInlineStack';
import React from 'react';
import NewAssistantChatGraphic from 'svg/NewAssistantChatGraphic';
import NewChatGraphic from 'svg/NewAssistantChatGraphic.svg';

import AssistantSuggestedQuery from './AssistantSuggestedQuery';

export default function NewAssistantChat() {
  return (
    <Stack
      sx={{
        height: '100%',
        paddingX: '0px',
        backgroundImage: `url(${NewChatGraphic})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        backgrounPosition: 'center center'
      }}>
      <Stack
        gap="18px"
        sx={{
          margin: 'auto',
          color: 'colors.text_primary'
        }}
        justifyContent="center"
        alignItems="center">
        <NewAssistantChatGraphic />
        <Typography variant="h3_Semibold" sx={{ marginTop: '12px' }}>
          Start a new research
        </Typography>
        <Typography sx={{ maxWidth: '422px' }}>
          Start by describing the companies and the details that interest you about them, or
          manually add companies to the table.
        </Typography>
      </Stack>
      <MLInlineStack
        sx={{ marginTop: 'auto', textAlign: 'start', flexWrap: 'wrap', paddingX: '24px' }}>
        {SUGGESTED_QUERIES.map((suggestedQuery, index) => (
          <AssistantSuggestedQuery key={index} {...suggestedQuery} />
        ))}
      </MLInlineStack>
    </Stack>
  );
}
