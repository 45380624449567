import { Dialog, DialogContent, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { getExploreHiddenColumnsById } from 'selectors/explore';
import { COLUMN_TYPES } from 'utils/explore-table-columns';
import { getDocumentFileName } from 'utils/files';
import { generateQuickViewDialogPowerPoint } from 'utils/react-to-powerpoint-utils';

import QuickViewCA from './QuickViewCA';
import QuickViewCompanyInfo from './QuickViewCompanyInfo';
import QuickViewFinancials from './QuickViewFinancials';
import QuickViewLocation from './QuickViewLocation';
import QuickViewTitle from './QuickViewTitle';

const FIELDS_NOT_TO_FORMAT = [];

const getRowFieldValue = (row, colDefs, field) => {
  const val = row[field];
  if (!val) {
    return null;
  }
  const colDef = colDefs.find((item) => item.field === field);
  const valueFormatter = colDef?.extraParams?.companyInfoFormatter || colDef?.valueFormatter;
  const formattedVal =
    valueFormatter && !FIELDS_NOT_TO_FORMAT.includes(field) ? valueFormatter(val) : val;
  if (typeof formattedVal === 'string') return formattedVal;
  return formattedVal ? JSON.stringify(formattedVal) : null;
};

function QuickViewDialog({ listId, open, onClose, row, colDefs, api }) {
  const contentRef = useRef();
  const [currentRow, setCurrentRow] = useState({});
  const allRowIds = api.getSortedRowIds();
  const [minRowId] = allRowIds;
  const [maxRowId] = allRowIds.slice(-2);
  const value = currentRow?.[COLUMN_TYPES.COMPANY];
  const [exportingPdf, setExportingPdf] = useState(false);
  const [exportingPowerPoint, setExportingPowerPoint] = useState(false);
  const hiddenColumns = useSelector((state) => getExploreHiddenColumnsById(state, listId));

  const reactToPrintFn = useReactToPrint({
    contentRef: contentRef,
    documentTitle: getDocumentFileName(value?.name, 'report'),
    onAfterPrint: () => {
      setExportingPdf(false);
    }
  });

  const generatePowerPoint = async () => {
    const pptx = await generateQuickViewDialogPowerPoint(contentRef.current);
    pptx.writeFile({ fileName: getDocumentFileName(value?.name, 'presentation') }).then(() => {
      setExportingPowerPoint(false);
    });
  };

  const { id } = currentRow;
  const revenuesData = (api.getColumn(COLUMN_TYPES.REVENUES)?.extraParams?.values || []).reduce(
    (acc, item, index) => {
      if (allRowIds.includes(index)) {
        acc.push(item?.data);
      }
      return acc;
    },
    []
  );
  const countryData = (api.getColumn(COLUMN_TYPES.COUNTRY)?.extraParams?.values || []).reduce(
    (acc, item, index) => {
      if (allRowIds.includes(index)) {
        acc.push(item);
      }
      return acc;
    },
    []
  );
  useEffect(() => {
    setCurrentRow(row);
  }, [row]);
  const getFieldValue = useCallback(
    (field) => {
      return getRowFieldValue(currentRow, colDefs, field);
    },
    [currentRow, colDefs]
  );
  const changeRow = useCallback(
    (newId) => {
      const newRow = api.getRow(newId);
      if (!newRow) return;
      setCurrentRow(newRow);
    },
    [setCurrentRow, api]
  );
  const onUp = useCallback(() => {
    if (id === minRowId) return;
    const indexOfCurrentId = allRowIds.indexOf(id);
    const newId = allRowIds[indexOfCurrentId - 1];
    changeRow(newId);
  }, [id, changeRow, minRowId, allRowIds]);

  const onDown = useCallback(() => {
    if (id === maxRowId) return;
    const indexOfCurrentId = allRowIds.indexOf(id);
    const newId = allRowIds[indexOfCurrentId + 1];
    changeRow(newId);
  }, [changeRow, id, maxRowId, allRowIds]);

  const customAnalyticsColIds = colDefs
    ?.filter(({ type }) => type === 'custom_analytics')
    ?.map(({ field }) => field);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: { minWidth: '983px', maxWidth: '983px', borderRadius: '8px' },
        ref: contentRef
      }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: 'colors.primary_text',
            opacity: '0.5 !important'
          }
        }
      }}>
      <QuickViewTitle
        listId={listId}
        value={value}
        onClose={onClose}
        toPdf={reactToPrintFn}
        exportingPdf={exportingPdf}
        setExportingPdf={setExportingPdf}
        toPowerPoint={generatePowerPoint}
        exportingPowerPoint={exportingPowerPoint}
        setExportingPowerPoint={setExportingPowerPoint}
        getFieldValue={getFieldValue}
        onUp={id === minRowId ? null : onUp}
        onDown={id === maxRowId ? null : onDown}
      />
      <DialogContent sx={{ backgroundColor: 'colors.gray_bg' }}>
        <Stack gap="16px">
          <QuickViewCompanyInfo getFieldValue={getFieldValue} />
          {customAnalyticsColIds &&
            customAnalyticsColIds
              .filter((columnId) => hiddenColumns?.[columnId] !== true)
              .map((columnId) => <QuickViewCA key={columnId} value={currentRow?.[columnId]} />)}
          <QuickViewFinancials row={currentRow} revenuesData={revenuesData} />
          <QuickViewLocation row={currentRow} countryData={countryData} />
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

QuickViewDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  row: PropTypes.object,
  colDefs: PropTypes.array,
  api: PropTypes.object,
  listId: PropTypes.string
};

export default QuickViewDialog;
