import { Menu, MenuItem, Stack, Typography } from '@mui/material';
import { updateDomainReportSections } from 'actions/explore/update-domain-report-sections-actions';
import { useDomainReportContext } from 'components/domain-report/DomainReportContext';
import { SECTIONS_DEFAULT_PRESENTATION_PROPS } from 'constants/domain-reports';
import { ICON_VARIANTS } from 'constants/icons';
import MLButton from 'design-system/MLButton';
import MLInlineStack from 'design-system/MLInlineStack';
import useMenuManagement from 'design-system/hooks/useMenuManagement';
import AddIcon from 'icons/AddIcon';
import { DomainReportSection, DomainReportSectionType } from 'models/domain-report/types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { updateDomainReport } from 'reducer/domain-reports-slice';
import { useAppDispatch } from 'types/store';

import DomainReportDraggableList from '../draggable/DomainReportDraggableList';

const itemSx = {
  minHeight: '32px',
  padding: '8px'
};

interface DomainReportEditorMainViewProps {
  updateSectionEdit: (section: DomainReportSection) => void;
  updateSectionCreation: (section: DomainReportSectionType) => void;
}

export default function DomainReportEditorMainView({
  updateSectionEdit,
  updateSectionCreation
}: DomainReportEditorMainViewProps) {
  const dispatch = useAppDispatch();
  const { listId, domainReport } = useDomainReportContext();
  const { sections } = useDomainReportContext();

  const [internalSections, setInternalSections] = useState<DomainReportSection[]>(sections);

  const sectionsUpdateTimeout = useRef<NodeJS.Timeout | null>(null);

  const triggerUpdateSectionsWithTimeout = useCallback(
    (sections: DomainReportSection[]) => {
      if (sectionsUpdateTimeout.current) {
        clearTimeout(sectionsUpdateTimeout.current);
      }
      sectionsUpdateTimeout.current = setTimeout(() => {
        dispatch(updateDomainReportSections(listId, domainReport.domain_report_id, sections));
        sectionsUpdateTimeout.current = null;
      }, 5000);
    },
    [dispatch, listId, domainReport.domain_report_id]
  );

  useEffect(() => {
    if (sectionsUpdateTimeout.current) {
      triggerUpdateSectionsWithTimeout(sections);
    }
  }, [sections, triggerUpdateSectionsWithTimeout]);

  useEffect(() => {
    setInternalSections(sections);
  }, [sections]);

  const updateSectionsOrder = (newSections: DomainReportSection[]) => {
    dispatch(
      updateDomainReport({
        listId,
        lastDomainReportId: domainReport.domain_report_id,
        data: {
          domain_report_id: domainReport.domain_report_id,
          sections: newSections.map((section) => section.props)
        }
      })
    );

    triggerUpdateSectionsWithTimeout(newSections);
  };

  const { onOpen, onClose, open, anchorEl, ref } = useMenuManagement<HTMLButtonElement>();
  return (
    <Stack>
      <MLInlineStack justifyContent="space-between" padding="16px">
        <Typography variant="text1_Medium">{internalSections.length} sections</Typography>
        <MLButton
          ref={ref}
          variant="contained"
          startIcon={<AddIcon variant={ICON_VARIANTS.SECONDARY} />}
          onClick={onOpen}>
          Add
        </MLButton>
        <Menu
          open={open}
          onClose={onClose}
          title="Section Type"
          anchorEl={anchorEl}
          MenuListProps={{ sx: { py: 0 } }}
          slotProps={{
            paper: {
              sx: {
                width: '214px',
                border: '1px solid',
                borderColor: 'colors.ui_border',
                borderRadius: '8px',
                boxShadow: 'box-shadow: 0px 2px 7px 0px rgba(66, 82, 110, 0.41)'
              }
            }
          }}>
          <MLInlineStack sx={itemSx}>
            <Typography variant="text1_Normal" color="colors.secondary_text">
              Section Type
            </Typography>
          </MLInlineStack>
          {Object.entries(SECTIONS_DEFAULT_PRESENTATION_PROPS)
            .filter(([, section]) => section.isAddable)
            .map(([key, section]) => {
              const type = key as DomainReportSectionType;
              return (
                <MenuItem sx={itemSx} key={type} onClick={() => updateSectionCreation(type)}>
                  <MLInlineStack>
                    {section.iconComponent}
                    <Typography variant="text1_Normal">{section.defaultTitle}</Typography>
                  </MLInlineStack>
                </MenuItem>
              );
            })}
        </Menu>
      </MLInlineStack>
      <Stack gap="16px">
        <DomainReportDraggableList
          items={internalSections}
          setItems={setInternalSections}
          onDragEndCallback={updateSectionsOrder}
          setEditableSection={updateSectionEdit}
        />
      </Stack>
    </Stack>
  );
}
