import { Checkbox, MenuItem, OutlinedInput, Stack } from '@mui/material';
import MLModal from 'design-system/MLModal';
import { ListContextType, useListContext } from 'hooks/ListContextProvider';
import SearchIcon from 'icons/SearchIcon';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { appTheme } from 'theme';

import QuickViewExpandable from '../quick-view/QuickViewExpandable';
import ColumnSelectionItem from './ColumnSelectionItem';
import { Item, transformData, transformedDataTobeIdAndName } from './utils';

interface DialogDeleteMultipleColumnsProps {
  open: boolean;
  handleDeleteClose: () => void;
  handleDelete: (item: Record<string, string>) => void;
}

const DialogDeleteMultipleColumns = ({
  open,
  handleDeleteClose,
  handleDelete
}: DialogDeleteMultipleColumnsProps) => {
  const [searchQuery, setSearchQuery] = useState<string>();
  const [checkedItems, setCheckedItems] = useState<Record<string, boolean>>({});
  const [data, setData] = useState<Record<string, Item[]> | undefined>();

  const listContext = useListContext() as ListContextType;

  useEffect(() => {
    if (listContext?.customColumns) {
      setData(transformData(listContext.customColumns));
    }
  }, [listContext?.customColumns]);

  const handleToggle = useCallback((id: string) => {
    setCheckedItems((prev) => ({
      ...prev,
      [id]: !prev[id]
    }));
  }, []);

  const groupedItems = useMemo(() => {
    if (!data) return {};

    const result: Record<string, Item[]> = {};

    Object.entries(data).forEach(([type, items]) => {
      const filteredGroup = searchQuery
        ? items.filter((item) => item.name.toLowerCase().includes(searchQuery.toLowerCase()))
        : items;

      if (filteredGroup.length > 0) {
        result[type] = filteredGroup;
      }
    });

    return result;
  }, [data, searchQuery]);

  const handleGroupSelectAll = useCallback(
    (type: string, items: Item[]) => {
      const allChecked = items.every((item) => checkedItems[item.id]);
      const newCheckedItems = { ...checkedItems };

      items.forEach((item) => {
        newCheckedItems[item.id] = !allChecked;
      });

      setCheckedItems(newCheckedItems);
    },
    [checkedItems]
  );

  const selectedCount = useMemo(() => {
    if (!data) return [];

    const result: Record<string, Item[]> = {};

    Object.entries(data).forEach(([type, items]) => {
      const checkedItemsInGroup = items.filter((item) => checkedItems[item.id]);

      if (checkedItemsInGroup.length > 0) {
        result[type] = checkedItemsInGroup;
      }
    });

    return Object.values(result)
      .flat()
      .filter((item) => checkedItems[item.id]);
  }, [data, checkedItems]);

  const onDelete = () => {
    handleDelete(transformedDataTobeIdAndName(selectedCount));
  };

  const primaryButtonProps = {
    label: `Delete ${selectedCount.length === 0 ? '' : selectedCount.length} columns`,
    onClick: () => onDelete(),
    disabled: selectedCount.length === 0
  };
  const secondaryButtonProps = {
    label: 'Cancel',
    onClick: () => handleDeleteClose()
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  const handleWheel = (event: React.WheelEvent<HTMLDivElement>) => {
    event.stopPropagation();
    const element = event.currentTarget;
    element.scrollTop += event.deltaY;
  };

  const selectByType = (groupItems: Item[], type: string) => {
    const lengthOfGroupItems = groupItems.length;
    const lengthOfData = data && data[type].length;

    const allChecked = groupItems.every((item: Item) => checkedItems[item.id]);

    return (
      <Checkbox
        size="small"
        checked={
          searchQuery
            ? lengthOfGroupItems === lengthOfData && lengthOfGroupItems >= 0 && allChecked
            : allChecked
        }
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          handleGroupSelectAll(type, groupItems);
        }}
        onChange={(event) => {
          event.preventDefault();
          event.stopPropagation();
          handleGroupSelectAll(type, groupItems);
        }}
        sx={{
          '&:hover': {
            backgroundColor: 'transparent !important'
          }
        }}
      />
    );
  };

  return (
    <MLModal
      maxWidth="sm"
      title={`Delete multiple columns`}
      open={open}
      onClose={handleDeleteClose}
      primaryButtonProps={primaryButtonProps}
      secondaryButtonProps={secondaryButtonProps}>
      <Stack width="100%" height="100%" direction="column">
        <OutlinedInput
          placeholder={'Search column'}
          value={searchQuery}
          startAdornment={<SearchIcon />}
          onChange={(event) => setSearchQuery(event.target.value)}
          sx={{
            width: '100%',
            backgroundColor: 'transparent',
            marginBottom: '20px',
            ...appTheme.typography.text1_Normal,
            '.MuiInputBase-input': { padding: '8px' }
          }}
        />
      </Stack>
      <Stack
        width="100%"
        height="360px"
        direction="column"
        onScroll={handleScroll}
        onWheel={handleWheel}
        onClick={(event) => event.stopPropagation()}
        sx={{
          maxHeight: '285px',
          overflow: 'auto'
        }}>
        <MenuItem
          value="all"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '0'
          }}>
          {groupedItems && Object.keys(groupedItems).length === 0 && searchQuery && (
            <>No results found</>
          )}
        </MenuItem>
        {groupedItems &&
          Object.entries(groupedItems).map(([type, groupItems]) => (
            <QuickViewExpandable
              key={type}
              title={type}
              defaultExpanded={true}
              startButton={selectByType(groupItems, type)}
              titleVariant="text1_Semibold"
              marginTop="0"
              disabled={false}>
              <Stack marginLeft={'12px'} direction={'column'} gap={0}>
                {groupItems &&
                  groupItems.length &&
                  groupItems.map((column: Item) => (
                    <ColumnSelectionItem
                      key={column.id}
                      id={column.id}
                      name={column.name}
                      isChecked={Boolean(checkedItems[column.id])}
                      onToggle={handleToggle}
                    />
                  ))}
              </Stack>
            </QuickViewExpandable>
          ))}
      </Stack>
    </MLModal>
  );
};

export default DialogDeleteMultipleColumns;
