import { addDomainReport } from 'reducer/domain-reports-slice';
import { isDomainReportCASectionType } from 'utils/domain-report/domain-report-ts-utils';

import { bindDomainReportPusherAction } from './bind-domain-report-pusher-action';

export function loadDomainReportAction(listId) {
  return (dispatch, _getState, apiClient) => {
    apiClient.explore.fetchExploreListDomainReport(listId).then((res) => {
      if (res.ok && res.data.exists) {
        dispatch(addDomainReport({ listId, data: res.data }));
        if (res.data?.domain_report?.parameters?.sections) {
          res.data.domain_report.parameters.sections.forEach((section) => {
            if (isDomainReportCASectionType(section.type)) {
              dispatch(
                bindDomainReportPusherAction({
                  listId,
                  domainReportId: res.data.domain_report_id,
                  postId: section.data?.post_id
                })
              );
            }
          });
        }
      }
    });
  };
}
