import { useTheme } from '@mui/material';
import { RESOURCE_TYPE_TO_ICON } from 'components/domain-knowledge/DomainKnowledgeResourceTile';
import { DOMAIN_KNOWLEDGE_RESOURCE_OPTIONS } from 'constants/domain-knowledge';
import useDeriveListContext from 'hooks/useDeriveListContext';
import { useMemo } from 'react';
import {
  getContextSections,
  getContextTextAndTooltip,
  getHandleSearchPlaceChange,
  promptConfigToSearchPlaces
} from 'utils/prompt-dropdown-utils';

type PromptConfig = {
  useDomainKnowledge: boolean;
  columns: string[] | null;
  resources: number[] | null;
  useTable: boolean;
  searchTheWeb: boolean;
};

const useEditConfigContext = (
  listId: number,
  localPC: PromptConfig,
  setLocalPC: CallableFunction,
  includeDomainKnowledge = true
) => {
  const searchPlaces = promptConfigToSearchPlaces(localPC);
  const handleSearchPlaceChange = getHandleSearchPlaceChange(setLocalPC, null);

  const { columns, supportingResources: domainKnowledge } = useDeriveListContext({ listId });

  const domainKnowledgeOptions = [
    ...DOMAIN_KNOWLEDGE_RESOURCE_OPTIONS,
    ...Object.values(domainKnowledge || {})
      .sort((item1, item2) => (item1.type < item2.type ? 1 : -1))
      .map((dk) => ({
        icon: RESOURCE_TYPE_TO_ICON[dk.type as keyof typeof RESOURCE_TYPE_TO_ICON],
        ...dk
      }))
  ];
  const knowledge = includeDomainKnowledge
    ? domainKnowledgeOptions.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {} as Record<string, any>)
    : null;
  const contextSections: Record<string, any>[] = useMemo(
    () =>
      getContextSections({
        allColumns: columns,
        useDomainKnowledge: localPC.useDomainKnowledge,
        columns: localPC.columns,
        resources: [localPC.resources],
        setPromptConfig: setLocalPC,
        useTable: localPC.useTable,
        knowledge
      }),
    [
      localPC.useDomainKnowledge,
      localPC.columns,
      localPC.resources,
      setLocalPC,
      localPC.useTable,
      columns,
      knowledge
    ]
  );
  const { text: contextSelectorTitle, tooltipText: contextSelectorTooltip } = useMemo(
    () => getContextTextAndTooltip(localPC.useTable, localPC.useDomainKnowledge, contextSections),
    [localPC.useTable, localPC.useDomainKnowledge, contextSections]
  );
  const theme = useTheme();
  const defaultSources = [
    { id: 'web', name: 'Web' },
    { id: 'table', name: 'Table' }
  ];

  return {
    searchPlaces,
    handleSearchPlaceChange,
    contextSections,
    contextSelectorTitle,
    contextSelectorTooltip,
    theme,
    localPC,
    setLocalPC,
    defaultSources,
    domainKnowledgeOptions
  };
};

export default useEditConfigContext;
