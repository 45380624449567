import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getLatestDRCompanyMetaIdsByListId } from 'selectors/domain-reports';
import { getCompanyMetasByListId } from 'selectors/explore';
import { getExploreFilteredCompanies } from 'selectors/explore-table-ui';

export function useIsDomainReportOutdated(listId: number) {
  const exploreCompanyMetas = useSelector((state) => getCompanyMetasByListId(state, listId));
  const filteredExploreCompanyListMetaIds = useSelector((state) =>
    getExploreFilteredCompanies(state, listId)
  );

  const explorePresentedCompanyMetaIds = useMemo(() => {
    return exploreCompanyMetas
      .filter((company: { company_list_meta_id: any }) =>
        filteredExploreCompanyListMetaIds.includes(company.company_list_meta_id)
      )
      .map((company: { company_meta_id: any }) => company.company_meta_id);
  }, [exploreCompanyMetas, filteredExploreCompanyListMetaIds]);

  const domainReportMetaIds = useSelector((state) =>
    getLatestDRCompanyMetaIdsByListId(state, listId)
  );

  const checkOutdated = useCallback(() => {
    if (!explorePresentedCompanyMetaIds || !domainReportMetaIds) return true;
    if (explorePresentedCompanyMetaIds.length !== domainReportMetaIds.length) return true;

    const sortedPresentedCompaniesIds = [...explorePresentedCompanyMetaIds].sort(
      (element1, element2) => element1 - element2
    );
    const sortedDomainReportMetaIds = [...domainReportMetaIds].sort(
      (element1, element2) => element1 - element2
    );

    return !sortedPresentedCompaniesIds.every(
      (value, index) => value === sortedDomainReportMetaIds[index]
    );
  }, [explorePresentedCompanyMetaIds, domainReportMetaIds]);

  return useMemo(() => checkOutdated(), [checkOutdated]);
}
