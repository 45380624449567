import { DndContext, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Box, Button, Divider, Popover, Stack, Tooltip, Typography } from '@mui/material';
import { updateFinalStepInPlanAction } from 'actions/workflows/update-final-step-in-plan-action';
import { WORK_FLOW_STEP_TYPES } from 'constants/workflows';
import MLButton from 'design-system/MLButton';
import MLInlineStack from 'design-system/MLInlineStack';
import InfoIcon from 'design-system/icons/basic/InfoIcon';
import ColumnsIcon from 'icons/ColumnsIcon';
import RefreshIcon from 'icons/RefreshIcon';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getThreadKey } from 'reducer/custom-analytics';
import { getCustomAnalyticsPlanByThreadAndPostId } from 'selectors/custom-analytics';

import ManageColumnsItem from './ManageColumnsItem';

function WorkflowManageColumns({ listId, workflowId }) {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [columnsToDisplay, setColumnsToDisplay] = useState({});

  const threadKey = getThreadKey(listId);
  const plan = useSelector((state) =>
    getCustomAnalyticsPlanByThreadAndPostId(state, threadKey, workflowId)
  );

  const columns = useMemo(() => {
    const planColumns = [];
    plan?.steps?.forEach((step) => {
      if (
        step?.function_use?.action === WORK_FLOW_STEP_TYPES.information.id ||
        step?.function_use?.action === WORK_FLOW_STEP_TYPES.add_column_from_library.id
      ) {
        const addedColumns = step?.function_use?.action_variables?.queries?.map((substep) => ({
          id: substep.attribute_name,
          title: substep.attribute_title || substep.attribute_name
        }));
        planColumns.push(...addedColumns);
      }
    });
    return planColumns;
  }, [plan]);

  const haveHiddenColumns = useMemo(
    () => Object.values(columnsToDisplay).findIndex((columnVisibility) => !columnVisibility) >= 0,
    [columnsToDisplay]
  );

  const resetColumnsToDisplay = useCallback(() => {
    const newColumnsToDisplay = columns.reduce((obj, column) => {
      obj[column.id] = true;
      return obj;
    }, {});
    setColumnsToDisplay(newColumnsToDisplay);
  }, [columns]);

  useEffect(() => {
    const endStep = plan?.steps?.find((step) => step.step_id === 'end');
    if (endStep?.function_use?.action_variables?.queries?.[0]?.columns) {
      const currentVisibleColumnsSet = new Set(
        endStep.function_use.action_variables.queries[0].columns
      );
      const columnsVisibility = columns.reduce((obj, column) => {
        obj[column.id] = currentVisibleColumnsSet.has(column.id);
        return obj;
      }, {});
      setColumnsToDisplay(columnsVisibility);
    } else {
      resetColumnsToDisplay();
    }
  }, [plan, columns, resetColumnsToDisplay, setColumnsToDisplay]);

  const sensors = useSensors(useSensor(PointerSensor));

  const handleOpenManageColumns = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseManageColumns = () => {
    dispatch(updateFinalStepInPlanAction(listId, workflowId, columnsToDisplay));
    setAnchorEl(null);
  };

  const updateColumnsToDisplay = (column, isCheck) => {
    const newColumnsToDisplay = { ...columnsToDisplay };
    newColumnsToDisplay[column.id] = isCheck;
    setColumnsToDisplay(newColumnsToDisplay);
  };

  if (columns.length <= 0) return null;

  return (
    <React.Fragment>
      <Button variant="text" startIcon={<ColumnsIcon />} onClick={handleOpenManageColumns}>
        Manage columns
      </Button>
      {Boolean(anchorEl) && (
        <Popover
          id="color-picker"
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleCloseManageColumns}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          slotProps={{
            paper: {
              sx: { backgroundColor: 'colors.primary_bg' }
            }
          }}>
          <DndContext
            sensors={sensors}
            // onDragStart={(p1, p2) => console.log('updateActiveItem', p1, p2)}
            // onDragEnd={(p1, p2) => console.log('onDragEnd', p1, p2)}
            collisionDetection={closestCenter}
            modifiers={[restrictToVerticalAxis, restrictToParentElement]}>
            <SortableContext
              items={columns.map((column) => column.id)}
              strategy={verticalListSortingStrategy}>
              <Stack gap="16px" minWidth="240px" padding="8px">
                <MLInlineStack justifyContent="space-between" color="colors.primary_text">
                  <Typography variant="text1_Semibold" color="colors.primary_text">
                    Modify final table view
                  </Typography>
                  <Tooltip
                    title="Decide which columns will appear after running the workflow"
                    arrow>
                    <Box>
                      <InfoIcon />
                    </Box>
                  </Tooltip>
                </MLInlineStack>
                <Stack gap="4px">
                  {columns.map((column) => (
                    <ManageColumnsItem
                      key={column.id}
                      column={column}
                      isChecked={columnsToDisplay[column.id]}
                      updateColumnVisibilty={updateColumnsToDisplay}
                    />
                  ))}
                </Stack>
                <Divider />
                <MLButton
                  variant="text"
                  startIcon={<RefreshIcon />}
                  disabled={!haveHiddenColumns}
                  onClick={resetColumnsToDisplay}
                  sx={{ alignSelf: 'flex-start' }}>
                  Reset to default
                </MLButton>
              </Stack>
            </SortableContext>
          </DndContext>
        </Popover>
      )}
    </React.Fragment>
  );
}

WorkflowManageColumns.propTypes = {
  listId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  workflowId: PropTypes.string
};

export default WorkflowManageColumns;
