import React, { ReactNode, createContext, useContext, useRef, useState } from 'react';

interface TooltipContextType {
  isTooltipVisible: boolean;
  showTooltip: () => void;
  hideTooltip: () => void;
}

// Create a default value for the context
const defaultTooltipContext: TooltipContextType = {
  isTooltipVisible: false,
  showTooltip: () => null,
  hideTooltip: () => null
};

const TooltipContext = createContext(defaultTooltipContext);

interface MLTooltipProviderrProps {
  children: ReactNode;
}

export const MLTooltipProvider = ({ children }: MLTooltipProviderrProps) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const showTooltip = () => {
    timerRef.current = setTimeout(() => {
      setIsTooltipVisible(true);
    }, 500);
  };
  const hideTooltip = () => {
    clearTimeout(timerRef.current as NodeJS.Timeout);
    setIsTooltipVisible(false);
  };

  return (
    <TooltipContext.Provider value={{ isTooltipVisible, showTooltip, hideTooltip }}>
      {children}
    </TooltipContext.Provider>
  );
};

export const useMLTooltip = () => {
  return useContext(TooltipContext);
};
