/* eslint-disable max-lines */
import { Grid2, Stack, Typography } from '@mui/material';
import { wrapWithError } from 'components/ErrorBoundaryComponent';
import { MLTooltipProvider } from 'hooks/useMLTooltip';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getListCustomColumnsByListId } from 'selectors/explore';

import { PlatterItemComponent } from './PlatterItemComponent';
import QueryLibraryCategorySideBar from './QueryLibraryCategorySideBar';
import { usePlatterItemActions } from './usePlatterActions';
import { SavedQueryType, usePlatterItems } from './usePlatterItems';

interface QueryLibraryContentProps {
  onClose: () => void;
  companyListId: number;
  actionType: SavedQueryType;
}

function QueryLibraryContent({ onClose, companyListId, actionType }: QueryLibraryContentProps) {
  const listCustomColumns = useSelector((state) =>
    getListCustomColumnsByListId(state, companyListId)
  );

  const [categoryValue, setCategoryValue] = useState<string | number>(0);
  const sectionsContainerRef = useRef<HTMLDivElement>(null);

  const { columnsByCategories, getCategoryColor, getCategoryBackgroundColor } =
    usePlatterItems(actionType);

  const handleClose = () => {
    setCategoryValue(0);
    onClose();
  };

  const { onColumnClick } = usePlatterItemActions({ handleClose, companyListId, actionType });

  const isColumnInList = (column: any) =>
    listCustomColumns &&
    (Object.keys(listCustomColumns).includes(column.data_name) ||
      Object.values(listCustomColumns).some(
        (col: any) =>
          column?.parameters?.saved_query_id &&
          col?.info?.saved_query_id === column.parameters.saved_query_id
      ));

  return (
    <Stack
      direction="row"
      sx={{ gap: '16px', padding: 0, borderTop: '1px solid', borderColor: 'colors.ui_border' }}>
      <QueryLibraryCategorySideBar
        categoryValue={categoryValue}
        setCategoryValue={setCategoryValue}
        categories={Object.keys(columnsByCategories)}
        sectionsContainerRef={sectionsContainerRef}
      />
      <Stack
        height="80vh"
        overflow="auto"
        sx={{ gap: '16px', padding: '16px', width: '100%' }}
        ref={sectionsContainerRef}>
        {columnsByCategories &&
          Object.entries(columnsByCategories).map(([category, columns]) => (
            <Stack key={category} gap="8px" padding="4px" paddingTop="16px" id={category}>
              <Typography
                variant={categoryValue === category ? 'text1_Bold' : 'text1_Medium'}
                color="colors.primary_text">
                {category}
              </Typography>
              <Stack direction="row">
                <Grid2 container rowSpacing={1.5} columnSpacing={2}>
                  {columns?.map((column) => (
                    <Grid2 key={column.data_name || column.id}>
                      <MLTooltipProvider>
                        <PlatterItemComponent
                          onCloseDialog={handleClose}
                          onClick={() => onColumnClick(column)}
                          isColumnInList={isColumnInList(column)}
                          title={column.title}
                          type={column.type}
                          parameters={column.parameters}
                          id={column.id}
                          actionType={actionType}
                          color={getCategoryColor(category)}
                          backgroundColor={getCategoryBackgroundColor(category)}
                          data_name={column.data_name}
                          category={category}
                        />
                      </MLTooltipProvider>
                    </Grid2>
                  ))}
                </Grid2>
              </Stack>
            </Stack>
          ))}
      </Stack>
    </Stack>
  );
}

export default wrapWithError(QueryLibraryContent);
