import { Box, Tooltip, Typography, useTheme } from '@mui/material';
import { NodeViewWrapper } from '@tiptap/react';
import {
  SUGGESTION_ID_TYPE_TO_BG_COLOR,
  SUGGESTION_ID_TYPE_TO_ICON,
  suggestionIdTypeToSx
} from 'constants/assistant';
import MLIcon from 'design-system/MLIcon';
import MLInlineStack from 'design-system/MLInlineStack';
import { TTMention } from 'models/assistant/tiptap';
import { SuggestionIdType } from 'models/assistant/types';
import React from 'react';
import { limitLength } from 'utils/string-utils';
import { sxColorToStyleColor } from 'utils/theme-utils';

interface MentionChipProps {
  node: any | TTMention;
  updateAttributes?: any;
  selected?: boolean;
  editor?: any;
  isStatic?: boolean;
}
const LABEL_CUTOFF = 30;

export default function MentionChip({ node, isStatic }: MentionChipProps) {
  const theme = useTheme();
  const { id, label } = node.attrs;
  const [suggestionIdType] = id.split(':');
  const style = Object.entries(
    suggestionIdTypeToSx(suggestionIdType as SuggestionIdType, true)
  ).reduce((acc, [key, value]) => ({ ...acc, [key]: sxColorToStyleColor(value, theme) }), {});

  const inner = (
    <MLInlineStack
      sx={{
        padding: '0px 4px',
        border: '1px solid',
        borderRadius: '4px',
        borderColor: SUGGESTION_ID_TYPE_TO_BG_COLOR[suggestionIdType as SuggestionIdType]
      }}
      gap="2px">
      <MLIcon
        size="small"
        icon={React.createElement(SUGGESTION_ID_TYPE_TO_ICON[suggestionIdType as SuggestionIdType])}
      />
      <Typography sx={{ overflowX: 'ellipsis', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
        {limitLength(label, LABEL_CUTOFF) as string}
      </Typography>
    </MLInlineStack>
  );
  const withOptionalTooltip =
    label.length > LABEL_CUTOFF ? (
      <Tooltip title={label} placement="top" arrow>
        <Box>{inner}</Box>
      </Tooltip>
    ) : (
      inner
    );
  return isStatic ? (
    <div className="staticMention" style={style}>
      {withOptionalTooltip}
    </div>
  ) : (
    <NodeViewWrapper className="mention" style={style}>
      {withOptionalTooltip}
    </NodeViewWrapper>
  );
}
