import { Button, Menu, Stack } from '@mui/material';
import FiltersComponent from 'components/discovery/FiltersComponent';
import { useAppState } from 'hooks/state-context';
import SettingsIcon from 'icons/SettingsIcon';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { getAllFilters } from 'selectors/companyLists';

export default function SelectFiltersComponent({ filters, setFilters, isFilterMissing }) {
  const { state } = useAppState();
  const allFilters = getAllFilters(state);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const determineInitialChecked = (key) => {
    return filters[key] || (allFilters[key].single && [allFilters[key].labels[0]]) || null;
  };
  const handleChange = (update) => {
    setFilters({ ...filters, ...update });
  };
  useEffect(() => {
    if (allFilters) {
      Object.keys(allFilters)
        .filter((key) => !allFilters[key]?.partnership_discovery)
        .forEach((key) => {
          const values =
            (allFilters[key].single && [allFilters[key].labels[0]]) || allFilters[key].labels;
          if (!filters[key] && values) {
            setFilters({ ...filters, [key]: values });
          }
        });
    }
  }, [allFilters, filters, setFilters]);

  const determineBorderColor = () => {
    if (isFilterMissing) return 'red';
    if (open) return 'colors.primary';
    return 'colors.ui_border';
  };
  return (
    <div>
      <Button
        onClick={handleClick}
        startIcon={<SettingsIcon />}
        sx={{
          border: isFilterMissing ? '1px solid' : 'unset',
          borderColor: determineBorderColor()
        }}>
        Scoping
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <Stack width="fit-content">
          {allFilters &&
            Object.keys(allFilters)
              ?.filter((key) => !allFilters[key]?.partnership_discovery)
              ?.map((key, index) => {
                return (
                  <FiltersComponent
                    key={index}
                    title={allFilters[key].title}
                    labels={allFilters[key].labels}
                    checked={determineInitialChecked(key)}
                    onChange={(newValue) => handleChange({ [key]: newValue })}
                  />
                );
              })}
        </Stack>
      </Menu>
    </div>
  );
}

SelectFiltersComponent.propTypes = {
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  isFilterMissing: PropTypes.bool
};
