import { Fade, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';

function ResizeableWidthStack({
  maxWidth,
  minWidth,
  initialWidth,
  children,
  closeComponent,
  ...args
}) {
  const stackRef = useRef();

  const [trackMove, setTrackMove] = useState(false);
  const [hoverHandler, setHoverHandler] = useState(false);
  const [chatHover, setChatHover] = useState(false);
  const [showClose, setShowClose] = useState(false);
  const [liveWidth, setLiveWidth] = useState(initialWidth);

  const handleMouseMoveEvent = useCallback(
    (event) => {
      if (!stackRef.current) return;
      const newWidth = stackRef.current.clientWidth + event.movementX;
      if (newWidth < maxWidth && newWidth > minWidth) {
        stackRef.current.style.width = `${newWidth}px`;
        setLiveWidth(newWidth);
      }
      event.stopPropagation();
      event.preventDefault();
    },
    [maxWidth, minWidth]
  );

  const handleMouseUpEvent = useCallback(() => {
    document.body.removeEventListener('mousemove', handleMouseMoveEvent, true);
    setTrackMove(false);
  }, [handleMouseMoveEvent]);

  const handleMouseDownEvent = useCallback(() => {
    setTrackMove(true);
    document.body.addEventListener('mouseup', handleMouseUpEvent, true);

    document.body.addEventListener('mousemove', handleMouseMoveEvent, true);
  }, [handleMouseUpEvent, handleMouseMoveEvent]);

  useEffect(() => {
    if (!trackMove) {
      document.body.removeEventListener('mouseup', handleMouseUpEvent, true);
      document.body.removeEventListener('mousemove', handleMouseMoveEvent, true);
    }
  }, [trackMove, handleMouseUpEvent, handleMouseMoveEvent]);

  useEffect(() => {
    if (initialWidth) {
      setLiveWidth(initialWidth);
    }
  }, [initialWidth]);

  useEffect(() => {
    if (hoverHandler || chatHover) {
      setShowClose(true);
    } else {
      setShowClose(false);
    }
  }, [showClose, hoverHandler, chatHover]);
  return (
    <Stack
      ref={stackRef}
      direction="row"
      height="100%"
      width={initialWidth}
      onMouseEnter={() => setChatHover(true)}
      onMouseLeave={() => setChatHover(false)}>
      <Stack {...args} width="100%" height="100%" position="relative">
        {children}
      </Stack>
      {closeComponent && (
        <Fade in={showClose} timeout={{ enter: 200, exit: 200 }} unmountOnExit>
          <Stack
            sx={{
              position: 'absolute',
              zIndex: 11,
              marginTop: '22px',
              marginLeft: `${(liveWidth || initialWidth) - 14}px`
            }}>
            {closeComponent}
          </Stack>
        </Fade>
      )}
      <Stack
        justifyContent="center"
        width="3px"
        height="100%"
        sx={{
          backgroundColor: hoverHandler || trackMove ? 'colors.primary' : 'transparent',
          cursor: 'col-resize',
          zIndex: 10
        }}
        onMouseEnter={() => setHoverHandler(true)}
        onMouseLeave={() => setHoverHandler(false)}
        onMouseDown={handleMouseDownEvent}></Stack>
    </Stack>
  );
}

ResizeableWidthStack.propTypes = {
  maxWidth: PropTypes.number,
  minWidth: PropTypes.number,
  initialWidth: PropTypes.number,
  children: PropTypes.any,
  closeComponent: PropTypes.node
};

export default ResizeableWidthStack;
