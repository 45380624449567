import { getReactState } from 'actions/get-react-state-action';
import { WORKFLOW_SELECTED_TYPE } from 'constants/workflows';
import {
  addPostAnswerToThread,
  addPostQueryToThread,
  getThreadKey
} from 'reducer/custom-analytics';
import {
  updateSelectedWorkflowIdByListId,
  updateWorkflowHistoryModeByIdAndListId
} from 'reducer/workflows-slice';
import { getUserId } from 'selectors/user';

export function copyLibraryWorkflowByQueryAction(listId, query) {
  return (dispatch) => {
    const userId = getUserId(getReactState());

    if (query.shouldAddToState) {
      dispatch(
        addPostQueryToThread({
          threadKey: getThreadKey(listId),
          postId: query.id,
          query: '',
          body: { saved_query_id: query.savedQueryId, hidePost: true },
          discoveryId: 1,
          messages: [],
          isWorkflow: true,
          name: query.name
        })
      );

      dispatch(
        addPostAnswerToThread({
          threadKey: getThreadKey(listId),
          threadId: 1,
          postId: query.id,
          message: '',
          responseType: 'PREPARATION',
          messages: null,
          image: null,
          plan: query.parameters,
          isWorkflow: true
        })
      );
    }
    dispatch(updateWorkflowHistoryModeByIdAndListId({ listId, isHistoryMode: false }));
    dispatch(
      updateSelectedWorkflowIdByListId({
        listId,
        selectedWorkflowId: query.id,
        selectedType:
          query.owner.id === userId
            ? WORKFLOW_SELECTED_TYPE.LIBRARY_EDIT
            : WORKFLOW_SELECTED_TYPE.LIBRARY_READONLY
      })
    );
  };
}
