import BaseClient from './BaseClient';

const URL_PREFIX = 'supporting_resources';

export default class SupportingResourcesClient extends BaseClient {
  getSupportingResources() {
    return this.get(URL_PREFIX);
  }

  fetchSupportingResource(id) {
    return this.get(`${URL_PREFIX}/${id}`);
  }

  submitSupportingResource(resource) {
    return this.post(URL_PREFIX, resource);
  }

  updateSupportingResourceData(id, paylad) {
    return this.put(`${URL_PREFIX}/${id}`, paylad);
  }

  deleteSupportingResource(id) {
    return this.httpDelete(`${URL_PREFIX}/${id}`);
  }

  downloadSupportingResourceFile(id) {
    return this.get(`${URL_PREFIX}/${id}/download_file`, 'files');
  }
}
