import React, { forwardRef } from 'react';

interface DraggableItemOverlayProps {
  id: number | string;
  children: React.ReactNode;
}

const DraggableItemOverlay = forwardRef<HTMLDivElement, DraggableItemOverlayProps>(
  ({ children }, ref) => {
    return <div ref={ref}>{children}</div>;
  }
);

DraggableItemOverlay.displayName = 'DraggableItemOverlay';

export default DraggableItemOverlay;
