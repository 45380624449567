import { Box, Divider, Drawer, DrawerProps, Stack, Toolbar } from '@mui/material';
import React from 'react';

import MLSidebarHeader from './MLSidebarHeader';

interface MLSidebarProps extends DrawerProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  id?: string;
  children?: React.ReactNode;
  isBackButton?: boolean;
  onBack?: () => void;
}

export default function MLSidebar({
  id = 'sidebar',
  open,
  setOpen,
  title,
  isBackButton,
  onBack,
  children,
  ...drawerProps
}: MLSidebarProps) {
  const close = () => {
    setOpen(false);
  };

  return (
    <Drawer
      anchor="right"
      id={id}
      open={open}
      ModalProps={{
        hideBackdrop: true
      }}
      PaperProps={{
        sx: { overflow: 'hidden' }
      }}
      variant="persistent"
      sx={{
        zIndex: 'mlSideBar'
      }}
      {...drawerProps}>
      <Stack sx={{ height: '100%', display: 'flex' }}>
        <Toolbar
          sx={{
            '@media all': {
              minHeight: 50
            }
          }}
        />
        <MLSidebarHeader
          title={title}
          isBackButton={isBackButton}
          onBack={onBack}
          onClose={close}
        />
        <Divider />
        <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>{children}</Box>
      </Stack>
    </Drawer>
  );
}
