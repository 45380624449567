import { usePusher } from 'hooks/PusherProvider';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearChannelSubscriptions } from 'reducer/pusher-slice';
import { getActiveSubscriptions } from 'selectors/pusher';

function PuhserChannelsManager() {
  const pusher = usePusher();
  const reduxDisaptch = useDispatch();

  const allChannelsActiveSubscriptions = useSelector((state) => getActiveSubscriptions(state));
  useEffect(() => {
    Object.keys(allChannelsActiveSubscriptions).forEach((channelName) => {
      if (allChannelsActiveSubscriptions[channelName] <= 0) {
        reduxDisaptch(clearChannelSubscriptions({ channelName }));
        pusher.unsubscribe(channelName);
      }
    });
  }, [allChannelsActiveSubscriptions, pusher, reduxDisaptch]);

  return null;
}

export default PuhserChannelsManager;
