import { Box, Collapse, Stack } from '@mui/material';
import ArrowIcon from 'icons/ArrowIcon';
import PropTypes from 'prop-types';
import React from 'react';
import { PPTX_CLASS_NAMES } from 'utils/react-to-powerpoint-utils';

function DomainReportClusterExpandable({ titleComponent, disabled, children }) {
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandedChange = () => setExpanded(!expanded);
  return (
    <Stack className={PPTX_CLASS_NAMES.slideQuery}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap="4px"
        onClick={disabled ? null : handleExpandedChange}
        sx={{
          color: disabled ? 'disabled-text-color' : 'colors.primary_text',
          pageBreakInside: 'avoid',
          cursor: disabled ? null : 'pointer'
        }}>
        {titleComponent}
        <Box
          sx={{
            transform: expanded ? 'rotate(0deg)' : 'rotate(180deg)'
          }}>
          <ArrowIcon />
        </Box>
      </Stack>
      <Collapse in={expanded} className={PPTX_CLASS_NAMES.ignoreHidden}>
        <Stack gap="16px">{children}</Stack>
      </Collapse>
    </Stack>
  );
}

DomainReportClusterExpandable.propTypes = {
  titleComponent: PropTypes.node,
  children: PropTypes.node,
  disabled: PropTypes.bool
};

export default DomainReportClusterExpandable;
