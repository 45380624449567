import React from 'react';

export default function OrganizationIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 5H6C5.44772 5 5 5.44772 5 6L5 19H14V6C14 5.44772 13.5523 5 13 5ZM6 4C4.89543 4 4 4.89543 4 6V19C4 19.5523 4.44771 20 5 20H15V6C15 4.89543 14.1046 4 13 4H6Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 13H15V19H19V13ZM14 12V20H19C19.5523 20 20 19.5523 20 19V13C20 12.4477 19.5523 12 19 12H14Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 17H8.5V19H10.5V17ZM8.5 16C7.94772 16 7.5 16.4477 7.5 17V20H11.5V17C11.5 16.4477 11.0523 16 10.5 16H8.5Z"
        fill="currentColor"
      />
      <path
        d="M11 11.5C11 11.2239 11.2239 11 11.5 11C11.7761 11 12 11.2239 12 11.5L12 13.5C12 13.7761 11.7761 14 11.5 14C11.2239 14 11 13.7761 11 13.5L11 11.5Z"
        fill="currentColor"
      />
      <path
        d="M7 11.5C7 11.2239 7.22386 11 7.5 11C7.77614 11 8 11.2239 8 11.5L8 13.5C8 13.7761 7.77614 14 7.5 14C7.22386 14 7 13.7761 7 13.5L7 11.5Z"
        fill="currentColor"
      />
      <path
        d="M11 7.5C11 7.22386 11.2239 7 11.5 7C11.7761 7 12 7.22386 12 7.5L12 9.5C12 9.77614 11.7761 10 11.5 10C11.2239 10 11 9.77614 11 9.5L11 7.5Z"
        fill="currentColor"
      />
      <path
        d="M7 7.5C7 7.22386 7.22386 7 7.5 7C7.77614 7 8 7.22386 8 7.5L8 9.5C8 9.77614 7.77614 10 7.5 10C7.22386 10 7 9.77614 7 9.5L7 7.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
