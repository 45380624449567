import { SECTIONS_DEFAULT_PRESENTATION_PROPS } from 'constants/domain-reports';
import {
  DomainReport,
  DomainReportSection,
  DomainReportSectionProps,
  DomainReportStatus
} from 'models/domain-report/types';
import React, { createContext, useContext } from 'react';
import { useSelector } from 'react-redux';
import { getLatestDRStatusByListId, getLatestDomainReportByListId } from 'selectors/domain-reports';

import { useIsDomainReportOutdated } from '../../hooks/useIsDomainReportOutdated';

const DomainReportContext = createContext({
  outdated: false,
  listId: 0,
  domainReport: {} as DomainReport,
  sections: [] as DomainReportSection[],
  status: DomainReportStatus.SUCCEEDED as DomainReportStatus
});

interface DomainReportProviderProps {
  children: React.ReactNode;
  listId: number;
}

export const DomainReportProvider = ({ children, listId }: DomainReportProviderProps) => {
  const outdated = useIsDomainReportOutdated(listId);
  const domainReport = useSelector((state) => getLatestDomainReportByListId(state, listId));
  const status = useSelector((state) => getLatestDRStatusByListId(state, listId));

  const sectionsProps = (domainReport?.parameters?.sections || []) as DomainReportSectionProps[];
  const sections = sectionsProps.map((sectionProps) => ({
    props: sectionProps,
    presentationProps: SECTIONS_DEFAULT_PRESENTATION_PROPS[sectionProps.type]
  }));

  return (
    <DomainReportContext.Provider value={{ outdated, listId, domainReport, sections, status }}>
      {children}
    </DomainReportContext.Provider>
  );
};

export const useDomainReportContext = () => {
  return useContext(DomainReportContext);
};
