import { Box, Collapse, Stack, Typography } from '@mui/material';
import ArrowIcon from 'icons/ArrowIcon';
import PropTypes from 'prop-types';
import React from 'react';
import { PPTX_CLASS_NAMES } from 'utils/react-to-powerpoint-utils';

function TopProblemExpandable({ title, description, disabled, children }) {
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandedChange = () => setExpanded(!expanded);
  return (
    <Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap="12px"
        onClick={disabled ? null : handleExpandedChange}
        sx={{
          color: disabled ? 'disabled-text-color' : 'colors.primary_text',
          cursor: disabled ? null : 'pointer'
        }}>
        <Stack gap="8px">
          <Typography variant="h3_Bold" color="colors.primary">
            {title}
          </Typography>
          <Typography variant="text1_Normal" color="colors.primary_text">
            {description}
          </Typography>
        </Stack>
        <Box
          sx={{
            transform: expanded ? 'rotate(0deg)' : 'rotate(180deg)'
          }}>
          <ArrowIcon />
        </Box>
      </Stack>
      <Collapse in={expanded} className={PPTX_CLASS_NAMES.ignoreHidden}>
        <Stack sx={{ marginTop: '24px' }} gap="16px">
          {children}
        </Stack>
      </Collapse>
    </Stack>
  );
}

TopProblemExpandable.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  description: PropTypes.string,
  disabled: PropTypes.bool
};

export default TopProblemExpandable;
