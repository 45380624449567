import { Button, Stack, TextField, Typography } from '@mui/material';
import ModalComponent from 'components/modal/ModalComponent';
import React from 'react';
import { appTheme } from 'theme';

interface RenameColumnDialogProps {
  open: boolean;
  onClose: () => void;
  columnName: string;
  newName: string;
  onNameChange: (value: string) => void;
  onRename: () => void;
}

export function RenameColumnDialog({
  open,
  onClose,
  newName,
  onNameChange,
  onRename
}: RenameColumnDialogProps) {
  return (
    <ModalComponent
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      title="Rename column name"
      maxWidth={400}
      bottomBar={
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: '100%', padding: '8px' }}>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={onRename}>
            Change
          </Button>
        </Stack>
      }>
      <Stack direction="column" gap="32px" padding="24px">
        <Typography variant="text1_Medium">Set the new column name</Typography>
        <TextField
          variant="outlined"
          placeholder="Write new column name here..."
          value={newName}
          onChange={(event) => onNameChange(event.target.value)}
          sx={{
            input: {
              color: 'colors.primary_text'
            },
            paddingLeft: '24px',
            backgroundColor: 'transparent',
            ...appTheme.typography.text1_Normal
          }}
        />
      </Stack>
    </ModalComponent>
  );
}
