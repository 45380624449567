import { fetchCustomColumnOfListAction } from 'actions/company_lists/fetch-custom-column-of-list-action';
import { updateSubdomainIdInRecordAction } from 'actions/domain-research/update-subdomain-in-record-action';
import { AssistantResponse, PostAssistantPayload } from 'models/assistant/api';
import { ActionType } from 'models/assistant/assistant-actions';
import {
  addAssistantUtilityCompanyMetaIdsToFetch,
  addErrorMessageToThread,
  processAssistantResponse,
  updateActionAfterSubmission,
  updateActionSubmissionInfo,
  updateAssistantUtility
} from 'reducer/assistant-slice';
import { getCompanyMetasByListId } from 'selectors/explore';
import {
  getExploreSelectedCompanyMetaIds,
  getListOrderedRenderdMetaIdsByListId
} from 'selectors/explore-table-ui';
import { AppDispatch } from 'types/store';

export type ExtraParams = {
  subdomainId?: number;
  onResponse?: (response: AssistantResponse) => void;
} | null;

function getSelectedMetaIds(state: any, listId: number, actionType: ActionType) {
  let selected_company_meta_ids: null | number[] = null;
  const selectedCompanyMetaIds = (getExploreSelectedCompanyMetaIds as any)(state, listId);
  if (selectedCompanyMetaIds) {
    selected_company_meta_ids = selectedCompanyMetaIds;
  }

  if (actionType === ActionType.PREVIEW_COLUMN) {
    const orderedRenderdMetaIds = getListOrderedRenderdMetaIdsByListId(state, listId) || null;
    if (orderedRenderdMetaIds) {
      if (selected_company_meta_ids) {
        selected_company_meta_ids = orderedRenderdMetaIds.filter((id: number) =>
          (selected_company_meta_ids as number[]).includes(id)
        );
      }
      selected_company_meta_ids = (selected_company_meta_ids || orderedRenderdMetaIds).slice(0, 5);
    }
  }
  return selected_company_meta_ids;
}
export function submitAssistantHelperAction(
  threadId: string,
  payload: PostAssistantPayload,
  extraParams?: ExtraParams
) {
  return (dispatch: AppDispatch, getState: any, apiClient: any) => {
    const state = getState();
    const listId = payload.company_list_id;
    const newPayload = { ...payload };
    const originalCompanyMetaIds: number[] = (getCompanyMetasByListId(state, listId) || []).map(
      (meta: any) => meta.company_meta_id as number
    );
    if (listId) {
      const lastMessage = payload.messages?.slice(-1)?.[0];
      const actionType = (lastMessage?.content as any)?.action_type;
      const selected_company_meta_ids = getSelectedMetaIds(state, listId, actionType);
      if (selected_company_meta_ids) {
        newPayload.selected_company_meta_ids = selected_company_meta_ids;
      }
    }
    dispatch(updateAssistantUtility({ showLoading: true }));

    setTimeout(() => {
      dispatch(updateAssistantUtility({ scrollToEnd: true }));
    }, 500);

    apiClient.assistant
      .submit(newPayload)
      .then((res: any) => {
        if (res.ok && res.data) {
          const response = res.data as AssistantResponse;
          extraParams?.onResponse?.(response);

          if (
            payload.action &&
            response.action &&
            response.action.action_type === payload.action.action_type
          ) {
            dispatch(
              updateActionAfterSubmission({
                threadId,
                response,
                submittedActionId: payload.action.id
              })
            );
          } else {
            dispatch(processAssistantResponse({ threadId, response }));
          }
          if (listId && response.submission_info?.submitted && response.submission_info?.view_id) {
            dispatch(
              updateAssistantUtility({ navigateToViewId: response.submission_info.view_id })
            );
          }
          if (
            listId &&
            response.submission_info?.submitted &&
            response.submission_info?.company_meta_ids &&
            response.submission_info?.company_meta_ids.length > 0
          ) {
            const alreadyCompanyMetaIds = response.submission_info.company_meta_ids.filter((id) =>
              originalCompanyMetaIds.includes(id)
            );
            const newCompanyMetaIds = response.submission_info.company_meta_ids.filter(
              (id) => !originalCompanyMetaIds.includes(id)
            );
            if (newCompanyMetaIds.length > 0) {
              dispatch(
                addAssistantUtilityCompanyMetaIdsToFetch({
                  companyMetaIds: newCompanyMetaIds
                })
              );
            }
            const actionId = payload?.action?.id || response?.action?.id;
            if (actionId && alreadyCompanyMetaIds.length > 0) {
              dispatch(
                updateActionSubmissionInfo({
                  threadId,
                  actionId,
                  submissionInfo: {
                    ...response.submission_info,
                    already_company_meta_ids: alreadyCompanyMetaIds
                  }
                })
              );
            }
          }

          if (
            response.submission_info?.submitted &&
            extraParams?.subdomainId &&
            response.submission_info?.record_id
          ) {
            dispatch(
              updateSubdomainIdInRecordAction(
                listId,
                extraParams.subdomainId,
                response.submission_info.record_id
              )
            );
          }

          if (
            listId &&
            response.submission_info?.submitted &&
            response.submission_info?.column_id
          ) {
            dispatch(
              fetchCustomColumnOfListAction(listId, response.submission_info.column_id, true)
            );
          }
        } else {
          dispatch(
            addErrorMessageToThread({
              threadId,
              errorMessage: 'Error submitting message. Try again.'
            })
          );
        }
        dispatch(updateAssistantUtility({ showLoading: false }));
      })
      .catch(() => {
        dispatch(
          addErrorMessageToThread({
            threadId,
            errorMessage: 'Error submitting message. Try again.'
          })
        );
        dispatch(updateAssistantUtility({ showLoading: false }));
      });
  };
}
