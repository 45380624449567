import { FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { submitDomainReportTaskAction } from 'actions/explore/submit-domain-report-task-action';
import MLButton from 'design-system/MLButton';
import MLInlineStack from 'design-system/MLInlineStack';
import { DomainReportEditQueryType, DomainReportSection } from 'models/domain-report/types';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getDomainReportTaskDefaultConfig,
  getLatestDRStatusByListId,
  getLatestDRTaskByListId,
  getLatestDomainReportIdByListId
} from 'selectors/domain-reports';
import { useAppDispatch } from 'types/store';
import { NULL_PROMPT_CONFIG, PromptConfig } from 'utils/custom-analytics-utils';
import { isTaskPromptConfigEqual, promptConfigToTaskParams } from 'utils/domain-report-utils';
import { determineErrorStatus, promptToPromptConfig } from 'utils/prompts-utils';

import { useDomainReportContext } from '../../DomainReportContext';
import DomainReportEditorConfig from '../edit/DomainReportEditorConfig';
import DomainReportEditorDefaultConfig from '../edit/DomainReportEditorDefaultConfig';

interface DomainReportEditSectionViewProps {
  section: DomainReportSection;
  resetView: () => void;
}

const getPromptConfig = (config: PromptConfig): PromptConfig => {
  if (config.simplified_question) {
    const promptConfig = promptToPromptConfig(config.simplified_question) as PromptConfig;
    promptConfig.isSimplified = true;
    return promptConfig;
  }
  return promptToPromptConfig(config.question);
};

export default function DomainReportEditSectionView({
  section,
  resetView
}: DomainReportEditSectionViewProps) {
  const dispatch = useAppDispatch();
  const { listId } = useDomainReportContext();
  const { customAnalyticsTaskType } = section.presentationProps;
  const { sections } = useDomainReportContext();
  const status = useSelector((state) => getLatestDRStatusByListId(state, listId));
  const { parameters: config } =
    useSelector((state) => {
      const task = getLatestDRTaskByListId(state, listId, customAnalyticsTaskType);
      return task && task.parameters ? task : NULL_PROMPT_CONFIG;
    }) || NULL_PROMPT_CONFIG;
  const defaultConfig =
    useSelector((state) => getDomainReportTaskDefaultConfig(state, customAnalyticsTaskType)) ||
    NULL_PROMPT_CONFIG;

  const domainReportId = useSelector((state) => getLatestDomainReportIdByListId(state, listId));
  const customPromptConfig = getPromptConfig(config);
  const defaultPromptConfig = getPromptConfig(defaultConfig);
  const isDefault = isTaskPromptConfigEqual(defaultPromptConfig, customPromptConfig);
  const [queryType, setQueryType] = useState<DomainReportEditQueryType>(
    DomainReportEditQueryType.DEFAULT
  );
  const [edited, setEdited] = useState(false);
  const [localPromptConfig, setLocalPromptConfig] = useState<PromptConfig | null>(
    customPromptConfig
  );

  const errorStatus = useMemo(
    () => (localPromptConfig ? determineErrorStatus(localPromptConfig) : null),
    [localPromptConfig]
  );

  const resetLocalPromptConfig = () => {
    setLocalPromptConfig(customPromptConfig);
    setEdited(false);
  };

  useEffect(() => {
    if (!edited) {
      setQueryType(
        isDefault ? DomainReportEditQueryType.DEFAULT : DomainReportEditQueryType.CUSTOM
      );
    }
  }, [edited, isDefault, setQueryType]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!edited) {
      setEdited(true);
    }
    setQueryType(event.target.value as DomainReportEditQueryType);
  };
  const handleSave = () => {
    if (!domainReportId) {
      return;
    }
    let taskPromptConfig = null;
    if (queryType === DomainReportEditQueryType.DEFAULT) {
      // Retrigger default
      taskPromptConfig = defaultPromptConfig;
      resetLocalPromptConfig();
    } else {
      // Save custom
      taskPromptConfig = localPromptConfig;
    }
    const parameters = promptConfigToTaskParams(taskPromptConfig);
    const sectionToUpdate = sections.find(
      (reduxSection) => reduxSection.props.type === section.props.type
    );
    if (sectionToUpdate) {
      dispatch(
        submitDomainReportTaskAction({
          listId,
          domainReportId,
          section: sectionToUpdate,
          parameters
        })
      );
    }
  };
  const closeAndReset = () => {
    resetLocalPromptConfig();
    resetView();
  };

  const isError = Boolean(errorStatus && errorStatus.length > 0);
  return (
    <Stack textAlign="left" sx={{ height: '100%' }}>
      <Stack
        gap="32px"
        padding="24px"
        sx={{ flexGrow: 1, overflowY: 'auto', scrollbarGutter: 'stable' }}>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
            value={queryType}
            onChange={handleChange}
            row>
            <FormControlLabel
              value={DomainReportEditQueryType.DEFAULT}
              control={<Radio />}
              label={<Typography variant="text1_Medium">Default Settings</Typography>}
            />
            <FormControlLabel
              value={DomainReportEditQueryType.CUSTOM}
              control={<Radio />}
              label={<Typography variant="text1_Medium">Custom</Typography>}
            />
          </RadioGroup>
        </FormControl>
        {queryType === DomainReportEditQueryType.DEFAULT && defaultPromptConfig && (
          <DomainReportEditorDefaultConfig promptConfig={defaultPromptConfig} />
        )}
        {queryType === DomainReportEditQueryType.CUSTOM && localPromptConfig && (
          <DomainReportEditorConfig
            listId={listId}
            localPromptConfig={localPromptConfig}
            setLocalPromptConfig={setLocalPromptConfig}
          />
        )}
      </Stack>
      <MLInlineStack
        padding="24px"
        gap="8px"
        justifyContent="flex-end"
        sx={{ position: 'sticky', bottom: 0, backgroundColor: 'white' }}>
        <MLButton variant="outlined" size="large" onClick={closeAndReset}>
          Cancel
        </MLButton>
        <MLButton
          variant="contained"
          size="large"
          onClick={handleSave}
          disabled={isError || status === 'RUNNING'}>
          Save and regenerate
        </MLButton>
      </MLInlineStack>
    </Stack>
  );
}
