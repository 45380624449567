import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { launchWorkflowAction } from 'actions/workflows/launch-workflow-action';
import SaveQueryDialog from 'components/discovery/SaveQueryDialog';
import { EXPLORE_PREFIX } from 'constants/app-routes';
import { WORKFLOW_STATUSES } from 'constants/workflows';
import MLIconButton from 'design-system/MLIconButton';
import LinkIcon from 'design-system/icons/basic/LinkIcon';
import MenuIcon from 'icons/MenuIcon';
import RefreshIcon from 'icons/RefreshIcon';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getWorkflowStatusByListIdAndWorkflowId } from 'selectors/workflows';

function WorkflowsCenterDialogRowMenu({ query, listId, workflowId, closeWorkflowCenter }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(false);
  const nestedComponentRef = useRef();

  const workflowStatus = useSelector((state) =>
    getWorkflowStatusByListIdAndWorkflowId(state, listId, workflowId)
  );
  const plan = query.parameters.workflow;
  const viewId = query.parameters.view_id;
  const recordStatus = query?.statuses?.workflow;
  const isStopped = recordStatus === 'STOPPED' || workflowStatus === WORKFLOW_STATUSES.STOPED;
  const isFailed = recordStatus === 'FAILED' || workflowStatus === WORKFLOW_STATUSES.HISTORY_FAILED;
  const lastStepId = query?.parameters?.last_step_id;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleRelaunch = (event) => {
    event.stopPropagation();
    dispatch(launchWorkflowAction(listId, plan, plan?.name, workflowId, true));
    handleClose();
  };

  const handleRelaunchFromStep = (event) => {
    event.stopPropagation();
    dispatch(launchWorkflowAction(listId, plan, plan?.name, workflowId, true, lastStepId));
    handleClose();
  };

  const gotoWorkflowView = (event) => {
    event.stopPropagation();
    handleClose();
    navigate(`${EXPLORE_PREFIX}/${listId}/views/${viewId}`);
    closeWorkflowCenter();
  };

  const menuItems = [
    {
      id: 'save',
      onClick: (event) => {
        event.stopPropagation();
        if (nestedComponentRef.current) {
          nestedComponentRef.current.notifyFromParent();
        }
      },
      startIcon: (
        <SaveQueryDialog
          prompt={{ ...plan, saved_query_id: query.savedQueryId }}
          existColumnName={query?.parameters?.workflow?.name || query.name}
          isSaved={false}
          listId={listId}
          ref={nestedComponentRef}
          showIsBookmarked={false}
          isWorkflow={true}
        />
      ),
      text: 'Save workflow'
    },
    {
      id: 'relaunch',
      text: 'Relaunch',
      startIcon: <RefreshIcon />,
      onClick: handleRelaunch,
      hidden: !plan?.id
    },
    {
      id: 'relaunch-from-step',
      text: isStopped ? 'Relaunch from stopped' : 'Relaunch from failed',
      startIcon: <RefreshIcon />,
      onClick: handleRelaunchFromStep,
      hidden: !(isStopped || isFailed) || !lastStepId
    },
    {
      id: 'open-save-view',
      text: 'Open this workflow view',
      startIcon: <LinkIcon />,
      onClick: gotoWorkflowView,
      hidden: !viewId
    }
  ];

  return (
    <React.Fragment>
      <MLIconButton sx={{ padding: 0 }} onClick={handleOpen}>
        <MenuIcon color="currentColor" />
      </MLIconButton>

      <Menu
        MenuListProps={{ sx: { py: 0 } }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {menuItems
          .filter(({ hidden }) => !hidden)
          .map(({ id, startIcon, onClick, text, getText }) => (
            <MenuItem key={id} onClick={onClick}>
              <ListItemIcon sx={{ justifyContent: 'center' }}>{startIcon}</ListItemIcon>
              <ListItemText>{text || getText()}</ListItemText>
            </MenuItem>
          ))}
      </Menu>
    </React.Fragment>
  );
}

WorkflowsCenterDialogRowMenu.propTypes = {
  listId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  workflowId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  query: PropTypes.object,
  closeWorkflowCenter: PropTypes.func
};

export default WorkflowsCenterDialogRowMenu;
