import FileUploadProgressComponent from 'components/FileUploadProgressComponent';
import OrganizationContextFetcher from 'components/OrganizationContextFetcher';
import ProgressMonitoringComponent from 'components/ProgressMonitoringComponent';
import TopbarComponent from 'components/TopbarComponent';
import PuhserChannelsManager from 'components/pusher/PusherChannelsManager';
import PusherGeneralListener from 'components/pusher/PusherGeneralListener';
import PusherHomePageListener from 'components/pusher/PusherHomepageListener';
import ActiveRoutes from 'components/routers/ActiveRoutes';
import { PUBLIC_ROUTES_PATHS, ROUTES_PATHS } from 'constants/app-routes';
import { OnErrorProvider } from 'hooks/OnErrorProvider';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import AdminScreen from 'screens/AdminScreen';
import CardScreen from 'screens/CardScreen';
import CompanyInsightsRunScreen from 'screens/CompanyInsightsRunScreen';
import DevScreen from 'screens/DevScreen';
import DiscoveryRunBaseScreen from 'screens/DiscoveryRunBaseScreenConnector';
import HomePageScreen from 'screens/HomePageScreen';
import MonitoringsScreen from 'screens/MonitoringsScreen';
import OrganizationScreen from 'screens/OrganizationScreen';
import ProfileScreen from 'screens/ProfileScreen';
import PublicShareScreen from 'screens/PublicShareScreen';
import RedirectToHomeScreen from 'screens/RedirectToHomeScreen';
import TeamScreen from 'screens/TeamScreen';

function AuthorizedRouter() {
  return (
    <Router>
      <TopbarComponent />
      <OnErrorProvider>
        <ActiveRoutes>
          <Route path={ROUTES_PATHS.MAIN_PATH} element={<HomePageScreen />} />
          <Route path={ROUTES_PATHS.FOLDER_PATH} element={<HomePageScreen />} />
          <Route
            path={ROUTES_PATHS.COMPANY_INSIGHTS_RUN_PATH}
            element={<CompanyInsightsRunScreen />}
          />
          <Route path={ROUTES_PATHS.CARD_PATH} element={<CardScreen />} />
          <Route path={ROUTES_PATHS.ADMIN_PATH} element={<AdminScreen />} />
          <Route path={ROUTES_PATHS.DEV_PATH} element={<DevScreen />} />
          <Route path={ROUTES_PATHS.TEAM_PATH} element={<TeamScreen />} />
          <Route path={ROUTES_PATHS.PROFILE_PATH} element={<ProfileScreen />} />
          <Route path={ROUTES_PATHS.ORGANIZATION_PATH} element={<OrganizationScreen />} />
          <Route path={ROUTES_PATHS.EXPLORE_RUN_PATH} element={<DiscoveryRunBaseScreen />} />
          <Route path={ROUTES_PATHS.EXPLORE_VIEW_PATH} element={<DiscoveryRunBaseScreen />} />
          <Route path={ROUTES_PATHS.MONITORINGS_PATH} element={<MonitoringsScreen />} />
          {/* legacy route redirects  */}
          <Route path={ROUTES_PATHS.COMPANY_INSIGHTS_PATH} element={<RedirectToHomeScreen />} />
          <Route path={ROUTES_PATHS.PEOPLE_INSIGHTS_PATH} element={<RedirectToHomeScreen />} />
          <Route path={ROUTES_PATHS.EXPLORE_PATH} element={<RedirectToHomeScreen />} />
          <Route path={ROUTES_PATHS.LEGACY_DISCOVERY_PATH} element={<RedirectToHomeScreen />} />
          <Route path={ROUTES_PATHS.LEGACY_DISCOVERY_RUN_PATH} element={<RedirectToHomeScreen />} />
          <Route
            path={ROUTES_PATHS.LEGACY_DISCOVERY_VIEW_PATH}
            element={<RedirectToHomeScreen />}
          />
          {/* authorized public routes  */}
          <Route
            path={PUBLIC_ROUTES_PATHS.PUBLIC_SHARE_PATH}
            element={<PublicShareScreen noTopBar={true} />}
          />
          <Route
            path={PUBLIC_ROUTES_PATHS.PUBLIC_SHARE_TAB_PATH}
            element={<PublicShareScreen noTopBar={true} />}
          />
        </ActiveRoutes>
      </OnErrorProvider>
      <PuhserChannelsManager />
      <ProgressMonitoringComponent />
      <FileUploadProgressComponent />
      <PusherGeneralListener />
      <PusherHomePageListener />
      <OrganizationContextFetcher />
    </Router>
  );
}

export default AuthorizedRouter;
