import React from 'react';

export default function ChevronLeftIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.17193 16.0653C8.87904 16.3582 8.87904 16.833 9.17193 17.1259C9.46483 17.4188 9.9397 17.4188 10.2326 17.1259L14.2983 13.0602L14.6519 12.7067C15.0425 12.3162 15.0426 11.683 14.6521 11.2924L14.2985 10.9387L10.2326 6.87308C9.9397 6.5802 9.46483 6.58021 9.17194 6.8731C8.87904 7.16599 8.87904 7.64087 9.17194 7.93377L13.2377 11.9995L9.17193 16.0653Z"
        fill="currentColor"
      />
    </svg>
  );
}
