import React from 'react';

export default function NewAssistantChatGraphic() {
  return (
    <svg
      width="126"
      height="119"
      viewBox="0 0 126 119"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.8">
        <path d="M67.876 66.6842L96.6775 39.8464" stroke="#BCD7FE" />
        <path d="M67.2213 66.0297L60.6755 10.3904" stroke="#BCD7FE" />
        <g filter="url(#filter0_d_3037_74721)">
          <circle cx="60.7953" cy="11.7953" r="5.79526" fill="white" />
        </g>
        <g filter="url(#filter1_d_3037_74721)">
          <circle cx="93.4046" cy="42.4646" r="13.0916" fill="white" />
        </g>
        <path d="M16.5001 83.5001L26.0001 50.0001" stroke="#BCD7FE" />
        <path d="M33.1832 48.356L64.6031 65.375" stroke="#BCD7FE" />
        <g filter="url(#filter2_d_3037_74721)">
          <circle cx="66.5668" cy="66.6841" r="7.20038" fill="white" />
        </g>
        <g filter="url(#filter3_d_3037_74721)">
          <circle cx="27.292" cy="45.7376" r="9.8187" fill="white" />
        </g>
        <g filter="url(#filter4_d_3037_74721)">
          <circle cx="16.1641" cy="84.3578" r="9.16412" fill="white" />
        </g>
        <rect
          x="90.4175"
          y="87.0734"
          width="5.23664"
          height="10.4733"
          transform="rotate(-45 90.4175 87.0734)"
          fill="#D2CCFF"
        />
        <circle
          cx="73.7541"
          cy="66.7073"
          r="25.5286"
          transform="rotate(-45 73.7541 66.7073)"
          fill="#F8F9FF"
          fillOpacity="0.6"
          stroke="url(#paint0_linear_3037_74721)"
          strokeWidth="3.92748"
        />
        <path
          d="M96.0493 96.3302C94.9903 95.3145 94.9727 93.6268 96.0103 92.5892L99.7124 88.8871C100.72 87.8796 102.348 87.8627 103.376 88.8489L124.07 108.698C125.13 109.713 125.147 111.401 124.109 112.439L120.407 116.141C119.4 117.148 117.772 117.165 116.744 116.179L96.0493 96.3302Z"
          fill="url(#paint1_linear_3037_74721)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_3037_74721"
          x="48"
          y="0"
          width="25.5906"
          height="25.5906"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="3.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0905083 0 0 0 0 0.00663216 0 0 0 0 0.845394 0 0 0 0.09 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3037_74721" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3037_74721"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_3037_74721"
          x="73.313"
          y="23.373"
          width="40.1832"
          height="40.1832"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="3.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0905083 0 0 0 0 0.00663216 0 0 0 0 0.845394 0 0 0 0.09 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3037_74721" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3037_74721"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_3037_74721"
          x="52.3665"
          y="53.4838"
          width="28.4008"
          height="28.4008"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="3.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0905083 0 0 0 0 0.00663216 0 0 0 0 0.845394 0 0 0 0.09 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3037_74721" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3037_74721"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d_3037_74721"
          x="10.4733"
          y="29.9189"
          width="33.6375"
          height="33.6375"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="3.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0905083 0 0 0 0 0.00663216 0 0 0 0 0.845394 0 0 0 0.09 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3037_74721" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3037_74721"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_d_3037_74721"
          x="0"
          y="69.1937"
          width="32.3282"
          height="32.3282"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="3.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0905083 0 0 0 0 0.00663216 0 0 0 0 0.845394 0 0 0 0.09 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3037_74721" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3037_74721"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_3037_74721"
          x1="73.7541"
          y1="39.2149"
          x2="73.7541"
          y2="94.1996"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#8666FE" />
          <stop offset="1" stopColor="#D1C5FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3037_74721"
          x1="98.0727"
          y1="90.5268"
          x2="122.047"
          y2="114.501"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#B6A3FE" />
          <stop offset="1" stopColor="#8362FF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
