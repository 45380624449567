import { updateAllExtraDataForCompaniesInListAction } from 'actions/company_extra_data/update-extra-data-for-companies-in-list-action';
import { RESOURCE_TYPES } from 'constants/resources';
import { addNewCompanyMetas } from 'reducer/explore-slice';
import { isForbidden } from 'utils/error-utils';

import { translateListRecordForState } from './load-all-explores-action';

export function addNewCompanyMetasToList(
  companyListId,
  forbiddenResource,
  companyMetaIds,
  updateExtraData = true,
  filtersBypass = false
) {
  return (dispatch, _getState, apiClient) => {
    apiClient.explore
      .fetchPartialExploreList(companyListId, companyMetaIds)
      .then((res) => {
        if (res.ok && res.data) {
          const exploreData = translateListRecordForState(res.data);
          dispatch(addNewCompanyMetas({ listId: companyListId, data: exploreData, filtersBypass }));
          if (updateExtraData) {
            dispatch(updateAllExtraDataForCompaniesInListAction(companyListId));
          }
        } else if (isForbidden(res.status) && forbiddenResource) {
          forbiddenResource(companyListId, RESOURCE_TYPES.LIST);
        }
      })
      .catch(() => {
        if (forbiddenResource) {
          forbiddenResource(companyListId, RESOURCE_TYPES.LIST);
        }
      });
  };
}
