import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { ACTION_TYPE_TO_LABEL } from 'constants/assistant';
import MLInlineStack from 'design-system/MLInlineStack';
import InfoIcon from 'design-system/icons/basic/InfoIcon';
import { ApiAssistantMessage } from 'models/assistant/api';
import { MessageRole } from 'models/assistant/types';
import React from 'react';
import { formatTimestamp } from 'utils/time-utils';

interface AssistantAdminMessageProps {
  message: ApiAssistantMessage;
}
export default function AssistantAdminMessage({ message }: AssistantAdminMessageProps) {
  return (
    <Stack
      gap="4px"
      sx={{
        padding: '4px',
        paddingX: '8px',
        borderRadius: '4px',
        backgroundColor: 'colors.gray_bg'
      }}>
      <MLInlineStack justifyContent="space-between">
        <MLInlineStack>
          <Typography variant="text1_Semibold">{message.role.toUpperCase()}:</Typography>
          {message.action_type && (
            <Typography color="colors.link">{ACTION_TYPE_TO_LABEL[message.action_type]}</Typography>
          )}
          {message.role === MessageRole.ASSISTANT &&
            message.action_type &&
            message.submission_info &&
            !message.submission_info.submitted && (
              <Typography>
                {' '}
                <em>- asking for confirmation</em>
              </Typography>
            )}
          {message.role === MessageRole.ASSISTANT &&
            message.action_type &&
            message.submission_info &&
            message.submission_info.submitted && (
              <Typography>
                {' '}
                <em>- confirmed by user</em>
              </Typography>
            )}
        </MLInlineStack>
        <MLInlineStack>
          <Typography>{formatTimestamp(message.time_submitted)} </Typography>
          <Tooltip
            title={
              message.action_params ? (
                <pre>{JSON.stringify(message.action_params, null, 2)}</pre>
              ) : (
                'No params'
              )
            }
            arrow>
            <Box>
              <InfoIcon />
            </Box>
          </Tooltip>
        </MLInlineStack>
      </MLInlineStack>
      <Typography>{message.text}</Typography>
    </Stack>
  );
}
