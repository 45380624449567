import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { Button, Stack, Typography } from '@mui/material';
import { getAPIClient } from 'api/client-api';
import { EXPLORE_PREFIX } from 'constants/app-routes';
import MLButton from 'design-system/MLButton';
import MLInlineStack from 'design-system/MLInlineStack';
import MLModal from 'design-system/MLModal';
import { ApiClient } from 'models/api/types';
import {
  ApiAssistantThreadsResponse,
  ApiThread,
  FetchThreadsForAdminParams
} from 'models/assistant/api';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router';
import { formatTimestamp } from 'utils/time-utils';

import AssistantAdminCompanyListSelector from './AssistantAdminCompanyListSelector';
import AssistantAdminConversation from './AssistantAdminConversation';

interface AssistantAdminTabProps {
  userId?: number | null;
  organizationId?: number | null;
}

export const ALL_LISTS_VALUE = 0;

function AssistantAdminTab({ userId, organizationId }: AssistantAdminTabProps) {
  const [openThreadId, setOpenThreadId] = useState<string | null>(null);
  const navigate = useNavigate();
  const [listId, setListId] = useState<number>(ALL_LISTS_VALUE);
  const {
    data: threads,
    isLoading,
    refetch
  } = useQuery({
    queryKey: ['threads', { userId, organizationId }],
    queryFn: async () => {
      const client = getAPIClient() as ApiClient;
      const params: FetchThreadsForAdminParams = {};
      if (userId && userId !== 0) {
        params.user_id = userId;
      }
      if (organizationId && organizationId !== 0) {
        params.organization_id = organizationId;
      }
      const res = (await client.assistant.fetchThreadsForAdmin(
        params
      )) as ApiAssistantThreadsResponse;
      return res.data.threads;
    },
    enabled: false
  });
  const openThread =
    openThreadId && threads ? threads.find((thread) => thread.thread_id === openThreadId) : null;

  const onClickList = (listId: number) => {
    navigate(`${EXPLORE_PREFIX}/${listId}`);
  };
  const handleModalClose = () => {
    setOpenThreadId(null);
  };
  const getThreads = () => {
    refetch();
  };
  const handleModalOpen = (threadId: string) => {
    setOpenThreadId(threadId);
  };
  return (
    <Stack
      direction="column"
      gap="16px"
      sx={{ justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <MLInlineStack gap="64px">
        <MLButton variant="contained" onClick={getThreads} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Fetch latest threads'}
        </MLButton>
        <AssistantAdminCompanyListSelector
          listId={listId}
          setListId={setListId}
          listIds={(threads || []).reduce((acc, thread) => {
            if (acc.indexOf(thread.company_list_id) === -1) {
              acc.push(thread.company_list_id);
            }
            return acc;
          }, [] as number[])}
        />
      </MLInlineStack>
      <Stack direction="column" gap="0px" sx={{ textAlign: 'left' }}>
        <Stack
          direction="row"
          gap="32px"
          alignItems="center"
          sx={{ padding: '8px', borderBottom: '1px solid black' }}>
          <Button
            size="small"
            variant="contained"
            disabled
            sx={{ width: '60px' }}
            onClick={() => onClickList(9494)}>
            List ID
          </Button>
          <Button size="small" variant="contained" disabled sx={{ width: '60px' }}>
            <QuestionAnswerIcon />
          </Button>
          <Typography variant="text1_Normal" width="180px">
            Timestamp
          </Typography>
          <Typography variant="text1_Normal" sx={{ marginLeft: 'auto', width: '100px' }}>
            User
          </Typography>
          <Typography variant="text1_Normal" sx={{ marginLeft: 'auto', width: '100px' }}>
            No. messages
          </Typography>
        </Stack>
      </Stack>
      {(threads || [])
        .filter((thread) => (listId === ALL_LISTS_VALUE ? true : thread.company_list_id === listId))
        .sort(
          (th1, th2) =>
            new Date(th2.time_submitted).getTime() - new Date(th1.time_submitted).getTime()
        )
        .map((thread: ApiThread) => (
          <Stack
            key={thread.thread_id}
            direction="row"
            gap="32px"
            alignItems="center"
            justifyContent="start"
            textAlign="left"
            sx={{ padding: '8px', borderBottom: '1px solid black' }}>
            <Button
              size="small"
              variant="contained"
              sx={{ width: '60px' }}
              onClick={() => onClickList(thread.company_list_id)}>
              {thread.company_list_id}
            </Button>
            <Button
              size="small"
              variant="contained"
              sx={{ width: '60px' }}
              onClick={() => handleModalOpen(thread.thread_id)}>
              <QuestionAnswerIcon />
            </Button>
            <Typography variant="text1_Normal" width="180px">
              {formatTimestamp(thread.time_submitted)}
            </Typography>
            <Typography variant="text1_Normal" sx={{ marginLeft: 'auto', width: '100px' }}>
              {thread.user.username}
            </Typography>
            <Typography variant="text1_Normal" sx={{ marginLeft: 'auto', width: '100px' }}>
              {(thread.messages || []).length}
            </Typography>
          </Stack>
        ))}
      <MLModal
        open={Boolean(openThreadId)}
        onClose={handleModalClose}
        title="Thread details"
        maxWidth="lg">
        {openThread && <AssistantAdminConversation thread={openThread} />}
      </MLModal>
    </Stack>
  );
}

export default AssistantAdminTab;
