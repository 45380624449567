import { datadogRum } from '@datadog/browser-rum';
import { User } from 'models/users/api';

const MATHLABS_ORGANIZATION_ID = 1;

function checkIfDatadogRUMShouldBeInitialized(user: User) {
  if (process.env.REACT_APP_ENV && ['local', 'dev'].includes(process.env.REACT_APP_ENV)) {
    return true;
  }
  if (user.organization && user.organization.id === MATHLABS_ORGANIZATION_ID) {
    return false;
  }
  if (user.email.includes('@mathlabs')) {
    return false;
  }
  return true;
}

export function initDatadogRUM(user: User) {
  const shouldCapture = checkIfDatadogRUMShouldBeInitialized(user);
  if (!shouldCapture) {
    return;
  }
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID as string,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN as string,
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'datadoghq.eu',
    service: 'frontend',
    env: process.env.REACT_APP_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    defaultPrivacyLevel: 'mask-user-input'
  });
  datadogRum.setUser({
    id: user.id.toString(),
    email: user.email,
    name: user.username,
    organizationId: user.organization ? user.organization.id.toString() : null
  });
}
