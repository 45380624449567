import { updateExploreCustomColumnColor } from 'reducer/explore-slice';
import { getListCustomColumnColorByListIdAndColumnId } from 'selectors/explore';

export function editDiscoveryCustomColumnColorAction(listId, columnId, newColumnColor) {
  return (dispatch, getState, apiClient) => {
    const currentColor = getListCustomColumnColorByListIdAndColumnId(getState(), listId, columnId);
    dispatch(updateExploreCustomColumnColor({ listId, columnId, newColumnColor }));

    apiClient.companiesLists
      .changeCustomColumnColor(listId, columnId, newColumnColor)
      .then((res) => {
        if (!res?.ok) {
          dispatch(
            updateExploreCustomColumnColor({ listId, columnId, newColumnColor: currentColor })
          );
        }
      });
  };
}
