import { submitAssistantMessageAction } from 'actions/assistant/submit-assistant-message-action';
import { createNewDiscoveryInCustomAnalyticsThreadAction } from 'actions/custom-analytics/create-new-discovery-in-custom-analytics-thread-action';
import { submitSubdomainAction } from 'actions/domain-research/submit-subdomain-action';
import { sendUserEvent } from 'actions/users/send-user-event-action';
import { DISCOVERY_TYPES, VIEW_TYPES } from 'constants/discovery';
import { DOMAIN_RESEARCH_EVENTS } from 'constants/userEvents';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import AssistantPromptConfig from 'models/assistant/AssistantPromptConfig';
import { ActionType } from 'models/assistant/assistant-actions';
import React, { ReactNode, createContext, useCallback, useContext, useState } from 'react';
import {
  highlightExploreSubdomains,
  updateSubdomainsDiscoveryRecords
} from 'reducer/domain-research-slice';
import { updateSelectedView } from 'reducer/explore-table-ui';
import { getExploreListSubdomains, getRanSubdomains } from 'selectors/domain-research';
import { getUserFeatureFlags } from 'selectors/user';
import { useAppDispatch, useAppSelector } from 'types/store';
import { EXTEND_DOMAIN_PREFIX, EXTEND_DOMAIN_TYPES } from 'utils/subdomains';

export type Subdomain = {
  id: number;
  name: string;
  level: number;
  description: string;
  parent_subdomain_id: number;
  loading?: boolean;
  streamed?: boolean;
  parameters?: any;
  shouldHightlight?: boolean;
};
type ExtendTypes = typeof EXTEND_DOMAIN_TYPES[keyof typeof EXTEND_DOMAIN_TYPES];
// Define the shape of your context
export type DomainResearchContextType = {
  selectedDomains: Set<number>;
  setSelectedDomains: React.Dispatch<React.SetStateAction<Set<number>>>;
  onClickSubdomain: (subdomainId: number) => void;
  subdomains: Subdomain[] | null;
  onSubmitDiscovery: (
    localSelectedDomains: Set<number>,
    additionalInputText?: string | null
  ) => void;
  ranSubdomains: Record<number, number>;
  scrollToCategory: (elementId: string, subdomainId: number) => void;
  submitSubdomain: (
    subdomain: Subdomain,
    extendType: ExtendTypes,
    userPrefix?: string | null
  ) => void;
};

// Create the context with an undefined default value
/* eslint-disable no-undefined */
const DomainResearchContext = createContext<DomainResearchContextType | undefined>(undefined);

interface DomainResearchContextProvider {
  listId: number;
  children: ReactNode;
}

// Provider component that holds the state and provides it to its children
export const DomainResearchContextProvider: React.FC<DomainResearchContextProvider> = ({
  listId,
  children
}) => {
  const [selectedDomains, setSelectedDomains] = useState<Set<number>>(new Set());
  const subdomains: Subdomain[] = useAppSelector((state) =>
    getExploreListSubdomains(state, listId)
  );
  const featureFlags = useOldStateSelector(getUserFeatureFlags);
  const isUnifyChat = featureFlags?.includes('unify') || featureFlags?.includes('unify_both');
  const ranSubdomains: Record<number, number> = useAppSelector((state) =>
    (getRanSubdomains as any)(state, listId)
  );

  const dispatch = useAppDispatch();

  const onClickSubdomain = (subdomainId: number) => {
    const newSelectedDomains = new Set(selectedDomains);
    if (newSelectedDomains.has(subdomainId)) {
      newSelectedDomains.delete(subdomainId);
    } else {
      newSelectedDomains.add(subdomainId);
    }
    setSelectedDomains(newSelectedDomains);
  };

  const onSubmitDiscovery = async (
    localSelectedDomains: Set<number>,
    additionalInputText?: string | null
  ) => {
    const idsToSubmit = localSelectedDomains || selectedDomains;
    const subdomainsToSubmit = subdomains.filter((subdomain) => idsToSubmit.has(subdomain.id));

    for (let idx = 0; idx < subdomainsToSubmit.length; idx++) {
      const searchTerm = `${subdomainsToSubmit[idx].name}${
        additionalInputText ? `\n${additionalInputText}` : ''
      }`;
      if (isUnifyChat) {
        const assistantPrompt = {
          action_type: ActionType.DISCOVERY_BY_QUERY,
          text: searchTerm
        };

        dispatch(
          updateSubdomainsDiscoveryRecords({
            listId,
            subdomainIdToRecordId: { [subdomainsToSubmit[idx].id]: -1 }
          })
        );

        dispatch(
          submitAssistantMessageAction({
            listId,
            newPromptConfig: new AssistantPromptConfig(assistantPrompt),
            extraParams: { subdomainId: subdomainsToSubmit[idx].id }
          })
        );
      } else {
        dispatch(
          createNewDiscoveryInCustomAnalyticsThreadAction(
            listId,
            DISCOVERY_TYPES.TEXT,
            null,
            searchTerm,
            null,
            null,
            subdomainsToSubmit[idx].id
          )
        );
      }
      // eslint-disable-next-line no-promise-executor-return, no-await-in-loop
      await new Promise((resolve) => setTimeout(resolve, 800));
    }
    setSelectedDomains(new Set());

    dispatch(
      sendUserEvent(DOMAIN_RESEARCH_EVENTS.SUBMITTED_DISCOVERY_FROM_SUBDOMAINS, {
        listId,
        num_discoveries: idsToSubmit.size,
        num_all_selected: selectedDomains.size,
        additionalInputText: additionalInputText || ''
      })
    );
    dispatch(updateSelectedView({ listId, selectedView: VIEW_TYPES.TABLE }));
  };

  const scrollToCategory = useCallback(
    (elementId: string, subdomainId: number) => {
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
      }
      dispatch(highlightExploreSubdomains({ listId, subdomainId, shouldHightlight: true }));
    },
    [listId, dispatch]
  );

  const submitSubdomain = async (
    subdomain: Subdomain,
    extendType: ExtendTypes,
    userPrefix?: string | null,
    shouldScrollToCategory?: boolean
  ) => {
    dispatch(
      submitSubdomainAction(
        extendType,
        listId,
        subdomain.id,
        subdomain.name,
        Number(subdomain.level) + 1,
        userPrefix
      )
    );
    if (shouldScrollToCategory) {
      // eslint-disable-next-line no-promise-executor-return
      await new Promise((resolve) => setTimeout(resolve, 200));
      const elementId = `subdomain-category-${subdomain.id}-${
        userPrefix || EXTEND_DOMAIN_PREFIX[extendType]
      }`;
      scrollToCategory(elementId, subdomain.id);
    }
  };

  return (
    <DomainResearchContext.Provider
      value={{
        selectedDomains,
        setSelectedDomains,
        onClickSubdomain,
        subdomains,
        onSubmitDiscovery,
        ranSubdomains,
        submitSubdomain,
        scrollToCategory
      }}>
      {children}
    </DomainResearchContext.Provider>
  );
};

// Custom hook for consuming the context
export const useDomainResearchContext = (): DomainResearchContextType => {
  const context = useContext(DomainResearchContext);
  if (!context) {
    throw new Error('useDomainResearchContext must be used within a DomainResearchContextProvider');
  }
  return context;
};
