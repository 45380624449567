import styled from '@emotion/styled';
import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextareaAutosize,
  Typography
} from '@mui/material';
import { generateWorkflowAction } from 'actions/workflows/generate-workflow-action';
import { updateMainPromptInWorkflowAction } from 'actions/workflows/update-main-prompt-in-workflow-action';
import LoaderComponent from 'components/LoaderComponent';
import { WORKFLOW_STATUSES, WORK_FLOW_STEP_TYPES } from 'constants/workflows';
import MLInlineStack from 'design-system/MLInlineStack';
import AddIcon from 'icons/AddIcon';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getThreadKey } from 'reducer/custom-analytics';
import {
  getCustomAnalyticsPlanByThreadAndPostId,
  getCustomAnalyticsPlanMainPromptByThreadAndPostId
} from 'selectors/custom-analytics';
import {
  getIsWorkflowReadOnlyModeByListId,
  getWorkflowHistoryPlanByListIdAndWorkflowId,
  getWorkflowSelectedPostIdByListId,
  getWorkflowStatusByListIdAndWorkflowId
} from 'selectors/workflows';
import { appTheme } from 'theme';
import { applyThemeColorOpacity } from 'utils/theme-utils';
import { nodesFromPlan } from 'utils/work-flow-planner-utils';

import WorkflowFormAddStepMenu from './WorkflowFormAddStepMenu';
import WorkflowFormEmptyAddStep from './WorkflowFormEmptyAddStep';
import WorkflowFormEmptyState from './WorkflowFormEmptyState';
import WorkflowFromStepInfoComponent from './WorkflowFromStepInfoComponent';
import WorkflowFromStepMetaComponent from './WorkflowFromStepMetaComponent';
import WorkflowManageColumns from './WorkflowManageColumns';

const Textarea = styled(TextareaAutosize)(
  ({ theme }) => `
    box-sizing: border-box;
    width: 100%;
    font-family: ${appTheme.typography.text1_Normal.fontFamily};
    font-size: ${appTheme.typography.text1_Normal.fontSize}px;
    font-weight: ${appTheme.typography.text1_Normal.fontWeight};
    line-height: ${appTheme.typography.text1_Normal.lineHeight};
    padding: 8px 12px;
    border-radius: 8px;
    flex: 1;
    white-space: pre-wrap;
    color: ${theme.vars.palette.colors.primary_text};
  `
);

function WorkflowFromComponent({ listId }) {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [showMainPrompt, setShowMainPrompt] = useState(true);

  const threadKey = getThreadKey(listId);
  const selectedWorkflowId = useSelector((state) =>
    getWorkflowSelectedPostIdByListId(state, listId)
  );
  const mainPrompt = useSelector((state) =>
    getCustomAnalyticsPlanMainPromptByThreadAndPostId(state, threadKey, selectedWorkflowId)
  );
  const plan = useSelector((state) =>
    getCustomAnalyticsPlanByThreadAndPostId(state, threadKey, selectedWorkflowId)
  );
  const historyPlan = useSelector((state) =>
    getWorkflowHistoryPlanByListIdAndWorkflowId(state, listId, selectedWorkflowId)
  );
  const status = useSelector((state) =>
    getWorkflowStatusByListIdAndWorkflowId(state, listId, selectedWorkflowId)
  );
  const isReadOnly = useSelector((state) => getIsWorkflowReadOnlyModeByListId(state, listId));
  const actualPlan = historyPlan || plan;

  const planSteps = useMemo(() => {
    if (!actualPlan?.steps) return [];
    return nodesFromPlan(actualPlan.steps, listId, selectedWorkflowId).filter(
      (step) => step.type === 'workFlow'
    );
  }, [actualPlan, listId, selectedWorkflowId]);

  useEffect(() => {
    if (actualPlan) {
      setShowMainPrompt(typeof actualPlan.question === 'string' && actualPlan.model !== 'human');
    }
  }, [actualPlan]);

  const onPromptChange = (event) => {
    dispatch(updateMainPromptInWorkflowAction(listId, selectedWorkflowId, event.target.value));
  };

  const isRegenerate = Boolean(plan?.steps);
  const onGenerateButtonClick = () => {
    dispatch(generateWorkflowAction(listId, mainPrompt, isRegenerate));
  };

  const handleOpenNewStep = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseNewStep = () => {
    setAnchorEl(null);
  };

  return (
    <Stack
      gap="32px"
      sx={{
        height: '100%',
        backgroundColor: 'colors.grey_bg',
        padding: '24px'
      }}>
      {showMainPrompt && (
        <MLInlineStack
          alignItems={isReadOnly ? 'center' : 'flex-start'}
          sx={{
            border: '1px solid',
            borderColor: 'colors.ui_border',
            borderRadius: '8px',
            padding: '8px 16px',
            backgroundColor: 'colors.primary_bg'
          }}>
          <MLInlineStack sx={{ width: '200px' }}>
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                width: '24px',
                height: '24px',
                borderRadius: '4px',
                backgroundColor: applyThemeColorOpacity(WORK_FLOW_STEP_TYPES.main_prompt.color, 12)
              }}>
              <WORK_FLOW_STEP_TYPES.main_prompt.Icon />
            </Stack>
            <Typography variant="text1_Bold" color={WORK_FLOW_STEP_TYPES.main_prompt.color}>
              {WORK_FLOW_STEP_TYPES.main_prompt.label}
            </Typography>
          </MLInlineStack>
          {isReadOnly ? (
            <Typography variant="text1_Medium" sx={{ flex: 1 }}>
              {mainPrompt}
            </Typography>
          ) : (
            <Textarea
              variant="outlined"
              value={mainPrompt}
              onChange={onPromptChange}
              maxRows={8}
              placeholder={
                'Describe the type of companies that interest you and what you would like to learn about them....'
              }
              sx={{ ...appTheme.typography.text1_Medium, width: '100%', padding: '8px' }}
            />
          )}
          {!isReadOnly && (
            <Button
              variant="outlined"
              disabled={!mainPrompt || mainPrompt.length <= 0}
              onClick={onGenerateButtonClick}>
              {isRegenerate ? 'Regenerate' : 'Generate'}
            </Button>
          )}
        </MLInlineStack>
      )}
      <TableContainer
        component={Box}
        sx={{
          flex: 1,
          border: '1px solid',
          borderColor: 'colors.ui_border',
          borderRadius: '8px',
          backgroundColor: 'colors.primary_bg'
        }}>
        {planSteps.length > 0 && (
          <Table aria-label="steps table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '200px' }}>Workflow step </TableCell>
                <TableCell>Prompt</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {planSteps.map((step) => (
                <TableRow
                  key={step.id}
                  sx={{
                    backgroundColor:
                      step?.data?.dependsOn?.length > 0 && step?.data?.color
                        ? applyThemeColorOpacity(step.data.color, 6)
                        : 'transparent'
                  }}>
                  <TableCell sx={{ width: '200px' }}>
                    <WorkflowFromStepMetaComponent
                      step={step}
                      isReadOnly={isReadOnly}
                      maxWidth="200px"
                    />
                  </TableCell>
                  <TableCell sx={{ padding: '8px' }}>
                    <WorkflowFromStepInfoComponent
                      listId={listId}
                      workflowId={selectedWorkflowId}
                      step={step}
                      isReadOnly={isReadOnly}
                    />
                  </TableCell>
                </TableRow>
              ))}
              {!isReadOnly && (
                <TableRow>
                  <TableCell sx={{ border: 0 }}>
                    <Button variant="text" startIcon={<AddIcon />} onClick={handleOpenNewStep}>
                      Add step
                    </Button>
                    <WorkflowFormAddStepMenu
                      listId={listId}
                      anchorEl={anchorEl}
                      onClose={handleCloseNewStep}
                    />
                  </TableCell>
                  <TableCell sx={{ border: 0 }}>
                    <WorkflowManageColumns listId={listId} workflowId={selectedWorkflowId} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
        {!showMainPrompt && plan && (!plan.steps || plan.steps.length <= 0) && (
          <React.Fragment>
            <WorkflowFormEmptyAddStep listId={listId} />
          </React.Fragment>
        )}
        {actualPlan && (!actualPlan.steps || actualPlan.steps.length <= 0) && (
          <React.Fragment>
            <WorkflowFormEmptyState />
          </React.Fragment>
        )}
        {(!status || status === WORKFLOW_STATUSES.GENERATING || !actualPlan) && (
          <Stack alignItems="center" justifyContent="center" sx={{ height: 'calc(100% - 60px)' }}>
            {!actualPlan && <LoaderComponent />}
          </Stack>
        )}
      </TableContainer>
    </Stack>
  );
}

WorkflowFromComponent.propTypes = {
  listId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default WorkflowFromComponent;
