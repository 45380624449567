import React from 'react';

export default function GraphWidgetIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.5 14.7216C19.0033 15.8991 18.2263 16.9367 17.2371 17.7437C16.2478 18.5507 15.0764 19.1025 13.8253 19.3509C12.5741 19.5992 11.2813 19.5366 10.0599 19.1685C8.83849 18.8004 7.72562 18.138 6.81861 17.2391C5.91159 16.3403 5.23805 15.2325 4.85687 14.0125C4.47568 12.7924 4.39846 11.4974 4.63196 10.2405C4.86546 8.98371 5.40256 7.80333 6.19631 6.80262C6.99006 5.80191 8.01629 5.01133 9.18528 4.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 11.5C19.5 10.5807 19.3189 9.67049 18.9672 8.82122C18.6154 7.97194 18.0998 7.20026 17.4497 6.55025C16.7997 5.90024 16.0281 5.38463 15.1788 5.03284C14.3295 4.68106 13.4193 4.5 12.5 4.5V11.5H19.5Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
