import React from 'react';

function FilterIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7762 4.96186C19.7761 4.43062 19.3454 4 18.8142 4H5.18598C4.65469 4 4.224 4.43069 4.224 4.96198V6.75396C4.224 6.98429 4.30664 7.20697 4.45689 7.38153L9.88316 13.6856L9.88315 18.3308C9.88315 18.7722 10.1836 19.157 10.6118 19.2641L12.9217 19.8416C13.5288 19.9934 14.117 19.5341 14.117 18.9083V13.6856L19.5435 7.38157C19.6938 7.20697 19.7764 6.98423 19.7764 6.75385L19.7762 4.96186ZM18.7762 5L18.7764 6.73987L13.3499 13.0439C13.1996 13.2184 13.117 13.4411 13.117 13.6715V18.8596L10.8832 18.3011L10.8832 13.6714C10.8832 13.4411 10.8005 13.2184 10.6503 13.0439L5.224 6.73985V5H18.7762Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default FilterIcon;
