import { Button, Stack } from '@mui/material';
import ChooseEntrichDomainKnowledgeNameDialog from 'components/discovery/ChooseEntrichDomainKnowledgeNameDialog';
import { AssistantAction } from 'models/assistant/assistant-actions';
import React, { useState } from 'react';

type Props = {
  listId: number;
  postId: string;
  action: AssistantAction;
};

export default function AddToDomainKnowledgeButton({ listId, postId, action }: Props) {
  const [domainKnowledgePostId, setDomainKnowledgePostId] = useState<string | null>(null);

  return (
    <Stack alignItems="flex-start" width="100%" sx={{ paddingY: '8px', paddingBottom: '8px' }}>
      <Button
        variant="outlined"
        sx={{ height: '32px' }}
        onClick={() => setDomainKnowledgePostId(postId)}>
        Add to domain knowledge
      </Button>

      <ChooseEntrichDomainKnowledgeNameDialog
        open={Boolean(domainKnowledgePostId)}
        onClose={() => setDomainKnowledgePostId(null)}
        listId={listId}
        domainKnowledgePostId={domainKnowledgePostId}
        action={action}
      />
    </Stack>
  );
}
