import { useCurrentEditor } from '@tiptap/react';
import { ACTION_TYPE_TO_PLACEHOLDER, TT_PLACEHOLDER } from 'constants/assistant';
import { useListContext } from 'hooks/ListContextProvider';
import { useEffect, useMemo } from 'react';
import { disableOverwrite, updatePromptConfig } from 'reducer/assistant-slice';
import { getPromptConfig } from 'selectors/assistant';
import { useAppDispatch, useAppSelector } from 'types/store';
import { optionsFromCompaniesColumnsSupportingResources } from 'utils/assistant/text';
import { parseMentions } from 'utils/assistant/tiptap';

export default function TTUtility() {
  const { editor } = useCurrentEditor();
  const text = editor?.getText();
  const promptConfig = useAppSelector(getPromptConfig);
  const dispatch = useAppDispatch();
  const { columns, companies, supportingResources } = useListContext();
  const options = useMemo(
    () =>
      optionsFromCompaniesColumnsSupportingResources({ columns, companies, supportingResources }),
    [columns, companies, supportingResources]
  );
  // Keep the context options in sync
  useEffect(() => {
    if (editor) {
      editor.storage.MentionStorage.suggestions = options;
    }
  }, [options, editor]);

  // Keep placeholder in sync
  useEffect(() => {
    if (editor) {
      const placeholder = promptConfig.action_type
        ? ACTION_TYPE_TO_PLACEHOLDER[promptConfig.action_type]
        : TT_PLACEHOLDER;
      if (editor.storage.PlaceholderStorage.placeholder !== placeholder) {
        editor.storage.PlaceholderStorage.placeholder = placeholder;
        editor.commands.focus();
      }
    }
  }, [promptConfig.action_type, editor]);

  // Keep the text in sync with the editor
  useEffect(() => {
    if (editor && promptConfig.overwrite && text !== promptConfig.text) {
      const nodes = parseMentions(promptConfig.text);
      let editorRef = editor.chain().focus().clearContent();
      nodes.forEach((node) => {
        editorRef = editorRef.insertContent(node);
      });
      editorRef.run();
      dispatch(disableOverwrite());
    } else if (text) {
      dispatch(updatePromptConfig({ text }));
    }
  }, [editor, text, promptConfig.overwrite, promptConfig.text, dispatch]);
  return null;
}
