/* eslint-disable max-lines */
import { createSlice } from '@reduxjs/toolkit';

const initialSliceState = {
  domainReports: {},
  defaultConfig: null
};

export const domainReportsSlice = createSlice({
  name: 'domainReport',
  initialState: initialSliceState,
  reducers: {
    addDefaultConfig: (sliceState, { payload }) => {
      const { default_config } = payload;
      sliceState.defaultConfig = default_config;
    },
    addDomainReport: (sliceState, { payload }) => {
      const { listId, data } = payload;
      const { domain_report_id, exists, status, domain_report } = data;
      if (!exists) {
        return;
      }
      if (!sliceState.domainReports[listId]) {
        sliceState.domainReports[listId] = {};
      }
      sliceState.domainReports[listId][domain_report_id] = {
        ...(domain_report || {}),
        domain_report_id,
        status: status || 'RUNNING'
      };
    },
    updateDomainReport: (sliceState, { payload }) => {
      const { listId, lastDomainReportId, data } = payload;
      const { domain_report_id, sections } = data;

      if (!sliceState.domainReports[listId]) {
        return;
      }
      const lastDomainReport = sliceState.domainReports[listId][lastDomainReportId];

      sliceState.domainReports[listId][domain_report_id] = {
        ...(lastDomainReport || {}),
        domain_report_id
      };

      sliceState.domainReports[listId][domain_report_id].parameters.sections = sections;
    },
    updateDomainReportProgress: (sliceState, { payload }) => {
      const { listId, domainReportId, postId, progress, message } = payload;
      if (!sliceState.domainReports[listId]) {
        return;
      }
      if (!sliceState.domainReports[listId][domainReportId]) {
        return;
      }
      if (!sliceState.domainReports[listId][domainReportId].parameters?.sections) {
        return;
      }
      const { sections } = sliceState.domainReports[listId][domainReportId].parameters;
      const sectionToUpdate = sections.find((section) => section.data?.post_id === postId);
      if (!sectionToUpdate || !sectionToUpdate.data) {
        return;
      }
      sectionToUpdate.data.progress = {
        progress,
        message
      };
    }
  }
});

export const { addDomainReport, updateDomainReport, updateDomainReportProgress, addDefaultConfig } =
  domainReportsSlice.actions;

export default domainReportsSlice.reducer;
