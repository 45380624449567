import { updateSubdomainsDiscoveryRecords } from 'reducer/domain-research-slice';

export function updateSubdomainIdInRecordAction(listId, subdomainId, recordId) {
  return (dispatch, getState, apiClient) => {
    dispatch(
      updateSubdomainsDiscoveryRecords({
        listId,
        subdomainIdToRecordId: { [subdomainId]: recordId }
      })
    );
    apiClient.runs.updateRecordParameters(recordId, { subdomain_id: subdomainId });
  };
}
