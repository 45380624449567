import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useAppUtils } from 'AppUtilsProvider';
import { deleteUserAction } from 'actions/users/delete-user-action';
import { TOAST_TYPES } from 'constants/toasts';
import MLIconButton from 'design-system/MLIconButton';
import CloseIcon from 'design-system/icons/platform/CloseIcon';
import { dispatch } from 'hooks/AppStateProvider';
import PropTypes from 'prop-types';
import React from 'react';
import { appTheme } from 'theme';

export default function DeleteUserDialog({ open, user, onClose }) {
  const { addToast } = useAppUtils();
  const closeHandler = (event) => {
    event.stopPropagation();
    event.preventDefault();
    onClose?.();
  };

  const onError = (message) => {
    addToast(message, TOAST_TYPES.ERROR);
  };
  const onSuccess = (message) => {
    addToast(message, TOAST_TYPES.SUCCESS);
  };
  const deleteUser = () => {
    dispatch(deleteUserAction(user.id, onError, onSuccess));
  };

  const submitRemove = (event) => {
    event.stopPropagation();
    event.preventDefault();
    deleteUser(user.id);
    onClose?.();
  };

  return (
    <Dialog
      open={open}
      onClose={closeHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ zIndex: 'topBar' }}>
      <DialogTitle
        id="alert-dialog-title"
        border="1px solid"
        borderColor="colors.ui_border"
        backgroundColor="colors.gray_bg"
        sx={{
          padding: '12px 24px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          ...appTheme.typography.text1_Bold,
          color: 'colors.primary_text',
          textTransform: 'capitalize'
        }}>
        {`Remove user`}
        <MLIconButton tooltipText="Close" onClick={closeHandler}>
          <CloseIcon />
        </MLIconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          sx={{
            paddingTop: '40px',
            paddingBottom: '16px',
            ...appTheme.typography.text1_Normal,
            color: 'colors.primary_text'
          }}>
          {`Are you sure you want to remove the user ${user.email}?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ gap: '8px' }}>
        <Button
          variant="outlined"
          onClick={closeHandler}
          sx={{ ...appTheme.typography.text1_Bold }}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={submitRemove}
          autoFocus
          sx={{ ...appTheme.typography.text1_Medium }}>
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DeleteUserDialog.propTypes = {
  open: PropTypes.bool,
  user: PropTypes.object,
  onClose: PropTypes.func
};
