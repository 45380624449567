import { ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import { useAppUtils } from 'AppUtilsProvider';
import { updateResourcePermissionsAction } from 'actions/permissions/update-resource-permissions-action';
import { deleteSavedQueryAction } from 'actions/saved_queries/delete-saved-query-action';
import { renameSavedQueryAction } from 'actions/saved_queries/rename-saved-query-action';
import SetQueryNameDialog from 'components/discovery/SetQueryNameDialog';
import { RESOURCE_TYPES } from 'constants/resources';
import MLIconButton from 'design-system/MLIconButton';
import CloseIcon from 'design-system/icons/platform/CloseIcon';
import { useListContext } from 'hooks/ListContextProvider';
import { useMLTooltip } from 'hooks/useMLTooltip';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import CopyIcon from 'icons/CopyIcon';
import EditIcon from 'icons/EditIcon';
import MenuIcon from 'icons/MenuIcon';
import PrivateIcon from 'icons/PrivateIcon';
import ShareIcon from 'icons/ShareIcon';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { newPromptConfig } from 'reducer/assistant-slice';
import { getSavedQueryIsMine, getSavedQueryIsShared } from 'selectors/saved-queries';
import { getUserId, getUserOrganizationId } from 'selectors/user';
import { appTheme } from 'theme';
import { useAppDispatch } from 'types/store';
import { convertV1CaParametersToV2 } from 'utils/assistant/backwards-compatibility';
import { SAVED_QUERY_ACTION_TYPES } from 'utils/platter-utils';

import { SavedQueryType } from './usePlatterItems';

interface PlatterItemMenuProps {
  onCloseDialog: () => void;
  title: string;
  type: string;
  parameters: any;
  id: number;
  actionType: SavedQueryType;
}

export const PlatterItemMenu = ({
  onCloseDialog,
  title,
  type,
  parameters,
  id,
  actionType
}: PlatterItemMenuProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useAppDispatch();
  const { addToast } = useAppUtils();
  const listContext = useListContext();
  const [sharedWithOrg, setSharedWithOrg] = useState(false);
  const organizationId = useOldStateSelector(getUserOrganizationId);
  const userId = useOldStateSelector(getUserId);

  const isSharedFromDb = useSelector((state) => getSavedQueryIsShared(state, id));
  const isMine = useSelector((state) => getSavedQueryIsMine(state, id, userId));

  const [columnName, setColumnName] = useState('');
  const [isRenameDialogOpen, setIsRenameDialogOpen] = useState(false);

  const { hideTooltip, showTooltip } = useMLTooltip();

  const [isClicked, setIsClicked] = useState(false);

  const handleMouseEnter = () => {
    hideTooltip();
  };

  const handleMouseLeave = () => {
    if (!isClicked) {
      showTooltip();
    }
  };

  const handleClick = () => {
    setIsClicked(true);
    hideTooltip();
    setTimeout(() => {
      setIsClicked(false);
    }, 100);
  };

  const handleActionsClick = (event?: any) => {
    event?.stopPropagation();
    setAnchorEl(event?.target);
  };

  const handleMenuClick = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(null);
  };

  useEffect(() => {
    setSharedWithOrg(isSharedFromDb);
  }, [isSharedFromDb]);

  useEffect(() => {
    if (title) {
      setColumnName(title);
    }
  }, [title]);

  const handleShareToOrg = (event: any) => {
    handleMenuClick(event);

    dispatch(
      updateResourcePermissionsAction(
        id,
        RESOURCE_TYPES.SAVED_QUERY,
        [],
        sharedWithOrg ? [] : [organizationId],
        [],
        addToast
      )
    );
    setSharedWithOrg(!sharedWithOrg);
    handleActionsClick();
  };

  const handleCopyClick = (event: any) => {
    handleMenuClick(event);
    dispatch(
      newPromptConfig(
        convertV1CaParametersToV2(
          {
            ...parameters,
            for_each_company: actionType === SAVED_QUERY_ACTION_TYPES.COLUMN
          },
          listContext
        )
      )
    );

    onCloseDialog();
  };

  const onRemove = (event: any) => {
    handleMenuClick(event);
    dispatch(deleteSavedQueryAction(id, addToast));
  };

  const closeRenameDialog = () => {
    setIsRenameDialogOpen(false);
    setColumnName(title);
  };
  const onOpenRenameDialog = (event: any) => {
    handleMenuClick(event);
    setIsRenameDialogOpen(true);
  };
  const handleRenameSubmit = () => {
    dispatch(renameSavedQueryAction(id, columnName));
    closeRenameDialog();
  };

  const menuItems = [
    {
      isVisible: type?.includes('custom_analytics'),
      text: 'Copy query to chat',
      onClick: handleCopyClick,
      endIcon: <CopyIcon />
    },
    {
      isVisible: type === 'custom_analytics' && isMine,
      text: 'Rename',
      onClick: onOpenRenameDialog,
      endIcon: <EditIcon useDefaultColor />
    },
    {
      isVisible: type === 'custom_analytics' && isMine,
      text: sharedWithOrg ? 'Change to private' : 'Change to shared',
      onClick: handleShareToOrg,
      endIcon: sharedWithOrg ? (
        <PrivateIcon useDefaultColor />
      ) : (
        <ShareIcon color={appTheme.palette.colors.dark_bg} />
      )
    },
    {
      isVisible: type === 'custom_analytics' && isMine,
      text: 'Remove from library',
      onClick: onRemove,
      endIcon: <CloseIcon />
    }
  ].filter((item) => item.isVisible);

  return (
    <div>
      <MLIconButton
        id="platter-item-menu"
        sx={{ padding: 0 }}
        onClick={(event) => {
          handleClick();
          handleActionsClick(event);
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        <MenuIcon />
      </MLIconButton>

      <Menu
        id="more-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClick}
        sx={{ '.MuiMenu-list': { padding: 0 } }}>
        {menuItems.map((item, index) => (
          <MenuItem key={index} onClick={(event) => item.onClick(event)}>
            <ListItemIcon>{item.endIcon}</ListItemIcon>
            <Typography variant="text1_Normal">{item.text}</Typography>
          </MenuItem>
        ))}
      </Menu>
      <SetQueryNameDialog
        title={`Rename ${actionType === SAVED_QUERY_ACTION_TYPES.COLUMN ? 'Column' : 'Question'}`}
        open={isRenameDialogOpen}
        onClose={closeRenameDialog}
        columnName={columnName}
        setColumnName={setColumnName}
        onSaveQuery={handleRenameSubmit}
      />
    </div>
  );
};
