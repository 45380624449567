import { sendUserEvent } from 'actions/users/send-user-event-action';
import { TOAST_TYPES } from 'constants/toasts';
import { DOMAIN_REPORT_EVENTS } from 'constants/userEvents';
import { updateDomainReport } from 'reducer/domain-reports-slice';
import { AppDispatch, getAppState } from 'types/store';

export function deleteDomainReportSection(
  listId: number,
  domainReportId: number,
  sectionId: string,
  addToast: (message: string, type: string) => void
) {
  return (dispatch: AppDispatch, getState: getAppState, apiClient: any) => {
    dispatch(
      sendUserEvent(DOMAIN_REPORT_EVENTS.DOMAIN_REPORT_SECTION_DELETION, {
        listId,
        domainReportId,
        sectionId
      })
    );
    apiClient.explore
      .submitExploreListDomainReportSectionDelete(listId, domainReportId, sectionId)
      .then((res: any) => {
        if (res.ok && res.data) {
          const data = { ...res.data };
          dispatch(updateDomainReport({ listId, lastDomainReportId: domainReportId, data }));
          addToast('Section Deleted Successfully', TOAST_TYPES.SUCCESS);
        }
      });
  };
}
