import { Box, MenuItem, Paper, Popper, Typography } from '@mui/material';
import { ACTION_TYPE_TO_LABEL } from 'constants/assistant';
import MLInlineStack from 'design-system/MLInlineStack';
import ArrowIcon from 'icons/ArrowIcon';
import { ActionType } from 'models/assistant/assistant-actions';
import { ActionTypeDisplay } from 'models/assistant/types';
import React, { useRef, useState } from 'react';

interface AssistantActionMenuItemProps extends ActionTypeDisplay {
  handleMenuItemClick: (action: ActionType) => void;
}

const determineBackgroundColor = (
  hoverBase: boolean,
  hoverOther: boolean,
  hasNestedActions?: boolean
) => {
  if (hoverBase) {
    if (hasNestedActions) {
      return hoverOther ? 'colors.hover_on_gray_bg' : 'colors.hover_on_primary_bg';
    }
    return 'colors.hover_on_primary_bg';
  }
  return null;
};

export default function AssistantActionMenuItem({
  actionType,
  color,
  bgColor,
  nestedActions,
  icon: Icon,
  handleMenuItemClick
}: AssistantActionMenuItemProps) {
  const hasNestedActions = nestedActions && nestedActions.length > 0;
  const subOptionBoxRef = useRef<HTMLDivElement>(null);
  const [hoverBase, setHoverBase] = useState(false);
  const [hoverMainOption, setHoverMainOption] = useState(false);
  const [hoverSubOption, setHoverSubOption] = useState(false);
  const closeTimeoutRef = useRef<number | null>(null);

  const clearCloseTimeout = () => {
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current);
      closeTimeoutRef.current = null;
    }
  };

  const handleClick = (action: ActionType) => {
    clearCloseTimeout();
    setHoverSubOption(false);
    handleMenuItemClick(action);
  };

  const handleMouseEnter = () => {
    setHoverBase(true);
  };

  const handleMouseLeave = () => {
    setHoverBase(false);
  };

  const handleMouseEnterMainOption = () => {
    setHoverMainOption(true);
  };

  const handleMouseLeaveMainOption = () => {
    setHoverMainOption(false);
  };

  const handleMouseEnterSubOption = () => {
    clearCloseTimeout();
    setHoverSubOption(true);
  };

  const handleMouseLeaveSubOption = () => {
    closeTimeoutRef.current = window.setTimeout(() => {
      setHoverSubOption(false);
    }, 150);
  };

  return (
    <MLInlineStack
      gap="2px"
      key={actionType}
      sx={{ height: '44px', paddingX: '8px' }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      <MLInlineStack
        onClick={() => handleClick(actionType)}
        onMouseEnter={handleMouseEnterMainOption}
        onMouseLeave={handleMouseLeaveMainOption}
        sx={{
          padding: '12px',
          color,
          borderTopLeftRadius: '8px',
          borderBottomLeftRadius: '8px',
          borderTopRightRadius: hasNestedActions ? '0px' : '8px',
          borderBottomRightRadius: hasNestedActions ? '0px' : '8px',
          width: '100%',
          cursor: 'pointer',
          backgroundColor: determineBackgroundColor(hoverBase, hoverMainOption, hasNestedActions)
        }}>
        <Box sx={{ width: '24px', height: '24px', backgroundColor: bgColor, borderRadius: '4px' }}>
          <Icon />
        </Box>
        <Typography color="colors.primary_text">{ACTION_TYPE_TO_LABEL[actionType]}</Typography>
      </MLInlineStack>
      {hasNestedActions && (
        <React.Fragment>
          <Box
            ref={subOptionBoxRef}
            onMouseEnter={handleMouseEnterSubOption}
            onMouseLeave={handleMouseLeaveSubOption}
            sx={{
              width: '44px',
              height: '48px',
              alignContent: 'center',
              textAlign: 'center',
              borderTopRightRadius: '8px',
              borderBottomRightRadius: '8px',
              backgroundColor: determineBackgroundColor(hoverBase, hoverSubOption, hasNestedActions)
            }}>
            <Box sx={{ margin: 'auto', width: '24px', height: '24px' }}>
              <ArrowIcon rotation={90} />
            </Box>
          </Box>
          <Popper
            open={hoverSubOption}
            anchorEl={subOptionBoxRef.current}
            placement="right-start"
            sx={{ zIndex: 10000 }}>
            <Paper
              onMouseEnter={handleMouseEnterSubOption}
              onMouseLeave={handleMouseLeaveSubOption}
              sx={{
                padding: '8px',
                boxShadow: 'box-shadow: 0px 2px 7px 0px rgba(66, 82, 110, 0.41)'
              }}>
              {nestedActions.map(
                ({ icon: SubIcon, label: subLabel, actionType: subActionType }) => (
                  <MenuItem key={subActionType} onClick={() => handleClick(subActionType)}>
                    <MLInlineStack>
                      <SubIcon />
                      <Typography>{subLabel}</Typography>
                    </MLInlineStack>
                  </MenuItem>
                )
              )}
            </Paper>
          </Popper>
        </React.Fragment>
      )}
    </MLInlineStack>
  );
}
