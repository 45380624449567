import {
  Divider,
  ListItemIcon,
  MenuItem,
  OutlinedInput,
  Popover,
  Stack,
  Typography
} from '@mui/material';
import { addStepToPlanAction } from 'actions/workflows/add-step-to-plan-action';
import { usePlatterItems } from 'components/elements/query-library/usePlatterItems';
import { WORK_FLOW_STEP_TYPES } from 'constants/workflows';
import MLIconButton from 'design-system/MLIconButton';
import MLInlineStack from 'design-system/MLInlineStack';
import LibraryIcon from 'design-system/icons/basic/LibraryIcon';
import BackIcon from 'icons/BackIcon';
import SearchIcon from 'icons/SearchIcon';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getWorkflowSelectedPostIdByListId } from 'selectors/workflows';
import { appTheme } from 'theme';
import { SAVED_QUERY_ACTION_TYPES } from 'utils/platter-utils';
import { applyThemeColorOpacity } from 'utils/theme-utils';

function WorkflowFormAddStepMenu({ listId, anchorEl, stepId, onClose }) {
  const dispatch = useDispatch();
  const [isAddFromLibraryMode, setIsAddFromLibraryMode] = useState(false);
  const [columnsFilter, setColumnsFilter] = useState('');

  const selectedWorkflowId = useSelector((state) =>
    getWorkflowSelectedPostIdByListId(state, listId)
  );

  const closePopup = () => {
    setColumnsFilter('');
    setIsAddFromLibraryMode(false);
    onClose();
  };

  const handleAddStep = (step) => {
    dispatch(addStepToPlanAction(listId, selectedWorkflowId, step.id, stepId));
    closePopup();
  };

  const handleAddLibraryStep = (column) => {
    dispatch(
      addStepToPlanAction(
        listId,
        selectedWorkflowId,
        WORK_FLOW_STEP_TYPES.add_column_from_library.id,
        stepId,
        column
      )
    );
    closePopup();
  };

  const { columnsByCategories } = usePlatterItems(SAVED_QUERY_ACTION_TYPES.COLUMN);
  const libraryColumns = useMemo(
    () =>
      Object.keys(columnsByCategories)
        .filter((category) => category !== 'Manual')
        .reduce((allColumns, category) => allColumns.concat(columnsByCategories[category]), [])
        .map((item) => ({
          id: item.data_name || item.id,
          title: item.title,
          data_name: item.data_name || item.parameters?.data_name || item.title,
          type: item.type,
          category: item.category,
          parameters: item.parameters
        }))
        .filter((item) =>
          item.title.toLocaleLowerCase('en').includes(columnsFilter.toLocaleLowerCase('en'))
        ),
    [columnsByCategories, columnsFilter]
  );

  const getStepItem = (step) => {
    return (
      <MenuItem onClick={() => handleAddStep(step)}>
        <ListItemIcon>
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              width: '24px',
              height: '24px',
              borderRadius: '4px',
              color: step.color,
              backgroundColor: applyThemeColorOpacity(step.color, 12)
            }}>
            <step.Icon size={20} />
          </Stack>
        </ListItemIcon>
        <Typography variant="text1_Normal" color="colors.primary_text">
          {step.label}
        </Typography>
      </MenuItem>
    );
  };

  return (
    <Popover
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={closePopup}
      slotProps={{
        paper: {
          sx: { backgroundColor: 'colors.primary_bg' }
        }
      }}>
      {isAddFromLibraryMode ? (
        <React.Fragment>
          <MLInlineStack>
            <MLIconButton onClick={() => setIsAddFromLibraryMode(false)}>
              <BackIcon />
            </MLIconButton>
            <Typography variant="text1_Medium" color="colors.primary_text">
              Add from library
            </Typography>
          </MLInlineStack>
          <Stack direction="column" gap="8px">
            <OutlinedInput
              placeholder={'search...'}
              value={columnsFilter}
              disableunderline="true"
              startAdornment={<SearchIcon />}
              onChange={(event) => setColumnsFilter(event.target.value)}
              sx={{
                backgroundColor: 'transparent',
                ...appTheme.typography.text1_Normal,
                '.MuiInputBase-input': { padding: '8px' }
              }}
            />
            {libraryColumns.map((column) => (
              <MenuItem
                key={column.id}
                value={column.id}
                onClick={() => handleAddLibraryStep(column)}>
                <Typography variant="text1_Normal" color="colors.primary_text">
                  {column.title}
                </Typography>
              </MenuItem>
            ))}
          </Stack>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {getStepItem(WORK_FLOW_STEP_TYPES.discovery)}
          {getStepItem(WORK_FLOW_STEP_TYPES.information)}
          {getStepItem(WORK_FLOW_STEP_TYPES.filter)}
          {getStepItem(WORK_FLOW_STEP_TYPES.analysis)}
          <Divider />
          <MenuItem onClick={() => setIsAddFromLibraryMode(true)}>
            <ListItemIcon>
              <LibraryIcon />
            </ListItemIcon>
            <Typography variant="text1_Normal" color="colors.primary_text">
              Add from library
            </Typography>
          </MenuItem>
        </React.Fragment>
      )}
    </Popover>
  );
}

WorkflowFormAddStepMenu.propTypes = {
  listId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  anchorEl: PropTypes.object,
  stepId: PropTypes.string,
  onClose: PropTypes.func
};

export default WorkflowFormAddStepMenu;
