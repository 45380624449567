import { MentionNodeAttrs } from '@tiptap/extension-mention';
import { ReactRenderer } from '@tiptap/react';
import { SuggestionOptions } from '@tiptap/suggestion';
import { TT_TRIGGER_CHAR } from 'constants/assistant';
import tippy, { Instance } from 'tippy.js';

import MentionList, { MentionListHandle } from './MentionList';

function generateSuggestion():
  | Omit<SuggestionOptions<any, MentionNodeAttrs>, 'editor'>
  | undefined {
  return {
    char: TT_TRIGGER_CHAR,
    render: () => {
      /* eslint-disable init-declarations */
      let component: ReactRenderer<MentionListHandle>;
      let popup: Instance[];

      return {
        onStart: (props) => {
          component = new ReactRenderer(MentionList, {
            props,
            editor: props.editor
          });

          if (!props.clientRect) {
            return;
          }

          popup = tippy('body', {
            getReferenceClientRect: props.clientRect as any,
            appendTo: () => document.body,
            content: component.element,
            showOnCreate: true,
            interactive: true,
            trigger: 'manual',
            placement: 'top-start',
            onHidden: () => {
              component.destroy();
              const { range } = (props.editor as any).state.mention$;
              if (!range) return;
              const { from, to } = range;
              props.editor
                .chain()
                .focus()
                .deleteRange({ from: Math.max(0, from - 1), to })
                .run();
            }
          });
        },

        onUpdate(props) {
          component.updateProps(props);

          if (!props.clientRect) {
            return;
          }

          popup[0].setProps({
            getReferenceClientRect: props.clientRect as any
          });
        },

        onKeyDown(props) {
          if (props.event.key === 'Escape') {
            popup[0].hide();

            return true;
          }

          return component.ref?.onKeyDown(props) ?? false;
        },

        onExit() {
          popup[0].destroy();
          component.destroy();
        }
      };
    }
  };
}

export default generateSuggestion;
