import { Button, OutlinedInput, Stack, Typography } from '@mui/material';
import { addEmptyWorkflowAction } from 'actions/workflows/add-empty-workflow-action';
import { createWorkflowFromListAction } from 'actions/workflows/create-workflow-from-list-action';
import { ICON_VARIANTS } from 'constants/icons';
import MLInlineStack from 'design-system/MLInlineStack';
import MLModal from 'design-system/MLModal';
import AddIcon from 'icons/AddIcon';
import { ListIcon } from 'icons/ListIcon';
import PointerIcon from 'icons/PointerIcon';
import PromptTextIcon from 'icons/PromptTextIcon';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { applyThemeColorOpacity } from 'utils/theme-utils';

function AddNewWorkflowComponent({ listId }) {
  const dispatch = useDispatch();
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [workflowName, setWorkflowName] = useState('New Workflow');
  const [startPoint, setStartPoint] = useState(0);

  const handleCreateWorkflowClick = (event) => {
    event.stopPropagation();
    setOpenCreateDialog(true);
  };

  const handleCloseCreateWorkflow = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setOpenCreateDialog(false);
    setWorkflowName('New Workflow');
  };

  const onCreateWorkflow = (event) => {
    switch (startPoint) {
      case 0:
      case 1:
        dispatch(addEmptyWorkflowAction(listId, workflowName, startPoint === 1));
        break;
      default:
        dispatch(createWorkflowFromListAction(listId, workflowName));
        break;
    }
    handleCloseCreateWorkflow(event);
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        startIcon={<AddIcon variant={ICON_VARIANTS.SECONDARY} />}
        onClick={handleCreateWorkflowClick}>
        New workflow
      </Button>
      <MLModal
        title="Create a new workflow"
        open={openCreateDialog}
        primaryButtonProps={{ label: 'Create workflow', onClick: onCreateWorkflow }}
        secondaryButtonProps={{ label: 'Cancel', onClick: handleCloseCreateWorkflow }}
        onClose={handleCloseCreateWorkflow}>
        <Stack gap="32px">
          <Stack gap="8px">
            <Typography variant="text1_Normal" color="colors.primary_text">
              Workflow name
            </Typography>
            <OutlinedInput
              size="small"
              value={workflowName}
              onChange={(event) => setWorkflowName(event.target.value)}
              placeholder="Write the workflow name here..."
              autoFocus
              sx={{
                width: '100%',
                height: '32px'
              }}
            />
          </Stack>
          <Stack gap="8px">
            <Typography variant="text1_Normal" color="colors.primary_text">
              Choose a starting point
            </Typography>
            <Stack gap="8px">
              <MLInlineStack
                sx={{
                  cursor: 'pointer',
                  padding: '8px',
                  borderRadius: '8px',
                  backgroundColor:
                    startPoint === 0 ? applyThemeColorOpacity('colors.main', 12) : 'transparent',
                  '&:hover': {
                    backgroundColor:
                      startPoint === 0
                        ? applyThemeColorOpacity('colors.main', 12)
                        : 'colors.hover_on_primary_bg'
                  }
                }}
                onClick={() => setStartPoint(0)}>
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    width: '58px',
                    height: '58px',
                    backgroundColor: 'colors.main',
                    color: 'colors.text_on_primary',
                    borderRadius: '8px'
                  }}>
                  <PromptTextIcon size={32} />
                </Stack>
                <Stack>
                  <Typography variant="text1_Bold" color="colors.primary_text">
                    Start with a prompt
                  </Typography>
                  <Typography variant="text1_Normal" color="colors.secondary_text">
                    Generate a workflow using a main prompt
                  </Typography>
                </Stack>
              </MLInlineStack>
              <MLInlineStack
                sx={{
                  cursor: 'pointer',
                  padding: '8px',
                  borderRadius: '8px',
                  backgroundColor:
                    startPoint === 1
                      ? applyThemeColorOpacity('accent_colors.bright_blue', 12)
                      : 'transparent',
                  '&:hover': {
                    backgroundColor:
                      startPoint === 1
                        ? applyThemeColorOpacity('accent_colors.bright_blue', 12)
                        : 'colors.hover_on_primary_bg'
                  }
                }}
                onClick={() => setStartPoint(1)}>
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    width: '58px',
                    height: '58px',
                    backgroundColor: 'accent_colors.bright_blue',
                    color: 'colors.text_on_primary',
                    borderRadius: '8px'
                  }}>
                  <PointerIcon size={32} color="currentColor" />
                </Stack>
                <Stack>
                  <Typography variant="text1_Bold" color="colors.primary_text">
                    Start manually
                  </Typography>
                  <Typography variant="text1_Normal" color="colors.secondary_text">
                    Start from scratch and add steps as needed
                  </Typography>
                </Stack>
              </MLInlineStack>
              <MLInlineStack
                sx={{
                  cursor: 'pointer',
                  padding: '8px',
                  borderRadius: '8px',
                  backgroundColor:
                    startPoint === 2
                      ? applyThemeColorOpacity('accent_colors.orchid', 12)
                      : 'transparent',
                  '&:hover': {
                    backgroundColor:
                      startPoint === 2
                        ? applyThemeColorOpacity('accent_colors.orchid', 12)
                        : 'colors.hover_on_primary_bg'
                  }
                }}
                onClick={() => setStartPoint(2)}>
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    width: '58px',
                    height: '58px',
                    backgroundColor: 'accent_colors.orchid',
                    color: 'colors.text_on_primary',
                    borderRadius: '8px'
                  }}>
                  <ListIcon size={32} color="currentColor" />
                </Stack>
                <Stack>
                  <Typography variant="text1_Bold" color="colors.primary_text">
                    Create from list
                  </Typography>
                  <Typography variant="text1_Normal" color="colors.secondary_text">
                    Convert list creation steps into a workflow
                  </Typography>
                </Stack>
              </MLInlineStack>
            </Stack>
          </Stack>
        </Stack>
      </MLModal>
    </React.Fragment>
  );
}

AddNewWorkflowComponent.propTypes = {
  listId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default AddNewWorkflowComponent;
