/* eslint-disable max-lines */
import { createSlice } from '@reduxjs/toolkit';
import { Subdomain } from 'components/subdomains/DomainResearchContextProvider';
import { isEmpty } from 'utils/objects-utils';

interface DomainResearchData {
  subdomains?: Subdomain[];
  hideSubdomainsDescription?: boolean;
  discoveryRecords?: Record<number, number>;
}

interface DomainResearchSliceState {
  exploreLists: Record<number, DomainResearchData>;
}

const initialSliceState: DomainResearchSliceState = {
  exploreLists: {}
};

export const domainResearchSlice = createSlice({
  name: 'domainResearch',
  initialState: initialSliceState,
  reducers: {
    addAllExploreSubdomains: (sliceState, { payload }) => {
      const { listId, subdomains } = payload;
      if (!sliceState.exploreLists[listId]) {
        sliceState.exploreLists[listId] = {};
      }
      sliceState.exploreLists[listId].subdomains = subdomains;
    },
    addExploreSubdomains: (sliceState, { payload }) => {
      const { listId, subdomains } = payload;
      if (isEmpty(subdomains)) return;

      if (!sliceState.exploreLists[listId]) {
        sliceState.exploreLists[listId] = {};
      }

      const oldSubdomains = sliceState.exploreLists[listId]?.subdomains || [];
      const loadingIdx = oldSubdomains
        .map((subdomain, idx) => {
          if (
            !subdomain?.loading ||
            subdomain.parent_subdomain_id !== subdomains[0].parent_subdomain_id
          ) {
            return -1;
          }
          return idx;
        })
        .filter((idx) => idx !== -1);
      if (loadingIdx.length) {
        oldSubdomains.splice(loadingIdx[0], subdomains.length, ...subdomains);
      } else {
        oldSubdomains.push(...subdomains);
      }
      sliceState.exploreLists[listId].subdomains = [...oldSubdomains];
    },
    deleteExploreSubdomains: (sliceState, { payload }) => {
      const { listId, subdomainIds } = payload;
      const subdomains = sliceState.exploreLists[listId]?.subdomains;
      if (subdomains) {
        sliceState.exploreLists[listId].subdomains = subdomains.filter(
          (item) => !subdomainIds.includes(item.id)
        );
      }
    },
    removeExploreSubdomainsLoading: (sliceState, { payload }) => {
      const { listId, parent_subdomain_id, promptPrefix } = payload;

      const subdomains = sliceState.exploreLists[listId]?.subdomains;
      if (subdomains) {
        sliceState.exploreLists[listId].subdomains = subdomains.filter((subdomain) => {
          if (!subdomain.loading) {
            return true;
          }
          return (
            subdomain.parent_subdomain_id !== parent_subdomain_id ||
            subdomain.parameters.prompt_prefix !== promptPrefix
          );
        });
      }
    },
    highlightExploreSubdomains: (sliceState, { payload }) => {
      const { listId, subdomainId, shouldHightlight } = payload;

      if (!sliceState.exploreLists[listId]) {
        sliceState.exploreLists[listId] = {};
      }
      const exploreList = sliceState.exploreLists[listId] as DomainResearchData;
      if (!exploreList?.subdomains) {
        exploreList.subdomains = [];
      }
      const { subdomains } = exploreList;

      const sdIndex = subdomains.findIndex((subdomain) => subdomain.id === subdomainId);
      if (sdIndex !== -1 && subdomains[sdIndex]) {
        subdomains[sdIndex].shouldHightlight = shouldHightlight;
      }
    },

    updateHideSubdomainsDescription: (sliceState, { payload }) => {
      const { listId, hideSubdomainsDescription } = payload;
      if (!sliceState.exploreLists[listId]) {
        sliceState.exploreLists[listId] = {};
      }
      sliceState.exploreLists[listId].hideSubdomainsDescription = hideSubdomainsDescription;
    },

    updateSubdomainsDiscoveryRecords: (sliceState, { payload }) => {
      const { listId, subdomainIdToRecordId } = payload;
      if (!sliceState.exploreLists[listId]) {
        sliceState.exploreLists[listId] = {};
      }
      if (!sliceState.exploreLists[listId]?.discoveryRecords) {
        sliceState.exploreLists[listId].discoveryRecords = {};
      }
      sliceState.exploreLists[listId].discoveryRecords = {
        ...sliceState.exploreLists[listId].discoveryRecords,
        ...subdomainIdToRecordId
      };
    }
  }
});

export const {
  addAllExploreSubdomains,
  addExploreSubdomains,
  deleteExploreSubdomains,
  removeExploreSubdomainsLoading,
  highlightExploreSubdomains,
  updateHideSubdomainsDescription,
  updateSubdomainsDiscoveryRecords
} = domainResearchSlice.actions;

export default domainResearchSlice.reducer;
