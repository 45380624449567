import React from 'react';

export default function WebsiteIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3067 5.0339C7.93005 5.36589 5.25774 8.09719 5.01758 11.5H8.72121C8.87466 9.12499 9.78038 6.85991 11.3067 5.0339ZM12.6937 5.03394C14.22 6.85995 15.1257 9.12501 15.2792 11.5H18.9824C18.7423 8.09733 16.0702 5.36612 12.6937 5.03394ZM14.2769 11.5C14.1278 9.39888 13.3321 7.39522 12.0002 5.76451C10.6683 7.39522 9.87262 9.39888 9.7235 11.5H14.2769ZM9.7235 12.5H14.2769C14.1278 14.6011 13.3321 16.6048 12.0002 18.2355C10.6683 16.6048 9.87262 14.6011 9.7235 12.5ZM8.72121 12.5H5.01758C5.25774 15.9028 7.93005 18.6341 11.3067 18.9661C9.78038 17.1401 8.87466 14.875 8.72121 12.5ZM12.6937 18.9661C14.22 17.1401 15.1257 14.875 15.2792 12.5H18.9824C18.7423 15.9027 16.0702 18.6339 12.6937 18.9661ZM4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12Z"
        fill="currentColor"
      />
    </svg>
  );
}
