import { EXPLORE_PREFIX } from 'constants/app-routes';
import { NavigateFunction } from 'react-router-dom';

export function generateNavigateToView(navigate: NavigateFunction) {
  return (listId: number, viewId: number) => {
    if (!listId || !viewId) {
      return;
    }
    navigate(`${EXPLORE_PREFIX}/${listId}/views/${viewId}`);
  };
}
