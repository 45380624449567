import { Stack, Typography } from '@mui/material';
import MLInlineStack from 'design-system/MLInlineStack';
import React, { useMemo } from 'react';
import { parseFinanceSourceValue } from 'utils/finance-utils';

import LinkToFinanceSourceButton from './LinkToFinanceSourceButton';
import { FinanceData } from './types';

type Props = {
  sourceData: FinanceData;
  hasBorderBottom: boolean;
};

export default function FinanceSourceDataComponent({ sourceData, hasBorderBottom }: Props) {
  const parsedSourceValue = useMemo(
    () => sourceData && parseFinanceSourceValue(sourceData),
    [sourceData]
  );
  if (!parsedSourceValue) return null;

  return (
    <Stack
      key={sourceData.source_name}
      borderBottom={hasBorderBottom ? '1px solid' : 'none'}
      borderColor="colors.primary_text">
      <Typography variant="text1_Medium" color="colors.secondary_text_on_primary" paddingTop="4px">
        Source:
      </Typography>
      <MLInlineStack padding="8px">
        {sourceData.link_to_source && (
          <LinkToFinanceSourceButton link={sourceData.link_to_source} />
        )}
        <Typography variant="text1_Medium">
          {sourceData.source_label
            .split('_')
            .map((word) => word[0].toUpperCase() + word.substring(1))
            .join(' ')}
        </Typography>
        <Typography marginLeft="auto" variant="text1_Medium">
          {parsedSourceValue}
        </Typography>
      </MLInlineStack>
      {sourceData?.year && (
        <Typography paddingLeft="8px" color="colors.secondary_text_on_primary">
          Year: {sourceData.year}
        </Typography>
      )}
    </Stack>
  );
}
