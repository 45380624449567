import PropTypes from 'prop-types';
import React from 'react';

function PointerIcon({ size, color }) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        d="M11.5252 11.5632V5.029C11.5252 4.30726 10.9401 3.72217 10.2183 3.72217V3.72217C9.49659 3.72217 8.9115 4.30726 8.9115 5.029V10.9098"
        stroke={color}
        strokeWidth="1.09574"
        strokeLinecap="round"
      />
      <path
        d="M13.812 11.8894V8.13225C13.812 7.50072 13.3 6.98877 12.6685 6.98877V6.98877C12.037 6.98877 11.525 7.50366 11.525 8.13519C11.525 9.97618 11.525 8.47544 11.525 11.5627"
        stroke={color}
        strokeWidth="1.09574"
        strokeLinecap="round"
      />
      <path
        d="M8.90931 10.2592L8.90931 11.585C8.90931 11.9427 8.47852 12.1237 8.22305 11.8733V11.8733L7.42487 11.0751C6.974 10.6243 6.2432 10.6236 5.79149 11.0736V11.0736C5.3456 11.5179 5.31855 12.2539 5.70969 12.7471C6.06571 13.1959 6.43896 13.6711 6.61097 13.8507L9.2382 17.4443C10.2183 18.6422 12.7666 20.7113 15.1189 19.4044C17.4712 18.0976 18.2771 15.1574 18.386 13.8507L18.386 13.1971"
        stroke={color}
      />
      <path
        d="M16.099 12.8698V9.43938C16.099 8.80785 15.587 8.2959 14.9555 8.2959V8.2959C14.324 8.2959 13.812 8.80785 13.812 9.43938L13.812 12.2164"
        stroke={color}
        strokeWidth="1.09574"
        strokeLinecap="round"
      />
      <path
        d="M18.386 13.8503V10.4198C18.386 9.78832 17.874 9.27637 17.2425 9.27637V9.27637C16.611 9.27637 16.099 9.78832 16.099 10.4198V12.8702"
        stroke={color}
        strokeWidth="1.09574"
        strokeLinecap="round"
      />
    </svg>
  );
}

PointerIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
};

PointerIcon.defaultProps = {
  size: 24,
  color: '#5C5E76'
};

export default PointerIcon;
