import { Box, Stack } from '@mui/material';
import MLInlineStack from 'design-system/MLInlineStack';
import { ActionType, AssistantAction } from 'models/assistant/assistant-actions';
import { MessageRole } from 'models/assistant/types';
import React from 'react';

import ActionAddCompaniesByNameDisplay from '../ActionAddCompaniesByNameDisplay';
import { messageRoleToAvatar } from '../MessageComponent';
import MessageConfirmationComponent from '../MessageConfirmationComponent';
import WorkflowPostDisplayComponent from '../WorkflowPostDisplayComponent';
import CustomAnalyticsPostDisplay from './CustomAnalyticsPostDisplay';
import FilterAnswer from './FilterAnswer';

type Props = {
  listId: number;
  threadId: string;
  action: AssistantAction;
  role: MessageRole;
};

export default function AssistantActionPostDisplay({ listId, action, role, threadId }: Props) {
  const actionSubmitted = action?.submission_info?.submitted;

  const showCustomAnalyticsPost =
    actionSubmitted &&
    (action.action_type !== ActionType.FILTER || action?.submission_info?.post_id) &&
    action.action_type !== ActionType.WORKFLOW &&
    action.action_type !== ActionType.ADD_COMPANIES_BY_NAME;

  if (!actionSubmitted && !showCustomAnalyticsPost) return null;
  return (
    <Stack gap="16px">
      <MLInlineStack alignItems="flex-start">
        {messageRoleToAvatar(role)}
        {actionSubmitted && action.action_type === ActionType.WORKFLOW && (
          <WorkflowPostDisplayComponent action={action} listId={listId} />
        )}
        {actionSubmitted && action.action_type === ActionType.ADD_COMPANIES_BY_NAME && (
          <ActionAddCompaniesByNameDisplay action={action} />
        )}
        {!showCustomAnalyticsPost &&
          actionSubmitted &&
          action.action_type === ActionType.FILTER && <FilterAnswer />}
        {showCustomAnalyticsPost && <CustomAnalyticsPostDisplay listId={listId} action={action} />}
      </MLInlineStack>
      {action.action_type === ActionType.PREVIEW_COLUMN && (
        <Box pl="48px">
          <MessageConfirmationComponent action={action} threadId={threadId} listId={listId} />
        </Box>
      )}
    </Stack>
  );
}
