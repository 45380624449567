import React from 'react';

export default function LibraryIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.5 19.2999L10.75 15.9413L6 19.2999V8.55242C6 8.19612 6.14298 7.85441 6.3975 7.60247C6.65201 7.35052 6.99721 7.20898 7.35714 7.20898H14.1429C14.5028 7.20898 14.848 7.35052 15.1025 7.60247C15.357 7.85441 15.5 8.19612 15.5 8.55242V19.2999Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.2998 5H16.2998C17.4044 5 18.2998 5.89543 18.2998 7V17.7716C18.2998 17.8587 18.196 17.9042 18.132 17.845L17 16.7998"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  );
}
