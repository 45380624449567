import { Stack, Tooltip } from '@mui/material';
import { UseMenuManagementReturn } from 'design-system/hooks/useMenuManagement';
import { useMLTooltip } from 'hooks/useMLTooltip';
import React from 'react';
import { getObjectLength } from 'utils/objects-utils';

import { FinanceDataItems } from '../RenderProfitData';
import ProfitCellTooltip from './ProfitCellTooltip';
import RenderFinanceTableInlineCell from './RenderFinanceTableInlineCell';

type Props = {
  financeDataItems: Record<string, FinanceDataItems>;
  profitFinanceData: FinanceDataItems;
  color: string;
  menuManagment: UseMenuManagementReturn<HTMLButtonElement>;
};

export default function RenderProfitInlineCell({
  menuManagment,
  financeDataItems,
  profitFinanceData,
  color
}: Props) {
  const { isTooltipVisible, hideTooltip, showTooltip } = useMLTooltip();
  const cellFinanceSource = profitFinanceData.financeData[0]?.extra_info?.profit_finance_key;
  const disableTooltip = getObjectLength(financeDataItems) <= 1;
  return (
    <Tooltip
      open={isTooltipVisible && !disableTooltip}
      title={<ProfitCellTooltip financeDataItems={financeDataItems} />}
      arrow>
      <Stack onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
        <RenderFinanceTableInlineCell
          onOpen={menuManagment.onOpen}
          color={color}
          finalValue={profitFinanceData.finalValue}
          isEstimated={profitFinanceData.isEstimated}
          ref={menuManagment.ref}
          financeType={cellFinanceSource}
        />
      </Stack>
    </Tooltip>
  );
}
