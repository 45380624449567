import { Stack, TextField } from '@mui/material';
import MultiSectionsSelectComponent from 'components/discovery/MultiSectionsSelectComponent';
import SlimMultiSelectPromptInputComponent from 'components/discovery/SlimMultiSelectComponent';
import { PROMPT_SELECT_STYLE } from 'constants/prompt2';
import useEditConfigContext from 'hooks/useEditConfigContext';
import React, { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { PromptConfig } from 'utils/custom-analytics-utils';

import DomainReportEditorKeyValue from './DomainReportEditorKeyValue';

interface DomainReportEditorConfigProps {
  listId: number;
  localPromptConfig: PromptConfig;
  setLocalPromptConfig: Dispatch<SetStateAction<PromptConfig | null>>;
}

export default function DomainReportEditorConfig({
  listId,
  localPromptConfig,
  setLocalPromptConfig
}: DomainReportEditorConfigProps) {
  const {
    searchPlaces,
    handleSearchPlaceChange,
    contextSections,
    contextSelectorTitle,
    contextSelectorTooltip,
    theme
  } = useEditConfigContext(listId, localPromptConfig, setLocalPromptConfig, false);

  const updatePromptConfig = (event: ChangeEvent<HTMLInputElement>) => {
    setLocalPromptConfig((prev: PromptConfig | null) => {
      return { ...prev, question: event.target.value } as PromptConfig;
    });
  };

  return (
    <Stack gap="24px">
      <DomainReportEditorKeyValue title="Query">
        <TextField
          multiline
          maxRows={15}
          minRows={3}
          variant="outlined"
          value={localPromptConfig?.question}
          size="small"
          autoFocus
          slotProps={{
            input: {
              sx: {
                ...theme.typography.text1_Normal
              }
            }
          }}
          sx={{
            ...theme.typography.text1_Normal,
            minWidth: '100%'
          }}
          onChange={updatePromptConfig}
        />
      </DomainReportEditorKeyValue>
      <DomainReportEditorKeyValue title="Search">
        <SlimMultiSelectPromptInputComponent
          selectedValues={searchPlaces}
          onValueChange={handleSearchPlaceChange}
          allTag="Search everywhere"
          emptyTag="Select sources"
          sx={{ width: '250px' }}
        />
      </DomainReportEditorKeyValue>
      <DomainReportEditorKeyValue title="Context">
        <MultiSectionsSelectComponent
          containerSx={{
            ...PROMPT_SELECT_STYLE,
            flex: 1,
            maxWidth: '250px'
          }}
          sections={contextSections}
          title={contextSelectorTitle}
          tooltipText={contextSelectorTooltip}
        />
      </DomainReportEditorKeyValue>
    </Stack>
  );
}
