import React from 'react';

export default function BusinessIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.6667 7.16672H4.33334C3.59696 7.16672 3 7.76367 3 8.50005V15.6667C3 16.403 3.59696 17 4.33334 17H15.6667C16.403 17 17 16.403 17 15.6667V8.50005C17 7.76367 16.403 7.16672 15.6667 7.16672Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 17L12.5001 4.33334C12.5001 3.97971 12.3596 3.64057 12.1095 3.39053C11.8595 3.14048 11.5203 3 11.1667 3H8.83339C8.47977 3 8.14063 3.14048 7.89058 3.39053C7.64053 3.64057 7.50005 3.97971 7.50005 4.33334L7.5 17"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
