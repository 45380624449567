import { createSlice } from '@reduxjs/toolkit';

const initialSliceState = {
  activeSubscriptions: {}
};

export const pusherSlice = createSlice({
  name: 'pusher',
  initialState: initialSliceState,
  reducers: {
    addSubscriberToChannel: (sliceState, { payload }) => {
      const { channelName } = payload;
      const prevValue = sliceState.activeSubscriptions[channelName] || 0;
      sliceState.activeSubscriptions[channelName] = prevValue + 1;
    },
    removeSubscriberFromChannel: (sliceState, { payload }) => {
      const { channelName } = payload;
      const prevValue = sliceState.activeSubscriptions[channelName] || 0;
      sliceState.activeSubscriptions[channelName] = prevValue - 1;
    },
    clearChannelSubscriptions: (sliceState, { payload }) => {
      const { channelName } = payload;
      delete sliceState.activeSubscriptions[channelName];
    }
  }
});

export const { addSubscriberToChannel, removeSubscriberFromChannel, clearChannelSubscriptions } =
  pusherSlice.actions;

export default pusherSlice.reducer;
