import React from 'react';

export default function ListWidgetIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.00016 6.70834C4.56294 6.70834 4.2085 7.06278 4.2085 7.50001C4.2085 7.93724 4.56294 8.29168 5.00016 8.29168H5.00891C5.44614 8.29168 5.80058 7.93724 5.80058 7.50001C5.80058 7.06278 5.44614 6.70834 5.00891 6.70834H5.00016Z"
        fill="currentColor"
      />
      <path
        d="M9.5 7C9.22386 7 9 7.22386 9 7.5C9 7.77614 9.22386 8 9.5 8H19.5C19.7761 8 20 7.77614 20 7.5C20 7.22386 19.7761 7 19.5 7H9.5Z"
        fill="currentColor"
      />
      <path
        d="M9.3335 11.5C9.05735 11.5 8.8335 11.7239 8.8335 12C8.8335 12.2761 9.05735 12.5 9.3335 12.5H19.5C19.7761 12.5 20 12.2761 20 12C20 11.7239 19.7761 11.5 19.5 11.5H9.3335Z"
        fill="currentColor"
      />
      <path
        d="M8.8335 16.5C8.8335 16.2239 9.05735 16 9.3335 16H19.6252C19.9013 16 20.1252 16.2239 20.1252 16.5C20.1252 16.7761 19.9013 17 19.6252 17H9.3335C9.05735 17 8.8335 16.7761 8.8335 16.5Z"
        fill="currentColor"
      />
      <path
        d="M4.2085 12C4.2085 11.5628 4.56294 11.2083 5.00016 11.2083H5.00891C5.44614 11.2083 5.80058 11.5628 5.80058 12C5.80058 12.4372 5.44614 12.7917 5.00891 12.7917H5.00016C4.56294 12.7917 4.2085 12.4372 4.2085 12Z"
        fill="currentColor"
      />
      <path
        d="M5.00016 15.7083C4.56294 15.7083 4.2085 16.0628 4.2085 16.5C4.2085 16.9372 4.56294 17.2917 5.00016 17.2917H5.00891C5.44614 17.2917 5.80058 16.9372 5.80058 16.5C5.80058 16.0628 5.44614 15.7083 5.00891 15.7083H5.00016Z"
        fill="currentColor"
      />
    </svg>
  );
}
