import { Collapse, Stack } from '@mui/material';
import { wrapWithError } from 'components/ErrorBoundaryComponent';
import ResizeableWidthStack from 'components/ResizeableWidthStack';
import MLIconButton from 'design-system/MLIconButton';
import ChevronLeftIcon from 'design-system/icons/basic/ChevronLeftIcon';
import { useWindowSize } from 'hooks/useWindowSize';
import React from 'react';
import { useParams } from 'react-router-dom';
import { getIsInitialState } from 'selectors/assistant';
import { useAppSelectorWithArgs } from 'types/store';

import AssistantUtility from './AssistantUtility';
import AssistantConversation from './conversation/AssistantConversation';
import NewAssistantChat from './initial/NewAssistantChat';
import AssistantPrompt from './prompt/AssistantPrompt';

interface AssistantLeftPaneProps {
  heights: { navBarHeight: number };
  isCollapsed: boolean;
  setIsCollapsed: (isCollapsed: boolean) => void;
}

const collapseButtonSx = {
  cursor: 'pointer',
  height: '28px',
  width: '28px',
  borderRadius: '14px',
  border: '1px solid',
  borderColor: 'colors.ui_border',
  backgroundColor: 'colors.primary_bg',
  '&:hover': {
    backgroundColor: 'colors.hover_on_primary_bg'
  }
};

function getInitialWidth(windowSize: number[]) {
  return Math.max(565, windowSize[0] / 3);
}

function AssistantLeftPane({ heights, isCollapsed, setIsCollapsed }: AssistantLeftPaneProps) {
  const { listId: listIdStr } = useParams();
  const listId = listIdStr ? parseInt(listIdStr, 10) : null;
  const windowSize = useWindowSize();
  const onCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };
  const isInitialState = useAppSelectorWithArgs(getIsInitialState, listId);
  return (
    <Stack
      direction="column"
      sx={{
        overflowX: 'hidden',
        overflowY: 'auto',
        height: `${windowSize[1] - heights.navBarHeight}px`,
        backgroundColor: 'colors.primary_bg',
        borderRight: '1px solid',
        borderColor: 'colors.ui_border'
      }}>
      <Collapse
        in={isCollapsed}
        orientation="horizontal"
        sx={{ flex: isCollapsed ? 1 : 0, height: '100%' }}>
        <Stack
          sx={{
            width: '24px'
          }}>
          <MLIconButton
            onClick={onCollapse}
            sx={{
              ...collapseButtonSx,
              position: 'absolute',
              marginTop: '22px',
              marginLeft: '10px'
            }}>
            <ChevronLeftIcon />
          </MLIconButton>
        </Stack>
      </Collapse>
      <Collapse
        in={!isCollapsed}
        orientation="horizontal"
        sx={{ flex: isCollapsed ? 0 : 1, height: '100%', borderRadius: '1px solid red' }}>
        <ResizeableWidthStack
          maxWidth={700}
          minWidth={565}
          initialWidth={getInitialWidth(windowSize)}
          direction="column"
          closeComponent={
            <MLIconButton
              id="collapse-explore-pannel"
              onClick={onCollapse}
              sx={{
                ...collapseButtonSx,
                transform: 'rotate(180deg)'
              }}>
              <ChevronLeftIcon />
            </MLIconButton>
          }
          sx={{
            backgroundColor: 'colors.primary_bg',
            overflowY: 'hidden',
            outline: 'none',
            zIndex: 1,
            borderRadius: '4px'
          }}>
          {isInitialState ? <NewAssistantChat /> : <AssistantConversation />}
          <Stack sx={{ marginTop: 'auto', padding: '16px' }}>
            <AssistantPrompt listId={listId} />
          </Stack>
        </ResizeableWidthStack>
      </Collapse>
      <AssistantUtility />
    </Stack>
  );
}

export default wrapWithError(AssistantLeftPane);
