import { V1CaParameters } from 'models/assistant/backwards-compatibility';

import { CustomAnalyticsResponse, CustomAnalyticsResponseType } from './CustomAnalyticsResponse';

type Plan = Record<string, any>;

export type Suggestion = {
  prompt: string;
  reasoning: string;
};

type FrontendAddedParamsToBody = {
  total_num_companies?: number | null;
};

type FrontendAddedParamsToPost = {
  query: string;
  status: string;
  statusMessage: string | null;
  records?: Record<number, { isRunning: boolean; recordId: number }> | null;
  currentStepId?: string | null;
  responseType?: CustomAnalyticsResponseType | 'DISCOVERY';
  recordId?: number | null;
  discoveryId?: number | null;
};

export type CustomAnalyticsPost = {
  postId: string;
  body: V1CaParameters & FrontendAddedParamsToBody & { plan?: Plan };
  suggestions: Suggestion[];
} & CustomAnalyticsResponse &
  FrontendAddedParamsToPost;

type WorkflowRunPost = {
  body: { plan: Plan };
} & CustomAnalyticsPost;

type PrepareWorkflowPost = {
  plan: Plan;
} & CustomAnalyticsPost;

export function isWorkflowRunPost(post: CustomAnalyticsPost): post is WorkflowRunPost {
  return post.body.plan && post.body.plan.is_workflow;
}

export function isPrepareWorkflowPost(post: CustomAnalyticsPost): post is PrepareWorkflowPost {
  return post.plan && post.plan.is_workflow;
}
