import React from 'react';

export default function LeftLeadershipIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.0667 10L16.7649 6.15987C16.8435 5.72759 16.3356 5.43614 16.002 5.72207L14.4664 7.03832C14.0656 7.38185 13.4599 7.32492 13.1302 6.91273L10.6869 3.85867C10.3271 3.40887 9.64972 3.3892 9.26438 3.81735L6.45809 6.93545C6.10037 7.33292 5.4825 7.34917 5.10439 6.97105L3.97474 5.84141C3.65918 5.52585 3.12321 5.79072 3.18219 6.23307L3.93333 11.8667"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
      />
      <path
        d="M8.13333 14.2H3.93333C3.41787 14.2 3 13.7821 3 13.2667V12.8C3 12.2845 3.41787 11.8667 3.93333 11.8667H10"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
      />
      <path
        d="M13 13L17 17"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 13L13 17"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
