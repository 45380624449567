import { Divider, Stack } from '@mui/material';
import { CustomAnalyticsMessagesItem } from 'components/custom-analytics/types/CustomAnalyticsResponse';
import { getCustomScrollbarStyles } from 'design-system/styles/customScrollBar';
import { MLTooltipProvider } from 'hooks/useMLTooltip';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getCompanyMetasMergedRowsMapByListId } from 'selectors/companyMetas';
import { isEmpty } from 'utils/objects-utils';

import CompanyAnswerRow from './CompanyAnswerRow';
import SingleCompanyAnswerDialog from './SingleCompanyAnswerDialog';

type Props = {
  messages: CustomAnalyticsMessagesItem[];
  listId: number;
  selectedCompaniesIds?: number[] | null;
};
export default function CompaniesAnswerComponent({
  messages,
  listId,
  selectedCompaniesIds
}: Props) {
  const [selectedCompanyId, setSelectedCompanyId] = React.useState<number | null>(null);

  const companies: Record<string, any> = useSelector((state) =>
    getCompanyMetasMergedRowsMapByListId(state, listId)
  );

  const companiesByMetaId = useMemo(() => {
    if (isEmpty(companies)) {
      return companies;
    }
    return Object.values(companies).reduce((acc, company) => {
      return {
        ...acc,
        [company.companyMetaId]: company
      };
    }, {});
  }, [companies]);

  const messagesRows = useMemo(() => {
    if (!messages) return null;
    return messages
      .filter(
        (row) =>
          companiesByMetaId[row.company_meta_id] &&
          (!selectedCompaniesIds || selectedCompaniesIds.includes(row.company_meta_id))
      )
      .map(({ company_meta_id, message, loading }) => {
        return {
          companyName: companiesByMetaId[company_meta_id]?.name,
          companyLogo: companiesByMetaId[company_meta_id].logo_url,
          isLoading: loading || false,
          id: company_meta_id,
          message
        };
      })
      .sort((row1, row2) =>
        row1.companyName.localeCompare(row2.companyName, {
          sensitivity: 'base'
        })
      );
  }, [messages, companiesByMetaId, selectedCompaniesIds]);

  const onClickRow = (newId: number) => {
    setSelectedCompanyId(newId);
  };

  if (!messagesRows) return null;

  const possibleCompanies = messagesRows.filter((row) => !row.isLoading);

  return (
    <Stack
      maxHeight="280px"
      sx={{
        overflowY: 'auto',
        boxSizing: 'border-box',
        ...getCustomScrollbarStyles({ size: 'small' })
      }}>
      {messagesRows?.map((row) => (
        <Stack key={row.companyName}>
          <MLTooltipProvider>
            <CompanyAnswerRow
              companyName={row.companyName}
              companyLogo={row.companyLogo}
              isLoading={row.isLoading}
              onClickRow={() => !row.isLoading && onClickRow(row.id)}
              message={row.message}
            />
          </MLTooltipProvider>
          <Divider />
        </Stack>
      ))}
      <SingleCompanyAnswerDialog
        listId={listId}
        selectedCompanyId={selectedCompanyId}
        setSelectedCompanyId={setSelectedCompanyId}
        possibleCompanies={possibleCompanies}
      />
    </Stack>
  );
}
