import { Box, Stack, Typography } from '@mui/material';
import {
  CustomAnalyticsPost,
  isWorkflowRunPost
} from 'components/custom-analytics/types/CustomAnalyticsPost';
import CustomAnalyticsWorkflowMessageComponent from 'components/discovery/CustomAnalyticsWorkflowMessageComponent';
import MLShimmeringStack from 'design-system/MLShimmeringStack';
import React, { useMemo } from 'react';
import { extractNodesAndEdges } from 'utils/work-flow-planner-utils';

type Props = {
  post: CustomAnalyticsPost;
  listId: number;
  isForEach: boolean;
};

export default function PostStatusComponent({ post, listId, isForEach }: Props) {
  const totalNumCompanies = post.body?.total_num_companies || 0;
  const messagesCount = post?.messages?.filter((mes) => mes?.message?.answer)?.length || 0;

  const stepIdToLabel = useMemo(() => {
    if (isWorkflowRunPost(post)) {
      const runningPlan = post.body.plan;
      if (runningPlan) {
        const { nodes } = extractNodesAndEdges(runningPlan.steps, listId, post.postId);
        return nodes
          ?.filter((node: any) => !node.id.includes('-'))
          .reduce((acc: any, node: any) => {
            if (node.type === 'workFlowGroup') {
              acc[node.id] = `${node.data.label} - running ${node.data.numberOfChildrens} queries`;
            } else {
              acc[node.id] = `${node.data.label} - ${
                node.data.attributeTitle ||
                node.data.attributeDefaultTitle ||
                node.data.attributeName
              }`;
            }
            return acc;
          }, {});
      }
    }
    return [];
  }, [post, listId]);

  const statusPercentages = useMemo(() => {
    if (
      isForEach &&
      messagesCount > 0 &&
      (parseFloat(post.status?.replace('%', '')) < (messagesCount * 100) / totalNumCompanies ||
        post.status === '100%')
    ) {
      return `${Math.round((messagesCount * 100) / totalNumCompanies)}%`;
    }
    if (isWorkflowRunPost(post)) {
      const steps = post.body.plan?.steps;
      const { currentStepId } = post;
      if (!currentStepId && !steps) {
        return '0%';
      }
      if (currentStepId === 'end') {
        return '100%';
      }
      const indexOfCurrentStep = currentStepId
        ? steps.findIndex((step: any) => step.step_id === currentStepId)
        : 0;
      return `${Math.round((indexOfCurrentStep / steps.length) * 100)}%`;
    }
    return post.status || '0%';
  }, [messagesCount, post, totalNumCompanies, isForEach]);

  const statusMessage = useMemo(() => {
    if (isForEach) {
      if (messagesCount === 0) {
        return post.statusMessage || `Waiting for ${totalNumCompanies} companies`;
      }
      return `${messagesCount} / ${totalNumCompanies} company results`;
    }
    if (isWorkflowRunPost(post)) {
      const { currentStepId } = post;
      if (!stepIdToLabel) {
        return post.statusMessage;
      }

      return stepIdToLabel[currentStepId || 'start'];
    }
    return post.statusMessage;
  }, [messagesCount, post, totalNumCompanies, stepIdToLabel, isForEach]);

  return (
    <Stack gap="16px">
      <Stack direction="row" alignItems="center" gap="16px">
        <MLShimmeringStack sx={{ opacity: 1 }}>
          <Typography color="colors.primary" variant="text1_Normal">
            {statusPercentages} -{' '}
            {statusMessage && statusMessage.length > 0 ? statusMessage : 'Loading...'}
          </Typography>
        </MLShimmeringStack>
        <Box marginLeft="auto">
          {isWorkflowRunPost(post) && (
            <CustomAnalyticsWorkflowMessageComponent
              listId={listId}
              postId={post.postId}
              isWorflowRun={true}
              statusMessage={null}
            />
          )}
        </Box>
      </Stack>
    </Stack>
  );
}
