import { WORKFLOW_STATUSES } from 'constants/workflows';
import { addPostAnswerToThread, getThreadKey } from 'reducer/custom-analytics';
import { updateWorkflowStatusByIdAndListId } from 'reducer/workflows-slice';
import { getCustomAnalyticsThreadSpecificPostById } from 'selectors/custom-analytics';

export function stopWorkflowAction(runId, listId, postId) {
  return (dispatch, getState, apiClient) => {
    const currentStepId = getCustomAnalyticsThreadSpecificPostById(
      getState(),
      getThreadKey(listId),
      postId
    )?.currentStepId;
    dispatch(
      addPostAnswerToThread({
        threadKey: getThreadKey(listId),
        postId,
        message: { answer: 'Workflow was canceled' },
        currentStepId: 'end'
      })
    );
    dispatch(
      updateWorkflowStatusByIdAndListId({
        listId,
        workflowId: postId,
        status: WORKFLOW_STATUSES.STOPING
      })
    );
    apiClient.runs
      .stopWorkflow(runId, currentStepId)
      .then((res) => {
        dispatch(
          updateWorkflowStatusByIdAndListId({
            listId,
            workflowId: postId,
            status: res.ok ? WORKFLOW_STATUSES.STOPED : WORKFLOW_STATUSES.STOP_FAILED
          })
        );
      })
      .catch(() => {
        dispatch(
          updateWorkflowStatusByIdAndListId({
            listId,
            workflowId: postId,
            status: WORKFLOW_STATUSES.STOP_FAILED
          })
        );
      });
  };
}
