import React from 'react';

export default function HiddenGemsIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.5 4H13.5L17 8.42361L10 16.25L3 8.42361L6.5 4Z"
        stroke="currentColor"
        strokeWidth="1.3125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 16.25L12.625 8.42361L10 4L7.375 8.42361L10 16.25Z"
        stroke="currentColor"
        strokeWidth="1.3125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 8.375H17"
        stroke="currentColor"
        strokeWidth="1.3125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
