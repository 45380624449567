import { WORKFLOW_SELECTED_TYPE, WORKFLOW_STATUSES } from 'constants/workflows';
import { createSelector } from 'reselect';

export const getWorkflowsSlice = (state) => state.workflows;
export const getWorkflows = (state) => getWorkflowsSlice(state).workflows;
export const getWorkflowsHistory = (state) => getWorkflowsSlice(state).history;
export const getWorkflowsByListId = (state, listId) => getWorkflows(state)[listId];
export const getWorkflowSelectedPostIdByListId = (state, listId) =>
  getWorkflowsByListId(state, listId)?.selectedWorkflowId;
export const getWorkflowSelectedTypeByListId = (state, listId) =>
  getWorkflowsByListId(state, listId)?.selectedType;
export const getWorkflowIsHistoryModeByListId = (state, listId) =>
  getWorkflowsByListId(state, listId)?.isHistoryMode;
export const getWorkflowByListIdAndWorkflowId = (state, listId, workflowId) =>
  getWorkflowsByListId(state, listId)?.[workflowId];
export const getWorkflowStatusByListIdAndWorkflowId = (state, listId, workflowId) =>
  getWorkflowByListIdAndWorkflowId(state, listId, workflowId)?.status;
export const getWorkflowInfoByListIdAndWorkflowId = (state, listId, workflowId) =>
  getWorkflowByListIdAndWorkflowId(state, listId, workflowId)?.info;
export const getWorkflowModeByListIdAndWorkflowId = (state, listId, workflowId) =>
  getWorkflowByListIdAndWorkflowId(state, listId, workflowId)?.isFormMode;
export const getWorkflowStepsStatusesByListIdAndWorkflowId = (state, listId, workflowId) =>
  getWorkflowByListIdAndWorkflowId(state, listId, workflowId)?.stepsStatuses;
export const getWorkflowsHistoryByListId = (state, listId) => getWorkflowsHistory(state)[listId];
export const getWorkflowHistoryByListIdAndWorkflowId = (state, listId, workflowId) =>
  getWorkflowsHistoryByListId(state, listId)?.[workflowId];
export const getWorkflowHistoryPlanByListIdAndWorkflowId = (state, listId, workflowId) =>
  getWorkflowHistoryByListIdAndWorkflowId(state, listId, workflowId)?.parameters?.workflow;
export const getWorkflowHistoryNameByListIdAndWorkflowId = (state, listId, workflowId) =>
  getWorkflowHistoryPlanByListIdAndWorkflowId(state, listId, workflowId)?.name;

export const getIsWorkflowReadOnlyModeByListId = (state, listId) => {
  const selectedType = getWorkflowSelectedTypeByListId(state, listId);
  return (
    selectedType === WORKFLOW_SELECTED_TYPE.HISTORY ||
    selectedType === WORKFLOW_SELECTED_TYPE.LIBRARY_READONLY
  );
};
export const getCurrentlyRunningWorkflowsByListId = createSelector(
  getWorkflowsByListId,
  (workflows) => {
    return workflows
      ? Object.keys(workflows).reduce((acc, workflowId) => {
          if (
            [WORKFLOW_STATUSES.LAUNCHING, WORKFLOW_STATUSES.LAUNCHED].includes(
              workflows[workflowId]?.status
            )
          ) {
            acc[workflowId] = workflows[workflowId];
          }
          return acc;
        }, {})
      : {};
  }
);

export const getWorkflowIdByRecordId = (state, listId, customAnalyticsRecordId) => {
  const workflows = getWorkflowsByListId(state, listId);
  return workflows
    ? Object.keys(workflows).find(
        (workflowId) => workflows[workflowId]?.customAnalyticsRecordId === customAnalyticsRecordId
      )
    : null;
};
