import { Divider, Stack } from '@mui/material';
import { useCurrentEditor } from '@tiptap/react';
import { submitAssistantMessageAction } from 'actions/assistant/submit-assistant-message-action';
import QueryLibraryDialog from 'components/elements/query-library/QueryLibraryDialog';
import {
  ACTION_TYPES_WITH_ANSWER_TYPE,
  ACTION_TYPES_WITH_CONFIG,
  ACTION_TYPES_WITH_SCOPING,
  ACTION_TYPES_WITH_SEARCH_SETTINGS,
  TT_TRIGGER_CHAR
} from 'constants/assistant';
import MLIconButton from 'design-system/MLIconButton';
import SlashIcon from 'design-system/icons/basic/SlashIcon';
import SendIcon from 'design-system/icons/platform/SendIcon';
import PreviewIcon from 'icons/PreviewIcon';
import { ActionType } from 'models/assistant/assistant-actions';
import { ExtendedEditor } from 'models/assistant/tiptap';
import React from 'react';
import { updatePromptConfig } from 'reducer/assistant-slice';
import { getPromptConfigActionType } from 'selectors/assistant';
import { useAppDispatch, useAppSelector } from 'types/store';

import AssistantActionButton from './buttons/AssistantActionButton';
import AssistantAnswerTypeButton from './buttons/AssistantAnswerTypeButton';
import AssistantChatMenuButton from './buttons/AssistantChatMenuButton';
import AssistantScopingButton from './buttons/AssistantScopingButton';
import AssistantWebTableButton from './buttons/AssistantWebTableButton';

interface AssistantActionsBarProps {
  promptRef: React.RefObject<HTMLDivElement>;
  listId: number | null;
}

function AssistantActionsBar({ promptRef, listId }: AssistantActionsBarProps) {
  const { editor } = useCurrentEditor();
  const dispatch = useAppDispatch();
  const action_type = useAppSelector(getPromptConfigActionType);

  const shouldShowAnswerType =
    action_type &&
    [ActionType.ADD_COLUMN, ActionType.ASK_QUESTION, ActionType.PREVIEW_COLUMN].includes(
      action_type
    );

  const handleSubmitAndClearInput = () => {
    if (!editor) return;
    dispatch(submitAssistantMessageAction({ listId }));
    editor.chain().focus().clearContent().run();
  };

  const handlePreviewResults = () => {
    dispatch(updatePromptConfig({ action_type: ActionType.PREVIEW_COLUMN }));
    handleSubmitAndClearInput();
  };

  const handleContext = () => {
    if (!editor) return;
    if ((editor as ExtendedEditor).view.state.mention$.active) {
      const { range } = (editor as ExtendedEditor).view.state.mention$;
      if (!range) return;
      const { from, to } = range;
      editor
        .chain()
        .focus()
        .deleteRange({ from: Math.max(0, from - 1), to })
        .run();
      return;
    }
    editor.chain().focus().insertContent(` ${TT_TRIGGER_CHAR}`).run();
  };

  return (
    <Stack
      direction="row"
      gap="8px"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      sx={{ marginTop: 'auto' }}>
      <Stack direction="row" gap="8px" alignItems="center">
        <AssistantActionButton promptRef={promptRef} />
        <MLIconButton tooltipText="Context" onClick={handleContext}>
          <SlashIcon />
        </MLIconButton>
        {action_type && ACTION_TYPES_WITH_CONFIG.includes(action_type) && (
          <Divider flexItem orientation="vertical" />
        )}
        {action_type && ACTION_TYPES_WITH_SEARCH_SETTINGS.includes(action_type) && (
          <AssistantWebTableButton />
        )}
        {action_type && ACTION_TYPES_WITH_SCOPING.includes(action_type) && (
          <AssistantScopingButton />
        )}

        {action_type &&
          shouldShowAnswerType &&
          ACTION_TYPES_WITH_ANSWER_TYPE.includes(action_type) && <AssistantAnswerTypeButton />}
      </Stack>
      <Stack direction="row" gap="8px" alignItems="center">
        <AssistantChatMenuButton />
        {listId && <QueryLibraryDialog listId={listId} />}
        {action_type === ActionType.ADD_COLUMN && (
          <MLIconButton tooltipText="Preview Results" onClick={handlePreviewResults}>
            <PreviewIcon />
          </MLIconButton>
        )}
        <MLIconButton onClick={handleSubmitAndClearInput} variant="contained">
          <SendIcon />
        </MLIconButton>
      </Stack>
    </Stack>
  );
}

export default AssistantActionsBar;
