import { updateSubdomainDiscoveriesFromHistoryAction } from 'actions/domain-research/update-subdomains-discoveries-action';
import { RecordType } from 'constants/researches';
import { updateExploreRunsSummary } from 'reducer/explore-slice';

import { translateDiscoveryRecordForState } from './load-all-explores-action';

export function fetchExploreRunsSummaryAction(listId) {
  return (dispatch, getState, apiClient) => {
    apiClient.explore.fetchExploreRunsSummary(listId).then((res) => {
      if (res.ok && res.data) {
        const { list_history, records } = res.data;
        const history = list_history?.history;
        const discoveryRecords = records
          ?.map(translateDiscoveryRecordForState)
          ?.filter((record) => record.recordType === RecordType.DISCOVERY);
        dispatch(updateExploreRunsSummary({ listId, history, discoveryRecords }));

        dispatch(updateSubdomainDiscoveriesFromHistoryAction(listId, history));
      }
    });
  };
}
