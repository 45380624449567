import React from 'react';

export default function SendIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2275 4.11731C19.0456 4.06982 18.8544 4.07167 18.6734 4.12266C18.6675 4.12432 18.6617 4.12609 18.6558 4.12797L4.75886 8.60123C4.55573 8.66075 4.37503 8.77988 4.24025 8.94331C4.10323 9.10945 4.02019 9.31346 4.00222 9.52806C3.98426 9.74266 4.03222 9.95764 4.1397 10.1442C4.24718 10.3308 4.40909 10.4802 4.60372 10.5724L10.5729 13.3987C10.5778 13.401 10.5824 13.4041 10.5864 13.4077C10.5874 13.4087 10.5883 13.4097 10.5893 13.4106C10.5899 13.4113 10.5906 13.4119 10.5912 13.4125C10.5948 13.4166 10.5979 13.4211 10.6002 13.4261L13.4272 19.3944C13.5193 19.5891 13.6687 19.751 13.8553 19.8584C14.0419 19.9659 14.2569 20.0139 14.4715 19.9959C14.6861 19.978 14.8901 19.8949 15.0562 19.7579C15.219 19.6237 15.3378 19.4439 15.3976 19.2417L19.9718 5.44944C19.9741 5.44224 19.9764 5.43498 19.9784 5.42767C20.0294 5.24673 20.0313 5.05547 19.9838 4.87358C19.9363 4.69169 19.8412 4.52574 19.7083 4.39282C19.5754 4.25989 19.4094 4.16479 19.2275 4.11731ZM11.5699 13.1372L14.331 18.9665C14.336 18.9771 14.3442 18.986 14.3544 18.9919C14.3646 18.9978 14.3763 19.0004 14.388 18.9994C14.3998 18.9984 14.4109 18.9939 14.42 18.9864C14.429 18.9789 14.4356 18.9689 14.4388 18.9575C14.4409 18.9503 14.4431 18.9431 14.4454 18.936L19.0172 5.15103C19.0187 5.14279 19.0183 5.13431 19.0162 5.12618C19.0136 5.11624 19.0084 5.10718 19.0012 5.09992C18.9939 5.09267 18.9849 5.08747 18.9749 5.08488C18.9664 5.08267 18.9576 5.08242 18.949 5.08413L5.058 9.55547C5.05223 9.55733 5.04644 9.55908 5.04061 9.56073C5.02929 9.56392 5.01922 9.57049 5.01173 9.57956C5.00425 9.58864 4.99972 9.59978 4.99874 9.61149C4.99776 9.62321 5.00038 9.63495 5.00624 9.64514C5.01211 9.65533 5.02095 9.66348 5.03158 9.66851L10.8631 12.4297L14.1855 9.10738C14.3807 8.91212 14.6973 8.91212 14.8926 9.10738C15.0878 9.30265 15.0878 9.61923 14.8926 9.81449L11.5699 13.1372Z"
        fill="currentColor"
      />
    </svg>
  );
}
