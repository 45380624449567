import { Box, Typography } from '@mui/material';
import { highlightColumnAction } from 'actions/explore-table-ui/highlight-column-action';
import MLButton from 'design-system/MLButton';
import MLInlineStack from 'design-system/MLInlineStack';
import ArrowIcon from 'icons/ArrowIcon';
import React from 'react';
import { useSelector } from 'react-redux';
import { getCustomAnalyticDataTypeByPostIdMap } from 'selectors/custom-analytics';
import {
  getCompanyListTotalCompaniesByListId,
  getListCustomColumnNameByListIdAndColumnId
} from 'selectors/explore';
import { useAppDispatch } from 'types/store';

type Props = {
  listId: number;
  postId: string;
  numSelectedCompanies: number;
  showAnswers: boolean;
  setShowAnswers: (showAnswers: boolean) => void;
};

type ColumnCreatedComponentProps = {
  listId: number;
  dataType: string;
  numCompanies?: number | null;
};

export function ColumnCreatedComponent({
  listId,
  dataType,
  numCompanies
}: ColumnCreatedComponentProps) {
  const dispatch = useAppDispatch();
  const columnName = useSelector((state) =>
    getListCustomColumnNameByListIdAndColumnId(state, listId, dataType)
  );
  const onGoToColumn = () => {
    dispatch(highlightColumnAction(listId, dataType, true));
  };

  return (
    <Typography variant="text1_Normal" textAlign="start">
      {'Column '}
      <Typography
        variant="text1_Bold"
        textAlign="start"
        color="colors.link"
        sx={{ cursor: 'pointer' }}
        onClick={onGoToColumn}>
        {columnName}
      </Typography>
      {' created'}
      {numCompanies && numCompanies > 0 && ` for ${numCompanies} selected companies`}.
    </Typography>
  );
}

export default function ColumnCreatedMessageComponent({
  listId,
  postId,
  numSelectedCompanies,
  showAnswers,
  setShowAnswers
}: Props) {
  const dataType = useSelector((state) => getCustomAnalyticDataTypeByPostIdMap(state, postId));

  const numCompanies =
    useSelector((state) => getCompanyListTotalCompaniesByListId(state, listId)) ===
    numSelectedCompanies
      ? null
      : numSelectedCompanies;

  return (
    <MLInlineStack
      gap="16px"
      justifyContent="space-between"
      width="100%"
      sx={{ marginTop: '-8px' }}>
      {dataType && (
        <ColumnCreatedComponent listId={listId} dataType={dataType} numCompanies={numCompanies} />
      )}
      <MLButton
        variant="text"
        endIcon={
          <Box sx={{ transform: showAnswers ? 'rotate(0deg)' : 'rotate(180deg)' }}>
            <ArrowIcon />
          </Box>
        }
        onClick={() => setShowAnswers(!showAnswers)}
        sx={{ marginLeft: 'auto', textWrap: 'nowrap', minWidth: '120px' }}>
        View results
      </MLButton>
    </MLInlineStack>
  );
}
