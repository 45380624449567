import React from 'react';

function NumberTypeIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.992 5.08953C16.0414 4.81784 15.8612 4.55755 15.5895 4.50815C15.3178 4.45875 15.0575 4.63896 15.0082 4.91064L14.2873 8.87512H10.8037L11.492 5.08953C11.5414 4.81784 11.3612 4.55755 11.0895 4.50815C10.8178 4.45875 10.5575 4.63896 10.5082 4.91064L9.78734 8.87512H5.75C5.47386 8.87512 5.25 9.09898 5.25 9.37512C5.25 9.65126 5.47386 9.87512 5.75 9.87512H9.60552L8.87825 13.8751H4.5C4.22386 13.8751 4 14.099 4 14.3751C4 14.6513 4.22386 14.8751 4.5 14.8751H8.69643L8.00815 18.6606C7.95875 18.9323 8.13896 19.1926 8.41064 19.242C8.68233 19.2914 8.94262 19.1112 8.99202 18.8395L9.71282 14.8751H13.1964L12.5082 18.6606C12.4588 18.9323 12.639 19.1926 12.9106 19.242C13.1823 19.2914 13.4426 19.1112 13.492 18.8395L14.2128 14.8751H18.25C18.5261 14.8751 18.75 14.6513 18.75 14.3751C18.75 14.099 18.5261 13.8751 18.25 13.8751H14.3946L15.1219 9.87512H19.5C19.7761 9.87512 20 9.65126 20 9.37512C20 9.09898 19.7761 8.87512 19.5 8.87512H15.3037L15.992 5.08953ZM13.3782 13.8751L14.1055 9.87512H10.6219L9.89464 13.8751H13.3782Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default NumberTypeIcon;
