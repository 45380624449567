import React from 'react';

export default function LinkIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.7695 4.00004C14.6464 3.99003 13.5669 4.43299 12.763 5.22944L11.4618 6.55619C11.2684 6.75334 11.2715 7.06991 11.4686 7.26326C11.6658 7.45661 11.9824 7.45352 12.1757 7.25637L13.4692 5.93742C14.0861 5.32757 14.909 4.99241 15.7606 5C16.6133 5.0076 17.4316 5.35832 18.0385 5.98078C18.6458 6.60365 18.9924 7.44883 18.9999 8.33492C19.0074 9.2196 18.6762 10.0696 18.0813 10.7031L15.8237 13.0185C15.4918 13.3591 15.0931 13.6216 14.6553 13.7892C14.2174 13.9567 13.75 14.0255 13.2846 13.9913C12.8191 13.9572 12.3657 13.8207 11.9551 13.5906C11.5444 13.3604 11.1857 13.0418 10.904 12.6556C10.7413 12.4324 10.4285 12.3835 10.2054 12.5462C9.9823 12.7089 9.93334 13.0217 10.0961 13.2448C10.4616 13.746 10.9287 14.1617 11.4662 14.4629C12.0038 14.7642 12.599 14.9437 13.2113 14.9887C13.8237 15.0336 14.438 14.943 15.0126 14.7232C15.5871 14.5033 16.1078 14.1598 16.5398 13.7165L18.8004 11.398L18.8064 11.3917C19.5823 10.5678 20.0095 9.4668 19.9999 8.32645C19.9902 7.18608 19.5443 6.09279 18.7545 5.28268C17.9643 4.47217 16.8926 4.01005 15.7695 4.00004Z"
        fill="currentColor"
      />
      <path
        d="M10.7985 9.00133C10.1861 8.95635 9.57176 9.04701 8.9972 9.26683C8.42272 9.48663 7.90196 9.83027 7.46998 10.2735L5.20935 12.592L5.2034 12.5983C4.42754 13.4222 4.00026 14.5232 4.00993 15.6635C4.01959 16.8039 4.46546 17.8972 5.2553 18.7073C6.04555 19.5178 7.11723 19.9799 8.24029 19.9899C9.36336 20 10.4429 19.5571 11.2469 18.7606L12.5415 17.4328C12.7343 17.235 12.7303 16.9185 12.5326 16.7257C12.3349 16.5329 12.0183 16.5369 11.8255 16.7347L10.5401 18.0531C9.92323 18.6626 9.10053 18.9976 8.2492 18.99C7.39649 18.9824 6.5782 18.6317 5.97131 18.0092C5.36402 17.3863 5.0174 16.5412 5.00989 15.6551C5.00239 14.7704 5.33358 13.9203 5.92855 13.2869L8.18611 10.9714C8.51803 10.6309 8.91666 10.3683 9.35454 10.2008C9.79239 10.0333 10.2598 9.96445 10.7252 9.99864C11.1907 10.0328 11.6441 10.1693 12.0547 10.3994C12.4654 10.6296 12.8241 10.9482 13.1058 11.3344C13.2685 11.5575 13.5813 11.6065 13.8044 11.4438C14.0275 11.2811 14.0765 10.9683 13.9137 10.7452C13.5482 10.244 13.0811 9.82828 12.5436 9.52706C12.006 9.22581 11.4108 9.04631 10.7985 9.00133Z"
        fill="currentColor"
      />
    </svg>
  );
}
