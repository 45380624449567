import { Typography } from '@mui/material';
import React, { forwardRef } from 'react';

import { MentionListBaseProps } from './MentionList';
import { MentionListAutocompleteHandle } from './MentionListAutocomplete';

const MentionListNoResults = forwardRef<MentionListAutocompleteHandle, MentionListBaseProps>(() => {
  return <Typography>No results found!</Typography>;
});

MentionListNoResults.displayName = 'MentionListNoResults';

export default MentionListNoResults;
