import { loadCompaniesAndUpdateColumns } from 'actions/company_extra_data/update-extra-data-for-companies-in-list-action';
import { highlightColumnAction } from 'actions/explore-table-ui/highlight-column-action';
import { addExploreCustomColumn } from 'reducer/explore-slice';

export function fetchCustomColumnOfListAction(listId, customColumnId, highlightColumn = false) {
  return (dispatch, getState, apiClient) => {
    apiClient.companiesLists.getListCustomColumns(listId).then((res) => {
      if (res.ok) {
        const { custom_columns: customColumns } = res.data;
        const columnSetting = customColumns[customColumnId];
        if (columnSetting) {
          dispatch(addExploreCustomColumn({ listId, columnSetting }));
          dispatch(loadCompaniesAndUpdateColumns(listId, customColumnId));
          if (highlightColumn) {
            dispatch(highlightColumnAction(listId, customColumnId, true));
          }
        }
      }
    });
  };
}
