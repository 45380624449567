import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import AssistantPromptConfig, {
  AssistantPromptConfigJSON
} from 'models/assistant/AssistantPromptConfig';
import AssistantThread, { createNewThreadForState } from 'models/assistant/AssistantThread';
import { AssistantResponse } from 'models/assistant/api';
import { AssistantAction } from 'models/assistant/assistant-actions';
import { MessageRole, SearchSettings } from 'models/assistant/types';
import { generateDefaultNewConversationMessage } from 'utils/assistant/conversation';
import { v4 as uuidv4 } from 'uuid';

const initialThread = createNewThreadForState();

interface AssistantUtility {
  scrollToEnd: boolean;
  navigateToViewId: number | null;
  companyMetaIdsToFetch: number[];
  showLoading?: boolean;
}

interface AssistantSliceState {
  threads: {
    [key: string]: AssistantThread;
  };
  promptConfig: AssistantPromptConfigJSON;
  utility: AssistantUtility;
}

const initialSliceState: AssistantSliceState = {
  threads: {
    [initialThread.id]: initialThread
  },
  promptConfig: new AssistantPromptConfig().toJSON(),
  utility: {
    scrollToEnd: true,
    navigateToViewId: null,
    companyMetaIdsToFetch: []
  }
};

interface UpdateThreadPayload {
  thread: AssistantThread;
}

interface UpdateThreadFolderIdPayload {
  threadId: string;
  folder_id: number;
}

interface UpdateThreadCompanyListIdPayload {
  threadId: string;
  company_list_id: number | null;
}

interface ProcessAssistantResponsePayload {
  threadId: string;
  response: AssistantResponse;
}

interface updateActionAfterSubmissionPayload {
  threadId: string;
  submittedActionId: string;
  response: AssistantResponse;
}

type StartNewThreadPayload = PayloadAction<Partial<AssistantThread>>;

export const assistantSlice = createSlice({
  name: 'assistant',
  initialState: initialSliceState,
  reducers: {
    updateThread: (
      sliceState: AssistantSliceState,
      { payload }: PayloadAction<UpdateThreadPayload>
    ) => {
      const { thread } = payload;
      sliceState.threads[thread.id] = thread;
    },
    updateThreadFolderId: (
      sliceState: AssistantSliceState,
      { payload }: PayloadAction<UpdateThreadFolderIdPayload>
    ) => {
      const { threadId, folder_id } = payload;
      if (sliceState.threads[threadId].messages.length > 0) {
        const newThread = createNewThreadForState({ folder_id });
        sliceState.threads[newThread.id] = newThread;
      } else {
        sliceState.threads[threadId].folder_id = folder_id;
      }
    },
    updateThreadCompanyListId: (
      sliceState: AssistantSliceState,
      { payload }: PayloadAction<UpdateThreadCompanyListIdPayload>
    ) => {
      const { threadId, company_list_id } = payload;
      if (sliceState.threads[threadId].messages.length > 0) {
        const newThread = createNewThreadForState({
          company_list_id: company_list_id
        });
        sliceState.threads[newThread.id] = newThread;
      } else {
        sliceState.threads[threadId].company_list_id = company_list_id;
      }
    },
    processAssistantResponse: (
      sliceState: AssistantSliceState,
      { payload }: PayloadAction<ProcessAssistantResponsePayload>
    ) => {
      const { threadId, response } = payload;
      const { message, action, submission_info } = response;
      const actionId = action?.id || uuidv4();
      if (message) {
        const newMessage = { ...message };
        if (action) {
          newMessage.actionId = actionId;
        }
        sliceState.threads[threadId].messages.push(newMessage);
      }
      if (action) {
        sliceState.threads[threadId].actions[actionId] = {
          ...action,
          id: actionId,
          submission_info
        };
      }
      sliceState.utility.scrollToEnd = true;
    },
    startNewThread: (sliceState: AssistantSliceState, { payload }: StartNewThreadPayload) => {
      const newThread = createNewThreadForState(payload);
      sliceState.threads[newThread.id] = newThread;
    },
    updateActionParams: (
      sliceState: AssistantSliceState,
      {
        payload
      }: PayloadAction<{
        threadId: string;
        actionId: string;
        newAction: AssistantAction;
      }>
    ) => {
      const { threadId, actionId, newAction } = payload;
      sliceState.threads[threadId].actions[actionId] = { ...newAction };
    },
    updateActionAfterSubmission: (
      sliceState: AssistantSliceState,
      { payload }: PayloadAction<updateActionAfterSubmissionPayload>
    ) => {
      const { threadId, response, submittedActionId } = payload;
      const action = sliceState.threads[threadId].actions[submittedActionId];
      if (action && response.action && response.action.action_type === action.action_type) {
        sliceState.threads[threadId].actions[submittedActionId] = {
          ...action,
          ...response.action,
          submission_info: response.submission_info,
          id: submittedActionId
        } as typeof response.action;
      }
    },
    updateActionSubmissionInfo: (
      sliceState: AssistantSliceState,
      {
        payload
      }: PayloadAction<{
        threadId: string;
        actionId: string;
        submissionInfo: AssistantAction['submission_info'];
      }>
    ) => {
      const { threadId, actionId, submissionInfo } = payload;
      sliceState.threads[threadId].actions[actionId].submission_info = submissionInfo;
    },
    addErrorMessageToThread: (
      sliceState: AssistantSliceState,
      { payload }: PayloadAction<{ threadId: string; errorMessage: string }>
    ) => {
      const { threadId, errorMessage } = payload;
      if (sliceState.threads[threadId]) {
        sliceState.threads[threadId].messages.push({
          id: uuidv4(),
          role: MessageRole.ASSISTANT,
          content: {
            text: errorMessage,
            raw_content: null
          }
        });
      }
    },
    newPromptConfig: (
      sliceState: AssistantSliceState,
      { payload }: PayloadAction<Partial<AssistantPromptConfigJSON>>
    ) => {
      sliceState.promptConfig = new AssistantPromptConfig({ ...payload, overwrite: true }).toJSON();
    },
    updatePromptConfig: (
      sliceState: AssistantSliceState,
      { payload }: PayloadAction<Partial<AssistantPromptConfigJSON>>
    ) => {
      const newPromptConfig = new AssistantPromptConfig({ ...sliceState.promptConfig, ...payload });
      if (newPromptConfig.isValid()) {
        sliceState.promptConfig = newPromptConfig.toJSON();
      }
    },
    resetPromptConfig: (sliceState: AssistantSliceState) => {
      sliceState.promptConfig = new AssistantPromptConfig().toJSON();
    },
    disableOverwrite: (sliceState: AssistantSliceState) => {
      sliceState.promptConfig.overwrite = false;
    },
    togglePromptConfigUseWeb: (sliceState: AssistantSliceState) => {
      const newPromptConfig = new AssistantPromptConfig({ ...sliceState.promptConfig });
      newPromptConfig.toggleUseWeb();
      sliceState.promptConfig = newPromptConfig.toJSON();
    },
    togglePromptConfigUseTable: (sliceState: AssistantSliceState) => {
      const newPromptConfig = new AssistantPromptConfig({ ...sliceState.promptConfig });
      newPromptConfig.toggleUseTable();
      sliceState.promptConfig = newPromptConfig.toJSON();
    },
    updatePromptConfigSearchSettings: (
      sliceState: AssistantSliceState,
      { payload }: PayloadAction<{ search_settings: SearchSettings }>
    ) => {
      const { search_settings } = payload;
      const newPromptConfig = new AssistantPromptConfig({ ...sliceState.promptConfig });
      newPromptConfig.updateSearchSettings(search_settings);
      sliceState.promptConfig = newPromptConfig.toJSON();
    },
    updateAssistantUtility: (
      sliceState: AssistantSliceState,
      { payload }: PayloadAction<Partial<AssistantUtility>>
    ) => {
      sliceState.utility = { ...sliceState.utility, ...payload };
    },
    addAssistantUtilityCompanyMetaIdsToFetch: (
      sliceState: AssistantSliceState,
      { payload }: PayloadAction<{ companyMetaIds: number[] }>
    ) => {
      const { companyMetaIds } = payload;
      sliceState.utility.companyMetaIdsToFetch.push(...companyMetaIds);
    },
    startNewConversation: (
      sliceState: AssistantSliceState,
      { payload }: PayloadAction<{ company_list_id: number; defaultMessage?: boolean }>
    ) => {
      const { company_list_id, defaultMessage } = payload;
      const newThread = createNewThreadForState({ company_list_id });
      Object.entries(sliceState.threads).forEach(([threadId, thread]) => {
        if (thread.company_list_id === company_list_id) {
          sliceState.threads[threadId].is_hidden = true;
        }
      });
      if (defaultMessage) {
        newThread.messages.push(generateDefaultNewConversationMessage());
      }
      sliceState.threads[newThread.id] = newThread;
    }
  }
});

export const {
  updateThread,
  startNewThread,
  updateThreadFolderId,
  updateThreadCompanyListId,
  processAssistantResponse,
  updateActionParams,
  updateActionAfterSubmission,
  addErrorMessageToThread,
  updatePromptConfig,
  resetPromptConfig,
  updatePromptConfigSearchSettings,
  newPromptConfig,
  disableOverwrite,
  togglePromptConfigUseWeb,
  togglePromptConfigUseTable,
  updateAssistantUtility,
  addAssistantUtilityCompanyMetaIdsToFetch,
  updateActionSubmissionInfo,
  startNewConversation
} = assistantSlice.actions;

export default assistantSlice.reducer;
